import React, { Component } from "react";
import SideNavMenuItem from "views/common/SideNavMenuItem";

class ProfilePageSideNavMenu extends Component {
    render() {
        return (
            <div className="list-group list-widget sidemNavMenu">
                <SideNavMenuItem readOnly={this.props.readOnly} value="Personal Information" icon="fa-info" handleClick={this.props.handleClick} selectedSideNavValue={this.props.selectedSideNavValue} />
                <SideNavMenuItem readOnly={this.props.readOnly} value="Access Control" icon="fa-key" handleClick={this.props.handleClick} selectedSideNavValue={this.props.selectedSideNavValue} />
                <SideNavMenuItem readOnly={this.props.readOnly} value="Account Activities" icon="fe fe-activity" handleClick={this.props.handleClick} selectedSideNavValue={this.props.selectedSideNavValue} />
                <SideNavMenuItem readOnly={this.props.readOnly} value="Security Settings" icon="fa-shield" handleClick={this.props.handleClick} selectedSideNavValue={this.props.selectedSideNavValue} />
                <SideNavMenuItem readOnly={this.props.readOnly} value="Preference" icon="fa-wrench" handleClick={this.props.handleClick} selectedSideNavValue={this.props.selectedSideNavValue} />
                <SideNavMenuItem readOnly={this.props.readOnly} value="Notifications" icon="fa-bell " handleClick={this.props.handleClick} selectedSideNavValue={this.props.selectedSideNavValue} />
            </div>
        );
    }
}
export default ProfilePageSideNavMenu;