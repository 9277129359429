
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const dashbaordService = {
    //projectStatistics,
    employeeWeeklyUtilization,
    getProjectStatisticsByCategory,
    getTaskStatisticsByCategory,
    getWorkItemStatisticsByCategory

    /*taskActivity,
    projectTeamComparison,
    employeeTeamWise,
    employeeLocation,
    employeeStructure,
    employeeDivision,
    employeeType*/
};


async function employeeWeeklyUtilization(request) {
    return await axiosUtils.get(urlConfig.DASHBOARD_API_PATH+`/employee/utilization?employeeId=${request.employeeId}&startDate=${request.startDate}&endDate=${request.endDate}`);
}
async function getProjectStatisticsByCategory(purchaseOrderId) {
    return await axiosUtils.get(urlConfig.DASHBOARD_PROJECT_STAT_BY_CAT+`/${purchaseOrderId}`);
}

async function getTaskStatisticsByCategory(projectId) {
    return await axiosUtils.get(urlConfig.DASHBOARD_TASK_STAT_BY_CAT+`/${projectId}`);
}

async function getWorkItemStatisticsByCategory(deliverableId) {
    return await axiosUtils.get(urlConfig.DASHBOARD_WORKITEM_STAT_BY_CAT+`/${deliverableId}`);
}