import { employeeConstants } from '../_constants';
import { appConfigService, employeeService } from '../_services';
import { notificationActions } from './';
import { appConfigActions, accountActions } from '../_actions';

export const employeeActions = {
    initializeCreateEmployee,
    
    createEmployee,
    updateEmployee,
    updateEmployeeExitDate,
    deleteEmployee,
    removeEmployee,
    activateEmployee,
    getTeamsEmployees,
    getTeamsEmployeesPage,
    getAllEmployees,
    getEmployeesInfo,
    updateEmployeeRoles,

    getModuleConfig,
    getTeamsAttendance,
    createAttendance,
    deleteAttendance,
    getHolidays,
    createHolidayItem,
    removeHolidayItem
};

function initializeCreateEmployee(userTeamId) {
    return dispatch => {
        dispatch(getModuleConfig(userTeamId));
        dispatch(getAllEmployees());
        dispatch(appConfigActions.getAllTeams());
        dispatch(appConfigActions.getAllRoles(true))
        dispatch(accountActions.getProjectTeamAccounts(userTeamId));
    };
}
function getModuleConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'HRMS').then(
            appConfig => {
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("initializeCreateEmployee");
    };
    function request() { return { type: employeeConstants.INITIALIZE_CREATE_EMPLOYEE_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: employeeConstants.INITIALIZE_CREATE_EMPLOYEE_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: employeeConstants.INITIALIZE_CREATE_EMPLOYEE_FAILURE, error } }
}

function createEmployee(employeeRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(employeeRequest));
        dispatch(notificationActions.clear());
        employeeService.createEmployee(employeeRequest)
            .then(
                employeeResponse => {
                    dispatch(success(employeeResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('Registration successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(getErrorMessage(error)));
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(employee) { return { type: employeeConstants.CREATE_EMPLOYEE_REQUEST, employee } }
    function success(employee, showModel) { return { type: employeeConstants.CREATE_EMPLOYEE_SUCCESS, employee, showModel } }
    function failure(error) { return { type: employeeConstants.CREATE_EMPLOYEE_FAILURE, error } }
}

function getErrorMessage(error){
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}

function updateEmployee(employeeRequest) {
    return dispatch => {
        dispatch(request(employeeRequest));
        dispatch(notificationActions.clear());
        employeeService.updateEmployee(employeeRequest)
            .then(
                employeeResponse => {
                    dispatch(success(employeeResponse.payload, employeeRequest.id));
                    dispatch(notificationActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(employee) { return { type: employeeConstants.UPDATE_EMPLOYEE_REQUEST, employee } }
    function success(employee, employeeId) { return { type: employeeConstants.UPDATE_EMPLOYEE_SUCCESS, employee, employeeId } }
    function failure(error) { return { type: employeeConstants.UPDATE_EMPLOYEE_FAILURE, error } }
}

function updateEmployeeExitDate(employeeId, exitDate) {
    return dispatch => {
        dispatch(request({employeeId, exitDate}));
        dispatch(notificationActions.clear());
        employeeService.updateEmployeeExitDate({employeeId, exitDate})
            .then(
                employeeResponse => {
                    dispatch(success(employeeResponse.payload, employeeId));
                    dispatch(notificationActions.success('Update employee exit date successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(employee) { return { type: employeeConstants.UPDATE_EMPLOYEE_REQUEST, employee } }
    function success(employee, employeeId) { return { type: employeeConstants.UPDATE_EMPLOYEE_SUCCESS, employee, employeeId } }
    function failure(error) { return { type: employeeConstants.UPDATE_EMPLOYEE_FAILURE, error } }
}


function updateEmployeeRoles(selectedEmployeesAndRoles) {
    return dispatch => {
        dispatch(request(selectedEmployeesAndRoles));
        dispatch(notificationActions.clear());
        employeeService.updateEmployeeRoles(selectedEmployeesAndRoles)
            .then(
                employeeResponse => {
                    dispatch(success(employeeResponse.payload));
                    dispatch(notificationActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(employeeRoles) { return { type: employeeConstants.UPDATE_EMPLOYEE_USER_ROLE_REQUEST, employeeRoles } }
    function success(employees) { return { type: employeeConstants.UPDATE_EMPLOYEE_USER_ROLE_SUCCESS, employees } }
    function failure(error) { return { type: employeeConstants.UPDATE_EMPLOYEE_USER_ROLE_FAILURE, error } }
}


function deleteEmployee(employeeId,isDelete) {
    return dispatch => {
        dispatch(request(employeeId));

        employeeService.deleteEmployee(employeeId, isDelete)
            .then(
                employeeResponse => dispatch(success(employeeId, isDelete)),
                error => {
                    dispatch(failure(employeeId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(employeeId) { return { type: employeeConstants.DELETE_EMPLOYEE_REQUEST, employeeId } }
    function success(employeeId, isDelete) { return { type: employeeConstants.DELETE_EMPLOYEE_SUCCESS, employeeId, isDelete } }
    function failure(employeeId, error) { return { type: employeeConstants.DELETE_EMPLOYEE_FAILURE, employeeId, error } }
}

function removeEmployee(employeeId) {
    return dispatch => {
        dispatch(request(employeeId));

        employeeService.removeEmployee(employeeId)
            .then(
                employeeResponse => dispatch(success(employeeId)),
                error => {
                    dispatch(failure(employeeId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(employeeId) { return { type: employeeConstants.REMOVE_EMPLOYEE_REQUEST, employeeId } }
    function success(employeeId) { return { type: employeeConstants.REMOVE_EMPLOYEE_SUCCESS, employeeId } }
    function failure(employeeId, error) { return { type: employeeConstants.REMOVE_EMPLOYEE_FAILURE, employeeId, error } }
}

function activateEmployee(employeeId, isActive) {
    return dispatch => {
        dispatch(request(employeeId));

        employeeService.activateEmployee(employeeId, isActive)
            .then(
                employeeResponse => dispatch(success(employeeId, isActive)),
                error => {
                    dispatch(failure(employeeId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(employeeId) { return { type: employeeConstants.ACTIVATE_EMPLOYEE_REQUEST, employeeId } }
    function success(employeeId,isActive) { return { type: employeeConstants.ACTIVATE_EMPLOYEE_SUCCESS, employeeId, isActive } }
    function failure(employeeId, error) { return { type: employeeConstants.ACTIVATE_EMPLOYEE_FAILURE, employeeId, error } }
}


function getAllEmployees() {
    return dispatch => {
        dispatch(request());

        employeeService.getAllEmployees()
            .then(
                employees => dispatch(success(employees.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.GETALL_EMPLOYEES_REQUEST } }
    function success(employees) { return { type: employeeConstants.GETALL_EMPLOYEES_SUCCESS, employees } }
    function failure(error) { return { type: employeeConstants.GETALL_EMPLOYEES_FAILURE, error } }
}

function getEmployeesInfo(employeeId) {
    return dispatch => {
        dispatch(request());

        employeeService.getEmployeeById(employeeId)
            .then(
                employee => dispatch(success(employee.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.GET_INFO_EMPLOYEE_REQUEST } }
    function success(employee) { return { type: employeeConstants.GET_INFO_EMPLOYEE_SUCCESS, employee } }
    function failure(error) { return { type: employeeConstants.GET_INFO_EMPLOYEE_FAILURE, error } }
}

function getTeamsEmployeesPage(userTeamIds, filterOptions) {
    return dispatch => {
        dispatch(request());

        employeeService.getTeamsEmployees(userTeamIds)
            .then(
                employees => dispatch(success(employees.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.GET_TEAMS_EMPLOYEES_PAGE_REQUEST } }
    function success(employees, filterOptions) { return { type: employeeConstants.GET_TEAMS_EMPLOYEES_PAGE_SUCCESS, employees, filterOptions } }
    function failure(error) { return { type: employeeConstants.GET_TEAMS_EMPLOYEES_PAGE_FAILURE, error } }
}

function getTeamsEmployees(userTeamIds) {
    return dispatch => {
        dispatch(request());

        employeeService.getTeamsEmployees(userTeamIds)
            .then(
                employees => dispatch(success(employees.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.GET_TEAMS_EMPLOYEES_REQUEST } }
    function success(employees) { return { type: employeeConstants.GET_TEAMS_EMPLOYEES_SUCCESS, employees } }
    function failure(error) { return { type: employeeConstants.GET_TEAMS_EMPLOYEES_FAILURE, error } }
}

function getTeamsAttendance(userTeamIds) {
    return dispatch => {
        dispatch(request());

        employeeService.getTeamsAttendance(userTeamIds)
            .then(
                attendance => dispatch(success(attendance.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.GET_TEAMS_ATTENDANCE_REQUEST } }
    function success(attendance) { return { type: employeeConstants.GET_TEAMS_ATTENDANCE_SUCCESS, attendance } }
    function failure(error) { return { type: employeeConstants.GET_TEAMS_ATTENDANCE_FAILURE, error } }
}

function createAttendance(requestObj) {
    //console.log(requestObj)
    return dispatch => {
        dispatch(request());

        employeeService.createAttendance(requestObj)
            .then(
                attendance => dispatch(success(attendance.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.CREATE_ATTENDANCE_REQUEST } }
    function success(attendance) { return { type: employeeConstants.CREATE_ATTENDANCE_SUCCESS, attendance } }
    function failure(error) { return { type: employeeConstants.CREATE_ATTENDANCE_FAILURE, error } }
}

function deleteAttendance(requestObj) {
    return dispatch => {
        dispatch(request());

        employeeService.deleteAttendance(requestObj.id)
            .then(
                attendance => dispatch(success(requestObj.id)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: employeeConstants.DELETE_ATTENDANCE_REQUEST } }
    function success(id) { return { type: employeeConstants.DELETE_ATTENDANCE_SUCCESS, id } }
    function failure(error) { return { type: employeeConstants.DELETE_ATTENDANCE_FAILURE, error } }
}

function getHolidays(requestObj) {
    return dispatch => {
        dispatch(request());

        employeeService.getHolidays(requestObj)
            .then(
                holidays => dispatch(success(holidays.payload)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: employeeConstants.GET_HOLIDAY_LEAVE_REQUEST } }
    function success(holidays) { return { type: employeeConstants.GET_HOLIDAY_LEAVE_SUCCESS, holidays } }
    function failure(error) { return { type: employeeConstants.GET_HOLIDAY_LEAVE_FAILURE, error } }
}


function createHolidayItem(holidayItemRequest,isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(holidayItemRequest));

        employeeService.createHolidayItem(holidayItemRequest)
            .then(
                holidayItemResponse => {
                    dispatch(success(holidayItemResponse.payload,isCreateAnother));
                    dispatch(notificationActions.success('Workitem create successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(getErrorMessage(error)));
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(holidayItem) { return { type: employeeConstants.CREATE_HOLIDAY_ITEM_REQUEST, holidayItem } }
    function success(holidayItem, showModel) { return { type: employeeConstants.CREATE_HOLIDAY_ITEM_SUCCESS, holidayItem,showModel } }
    function failure(error) { return { type: employeeConstants.CREATE_HOLIDAY_ITEM_FAILURE, error } }
}

function removeHolidayItem(holidayItemId) {
    return dispatch => {
        dispatch(request(holidayItemId));

        employeeService.removeHolidayItem(holidayItemId)
            .then(
                holidayItemResponse => dispatch(success(holidayItemId)),
                error => {
                    dispatch(failure(holidayItemId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(holidayItemId) { return { type: employeeConstants.REMOVE_HOLIDAY_ITEM_REQUEST, holidayItemId } }
    function success(holidayItemId) { return { type: employeeConstants.REMOVE_HOLIDAY_ITEM_SUCCESS, holidayItemId } }
    function failure(holidayItemId, error) { return { type: employeeConstants.REMOVE_HOLIDAY_ITEM_FAILURE, holidayItemId, error } }
}
