import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "_helpers/microsoftAuthConfig";
import { MicrosoftSignOutButton } from "./fragments/MicrosoftSignOutButton";

const msalInstance = new PublicClientApplication(msalConfig);

export const MicrosoftLoginoutSection = () => {
    return (
        <MsalProvider instance={msalInstance}>
           <MicrosoftSignOutButton/>
        </MsalProvider>
    )
}