import React, { Component } from "react";
import { connect } from "react-redux";
import { accessUtils } from "_helpers";
import ProjectDetailsGeneral from "../ProjectDetailsGeneral";

class ViewProjectDetailsSidebar extends Component {
  constructor() {
    super();
    this.state = {
      permission: accessUtils.getProjectAccessControl()
    }
  }
  render() {
    return (
      <div id="createprojectsidebar" className={`right_sidebar view_project_right_sidebar  ${this.props.show ? "open" : ""}`}>
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">View Project Details</h3>
            <div className="card-options">
              <button onClick={this.props.onCloseViewProject} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          {this.props.show &&
           <div className="card-body pl-3 pr-3 overflow-auto">
            <ProjectDetailsGeneral 
                item={this.props.projectInfo} 
                readOnly={true}
                permission={this.state.permission}
                />
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projectInfo: state.projects.selectedProjectInfo,
  isLoading: state.projects.loading
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProjectDetailsSidebar);

