import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "_helpers/microsoftAuthConfig";
import { MicrosoftSignInButton } from "./fragments/MicrosoftSignInButton";

const msalInstance = new PublicClientApplication(msalConfig);

export const MicrosoftLoginSection = () => {
    return (
        <MsalProvider instance={msalInstance}>
           <MicrosoftSignInButton/>
        </MsalProvider>
    )
}