import React, { Component } from "react";
import { connect } from "react-redux";
import { bidRequestsActions } from "_actions";
import { store } from "_helpers";
import { accessUtils } from "_helpers";
import { commonUtils } from "_helpers";
import BidRequestsDetailsGeneral from "./components/BidRequestsDetailsGeneral";

class BidRequestsDetailsPage extends Component {
    constructor() {
        super()
        this.state = {
            permission: accessUtils.getBidRequestsAccessControl()
        };

    }
    componentDidMount() {

        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name + " : " + this.props.match.params.bidRequestId)
        if (!this.props.bidRequestsInfo || this.props.bidRequestsInfo.id !== this.props.match.params.bidRequestId) {
            let bidRequestId = commonUtils.removeNameIntial(this.props.match.params.bidRequestId);
            store.dispatch(this.props.getBidRequestsInfo(bidRequestId));
            this.setState({
                bidRequestId: bidRequestId
            })
        }
    }

    render() {
        return (
            <div className="bidRequestDetails">
                <div className="section-body mt-0">
                    <div className="container-fluid px-0">
                        {!this.props.isLoading && this.props.bidRequestsInfo &&
                            <div className="row mx-0 taslDetailsSection pt-1 border-top">

                                <div className="col-lg-12 px-1">
                                    <BidRequestsDetailsGeneral
                                        permission={this.state.permission}
                                        item={this.props.bidRequestsInfo} />
                                </div>

                            </div>
                        }

                    </div>
                </div>
                {!this.props.bidRequestsInfo && !this.props.isLoading &&
                    <div className="text-center pt-3">
                        <img src="/assets/images/custom/notasks-min.PNG" alt="No Bids"/>
                        <span className="d-block fsize15"> No Purchase Order details found ?</span>
                        <span className="d-block fsize12 text-muted">You may not have enough privilleage to view the deliverable details
                        </span>
                        <span className="d-block fsize12 text-muted">
                            or Deliverable does not exist</span>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    bidRequestsInfo: state.bidRequests.selectedBidRequestsInfo,
    isLoading: state.bidRequests.loading
});
const mapDispatchToProps = dispatch => ({
    getBidRequestsInfo: bidRequestsActions.getBidRequestsInfo
});
export default connect(mapStateToProps, mapDispatchToProps)(BidRequestsDetailsPage);
