import React, { Component } from "react";
import ImageUpload from "./components/ImageUpload";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { employeeActions } from "_actions";
import { store } from "_helpers";
import { validationUtils } from '_helpers'
import ProfilePageSideNavMenu from "./components/ProfilePageSideNavMenu";
import { commonUtils } from "_helpers";
import CreateEmployeeSidebarSection from "./components/modal/CreateEmployeeSidebarSection";
import ProfilePageSideSecuritySettings from "./components/fragments/ProfilePageSideSecuritySettings";
import ApplicationConfigSection from "./components/ApplicationConfigSection";
import { appConfigActions } from "_actions";
import { userActions } from "_actions";
import { accessUtils } from "_helpers";

const fieldConfig = [
    { name: 'name', mandatory: true, enabled: true },
    { name: 'email', mandatory: true, enabled: true },
    { name: 'defaultTeam', mandatory: true, enabled: true },
    { name: 'userRole', mandatory: true, enabled: true },
    { name: 'doj', mandatory: true, enabled: true },
];

class ProfilePage extends Component {
    constructor() {
        super();
        this.state = {
            selectedSideNavValue: "Personal Information",
            employeeRequest: {},
            formErrors: {},
            view: 'ACTIVE',
            sort: 'A-Z',
            permission: accessUtils.getEmployeeAccessControl(),
        }
    }

    componentDidMount() {
        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)

        //let urlEmployeeId = new URLSearchParams(this.props.location.search).get("employeeId");
        let urlEmployeeId = this.props.match.params.employeeId
        if (!urlEmployeeId) {
            urlEmployeeId = commonUtils.getCurrentUserId();
        }
        store.dispatch(this.props.getEmployeesInfo(urlEmployeeId));

        this.setState({
            employeeRequest: {
                defaultTeam: commonUtils.getCurrentUserTeamId()
            }
        });
    }

    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }

    onChangeSideNavMenu = (value) => {
        this.setState(prevState => ({
            selectedSideNavValue: value
        }));
    }

    onChange = (fieldName, value) => {
        this.setState(prevState => ({
            employeeRequest: {
                ...prevState.employeeRequest,
                [fieldName]: value
            }
        }))
    }
    refreshUserRole = () => {
        store.dispatch(userActions.authRefresh());
    }

    handleSubmit = (isCreateAnotherEnabled) => {
        //console.log("Validating form " + validationUtils.isValidRequest(fieldConfig, this.state.employeeRequest, this.state.formErrors) + "  request ");
        //console.log(this.state.employeeRequest);
        //console.log(this.state.formErrors)
    }
    handleUpdateUserExitDate = (employeeId, exitDate) => {
        store.dispatch(this.props.updateEmployeeExitDate(employeeId, exitDate));
      }
    

    render() {
        return (

            <div className="section-body mt-1">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-12 pr-0">
                            <div className="card">
                                <ImageUpload
                                    imageUrl={`/assets/images/${this.props.selectedEmployeeInfo && (this.props.selectedEmployeeInfo.gender === 'female' || this.props.selectedEmployeeInfo.gender === 'Female') ? 'female.png' : 'male.jpg'}`}
                                    readOnly={this.props.selectedEmployeeInfo && this.props.selectedEmployeeInfo.id != commonUtils.getCurrentUserId()}
                                />
                                {this.props.getInfoLoading === false && this.props.selectedEmployeeInfo &&
                                    <div className="media-body text-center">
                                        <h5 className="m-0  fsize20 fweight600">{this.props.selectedEmployeeInfo.name}</h5>
                                        <p className="text-muted mb-0">{this.props.selectedEmployeeInfo.designation}</p>
                                        <p className="text-muted mb-0">{this.props.selectedEmployeeInfo.primarySkill}</p>
                                    </div>
                                }
                                <div className="card-body">
                                    <ProfilePageSideNavMenu
                                        readOnly={this.props.selectedEmployeeInfo && this.props.selectedEmployeeInfo.id != commonUtils.getCurrentUserId()}
                                        handleClick={this.onChangeSideNavMenu}
                                        selectedSideNavValue={this.state.selectedSideNavValue} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h6>{this.state.selectedSideNavValue}</h6>
                                    <div className="card-options">
                                        {this.props.selectedEmployeeInfo && this.state.selectedSideNavValue === 'Access Control' &&
                                            <button className="link pl-3" onClick={() => this.refreshUserRole()}><i className="fa fa-refresh"></i></button>
                                        }
                                    </div>
                                </div>
                                {this.props.selectedEmployeeInfo && this.state.selectedSideNavValue === 'Access Control' &&
                                    <AccessControl
                                        getAppConfig={this.props.getAppConfig}
                                        selectedConfigData={this.props.selectedConfigData}
                                        roleId={this.props.selectedEmployeeInfo.userRole}
                                    />
                                }
                                {this.props.selectedEmployeeInfo && this.state.selectedSideNavValue === 'Personal Information' &&
                                    <CreateEmployeeSidebarSection
                                        handleSubmit={this.props.handleSubmit}
                                        handleUpdateUserExitDate={this.handleUpdateUserExitDate}
                                        editMode={this.props.editMode}
                                        readOnly={true}
                                        isPageView={true}
                                        selectedEmployeeInfo={this.props.selectedEmployeeInfo}
                                        currentUser={this.props.currentUser}
                                        fieldConfig={this.props.fieldConfig}
                                        createConfig={this.props.createConfig}
                                        allEmployees={this.props.allEmployees}
                                        allRoles={this.props.allRoles}
                                        allTeams={this.props.allTeams}
                                        isProcessing={this.props.registring || this.props.updating}
                                        permission={this.state.permission}
                                    />
                                }
                                {this.props.selectedEmployeeInfo && this.state.selectedSideNavValue === 'Security Settings' &&
                                    <ProfilePageSideSecuritySettings
                                        selectedEmployeeInfo={this.props.selectedEmployeeInfo}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

class AccessControl extends Component {
    componentDidMount() {
        store.dispatch(this.props.getAppConfig(this.props.roleId, 'role'));
    }
    render() {
        return (
            <>
                {this.props.selectedConfigData &&
                    <fieldset className="disabled" disabled="disabled">
                        <ApplicationConfigSection
                            data={this.props.selectedConfigData}
                            onChange={() => { }}
                            readOnly={true} />
                    </fieldset>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.authentication.user,
    selectedEmployeeInfo: state.employees.selectedEmployeeInfo,
    getInfoLoading: state.employees.getInfoLoading,
    selectedConfigData: state.appconfig.selectedConfigData,
});

const mapDispatchToProps = dispatch => ({
    getEmployeesInfo: employeeActions.getEmployeesInfo,
    getAppConfig: appConfigActions.getAppConfig,
    updateEmployeeExitDate: employeeActions.updateEmployeeExitDate
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);