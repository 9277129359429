import { appConfigActions } from "_actions";
import React, { Component } from "react";
import { connect } from "react-redux";

class SettingsRightSidebar extends Component {
  render() {
    return (
      <div id="settings_right_sidebar" className="right_sidebar settings_right_sidebar">
        <button className="link p-3 settingbar float-right"><i className="fa fa-close" /></button>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#Settings" aria-expanded="true">Settings</a></li>
        </ul>
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane vivify fadeIn active" id="Settings" aria-expanded="true">
            <div className="mb-4">
              <h6 className="font-14 font-weight-bold text-muted">Font Style</h6>
              <div className="custom-controls-stacked font_setting">
                <label className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" name="font" defaultValue="font-opensans" />
                  <span className="custom-control-label">Open Sans Font</span>
                </label>
                <label className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" name="font" defaultValue="font-montserrat" />
                  <span className="custom-control-label">Montserrat Google Font</span>
                </label>
                <label className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" name="font" defaultValue="font-roboto" defaultChecked />
                  <span className="custom-control-label">Robot Google Font</span>
                </label>
              </div>
            </div>
            <div className="mb-4">
              <h6 className="font-14 font-weight-bold mt-4 text-muted">SubMenu List Icon</h6>
              <div className="custom-controls-stacked list_option">
                <label className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" name="listicon" defaultValue="list-a" />
                  <span className="custom-control-label">A</span>
                </label>
                <label className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" name="listicon" defaultValue="list-b" defaultChecked />
                  <span className="custom-control-label">B</span>
                </label>
                <label className="custom-control custom-radio custom-control-inline">
                  <input type="radio" className="custom-control-input" name="listicon" defaultValue="list-c" />
                  <span className="custom-control-label">C</span>
                </label>
              </div>
            </div>
            <div>
              <h6 className="font-14 font-weight-bold mt-4 text-muted">General Settings</h6>
              <ul className="setting-list list-unstyled mt-1 setting_switch">
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Night Mode</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-darkmode" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Fix Navbar top</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-fixnavbar" defaultChecked />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Header Dark</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-pageheader" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Min Sidebar Dark</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-min_sidebar" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Sidebar Dark</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-sidebar" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Icon Color</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-iconcolor" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Gradient Color</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-gradient" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch">
                    <span className="custom-switch-description">Box Shadow</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-boxshadow" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch d-none">
                    <span className="custom-switch-description">RTL Support</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-rtl" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
                <li>
                  <label className="custom-switch d-none">
                    <span className="custom-switch-description">Box Layout</span>
                    <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input btn-boxlayout" />
                    <span className="custom-switch-indicator" />
                  </label>
                </li>
              </ul>
            </div>
            <hr />
            <div className="d-none">
              <h6 className="font-14 font-weight-bold mt-4 text-muted">Server Status</h6>
              <ul className="setting-list list-unstyled mt-1 setting_switch">
                <label>
                  <i className="fa fa-lg fa-times-circle colorred"></i>
                  <span className="ml-3">Application Server</span>
                </label>
                <label>
                  <i className="fa fa-lg fa-check-circle colorgreen"></i>
                  <span className="ml-3">Reporting Server</span>
                </label>
                <label>
                  <i className="fa fa-lg fa-check-circle colorgreen"></i>
                  <span className="ml-3">Scheduler Server</span>
                </label>
              </ul>
            </div>
            <div className="form-group d-none">
              <label className="d-block">Storage<span className="float-right">77%</span></label>
              <div className="progress progress-sm">
                <div className="progress-bar" role="progressbar" aria-valuenow={77} aria-valuemin={0} aria-valuemax={100} style={{ width: '77%' }} />
              </div>
              <button type="button" className="btn btn-primary btn-block mt-3">Upgrade Storage</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 //show: state.appConfig.showTodaysActivityModel,
 // hostHealthStatus: state.appConfig.todaysWorkItems && commonUtils.sortArrayOfObjectsByDateField(state.workItem.todaysWorkItems, "actualStartTime", true)
});

const mapDispatchToProps = dispatch => ({
  //getServerHealthStatus: appConfigActions.getServerHealthStatus
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsRightSidebar);

