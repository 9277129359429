import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import dateUtils from "_helpers/date.utils";
import classNames from 'classnames/bind';
import SelectInput from "views/common/fragments/formfields/SelectInput";

class ProjectInfoLine extends Component {

  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }
  handleRemoveProject = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveProject(this.props.item.id);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (
      <tr>
        <td className={classNames("", { "deleted": this.props.item.deleted }, { "inactive": this.props.item.archived })}>

          <button type="button" className="link" onClick={() => this.props.handleFavoriteProject(this.props.item.id, !this.props.item.favorite)}>
            <i className={classNames("fav-star pr-1", { "fa fa-star  text-orange": this.props.item.favorite }, { "fa fa-star-o": !this.props.item.favorite })}></i>
          </button>
        </td>
        <td className="w-90">
          {this.props.item.itemIdUserTeamPrefix}</td>
        <td>{dateUtils.convertDateFormat(this.props.item.createdDate)}</td>
        <td className="">


          <NavLink
            to={`/project/details/${this.props.item.itemIdUserTeamPrefix}`}
            className="nav-link list-b pl-0 mr-0 pr-0"
            activeClassName="active">
            {this.props.item.shared &&
              <i className="fa fa-share-square-o sharelink pr-1"></i>
            }
            <span className="project-name-text d-inline-block text-truncate mt-0 align-middle w-250" title={this.props.item.name}>{this.props.item.name}</span>
          </NavLink>
        </td>
        <td>{this.props.item.accountName}</td>
        <td className="d-block">
          {this.props.item.teamName &&
            <p className="m-0 m-0 text-truncate w-100" title={this.props.item.teamName}>- {this.props.item.teamName}</p>
          }
          {this.props.item.clientNames &&
            <p className="m-0 m-0 text-truncate w-100" title={this.props.item.clientNames && this.props.item.clientNames.join(',')}>
              - {this.props.item.clientNames && this.props.item.clientNames.join(',')}
            </p>
          }
        </td>
        <td className="text-center">{this.props.item.completedTaskCount}/{this.props.item.taskTotalCount}</td>
        <td className="text-center">{this.props.item.actualHours}/{this.props.item.estHours}</td>
        <td>
          <SelectInput
            options={[
              { value: "NEW", label: "New", level: 0 },
              { value: "ASSIGNED", label: "Assigned", level: 1 },
              { value: "INPROGRESS", label: "In Progress", level: 2 },
              { value: "COMPLETED", label: "Completed", level: 3 },
              { value: "CLOSED", label: "Closed", level: 4 }]}
            value={this.props.item.status}
            allowLevelDown={this.props.permission.allowStatusLevelDown}
            changeableStatus={this.props.permission.changeableStatus}
            isReadOnly={!this.props.permission.allowStatusChange}
            itemId={this.props.item.id}
            onChange={this.props.handleUpdateStatus}
            className={classNames("badge",
              { "badge-info": this.props.item.status === 'NEW' },
              { "badge-primary": this.props.item.status === 'ASSIGNED' },
              { "badge-warning": this.props.item.status === 'INPROGRESS' },
              { "badge-success": this.props.item.status === 'COMPLETED' },
              { "badge-dark": this.props.item.status === 'CLOSED' }
            )} />
        </td>
        <td className="w-100 d-block">
          <span className="project-due-text colorblue px-1 py-0 mb-0 d-block">{dateUtils.convertDateFormat(this.props.item.estStartTime)}</span>
          <span className="project-due-text red px-1 py-0">{dateUtils.convertDateFormat(this.props.item.estCompletionTime)}</span>
        </td>
        <td className="text-center w-145">
          {this.props.permission.archiveEnabled === true &&
            <label className=" custom-switch custom-switch-sm m-0">
              <input type="checkbox" onChange={e => { }}
                onClick={() => this.props.handleArchiveProject(this.props.item.id, !this.props.item.archived)}
                className="custom-switch-input" checked={this.props.item.archived ? false : true} />
              <span className="custom-switch-indicator" />
            </label>
          }
          {this.props.permission.viewEnabled === true &&
            <button className="link pl-2" title="View"
              onClick={(e) => this.props.onViewProject(this.props.item.id)}
            ><i className="fa fa-eye align-text-top fa-lg"></i></button>
          }
          {!this.props.item.archived && this.props.permission.editEnabled === true &&
            <>
              {(this.props.item.status !== 'COMPLETED' && this.props.item.status !== 'CLOSED') &&
                < button type="button" className="link pl-2" title="Edit"
                  onClick={(e) => this.props.handleEditProject(this.props.item.id, this.props.item.userTeamId)}
                ><i className="fa fa-edit" /></button>
              }
              {(this.props.item.status === 'COMPLETED' || this.props.item.status === 'CLOSED') &&
                <i className="pl-2 fa fa-edit text-muted" />
              }
            </>
          }
          {this.props.permission.duplicateEnabled === true &&
            < button type="button" className="link pl-2" title="Duplicate"
              onClick={(e) => this.props.handleDuplicateProject(this.props.item.id, this.props.item.userTeamId)}
            ><i className="fa fa-clone" /></button>
          }
          {this.props.permission.deleteEnabled === true &&
            <button type="button"
              onClick={(e) => this.props.handleDeleteProject(this.props.item.id, !this.props.item.deleted)}
              className="link pl-2" title={this.props.item.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
              {this.props.item.deleted &&
                <>
                  <i className="fa fa-rotate-left colorred blink_text"></i>
                </>
              }
              {!this.props.item.deleted &&
                <>
                  <i className="fa fa-trash"></i>
                </>
              }
            </button>
          }
          {this.props.permission.removeEnabled === true &&

            <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveProject(this.state.confrimDelete)}>
              {this.state.confrimDelete === true &&
                <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
              }
              {this.state.confrimDelete === false &&
                <i className="dropdown-icon fa fa-times colorred"></i>
              }
            </button>
          }
        </td>
      </tr >
    );
  }
}
export default ProjectInfoLine;