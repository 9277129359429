import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
const MAX_FILE_SIZE = 10485760;


class FileDropZone extends Component {

  constructor() {
    super();
    this.state = {
      rejectedFiles: [],
      acceptedFiles: []
    };
  }


  onDrop = (files) => {
    const rejectedFiles = files && files.filter(file => file.size > MAX_FILE_SIZE) || [];
    const acceptedFiles = files && files.filter(file => file.size <= MAX_FILE_SIZE) || [];
    this.setState({ 
      rejectedFiles,
      acceptedFiles
    })
  };

  render() {
    const rejectedFiles = this.state.rejectedFiles.map(file => (
      <li key={file.name} >
        {file.name} - {file.size} bytes - <span className="colorred">File is too large to upload Max limit 10MB.</span>
      </li>
    ));

    const acceptedFiles = this.state.acceptedFiles.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <>
        <Dropzone
          onDrop={this.onDrop}
         // maxFiles={5}
          //accept="image/png"
          //minSize={0}
          //maxSize={MAX_FILE_SIZE}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject}) => {
            return (
              <>
                <section className="container">
                    <ul>
                      {rejectedFiles}
                      {acceptedFiles}
                    </ul>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>{!isDragActive && 'Click here or drop a file to upload!'}
                      {isDragActive && !isDragReject && "Drop it like it's hot!"}
                      {isDragReject && "File type not accepted, sorry!"}</p>
                  </div>
                </section>
                
              </>
            )
          }
          }
        </Dropzone>
      </>
    );
  }
}

export default FileDropZone;