import React, { PureComponent } from "react";
import BidRequestsListTable from "./components/BidRequestsListTable";
import CreateBidRequestsSidebar from "./components/modal/CreateBidRequestsSidebar";
import { connect } from "react-redux";
import { bidRequestsActions } from "_actions";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";
import PageInnerHeader from "views/common/PageInnerHeader";
import { commonUtils } from "_helpers";
import { bidRequestsConstants } from "_constants";
import { accessUtils } from "_helpers";
import ViewBidRequestsDetailsSidebar from "./components/modal/ViewBidRequestsDetailsSidebar";
import ItemNotFoundSection from "views/project/components/ItemsNotFoundSection";


class BidRequestsListPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ACTIVE", label: "Active" }, { name: "ARCHIVED", label: "Archived" }, { name: "NEW", label: "New" },{ name: "ASSIGNED", label: "Assigned" },{ name: "INPROGRESS", label: "In Progress" }, { name: "WON", label: "Won" }, { name: "LOST", label: "Lost" }, { name: "CANCELLED", label: "Cancelled" }, { name: "REJECTED", label: "Rejected" }, { name: "CLOSED", label: "Closed" }], selected: "ACTIVE" },
        SORT: { options: [{ name: "Created Date", label: "Created Date" }, { name: "A-Z", label: "A-Z" }], selected: "A-Z", directionUp:true },
        filterText: null,
        dateRangeValue: dateUtils.getDefaultDateRange(),
      },
      permission: accessUtils.getBidRequestsAccessControl(),
      editMode: false,
      duplicateMode: false
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    if (!this.props.items) {
      this.invokeBidRequestsListService();
    }
  }

  invokeBidRequestsListService = () => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    store.dispatch({ type: bidRequestsConstants.INITIALIZE_BID_REQUEST_USER_TEAMS, userTeams });
    !this.props.items && this.reloadBidRequestsListService(userTeams);
  }

  reloadBidRequestsListService = (userTeams) => {
    let selectedUserTeams = []
    let currentUserId = commonUtils.getCurrentUserId();
    if (this.state.permission.switchUserTeamsEnabled === true) {
      userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    }
    const startDate = this.state.filterOptions.dateRangeValue.start.format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = this.state.filterOptions.dateRangeValue.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getBidRequestsList({ userTeamIds: selectedUserTeams, currentUserId: currentUserId, startDate: startDate, endDate: endDate }, this.state.filterOptions))
  }

  onCreateItemClick = (e) => {
    e.preventDefault();
    this.setState({ editMode: false });
    this.setState({ duplicateMode: false });
    let defaultTeam = commonUtils.getCurrentUserTeamId();
    store.dispatch({ type: bidRequestsConstants.SHOW_BID_REQUEST_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateBidRequests(defaultTeam));
  }
  onCloseCreateItem = (e) => {
    e.preventDefault();
    store.dispatch({ type: bidRequestsConstants.CLOSE_BID_REQUEST_CREATE_WINDOW });
  }

  handleDateRangeChange = (daterange) => {
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        dateRangeValue: daterange
      }
    }), () => {
      this.reloadBidRequestsListService(commonUtils.getUserTeams(this.props.userTeams));
    })
  }

  handleDeleteBidRequests = (bidRequestsIdTobeDeleted, isDelete) => {

    store.dispatch(this.props.deleteBidRequests(bidRequestsIdTobeDeleted, isDelete));
  }
  handleUpdateStatus = (projectId, status) => {
    store.dispatch(this.props.updateBidRequestsStatus(projectId, status));
  }


  handleEditBidRequests = (bidRequestsId, bidRequestsUserTeamId) => {

    this.setState({ duplicateMode: false, editMode: true });
    store.dispatch(this.props.getBidRequestsInfo(bidRequestsId));
    store.dispatch({ type: bidRequestsConstants.SHOW_BID_REQUEST_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateBidRequests(bidRequestsUserTeamId));
  }

  handleDuplicateBidRequests = (bidRequestsId, bidRequestsUserTeamId) => {

    this.setState({ duplicateMode: true, editMode: false });
    store.dispatch(this.props.getBidRequestsInfo(bidRequestsId));
    store.dispatch({ type: bidRequestsConstants.SHOW_BID_REQUEST_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateBidRequests(bidRequestsUserTeamId));
  }

  onViewBidRequests = (bidRequestsId) => {
    store.dispatch(this.props.getBidRequestsInfo(bidRequestsId));
    store.dispatch({ type: bidRequestsConstants.SHOW_BID_REQUEST_VIEW_WINDOW });
  }

  onCloseViewBidRequests = () => {
    store.dispatch({ type: bidRequestsConstants.CLOSE_BID_REQUEST_VIEW_WINDOW });
  }
  handleRemoveBidRequests = (bidRequestsId) => {
    store.dispatch(this.props.removeBidRequests(bidRequestsId));
  }

  handleArchiveBidRequests = (bidRequestsId, isActive) => {
    store.dispatch(this.props.archiveBidRequests(bidRequestsId, isActive));
  }

  handleSubmit = (BidRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
    if (isEdtiMode) {
      store.dispatch(this.props.updateBidRequests(BidRequest));
    } else {
      store.dispatch(this.props.createBidRequests(BidRequest, isCreateAnotherEnabled, callbackFunction));
    }
  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: bidRequestsConstants.CHANGE_FILTER_BID_REQUEST, filterOptions });
      });
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: bidRequestsConstants.INITIALIZE_BID_REQUEST_USER_TEAMS, userTeams });
    this.reloadBidRequestsListService(userTeams)
  }

  render() {
    return (
      <div className="bidRequestslist">

        <PageInnerHeader
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          handleUserTeamsSelection={this.handleUserTeamsSelection}
          showCreateMenu={this.state.permission.createEnabled}
          showGridListMenu={false}
          searchViewEnabled={true}
          showFilter={true}
          showUserTeamMenu={true}
          isLoading={this.props.isLoading}
          filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
          handleFilterChange={this.handleFilterChange}
          onFilterTextChange={this.onFilterTextChange}
          showCreateMenuText="CREATE BID REQUEST"
          createItemHandler={this.onCreateItemClick}
          showDateRangeSelector={false}
          handleDateRangeChange={this.handleDateRangeChange}
          permission={this.state.permission}
        >
        </PageInnerHeader>
        {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
          <>

            <div className="section-body mt-1">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content">

                      <BidRequestsListTable
                        items={this.props.items}
                        isLoading={this.props.isLoading}
                        handleDeleteBidRequests={this.handleDeleteBidRequests}
                        handleEditBidRequests={this.handleEditBidRequests}
                        handleUpdateStatus={this.handleUpdateStatus}
                        handleDuplicateBidRequests={this.handleDuplicateBidRequests}
                        onViewBidRequests={this.onViewBidRequests}
                        handleArchiveBidRequests={this.handleArchiveBidRequests}
                        handleRemoveBidRequests={this.handleRemoveBidRequests}
                        permission={this.state.permission} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        <ItemNotFoundSection items={this.props.items} isLoading={this.props.isLoading} imgurl="bid-request-min.png" text="No BidRequests found ?" description="Try Creating a BidRequests from scratch..." />
        <CreateBidRequestsSidebar
          show={this.props.showCreateModel}
          closeActionHandler={this.onCloseCreateItem}
          editMode={this.state.editMode}
          duplicateMode={this.state.duplicateMode}
          selectedBidRequestsInfo={this.props.selectedBidRequestsInfo}
          handleSubmit={this.handleSubmit}
        />
        <ViewBidRequestsDetailsSidebar
          show={this.props.isShowBidRequestsViewSidebar}
          onCloseViewBidRequests={this.onCloseViewBidRequests} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication,
  isLoading: state.bidRequests.loading,
  userTeams: commonUtils.getUserTeams(state.bidRequests.userTeams),
  items: commonUtils.filterBidRequests(state.bidRequests.items, state.bidRequests.filterOptions),
  filterOptions: state.bidRequests.filterOptions,
  selectedBidRequestsInfo: state.bidRequests.selectedBidRequestsInfo,
  showCreateModel: state.bidRequests.showCreateModel,
  isShowBidRequestsViewSidebar: state.bidRequests.isShowBidRequestsViewSidebar
});
const mapDispatchToProps = dispatch => ({
  getBidRequestsInfo: bidRequestsActions.getBidRequestsInfo,
  getBidRequestsList: bidRequestsActions.getBidRequestsList,
  createBidRequests: bidRequestsActions.createBidRequests,
  updateBidRequests: bidRequestsActions.updateBidRequests,
  updateBidRequestsStatus: bidRequestsActions.updateBidRequestsStatus,
  deleteBidRequests: bidRequestsActions.deleteBidRequests,
  removeBidRequests: bidRequestsActions.removeBidRequests,
  archiveBidRequests: bidRequestsActions.archiveBidRequests,
  initializeCreateBidRequests: bidRequestsActions.initializeCreateBidRequests
});
export default connect(mapStateToProps, mapDispatchToProps)(BidRequestsListPage);