import React from "react";
import { useMsal } from "@azure/msal-react";
import { userActions } from "_actions";
import { store } from "_helpers";

/**
 * Renders a sign-out button
 */
export const MicrosoftSignOutButton = () => {
    const { instance } = useMsal();


    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            }).then(response => {
                store.dispatch(userActions.logout());

            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            }).then(response => {
                store.dispatch(userActions.logout());

            });
        }
    }
    return (
        <button className="link dropdown-item" onClick={() => handleLogout("popup")}><i className="dropdown-icon fe fe-log-out"></i>Microsoft Sign out</button>
    )
}