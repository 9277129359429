import React, { Component } from "react";
import CustomInput from "views/common/fragments/formfields/CustomInput";
class ServiceLineEstimate extends Component {
    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.name, {
            ...this.props.serviceLineEstimate,
            [fieldName]: newValue
        })
    }
    onSeviceLineChange = (fieldName, selectedServiceLines) => {

        let updatedServiceLineEstimate = null
        if (this.props.serviceLineEstimate) {
            updatedServiceLineEstimate = {}
            if (selectedServiceLines) {
                selectedServiceLines.forEach(serviceLine => {
                    let newServiceEstimate = this.props.serviceLineEstimate[serviceLine] || (this.props.parentServiceLineEstimate && this.props.parentServiceLineEstimate[serviceLine]) || { estHours: 0, estHourlyRate: 0 }
                    updatedServiceLineEstimate = {
                        ...updatedServiceLineEstimate,
                        [serviceLine]: {
                            ...newServiceEstimate
                        }
                    }
                })
            }
        }
        this.props.onChange(fieldName, selectedServiceLines)
        this.props.onChange(this.props.name, updatedServiceLineEstimate)
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <CustomInput
                            type='select'
                            label="Service Lines"
                            name="serviceLines"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.mappingObject}
                            onChange={this.onSeviceLineChange}
                            placeholder="Service Lines..."
                            multi={true}
                            options={this.props.serviceLineOptions}
                            onFormError={this.props.onFormError}
                        />
                    </div>
                </div>
                {this.props.selectedServiceLines &&
                    <div className="row bgcolorlightgray">
                        <div className="row bgcolorgray col-12 py-1 px-0 mx-0 fweight600">
                            <div className="col-4 fsize11">Service Line</div>
                            <div className="col-3 fsize11">Est Hours</div>
                            <div className="col-3 fsize11">Est Hourly Rate ($)</div>
                            <div className="col-2 fsize11">Est Cost</div>
                        </div>
                        {this.props.selectedServiceLines.map(selectedServiceLine =>
                            <div className="row col-12 ml-1" key={selectedServiceLine}>
                                <ServiceLineEstimateSection
                                    readOnly={this.props.readOnly}
                                    config={this.props.config}
                                    key={selectedServiceLine}
                                    serviceLineEstimate={this.props.serviceLineEstimate}
                                    selectedServiceLine={selectedServiceLine}
                                    onChange={this.onChange}
                                    fieldConfig={this.props.fieldConfig}
                                    onFormError={this.props.onFormError}
                                    createConfig={this.props.createConfig}
                                />
                            </div>
                        )
                        }
                    </div>
                }

            </>
        )
    }
}


class ServiceLineEstimateSection extends Component {

    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.selectedServiceLine, {
            ...this.props.serviceLineEstimate[this.props.selectedServiceLine] || {},
            [fieldName]: newValue
        })
    }
    onFormError = () => {

    }
    render() {
        return (
            <>
                <div className="col-4">
                    <span className="d-block mt-1 fsize11">{this.props.selectedServiceLine}</span>
                </div>
                <div className="col-3">
                    {this.props.config.estHourViewEnabled &&
                        <CustomInput inputtype='text'
                            readOnly={this.props.readOnly || this.props.config.estHourEditEnabled === false}
                            name="estHours"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.serviceLineEstimate[this.props.selectedServiceLine] || {}}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Estimated Hours..."
                            datatype='hourdecimal' />
                    }
                    {!this.props.config.estHourViewEnabled &&
                        <span>----</span>
                    }
                </div>
                <div className="col-3">
                    {this.props.config.estHourlyCostViewEnabled &&
                        <CustomInput inputtype='text'
                            readOnly={this.props.readOnly || this.props.config.estHourlyCostEditEnabled === false}
                            name="estHourlyRate"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.serviceLineEstimate[this.props.selectedServiceLine] || {}}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Hourly Rate..."
                            datatype='decimal' />
                    }
                    {!this.props.config.estHourlyCostViewEnabled &&
                        <span>$xxx</span>
                    }
                </div>
                <div className="col-2">
                    {this.props.config.estHourlyCostViewEnabled &&
                        <>
                            ${
                                (parseFloat((this.props.serviceLineEstimate[this.props.selectedServiceLine] && this.props.serviceLineEstimate[this.props.selectedServiceLine].estHourlyRate) || 0) * parseFloat((this.props.serviceLineEstimate[this.props.selectedServiceLine] && this.props.serviceLineEstimate[this.props.selectedServiceLine].estHours) || 0)).toFixed(2)
                            }
                        </>
                    }
                    {!this.props.config.estHourlyCostViewEnabled &&
                        <span>$xxx</span>
                    }
                </div>
            </>
        )
    }
}

export default ServiceLineEstimate