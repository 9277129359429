import React, { Component } from "react";
import { commonUtils } from "_helpers";

class UserTeamSelection extends Component {
	constructor() {
		super();
		this.state = {
			selectedUserTeamsIds: null
		};
	}
	onCheckChangeElement = (event, userTeamId) => {

		let currentTeams = this.state.selectedUserTeamsIds || this.props.mappingObject[this.props.name] || [];
		if (event.target.checked) {
			currentTeams.push(userTeamId);
		} else {
			currentTeams = currentTeams.filter(function (teamId) {
				return teamId !== userTeamId
			})
		}
		this.setState({
			selectedUserTeamsIds: currentTeams
		}, () => {
			this.props.onChange(this.props.name, this.state.selectedUserTeamsIds);
		});

	}
	renderConfigurationValueItems = () => {
		let value = this.props.mappingObject && this.props.mappingObject[this.props.name];
		let currentUserTeams = commonUtils.getUserTeamsFromUserItem();
		return (
			<>
				{this.props.mappingObject && this.props.userTeams.map(userTeam => {
					let isTeamEnabledForUser = currentUserTeams.find(currentUserTeam => userTeam.id === currentUserTeam.id);
					return <React.Fragment key={userTeam.id}>
						{(!this.props.showOnlyActive || isTeamEnabledForUser || value && value.includes(userTeam.id)) &&
							<div className={this.props.teamClasssName ? this.props.teamClasssName : 'col-4'} key={userTeam.id}>
								<label className="custom-control custom-checkbox" >
									<input type="checkbox"
										disabled={this.props.isLoading || (!isTeamEnabledForUser && !this.props.enableAllTeams)}
										checked={(value && value.includes(userTeam.id)) || false}
										onChange={(event) => this.onCheckChangeElement(event, userTeam.id)}
										className="custom-control-input"
										name={`Team${userTeam.id}`}
										value={userTeam.id || ''} />
									<span className="custom-control-label fsize13 fweight600">{userTeam.name}</span>
								</label>

							</div>
						}
					</React.Fragment>
				})
				}
			</>
		)

	}
	render() {
		return (
			<>
				{this.props.userTeams && this.props.userTeams.length > 1 &&
					<div className={this.props.inputClassName ? this.props.inputClassName : "col-6"}>

						<label className="form-label">{this.props.label}</label>
						<div className="col-12 row" name={this.props.name}>
							{this.renderConfigurationValueItems()}
						</div>

					</div>
				}
			</>
		);
	}
}

export default UserTeamSelection;
