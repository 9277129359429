import React, { Component } from "react";

class ItemNotFoundSection extends Component {

	render() {
		return (
			<>
				{(!this.props.items || this.props.items.length === 0) && !this.props.isLoading &&
					<div className={`pt-5 flex-column d-flex h-100vh ${this.props.bgcolor ? this.props.bgcolor :'bgcolorlightgray'}`}>

						<div className="text-center">
							<img alt={this.props.text} src={"/assets/images/custom/" + this.props.imgurl} />
							<span className="d-block fsize15">{this.props.text} </span>
							<span className="d-block fsize12 text-muted">{this.props.description} </span>
						</div>

					</div>
				}
			</>
		);
	}
}

export default ItemNotFoundSection;
