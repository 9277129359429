export const dashboardConstants = {
    GET_PROJECT_STATISTICS_BY_CATEGORY_REQUEST: 'GET_PROJECT_STATISTICS_BY_CATEGORY_REQUEST',
    GET_PROJECT_STATISTICS_BY_CATEGORY_SUCCESS: 'GET_PROJECT_STATISTICS_BY_CATEGORY_SUCCESS',
    GET_PROJECT_STATISTICS_BY_CATEGORY_FAILURE: 'GET_PROJECT_STATISTICS_BY_CATEGORY_FAILURE',

    GET_TASK_STATISTICS_BY_CATEGORY_REQUEST: 'GET_TASK_STATISTICS_BY_CATEGORY_REQUEST',
    GET_TASK_STATISTICS_BY_CATEGORY_SUCCESS: 'GET_TASK_STATISTICS_BY_CATEGORY_SUCCESS',
    GET_TASK_STATISTICS_BY_CATEGORY_FAILURE: 'GET_TASK_STATISTICS_BY_CATEGORY_FAILURE',

    GET_WORKITEM_STATISTICS_BY_CATEGORY_REQUEST: 'GET_WORKITEM_STATISTICS_BY_CATEGORY_REQUEST',
    GET_WORKITEM_STATISTICS_BY_CATEGORY_SUCCESS: 'GET_WORKITEM_STATISTICS_BY_CATEGORY_SUCCESS',
    GET_WORKITEM_STATISTICS_BY_CATEGORY_FAILURE: 'GET_WORKITEM_STATISTICS_BY_CATEGORY_FAILURE',

    GET_EMPLOYEE_WEEKLY_UTILIZATION_REQUEST: 'GET_EMPLOYEE_WEEKLY_UTILIZATION_REQUEST',
    GET_EMPLOYEE_WEEKLY_UTILIZATION_SUCCESS: 'GET_EMPLOYEE_WEEKLY_UTILIZATION_SUCCESS',
    GET_EMPLOYEE_WEEKLY_UTILIZATION_FAILURE: 'GET_EMPLOYEE_WEEKLY_UTILIZATION_FAILURE',

};