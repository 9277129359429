import React, { Component } from "react";

class LoginPageFooter extends Component {

  render() {
    return <div className="separator">
      <div>
        <h2 className='project-scheduler-text'><span> Project Management, v 2.3</span></h2>
        <p className='text-reserved-text'>©2023 All Rights Reserved. Powered by Infleca Innovation Pvt Ltd, Privacy and Terms</p>
      </div>
    </div>;

  }
}
export default LoginPageFooter;

