import React, { Component } from "react";

class ItemPropertyInfo extends Component {

	render() {
		return (
			<div className="form-group col-4">
			<label className="form-label text-muted">{this.props.label}</label>
			<span className="fsize13 fweight600 menu-text-black">{this.props.value}</span>
		</div>
		);
	}
}

export default ItemPropertyInfo;
