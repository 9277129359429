import React, { Component } from "react";

class ProjectDetailsActivity extends Component {
    render() {
        return (
            <div className="card h-100 mb-0">
                <div className="card-header">
                    <h3 className="card-title">Activities</h3>
                    <div className="card-options">
                    </div>
                </div>
                <div className="card-body pl-5 pr-2 d-none">
               
                    <div className="timeline_item ">
                        <img className="tl_avatar" src="/assets/images/xs/avatar1.jpg" alt="" />
                        <span><button className="link">Elisse Joson</button> San Francisco, CA <small className="float-right text-right">20-April-2019 - Today</small></span>
                        <h6 className="font600">Hello, 'Im a single div responsive timeline without media Queries!</h6>
                        <div className="msg">
                            <p>I'm speaking with myself, number one, because I have a very good brain and I've said a lot of things. I write the best placeholder text, and I'm the biggest developer on the web card she has is the Lorem card.</p>
                            <button className="link mr-20 text-muted"><i className="fa fa-heart text-pink" /> 12 Love</button>
                            <button className="link text-muted" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i className="fa fa-comments" /> 1 Comment</button>
                            <div className="collapse p-4 section-gray mt-2" id="collapseExample">
                                <form className="well">
                                    <div className="form-group">
                                        <textarea rows={2} className="form-control no-resize" placeholder="Enter here for tweet..." defaultValue={""} />
                                    </div>
                                    <button className="btn btn-primary">Submit</button>
                                </form>
                                <ul className="recent_comments list-unstyled mt-4 mb-0">
                                    <li>
                                        <div className="avatar_img">
                                            <img className="rounded img-fluid" src="/assets/images/xs/avatar4.jpg" alt="" />
                                        </div>
                                        <div className="comment_body">
                                            <h6>Donald Gardner <small className="float-right font-14">Just now</small></h6>
                                            <p>Lorem ipsum Veniam aliquip culpa laboris minim tempor</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timeline_item ">
                        <img className="tl_avatar" src="/assets/images/xs/avatar4.jpg" alt="" />
                        <span><button className="link" >Dessie Parks</button> Oakland, CA <small className="float-right text-right">19-April-2019 - Yesterday</small></span>
                        <h6 className="font600">Oeehhh, that's awesome.. Me too!</h6>
                        <div className="msg">
                            <p>I'm speaking with myself, number one, because I have a very good brain and I've said a lot of things. on the web by far... While that's mock-ups and this is politics, are they really so different? I think the only card she has is the Lorem card.</p>
                            <div className="timeline_img mb-20">
                                <img className="width100" src="/assets/images/gallery/1.jpg" alt="Awesome Image" />
                                <img className="width100" src="/assets/images/gallery/2.jpg" alt="Awesome Image" />
                            </div>
                            <button className="link mr-20 text-muted"><i className="fa fa-heart text-pink" /> 23 Love</button>
                            <button className="link text-muted" role="button" data-toggle="collapse" href="#collapseExample1" aria-expanded="false" aria-controls="collapseExample1"><i className="fa fa-comments" /> 2 Comment</button>
                            <div className="collapse p-4 section-gray mt-2" id="collapseExample1">
                                <form className="well">
                                    <div className="form-group">
                                        <textarea rows={2} className="form-control no-resize" placeholder="Enter here for tweet..." defaultValue={""} />
                                    </div>
                                    <button className="btn btn-primary">Submit</button>
                                </form>
                                <ul className="recent_comments list-unstyled mt-4 mb-0">
                                    <li>
                                        <div className="avatar_img">
                                            <img className="rounded img-fluid" src="/assets/images/xs/avatar4.jpg" alt="" />
                                        </div>
                                        <div className="comment_body">
                                            <h6>Donald Gardner <small className="float-right font-14">Just now</small></h6>
                                            <p>Lorem ipsum Veniam aliquip culpa laboris minim tempor</p>
                                            <div className="timeline_img mb-20">
                                                <img className="width150" src="/assets/images/gallery/7.jpg" alt="Awesome Image" />
                                                <img className="width150" src="/assets/images/gallery/8.jpg" alt="Awesome Image" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="avatar_img">
                                            <img className="rounded img-fluid" src="/assets/images/xs/avatar3.jpg" alt="" />
                                        </div>
                                        <div className="comment_body">
                                            <h6>Dessie Parks <small className="float-right font-14">1min ago</small></h6>
                                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="timeline_item ">
                        <img className="tl_avatar" src="/assets/images/xs/avatar7.jpg" alt="" />
                        <span><button className="link" >Rochelle Barton</button> San Francisco, CA <small className="float-right text-right">12-April-2019</small></span>
                        <h6 className="font600">An Engineer Explains Why You Should Always Order the Larger Pizza</h6>
                        <div className="msg">
                            <p>I'm speaking with myself, number one, because I have a very good brain and I've said a lot of things. I write the best placeholder text, and I'm the biggest developer on the web by far... While that's mock-ups and this is politics, is the Lorem card.</p>
                            <button className="link mr-20 text-muted"><i className="fa fa-heart text-pink" /> 7 Love</button>
                            <button className="link text-muted" role="button" data-toggle="collapse" href="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"><i className="fa fa-comments" /> 1 Comment</button>
                            <div className="collapse p-4 section-gray mt-2" id="collapseExample2">
                                <form className="well">
                                    <div className="form-group">
                                        <textarea rows={2} className="form-control no-resize" placeholder="Enter here for tweet..." defaultValue={""} />
                                    </div>
                                    <button className="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectDetailsActivity;
