import { itemAdditionalService } from '_services';
import { itemAdditionalConstants } from '../_constants';

export const itemAdditionalActions = {
    createItemAdditional,
    getItemAdditionals,
    removeItemAdditional,
    getSearchResults

};

function createItemAdditional(itemAdditionalRequest) {
    return dispatch => {
        dispatch(request());
        itemAdditionalService.createItemAdditional(itemAdditionalRequest)
            .then(
                itemAdditionalResponse => dispatch(success(itemAdditionalResponse.payload, itemAdditionalRequest.type)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: itemAdditionalConstants.CREATE_ITEM_ADDITIONAL_REQUEST }
    }
    function success(itemAdditional, itemType) { return { type: itemAdditionalConstants.CREATE_ITEM_ADDITIONAL_SUCCESS, itemAdditional, itemType} }
    function failure(error) { return { type: itemAdditionalConstants.CREATE_ITEM_ADDITIONAL_FAILURE, error } }

}

function getItemAdditionals(itemAdditionalRequest, type) {
    return dispatch => {
        dispatch(request());
        itemAdditionalService.getItemAdditionals(itemAdditionalRequest)
            .then(
                itemAdditional=> dispatch(success(itemAdditional.payload, type)),
                error => dispatch(failure(error.toString(), type))
            );
    };

    function request() {
        return { type: itemAdditionalConstants.GETALL_ITEM_ADDITIONALS_REQUEST }
    }
    function success(itemAdditionals, itemType) { return { type: itemAdditionalConstants.GETALL_ITEM_ADDITIONALS_SUCCESS, itemAdditionals, itemType } }
    function failure(error, itemType) { return { type: itemAdditionalConstants.GETALL_ITEM_ADDITIONALS_FAILURE, error, itemType } }

}

function removeItemAdditional(itemAdditionalRequest) {
    return dispatch => {
        dispatch(request());
        itemAdditionalService.removeItemAdditional(itemAdditionalRequest)
            .then(
                itemAdditional => dispatch(success(itemAdditional.payload, itemAdditionalRequest.itemId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: itemAdditionalConstants.REMOVE_ITEM_ADDITIONAL_REQUEST }
    }
    function success(itemAdditional, itemId) { return { type: itemAdditionalConstants.REMOVE_ITEM_ADDITIONAL_SUCCESS, itemAdditional , itemId} }
    function failure(error) { return { type: itemAdditionalConstants.REMOVE_ITEM_ADDITIONAL_FAILURE, error } }
}



function getSearchResults(searchText) {
    return dispatch => {
        dispatch(request());
        itemAdditionalService.getSearchResults(searchText)
            .then(
                searchResults=> dispatch(success(searchResults.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: itemAdditionalConstants.GET_ALL_SEARCH_RESULT_REQUEST }
    }
    function success(searchResults) { return { type: itemAdditionalConstants.GET_ALL_SEARCH_RESULT_SUCCESS, searchResults } }
    function failure(error) { return { type: itemAdditionalConstants.GET_ALL_SEARCH_RESULT_FAILURE, error} }

}