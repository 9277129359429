
import { axiosUtils, commonUtils } from '../_helpers';
import urlConfig from 'url.config'

export const projectService = {
   createProject,
   updateProject,
   removeProject,
   archiveProject,
   addToFavorites,
   deleteProject,
   updateProjectStatus,
   getProjectList,
   getProjectsByPurchaseOrder,
   searchProjectList,
   getProjectInfo,
   getProjectDeatils,
   updateProjectEstimate,
   getProjectListForTimesheet,
   getViewReportData
};

async function getProjectList(request) {
   return await axiosUtils.get(urlConfig.PROJECT_GET_ALL + `?userTeamIds=${request.userTeamIds}&employeeIds=${request.currentUserId}&startDate=${request.startDate}&endDate=${request.endDate}&isOnlyUserTeams=${request.isOnlyUserTeams}`);
}

async function getProjectsByPurchaseOrder(request) {
   return await axiosUtils.get(urlConfig.PROJECT_GET_PURCHASEORDER + `?userTeamIds=${request.userTeamIds}&employeeId=${request.currentUserId}&purchaseOrderNumber=${request.purchaseOrderNumber}`);
}

async function searchProjectList(request) {
   return await axiosUtils.post(urlConfig.PROJECT_GET_ALL + `/search?userTeamIds=${request.userTeamIds}&employeeId=${request.currentUserId}&isOnlyUserTeams=${request.isOnlyUserTeams}`, request.projectSearchRequest);
}

async function getProjectListForTimesheet(request) {
   return await axiosUtils.get(urlConfig.PROJECT_GET_TIMESHEET + `?userTeamId=${request.userTeamIds}&employeeId=${request.currentUserId}&startDate=${request.startDate}&endDate=${request.endDate}&isOnlyUserTeams=${request.isOnlyUserTeams}`);
}

async function getProjectInfo(projectId) {
   return await axiosUtils.get(urlConfig.PROJECT_GET_INFO + `/${projectId}`);
}

async function getProjectDeatils(projectId) {
   return await axiosUtils.get(urlConfig.PROJECT_GET_INFO + `/details/${projectId}`);
}

async function createProject(projectRequest) {
   return await axiosUtils.post(urlConfig.PROJECT_CREATE, projectRequest);
}

async function updateProject(projectRequest) {
   return await axiosUtils.put(urlConfig.PROJECT_UPDATE, projectRequest);
}


async function updateProjectStatus(projectId, status, currentUserId) {
   return await axiosUtils.put(urlConfig.PROJECT_UPDATE + `/status/${projectId}/${status}?employeeId=${currentUserId}`);
}

async function deleteProject(projectId, isDelete) {
   return await axiosUtils.remove(urlConfig.PROJECT_DELETE + `/${projectId}/${isDelete}`);
}

async function removeProject(projectId) {
   return await axiosUtils.remove(urlConfig.PROJECT_REMOVE + `/${projectId}`);
}

async function archiveProject(projectId, isArchive) {
   return await axiosUtils.put(urlConfig.PROJECT_ARCHIVE + `/${projectId}/${isArchive}`);
}

async function addToFavorites(projectId, employeeId, isFavorite) {
   return await axiosUtils.put(urlConfig.PROJECT_ADD_TO_FAVORITES + `/${projectId}/${employeeId}/${isFavorite}`);
}

async function updateProjectEstimate(projectEstimateRequest, projectId) {
   return await axiosUtils.put(urlConfig.PROJECT_UPDATE_ESTIMATE + `/${projectId}`, projectEstimateRequest);
}

async function getViewReportData(request) {
   let url = urlConfig.REPORT_VIEW + `/${request.reportType}/key?userTeamIds=${request.userTeamIds}&startDate=${request.startDate}&endDate=${request.endDate}`;
   if (request.reportType === 'myUtilization' || request.reportType === 'myTimesheetData' || request.reportType === 'myTimesheetDataDump') {
      url = url + "&employeeIds=" + [commonUtils.getCurrentUserId()]
   }
   return await axiosUtils.get(url);
}