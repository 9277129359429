import { projectConstants } from '../_constants';
let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true, editable: true },
  { name: 'purchaseOrderId', mandatory: false, enabled: true, editable: false },
  { name: 'proposalNumber', mandatory: false, enabled: true, editable: true },
  { name: 'userTeamId', mandatory: true, enabled: true, editable: true },
  { name: 'estStartTime', mandatory: true, enabled: true, editable: true },
  { name: 'estCompletionTime', mandatory: true, enabled: true, editable: true },
]
let estimateTypeOptions = [{ value: 'GENERAL', label: 'General' }, { value: 'SKILL', label: 'Skill' }, { value: 'PRACTICE', label: 'Practice' }, { value: 'DESIGNATION', label: 'Designation' }]

export function projects(state = { estimateTypeOptions, fieldConfig }, action) {

  switch (action.type) {
    case projectConstants.INITIALIZE_CREATE_PROJECT_REQUEST:
      return {
        ...state,
        fieldConfig: fieldConfig,
        createInitializing: true,
      };
    case projectConstants.INITIALIZE_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        createConfig: [
          ...action.configuration
        ]
      };
    case projectConstants.INITIALIZE_CREATE_PROJECT_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };

    case projectConstants.CLEAR_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjectInfo: null
      };

    case projectConstants.GETALL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case projectConstants.GETALL_PROJECTS_SUCCESS:
      //console.log(action.projects);
      return {
        ...state,
        loading: false,
        items: action.projects,
        filterOptions: action.filterOptions
      };
    case projectConstants.GETALL_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        error: action.error
      };

    case projectConstants.GETALL_PURCHASE_ORDER_PROJECTS_REQUEST:
      return {
        ...state,
        loadingPOProjects: true
      };
    case projectConstants.GETALL_PURCHASE_ORDER_PROJECTS_SUCCESS:
      //console.log(action.projects);
      return {
        ...state,
        loadingPOProjects: false,
        projectItems: action.projects
      };
    case projectConstants.GETALL_PURCHASE_ORDER_PROJECTS_FAILURE:
      return {
        ...state,
        loadingPOProjects: false,
        projectItems: [],
        error: action.error
      };

    case projectConstants.CREATE_PROJECT_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case projectConstants.CREATE_PROJECT_SUCCESS:
      state.items.push(action.project);

      return {
        ...state,
        creating: false,
        showCreateModel: action.showModel,
      };
    case projectConstants.CREATE_PROJECT_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case projectConstants.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items && state.items.map(project =>
          project.id === action.projectId
            ? { ...project, updating: true }
            : project
        ),
        error: null
      };
    case projectConstants.UPDATE_PROJECT_SUCCESS:
      let newItems = null;
      let newProjectItems = null;
      if (state.items) {
        newItems = [...state.items];
        const index = state.items.findIndex(project => project.id === action.projectId);
        newItems[index] = { ...newItems[index], ...action.project }
      }
      if (state.projectItems) {
        newProjectItems = [...state.projectItems];
        const index = state.projectItems.findIndex(project => project.id === action.projectId);
        newProjectItems[index] = { ...newProjectItems[index], ...action.project }
      }
      const currentProjectInfo = state.selectedProjectInfo && action.project.id === state.selectedProjectInfo.id ? { ...state.selectedProjectInfo, ...action.project } : state.selectedProjectInfo
      return {
        ...state,
        updating: false,
        items: newItems,
        projectItems:newProjectItems,
        showCreateModel: false,
        selectedProjectInfo: currentProjectInfo,
      };
    case projectConstants.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items && state.items.map(project => {
          if (project.id === action.projectId) {
            const { updating, ...projectCopy } = project;
            return { ...projectCopy, deleteError: action.error };
          }
          return project;
        }),
        error: action.error
      };
    case projectConstants.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        items: state.items && state.items.map(project =>
          project.id === action.projectId
            ? { ...project, deleting: true }
            : project
        )
      };
    case projectConstants.DELETE_PROJECT_SUCCESS:
      // remove deleted project from state
      return {
        ...state,
        items: state.items && state.items.map(project => {
          if (project.id === action.projectId) {
            const { deleting, ...projectCopy } = project;
            return { ...projectCopy, deleted: action.isDelete };
          }

          return project;
        }),
        projectItems: state.projectItems && state.projectItems.map(project => {
          if (project.id === action.projectId) {
            const { deleting, ...projectCopy } = project;
            return { ...projectCopy, deleted: action.isDelete };
          }

          return project;
        })
      };
    case projectConstants.DELETE_PROJECT_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to project 
      return {
        ...state,
        items: state.items && state.items.map(project => {
          if (project.id === action.projectId) {
            // make copy of project without 'deleting:true' property
            const { deleting, ...projectCopy } = project;
            // return copy of project with 'deleteError:[error]' property
            return { ...projectCopy, deleteError: action.error };
          }

          return project;
        })
      };

    case projectConstants.REMOVE_PROJECT_SUCCESS:
      return {
        ...state,
        items: state.items && state.items.filter(project => project.id !== action.projectId),
        projectItems: state.projectItems && state.projectItems.filter(project => project.id !== action.projectId)
      };

    case projectConstants.ARCHIVE_PROJECT_SUCCESS:
      return {
        ...state,
        items: state.items && state.items.map(project =>
          project.id === action.projectId
            ? { ...project, archived: action.isArchive }
            : project
        ),
        projectItems: state.projectItems && state.projectItems.map(project =>
          project.id === action.projectId
            ? { ...project, archived: action.isArchive }
            : project
        )
      };

    case projectConstants.ADD_TO_FAVORITES_PROJECT_SUCCESS:
      return {
        ...state,
        items: state.items && state.items.map(project =>
          project.id === action.projectId
            ? { ...project, favorite: action.isFavorite }
            : project
        ),
        projectItems:  state.projectItems && state.projectItems.map(project =>
          project.id === action.projectId
            ? { ...project, favorite: action.isFavorite }
            : project
        )
      };

    case projectConstants.SHOW_PROJECT_CREATE_WINDOW:
      return {
        ...state,
        showCreateModel: true
      };

    case projectConstants.CLOSE_PROJECT_CREATE_WINDOW:
      const { selectedProjectInfo, ...stateCopy } = state;
      return {
        ...stateCopy,
        showCreateModel: false
      };


    case projectConstants.GET_PROJECT_REQUEST:
      return {
        ...state,
        selectedProjectInfo: null,
        getInfoLoading: true
      };
    case projectConstants.GET_PROJECT_SUCCESS:
      //console.log(action.project);
      return {
        ...state,
        getInfoLoading: false,
        selectedProjectInfo: action.project
      };
    case projectConstants.GET_PROJECT_FAILURE:
      return {
        ...state,
        getInfoLoading: false,
        error: action.error
      };

    case projectConstants.INITIALIZE_PROJECTS_USER_TEAMS:
      return {
        ...state,
        userTeams: action.userTeams
      }
    case projectConstants.CHANGE_FILTER_PROJECTS:
      return {
        ...state,
        filterOptions: action.filterOptions
      };

    case projectConstants.GETALL_PROJECTS_TIMESHEET_REQUEST:
      return {
        ...state,
        projectsForTimesheetLoading: true,
        projectsForTimesheet: null,
      };
    case projectConstants.GETALL_PROJECTS_TIMESHEET_SUCCESS:
      //console.log(action.projects);
      return {
        ...state,
        projectsForTimesheetLoading: false,
        projectsForTimesheet: action.projects
      };
    case projectConstants.GETALL_PROJECTS_TIMESHEET_FAILURE:
      return {
        ...state,
        projectsForTimesheetLoading: false,
        projectsForTimesheet: null,
        error: action.error
      };

    case projectConstants.SHOW_PROJECT_VIEW_WINDOW:
      return {
        ...state,
        isShowProjectViewSidebar: true
      }
    case projectConstants.CLOSE_PROJECT_VIEW_WINDOW:
      return {
        ...state,
        isShowProjectViewSidebar: false
      }


    default:
      return state
  }
}