import React, { PureComponent } from "react";
import PageInnerHeaderTeamsSelectionMenu from "./fragments/PageInnerHeaderTeamsSelectionMenu";
import PageInnerHeaderFilterOptionsMenu from "./fragments/PageInnerHeaderFilterOptionsMenu";
import { validationUtils } from "_helpers";
import PageInnerHeaderSearchDateMenu from "./fragments/PageInnerHeaderSearchDateMenu";
import PageInnerHeaderEmployeesSelectionMenu from "./fragments/PageInnerHeaderEmployeesSelectionMenu";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import urlConfig from "url.config";

class PageInnerHeader extends PureComponent {

    onFilterTextChange = (value) => {
        let isValidChar = validationUtils.validateAlphaNumbericWithSpecial(value);
        if (isValidChar) {
            this.props.onFilterTextChange(value)
        }
    }

    downloadReport = (event) => {
        const userTeamIds = commonUtils.getSelectedUserTeamIds(this.props.userTeams)
        const startDate = this.props.filterOptions.dateRangeValue.start.format(dateUtils.SERVER_DATE_FORMAT);
        const endDate = this.props.filterOptions.dateRangeValue.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
        let url = urlConfig.REPORT_DOWNLOAD + `/${this.props.filterOptions.REPORT.selected}/key?userTeamIds=${userTeamIds}&startDate=${startDate}&endDate=${endDate}`;
        if (this.props.filterOptions.REPORT.selected === 'myUtilization' || this.props.filterOptions.REPORT.selected === 'myTimesheetData' || this.props.filterOptions.REPORT.selected === 'myTimesheetDataDump') {
            url = url + "&employeeIds=" + [commonUtils.getCurrentUserId()]
        }
        event.target.href = url;

    }

    render() {
        return (
            <div className={`section-body listgridheader ${this.props.stickytop === undefined || this.props.stickytop === true ? "stickytop" : ''} border-bottom`}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pt-2">

                            {this.props.showUserTeamMenu && this.props.userTeams &&
                                <PageInnerHeaderTeamsSelectionMenu
                                    userTeams={this.props.userTeams}
                                    handleUserTeamsSelection={this.props.handleUserTeamsSelection}
                                    isLoading={this.props.isLoading}
                                    enabled={(this.props.permission && this.props.permission.switchUserTeamsEnabled) || false}
                                    enableSingleSelection={this.props.enableUserTeamSingleSelection}
                                />

                            }
                            {this.props.showEmployeeMenu && this.props.employees && this.props.employees.length > 0 &&
                                <PageInnerHeaderEmployeesSelectionMenu
                                    employees={this.props.employees}
                                    handleEmployeesSelection={this.props.handleEmployeesSelection}
                                    isLoading={this.props.isLoading}
                                    enabled={(this.props.permission && this.props.permission.switchEmployeesEnabled) || false}
                                    enableSingleSelection={this.props.enableUserTeamSingleSelection}
                                    showEmployeeNameDropDownLabel={this.props.showEmployeeNameDropDownLabel}
                                />
                            }
                            {this.props.filterOptions &&
                                <PageInnerHeaderFilterOptionsMenu
                                    filterOptions={this.props.filterOptions}
                                    handleFilterChange={this.props.handleFilterChange}
                                    isLoading={this.props.isLoading}
                                />
                            }
                        </div>
                        {this.props.enableCalendarControl === true &&
                            <div className="pt-2">
                                <ul className="nav nav-tabs page-header-tab pl-1">
                                    <li className="nav-item pl-0">
                                        <button className="link " onClick={this.props.calendarControls.goToBack}><i className="fa fa-chevron-left" /></button></li>
                                    <li className="nav-item pl-0">
                                        <button className="link " onClick={this.props.calendarControls.goToCurrent}><i className="fa fa-home" /></button></li>
                                    <li className="nav-item pl-0">
                                        <button className="link " onClick={this.props.calendarControls.goToNext}><i className="fa fa-chevron-right" /></button></li>
                                </ul>
                            </div>
                        }

                        <div className="header-action d-md-flex pt-0">
                            {this.props.showDateRangeSelector &&
                                <PageInnerHeaderSearchDateMenu
                                    defaultDateRangeValue={this.props.filterOptions && this.props.filterOptions.dateRangeValue}
                                    handleDateRangeChange={this.props.handleDateRangeChange}
                                    searchViewEnabled={this.props.searchViewEnabled}
                                    handleSearchSubmit={this.props.handleSearchSubmit}
                                    searchViewOpitons={this.props.searchViewOpitons}
                                    defaultSearchViewOpiton={this.props.defaultSearchViewOpiton}
                                    isLoading={this.props.isLoading}
                                />
                            }
                            {this.props.showDateActionButtons &&
                                <div className="header-action d-md-flex">
                                    {this.props.filterOptions && this.props.filterOptions.REPORT.options.find(report => report.name === this.props.filterOptions.REPORT.selected).viewEnabled === true &&
                                        <button onClick={this.props.handleView} className="link create-link pt-0 xs-hide px-2 border mr-2 py-1 ml-3"><i className="fa fa-list-alt mr-1" />View</button>
                                    }
                                    <a onClick={(event) => this.downloadReport(event)} target="_new" className="link create-link mt-1 xs-hide px-2 border mr-3 py-1 colororange"><i className="fa fa-download mr-1 colororange" />Download</a>
                                </div>
                            }
                            <div className="header-action d-md-flex pt-2 pl-5">

                                {this.props.showFilter &&
                                    <div className="input-icon"><span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                                        <input type="text" disabled={this.props.isLoading} className="form-control input-sm filter" placeholder="Filter..."
                                            value={(this.props.filterOptions && this.props.filterOptions.filterText) || ''}
                                            onChange={(event) => this.onFilterTextChange(event.target.value)}
                                        />
                                    </div>
                                }

                                {this.props.showGridListMenu &&
                                    <ul className="nav nav-tabs page-header-tab pl-2 pr-5 xs-hide mr-0">
                                        <li className="nav-item pr-0">
                                            <button id="Tab-View" className={`link grid-view-link ${this.props.showGridDefault ? 'active' : ''} `} data-toggle="tab" onClick={this.props.gridClickHandler}><i className="fe fe-grid mr-0" />Grid</button></li>
                                        <li className="nav-item pl-2 pr-2">
                                            <button id="Tab-View" className={`link list-view-link ${this.props.showGridDefault ? '' : 'active'} `} data-toggle="tab" onClick={this.props.listClickHandler}><i className="fe fe-list mr-0" />List</button></li>
                                    </ul>
                                }
                                {!this.props.showGridListMenu &&
                                    <span className="pr-4 mr-4"></span>
                                }
                                {this.props.showCreateMenu &&
                                    <button onClick={this.props.createItemHandler} className="link create-link pt-0 xs-hide pr-3 pl-3"><i className={`fe ${this.props.createMenuIcon || 'fe-plus-circle'} mr-1`} />{this.props.showCreateMenuText}</button>
                                }
                                {this.props.additionalButtons &&
                                    <>
                                        {this.props.additionalButtons}
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default PageInnerHeader