import React, { Component } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css"
import moment from 'moment'
import { workItemActions } from "_actions";
import { connect } from "react-redux";
import { store } from "_helpers";
import { workItemConstants } from "_constants";
import PageInnerHeaderTeamsSelectionMenu from "views/common/fragments/PageInnerHeaderTeamsSelectionMenu";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import { accessUtils } from "_helpers";
//http://jquense.github.io/react-big-calendar/examples/index.html#resource
const localizer = momentLocalizer(moment)



class UserCalendarPage extends Component {
  constructor() {
    super()
    this.state = {
      weekendsVisible: true,
      permission: accessUtils.getUserCalendarAccessControl()
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    let viewMode = store.getState().workItem.viewMode ? store.getState().workItem.viewMode : 'WORKITEMS';
    let selectedUserTeams = commonUtils.getSelectedUserTeamIds(userTeams);

    store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_FULLCALENDAR, selectedUserTeams, viewMode });
    let startDate = dateUtils.getDefaultTodayDateRange().start.format(dateUtils.SERVER_DATE_FORMAT);
    let endDate = dateUtils.getDefaultTodayDateRange().end.format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getWorkItemActivitiesForCalendar({ userTeamIds: selectedUserTeams, startDate, endDate }))
  }

  event = ({ event }) => {
    return (
      <span className={`${event.status === 'COMPLETED' ? 'event-completed' : event.status === 'INPROGRESS' ? 'event-inprogress' : event.status === 'ASSIGNED' ? 'event-assigned' : 'event-new'}`}>
        <span className="fsize11">{event.title}</span>
        {event.desc && ':  ' + event.desc}
      </span>
    )
  }

  eventAgenda = ({ event }) => {
    return (
      <span>
        <em style={{ color: 'magenta' }}>{event.title}</em>
        <p>{event.desc}</p>
      </span>
    )
  }

  customDayPropGetter = date => {
    if (date.getDate() === 7 && date.getDate() === 15)
      return {
        className: 'special-day',
        style: {
          border: 'solid 3px ' + (date.getDate() === 7 ? '#faa' : '#afa'),
          backgroundColor: 'red',
        },
      }
    else return {}
  }

  customSlotPropGetter = date => {
    if (date.getDate() === 7 && date.getDate() === 15)
      return {
        className: 'special-day',
      }
    else return {}
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = '#' + (event.status === 'COMPLETED' ? '0f0' : event.status === 'INPROGRESS' ? '00f' : event.status === 'ASSINGED' ? 'ff0' : '0ff');
    var style = {
      backgroundColor: backgroundColor,
      border: '1px solid #000',
      opacity: 0.8,
      color: 'black',
      display: 'block'
    };
    return {
      style: style
    };
  }
  onDoubleClickEvent = () => {
    //console.log("onDoubleClickEvent")
  }

  onSelectEvent = (event) => {
    //console.log("onSelectEvent")
    //console.log(event)
  }

  onNavigate = (date) => {
    //console.log("onNavigate")
    //console.log(date)
  }

  onRangeChange = (date) => {
    //console.log("onRangeChange")
    //console.log(date)
  }

  onView = (date) => {
    //console.log("onView")
    //console.log(date)
  }

  render() {
    return (
      <div className="siteActivity">
        <div className="section-body mt-1 px-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card px-1">
                  <div className="card-body fullpagecalendar">
                    <Calendar
                      localizer={localizer}
                      events={this.props.events}
                      startAccessor="start"
                      endAccessor="end"
                      defaultView="week"
                      style={{ height: 600 }}
                      onSelectEvent={this.onSelectEvent}
                      onDoubleClickEvent={this.onDoubleClickEvent}
                      onNavigate={this.onNavigate}
                      onView={this.onView}
                      onRangeChange={this.onRangeChange}
                      components={{
                        toolbar: CustomToolbar,
                        event: this.event,
                        agenda: {
                          event: this.eventAgenda,
                        }
                      }}

                      dayPropGetter={this.customDayPropGetter}
                      slotPropGetter={this.customSlotPropGetter}
                      eventPropGetter={(this.eventStyleGetter)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  events: commonUtils.convertWorkItemsForCalendar(state.workItem.workItemsCalendar, state.workItem.viewMode),
  userTeams: state.workItem.userTeamsFC,
  viewMode: state.workItem.viewMode
});

const mapDispatchToProps = dispatch => ({
  getWorkItemActivitiesForCalendar: workItemActions.getWorkItemActivitiesForCalendar
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCalendarPage);


const CustomToolbar = (toolbar) => {
  let viewMode = store.getState().workItem.viewMode ? store.getState().workItem.viewMode : 'WORKITEMS';
  const goToBack = () => {
    let startDateMoment = dateUtils.convertDateToMoment(toolbar.date);
    if (toolbar.view === 'month') {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    } else if (toolbar.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    } else if (toolbar.view === 'day') {
      toolbar.date.setDate(toolbar.date.getDate() - 1);
    } else if (toolbar.view === 'agenda') {
      toolbar.date.setDate(toolbar.date.getDate() - 30);
    }
    toolbar.onNavigate('prev');
    realodTaskActiviesFullCaledar();
  };

  const goToNext = () => {

    if (toolbar.view === 'month') {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    } else if (toolbar.view === 'week') {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    } else if (toolbar.view === 'day') {
      toolbar.date.setDate(toolbar.date.getDate() + 1);
    } else if (toolbar.view === 'agenda') {
      toolbar.date.setDate(toolbar.date.getDate() + 30);
    }
    toolbar.onNavigate('next');
    realodTaskActiviesFullCaledar();
  };

  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.date.setDate(now.getDate());
    toolbar.onNavigate('current');
    realodTaskActiviesFullCaledar();
  };
  const changeCalendarView = (viewName) => {
    toolbar.onView(viewName);
    realodTaskActiviesFullCaledar(null, viewName);
  };

  const changeViewMode = (mode) => {
    if (viewMode !== mode) {
      viewMode = mode;
      let currentUserTeams = commonUtils.getUserTeams(store.getState().workItem.userTeamsFC)
      store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_FULLCALENDAR, userTeams: currentUserTeams, viewMode });
    }
  }

  const onChangeTeam = (currentUserTeams) => {
    store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_FULLCALENDAR, userTeams: currentUserTeams, viewMode });
    realodTaskActiviesFullCaledar(currentUserTeams);
  }

  const realodTaskActiviesFullCaledar = (currentUserTeams, calendarView) => {

    let startDateMoment = dateUtils.convertDateToMoment(toolbar.date);
    let startDate = null;
    let endDate = null;
    let toolBarView = calendarView ? calendarView : toolbar.view;
    if (toolBarView === 'month') {
      startDate = startDateMoment.clone().startOf('month')
      endDate = startDateMoment.clone().endOf('month')
    } else if (toolBarView === 'week') {
      startDate = startDateMoment.clone().startOf('week')
      endDate = startDateMoment.clone().endOf('week')
    } else if (toolBarView === 'day') {
      startDate = startDateMoment;
      endDate = startDateMoment;
    } else if (toolBarView === 'agenda') {
      startDate = startDateMoment;
      endDate = startDateMoment.clone().endOf('month')
    }

    let selectedUserTeams = commonUtils.getSelectedUserTeamIds(currentUserTeams ? currentUserTeams : commonUtils.getUserTeams(store.getState().workItem.userTeamsFC));
    store.dispatch(workItemActions.getWorkItemActivitiesForCalendar({ userTeamIds: selectedUserTeams, startDate: dateUtils.convertDateToMoment(startDate).format(dateUtils.SERVER_DATE_FORMAT), endDate: dateUtils.convertDateToMoment(endDate).format(dateUtils.SERVER_DATE_FORMAT) }));
  }

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
    );
  };

  return (

    <div className="section-body listgridheader stickytop">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="pt-2 d-flex">
            <PageInnerHeaderTeamsSelectionMenu
              userTeams={commonUtils.getUserTeams(store.getState().workItem.userTeamsFC)}
              handleUserTeamsSelection={onChangeTeam}
              isLoading={false}
              enabled={true}
            />
            <span className="text-muted font-weight-light">Show : </span>

            <ul className="nav nav-tabs page-header-tab pl-2">
              <li className="nav-item pr-0">
                <button className={`link ${viewMode === 'PROJECT' ? 'active' : 'muted'}`} onClick={() => changeViewMode("PROJECT")}><i className="fa fa-newspaper-o mr-0" /> Projects</button>
              </li>
              <li className="nav-item pl-2 pr-2">
                <button className={`link ${viewMode === 'DELIVERABLES' ? 'active' : 'muted'}`} onClick={() => changeViewMode("DELIVERABLES")}><i className="fa fa-list-alt mr-0" /> Deliverables</button>
              </li>
              <li className="nav-item pl-2 pr-2">
                <button className={`link ${viewMode === 'WORKITEMS' ? 'active' : 'muted'}`} onClick={() => changeViewMode("WORKITEMS")}><i className="fa fa-server mr-0" /> Work Items</button>
              </li>
            </ul>

          </div>
          <div className="pt-2 d-flex">
            <ul className="nav nav-tabs page-header-tab pl-2">
              <li className="nav-item pl-0">
                <button className="link " onClick={goToBack}><i className="fa fa-chevron-left" /></button></li>
              <li className="nav-item pl-0">
                <button className="link " onClick={goToCurrent}><i className="fa fa-home" /></button></li>
              <li className="nav-item pl-0">
                <button className="link " onClick={goToNext}><i className="fa fa-chevron-right" /></button></li>
            </ul>
            <span>{toolbar.label}</span>
          </div>
          <div className="header-action d-md-flex pt-0">

            <div className="header-action d-md-flex pt-2">

              <ul className="nav nav-tabs page-header-tab pl-2">

                <li className="nav-item pr-0">
                  <button className={`link ${toolbar.view === 'month' ? 'active' : 'muted'}`} onClick={() => changeCalendarView("month")}><i className="fe fe-grid mr-0" /> Month</button>
                </li>
                <li className="nav-item pl-2 pr-2">
                  <button className={`link ${toolbar.view === 'week' ? 'active' : 'muted'}`} onClick={() => changeCalendarView("week")}><i className="fa fa-indent mr-0" /> Week</button>
                </li>

                <li className="nav-item pl-2 pr-2">
                  <button className={`link ${toolbar.view === 'day' ? 'active' : 'muted'}`} onClick={() => changeCalendarView("day")}><i className="fa fa-list-ul mr-0" /> Day</button>
                </li>
                <li className="nav-item pl-2 pr-2">
                  <button className={`link ${toolbar.view === 'agenda' ? 'active' : 'muted'}`} onClick={() => changeCalendarView("agenda")}><i className=" fa fa-bars mr-0" /> DayList</button>
                </li>
              </ul>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

/*
<div className={'toolbar-container'}>
              <label className={'label-date'}>{label()}</label>

              <div className={'back-next-buttons'}>
                <button className={'btn-back'} onClick={goToBack}>&#8249;</button>
                <button className={'btn-current'} onClick={goToCurrent}>today</button>
                <button className={'btn-next'} onClick={goToNext}>&#8250;</button>
              </div>
              <div className={'back-next-buttons'}>
                <button className={'btn-back'} onClick={goToMonthView}>Month</button>
                <button className={'btn-current'} onClick={goToWeekView}>Week</button>
                <button className={'btn-next'} onClick={goToDayView}>Day</button>
              </div>
            </div >
*/

/*


*/