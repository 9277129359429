import { projectConstants } from '../_constants';

let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
]
let reportConfig = [
  { name: 'default', label: 'Default' },
]
export function reports(state = {}, action) {
  
  switch (action.type) {

    case projectConstants.GETALL_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        metaInfo: null
      }

    case projectConstants.GETALL_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.reports.data,
        metaInfo: action.reports.metaInfo
      }

    case projectConstants.GETALL_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        metaInfo: null
      }
      case projectConstants.INITIALIZE_REPORTS_USER_TEAMS:
      return {
        ...state,
        userTeams: action.userTeams
      }
      case projectConstants.CHANGE_FILTER_REPORTS:
      return {
        ...state,
        filterOptions: action.filterOptions
      };

      case projectConstants.INITIALIZE_CREATE_REPORTCONFIG_REQUEST:
      return {
        ...state,
        fieldConfig: fieldConfig,
        createInitializing: true,
        showCreateModel: true,
        selectedReportConfigInfo: null
      };
    case projectConstants.INITIALIZE_CREATE_REPORTCONFIG_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        reportConfig: reportConfig
      };
    case projectConstants.INITIALIZE_CREATE_REPORTCONFIG_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };
      case projectConstants.CLOSE_REPORTCONFIG_WINDOW:
        const { selectedProjectInfo, ...stateCopy } = state;
        return {
          ...stateCopy,
          showCreateModel: false
        };
    default:
      return state
  }
}