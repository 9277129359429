//https://www.syncfusion.com/react-ui-components/react-kanban-board
//https://stackoverflow.com/questions/36379184/html5-draggable-hide-original-element

import React, { Component } from "react";
import { ModalManager } from "views/common/plugins/model/Modal";
import WorkItemTimer from "views/common/plugins/WorkItemTimer";
import dateUtils from "_helpers/date.utils";
import { accessUtils } from "_helpers";


/*
 * The Kanban Board React component
 */
let columns = [
	{ name: 'Planned', stage: 1, canDrop: false },
	{ name: 'To Do', stage: 2, canDrop: false },
	{ name: 'In Progress', stage: 3, canDrop: false },
	{ name: 'Completed', stage: 4, canDrop: false },
];
class TaskBoardGrid extends Component {

	constructor(props) {
		super(props);
		this.state = ({
			draggedOverCol: 0,
		});
		//this.handleOnDragEnter = this.handleOnDragEnter.bind(this);
		//this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
	}

	componentDidMount() {
		//this.setState({ tasks: this.props.workItemList });
	}

	//this is called when a Kanban card is dragged over a column (called by column)
	handleOnDragEnter = (stageValue) => {
		this.setState({ draggedOverCol: stageValue });
	}

	handleOnDragStart = (workItem) => {
		workItem.dragging = true;
		columns = columns.map(column => {
			column.stage > workItem.stage ? column.canDrop = true : column.canDrop = false;
			return column;
		});
	}

	//this is called when a Kanban card dropped over a column (called by card)
	handleOnDragEnd = (workItem) => {
		workItem.dragging = false;
		columns = columns.map(column => {
			column.canDrop = false;
			return column;
		});
		let draggedOverCol = this.state.draggedOverCol;
		if (this.state.draggedOverCol < workItem.stage) {
			draggedOverCol = workItem.stage;
		}
		if (workItem.stage === 1 && this.state.draggedOverCol === 2) {
			this.props.updateTaskActivityStatus(workItem, "READY_TO_START");
		} else if (((workItem.stage === 1 || workItem.stage === 2) && this.state.draggedOverCol === 3) || ((workItem.stage === 1 || workItem.stage === 2 || workItem.stage === 3) && this.state.draggedOverCol === 4)) {
			this.props.showTaskStatusUpdateModel(workItem, draggedOverCol === 4 ? "COMPLETED" : "INPROGRESS", false, () => { });
		} else {
			this.setState({ draggedOverCol: 0 });
			ModalManager.close();
		}
	}
	handlePlayWorkItem = (workItem, callbackFunction) => {
		this.props.showTaskStatusUpdateModel(workItem, "COMPLETED", true, callbackFunction);
	}

	render() {

		return (
			<div className="row clearfix">
				{columns.map((column) => {
					return (
						<KanbanColumn
							column={column}
							workItems={this.props.workItems && this.props.workItems.filter((workItem) => { return (parseInt(workItem.stage, 10) === column.stage || (column.stage === 2 && workItem.status === 'READY_TO_START')); })}
							onDragEnter={this.handleOnDragEnter}
							onDragStart={this.handleOnDragStart}
							onDragEnd={this.handleOnDragEnd}
							onPause={this.props.onPause}
							onPlay={this.handlePlayWorkItem}
							onViewProject={this.props.onViewProject}
							onViewTask={this.props.onViewTask}
							key={column.stage}
						/>
					);
				})}
			</div>
		);
	}
}

/*
 * The Kanban Board Column React component
 */
class KanbanColumn extends React.Component {
	constructor(props) {
		super(props);
		this.state = ({ mouseIsHovering: false });

	}

	componentWillReceiveProps(nextProps) {
		this.setState({ mouseIsHovering: false });
	}

	render() {

		return (
			<div className="col-lg-3 pr-0 tasks-section" onDragEnter={(e) => { this.setState({ mouseIsHovering: true }); this.props.onDragEnter(this.props.column.stage); }}
				onDragExit={(e) => { this.setState({ mouseIsHovering: false }); }}
			>
				<div className={`card ${this.props.column.stage === 1 ? 'planned' : this.props.column.stage === 3 ? 'progress' : this.props.column.stage === 4 ? 'completed' : 'todo'}_task`}>
					<div className="card-header py-2 border-bottom">
						<h3 className="card-title">{this.props.column.name}</h3>
						<div className="card-options">
							<button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up" /></button>
							<button className="link card-options-fullscreen" data-toggle="card-fullscreen"><i className="fe fe-maximize" /></button>
						</div>
					</div>
					<div className={`card-body min-vh-100 ${this.props.column.canDrop ? 'droppable' : ''}`}>
						<div className="dd" data-plugin="nestable">
							<ul className="dd-list pl-1 list-unstyled">
								{this.props.column.canDrop &&
									< span className="align-items-center mb-2 d-flex dropplaceholder">
										<span className="w-100 text-center text-muted fsize17">Drop here</span>
									</span>
								}
								{this.props.workItems && this.props.workItems.map((workItem) =>
									<KanbanCard
										workItem={workItem}
										key={workItem.id}
										onDragStart={this.props.onDragStart}
										onDragEnd={this.props.onDragEnd}
										onPause={this.props.onPause}
										onPlay={this.props.onPlay}
										onViewProject={this.props.onViewProject}
										onViewTask={this.props.onViewTask}
									/>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

/*
 * The Kanban Board Card component
 */
class KanbanCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: true,
			permission: accessUtils.getTaskBoardAccessControl(),
		};
	}

	render() {
		return (
			<li className={`dd-item mr-1 ${this.props.workItem.dragging === true ? 'd-none' : ''}`} data-id={1}
				draggable={true}
				onDragStart={(e) => { this.props.onDragStart(this.props.workItem); }}
				onDragEnd={(e) => { this.props.onDragEnd(this.props.workItem); }}
			>

				<div className="dd-handle mb-3">
					<div className="workItem-details">
						<div className="p-1">
							<span className="fsize13 fweight600">{this.props.workItem.name}</span>
							<p className="py-0 my-0 fsize13">{this.props.workItem.description}</p>
						</div>
						<div className="d-flex justify-content-between align-items-center pb-1 ">
							<span className="badge badge-dim badge-warning">{this.props.workItem.taskActCategory}</span>

						</div>
					</div>
					<div className="d-flex justify-content-between align-items-center pb-1 border-top">
						<span className="mx-2 fsize10">
							<i className="fa fa-calendar fsize10 text-muted"><span className="pl-1">
								{dateUtils.convertDateTimeFormat(this.props.workItem.actualStartTime ? this.props.workItem.actualStartTime : this.props.workItem.estStartTime)}
							</span></i>
						</span>
						{this.props.workItem.taskType === "PROJECT" &&
							<span className="fsize11 actions-items">
								<button className="link" onClick={() => this.props.onViewProject(this.props.workItem.projectId)}>
									<i className="fa fa-eye"></i> </button>
								<button className="link mx-2" onClick={() => this.props.onViewTask(this.props.workItem.taskId, this.props.workItem.projectId)}>
									<i className="fa fa-newspaper-o"></i> </button>
								<button className="link">
									<i className=" fa fa-user"></i> </button>
							</span>
						}
						{
							this.props.workItem.stage === 3 ?
								<WorkItemTimer
									workItem={this.props.workItem}
									onPause={this.props.onPause}
									onPlay={this.props.onPlay}
									startTime={dateUtils.convertDateStringToDate(this.props.workItem.actualStartTime)}
									pausedTime={this.props.workItem.status === 'PAUSED' ? dateUtils.convertDateStringToDate(this.props.workItem.taskPausedTime) : null}
									workItemPausedTime={this.props.workItem.status === 'PAUSED' ? dateUtils.convertDateStringToDate(this.props.workItem.taskActivityPausedTime) : null} /> :
								<span className="mx-2 fsize10">
									<i className="fa fa-clock-o fsize10 text-muted">
										{this.props.workItem.stage === 4 ? <span className="pl-1"> {dateUtils.minuteToHourString(this.props.workItem.durationInMins) || '0h 0m'}</span> 
											: <span className="pl-1"> {this.state.permission.showWorkItemEstimatedHours === true && (dateUtils.minuteToHourString(this.props.workItem.estDurationInMins) || '0h 0m')}</span>}
									</i>
								</span>
						}
					</div>

					{this.props.workItem.stage === 3 &&
						<div className="progress progress-xs">
							<div className="progress-bar bg-red" role="progressbar" style={{ width: '15%' }} aria-valuenow={36} aria-valuemin={0} aria-valuemax={100} />
						</div>
					}

				</div>
			</li>
		);
	}
}
export default TaskBoardGrid;
