import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import ProjectInfoLine from "./ProjectInfoLine";

class ProjectListTable extends Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table_custom table-nowrap table-vcenter table-borderless position-relative">
          <thead>
            <tr>
              <th></th>
              <th>#</th>
              <th>Created Date</th>
              <th className="w-250">Project Name</th>
              <th className="w-100"> Account</th>
              <th className="w-100">Team/Client</th>
              <th className="text-center">Tasks</th>
              <th className="text-center">Actual/Est Hrs</th>
              <th>Status</th>
              <th>Date</th>
              <th className="text-center">Action
                <button type="button" className="link pr-2 float-right" title="Refresh" onClick={() => this.props.reloadProjectList()}>
                  <i className="fe fe-refresh-cw"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.isLoading &&
              <tr>
                <td colSpan="12">
                  <Skeleton count={7} height={40} />
                </td>
              </tr>
            }

            {!this.props.isLoading && this.props.items && this.props.items.map(item =>
              <ProjectInfoLine item={item} key={item.id}
                handleDeleteProject={this.props.handleDeleteProject}
                handleEditProject={this.props.handleEditProject}
                handleUpdateStatus={this.props.handleUpdateStatus}
                handleDuplicateProject={this.props.handleDuplicateProject}
                handleArchiveProject={this.props.handleArchiveProject}
                handleRemoveProject={this.props.handleRemoveProject}
                handleFavoriteProject={this.props.handleFavoriteProject}
                onViewProject={this.props.onViewProject}
                permission={this.props.permission}
              />
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
export default ProjectListTable;