export const itemAdditionalConstants = {
    GETALL_ITEM_ADDITIONALS_REQUEST: 'GETALL_ITEM_ADDITIONALS_REQUEST',
    GETALL_ITEM_ADDITIONALS_SUCCESS: 'GETALL_ITEM_ADDITIONALS_SUCCESS',
    GETALL_ITEM_ADDITIONALS_FAILURE: 'GETALL_ITEM_ADDITIONALS_FAILURE',

    CREATE_ITEM_ADDITIONAL_REQUEST: 'CREATE_ITEM_ADDITIONAL_REQUEST',
    CREATE_ITEM_ADDITIONAL_SUCCESS: 'CREATE_ITEM_ADDITIONAL_SUCCESS',
    CREATE_ITEM_ADDITIONAL_FAILURE: 'CREATE_ITEM_ADDITIONAL_FAILURE',

    UPDATE_ITEM_ADDITIONAL_REQUEST: 'UPDATE_ITEM_ADDITIONAL_REQUEST',
    UPDATE_ITEM_ADDITIONAL_SUCCESS: 'UPDATE_ITEM_ADDITIONAL_SUCCESS',
    UPDATE_ITEM_ADDITIONAL_FAILURE: 'UPDATE_ITEM_ADDITIONAL_FAILURE',
        
    REMOVE_ITEM_ADDITIONAL_REQUEST: 'REMOVE_ITEM_ADDITIONAL_REQUEST',
    REMOVE_ITEM_ADDITIONAL_SUCCESS: 'REMOVE_ITEM_ADDITIONAL_SUCCESS',
    REMOVE_ITEM_ADDITIONAL_FAILURE: 'REMOVE_ITEM_ADDITIONAL_FAILURE',

    SEARCH_INPUT_CHANGE: 'SEARCH_INPUT_CHANGE',
    GET_ALL_SEARCH_RESULT_REQUEST: 'GET_ALL_SEARCH_RESULT_REQUEST',
    GET_ALL_SEARCH_RESULT_SUCCESS: 'GET_ALL_SEARCH_RESULT_SUCCESS',
    GET_ALL_SEARCH_RESULT_FAILURE: 'GET_ALL_SEARCH_RESULT_FAILURE',
};
