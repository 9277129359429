import classNames from "classnames/bind";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import dateUtils from "_helpers/date.utils";

class  WorkItemPendingItems extends Component {
    render() {
        const colors = ['red', 'green', 'orange', 'blue', 'green', 'red', 'green', 'orange', 'blue', 'green'];
        //let colorIndex = parseInt(this.props.key) % 10;
     
        return (
            <div className="card pt-1">
                <div className="card-header pt-0 pb-0 pl-2">
                    <h3 className="card-title pl-0 text-normal-case fweight600">My Pending Tasks ({(this.props.items && this.props.items.length) || 0})</h3>
                    <div className="card-options">
                        <button className="link create-link pt-1 pr-1  fsize12" onClick={()=>this.props.reloadPendingTasks()}><i className="fa fa-refresh" /></button>

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="container-fluid ">

                            <div className="table-responsive h-200px">
                                {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
                                    <table className="table table-vcenter mb-0 table_custom spacing15">
                                        <tbody>
                                            {this.props.items && this.props.items.map((activityItem, index) =>
                                                < WorkItemPendingItem key={activityItem.id} item={activityItem} color={colors[parseInt(index) % 10]} />
                                            )}
                                            {this.props.isLoading &&
                                                <tr>
                                                    <td colSpan="11">
                                                        <Skeleton count={7} height={40} />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                }
                                {(!this.props.items || this.props.items.length === 0) && !this.props.isLoading &&
                                    <div className="text-center pt-3">
                                        <img src="/assets/images/custom/activetasksnotfound-min.PNG" />
                                        <span className="d-block fsize15"> No Pending Activities found ?</span>
                                        <span className="d-block fsize12 text-muted"> Try Creating a work item from project deliverables</span>
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


class WorkItemPendingItem extends Component {
    render() {
        return (
            <tr>
                <td className="pt-0 pl-3">
                    <i className={classNames("float-right",
                        { "fa fa-clock-o colorblue": this.props.item.status === 'NEW' },
                        { "fa fa-spinner colororange": this.props.item.status === 'ASSIGNED' },
                        { "fa fa-pause colorred": this.props.item.status === 'PAUSED' },
                        { "fa fa-spinner fa-spin colororange": this.props.item.status === 'INPROGRESS' },
                        { "fe  fe-check-square colorgreen": this.props.item.status === 'COMPLETED' },
                        { "fe fe-check-square colorgreen": this.props.item.status === 'CLOSED' }
                    )}></i>
                    <h6 className="mb-0 fsize13 fweight600">{this.props.item.name}</h6>
                    <p className="text-muted pb-0 mb-1">{this.props.item.taskName}</p>
                    <span className="pr-3"><i className="fa fa-calendar pr-1" />Start <span>{dateUtils.convertDateTimeFormat(this.props.item.estStartTime)}</span> </span>
                    <span><i className="fa fa-calendar-check-o pr-1" />Due to <span className={`color${this.props.color}`}>{dateUtils.convertDateFormat(this.props.item.taskEstCompletionTime || this.props.item.estStartTime)}</span> </span>
                </td>
            </tr>
        );
    }
}


export default  WorkItemPendingItems;