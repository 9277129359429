
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const workItemService = {
    
    getAllWorkItems,
    getWorkItemInfo,
    createWorkItem,
    updateWorkItemStatus,
    addTimesheetData,
    loadTimeSheetData,

    updateWorkItem,
    updateWorkItemWithStatus,
    playWorkItem,
    pauseWorkItem,
    deleteWorkItem,
    removeWorkItem,
    getWorkItemActivitiesForCalendar,
    getWorkItemActivities,
    getWorkItemActivitiesPending,
    getWorkItemBoardActivities,
    getProjectWorkItemActivities,
    getClonedByWorkItems
};

async function getAllWorkItems(request) {
    return await axiosUtils.get(urlConfig.TASK_WORK_ITEMS_GET_ALL + `/${request.taskId}`)
}

async function getWorkItemInfo(taskId) {
    return await axiosUtils.get(urlConfig.TASK_WORK_ITEMS_GET_INFO + `/${taskId}`)
}

async function createWorkItem(workItemRequest) {
    return await axiosUtils.post(urlConfig.TASK_WORK_ITEMS_CREATE,workItemRequest);
}

async function updateWorkItem(workItemRequest) {
    return await axiosUtils.put(urlConfig.TASK_WORK_ITEMS_UPDATE,workItemRequest);
}

async function updateWorkItemWithStatus(workItemRequest) {
    return await axiosUtils.put(urlConfig.TASK_WORK_ITEMS_UPDATE_STATUS,workItemRequest);
}

async function pauseWorkItem(workItemRequest,pauseTime) {
    return await axiosUtils.put(urlConfig.TASK_WORK_ITEM_PATH+`/pause?pauseTime=${pauseTime}`,workItemRequest);
}

async function playWorkItem(workItemRequest, playTime,isPreviousSave) {
    return await axiosUtils.put(urlConfig.TASK_WORK_ITEM_PATH+`/play/${isPreviousSave}?playTime=${playTime}`,workItemRequest);
}

async function deleteWorkItem(workItemId, isDelete) {
   return await axiosUtils.remove(urlConfig.TASK_WORK_ITEMS_DELETE+`/${workItemId}/${isDelete}`);
}

async function removeWorkItem(workItemId) {
    return await axiosUtils.remove(urlConfig.TASK_WORK_ITEMS_REMOVE+`/${workItemId}`);
 }

async function getWorkItemBoardActivities(request) {
    return getWorkItemList(urlConfig.TASK_WORK_ITEMS_BOARD_GET_ALL,request);
}
async function getWorkItemList(url, request) {
    return await axiosUtils.get(url + `?employeeIds=${request.employeeIds}&userTeamIds=${request.userTeamIds}&startDate=${request.startDate}&endDate=${request.endDate}`)
}

async function getWorkItemActivitiesPending(request) {
   return  getWorkItemList(urlConfig.TASK_WORK_ITEMS_PENDING_GET_ALL, request)
}

async function getProjectWorkItemActivities(request) {
    return getWorkItemList(urlConfig.TASK_WORK_ITEMS_TASK_GET_ALL + `/${request.projectId}`, request)
}

async function getWorkItemActivities(request) {
    return getWorkItemList(urlConfig.WORK_ITEMS_GET_ALL, request)
}

async function getWorkItemActivitiesForCalendar(request) {
    return getWorkItemList(urlConfig.TASK_WORK_ITEMS_CALEDAR_GET_ALL, request);
}

 async function addTimesheetData(taskRequest) {
    return await axiosUtils.put(urlConfig.TASK_TIMESHEET + `?userTeamIds=${taskRequest.userTeamIds}&currentEmployeeId=${taskRequest.currentEmployeeId}&employeeIds=${taskRequest.employeeIds}&weekStartDate=${taskRequest.startDate}`, taskRequest.payload);

 }
 async function loadTimeSheetData(taskRequest) {
    return await axiosUtils.get(urlConfig.TASK_TIMESHEET + `?userTeamIds=${taskRequest.userTeamIds}&employeeIds=${taskRequest.employeeIds}&startDate=${taskRequest.startDate}&endDate=${taskRequest.endDate}`);
 }

 async function updateWorkItemStatus(taskActivityId, status) {
    return await axiosUtils.put(urlConfig.TASK_WORK_ITEM_PATH + `/status/${taskActivityId}/${status}`);
}

async function getClonedByWorkItems(taskActivityId) {
    return await axiosUtils.get(urlConfig.TASK_WORK_ITEM_PATH + `/clonedby/${taskActivityId}`);
}
