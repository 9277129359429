import React, { Component } from "react";

class PurchaseOrderDetailsHeader extends Component {
    render() {
        return (
            <div className="section-body listgridheader stickytop">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="nav nav-tabs  ml-2">
                        <li className="nav-item">
                                <a className="nav-link active" id="Project-tab" data-toggle="tab" href="#Project-Details" onClick={()=>this.props.onSelectMenu('details')}>Details</a></li>
                           <li className="nav-item">
                                <a className="nav-link" id="Project-tab" data-toggle="tab" href="#Project-Dashboard">Dashboard</a></li>
                            <li className="nav-item">
                                <a className="nav-link" id="Project-tab" data-toggle="tab" href="#Project-Project" onClick={()=>this.props.onSelectMenu('projects')}>Projects</a></li>
                            <li className="nav-item">
                                <a className="nav-link" id="Project-tab" data-toggle="tab" href="#Project-ReleatedProject">Related Purchase Orders</a></li>

                        </ul>

                        <div className="header-action d-md-flex pt-0">
                            <ul className="nav nav-tabs  pl-2 mx-0">
                                <li className="nav-item">
                                    <a className="nav-link active" id="Project-tab" data-toggle="tab" href="#Project-Settings">Settings</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PurchaseOrderDetailsHeader;
