import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import { connect } from "react-redux";
import 'react-notifications/lib/notifications.css'
import routes from "routes.js";
import PageHeader from "views/common/PageHeader";
import PageFooter from "views/common/PageFooter";
import PageLeftMenubar from "views/common/PageLeftMenubar";
import PageLeftMenu from "views/common/PageLeftMenu";
import ThemeDiv from "views/common/ThemeDiv";
import UserDiv from "views/common/UserDiv";
import Notification from "../views/common/fragments/Notification";
import PageHelp from "views/common/PageHelp";
import { accessUtils } from "_helpers";
import SettingsRightSidebar from "views/common/SettingsRightSidebar";
import TodayActivitySidebar from "views/common/TodayActivitySidebar";

class MainLayout extends Component {
	constructor() {
		super()
		this.state = {
			pageHeader: '',
			permission: accessUtils.getMenuAccessControl()
		};
	}

	setPageHeader = pageHeaderName => this.setState({
		pageHeader: pageHeaderName,
	});

	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (this.state.permission && ((prop.layout === "/project" && this.state.permission.projectModules.active === true) || (prop.layout === "/hrms" && this.state.permission.hrmsModules.active === true) || (prop.layout === "/sales" && this.state.permission.salesModules.active === true)) && (this.state.permission[prop.layout.substring(1) + "Modules"][prop.path.substring(1) + "Enabled"] === true || (prop.showInMenu === false && !prop.accessControlled))) {
				return (
					<Route
						exact path={prop.layout + prop.path}
						render={props => (
							<prop.component
								{...props} routeProp={prop} pageHeaderNameChangeHandler={this.setPageHeader} />
						)}
						key={key} />
				);
			}
			else {
				return null;
			}
		});
	};

	render() {
		return <div id="main_content">
			{this.props.notification.message &&
				<Notification />
			}
			{this.props.notification.pageMessage &&
				<div className="alert alert-danger alert-dismissible custom-alert-bar">
					<button type="button" className="close" data-dismiss="alert"></button>
					<strong><i className="fa fa-exclamation-triangle colorred"></i></strong> {this.props.notification.pageMessage}
				</div>
			}
			<PageLeftMenubar {...this.props} routes={routes} />
			<PageLeftMenu {...this.props} routes={routes} menuAccessControl={this.state.permission} />
			<SettingsRightSidebar {...this.props} routes={routes} />
			<TodayActivitySidebar {...this.props} routes={routes} />
			<ThemeDiv {...this.props} routes={routes} />
			<UserDiv {...this.props} routes={routes} />
			<div className="page  justify-content-between flex-column d-flex h-100vh">
				<div className="vleft">
					<PageHeader pageName={this.state.pageHeader} showProjectActions={true} />
					<Switch>
						{this.getRoutes(routes)}
						{this.state.permission.projectModules.active === true &&
							<Redirect from="/project" to={"/project/" + this.state.permission.projectModules.defaultRedirectPage} />
						}
						{this.state.permission.hrmsModules.active === true &&
							<Redirect from="/hrms" to={"/hrms/" + this.state.permission.hrmsModules.defaultRedirectPage} />
						}
						{this.state.permission.salesModules.active === true &&
							<Redirect from="/sales" to={"/sales/" + this.state.permission.salesModules.defaultRedirectPage} />
						}
					</Switch>
				</div>
				<div className="vright">
					<PageFooter />
				</div>
			</div>
			<NotificationContainer />
			<div className="bottonrighthelpmenu no-print">
				<div className="">
					<PageHelp />
				</div>
			</div>
		</div>
	}
}

const mapStateToProps = state => ({
	notification: state.notification,
});

export default connect(
	mapStateToProps
)(MainLayout);