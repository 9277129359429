import React, { Component } from "react";
import PageInnerHeaderTeamsSelectionMenu from "./fragments/PageInnerHeaderTeamsSelectionMenu";
import dateUtils from "_helpers/date.utils";
import { commonUtils } from "_helpers";
import SelectInput from "./fragments/formfields/SelectInput";
import PageInnerHeaderEmployeesSelectionMenu from "./fragments/PageInnerHeaderEmployeesSelectionMenu";

class PageInnerHeaderTimesheet extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showTeamDropDown: false
        };
    }

    toggleTeamDropDown = (isDropdownShow) => {
        this.setState({ showTeamDropDown: isDropdownShow });
    }

    render() {
        return (
            <div className="section-body listgridheader stickytop mr-1 border-bottom">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pt-2">
                            {!this.props.isLoading && (this.props.showUserTeamMenu && this.props.userTeams) &&
                                <PageInnerHeaderTeamsSelectionMenu
                                    userTeams={this.props.userTeams}
                                    handleUserTeamsSelection={this.props.handleUserTeamsSelection}
                                    isLoading={this.props.isLoading}
                                    enabled={(this.props.permission && this.props.permission.switchUserTeamsEnabled) || false}
                                    enableSingleSelection={this.props.enableUserTeamSingleSelection}
                                />
                            }
                            <span>
                                <span className="text-muted font-weight-light"><i className="fa fa-calendar pr-1"></i>Select Week : </span>
                                <span className="pt-2">Week-{dateUtils.getWeekNumber(this.props.startOfTheWeek)}, {dateUtils.convertDateFormat(this.props.startOfTheWeek)}</span>

                                <span className="pt-0 px-2">
                                    <button disabled={(this.props.permission.enablePastTimeSheetView !== true) && (this.props.currentStartOfTheWeek.toDateString() === this.props.startOfTheWeek.toDateString())} className="link pr-2" onClick={this.props.goToBack}><i className="fa fa-chevron-left" /></button>
                                    <button disabled={this.props.currentStartOfTheWeek.toDateString() === this.props.startOfTheWeek.toDateString()} className="link pr-2" onClick={this.props.goToCurrent}><i className="fa fa-home" /></button>
                                    <button disabled={(this.props.permission.enableFutureTimeSheetView !== true) && (this.props.currentStartOfTheWeek.toDateString() === this.props.startOfTheWeek.toDateString())} className="link " onClick={this.props.goToNext}><i className="fa fa-chevron-right" /></button>
                                </span>

                                <span className="text-muted font-weight-light pl-2"><i className="fa fa-user-circle-o pr-1"></i>Employee : </span>
                                {!(this.props.showEmployeeMenu) &&
                                    <span className="pt-2 text-uppercase">{commonUtils.getCurrentUserName()}</span>
                                }
                                {this.props.showEmployeeMenu && this.props.employees && this.props.employees.length > 0 &&
                                    <PageInnerHeaderEmployeesSelectionMenu
                                        employees={this.props.employees}
                                        handleEmployeesSelection={this.props.handleEmployeesSelection}
                                        isLoading={this.props.isLoading}
                                        enabled={(this.props.permission && this.props.permission.switchEmployeesEnabled) || false}
                                        enableSingleSelection={true}
                                        showEmployeeNameDropDownLabel={true}
                                    />
                                }
                            </span>
                        </div>
                        <div className="pt-2">
                            {this.props.showCreateMenu &&
                                <button onClick={this.props.createItemHandler} className="link create-link pt-0 xs-hide pr-3 pl-3"><i className={`fe fe-plus-circle mr-1`} />Add Time Entry</button>
                            }
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default PageInnerHeaderTimesheet