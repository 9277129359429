import React, { Component } from "react";
import { commonUtils } from "_helpers";

class ItemPercentageOfCompletion extends Component {

	render() {
		return (
			<>
				<div className="clearfix">
					<div className="float-left"><strong>{commonUtils.getPercentageOfCompletion(this.props.totalCount, this.props.completedCount)}%</strong></div>
					<div className="float-right"><small className="text-muted">Progress</small></div>
				</div>
				<div className="progress progress-xs">
					<div className="progress-bar bg-azure" role="progressbar" style={{ width: `${commonUtils.getPercentageOfCompletion(this.props.totalCount, this.props.completedCount)}%` }} />
				</div>
			</>
		);
	}
}

export default ItemPercentageOfCompletion;
