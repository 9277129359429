import React, { Component } from "react";

import classNames from 'classnames/bind';

class PageInnerHeaderFilterOptionsMenu extends Component {

    onFilterOptionChange = (key, value) => {
        var alreadySelectedValue = this.props.filterOptions[key].selected;
        var directionUpValue = alreadySelectedValue === value ? !this.props.filterOptions[key].directionUp : true;
        this.props.handleFilterChange({
            ...this.props.filterOptions,
            [key]: {
                ...this.props.filterOptions[key],
                selected: value,
                directionUp: directionUpValue
            }
        }
        );
    }

    render() {
        return (
            <>
                {this.props.filterOptions
                    && Object.keys(this.props.filterOptions).map((key) => {
                        return <span key={key} className="pl-1">
                            {key !== 'filterText' && key !== 'dateRangeValue' &&
                                <>
                                    <span className="text-muted font-weight-light">{key} : </span>
                                    <span className="font-weight-bold">
                                      <span className=" d-inline-block text-truncate mt-0 align-middle mx-w-150">{this.props.filterOptions[key].options && this.props.filterOptions[key].options.length > 0 && this.props.filterOptions[key].options.find(filterOption => filterOption.name === this.props.filterOptions[key].selected).label}</span>

                                        {!this.props.isLoading &&
                                            <>
                                                <button className="link" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="fa fa-angle-down pl-1"></i>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-left dropdownrightmenu" x-placement="top-end">
                                                    <ul className="ml-0 pl-1">
                                                        {this.props.filterOptions[key].options && this.props.filterOptions[key].options.map(option =>
                                                            <li key={option.name}
                                                                onClick={() => this.onFilterOptionChange(key, option.name)} className={classNames('dropdown-item py-1 link', { "fweight600": this.props.filterOptions[key].selected === option.name })}>{option.label} {(this.props.filterOptions[key].selected === option.name && (option.hasDirection == undefined || option.hasDirection !== false)) ? <i className={classNames('fa ', { "fa-caret-up":  this.props.filterOptions[key].directionUp},  { "fa-caret-down":  !this.props.filterOptions[key].directionUp})}></i> : <></>}</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </>
                                        }
                                    </span>
                                </>
                            }
                        </span>
                    })
                }
            </>

        );
    }
}

export default PageInnerHeaderFilterOptionsMenu