import React, { Component } from "react";
import { Link } from "react-router-dom";
import { store } from "_helpers";
import { userActions } from "_actions";
import { connect } from "react-redux";
import { itemAdditionalActions } from "_actions";
import { itemAdditionalConstants } from "_constants";
import { history } from "_helpers";
import { commonUtils } from "_helpers";
import { userUtils } from "_helpers";
import { MicrosoftLoginoutSection } from "views/user/components/MicrosoftLogoutSection";

class PageHeader extends Component {
    constructor() {
        super();
        this.state = {
            searchText: ""
        }
    }
    handleLogout = (e) => {
        e.preventDefault();
        store.dispatch(this.props.logout());
    }

    onSearchTextChange = (searchText) => {
        //console.log(this.state.searchText)
        this.setState({
            searchText: searchText
        })
    }

    search = (event) => {
        if (event.key === 'Enter') {
            //console.log("SEARCH");
            store.dispatch({ type: itemAdditionalConstants.SEARCH_INPUT_CHANGE, searchText: this.state.searchText });
            store.dispatch(this.props.getSearchResults(this.state.searchText));
            history.push("/project/search")
        }
    }

    render() {
        return (
            <div id="page_top" className="section-body sticky-top no-print">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="left">
                            <button className="link nav-link icon menu_toggle xs-hide"><i className="fa  fa-align-left" /></button>
                            <h1 className="page-title">{this.props.pageName}</h1>
                            <button id="Project-tab" className={this.props.showHeaderViewActions ? 'link grid-view-link active d-block d-sm-none ' : 'd-none'} data-toggle="tab" href="#Grid-View"><i className="fe fe-grid mr-0 fa-lg" /></button>
                            <button id="Project-tab" className={this.props.showHeaderViewActions ? 'link list-view-link d-block d-sm-none' : 'd-none'} data-toggle="tab" href="#List-View"><i className="fe fe-list mr-0 fa-lg" /></button>
                            <button className={this.props.showHeaderCreateActions ? 'link create-link d-block d-sm-none ' : 'd-none'}><i className="fe fe-plus-circle fa-lg" /></button>
                        </div>
                        <div className="right">
                            <button className="link fullscreen_btn"><i className="fe fe-maximize fa-lg pr-3"></i></button>

                            <div className="input-icon xs-hide">
                                <span className="input-icon-addon">
                                    <i className="fe fe-search"></i>
                                </span>
                                <input autoComplete="false" type="text" value={this.state.searchText || ''} className="form-control search" placeholder="Search..." onChange={(e) => this.onSearchTextChange(e.target.value)} onKeyPress={(e) => this.search(e)} />
                            </div>
                            <div className="headernotification d-flex">
                                <div className="dropdown d-flex">
                                    <button className="link nav-link icon d-none d-md-flex  ml-1" data-toggle="dropdown"><i className="fa fa-tasks" /><span className="badge badge-success nav-unread" /></button>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <ul className="right_chat list-unstyled w250 p-0 ">

                                        </ul>
                                        <div className="dropdown-divider" />
                                        <button className="link dropdown-item text-center text-muted-dark readall">Mark all as read</button>
                                    </div>
                                </div>
                                <div className="dropdown d-flex">
                                    <button disabled className="link nav-link icon d-none d-md-flex  ml-1" data-toggle="dropdown"><i className="fa fa-comments" /><span className="badge badge-info" /></button>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <ul className="right_chat list-unstyled w250 p-0">

                                        </ul>
                                        <div className="dropdown-divider" />
                                        <button className="link dropdown-item text-center text-muted-dark readall">Mark all as read</button>
                                    </div>
                                </div>

                                <div className="dropdown d-flex">
                                    <button className="link nav-link icon d-none d-md-flex  ml-1" data-toggle="dropdown"><i className="fa fa-bell" /><span className="badge badge-primary" /></button>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <ul className="list-unstyled feeds_widget">

                                        </ul>
                                        <div className="dropdown-divider" />
                                        <button className="link dropdown-item text-center text-muted-dark readall">Mark all as read</button>
                                    </div>
                                </div>
                                <div className="dropdown d-flex">
                                    <button className="link nav-link icon d-none d-md-flex  ml-1" data-toggle="dropdown"><i className="fa fa-user" /></button>
                                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                                        <Link to={"/hrms/profile/" + commonUtils.getCurrentUserId()} className="dropdown-item"><i className="dropdown-icon fe fe-user" /> Profile</Link>
                                        <Link disabled={true} to={"/hrms/settings"} className="dropdown-item"><i className="dropdown-icon fe fe-settings" /> Settings</Link>
                                        <div className="dropdown-divider" />
                                        <button className="link dropdown-item"><i className="dropdown-icon fe fe-help-circle" /> Need help?</button>
                                        {userUtils.getLoginType() === 'MS' ? 
                                            <MicrosoftLoginoutSection /> :
                                            < button onClick={this.handleLogout} className="link dropdown-item"><i className="dropdown-icon fe fe-log-out" /> Sign out</button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = state => ({
    authentication: state.authentication,
    searchText: state.itemAdditional.searchText,
    searchResults: state.itemAdditional.searchResults
});

const mapDispatchToProps = dispatch => ({
    logout: userActions.logout,
    getSearchResults: itemAdditionalActions.getSearchResults
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageHeader);