
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const taskboardService = {
    getAllTaskboards,
    createTaskboard,
    updateTaskboard,
    removeTaskboard,
    activateTaskboard
};

async function getAllTaskboards(request) {
    let params = request.employeeIds ? `?employeeId=${request.employeeId}`: `?userTeamIds=${request.userTeamIds}`;
    return await axiosUtils.get(urlConfig.TASKBOARD + params);
}

async function createTaskboard(taskboardRequest) {
    return await axiosUtils.post(urlConfig.TASKBOARD,taskboardRequest);
}

async function updateTaskboard(taskboardRequest) {
    return await axiosUtils.put(urlConfig.TASKBOARD, taskboardRequest);
}

async function removeTaskboard(taskboardId) {
    return await axiosUtils.remove(urlConfig.TASKBOARD+`/${taskboardId}`);
 }

 async function activateTaskboard(taskboardId, isActive) {
    return await axiosUtils.put(urlConfig.TASKBOARD+`/${taskboardId}/${isActive}`);
 }
