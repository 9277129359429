import React, { PureComponent } from "react";
import { validationUtils } from '_helpers'
import Select from "react-select";
import PropTypes from 'prop-types';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-datepicker/dist/react-datepicker.css';
import { commonUtils } from '_helpers'
import dateUtils from "_helpers/date.utils";
import CreatableSelect from 'react-select/creatable';
import ReactDatePicker from "react-datepicker";

momentLocalizer()

Moment.updateLocale("en", {
    week: {
        dow: 1, // First day of week is Monday
        doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
    }
});

class CustomInput extends PureComponent {
    constructor() {
        super();
        this.state = {
            // isValidValue: false
        }

    }
    componentDidMount() {
        //this.validateValueOnLoad();
    }

    /*ivalidateValueOnLoad = () => {
        let value = this.props.mappingObject && this.props.mappingObject[this.props.name];
        f (this.props.datatype && value && this.isValidInputValue(this.props.datatype, value)) {
            this.setState({
                isValidValue: true
            });
            
        }
    }*/
    handleCreateSelectChange = (value, action) => {

        if (action && action.action === "select-option") {
            let selectedOption = this.props.options.filter(option => option.value === value.value)[0];
            this.props.onNewItemSelected(false)
            this.props.onCreateSelectChange(this.props.name, selectedOption)
        } if (action && action.action === "create-option") {
            this.props.onChange(this.props.name, value ? value.value : "");
            this.props.onNewItemSelected(true)
        }


    }
    onChange = (e) => {
        e.persist();
        const { name, value, type, checked } = e.target;
        this.props.onChange(name, type === 'checkbox' ? checked : (type === 'radio' ? (value === 'true' ? true : false) : value));
    }

    onDateChange = (name, selectedDateTime) => {
        let validatedDateTime = selectedDateTime && dateUtils.toDateToMoment(selectedDateTime);
        let isValidDateTime = true;
        if (this.props.minDate && this.props.maxDate && selectedDateTime) {
            if (validatedDateTime.isBefore(dateUtils.toDateToMoment(this.props.minDate)) || validatedDateTime.isAfter(dateUtils.toDateToMoment(this.props.maxDate))) {
                this.props.onChange(name, null);
                isValidDateTime = false;
                return;
            }
        }
        this.setState({
            // isValidValue: isValidDateTime
        })
        this.props.onChange(name, validatedDateTime ? validatedDateTime.format(dateUtils.SERVER_DATE_FORMAT) : undefined);
    }

    onChangeMultiSelect = (name, selectedOptions) => {
        this.props.onChange(name, selectedOptions ? selectedOptions.map(selectedOption =>
            selectedOption.value) : null);
    }

    getReadOnlyValue = (value, selectOptions) => {
        if (this.props.readOnlyValue) {
            return this.props.readOnlyValue;
        }
        return value &&
            (this.props.type !== 'select' || (this.props.isIdAsSelectValue === undefined || this.props.isIdAsSelectValue === true) ? (this.props.type !== 'dateselect' ? value : dateUtils.convertDateFormat(value)) :
                (this.props.multi ? selectOptions && value && selectOptions.filter((object) => value.includes(object.value)).label : selectOptions && selectOptions.filter((object) => object.value === value).label))
    }
    onChangeWithValidation = (event, dataType) => {
        event.persist();
        let isValidChar = dataType === 'email' ? validationUtils.validateAlphaNumbericWithSpecial(event.target.value) : this.isValidInputValue(dataType, event.target.value);
        if (isValidChar) {
            this.onChange(event);
            let isMandatoryField = this.props.fieldConfig && this.props.fieldConfig.some(field => field.name === this.props.name && field.mandatory);
            let isValidInputValue = event.target.value && isMandatoryField ? true : false;

            if (isValidInputValue && this.props.datatype === 'email') {
                isValidInputValue = validationUtils.validateEmail(event.target.value);
            }
            if (!isValidInputValue && isMandatoryField) {
                this.props.onFormError(this.props.name, true);
            } else if (isMandatoryField) {
                this.props.onFormError(this.props.name, false);
            }

            this.setState({
                //isValidValue: isValidInputValue
            })
        }
    }

    isValidInputValue = (dataType, inputValue) => {
        return dataType === 'alpnumspl' ? validationUtils.validateAlphaNumbericWithSpecial(inputValue) :
            dataType === 'alpnumspace' ? validationUtils.validateAlphaNumbericWithSpace(inputValue) :
                dataType === 'alpnum' ? validationUtils.validateAlphaNumberic(inputValue) :
                    dataType === 'alp' ? validationUtils.validateAlpha(inputValue) :
                        dataType === 'num' ? validationUtils.validateNumberic(inputValue) :
                            dataType === 'numm' ? validationUtils.validateNumbericWithNegative(inputValue) :
                                dataType === 'hourdecimal' ? validationUtils.validateHourDecimal(inputValue) :
                                    dataType === 'decimal' ? validationUtils.validateDecimalNumberic(inputValue) :
                                        dataType === 'email' ? validationUtils.validateEmail(inputValue) : true;
    }

    render() {
        let selectOptions = this.props.options;
        let isMandatory = this.props.fieldConfig && this.props.fieldConfig.some(field => field.name === this.props.name && field.mandatory);
        let editEnabled = this.props.fieldConfig && this.props.fieldConfig.some(field => field.name === this.props.name && field.editable);
        let isEnabled = this.props.fieldConfig && this.props.fieldConfig.some(field => field.name === this.props.name) ? this.props.fieldConfig.some(field => field.name === this.props.name && field.enabled) : true;
        let value = this.props.mappingObject && this.props.mappingObject[this.props.name];
        let isReadOnly = this.props.readOnly;
        isReadOnly = (editEnabled === false && this.props.editMode === true) ? true : this.props.readOnly
        let isValidValue = isMandatory ? value && this.isValidInputValue(this.props.datatype, value) : undefined;
        // this.validateValueOnLoad();
        if (this.props.isAppConfig && this.props.isAppConfig === true) {
            let configName = this.props.configName ? this.props.configName : this.props.name;
            let configObject = this.props.createConfig && this.props.createConfig.find(config => config.name === configName);
            //configValues = (this.props.excludeValues && configValues) ? this.props.configValues.filter((option) => option.value != this.props.excludeValue) : configValues;
            selectOptions = configObject && configObject.value && commonUtils.convertConfigListToSelectObject(configObject.value);
        }
        if (selectOptions && this.props.defaultOptions) {
            selectOptions = [...this.props.defaultOptions, ...selectOptions]
        }
        let readOnlyValueSelected = "";
        if (isReadOnly) {
            if (this.props.type === 'radios') {
                readOnlyValueSelected = value ? "Yes" : "No";
            } else {
                readOnlyValueSelected = this.getReadOnlyValue(value, selectOptions) || 'Nil'
                if (typeof (readOnlyValueSelected) !== 'string' && this.props.multi === true) {
                    readOnlyValueSelected = readOnlyValueSelected.map(value => <p key={value} className="m-0 custom-bullet fa">{value}</p>)
                }
            }
        }

        return (
            <>
                {isEnabled &&
                    <>
                        {this.props.label && <label className="form-label text-muted">{this.props.label}{isMandatory && <span className="form-required">*</span>}</label>}
                        <div className={`form-group mb-2 ${this.props.formClass ? this.props.formClass : ''}`}>
                            {isReadOnly &&
                                <>
                                    <span className="fsize14 fweight600">
                                        {
                                            readOnlyValueSelected
                                        }

                                    </span>
                                </>
                            }
                            {!isReadOnly && (this.props.type === 'text' ?
                                <input
                                    type={this.props.inputtype ? this.props.inputtype : 'text'}
                                    autoComplete={this.props.autoComplete || "off"}
                                    className={`form-control ${isValidValue ? 'is-valid' : isMandatory ? 'is-invalid' : ''} ${this.props.inputClassName || ''}`}
                                    name={this.props.name}
                                    value={value || ''}
                                    onChange={(event) => this.onChangeWithValidation(event, this.props.datatype)}
                                    placeholder={this.props.placeholder}
                                    readOnly={this.props.disabled && this.props.disabled === true ? true : false}
                                /> :
                                this.props.type === 'radios' ?
                                    <div className="custom-controls-stacked mt-2">
                                        <label className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                className={`custom-control-input ${isValidValue ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}
                                                name={this.props.name}
                                                value={true}
                                                checked={value !== undefined && value === true ? true : false}
                                                onChange={(event) => this.onChangeWithValidation(event, this.props.datatype)}
                                            />
                                            <div className="custom-control-label">Yes</div>
                                        </label>
                                        <label className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                className={`custom-control-input ${isValidValue ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}
                                                name={this.props.name}
                                                value={false}
                                                checked={value !== undefined && value === false ? true : false}
                                                onChange={(event) => this.onChangeWithValidation(event, this.props.datatype)}
                                            />
                                            <div className="custom-control-label">No</div>
                                        </label>
                                    </div> :
                                    this.props.type === 'textarea' ?
                                        <textarea
                                            style={{ height: this.props.height ? this.props.height : "90px" }}
                                            autoComplete={this.props.autoComplete || "off"}
                                            className={`form-control ${isValidValue ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}
                                            name={this.props.name}
                                            value={value || ''}
                                            onChange={(event) => this.onChangeWithValidation(event, this.props.datatype)}
                                            placeholder={this.props.placeholder} /> :
                                        this.props.type === 'select' ?
                                            <div className={`custom-select-${this.props.mappingObject && this.props.mappingObject[this.props.name] && isMandatory ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}>
                                                <Select
                                                    value={this.props.multi ? selectOptions && value && selectOptions.filter((object) => value.includes(object.value)) : selectOptions && (this.props.getSelectOptionEnabled ? value : selectOptions.filter((object) => object.value === value))}
                                                    onChange={(selectedOption) => this.props.multi ? this.onChangeMultiSelect(this.props.name, selectedOption) : this.props.onChange(this.props.name, selectedOption ? this.props.getSelectOptionEnabled ? selectedOption : selectedOption.value : "")}
                                                    options={selectOptions || []}
                                                    isSearchable={true}
                                                    isClearable={isMandatory || this.props.disableClearable ? false : true}
                                                    placeholder={this.props.placeholder}
                                                    classNamePrefix={'custom-select'}
                                                    isMulti={this.props.multi ? true : false}
                                                    menuPlacement={'auto'}
                                                    isMandatory={isMandatory}
                                                    isDisabled={this.props.disabled}
                                                />

                                            </div> :
                                            this.props.type === 'createselect' ?
                                                <div className={`custom-select-${this.props.mappingObject && this.props.mappingObject[this.props.name] && isMandatory ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}>

                                                    <CreatableSelect
                                                        //value={selectOptions && selectOptions.filter((object) => object.value === value)}
                                                        // onInputChange={this.handleCreateSelectChange}
                                                        //inputValue={(value && selectOptions && selectOptions.filter((object) => object.value === value)) ? "" : this.props.mappingObject[this.props.name]}
                                                        onChange={this.handleCreateSelectChange}
                                                        options={selectOptions}
                                                        isSearchable={true}
                                                        isClearable={isMandatory ? false : true}
                                                        placeholder={this.props.placeholder}
                                                        classNamePrefix={'custom-select'}
                                                        isMulti={this.props.multi ? true : false}
                                                        menuPlacement={'auto'}
                                                        isMandatory={isMandatory}
                                                    />

                                                </div> :

                                                this.props.type === 'dateselect' ?
                                                    <div className={`custom-date-time-picker-${this.props.mappingObject[this.props.name] && isMandatory ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}>
                                                        <DateTimePicker
                                                            time={this.props.time}
                                                            onChange={selectedValue => this.onDateChange(this.props.name, selectedValue)}
                                                            //onCurrentDateChange={selectedValue => this.props.onChange(this.props.name, selectedValue)}
                                                            value={typeof (value) === 'string' ? new Date(value) : value}
                                                            format={this.props.format || 'YYYY-MM-DD'}
                                                            max={(this.props.maxDate && new Date(this.props.maxDate)) || new Date(new Date().setFullYear(new Date().getFullYear() + 765))}
                                                            min={(this.props.minDate && new Date(this.props.minDate)) || new Date(new Date().setFullYear(new Date().getFullYear() - 365))}
                                                            defaultValue={this.props.defaultDate}
                                                            defaultCurrentDate={this.props.currentDate}
                                                        />
                                                    </div> :
                                                    //not using
                                                    this.props.type === 'datetimepicker' ?
                                                        <div className={`custom-date-time-picker-${this.props.mappingObject[this.props.name] && isMandatory ? 'is-valid' : isMandatory ? 'is-invalid' : ''}`}>
                                                            <ReactDatePicker
                                                                selected={typeof (value) === 'string' ? new Date(value) : value}
                                                                showTimeSelect
                                                                onChange={selectedValue => this.onDateChange(this.props.name, selectedValue)}
                                                                format={this.props.format || 'YYYY-MM-DD'}
                                                            />
                                                        </div> : ''
                            )}
                        </div>
                    </>
                }
            </>
        )
    }
}

CustomInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onFormError: PropTypes.func.isRequired,
    mappingObject: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(
        [
            'text',
            'textarea',
            'radios',
            'select',
            'createselect',
            'dateselect',
            'datetimepicker'
        ]
    ).isRequired,
    datatype: PropTypes.oneOf(
        [
            'alp',
            'email',
            'num',
            'numm',
            'hourdecimal',
            'alpnum',
            'alpnumspl',
            'alpnumspace'
        ]
    )

}
CustomInput.defaultProps = {
    type: 'text'
}

export default CustomInput;