
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const appConfigService = {
    initializeConfig,
    createNewConfig,
    deleteUserTeamItem,
    removeUserTeamItem,
    updateConfig,
    getAppConfig,
    updateConfigProperty,
    getModuleConfig,
    getAllTeams,
    getAllRoles
};

async function initializeConfig(configId) {
    return await axiosUtils.get(urlConfig.APPCONFIG_INITIALIZE + `/${configId}`, {});
}
async function createNewConfig(type, name, parentId) {
    return await axiosUtils.post(urlConfig.APPCONFIG_CREATE+ `?parentId=${parentId}&type=${type}&name=${name}`, null);
}
async function updateConfigProperty(configId, propertyName, propertyValue) {
    return await axiosUtils.put(urlConfig.APPCONFIG_UPDATE_PROPERTY+ `/${configId}/${propertyName}?propertyValue=${propertyValue}`, null);
}

async function updateConfig(configRequest, configId) {
    return await axiosUtils.put(urlConfig.APPCONFIG_UPDATE +  `/${configId}`, configRequest);
}
async function getAppConfig(configId, useCache) {
    return await axiosUtils.get(urlConfig.APPCONFIG_GET + `/${configId}/${useCache}`, {});
}

async function getModuleConfig(configId, moudleName) {
    return await axiosUtils.get(urlConfig.APPCONFIG_GET_MODULE + `/${configId}/${moudleName}`, {});
}

async function getAllTeams(isGroupByEnabled) {
    return await axiosUtils.get(urlConfig.APPCONFIG_GET_ALL_TEAMS  + `${isGroupByEnabled ? "/groupbyparent" : "?includeParent=true"}`, {});
}

async function getAllRoles(isGroupByEnabled) {
    return await axiosUtils.get(urlConfig.APPCONFIG_GET_ALL_ROLES + `${isGroupByEnabled ? "/groupbyparent" : ""}`, {});
}

async function deleteUserTeamItem(itemId, idDelete) {
    return await axiosUtils.remove(urlConfig.APPCONFIG_PATH+ `/${itemId}/${idDelete}`);
}
async function removeUserTeamItem(itemId) {
    return await axiosUtils.remove(urlConfig.APPCONFIG_PATH+ `/${itemId}`);
}
