import React, { PureComponent } from "react";

import classNames from 'classnames/bind';
import { commonUtils } from "_helpers";

class PageInnerHeaderEmployeesSelectionMenu extends PureComponent {
    constructor() {
        super();
        this.state = {
            showEmployeeDropDown: false,
            selectedEmployeesIds: [],
            filterText: ""
        };
    }
    componentDidMount() {
        this.initalizeEmployeesIds()
    }

    toggleEmployeeDropDown = (isDropdownShow) => {
        //this.initalizeEmployeesIds();
        this.setState({ showEmployeeDropDown: isDropdownShow });
    }

    initalizeEmployeesIds = () => {
        let currentEmployee = commonUtils.getCurrentUser();
        let selectedIds = [];
        this.props.employees && this.props.employees.filter(emp => emp.id != currentEmployee.id).map(employeeItem => {
            selectedIds.push({...employeeItem});
        })
        this.setState({ selectedEmployeesIds: [{ ...currentEmployee, isChecked: true}, ...commonUtils.sortArrayOfObjects(selectedIds, true)] });
    }
    closeEmployeeDropDown = () => {
        this.initalizeEmployeesIds();
        this.toggleEmployeeDropDown(false);
    }
    handleEmployeesSelection = () => {
        this.toggleEmployeeDropDown(false);
        this.props.handleEmployeesSelection(this.state.selectedEmployeesIds);
    }

    onFilterTextChange = (text) => {
        this.setState({
            filterText: text
        })
    }

    onCheckChangeElement = (event) => {
        this.setState({
            selectedEmployeesIds: this.state.selectedEmployeesIds.map(employeeItem =>
                employeeItem.id == event.target.value
                    ? { ...employeeItem, isChecked: event.target.checked }
                    : (this.props.enableSingleSelection === true ? { ...employeeItem, isChecked: false } : employeeItem)
            )
        });
    }

    getSelectedEmployeeName = () => {
        const selecteEmployee = this.state.selectedEmployeesIds.find(employeeItem => employeeItem.isChecked);
        return (selecteEmployee && selecteEmployee.name) || '';
    }
    render() {
        return (
            <>
                {this.props.employees.length > 0 && this.props.enabled &&
                    <span className="d-inline-flex">


                        <button className="link" onClick={() => !this.props.isLoading && this.toggleEmployeeDropDown(!this.state.showEmployeeDropDown)} title="Select Employees">
                            {this.props.showEmployeeNameDropDownLabel && this.props.enableSingleSelection === true &&
                                <>
                                    {this.getSelectedEmployeeName()}
                                    <i className="fe fe-chevron-down"></i>
                                </>
                            }
                            {!(this.props.showEmployeeNameDropDownLabel && this.props.enableSingleSelection === true) &&
                                <>
                                    <i className="fe fe-user pr-1"></i>
                                    <span className="totalcount-notation">{this.state.selectedEmployeesIds.filter(employeeItem => employeeItem.isChecked).length}</span>
                                </>
                            }
                        </button>

                        <div className={classNames({ "dropdowncontainer": !this.props.showEmployeeNameDropDownLabel }, { "dropdowncontainer-static": this.props.showEmployeeNameDropDownLabel }, { "d-none": !this.state.showEmployeeDropDown })} >

                            <div className="dropdownmenu custom-controls-stacked font-weight-normal text-capitalize w-200">
                                <div className="input-icon mb-2 ml-4"><span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                                    <input type="text" className="form-control input-sm filter" placeholder="Filter..."
                                        value={this.state.filterText || ''}
                                        onChange={(event) => this.onFilterTextChange(event.target.value)}
                                    />
                                </div>

                                <div className="custom-dropdownmenu pl-2 ">
                                    <div className="pr-3">
                                        {this.state.selectedEmployeesIds && this.state.selectedEmployeesIds
                                            .filter(employee =>
                                                this.state.filterText === "" || commonUtils.stringContainsString(employee.name, this.state.filterText))
                                            .map(employee =>

                                                <label className={`custom-control custom-checkbox ${commonUtils.getCurrentUserId() === employee.id ? 'border-bottom' : ''}`} key={employee.id}>
                                                    <input type="checkbox" disabled={this.props.isLoading || this.state.selectedEmployeesIds.length === 1} checked={employee.isChecked} onChange={(e) => this.onCheckChangeElement(e)} className="custom-control-input" name={`Employee${employee.id}`} value={employee.id} />
                                                    <span className="custom-control-label fsize13 fweight600">{employee.name}</span>
                                                </label>
                                            )}

                                    </div>
                                </div>

                                <div className="pr-3 pt-1 mt-2 border">
                                    {this.state.selectedEmployeesIds.length > 1 &&
                                        <button className={classNames("plainbutton fsize12 py-0 px-2 mb-1 ml-1 text-upper border fweight600 fsize13", { "colorlightgray": this.state.selectedEmployeesIds.filter(employeeItem => employeeItem.isChecked).length === 0 })} onClick={() => this.state.selectedEmployeesIds.filter(employeeItem => employeeItem.isChecked).length > 0 && this.handleEmployeesSelection()}>
                                            Done
                                        </button>
                                    }
                                    <button className="pl-2 colorblue link float-right mt-1" onClick={() => this.closeEmployeeDropDown()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </span>
                }
            </>

        );
    }
}

export default PageInnerHeaderEmployeesSelectionMenu