import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import EmployeeImage from "views/common/fragments/EmployeeImage";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
class EmployeeInfoGrid extends Component {
    render() {
        return (
            <div className="col-lg-3 col-md-6">
                <div className="card empcard">
                    <div className="card-bodyr ribbon">
                        <div className="ribbon-box green">{commonUtils.getNameInitial(this.props.employee.name)}</div>
                        {this.props.employee.deleted && <div className="deleted">DELETED</div>
                        }
                        {!this.props.employee.deleted && !this.props.employee.active && <div className="inactive">INACTIVE</div>
                        }
                        <div className="row pt-2">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6 text-center">
                                <EmployeeImage employee={this.props.employee} classes={"img-thumbnail rounded-circle avatar-xxl"}/>
                            </div>
                            <div className="col-lg-3">
                                <button type="button" className="link" data-toggle="dropdown" aria-expanded="true"><i className="fe fe-more-horizontal"></i></button>
                                <div className="dropdown-menu dropdown-menu-right dropdownrightmenu" x-placement="top-end">
                                    {this.props.permission.editEnabled === true &&
                                        <button type="button"
                                            className="dropdown-item py-1 link"
                                            onClick={(e) => this.props.handleEditEmployee(this.props.employee.id, this.props.employee.userTeamId)}
                                            title="Edit"><i className="dropdown-icon fa fa-edit" />Edit</button>
                                    }
                                    {this.props.permission.deleteEnabled === true &&
                                        <button type="button"
                                            onClick={(e) => this.props.handleDeleteEmployee(this.props.employee.id, !this.props.employee.deleted)}
                                            className="dropdown-item py-1 link">
                                            {this.props.employee.deleted &&
                                                <>
                                                    <i className="dropdown-icon fa fa-rotate-left colorred blink_text"></i> Undo Delete
                                                </>
                                            }
                                            {!this.props.employee.deleted &&
                                                <>
                                                    <i className="dropdown-icon fa fa-trash"></i>Delete
                                                </>
                                            }
                                        </button>
                                    }
                                    {this.props.permission.archiveEnabled === true &&
                                        <button type="button"
                                            onClick={(e) => this.props.handleActivateEmployee(this.props.employee.id, !this.props.employee.active)}
                                            className="dropdown-item py-1 link">
                                            {this.props.employee.active &&
                                                <>
                                                    <i className="dropdown-icon fe fe-user-x text-primary colorred"></i>InActivate
                                                </>
                                            }
                                            {!this.props.employee.active &&
                                                <>
                                                    <i className="dropdown-icon fe fe-user-check text-primary colorgreen"></i>Activate
                                                </>
                                            }
                                        </button>
                                    }
                                  
                                </div>
                            </div>
                        </div>

                        <div className="text-center mb-0 empcard-name  pt-1">
                            {this.props.permission.viewEnabled === true &&
                                <NavLink
                                    to={"/hrms/profile/"+ this.props.employee.id}
                                    className=""
                                    activeClassName="active"
                                >
                                    {this.props.employee.name}
                                </NavLink>
                            }
                            {!this.props.permission.viewEnabled &&
                                this.props.employee.name
                            }

                        </div>
                        <div className="text-center mb-0 empcard-info">{this.props.employee.designation} {this.props.employee.primarySkill && <span className="pl-1 d-block">({this.props.employee.primarySkill})</span>}</div>
                        <div className="text-center mb-2 empcard-info">{this.props.employee.empCode}</div>
                        {this.props.employee.exitDate && <div className="text-center mb-0 empcard-info text-muted colorred"><i className="dropdown-icon">Exit Date</i>{dateUtils.convertDateFormat(this.props.employee.exitDate)}</div>}
                        <div className="text-center mb-2 empcard-info">{this.props.employee.defaultUserTeamDetails && this.props.employee.defaultUserTeamDetails.name}</div>
                        <div className="text-center mb-0 empcard-info text-muted"><i className="dropdown-icon fa fa-envelope"></i>{this.props.employee.email}</div>
                        <div className="text-center mb-3 empcard-info text-muted">{this.props.employee.mobileNumber && <i className="dropdown-icon fa fa-phone"></i>}{this.props.employee.mobileNumber}</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default EmployeeInfoGrid;