import React, { PureComponent } from "react";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { commonUtils } from "_helpers";
import UserTeamSelection from "./UserTeamSelection";
import EmployeeProjectEstimate from "views/common/fragments/formfields/EmployeeProjectEstimate";
class ProjectTaskAccessRestrictionControlInput extends PureComponent {
    constructor() {
        super();
        this.state = {
            selectedUserTeamsIds: null
        };
    }

    componentDidMount() {
        this.setState({
            previouslySelectedRestrictedByUserTeamIds : this.props.mappingObject["restrictedByUserTeamIds"],
            previouslySelectedRestrictedByEmployeeIds : this.props.mappingObject["restrictedByEmployeeIds"],
            previouslySelectedRestrictedByEmployeeSkills : this.props.mappingObject["restrictedByEmployeeSkills"]       
        })
    }

    getRestrictedEmployees = () => {
        let restrictedUserTeams = this.props.mappingObject["restrictedByUserTeamIds"] || (this.props.projectRestrictedByUserTeamIds && this.props.projectRestrictedByUserTeamIds.length > 0 ? this.props.projectRestrictedByUserTeamIds  : undefined) || (this.props.userTeams && this.props.userTeams.map(userTeam => userTeam.id));
        return this.getRestrictedEmployeesByUserGroupIds(restrictedUserTeams);
    }
    getRestrictedEmployeesByUserGroupIds = (restrictedUserTeams) => {
        let employees = (this.props.projectRestrictedByEmployeeDetails && this.props.projectRestrictedByEmployeeDetails.length > 0) ? this.props.projectRestrictedByEmployeeDetails : this.props.allEmployees;
        if (employees && restrictedUserTeams && restrictedUserTeams.length > 0) {
            employees = commonUtils.filterEmployeesByTeams(employees, restrictedUserTeams)
        }
        let restrictedBySkills = this.props.projectRestrictedByEmployeeSkills;
        if (restrictedBySkills && employees && employees.length > 0) {
            employees = commonUtils.filterEmployeeBySkills(employees, restrictedBySkills)
        }
        return employees;
    }

    getRestrictedByEmployeeOptions = () => {
        let employees = this.getRestrictedEmployees();
        return commonUtils.convertObjectToSelectObject(employees);
    }

    getRestrictedBySkillsOptions = () => {
        let configObject = this.props.employeeAppConfig && this.props.employeeAppConfig.find(config => config.name === "skills");
        let restrictedBySkills = this.props.projectRestrictedByEmployeeSkills || (configObject && configObject.value);
        let employees = this.getRestrictedEmployees();

        if (employees && employees.length > 0 && restrictedBySkills) {
            restrictedBySkills = commonUtils.filterSkillsByEmployee(employees, restrictedBySkills)
        } else {
            restrictedBySkills = []
        }

        return commonUtils.convertConfigListToSelectObject(restrictedBySkills);
    }

    onChange = (fieldName, value) => {
        if (fieldName === "restrictedByEmployeeSkills") {
            this.props.onChange("restrictedByEmployeeIds", []);
        } else if (fieldName === "restrictedByEmployeeIds") {
            this.props.onChange("restrictedByEmployeeSkills", []);
            var currentSelectedRestrictedByEmployeeIds = value || [];
            var previouslySelectedRestrictedByEmployeeIds = this.state.previouslySelectedRestrictedByUserTeamIds || [];
            var allCurrentSelectedRestrictedByEmployeeIds = [...new Set([...currentSelectedRestrictedByEmployeeIds, ...previouslySelectedRestrictedByEmployeeIds])];
            this.setState({
                previouslySelectedRestrictedByEmployeeIds : allCurrentSelectedRestrictedByEmployeeIds
            })
        } else if (fieldName === 'restrictedByUserTeamIds') {
        
            var currentAvailableRestrictedEmployees = this.getRestrictedEmployeesByUserGroupIds(value);
            var previouslySelectedRestrictedByEmployeeIds = this.state.previouslySelectedRestrictedByEmployeeIds || [];
          
            if (previouslySelectedRestrictedByEmployeeIds && currentAvailableRestrictedEmployees) {
                previouslySelectedRestrictedByEmployeeIds = currentAvailableRestrictedEmployees.filter(emp => previouslySelectedRestrictedByEmployeeIds.includes(emp.id)).map(emp => emp.id);
            }
            this.props.onChange("restrictedByEmployeeIds", previouslySelectedRestrictedByEmployeeIds);
            this.props.onChange("restrictedByEmployeeSkills", []);
        }
        this.props.onChange(fieldName, value);
    }

    render() {
        let restrictedByEmployeeOptions = this.getRestrictedByEmployeeOptions();
        let restrictedBySkillsOptions = this.getRestrictedBySkillsOptions();

        //console.log("APRCI render");
        return (
            <div className="card pb-0 mb-0">
                <div className="card-header pt-0 pb-1">
                    <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Access Restrictions</span>
                    <div className="card-options">
                        <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                    </div>
                </div>
                <div className="col-12 row">
                    <UserTeamSelection
                        showOnlyActive={this.props.permission.showOnlyActiveUserTeamsInCreateWindow}
                        enableAllTeams={this.props.permission.showAllUserTeamsSelectableInProjectCreateWindow}
                        inputClassName={this.props.inputClassName ? this.props.inputClassName : "col-6"}
                        teamClasssName={this.props.teamClasssName ? this.props.teamClasssName : "col-6"}
                        name="restrictedByUserTeamIds"
                        label="Restricted By User Teams"
                        userTeams={this.props.projectRestrictedByUserTeamIds && this.props.userTeams ? this.props.userTeams && this.props.userTeams.filter(obj => this.props.projectRestrictedByUserTeamIds.includes(obj.id)) : this.props.userTeams}
                        fieldConfig={this.props.fieldConfig}
                        mappingObject={this.props.mappingObject}
                        onChange={this.onChange}
                        onFormError={this.props.onFormError}
                    />
                    <div className={this.props.inputClassName ? this.props.inputClassName : "col-6"}>
                        <div className="row">
                            <div className="col-12">
                                <CustomInput
                                    type='select'
                                    label="Restricted By Skills"
                                    name="restrictedByEmployeeSkills"
                                    // excludeValues={this.state.employeeRequest['primaryskill']}
                                    fieldConfig={this.props.fieldConfig}
                                    mappingObject={this.props.mappingObject}
                                    onChange={this.onChange}
                                    placeholder="Restrict access by skills..."
                                    options={this.props.employeeAppConfig && restrictedBySkillsOptions}
                                    onFormError={this.props.onFormError}
                                    multi={true}
                                />
                            </div>
                            <div className="col-12">
                                
                                <EmployeeProjectEstimate
                                    label="Restricted for Employees"
                                    name="restrictedByEmployeeIds"
                                    fieldConfig={this.props.fieldConfig}
                                    mappingObject={this.props.mappingObject}
                                    onChange={this.onChange}
                                    onFormError={this.props.onFormError}
                                    placeholder="Restrict access for employees..."
                                    options={restrictedByEmployeeOptions}
                                    permission={this.props.permission}
                                />

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default ProjectTaskAccessRestrictionControlInput;
