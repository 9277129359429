export const taskboardConstants = {

    SHOW_TASKBOARD_CONFIGURE_WINDOW: "SHOW_TASKBOARD_CONFIGURE_WINDOW",
    CLOSE_TASKBOARD_CONFIGURE_WINDOW: "CLOSE_TASKBOARD_CONFIGURE_WINDOW",
    
    GETALL_TASKBOARDS_REQUEST: 'GETALL_TASKBOARDS_REQUEST',
    GETALL_TASKBOARDS_SUCCESS: 'GETALL_TASKBOARDS_SUCCESS',
    GETALL_TASKBOARDS_FAILURE: 'GETALL_TASKBOARDS_FAILURE',

    CREATE_TASKBOARD_REQUEST: 'CREATE_TASKBOARD_REQUEST',
    CREATE_TASKBOARD_SUCCESS: 'CREATE_TASKBOARD_SUCCESS',
    CREATE_TASKBOARD_FAILURE: 'CREATE_TASKBOARD_FAILURE',

    UPDATE_TASKBOARD_REQUEST: 'UPDATE_TASKBOARD_REQUEST',
    UPDATE_TASKBOARD_SUCCESS: 'UPDATE_TASKBOARD_SUCCESS',
    UPDATE_TASKBOARD_FAILURE: 'UPDATE_TASKBOARD_FAILURE',

    REMOVE_TASKBOARD_REQUEST: 'REMOVE_TASKBOARD_REQUEST',
    REMOVE_TASKBOARD_SUCCESS: 'REMOVE_TASKBOARD_SUCCESS',
    REMOVE_TASKBOARD_FAILURE: 'REMOVE_TASKBOARD_FAILURE',

    ACTIVATE_TASKBOARD_REQUEST:'ACTIVATE_TASKBOARD_REQUEST',
    ACTIVATE_TASKBOARD_SUCCESS:'ACTIVATE_TASKBOARD_SUCCESS',
    ACTIVATE_TASKBOARD_FAILURE:'ACTIVATE_TASKBOARD_FAILURE'
};