import dateFormat from 'dateformat';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

//2019-10-15T23:59:42.411

const dateUtils = {
    getDaysArrayByMonth,
    getDaysArrayByWeek,
    getFirstAndLastDayofMonth,
    convertSecTohhmmss,
    convertDateFormat,
    convertDateTimeFormat,
    getDefaultTodayDateRange,
    getDefaultDateRange,
    getCurrentWeekDateArray,
    getDateRangeArray,
    getStartOftheWeek,
    getWeekNumber,
    convertDateToMoment,
    getHours,
    getCurrentTime,
    getCurrentDayStart,
    getCurrentDayEnd,
    getDayStart,
    getDayEnd,
    substractXDays,
    addXDays,
    substractXFromMoments,
    addXToMoments,
    toMomentToDate,
    toDateToMoment,
    minuteToHour,
    minuteToHourString,
    minuteToHourDecimal,
    getMonthYearArray,
    convertDateStringToDate,
    convertDateStringToMoment,
    convertMomentToString,
    milliSecToTimeString,
    compareTwoMoments,
    getTimeSelectionOptions,
    CLIENT_DATE_FORMAT: "yyyy-mmm-dd",
    DATE_MONTH_FORMAT: "MMM-yyyy",
    CLIENT_DATE_TIME_FORMAT: "yyyy-mmm-dd HH:MM",
    SERVER_DATE_FORMAT: "yyyy-MM-DDTHH:mm:ss.SSS",
    DATE_TIME_PICKER_FORMAT: 'YYYY-MM-DD HH:mm',
    DATE_PICKER_FORMAT: 'YYYY-MM-DD',
    WEEK_NAMES: [ 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
};

function getFirstAndLastDayofMonth(currentDate) {
    const currentMoment = convertDateToMoment(currentDate);
    const startOfMonth = currentMoment.clone().startOf('month');
    const endOfMonth = currentMoment.clone().endOf('month');
    return [startOfMonth, endOfMonth]
}

function getDaysArrayByMonth(currentDate) {
    var daysInMonth = currentDate.daysInMonth();
    var arrDays = [];

    while (daysInMonth) {
        var current = moment(currentDate).date(daysInMonth);
        arrDays.push(current);
        daysInMonth--;
    }

    return arrDays.reverse();
}

function getDefaultDateRange() {
    const today = moment();
    return moment.range(today.clone().subtract(30, "days"), today.clone().subtract(0, "days"))
}

function getDefaultTodayDateRange() {
    const today = moment();
    return moment.range(today.clone(), today.clone())
}

function convertSecTohhmmss(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    // round seconds
    seconds = Math.round(seconds)

    let result = (hours < 10 ? "0" + hours : hours);
    result += ":" + (minutes < 10 ? "0" + minutes : minutes);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
}

function convertDateFormat(sourceDate) {
    return sourceDate && dateFormat(sourceDate, dateUtils.CLIENT_DATE_FORMAT);
}

function convertDateTimeFormat(sourceDate) {
    return sourceDate && dateFormat(sourceDate, dateUtils.CLIENT_DATE_TIME_FORMAT);
}

function convertDateToMoment(date) {
    if (date) {
        return moment(date);
    }
    return null;
}

function getHours(dateString) {
    return moment(dateString).format("hh:mm")
}

function convertDateStringToDate(dateString) {
    if (dateString) {
        return moment(dateString).toDate();
    } else {
        return null;
    }
}
function convertDateStringToMoment(dateString) {
    if (dateString) {
        return moment(dateString);
    } else {
        return null;
    }
}

function convertMomentToString(momentDate) {
    return momentDate && momentDate.clone().format(dateUtils.SERVER_DATE_FORMAT)
}

function substractXDays(providedMoment, days) {
    return providedMoment.clone().subtract(days, "days")
}

function addXDays(providedMoment, days) {
    return providedMoment.clone().add(days, "days")
}

function substractXFromMoments(providedMoment, type, noOfDays) {
    return providedMoment.clone().subtract(noOfDays, type)
}

function addXToMoments(providedMoment, type, noOfDays) {
    return providedMoment.clone().add(noOfDays, type)
}

function getCurrentTime() {
    return moment();
}

function getDayStart(momentObj) {
    return momentObj.startOf('day')
}

function getDayEnd(momentObj) {
    return momentObj.startOf('day')
}
function getCurrentDayStart() {
    return moment().startOf('day')
}
function getCurrentDayEnd() {
    return moment().endOf('day')
}

function toMomentToDate(momentObj) {
    return momentObj && momentObj.toDate();
}

function toDateToMoment(dateObj) {
    return dateObj && moment(dateObj);
}

function getCurrentWeekDateArray(currentMoment) {
    var startOfWeek = currentMoment.startOf('isoweek').toDate();
    var endOfWeek = currentMoment.endOf('isoweek').toDate();
    return getDateRangeArray(startOfWeek, endOfWeek, false);
}

function getDateRangeArray(startDate, endDate, skipWeekends) {
    var dates = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) <= 0) {
        if (!(skipWeekends === true && (currDate.day() === 6 || currDate.day() === 0))) {
            dates.push({ value: dateFormat(currDate.clone(), dateUtils.CLIENT_DATE_FORMAT), dayNumber: currDate.day() });
            //dates.push(dateFormat(currDate.clone(), dateUtils.CLIENT_DATE_FORMAT));
        }
        currDate.add(1, 'days')
    }

    return dates;
}

function getDaysArrayByWeek(startDate) {
    var dates = [];
    var currDate = moment(startDate).startOf('isoweek');
    var lastDate = moment(startDate).endOf('isoweek');

    while (currDate.diff(lastDate) <= 0) {
        dates.push(currDate.clone());
        currDate.add(1, 'days')
    }

    return dates;
}

function compareTwoMoments(startDate, endDate) {
    if (startDate.diff(endDate) < 0) { // end date greater
        return 1;
    } else if (startDate.diff(endDate) == 0) {
        return 0;
    } else if (startDate.diff(endDate) > 0) { // startDate greater
        return -1;
    }
}

function getStartOftheWeek(currentMoment) {
    return currentMoment.clone().startOf('isoweek').toDate();
}

function getWeekNumber(currentMoment) {
    return moment(currentMoment).format('ww')
}

function minuteToHour(timeInMinute) {
    if (timeInMinute) {
        var hours = (timeInMinute / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + rminutes;
    }
    return null;

}
function minuteToHourDecimal(timeInMinute) {
    if (timeInMinute) {
        var hours = (timeInMinute / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + "." + rminutes;
    }
    return null;

}

function minuteToHourString(timeInMinute) {
    if (timeInMinute) {
        var hours = (timeInMinute / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + "h " + rminutes + "m";
    }
    return null;

}
function getMonthYearArray(estStartDate, estEndDate) {
    var dates = [];
    var startDate = moment(estStartDate)
    var endDate = moment(estEndDate)
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) <= 0) {
        dates.push(dateFormat(currDate.clone(), "mmm-yyyy"));
        currDate.add(1, 'month')
        currDate = currDate.startOf('month')
    }

    return dates;
}

function milliSecToTimeString(duration) {
    var milliseconds = parseInt((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes;
}

function getTimeSelectionOptions() {
    var x = 30; //minutes interval
    var timeOptions = []; // time array
    var tt = 0; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
        var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
        var mm = (tt % 60); // getting minutes of the hour in 0-55 format
        var value = ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2);
        var value12 = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2);
        var label = value12 + " " + ap[Math.floor(hh / 12)];
        timeOptions[i] = { value, label }; // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }
    return timeOptions;
}


export default dateUtils;
