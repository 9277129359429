import React, { Component } from "react";
import PageInnerHeader from "views/common/PageInnerHeader";
import CreateWorkItemModel from "./components/modal/CreateWorkItemModel";
import { store } from "_helpers";
import { connect } from "react-redux";
import { workItemConstants } from "_constants";
import { commonUtils } from "_helpers";
import { workItemActions } from "_actions";
import { taskActions } from "_actions";
import { accessUtils } from "_helpers";
import WorkItemActiveItems from "./components/WorkItemActiveItems";
import Calendar from "views/common/plugins/calendar/Calendar";
import dateUtils from "_helpers/date.utils";
import ItemStatistics from "./components/fragments/ItemStatistics";
import { dashboardActions } from "_actions";
import workItemUtils from "_helpers/workitem.utils";

class WorkItemsListPage extends Component {
    constructor() {
        super()
        this.state = {
            dateRange: { "startDateMoment": dateUtils.getCurrentTime(), "endDateMoment": dateUtils.getCurrentTime() },
            filterOptions: {
                SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ONGOING", label: "On Going" }, { name: "DELETED", label: "Deleted" }, { name: "COMPLETED", label: "Completed" }, { name: "MYASSIGNED", label: "My Assigned" }, { name: "CREATEDBYME", label: "Created By Me" }], selected: "ONGOING" },
                SORT: { options: [{ name: "ACTUALSTARTDATE", label: "Start Date" }, { name: "ESTIMATEDENDDATE", label: "Due Date" }, { name: "CREATEDDATE", label: "Created Date" }, { name: "A-Z", label: "A-Z" }], selected: "A-Z", directionUp:true },
                filterText: null
            },
            permission: accessUtils.getWorkItemAccessControl()
        };
    }
    componentDidMount() {
        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name + " : " + this.props.match.params.taskId)
        let taskId = commonUtils.removeNameIntial(this.props.match.params.taskId);
        if (!this.props.taskInfo || this.props.taskInfo.id !== taskId) {
            store.dispatch(this.props.getTaskInfo(taskId));
            this.setState({
                taskId
            })
        }
        let dateRange = { "startDateMoment": dateUtils.getCurrentTime(), "endDateMoment": dateUtils.getCurrentTime() }
        this.reloadWorkItemsListService(taskId, dateRange)
    }

    reloadWorkItemsListService = (taskId, dateRange) => {
        store.dispatch(this.props.getAllWorkItems({ taskId, dateRange }, this.state.filterOptions))
    }

    onCreateItemClick = () => {
        this.setState({ editMode: false });
        store.dispatch(this.props.initializeCreateWorkItem(commonUtils.getCurrentUserTeamId()));
    }


    onCloseCreateItem = () => {
        store.dispatch({ type: workItemConstants.CLOSE_WORK_ITEM_CREATE_WINDOW });
    }
    handleDeleteWorkItem = (workItemIdTobeDeleted, isDelete) => {

        store.dispatch(this.props.deleteWorkItem(workItemIdTobeDeleted, isDelete));
    }

    handleEditWorkItem = (workItemId) => {

        this.setState({ editMode: true });
        store.dispatch(this.props.getWorkItemInfo(workItemId));
        store.dispatch(this.props.initializeCreateWorkItem(commonUtils.getCurrentUserTeamId()));
    }

    handleRemoveWorkItem = (workItemId) => {
        store.dispatch(this.props.removeWorkItem(workItemId));
    }
    onSelectedDateChange = selectedDates => {
        let dateRange = { "startDateMoment": selectedDates[0].startOf("days"), "endDateMoment": selectedDates[selectedDates.length - 1].endOf("days") }
        this.setState({ dateRange })
        this.reloadWorkItemsListService(this.state.taskId, dateRange)
    }

    onCalendarViewDate = (viewMonthMoment) => {

    }

    handleSubmit = (workItemRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
        if (isEdtiMode) {
            store.dispatch(this.props.updateWorkItem(workItemRequest));
        } else {
            store.dispatch(this.props.createWorkItem(workItemRequest, isCreateAnotherEnabled, callbackFunction));
        }
    }
    onFilterTextChange = (value) => {
        //console.log(value);
        this.setState(prevState => ({
            filterOptions: {
                ...prevState.filterOptions,
                filterText: value
            }
        }),
            () => {
                this.handleFilterChange(this.state.filterOptions);
            }
        );
    }
    handleFilterChange = (filterOptions) => {
        this.setState({ filterOptions: filterOptions },
            () => {
                store.dispatch({ type: workItemConstants.CHANGE_FILTER_WORK_ITEMS, filterOptions });
            });
    }

    render() {
        return (
            <div className="projectDetails">
                <div className="section-body mt-0">
                    <div className="container-fluid px-0">

                        <div className="row mx-0 projectDetailsSection pt-1 border-top">
                            <div className="col-lg-9 px-1 bg-white">
                                <div className="col-lg-12 col-md-12 px-2">
                                    <div className="mb-0 h-100">
                                        {this.props.taskInfo && (this.props.showCreateModel && ((this.state.editMode && this.props.selectedWorkItemInfo) || !this.state.editMode)) &&
                                            <CreateWorkItemModel
                                                onCloseCreateItem={this.onCloseCreateItem}
                                                show={this.props.showCreateModel}
                                                editMode={this.state.editMode}
                                                selectedWorkItemInfo={this.props.selectedWorkItemInfo}
                                                handleSubmit={this.handleSubmit}
                                                taskInfo={this.props.taskInfo}
                                                permission={this.state.permission}
                                                minDate={this.props.taskInfo && workItemUtils.getWorkItemCreateStartMinDate(dateUtils.convertDateStringToMoment(this.props.taskInfo.estStartTime || dateUtils.getCurrentTime().clone().subtract(30, "days")), this.state.permission)}
                                                maxDate={this.props.taskInfo && workItemUtils.getWorkItemCreateEndMaxDate(dateUtils.convertDateStringToMoment(this.props.taskInfo.estCompletionTime || dateUtils.getCurrentTime().clone().add(30, "days")), this.state.permission)}
                                            />
                                        }
                                        <PageInnerHeader
                                            showFilter={true}
                                            stickytop={false}
                                            isLoading={this.props.loading}
                                            showUserTeamMenu={false}
                                            filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
                                            handleFilterChange={this.handleFilterChange}
                                            onFilterTextChange={this.onFilterTextChange}
                                            showCreateMenu={this.state.permission.createEnabled && this.props.taskInfo && this.props.taskInfo.status === 'INPROGRESS'}
                                            showCreateMenuText="CREATE WORK ITEM"
                                            createItemHandler={this.onCreateItemClick}
                                        />
                                        <WorkItemActiveItems
                                            items={this.props.items}
                                            isLoading={this.props.isLoading}
                                            view={"PAGE"}
                                            sortBy={(this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions).SORT.selected}
                                            handleEditWorkItem={this.handleEditWorkItem}
                                            permission={this.state.permission}
                                        />

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-3 sidemenu border-left px-0">
                                <Calendar
                                    className={"customSmallCalendar border-bottom pb-3"}
                                    onSelectedDateChange={this.onSelectedDateChange}
                                    onCalendarViewDate={this.onCalendarViewDate}
                                    holidayItems={[]}
                                    workItems={[]}
                                />
                                {this.state.taskId &&
                                    <ItemStatistics
                                        label="Work Item Statistics"
                                        items={this.props.workItemStatisticsByCategory}
                                        loadData={this.props.getWorkItemStatisticsByCategory}
                                        itemId={this.state.taskId}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    items: state.workItem.items && commonUtils.filterWorkItems(state.workItem.items, state.workItem.filterOptionsWI),
    taskInfo: state.tasks.selectedTaskInfo,
    isLoading: state.workItem.workItemLoading,
    filterOptions: state.workItem.filterOptionsWI,
    selectedWorkItemInfo: state.workItem.selectedWorkItemInfo,
    showCreateModel: state.workItem.showCreateModel,
    workItemStatisticsByCategory: state.dashboard.workItemStatisticsByCategory
});
const mapDispatchToProps = dispatch => ({
    getTaskInfo: taskActions.getTaskInfo,
    getAllWorkItems: workItemActions.getAllWorkItems,
    getWorkItemInfo: workItemActions.getWorkItemInfo,
    createWorkItem: workItemActions.createWorkItem,
    updateWorkItem: workItemActions.updateWorkItem,
    deleteWorkItem: workItemActions.deleteWorkItem,
    removeWorkItem: workItemActions.removeWorkItem,
    initializeCreateWorkItem: workItemActions.initializeCreateWorkItem,
    getWorkItemStatisticsByCategory: dashboardActions.getWorkItemStatisticsByCategory
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkItemsListPage);
