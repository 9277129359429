import React, { Component } from "react";
import dateUtils from "_helpers/date.utils";
import ItemCommentsSection from "./fragments/ItemCommentsSection";
import ItemPropertyInfo from "./fragments/ItemPropertyInfo";
import classNames from 'classnames/bind';
import SelectInput from "views/common/fragments/formfields/SelectInput";
import { store } from "_helpers";
import { connect } from "react-redux";
import { purchaseOrderActions } from "_actions";
import ConfigureChecklistSidebar from "./modal/ConfigureChecklistSidebar";
import ItemChecklistInput from "./fragments/ItemChecklistInput";
import { checklistActions } from "_actions";
import ProjectServiceLineView from "./fragments/ProjectServiceLineView";

class PurchaseOrderDetailsGeneral extends Component {
    handleUpdateStatus = (purchaseOrderId, status) => {
        store.dispatch(this.props.updatePurchaseOrderStatus(purchaseOrderId, status));
    }
    handleUpdateChecklist = (checklistData) => {
        store.dispatch(this.props.updateItemChecklistData(checklistData, this.props.item.id, "PURCHASE_ORDER"));
    }
    render() {
        return (
            <>
                {this.props.item &&
                    <div className="card h-100 mb-0 pl-2">
                        <div className="card-header pt-1 pl-0  pb-2 border-bottom">
                            <span>
                                <label className="d-block fsize14 colorlightgreen">{this.props.item.ownerUserTeamDetails.name}</label>
                                <span className="card-title text-normal-case fsize20 ">
                                    <i className="fa fa fa-newspaper-o fa-lg mr-1 colorlightgreen"></i>
                                    <span className=""></span>{this.props.item.name}
                                </span>
                            </span>
                            <div className="card-options">
                                <SelectInput
                                    options={[
                                        { value: "NEW", label: "New", level: 0 },
                                        { value: "CREATED", label: "Created", level: 1 },
                                        { value: "ASSIGNED", label: "Assigned", level: 2 },
                                        { value: "INPROGRESS", label: "In Progress", level: 3 },
                                        { value: "COMPLETED", label: "Completed", level: 4 },
                                        { value: "CLOSED", label: "Closed", level: 5 }]}
                                    value={this.props.item.status}
                                    allowLevelDown={this.props.permission.allowStatusLevelDown}
                                    changeableStatus={this.props.permission.changeableStatus}
                                    isReadOnly={(!this.props.permission.allowStatusChange) || this.props.readOnly}
                                    itemId={this.props.item.id}
                                    onChange={this.handleUpdateStatus}
                                    className={classNames("tag",
                                        { "tag-info": this.props.item.status === 'NEW' },
                                        { "tag-danger": this.props.item.status === 'CREATED' },
                                        { "tag-warning": this.props.item.status === 'INPROGRESS' },
                                        { "tag-success": this.props.item.status === 'COMPLETED' },
                                        { "tag-dark": this.props.item.status === 'CLOSED' }
                                    )} />
                                {!this.props.readOnly &&
                                    <div className="item-action dropdown ml-2 show">
                                        <button className="link" data-toggle="dropdown" aria-expanded="true"><i className="fe fe-more-vertical"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right dropdownrightmenu" x-placement="top-end">
                                            <button className="dropdown-item link"><i className="dropdown-icon fa fa-cloud-download"></i> Download</button>
                                            <div className="dropdown-divider"></div>
                                            <button className="dropdown-item link"><i className="dropdown-icon fa fa-edit"></i> Edit</button>
                                            <button className="dropdown-item link"><i className="dropdown-icon fa fa-paste"></i> Archive</button>
                                            <button className="dropdown-item link"><i className="dropdown-icon fa fa-trash"></i> Delete</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card-body pt-3 pb-1">
                            <div className="row m-0 p-0">
                                <div className="col-8 py-0 justify-content-between flex-column d-flex">
                                    <div className="top">
                                        <span className="subHeading">Description</span>
                                        <p>{this.props.item.description || 'No description availabel'} </p>

                                        <div className="row">

                                            <ItemPropertyInfo
                                                label="Purchase Order Category"
                                                value={this.props.item.category || 'NA'}
                                            />

                                            <ItemPropertyInfo
                                                label="Purchase Order Type"
                                                value={this.props.item.type || 'NA'}
                                            />
                                            <div className="form-group  col-4">
                                                <label className="form-label text-muted">Proposal No</label>
                                                <i className="fe fe-file-minus pr-1" />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.proposalNumber || 'NA'}</span>
                                            </div>

                                            <div className="form-group  col-4">
                                                <label className="form-label text-muted">Client Project Id</label>
                                                <i className="fe fe-file-minus pr-1" />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.clientProjectId || 'NA'}</span>
                                            </div>

                                            <div className="form-group  col-4">
                                                <label className="form-label text-muted">Sales Force Id</label>
                                                <i className="fe fe-file-minus pr-1" />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.salesForceId || 'NA'}</span>
                                            </div>


                                            <div className="form-group  col-4">
                                                <label className="form-label text-muted">Bid Id</label>
                                                <i className="fe fe-file-minus pr-1" />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.bidId || 'NA'}</span>
                                            </div>

                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Account</label>
                                                <i className="fe fe-layers pr-1 " />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.accountName}</span>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Team</label>
                                                <i className="fe fe-layers pr-1 " />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.teamName}</span>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Clients</label>
                                                <i className="fe fe-layers pr-1 " />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.clientNames && this.props.item.clientNames.join(',')}</span>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Client Partner</label>
                                                <i className="fa fa-handshake-o pr-1 " />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.clientPartner}</span>
                                            </div>


                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Complexity</label>
                                                <i className="fe fe-layers pr-1 " />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.complexity || 'NA'}</span>
                                            </div>

                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Priority</label>
                                                <i className="fe fe-layers pr-1 " />
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.priority}</span>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Service Category</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.serviceCategory || 'NA'}</span>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Survey Type</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.surveyType || 'NA'}</span>
                                            </div>
                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Phone Number</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.phoneNumber || 'NA'}</span>
                                            </div>


                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Time Zone</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.timezone || 'NA'}</span>
                                            </div>


                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Currency</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.currency || 'NA'}</span>
                                            </div>

                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">LOI</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.loi || 'NA'}</span>
                                            </div>

                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Sample</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.sample || 'NA'}</span>
                                            </div>


                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Target Audience</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.targetAudience || 'NA'}</span>
                                            </div>

                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Sample Targeting</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.sampleTargeting || 'NA'}</span>
                                            </div>

                                            <div className="form-group col-4">
                                                <label className="form-label text-muted">Estimated IR</label>
                                                <span className="fsize13 fweight600 menu-text-black">{this.props.item.estimatedIR || 'NA'}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <ProjectServiceLineView
                                        item={this.props.item}
                                        permission={this.props.permission}
                                    />
                                </div>
                                <div className="col-4 pl-3">
                                    <div className="form-group mb-1">
                                        <label className="text-muted fsize13 w150">Purchase Order Owner:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{(this.props.item.ownerDetails && this.props.item.ownerDetails.name) || 'NA'}</span>
                                    </div>

                                    <div className="form-group mb-1 ">
                                        <label className="text-muted fsize13 w150">Start Date:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateFormat(this.props.item.estStartTime)}</span>
                                    </div>

                                    <div className="form-group  mb-1">
                                        <label className="text-muted fsize13 w150">Due to:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateFormat(this.props.item.estCompletionTime)}</span>
                                    </div>

                                    <div className="form-group  mb-1">
                                        <label className="text-muted fsize13 w150">Created Date:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateTimeFormat(this.props.item.createdDate)}</span>
                                    </div>
                                    {this.props.item.lastModifiedDate &&
                                        <div className="form-group  mb-1">
                                            <label className="text-muted fsize13 w150">Updated Date:</label>
                                            <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateTimeFormat(this.props.item.lastModifiedDate)}</span>
                                        </div>
                                    }
                                    {this.props.item.createdByDetails &&
                                        <div className="form-group  mb-1">
                                            <label className="text-muted fsize13 w150">Created By:</label>
                                            <span className="fsize13 fweight600 menu-text-black">{this.props.item.createdByDetails.name}</span>
                                        </div>
                                    }
                                    {this.props.item.updatedByDetails &&
                                        <div className="form-group  mb-1">
                                            <label className="text-muted fsize13 w150">Updated By:</label>
                                            <span className="fsize13 fweight600 menu-text-black">{this.props.item.updatedByDetails.name}</span>
                                        </div>
                                    }

                                </div>
                            </div>
                            {this.props.permission.checklistEnabled &&
                                <ItemChecklistInput
                                    handleUpdateChecklist={this.handleUpdateChecklist}
                                    type={"PURCHASE_ORDER"}
                                    permission={this.props.permission}
                                    checklistData={this.props.item.checklistData}
                                    itemUserTeamId={this.props.item.userTeamId}
                                />
                            }
                        </div>

                        <ItemCommentsSection
                            itemId={this.props.item.id}
                            subType={"PURCHASE_ORDER"}
                            isReadOnly={this.props.readOnly}
                        />
                        <ConfigureChecklistSidebar
                            type={"PURCHASE_ORDER"}
                            show={this.props.showChecklistModel}
                            showConfigWindow={this.props.showChecklistModel}
                            showChecklistQuestionsWindow={false}
                        />

                    </div>
                }
            </>
        );
    }
}
const mapStateToProps = state => ({
    showChecklistModel: state.checklist.isShowConfigureChecklistSidebar
});
const mapDispatchToProps = dispatch => ({
    updatePurchaseOrderStatus: purchaseOrderActions.updatePurchaseOrderStatus,
    updateItemChecklistData: checklistActions.updateItemChecklistData,
});
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDetailsGeneral);

