export const purchaseOrderConstants = {
    INITIALIZE_CREATE_PURCHASE_ORDER_REQUEST : 'INITIALIZE_CREATE_PURCHASE_ORDER_REQUEST',
    INITIALIZE_CREATE_PURCHASE_ORDER_SUCCESS : 'INITIALIZE_CREATE_PURCHASE_ORDER_SUCCESS',
    INITIALIZE_CREATE_PURCHASE_ORDER_FAILURE : 'INITIALIZE_CREATE_PURCHASE_ORDER_FAILURE',
    CLEAR_SELECTED_PURCHASE_ORDER:'CLEAR_SELECTED_PURCHASE_ORDER',

    GETALL_PURCHASE_ORDERS_REQUEST: 'GETALL_PURCHASE_ORDERS_REQUEST',
    GETALL_PURCHASE_ORDERS_SUCCESS: 'GETALL_PURCHASE_ORDERS_SUCCESS',
    GETALL_PURCHASE_ORDERS_FAILURE: 'GETALL_PURCHASE_ORDERS_FAILURE',

    GETALL_ACTIVE_PURCHASE_ORDERS_REQUEST: 'GETALL_ACTIVE_PURCHASE_ORDERS_REQUEST',
    GETALL_ACTIVE_PURCHASE_ORDERS_SUCCESS: 'GETALL_ACTIVE_PURCHASE_ORDERS_SUCCESS',
    GETALL_ACTIVE_PURCHASE_ORDERS_FAILURE: 'GETALL_ACTIVE_PURCHASE_ORDERS_FAILURE',

    GET_PURCHASE_ORDER_REQUEST: 'GET_PURCHASE_ORDER_REQUEST',
    GET_PURCHASE_ORDER_SUCCESS: 'GET_PURCHASE_ORDER_SUCCESS',
    GET_PURCHASE_ORDER_FAILURE: 'GET_PURCHASE_ORDER_FAILURE',
    
    SHOW_PURCHASE_ORDER_CREATE_WINDOW:"SHOW_PURCHASE_ORDER_CREATE_WINDOW",
    CLOSE_PURCHASE_ORDER_CREATE_WINDOW:"CLOSE_PURCHASE_ORDER_CREATE_WINDOW",
    
    CREATE_PURCHASE_ORDER_REQUEST: 'CREATE_PURCHASE_ORDER_REQUEST',
    CREATE_PURCHASE_ORDER_SUCCESS: 'CREATE_PURCHASE_ORDER_SUCCESS',
    CREATE_PURCHASE_ORDER_FAILURE: 'CREATE_PURCHASE_ORDER_FAILURE',

    UPDATE_PURCHASE_ORDER_REQUEST: 'UPDATE_PURCHASE_ORDER_REQUEST',
    UPDATE_PURCHASE_ORDER_SUCCESS: 'UPDATE_PURCHASE_ORDER_SUCCESS',
    UPDATE_PURCHASE_ORDER_FAILURE: 'UPDATE_PURCHASE_ORDER_FAILURE',

    DELETE_PURCHASE_ORDER_REQUEST: 'DELETE_PURCHASE_ORDER_REQUEST',
    DELETE_PURCHASE_ORDER_SUCCESS: 'DELETE_PURCHASE_ORDER_SUCCESS',
    DELETE_PURCHASE_ORDER_FAILURE: 'DELETE_PURCHASE_ORDER_FAILURE',  
    
    REMOVE_PURCHASE_ORDER_REQUEST: 'REMOVE_PURCHASE_ORDER_REQUEST',
    REMOVE_PURCHASE_ORDER_SUCCESS: 'REMOVE_PURCHASE_ORDER_SUCCESS',
    REMOVE_PURCHASE_ORDER_FAILURE: 'REMOVE_PURCHASE_ORDER_FAILURE',

    ARCHIVE_PURCHASE_ORDER_REQUEST: 'ARCHIVE_PURCHASE_ORDER_REQUEST',
    ARCHIVE_PURCHASE_ORDER_SUCCESS: 'ARCHIVE_PURCHASE_ORDER_SUCCESS',
    ARCHIVE_PURCHASE_ORDER_FAILURE: 'ARCHIVE_PURCHASE_ORDER_FAILURE',
    
    CHANGE_FILTER_PURCHASE_ORDERS : 'CHANGE_FILTER_PURCHASE_ORDERS',
    INITIALIZE_PURCHASE_ORDERS_USER_TEAMS: 'INITIALIZE_PURCHASE_ORDERS_USER_TEAMS',

    SHOW_PURCHASE_ORDER_VIEW_WINDOW:'SHOW_PURCHASE_ORDER_VIEW_WINDOW',
    CLOSE_PURCHASE_ORDER_VIEW_WINDOW:'CLOSE_PURCHASE_ORDER_VIEW_WINDOW',
};
