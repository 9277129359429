import { projectActions } from "_actions";
import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import { projectConstants } from "_constants";
import ProjectListTable from "./ProjectListTable";

class ProjectListPageTableSection extends Component {
    
    handleDeleteProject = (projectIdTobeDeleted, isDelete) => {
        store.dispatch(this.props.deleteProject(projectIdTobeDeleted, isDelete));
    }

    handleUpdateStatus = (projectId, status) => {
        store.dispatch(this.props.updateProjectStatus(projectId, status));
    }

    onViewProject = (projectId) => {
        store.dispatch(this.props.getProjectInfo(projectId));
        store.dispatch({ type: projectConstants.SHOW_PROJECT_VIEW_WINDOW });
    }

    handleRemoveProject = (projectId) => {
        store.dispatch(this.props.removeProject(projectId));
    }

    handleArchiveProject = (projectId, isActive) => {
        store.dispatch(this.props.archiveProject(projectId, isActive));
    }

    handleFavoriteProject = (projectId, isFavorite) => {
        store.dispatch(this.props.addToFavorites(projectId, commonUtils.getCurrentUserId(), isFavorite));
    }
    render() {
        return (
            <div>
                <ProjectListTable
                    items={this.props.items}
                    isLoading={this.props.isLoading}
                    handleDeleteProject={this.handleDeleteProject}
                    //handleEditProject={this.handleEditProject}
                    handleUpdateStatus={this.handleUpdateStatus}
                    //handleDuplicateProject={this.handleDuplicateProject}
                    handleArchiveProject={this.handleArchiveProject}
                    handleRemoveProject={this.handleRemoveProject}
                    handleFavoriteProject={this.handleFavoriteProject}
                    onViewProject={this.onViewProject}
                    permission={this.props.permission}
                    reloadProjectList={this.props.invokeProjectListService}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({

});
const mapDispatchToProps = dispatch => ({
    getProjectInfo: projectActions.getProjectInfo,
    getProjectList: projectActions.getProjectList,
    deleteProject: projectActions.deleteProject,
    removeProject: projectActions.removeProject,
    archiveProject: projectActions.archiveProject,
    addToFavorites: projectActions.addToFavorites,
    updateProjectStatus: projectActions.updateProjectStatus

});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectListPageTableSection);
