import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { commonUtils } from "_helpers";
import { validationUtils } from '_helpers'
import SidebarFooter from "./SidebarFooter";


class CreateHolidayModel extends Component {

  constructor() {
    super();
    this.state = {
      holidayItemmRequest: {},
      formErrors: {},
      hasFormError: false
    }
  }

  componentDidMount() {
    this.initializeRequest();
  }
  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }

  initializeRequest = () => {
    let holidayItemmRequest = this.props.editMode ? this.props.selectedHolidayItemmRequest :
      {
        defaultTeam: commonUtils.getCurrentUserTeamId()
      }
    this.setState({
      holidayItemmRequest: holidayItemmRequest
    });
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  onChange = (fieldName, value) => {
    this.onResetFormError()
    this.setState(prevState => ({
      holidayItemmRequest: {
        ...prevState.holidayItemmRequest,
        [fieldName]: value
      }
    }))
  }

  handleSubmit = (isCreateAnotherEnabled) => {
    let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.holidayItemmRequest, this.state.formErrors);
    //console.log("Validating form  request, isValid - " + isValid);
    //console.log(this.state.taskRequest);
    //console.log(this.state.formErrors)
    if (isValid) {
      this.props.handleSubmit(this.state.holidayItemmRequest, this.props.editMode, isCreateAnotherEnabled, !this.props.editMode ? this.initializeRequest : null);
    } else {
      this.setState({
        hasFormError: true
      })
    }
  }

  render() {
    return (
      <div id="createworkItemsidebar" className={`shadow-lg bg-white rounded workitem model ${this.props.show === false ? 'closed' : 'p-1 mb-2'}`}>
        <div className="card-body pl-3 pr-3">
          <div className="row">
            <div className="col-12 row">
              <div className="col-6">
                <CustomInput inputtype='text'
                  label="Name"
                  name="name"
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.holidayItemmRequest}
                  onChange={this.onChange}
                  onFormError={this.onFormError}
                  placeholder="Name..."
                  datatype='alpnumspl' />
              </div>
              <div className="col-6">
                <CustomInput readOnly={this.props.readOnly}
                  type='dateselect'
                  label="Holiday Date"
                  name="holidayDate"
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.holidayItemmRequest}
                  onChange={this.onChange}
                  time={false}
                  onFormError={this.onFormError}
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date(2100, 0, 1)}
                  currentDate={new Date()}
                />

              </div>

            </div>

            <div className="col-12 row">
              <div className="col-12">
                <CustomInput readOnly={this.props.readOnly}
                  type='select'
                  label="Locations"
                  name="locations"
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.holidayItemmRequest}
                  onChange={this.onChange}
                  placeholder="Location..."
                  isAppConfig={true} 
                  createConfig={this.props.createConfig}
                  onFormError={this.onFormError}
                  configName={"location"}
                  multi={true}
                />
              </div>
            </div>
          </div>
        </div>
        <SidebarFooter
          handleSubmit={this.handleSubmit}
          editMode={this.props.editMode}
          closeButtonLabel={'Close'}
          initializeRequest={this.props.onCloseCreateItem}
          hasFormError={this.state.hasFormError}
          onResetFormError={this.onResetFormError}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  taskInfo: state.tasks.selectedTaskInfo,
  isLoading: state.tasks.loading,
  fieldConfig: state.employees.holidayFieldConfig,
  createConfig: state.employees.createConfig
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateHolidayModel);

