import { scheduleService } from '_services';
import { scheduleConstants } from '../_constants';

export const scheduleActions = {
    createSchedule,
    updateSchedule,
    getSchedules,
    enableSchedule,
    removeSchedule

};

function createSchedule(scheduleRequest, scheduleType) {
    return dispatch => {
        dispatch(request());
        scheduleService.createSchedule(scheduleRequest, scheduleType)
            .then(
                schedule => dispatch(success(schedule.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: scheduleConstants.CREATE_SCHEDULE_REQUEST }
    }
    function success(schedule) { return { type: scheduleConstants.CREATE_SCHEDULE_SUCCESS, schedule } }
    function failure(error) { return { type: scheduleConstants.CREATE_SCHEDULE_FAILURE, error } }

}

function updateSchedule(scheduleRequest, scheduleType) {
    return dispatch => {
        dispatch(request());
        scheduleService.updateSchedule(scheduleRequest, scheduleType)
            .then(
                schedule => dispatch(success(scheduleRequest.id, schedule.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: scheduleConstants.UPDATE_SCHEDULE_REQUEST }
    }
    function success(id, schedule) { return { type: scheduleConstants.UPDATE_SCHEDULE_SUCCESS, id, schedule } }
    function failure(error) { return { type: scheduleConstants.UPDATE_SCHEDULE_FAILURE, error } }

}

function getSchedules(scheduleRequest) {
    return dispatch => {
        dispatch(request());
        scheduleService.getSchedules(scheduleRequest)
            .then(
                schedule=> dispatch(success(schedule.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: scheduleConstants.GETALL_SCHEDULES_REQUEST }
    }
    function success(schedules) { return { type: scheduleConstants.GETALL_SCHEDULES_SUCCESS, schedules } }
    function failure(error) { return { type: scheduleConstants.GETALL_SCHEDULES_FAILURE, error } }

}

function enableSchedule(scheduleRequest) {
    return dispatch => {
        dispatch(request());
        scheduleService.enableSchedule(scheduleRequest)
            .then(
                schedule => dispatch(success(schedule.payload, scheduleRequest.itemId, scheduleRequest.isEnabled)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: scheduleConstants.ENABLE_SCHEDULE_REQUEST }
    }
    function success(schedule, itemId, isEnabled) { return { type: scheduleConstants.ENABLE_SCHEDULE_SUCCESS, schedule, itemId, isEnabled } }
    function failure(error) { return { type: scheduleConstants.ENABLE_SCHEDULE_FAILURE, error } }
}

function removeSchedule(scheduleRequest) {
    return dispatch => {
        dispatch(request());
        scheduleService.removeSchedule(scheduleRequest)
            .then(
                schedule => dispatch(success(schedule.payload, scheduleRequest.itemId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return { type: scheduleConstants.REMOVE_SCHEDULE_REQUEST }
    }
    function success(schedule, itemId) { return { type: scheduleConstants.REMOVE_SCHEDULE_SUCCESS, schedule , itemId} }
    function failure(error) { return { type: scheduleConstants.REMOVE_SCHEDULE_FAILURE, error } }
}
