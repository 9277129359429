import React, { Component } from "react";
import Menu from "./fragments/Menu";

class PageLeftMenu extends Component {
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  render() {
    return (
      <div id="left-sidebar" className="sidebar no-print ">
        <h5 className="brand-name">famstack<button className="link menu_option float-right"><i className="icon-grid font-16" data-toggle="tooltip" data-placement="left" title="Grid & List Toggle" /></button></h5>
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <ul className="metismenu">
            <li className="g_heading">Directories</li>
            {this.props.menuAccessControl && this.props.menuAccessControl.hrmsModules.active === true &&
              <Menu {...this.props} routes={this.props.routes} menuName={"HRMS"} layoutName="/hrms" icon={"fa fa-users"} />
            }
             {this.props.menuAccessControl && this.props.menuAccessControl.salesModules.active === true &&
              < Menu {...this.props} routes={this.props.routes} menuName={"Sales"} layoutName="/sales" icon={"icon-briefcase"} />
            }
            {this.props.menuAccessControl && this.props.menuAccessControl.projectModules.active === true &&
              < Menu {...this.props} routes={this.props.routes} menuName={"Project"} layoutName="/project" icon={"icon-rocket"} />
            }
          </ul>
        </nav>
      </div>
    );
  }
}

export default PageLeftMenu;
