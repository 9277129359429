import React from 'react'
//import Slider from '@material-ui/lab/Slider'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from 'views/common/plugins/image/canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { getRotatedImage } from 'views/common/plugins/image/canvasUtils'

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}
class ImageUpload extends React.Component {

    constructor() {
        super()
        this.imgFileInput = React.createRef();
    }
   
    state = {
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1,
        showSaveButton: false
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        //console.log(croppedAreaPixels.width / croppedAreaPixels.height)
        //console.log(croppedAreaPixels);
        this.setState({ croppedAreaPixels })
    }

    readFile(file) {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    onFileChange = async e => {
        //console.log("On change file");
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageSrc = await this.readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageSrc = await getRotatedImage(imageSrc, rotation)
            }

            this.setState({ imageSrc, showSaveButton: true })
        }
    }

    onSaveImage = () => {
        try {
            const croppedImage = getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels,
                0
            )
            //console.log("croppedImage ", { croppedImage });
            croppedImage.then((result) => {
                this.setState({ showSaveButton: false, imageSrc: result })
            });

        } catch (e) {
            console.error(e)
        }
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    render() {
        return (
            <>
                <div className="crop-container">
                    {this.state.showSaveButton === false &&
                        <img className="profileImgHolder" src={this.state.imageSrc || this.props.imageUrl}></img>
                    }
                    {this.state.showSaveButton === true && !this.props.readOnly && <Cropper
                        image={this.state.imageSrc}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        showGrid={false}
                        cropShape="round"
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                    }
                    {!this.props.readOnly &&

                        <span className="imageChangeButton" >
                            <i className="fa fa-camera fa-lg" onClick={(e) => this.imgFileInput.click()}></i>
                            {this.state.showSaveButton === true && <i className="fa fa-save fa-lg pl-3 colorred blink_text" onClick={this.onSaveImage}></i>}
                        </span>

                    }
                </div>
                {!this.props.readOnly &&
                    <div className="controls">
                        <input className="d-none" ref={input => this.imgFileInput = input} type="file" onChange={this.onFileChange} />
                    </div>
                }
            </>
        )
    }
}

export default ImageUpload;