import React, { Component } from "react";

class ProjectDetailsFiles extends Component {
    render() {
        return (
            <div className="card mb-0 h-100">
            <div className="card-header">
              <h3 className="card-title">Files</h3>
            </div>
            <div className="card-body">
              <div className="file_folder">
                <button className="link mx-3" >
                  <div className="icon">
                    <i className="fa fa-folder text-success" />
                  </div>
                  <div className="file-name">
                    <p className="mb-0 text-muted">Project</p>
                    <small>3 File, 1.2GB</small>
                  </div>
               </button>
                <button className="link mx-3" >
                  <div className="icon">
                    <i className="fa fa-file-word-o text-primary" />
                  </div>
                  <div className="file-name">
                    <p className="mb-0 text-muted">Report2017.doc</p>
                    <small>Size: 68KB</small>
                  </div>
               </button>
                <button className="link mx-3" >
                  <div className="icon">
                    <i className="fa fa-file-pdf-o text-danger" />
                  </div>
                  <div className="file-name">
                    <p className="mb-0 text-muted">Report2017.pdf</p>
                    <small>Size: 68KB</small>
                  </div>
               </button>
                <button className="link mx-3" >
                  <div className="icon">
                    <i className="fa fa-file-pdf-o text-danger" />
                  </div>
                  <div className="file-name">
                    <p className="mb-0 text-muted">Report2017.pdf</p>
                    <small>Size: 68KB</small>
                  </div>
               </button>
              </div>
            </div>
          </div>
        );
    }
}

export default ProjectDetailsFiles;
