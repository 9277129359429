export const bidRequestsConstants = {
    INITIALIZE_CREATE_BID_REQUEST_REQUEST : 'INITIALIZE_CREATE_BID_REQUEST_REQUEST',
    INITIALIZE_CREATE_BID_REQUEST_SUCCESS : 'INITIALIZE_CREATE_BID_REQUEST_SUCCESS',
    INITIALIZE_CREATE_BID_REQUEST_FAILURE : 'INITIALIZE_CREATE_BID_REQUEST_FAILURE',

    GETALL_BID_REQUESTS_REQUEST: 'GETALL_BID_REQUESTS_REQUEST',
    GETALL_BID_REQUESTS_SUCCESS: 'GETALL_BID_REQUESTS_SUCCESS',
    GETALL_BID_REQUESTS_FAILURE: 'GETALL_BID_REQUESTS_FAILURE',

    GETALL_ACTIVE_BID_REQUEST_REQUEST: 'GETALL_ACTIVE_BID_REQUEST_REQUEST',
    GETALL_ACTIVE_BID_REQUEST_SUCCESS: 'GETALL_ACTIVE_BID_REQUEST_SUCCESS',
    GETALL_ACTIVE_BID_REQUEST_FAILURE: 'GETALL_ACTIVE_BID_REQUEST_FAILURE',

    GET_BID_REQUEST_REQUEST: 'GET_BID_REQUEST_REQUEST',
    GET_BID_REQUEST_SUCCESS: 'GET_BID_REQUEST_SUCCESS',
    GET_BID_REQUEST_FAILURE: 'GET_BID_REQUEST_FAILURE',
    
    SHOW_BID_REQUEST_CREATE_WINDOW:"SHOW_BID_REQUEST_CREATE_WINDOW",
    CLOSE_BID_REQUEST_CREATE_WINDOW:"CLOSE_BID_REQUEST_CREATE_WINDOW",
    
    CREATE_BID_REQUEST_REQUEST: 'CREATE_BID_REQUEST_REQUEST',
    CREATE_BID_REQUEST_SUCCESS: 'CREATE_BID_REQUEST_SUCCESS',
    CREATE_BID_REQUEST_FAILURE: 'CREATE_BID_REQUEST_FAILURE',

    UPDATE_BID_REQUEST_REQUEST: 'UPDATE_BID_REQUEST_REQUEST',
    UPDATE_BID_REQUEST_SUCCESS: 'UPDATE_BID_REQUEST_SUCCESS',
    UPDATE_BID_REQUEST_FAILURE: 'UPDATE_BID_REQUEST_FAILURE',

    DELETE_BID_REQUEST_REQUEST: 'DELETE_BID_REQUEST_REQUEST',
    DELETE_BID_REQUEST_SUCCESS: 'DELETE_BID_REQUEST_SUCCESS',
    DELETE_BID_REQUEST_FAILURE: 'DELETE_BID_REQUEST_FAILURE',  
    
    REMOVE_BID_REQUEST_REQUEST: 'REMOVE_BID_REQUEST_REQUEST',
    REMOVE_BID_REQUEST_SUCCESS: 'REMOVE_BID_REQUEST_SUCCESS',
    REMOVE_BID_REQUEST_FAILURE: 'REMOVE_BID_REQUEST_FAILURE',

    ARCHIVE_BID_REQUEST_REQUEST: 'ARCHIVE_BID_REQUEST_REQUEST',
    ARCHIVE_BID_REQUEST_SUCCESS: 'ARCHIVE_BID_REQUEST_SUCCESS',
    ARCHIVE_BID_REQUEST_FAILURE: 'ARCHIVE_BID_REQUEST_FAILURE',
    
    CHANGE_FILTER_BID_REQUEST : 'CHANGE_FILTER_BID_REQUEST',
    INITIALIZE_BID_REQUEST_USER_TEAMS: 'INITIALIZE_BID_REQUEST_USER_TEAMS',

    SHOW_BID_REQUEST_VIEW_WINDOW:'SHOW_BID_REQUEST_VIEW_WINDOW',
    CLOSE_BID_REQUEST_VIEW_WINDOW:'CLOSE_BID_REQUEST_VIEW_WINDOW',
};
