import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PageInnerHeader from "views/common/PageInnerHeader";
import { accessUtils } from "_helpers";
import { store } from "_helpers";

class ToDoListPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      permission: accessUtils.getProjectAccessControl(),
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    if (!this.props.items) {
      //this.invokeToDoListService();
    }
  }

  invokeToDoListService = () => {

  }

  handleDeleteTodo = (id, isDelete) => {

    // store.dispatch(this.props.deleteTodo(projectIdTobeDeleted, isDelete));
  }

  handleAddToDo = (id) => {
    //store.dispatch(this.props.removeTodo(id));
  }

  render() {
    return (
      <div className="projectlist">

        <PageInnerHeader
          showCreateMenu={this.state.permission.createEnabled}
          showGridListMenu={false}
          searchViewEnabled={false}
          showFilter={true}
          showUserTeamMenu={false}
          isLoading={this.props.isLoading}
          onFilterTextChange={this.onFilterTextChange}
          showCreateMenuText="ADD TODO"
          createItemHandler={this.onCreateItemClick}
        >
        </PageInnerHeader>
        {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
          <>
            <div className="section-body ml-3">
              <div className="container-fluid">
                <div className="row">
                  <div className="table-responsive todo_list">
                    <table className="table table-hover table-striped table-vcenter mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="w-150 text-right">Due</th>
                          <th className="w-100">Priority</th>
                          <th className="w-80"><i className="icon-user"></i></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" name="example-checkbox1" value="option1" />
                              <span className="custom-control-label">Create tasks for my team members</span>
                            </label>
                          </td>
                          <td className="text-right">March 12-2023</td>
                          <td><span className="tag tag-danger ml-0 mr-0">HIGH</span></td>
                          <td>
                            <span className="avatar avatar-pink" data-toggle="tooltip" data-placement="top" title="" data-original-title="Avatar Name">NG</span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        }


      </div>
    );
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication,
  isLoading: true,
  items: {},
});
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(ToDoListPage);