import React, { Component } from "react";
import DateRangeSelector from "./DateRangeSelector";
import classNames from 'classnames/bind';

class PageInnerHeaderSearchDateMenu extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            calendarView: true,
            searchView: false,
            searchOption: 'All',
            searchTerm: ""
        };
    }

    componentDidMount(){
        this.setState({
            searchOption:this.props.defaultSearchViewOpiton
        })
    }
    onChange =(name, value) => {
    
        this.setState(prevState => ({
            ...prevState,
            [name]:value
        }))
    }

    onToggleView = (viewName, value) => {
        this.setState({
            calendarView: false,
            searchView: false,
            [viewName]: value
        });
    };
    handleSearchSubmit =()=>{
        if (this.state.searchTerm && this.state.searchTerm != "" ) {
            this.props.handleSearchSubmit(this.state.searchOption, this.state.searchTerm);
        }
    }
    render() {
        return (
            <>
                <div className="header-action d-md-flex pt-2">
                    {this.state.searchView && this.props.searchViewEnabled &&
                        <>
                            <select className="custom-select search" value={this.state.searchOption} onChange={(e) => this.onChange("searchOption", e.target.value)} disabled={this.props.isLoading}>
                                {this.props.searchViewOpitons && Object.keys(this.props.searchViewOpitons).map((key, index) =>
                                 <option  key={key} value={key}>{this.props.searchViewOpitons[key]}</option>
                                )}
                            </select>
                            <div className="input-icon px-1"><span className="input-icon-addon"><i className="fa fa-search"></i></span>
                                <input type="text" onChange={(e) => this.onChange("searchTerm", e.target.value)} value={this.state.searchTerm} className="form-control input-sm filter" placeholder="Search..." />
                            </div>
                            <button className="link plainbutton px-1 fsize12 mr-3 text-capitalize border" onClick={()=>this.handleSearchSubmit()}>
                                Search
                            </button>
                        </>
                    }
                    {this.state.calendarView && this.props.searchViewEnabled &&
                        <button className="link" onClick={() => this.onToggleView("searchView", true)}>
                            <i className="fa fa-search fa-lg pr-1" />
                        </button>
                    }
                    {this.state.searchView &&
                        <button className="link" onClick={() => this.onToggleView("calendarView", true)}>
                            <i className="fa fa-calendar fa-lg pr-1" />
                        </button>
                    }
                </div>
                {
                    this.state.calendarView &&
                    <DateRangeSelector
                        defaultDateRangeValue={this.props.defaultDateRangeValue}
                        onDateRangeChange={this.props.handleDateRangeChange}
                        isLoading={this.props.isLoading}
                    />
                }
            </>
        )
    }
}

export default PageInnerHeaderSearchDateMenu