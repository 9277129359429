import React, { Component } from "react";
import { connect } from "react-redux";
import { accessUtils } from "_helpers";
import BidRequestsDetailsGeneral from "../BidRequestsDetailsGeneral";

class ViewBidRequestsDetailsSidebar extends Component {
  constructor() {
    super();
    this.state = {
      permission: accessUtils.getBidRequestsAccessControl()
    }
  }
  render() {
    return (
      <div id="createbidrequestsidebar" className={`right_sidebar view_bid_request_right_sidebar  ${this.props.show ? "open" : ""}`}>
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">View BidRequests Details</h3>
            <div className="card-options">
              <button onClick={this.props.onCloseViewBidRequests} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          {this.props.show &&
           <div className="card-body px-0 overflow-auto">
            <BidRequestsDetailsGeneral 
                item={this.props.bidRequestsInfo} 
                readOnly={true}
                permission={this.state.permission}
                />
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bidRequestsInfo: state.bidRequests.selectedBidRequestsInfo,
  isLoading: state.projects.loading
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewBidRequestsDetailsSidebar);

