import { appConfigConstants } from "_constants";
export function appconfig(state = {}, action) {
  
  switch (action.type) {
    case appConfigConstants.INIALIZE_CONFIG_SUCCESS:
      return {
        ...state,
        configData: action.appConfig.config,
        selectedConfigData: action.appConfig.config[action.appConfig.id],
        configMenuData: action.appConfig.menu,
        appConfigMenuSelection: {
          "id": action.appConfig.id,
          "type": action.appConfig.type,
          "category": action.appConfig.category
        },
        parentMenuOptions: getMenuItemOptions(action.appConfig.menu[action.appConfig.type].nodes, action.appConfig.id)
      }
    case appConfigConstants.GET_CONFIG_SUCCESS:
      return {
        ...state,
        configData: action.appConfig.config,
        selectedConfigData: action.appConfig.config[action.appConfig.id],
        parentMenuOptions: state.configMenuData && state.configMenuData[action.appConfig.type] && getMenuItemOptions(state.configMenuData[action.appConfig.type].nodes, action.appConfig.id)
      }
      case appConfigConstants.RESET_CONFIG_REQUEST:
        return {
          ...state,
          selectedConfigData: null
        }
      case appConfigConstants.UPDATE_CONFIG_SUCCESS:
        return {
          ...state,
          configData: action.appConfig.config,
          selectedConfigData: action.appConfig.config[action.appConfig.id]
        }
    case appConfigConstants.CONFIG_MENU_CHANGE:
      return {
        ...state,
        appConfigMenuSelection: {
          "id": action.configId,
          "type": action.configType
        },
        selectedConfigData: null
      }
    case appConfigConstants.CREATE_CONFIG_SUCCESS:
      return updateConfigStates(state, action);
    case appConfigConstants.CONFIG_PROPERTY_UPDATE_SUCCESS:
      return updateConfigStates(state, action)

    case appConfigConstants.INIALIZE_ALL_ROLES_SUCCESS:
      return {
        ...state,
        allRoles: action.roles
      }

      case appConfigConstants.INIALIZE_ALL_ROLES_GROUP_SUCCESS:
        return {
          ...state,
          allSubRoles: action.roles
        }

    case appConfigConstants.INIALIZE_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        allTeams: action.teams
      }

    case appConfigConstants.INIALIZE_ALL_TEAMS_GROUP_SUCCESS:
      return {
        ...state,
        allUserTeamsByGroup: action.teams
      }
    case appConfigConstants.DELETE_CONFIG_ITEM_SUCCESS:
    case appConfigConstants.REMOVE_CONFIG_ITEM_SUCCESS:
      return {
        ...state,
        allUserTeamsByGroup: state.allUserTeamsByGroup && getAppconfigItemsAfterDeleteItems(state.allUserTeamsByGroup, action.itemId),
        allTeams: state.allTeams && state.allTeams.filter(appConfigItem => appConfigItem.id !== action.itemId)
      }
      case appConfigConstants.SHOW_SETTINGS_WINDOW:
        return {
          ...state,
          showSettingsWindow: !state.showSettingsWindow
        }
  
      case appConfigConstants.CLOSE_SETTINGS_WINDOW:
        return {
          ...state,
          showSettingsWindow: false
      }
    default:
      return state
  }
}

function getAppconfigItemsAfterDeleteItems(allUserTeamsByGroup, itemId) {
  allUserTeamsByGroup = allUserTeamsByGroup && allUserTeamsByGroup.filter(appConfigItem => appConfigItem.id !== itemId);
   allUserTeamsByGroup && allUserTeamsByGroup.forEach(userTeamParent => {
    userTeamParent.nodes = userTeamParent.nodes && userTeamParent.nodes.filter(appConfigItem => appConfigItem.id !== itemId);
  }
  );
  return allUserTeamsByGroup;
}

function updateConfigStates(state, action) {
  return {
    ...state,
    configData: action.appConfig.config,
    selectedConfigData: action.appConfig.config[action.appConfig.id],
    configMenuData: {
      ...state.configMenuData,
      [action.appConfig.type]: action.appConfig.menu[action.appConfig.type]
    },
    appConfigMenuSelection: {
      "id": action.appConfig.id,
      "type": action.appConfig.type,
      "category": action.appConfig.category
    },
    parentMenuOptions: getMenuItemOptions(action.appConfig.menu[action.appConfig.type].nodes, action.appConfig.id)
  };
}

function getMenuItemOptions(nodes, parentId) {
  let menuItemOptions = [];
  iterateMenuItemOptions(nodes, parentId, parentId, menuItemOptions);
  return menuItemOptions;
}
function iterateMenuItemOptions(nodes, parentId, currentConfigId, menuItemOptions) {
  if (nodes) {
    nodes.map((node, index) => {
      //console.log("parentId : " + parentId + "currentConfigId : " + currentConfigId + "node id : " + node.id);
      menuItemOptions.push({ "label": node.name, "value": node.id, "parent": parentId , "category": node.category });
      if (node.id !== currentConfigId) {
        iterateMenuItemOptions(node.nodes, node.id, currentConfigId, menuItemOptions);
      }
      return node
    });
  }
  return menuItemOptions;
}
