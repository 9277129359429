import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import PageInnerHeaderAccounts from "views/common/PageInnerHeaderAccounts";
import { accountActions } from "_actions";
import { appConfigActions } from "_actions";
import { projectConstants } from "_constants";
import { accessUtils } from "_helpers";
import { commonUtils } from "_helpers";
import { store } from "_helpers";
import ItemNotFoundSection from "./components/ItemsNotFoundSection";
import CreateAccountsSidebar from "./components/modal/CreateAccountsSidebar";

class AccountsListPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      parentItem: {},
      breadCrumps: [],
      viewName: "USERTEAMS",
      viewByAccounts: false,
      permission: accessUtils.getAccountsAccessControl(),
      userTeamsPermission: accessUtils.getUserTeamsAccessControl()
    }

  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);

    //let urlView = new URLSearchParams(this.props.location.search).get("view");
    //let index = new URLSearchParams(this.props.location.search).get("index");
    /*this.initializeAccounts(urlParentItemId, urlView, index)
   if(this.state.breadCrumps && this.state.breadCrumps.length > 0) {
     this.onSelectItemBack(this.state.breadCrumps[index], urlView, index)
   }*/

    if (!this.props.userTeamsByGroup) {
      this.initializeUserTeams()
    }
  }

  onShowAllByAccounts = () => {
    this.setState({
      viewName: "ACCOUNTS",
      viewByAccounts: true,
      breadCrumps: [],
      parentItem: {}
    })
    store.dispatch(this.props.getProjectAllAccounts());
  }

  onShowAllByUserTeams = () => {
    if (!this.props.userTeamsByGroup) {
      this.initializeUserTeams()
    }
    this.setState({
      viewName: "USERTEAMS",
      viewByAccounts: false,
      breadCrumps: [],
      parentItem: {}
    })
  }

  onSelectItemBack = (currentItem, viewName, index) => {
    let breadCrumps = [...this.state.breadCrumps]
    this.setState({
      parentItem: currentItem
    })
    this.initializeAccounts(currentItem, viewName, breadCrumps.slice(0, index + 1));
  }

  onSelectItem = (currentItem, viewName, index) => {
    let breadCrumps = [...this.state.breadCrumps]
    breadCrumps.push({ viewName, currentItem })

    this.setState((prevState) =>
    ({
      parentItem: currentItem,
    }), () => {
      this.initializeAccounts(currentItem, viewName, breadCrumps);
    });
  }
  initializeAccounts = (currentItem, viewName, breadCrumps) => {
    this.onCloseCreateItem();
    this.props.pageHeaderNameChangeHandler(viewName.charAt(0) + viewName.substr(1).toLowerCase());
    if (viewName === 'ACCOUNT') {
      if (currentItem) {
        store.dispatch(this.props.getProjectTeamAccounts(currentItem.id));
      } else {
        store.dispatch(this.props.getProjectAllAccounts());
        this.setState({
          parentItem: {}
        })
      }
    } else if (viewName === 'TEAM') {
      store.dispatch({ type: projectConstants.GET_ALL_CURRENT_VIEW_ACCOUNTS_SUCCESS, items: currentItem.teams, viewName: 'CLIENT' });
    } else if (viewName === 'CLIENT') {
      store.dispatch({ type: projectConstants.GET_ALL_CURRENT_VIEW_ACCOUNTS_SUCCESS, items: currentItem.clients, viewName: 'CLIENT' });
    }
    this.setState({
      viewName: viewName,
      breadCrumps: breadCrumps
    })
  }
  initializeUserTeams = () => {
    store.dispatch(appConfigActions.getAllTeams(true));
  }

  handleRemoveAccountItem = (itemId) => {
    store.dispatch(this.props.removeAccountItem(itemId));
  }

  handleRemoveUserTeamItem = (itemId) => {
    store.dispatch(this.props.removeUserTeamItem(itemId));

  }
  handleDeleteUserTeamItem = (itemId, isDelete) => {
    store.dispatch(this.props.deleteUserTeamItem(itemId, isDelete));
  }

  handleDetachFromTeam = (itemId) => {
    store.dispatch(this.props.detachFromAccountItem(itemId, this.state.breadCrumps[0].currentItem.id, this.state.parentItem.id));
  }

  createItemClickHandler = (e) => {
    e.preventDefault();
    store.dispatch(this.props.initializeCreateAccounts(this.state.breadCrumps[0].currentItem.id, this.state.parentItem.id, this.state.viewName, true, false));
    store.dispatch({ type: projectConstants.SHOW_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW });
  }

  onCloseCreateItem = (e) => {
    store.dispatch({ type: projectConstants.CLOSE_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW });
  }

  handleSubmit = (accountsRequest, isNewItemSelected, createAnotherEnabled, submitCallBackFunction) => {
    if (isNewItemSelected) {
      store.dispatch(this.props.createAccountItem(accountsRequest, createAnotherEnabled, submitCallBackFunction));
    } else {
      store.dispatch(this.props.addToAccountItem(accountsRequest.id, this.state.breadCrumps[0].currentItem.id, this.state.parentItem.id, createAnotherEnabled, submitCallBackFunction));
    }
  }

  onFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: projectConstants.CHANGE_FILTER_ACCOUNTS, filterOptions });
      });
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.onFilterChange(this.state.filterOptions);
      }
    );
  }



  render() {
    return (
      <div className="projectlist">

        <PageInnerHeaderAccounts
          showCreateMenu={this.state.permission.createEnabled && this.state.viewName !== 'USERTEAMS' && this.state.viewByAccounts === false}
          breadCrumps={this.state.breadCrumps}
          showFilter={this.state.viewName !== "USERTEAMS"}
          viewByAccounts={this.state.viewByAccounts}
          filterOptions={this.state.filterOptions}
          isLoading={this.props.isLoading}
          onFilterTextChange={this.onFilterTextChange}
          onSelectItem={this.onSelectItemBack}
          showCreateMenuText={this.state.viewName}
          showGridListMenu={this.state.permission.showAllByAccounts}
          showGridDefault={true}
          gridClickHandler={this.onShowAllByUserTeams}
          listClickHandler={this.onShowAllByAccounts}
          createItemHandler={this.createItemClickHandler}
          permission={this.state.permission}
        >
        </PageInnerHeaderAccounts>
        <>
          <div className="section-body">
            <div className="container-fluid">


              {this.state.viewName && this.state.viewName === "USERTEAMS" &&
                <div className="row clearfix mt-2">
                  {this.props.allUserTeamsByGroup && commonUtils.sortArrayOfObjects(this.props.allUserTeamsByGroup, true).map(userTeamParent =>
                    <React.Fragment key={userTeamParent.id}>
                      {userTeamParent.nodes && commonUtils.sortArrayOfObjects(userTeamParent.nodes, true).map((userTeam, index) =>
                        (this.state.permission.showAllUserTeamsAccount || commonUtils.getCurrentUserTeamIds().includes(userTeam.id)) &&
                        <ItemDetails
                          key={userTeam.id}
                          item={userTeam}
                          parentItem={userTeamParent}
                          onSelectItem={this.onSelectItem}
                          index={0}
                          viewName="ACCOUNT"
                          handleRemoveItem={this.handleRemoveUserTeamItem}
                          handleDetachFromTeam={this.handleDeleteUserTeamItem}
                          viewByAccounts={this.state.viewByAccounts}
                          permission={this.state.userTeamsPermission}
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              }
              {this.state.viewName && this.state.viewName !== "USERTEAMS" &&
                <>                   <div className="row clearfix mt-2">
                  {this.props.items && commonUtils.sortArrayOfObjects(this.props.items, true).map((item, index) =>
                    <ItemDetails
                      key={item.id}
                      item={item}
                      parentItem={this.state.parentItem}
                      onSelectItem={this.onSelectItem}
                      index={2}
                      handleDetachFromTeam={this.handleDetachFromTeam}
                      viewByAccounts={this.state.viewByAccounts}
                      viewName={this.props.viewName}
                      handleRemoveItem={this.handleRemoveAccountItem}
                      permission={this.state.permission}
                    />
                  )}
                </div>
                  <ItemNotFoundSection items={this.props.items} isLoading={this.props.isLoading} imgurl="noaccounts-min.PNG" text={`No Items found ?`} description="Try Creating it from menu..." />
                </>
              }
            </div>
          </div>
        </>
        {
          this.props.showCreateModel &&
          <CreateAccountsSidebar
            show={this.props.showCreateModel}
            selectedUserTeamItem={this.state.breadCrumps && this.state.breadCrumps.length > 0 && this.state.breadCrumps[0].currentItem}
            selectedParentItem={this.state.parentItem}
            viewName={this.state.viewName}
            handleSubmit={this.handleSubmit}
            closeActionHandler={this.onCloseCreateItem} />
        }
      </div >
    );
  }
}


class ItemDetails extends Component {

  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }

  handleRemoveItem = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveItem(this.props.item.id);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }

  render() {
    return (
      <>
        {this.props.item && this.props.parentItem &&
          <div className="col-lg-2 col-md-6" key={this.props.item.id}>
            <div className="card">
              <div className="card-header ribbon">
                <div className="ribbon-box green">
                  {this.props.item.id}
                </div>
                <div className="card-options">
                  {this.props.permission.removeEnabled &&
                    <button className="link card-options-remove" onClick={() => this.handleRemoveItem(this.state.confrimDelete)}>
                      {this.state.confrimDelete === true &&
                        <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
                      }
                      {this.state.confrimDelete === false &&
                        <i className="dropdown-icon fa fa-times colorred"></i>
                      }
                    </button>
                  }
                </div>
              </div>
              <div className="card-body text-center">
                {this.props.item.type !== 'CLIENT' &&
                  <NavLink className="link"
                    to={"/project/accounts?index=" + this.props.index}
                    onClick={() => this.props.onSelectItem(this.props.item, this.props.viewName, this.props.index)} >
                    <h6 className="mt-3">{this.props.item.name}</h6>
                  </NavLink>

                }
                {this.props.item.type === 'CLIENT' &&
                  <h6 className="mt-3">{this.props.item.name}</h6>
                }
                <div className="text-center text-muted mb-3">{this.props.parentItem.name}</div>
                {this.props.permission.editEnabled && !this.props.viewByAccounts &&
                  <button type="button" className="btn btn-icon btn-outline-primary mr-3"><i className="fa fa-pencil"></i></button>
                }
                {this.props.permission.deleteEnabled && !this.props.viewByAccounts &&
                  <button onClick={() => this.props.handleDetachFromTeam(this.props.item.id)} type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-unlink"></i></button>
                }
              </div>
              <div className="d-flex">
                <div className="ml-auto pr-2"><i className="fe fe-chevron-up"></i></div>
              </div>
              {(this.props.item.type === "ACCOUNT" || this.props.item.type === "TEAM") &&
                <div className="card-footer text-center">
                  <div className="row clearfix">
                    {this.props.item.type === 'ACCOUNT' &&
                      <div className="col-6">
                        <h6 className="mb-0">{this.props.item.noOfTeams | 0}</h6>
                        <div className="text-muted">Teams</div>
                      </div>
                    }
                    <div className="col-6">
                      <h6 className="mb-0">{this.props.item.noOfClients | 0}</h6>
                      <div className="text-muted">Clients</div>
                    </div>
                  </div>

                  <div className="d-none row clearfix separator pt-2">
                    <div className="col-6">
                      <h6 className="mb-0">105</h6>
                      <div className="text-muted fsize12">On Going Projects</div>
                    </div>
                    <div className="col-6">
                      <h6 className="mb-0">3100</h6>
                      <div className="text-muted fsize12">Total Projects</div>
                    </div>
                  </div>

                </div>
              }
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication,
  allUserTeamsByGroup: state.appconfig.allUserTeamsByGroup,
  items: commonUtils.filterAccounts(state.accounts.currentViewItems, state.accounts.filterOptions),
  viewName: state.accounts.viewName,
  allProjectTeamAccounts: state.accounts.allProjectTeamAccounts,
  showCreateModel: state.accounts.showCreateModel
});
const mapDispatchToProps = dispatch => ({
  getProjectTeamAccounts: accountActions.getProjectTeamAccounts,
  getProjectAllAccounts: accountActions.getProjectAllAccounts,
  initializeCreateAccounts: accountActions.initializeCreateAccounts,
  createAccountItem: accountActions.createAccountItem,
  updateAccountItem: accountActions.updateAccountItem,
  removeAccountItem: accountActions.removeAccountItem,
  detachFromAccountItem: accountActions.detachFromAccountItem,
  addToAccountItem: accountActions.addToAccountItem,
  removeUserTeamItem: appConfigActions.removeUserTeamItem,
  deleteUserTeamItem: appConfigActions.deleteUserTeamItem,
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountsListPage);