import React, { PureComponent } from "react";
import EmployeeListGrids from "./components/EmployeeListGrids";
import EmployeeListTable from "./components/EmployeeListTable";
import { employeeActions } from '_actions';
import { connect } from 'react-redux';
import { store } from "_helpers";
import Alert from "../common/fragments/Alert";
import PageInnerHeader from "../common/PageInnerHeader";
import CreateEmployeeSidebar from "./components/modal/CreateEmployeeSidebar";
import EmployeePageSummaryItem from "./components/fragments/EmployeePageSummaryItem";
import { employeeConstants } from '_constants';
import { commonUtils } from '_helpers';
import { accessUtils } from "_helpers";
import { projectActions } from "_actions";

class EmployeesPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      showGrid: true,
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ACTIVE", label: "Active" }, { name: "INACTIVE", label: "Inactive" }], selected: "ACTIVE" },
        SORT: { options: [{ name: "A-Z", label: "A-Z" }], selected: "A-Z", directionUp: true },
        filterText: null
      },
      permission: accessUtils.getEmployeeAccessControl(),
    }
  }
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    store.dispatch({ type: employeeConstants.INITIALIZE_EMPLOYEE_USER_TEAMS, userTeams });
    !this.props.items && this.reloadEmployeeItems(userTeams);
  }

  onCreateItemClick = (e) => {
    e.preventDefault();
    this.setState({ editMode: false });
    let userTeamId = commonUtils.getCurrentUserTeamId();
    store.dispatch({ type: employeeConstants.SHOW_EMPLOYEE_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateEmployee(userTeamId));
    store.dispatch(this.props.initializeProjectAppConfig(userTeamId))
  }
  onCloseCreateItem = (e) => {
    e.preventDefault();
    store.dispatch({ type: employeeConstants.CLOSE_EMPLOYEE_CREATE_WINDOW });
  }
  onGridClick = (e) => {
    e.preventDefault();
    this.setState({ showGrid: true });
  }
  onListClick = (e) => {
    e.preventDefault();
    this.setState({ showGrid: false });
  }

  handleDeleteEmployee = (employeeIdTobeDeleted, isDelete) => {
    store.dispatch(this.props.deleteEmployee(employeeIdTobeDeleted, isDelete));
  }

  handleEditEmployee = (employeeId, employeeUserTeamId) => {
    this.setState({ editMode: true });
    store.dispatch(this.props.getEmployeesInfo(employeeId));
    store.dispatch({ type: employeeConstants.SHOW_EMPLOYEE_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateEmployee(employeeUserTeamId));
    store.dispatch(this.props.initializeProjectAppConfig(employeeUserTeamId))
  }

  handleRemoveEmployee = (employeeId) => {
    store.dispatch(this.props.removeEmployee(employeeId));
  }

  handleActivateEmployee = (employeeId, isActive) => {
    store.dispatch(this.props.activateEmployee(employeeId, isActive));
  }

  handleSubmit = (employeeRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
    if (isEdtiMode) {
      store.dispatch(this.props.updateEmployee(employeeRequest));
    } else {
      store.dispatch(this.props.createEmployee(employeeRequest, isCreateAnotherEnabled, callbackFunction));
    }
  }

  handleUpdateUserExitDate = (employeeId, exitDate) => {
    store.dispatch(this.props.updateEmployeeExitDate(employeeId, exitDate));
  }

  onFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: employeeConstants.CHANGE_FILTER_EMPLOYEES, filterOptions });
      });
  }

  onUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: employeeConstants.INITIALIZE_EMPLOYEE_USER_TEAMS, userTeams });
    //console.log(userTeams)
    this.reloadEmployeeItems(userTeams)
  }

  reloadEmployeeItems = (userTeams) => {
    let selectedUserTeamsIds = commonUtils.getSelectedUserTeamIds(userTeams);
    selectedUserTeamsIds.length > 0 && store.dispatch(this.props.getTeamsEmployees(selectedUserTeamsIds, this.state.filterOptions))
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.onFilterChange(this.state.filterOptions);
      }
    );
  }

  render() {
    return (
      <div className="employees">

        <Alert
          onConfirm={() => this.confirmDeleteEmployeeHandler()}
          onDismiss={() => this.setState({ deleteEmployeeId: null })}
          show={this.state.deleteEmployeeId != null}
          showCancelButton={true}
          confirmButtonColor='#3085d6'
          cancelButtonColor='#d33'
          confirmButtonText='Yes, delete it!'
          text={'Do you really want to delete the employee?'}
          title={'Delete'}
          type={'error'}
        />
        <PageInnerHeader
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          handleUserTeamsSelection={this.onUserTeamsSelection}
          showGridDefault={this.state.showGrid}
          showCreateMenu={this.state.permission.createEnabled}
          showGridListMenu={true}
          showFilter={true}
          showUserTeamMenu={true}
          isLoading={this.props.loading}
          filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
          handleFilterChange={this.onFilterChange}
          onFilterTextChange={this.onFilterTextChange}
          showCreateMenuText="CREATE EMPLOYEE"
          createItemHandler={this.onCreateItemClick}
          gridClickHandler={this.onGridClick}
          listClickHandler={this.onListClick}
          showDateRangeSelector={false}
          permission={this.state.permission}
        >
        </PageInnerHeader>
        <div className="row px-3 pt-1 mx-0 pagesummary">

          <EmployeePageSummaryItem
            count={this.props.stat.active}
            label={"Active"}
            iconClass="fe fe-user-check text-primary font-30 colorgreen"
            isLoading={this.props.loading}
          />
          <EmployeePageSummaryItem
            count={this.props.stat.fulltime}
            label={"Fulltime"}
            iconClass="icon-user-following text-primary font-30 colorblue"
            isLoading={this.props.loading}
          />
          <EmployeePageSummaryItem
            count={this.props.stat.contract}
            label={"Contract"}
            iconClass="fe fe-user-minus text-primary font-30 colororange"
            isLoading={this.props.loading}
          />
          <EmployeePageSummaryItem
            count={this.props.stat.male}
            label={"Male"}
            iconClass="icon-user text-primary font-30"
            isLoading={this.props.loading}
          />
          <EmployeePageSummaryItem
            count={this.props.stat.female}
            label={"Female"}
            iconClass="icon-user-female text-primary font-30"
            isLoading={this.props.loading}
          />
          <EmployeePageSummaryItem
            count={this.props.stat.inactive}
            label={"Inactive"}
            iconClass="fe fe-user-x text-primary font-30 colorred"
            isLoading={this.props.loading}
          />
        </div>
        {((this.props.items && this.props.items.length > 0) || this.props.loading) &&
          <>
            <div className="section-body mt-0">
              <div className="container-fluid">
                <div className="tab-content mt-0">
                  {!this.state.showGrid &&
                    <EmployeeListTable
                      employees={this.props.items}
                      isLoading={this.props.loading}
                      handleDeleteEmployee={this.handleDeleteEmployee}
                      handleEditEmployee={this.handleEditEmployee}
                      handleActivateEmployee={this.handleActivateEmployee}
                      handleRemoveEmployee={this.handleRemoveEmployee}
                      permission={this.state.permission}
                    />
                  }
                  {this.state.showGrid &&
                    <EmployeeListGrids
                      employees={this.props.items}
                      isLoading={this.props.loading}
                      handleDeleteEmployee={this.handleDeleteEmployee}
                      handleEditEmployee={this.handleEditEmployee}
                      handleActivateEmployee={this.handleActivateEmployee}
                      handleRemoveEmployee={this.handleRemoveEmployee}
                      permission={this.state.permission}
                    />
                  }
                </div>
              </div>
            </div>
          </>
        }
        {(!this.props.items || this.props.items.length === 0) && !this.props.loading &&
          <div className="text-center pt-3">
            <img src="/assets/images/custom/nocontacts-min.PNG" />
            <span className="d-block fsize15"> No Employees found ?</span>
            <span className="d-block fsize12 text-muted"> Try Creating a Employee</span>
          </div>
        }
        <CreateEmployeeSidebar
          permission={this.state.permission}
          show={this.props.showCreateModel}
          handleSubmit={this.handleSubmit}
          handleUpdateUserExitDate={this.handleUpdateUserExitDate}
          closeActionHandler={this.onCloseCreateItem}
          editMode={this.state.editMode}
          selectedEmployeeInfo={this.props.selectedEmployeeInfo}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.authentication.user,
  stat: {
    active: state.employees.items && state.employees.items.filter(employee => employee.active).length,
    inactive: state.employees.items && state.employees.items.filter(employee => !employee.active).length,
    fulltime: state.employees.items && state.employees.items.filter(employee => employee.active && employee.empType === 'Permanent').length,
    contract: state.employees.items && state.employees.items.filter(employee => employee.active && employee.empType !== 'Permanent').length,
    male: state.employees.items && state.employees.items.filter(employee => employee.active && employee.gender === 'male').length,
    female: state.employees.items && state.employees.items.filter(employee => employee.active && employee.gender === 'female').length,
  },
  loading: state.employees.loading,
  items: commonUtils.filterEmployees(state.employees.items, state.employees.filterOptions),
  userTeams: commonUtils.getUserTeams(state.employees.userTeams),
  filterOptions: state.employees.filterOptions,
  selectedEmployeeInfo: state.employees.selectedEmployeeInfo,
  showCreateModel: state.employees.showCreateModel
});

const mapDispatchToProps = dispatch => ({
  getTeamsEmployees: employeeActions.getTeamsEmployeesPage,
  getEmployeesInfo: employeeActions.getEmployeesInfo,
  createEmployee: employeeActions.createEmployee,
  updateEmployee: employeeActions.updateEmployee,
  updateEmployeeExitDate: employeeActions.updateEmployeeExitDate,
  deleteEmployee: employeeActions.deleteEmployee,
  removeEmployee: employeeActions.removeEmployee,
  activateEmployee: employeeActions.activateEmployee,
  initializeCreateEmployee: employeeActions.initializeCreateEmployee,
  initializeProjectAppConfig: projectActions.getModuleConfig
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesPage);
