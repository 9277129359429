import React, { Component } from "react";
import { connect } from "react-redux";
import { taskActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import TaskDetailsHeader from "./components/TaskDetailsHeader";
import { NavLink } from "react-router-dom";
import { employeeActions } from "_actions";
import TaskDetailsGeneral from "./components/TaskDetailsGeneral";
import ItemStatistics from "./components/fragments/ItemStatistics";
import { dashboardActions } from "_actions";
import { accessUtils } from "_helpers";

class TaskDetailsPage extends Component {
    constructor() {
        super()
        this.state = {
            permission: accessUtils.getProjectAccessControl(),
        };

    }
    componentDidMount() {

        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name + " : " + this.props.match.params.taskId)
        if (!this.props.taskInfo || this.props.taskInfo.id !== this.props.match.params.taskId) {
            let taskId = commonUtils.removeNameIntial(this.props.match.params.taskId);
            store.dispatch(this.props.getTaskInfo(taskId));
            this.setState({
                taskId: taskId
            })
        }
        let userTeams = commonUtils.getUserTeams();
        let selectedUserTeamsIds = [];
        userTeams && userTeams.map(userTeam => selectedUserTeamsIds.push(userTeam.id));
        store.dispatch(this.props.getTeamsEmployees(selectedUserTeamsIds))
    }

    render() {
        return (
            <div className="projectDetails">
                {this.props.taskInfo &&  commonUtils.hasAccess(this.props.taskInfo, this.state.permission.allowAccessToOtherUserTeamDeliverables) &&
                    <TaskDetailsHeader
                        taskInfo={this.props.taskInfo}
                    />
                }
                <div className="section-body mt-0">
                    <div className="container-fluid px-0">
                        {!this.props.isLoading && this.props.taskInfo &&
                            <div className="row mx-0 taslDetailsSection pt-1 border-top">

                                <div className="col-lg-9 px-1">
                                    <TaskDetailsGeneral
                                        item={this.props.taskInfo}
                                        permission={this.state.permission}
                                        readOnly={(!commonUtils.hasAccess(this.props.taskInfo, this.state.permission.allowAccessToOtherUserTeamDeliverables)) || this.props.taskInfo.deleted}
                                    />
                                </div>
                                <div className="col-lg-3 sidemenu border-left px-0">

                                    <ItemStatistics
                                        label="Work Item Statistics"
                                        items={this.props.workItemStatisticsByCategory}
                                        loadData={this.props.getWorkItemStatisticsByCategory}
                                        itemId={this.props.taskInfo.id}
                                    />

                                    {false &&
                                        <ul className="nav nav-tabs page-header-tab mx-0">
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/details/" + this.props.taskInfo.projectInfo.id + "?view=general"}
                                                    className={`nav-link py-2 border-bottom d-flex w-100}`} >
                                                    <i className="fa fa-file-text px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Project Info</span>
                                                        <span className="fsize12 d-block text-muted">View project details</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/workitems/" + this.props.taskInfo.id}
                                                    onClick={this.changeProjectDetailsView}
                                                    className={`nav-link py-2 border-bottom d-flex w-100 active}`} >
                                                    <i className="fa fa-list px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Work Items</span>
                                                        <span className="fsize12 d-block text-muted">Create Work Items</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/details/" + this.props.taskInfo.projectInfo.id + "?view=calendar"}
                                                    className={`nav-link py-2 border-bottom d-flex w-100}`} >
                                                    <i className="fa fa-calendar px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Calendar</span>
                                                        <span className="fsize12 d-block text-muted">View Delivery Calendar</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/details/" + this.props.taskInfo.projectInfo.id + "?view=activity"}
                                                    className={`nav-link py-2 border-bottom d-flex w-100}`} >
                                                    <i className="fa fa-clock-o px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Activities</span>
                                                        <span className="fsize12 d-block text-muted">View activities</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/details/" + this.props.taskInfo.projectInfo.id + "?view=members"}
                                                    className={`nav-link py-2 border-bottom d-flex w-100}`} >
                                                    <i className="fe fe-users px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Members</span>
                                                        <span className="fsize12 d-block text-muted">View members</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/details/" + this.props.taskInfo.projectInfo.id + "?view=files"}
                                                    className={`nav-link py-2 border-bottom d-flex w-100}`} >
                                                    <i className="fe fe-upload px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Files</span>
                                                        <span className="fsize12 d-block text-muted">Upload and attach files</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-0">
                                                <NavLink
                                                    to={"/project/details/" + this.props.taskInfo.projectInfo.id + "?view=notification"}
                                                    className={`nav-link py-2 border-bottom d-flex w-100}`} >
                                                    <i className="fe fe-volume-2 px-3 pb-3 fsize15"></i>
                                                    <div>
                                                        <span className="fsize15 fweight600 menu-text-black">Notification</span>
                                                        <span className="fsize12 d-block text-muted">Control notifications</span>
                                                    </div>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        }

                    </div>
                </div>
                {!this.props.taskInfo && !this.props.isLoading &&
                    <div className="text-center pt-3">
                        <img alt="No Deliverable details found ?" src="/assets/images/custom/notasks-min.PNG" />
                        <span className="d-block fsize15"> No Deliverable details found ?</span>
                        <span className="d-block fsize12 text-muted">You may not have enough privilleage to view the deliverable details
                        </span>
                        <span className="d-block fsize12 text-muted">
                            or Deliverable does not exist</span>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    taskInfo: state.tasks.selectedTaskInfo,
    employees: state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees),
    isLoading: state.tasks.loading,
    workItemStatisticsByCategory: state.dashboard.workItemStatisticsByCategory
});
const mapDispatchToProps = dispatch => ({
    getTaskInfo: taskActions.getTaskInfo,
    getTeamsEmployees: employeeActions.getTeamsEmployees,
    getWorkItemStatisticsByCategory: dashboardActions.getWorkItemStatisticsByCategory,
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsPage);
