import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { validationUtils } from "_helpers";
import { commonUtils } from "_helpers";
import SidebarFooter from "./SidebarFooter";

class CreateAccountsSidebar extends Component {
    constructor() {
        super();
        this.state = {
            isNewItemSelected: true,
            accountsRequest: {},
            formErrors: {},
            hasFormError: false
        }
    }

    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }
    onResetFormError = () => {
        this.setState({
            hasFormError: false
        })
    }

    onNewItemSelected = (isNewItemSelected) => {
        this.setState(prevState => ({
            isNewItemSelected: isNewItemSelected
        }))
    }


    onChange = (fieldName, value) => {
        this.onResetFormError()
        if (fieldName === 'name') {
            this.setState(prevState => ({
                isNewItemSelected: true,
                accountsRequest: {
                    "allowOnlyForUserTeams": [this.props.selectedUserTeamItem.id],
                    "parentItems": [this.props.selectedParentItem.id],
                    "type": this.props.viewName,
                    [fieldName]: value
                }
            }))
        } else {
            this.setState(prevState => ({
                accountsRequest: {
                    ...prevState.accountsRequest,
                    [fieldName]: value
                }
            }))
        }
    }

    onCreateSelectChange = (fieldName, selectedOption) => {
        this.setState(prevState => ({
            accountsRequest: {
                ...selectedOption
            }
        }))
    }

    submitCallBackFunction = () => {
        this.setState({ accountsRequest: {} })
    }

    handleSubmit = (hasAnotherItemCreateEnabled) => {
        let isValidForm = validationUtils.isValidRequest(this.props.fieldConfig, this.state.accountsRequest, this.state.formErrors);
        //console.log("Validating form " + isValidForm + "  request ");
        if (isValidForm) {
            this.props.handleSubmit(this.state.accountsRequest, this.state.isNewItemSelected, hasAnotherItemCreateEnabled, this.submitCallBackFunction);
        } else {
            this.setState({
                hasFormError: true
            })
        }
    }
    render() {
        return (
            <div id="createaccountsidebar" className={`right_sidebar create_accounts_right_sidebar ${this.props.show === true ? 'open' : ''}`}>
                <div className="justify-content-between flex-column d-flex h-100vh">
                    <div className="card-header py-0 border-bottom">
                        <h3 className="card-title">{(this.state.isNewItemSelected ? "Create " : "Add ") + this.props.viewName}</h3>
                        <div className="card-options">
                            <button onClick={this.props.closeActionHandler} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
                        </div>
                    </div>
                    <div className="card-body pl-3 pr-3 overflow-auto">
                        <div className="row">
                            <div className="col-12 py-0 pl-2 pr-2 justify-content-between flex-column d-flex">
                                <div className="top">
                                    <div className="row">
                                        <div className="col-12 pt-1 row">
                                            <div className="col-6 pt-0">
                                                <CustomInput
                                                    type='createselect'
                                                    label="Name"
                                                    name="name"
                                                    fieldConfig={this.props.fieldConfig}
                                                    mappingObject={this.state.accountsRequest}
                                                    onChange={this.onChange}
                                                    onCreateSelectChange={this.onCreateSelectChange}
                                                    onNewItemSelected={this.onNewItemSelected}
                                                    placeholder="Name..."
                                                    onFormError={this.onFormError}
                                                    options={this.props.subAccountItems && commonUtils.convertObjectToSelectObject(this.props.subAccountItems)}
                                                />
                                            </div>
                                            <div className="col-6 pt-0">
                                                <CustomInput
                                                    type='select'
                                                    label="Owner"
                                                    name="poc"
                                                    fieldConfig={this.props.fieldConfig}
                                                    mappingObject={this.state.accountsRequest}
                                                    onChange={this.onChange}
                                                    placeholder="Owner..."
                                                    options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                                                    onFormError={this.onFormError}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 pt-1 row">
                                            <div className="col-12">
                                                <CustomInput
                                                    type='textarea'
                                                    label="Description"
                                                    name="description"
                                                    height={"50px"}
                                                    fieldConfig={this.props.fieldConfig}
                                                    mappingObject={this.state.accountsRequest}
                                                    onChange={this.onChange}
                                                    placeholder="Description..."
                                                    onFormError={this.onFormError}
                                                    datatype='alpnumspl'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 pt-1 row">
                                            {this.props.viewName === 'ACCOUNT' &&
                                                <div className="col-6">
                                                    <CustomInput
                                                        type='select'
                                                        label="Type"
                                                        name="itemType"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.accountsRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Type..."
                                                        options={[{ value: 'BILLABLE', label: 'Billable' }, { value: 'NON_BILLABLE', label: 'Non Billable' }]}
                                                        onFormError={this.onFormError}
                                                    />
                                                </div>
                                            }
                                            {this.props.viewName === 'CLIENT' &&
                                                <div className="col-6">
                                                    <CustomInput inputtype='text'
                                                        label="Client Email"
                                                        name="email"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.accountsRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Client Email"
                                                        datatype='email' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SidebarFooter
                        handleSubmit={this.handleSubmit}
                        editMode={this.props.editMode}
                        closeButtonLabel={'clear'}
                        initializeRequest={() => { }}
                        hasFormError={this.state.hasFormError}
                        onResetFormError={this.onResetFormError}
                    />

                </div >
            </div>
        );
    }
}
const mapStateToProps = state => ({
    fieldConfig: state.accounts.fieldConfig,
    currentUser: state.authentication.user,
    allUserTeams: state.appconfig.allUserTeamsByGroup,
    allEmployees: state.employees.allEmployees,
    subAccountItems: state.accounts.subAccountItems
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateAccountsSidebar);

