import { purchaseOrderConstants } from '../_constants';
import { appConfigService, purchaseOrderService } from '../_services';
import { notificationActions } from './';
import { appConfigActions } from './appconfig.actions';
import { accountActions } from './account.actions';
import { employeeActions } from './employee.actions';
import { commonUtils } from '_helpers';

export const purchaseOrderActions = {
    initializeCreatePurchaseOrder,
    createPurchaseOrder,
    updatePurchaseOrder,
    updatePurchaseOrderStatus,
    deletePurchaseOrder,
    removePurchaseOrder,
    archivePurchaseOrder,
    getPurchaseOrderList,
    importAndGetPurchaseOrderList,
    searchPurchaseOrderList,
    getActivePurchaseOrderList,
    getPurchaseOrderInfo,
    getModuleConfig,
    loadModuleConfig
  
};

function initializeCreatePurchaseOrder(userTeamId) {
    return dispatch => {
        dispatch(getModuleConfig(userTeamId));
        dispatch(employeeActions.getModuleConfig(userTeamId));
        dispatch(employeeActions.getAllEmployees());
        dispatch(appConfigActions.getAllTeams());
        dispatch(accountActions.getProjectTeamAccounts(userTeamId));
    };
}

function getModuleConfig(userTeamId) {
    return loadModuleConfig(userTeamId, ()=>{})
}

function loadModuleConfig(userTeamId, callbackFunction) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'PROJECT').then(
            appConfig => {
                callbackFunction(appConfig.payload)
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("getModuleConfig PurchaseOrder");
    };
    function request() { return { type: purchaseOrderConstants.INITIALIZE_CREATE_PURCHASE_ORDER_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: purchaseOrderConstants.INITIALIZE_CREATE_PURCHASE_ORDER_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: purchaseOrderConstants.INITIALIZE_CREATE_PURCHASE_ORDER_FAILURE, error } }
}


function getPurchaseOrderList(requestObj,filterOptions ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        purchaseOrderService.getPurchaseOrderList(requestObj)
            .then(
                purchaseOrders => dispatch(success(purchaseOrders.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_REQUEST } }
    function success(purchaseOrders, filterOptions) { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_SUCCESS, purchaseOrders, filterOptions } }
    function failure(error) { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_FAILURE, error } }
}

function importAndGetPurchaseOrderList(requestObj,filterOptions ) {

    return dispatch => {
        dispatch(request());
        purchaseOrderService.importAndGetPurchaseOrderList(requestObj)
            .then(
                purchaseOrders => dispatch(success(purchaseOrders.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_REQUEST } }
    function success(purchaseOrders, filterOptions) { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_SUCCESS, purchaseOrders, filterOptions } }
    function failure(error) { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_FAILURE, error } }
}


function searchPurchaseOrderList(requestObj,filterOptions ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        purchaseOrderService.searchPurchaseOrderList(requestObj)
            .then(
                purchaseOrders => dispatch(success(purchaseOrders.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_REQUEST } }
    function success(purchaseOrders, filterOptions) { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_SUCCESS, purchaseOrders, filterOptions } }
    function failure(error) { return { type: purchaseOrderConstants.GETALL_PURCHASE_ORDERS_FAILURE, error } }
}

function getActivePurchaseOrderList(requestObj, callbackFunction) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        purchaseOrderService.getPurchaseOrderList(requestObj)
            .then(
                purchaseOrders => {
                    dispatch(success(purchaseOrders.payload));
                    if(callbackFunction) {
                        callbackFunction(purchaseOrders.payload);
                    }
                },
                error =>{
                    dispatch(failure(error.toString()))
                    if(callbackFunction) {
                        callbackFunction(null);
                    }
                }
            );
    };

    function request() { return { type: purchaseOrderConstants.GETALL_ACTIVE_PURCHASE_ORDERS_REQUEST } }
    function success(purchaseOrders) { return { type: purchaseOrderConstants.GETALL_ACTIVE_PURCHASE_ORDERS_SUCCESS, purchaseOrders } }
    function failure(error) { return { type: purchaseOrderConstants.GETALL_ACTIVE_PURCHASE_ORDERS_FAILURE, error } }
}


function getPurchaseOrderInfo(prjectId) {
    return dispatch => {
        dispatch(request());
        purchaseOrderService.getPurchaseOrderInfo(prjectId)
            .then(
                purchaseOrder => dispatch(success(purchaseOrder.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: purchaseOrderConstants.GET_PURCHASE_ORDER_REQUEST } }
    function success(purchaseOrder) { return { type: purchaseOrderConstants.GET_PURCHASE_ORDER_SUCCESS, purchaseOrder } }
    function failure(error) { return { type: purchaseOrderConstants.GET_PURCHASE_ORDER_FAILURE, error } }
}


function createPurchaseOrder(purchaseOrderRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(purchaseOrderRequest));
        dispatch(notificationActions.clear());
        purchaseOrderService.createPurchaseOrder(purchaseOrderRequest)
            .then(
                purchaseOrderResponse => {
                    dispatch(success(purchaseOrderResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('PurchaseOrder Creation successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(getErrorMessage(error)));
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(purchaseOrder) { return { type: purchaseOrderConstants.CREATE_PURCHASE_ORDER_REQUEST, purchaseOrder } }
    function success(purchaseOrder, showModel) { return { type: purchaseOrderConstants.CREATE_PURCHASE_ORDER_SUCCESS, purchaseOrder, showModel } }
    function failure(error) { return { type: purchaseOrderConstants.CREATE_PURCHASE_ORDER_FAILURE, error } }
}

function getErrorMessage(error){
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}


function updatePurchaseOrder(purchaseOrderRequest) {
    return dispatch => {
        dispatch(request(purchaseOrderRequest));
        dispatch(notificationActions.clear());
        purchaseOrderService.updatePurchaseOrder(purchaseOrderRequest)
            .then(
                purchaseOrderResponse => {
                    dispatch(success(purchaseOrderResponse.payload, purchaseOrderRequest.id));
                    dispatch(notificationActions.success('PurchaseOrder update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(purchaseOrder) { return { type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_REQUEST, purchaseOrder } }
    function success(purchaseOrder, purchaseOrderId) { return { type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_SUCCESS, purchaseOrder , purchaseOrderId} }
    function failure(error) { return { type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_FAILURE, error } }
}


function updatePurchaseOrderStatus(purchaseOrderId, status) {
    return dispatch => {
        dispatch(request({id:purchaseOrderId}));
        dispatch(notificationActions.clear());
        purchaseOrderService.updatePurchaseOrderStatus(purchaseOrderId, status, commonUtils.getCurrentUserId())
            .then(
                purchaseOrderResponse => {
                    dispatch(success(purchaseOrderResponse.payload, purchaseOrderId));
                    dispatch(notificationActions.success('PurchaseOrder update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(purchaseOrder) { return { type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_REQUEST, purchaseOrder } }
    function success(purchaseOrder, purchaseOrderId) { return { type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_SUCCESS, purchaseOrder , purchaseOrderId} }
    function failure(error) { return { type: purchaseOrderConstants.UPDATE_PURCHASE_ORDER_FAILURE, error } }
}
function deletePurchaseOrder(purchaseOrderId, isDelete) {
    return dispatch => {
        dispatch(request(purchaseOrderId, isDelete));

        purchaseOrderService.deletePurchaseOrder(purchaseOrderId, isDelete)
            .then(
                purchaseOrderResponse => dispatch(success(purchaseOrderId, isDelete)),
                error => {
                    dispatch(failure(purchaseOrderId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(purchaseOrderId) { return { type: purchaseOrderConstants.DELETE_PURCHASE_ORDER_REQUEST, purchaseOrderId } }
    function success(purchaseOrderId, isDelete) { return { type: purchaseOrderConstants.DELETE_PURCHASE_ORDER_SUCCESS, purchaseOrderId, isDelete } }
    function failure(purchaseOrderId, error) { return { type: purchaseOrderConstants.DELETE_PURCHASE_ORDER_FAILURE, purchaseOrderId, error } }
}

function removePurchaseOrder(purchaseOrderId) {
    return dispatch => {
        dispatch(request(purchaseOrderId));

        purchaseOrderService.removePurchaseOrder(purchaseOrderId)
            .then(
                purchaseOrderResponse => dispatch(success(purchaseOrderId)),
                error => {
                    dispatch(failure(purchaseOrderId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(purchaseOrderId) { return { type: purchaseOrderConstants.REMOVE_PURCHASE_ORDER_REQUEST, purchaseOrderId } }
    function success(purchaseOrderId) { return { type: purchaseOrderConstants.REMOVE_PURCHASE_ORDER_SUCCESS, purchaseOrderId } }
    function failure(purchaseOrderId, error) { return { type: purchaseOrderConstants.REMOVE_PURCHASE_ORDER_FAILURE, purchaseOrderId, error } }
}

function archivePurchaseOrder(purchaseOrderId, isArchive) {
    return dispatch => {
        dispatch(request(purchaseOrderId));

        purchaseOrderService.archivePurchaseOrder(purchaseOrderId, isArchive)
            .then(
                purchaseOrderResponse => dispatch(success(purchaseOrderId, isArchive)),
                error => {
                    dispatch(failure(purchaseOrderId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(purchaseOrderId) { return { type: purchaseOrderConstants.ARCHIVE_PURCHASE_ORDER_REQUEST, purchaseOrderId } }
    function success(purchaseOrderId, isArchive) { return { type: purchaseOrderConstants.ARCHIVE_PURCHASE_ORDER_SUCCESS, purchaseOrderId, isArchive } }
    function failure(purchaseOrderId, error) { return { type: purchaseOrderConstants.ARCHIVE_PURCHASE_ORDER_FAILURE, purchaseOrderId, error } }
}