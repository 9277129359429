import React, { Component } from "react";
import { connect } from "react-redux";
import { workItemConstants } from "_constants";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import { store } from "_helpers";
import { workItemActions } from "_actions";
import classNames from "classnames/bind";

class TodayActivitySidebar extends Component {
  closeTodayActivitySidebar = () => {
    store.dispatch({ type: workItemConstants.CLOSE_TODAYS_ACTIVITY_WINDOW })
  }
  render() {
    return (
      <div id="todayactivity_right_sidebar" className={`right_sidebar todayactivity_right_sidebar ${this.props.show ? 'open' : ''}`}>
        <button className="link p-3 todayactivity float-right" onClick={() => this.closeTodayActivitySidebar()}><i className="fa fa-close" /></button>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#activity" aria-expanded="false">Todays Activity</a></li>
        </ul>
        <div className="tab-content">
          {this.props.show &&
            <ActivitySection
              getTodaysWorkItemActivities={this.props.getTodaysWorkItemActivities}
              items={this.props.items}
            />
          }
        </div>
      </div>
    );
  }
}

class ActivitySection extends Component {
  componentDidMount() {
    this.realoadTodaysWorkItems()
  }

  realoadTodaysWorkItems = () => {
    let employeeIds = [commonUtils.getCurrentUserId()];

    let startDate = dateUtils.getCurrentDayStart().clone().format(dateUtils.SERVER_DATE_FORMAT);
    let endDate = dateUtils.getCurrentDayEnd().clone().format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getTodaysWorkItemActivities({ employeeIds: employeeIds, userTeamIds: [], startDate: startDate, endDate: endDate }));
  }

  render() {
    return (<div role="tabpanel" className="tab-pane vivify fadeIn active" id="activity" aria-expanded="false">
      <ul className="new_timeline mt-3">
        <li>
          <div className="bullet green" />
          <div className="desc">
            <h3>Day Start</h3>
            <h4>Good Morning</h4>
          </div>
        </li>
        {this.props.items && this.props.items.map(item =>
          <ActivityItem
            key={item.id}
            item={item}
          />
        )
        }
        <li>
          <div className="bullet green" />
          <div className="desc">
            <h3>Finish</h3>
            <h4>Bye, Go to Home</h4>
          </div>
        </li>
      </ul>
    </div>)
  }
}

class ActivityItem extends Component {
  getTime = () => {
    let durationInMinutes = (this.props.item.status === 'COMPLETED' || this.props.item.status === 'CLOSED') ? this.props.item.durationInMins : this.props.item.estDurationInMins
    let estStartTime = (this.props.item.estStartTime && dateUtils.convertDateStringToMoment(this.props.item.estStartTime));
    let actualStartTime = (this.props.item.actualStartTime && dateUtils.convertDateStringToMoment(this.props.item.actualStartTime));
    let completionTime = (actualStartTime) ? dateUtils.addXToMoments(actualStartTime, "minutes", durationInMinutes) : dateUtils.addXToMoments(estStartTime, "minutes", durationInMinutes)
    return actualStartTime ? actualStartTime.format("hh:mm A") + " - " + completionTime.format("hh:mm A") : estStartTime.format("hh:mm A") + " - " + completionTime.format("hh:mm A")
  }
  render() {
    return (
      <li>
        <div
          className={classNames("bullet", { "blue": this.props.item.status === 'NEW' },
            { "pink": this.props.item.status === 'ASSIGNED' },
            { "orange": this.props.item.status === 'INPROGRESS' },
            { "green": this.props.item.status === 'COMPLETED' },
            { "green": this.props.item.status === 'CLOSED' }
          )} />
        <div className="time">{this.getTime()}</div>
        <div className="desc">
          <h3>{this.props.item.taskActCategory}</h3>
          <h4>{this.props.item.name}</h4>
        </div>
      </li>
    )
  }
}


const mapStateToProps = state => ({
  show: state.workItem.showTodaysActivityModel,
  items: state.workItem.todaysWorkItems && commonUtils.sortArrayOfObjectsByDateField(state.workItem.todaysWorkItems, "actualStartTime", true)
});
const mapDispatchToProps = dispatch => ({
  getTodaysWorkItemActivities: workItemActions.getTodaysWorkItemActivities

});
export default connect(mapStateToProps, mapDispatchToProps)(TodayActivitySidebar);