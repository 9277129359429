import React, { Component } from 'react';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class DateRangeSelector extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isOpen: false,
            value: this.props.defaultDateRangeValue
        };
    }

    onSelect = (value, states) => {
        if (!this.props.isLoading) {
            this.setState({ value, states });
            this.props.onDateRangeChange(value);
        }
        this.onToggle();
    };

    onToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    renderSelectionValue = () => {
        return this.state.value.start.format("YYYY-MMM-DD") + " - " + this.state.value.end.format("YYYY-MMM-DD")
    };

    render() {
        return (
            <div className="pt-1 pr-1">

                <div className="date-range-picker pt-1 pl-1 pb-0">
                    <button className="link"><i className="fa fa-calendar fa-lg pr-0" /></button>
                    <input onClick={this.onToggle} readOnly={true} type="text" className="ml-0" value={this.renderSelectionValue()} />
                </div>

                {this.state.isOpen && (
                    <DateRangePicker
                        firstOfWeek={1}
                        numberOfCalendars={2}
                        selectionType='range'
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    //minimumDate={new Date()}
                    //stateDefinitions={stateDefinitions}
                    //dateStates={dateRanges}
                    //defaultState="available"
                    //showLegend={false}
                    />
                )}
            </div>
        );
    }
}

export default DateRangeSelector;
