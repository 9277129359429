export const scheduleConstants = {
    SHOW_CREATE_SCHEDULES_WINDOW: 'SHOW_CREATE_SCHEDULES_WINDOW',
    CLOSE_CREATE_SCHEDULES_WINDOW: 'CLOSE_CREATE_SCHEDULES_WINDOW',
    GETALL_SCHEDULES_REQUEST: 'GETALL_SCHEDULES_REQUEST',
    GETALL_SCHEDULES_SUCCESS: 'GETALL_SCHEDULES_SUCCESS',
    GETALL_SCHEDULES_FAILURE: 'GETALL_SCHEDULES_FAILURE',

    CREATE_SCHEDULE_REQUEST: 'CREATE_SCHEDULE_REQUEST',
    CREATE_SCHEDULE_SUCCESS: 'CREATE_SCHEDULE_SUCCESS',
    CREATE_SCHEDULE_FAILURE: 'CREATE_SCHEDULE_FAILURE',

    UPDATE_SCHEDULE_REQUEST: 'UPDATE_SCHEDULE_REQUEST',
    UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
    UPDATE_SCHEDULE_FAILURE: 'UPDATE_SCHEDULE_FAILURE',

    ENABLE_SCHEDULE_REQUEST: 'ENABLE_SCHEDULE_REQUEST',
    ENABLE_SCHEDULE_SUCCESS: 'ENABLE_SCHEDULE_SUCCESS',
    ENABLE_SCHEDULE_FAILURE: 'ENABLE_SCHEDULE_FAILURE',  
    
    REMOVE_SCHEDULE_REQUEST: 'REMOVE_SCHEDULE_REQUEST',
    REMOVE_SCHEDULE_SUCCESS: 'REMOVE_SCHEDULE_SUCCESS',
    REMOVE_SCHEDULE_FAILURE: 'REMOVE_SCHEDULE_FAILURE',
};
