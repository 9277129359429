import React, { Component } from "react";
import { connect } from "react-redux";
import { accountActions } from "_actions";

import { employeeActions } from "_actions";
import CreateEmployeeSidebarSection from "./CreateEmployeeSidebarSection";
import { commonUtils } from "_helpers";

class CreateEmployeeSidebar extends Component {
  componentDidMount() {

  }
  render() {
    return (
      <div id="createprojectsidebar" className={`right_sidebar create_project_right_sidebar  ${this.props.show ? "open" : ""}`}>
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">{this.props.editMode ? 'Update' : 'Create'} Employee</h3>
            <div className="card-options">
              <button  onClick={this.props.closeActionHandler} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          {this.props.show && ((this.props.editMode && this.props.selectedEmployeeInfo) || !this.props.editMode) &&
            <CreateEmployeeSidebarSection
              handleSubmit={this.props.handleSubmit}
              handleUpdateUserExitDate={this.props.handleUpdateUserExitDate}
              editMode={this.props.editMode}
              selectedEmployeeInfo={this.props.selectedEmployeeInfo}
              currentUser={this.props.currentUser}
              fieldConfig={this.props.fieldConfig}
              createConfig={this.props.createConfig}
              projectAppConfig={this.props.projectAppConfig}
              allEmployees={this.props.allEmployees}
              allSubRoles={this.props.allSubRoles}
              allTeams={this.props.allTeams}
              allProjectTeamAccounts={this.props.allProjectTeamAccounts}
              isProcessing={this.props.registring || this.props.updating}
              getModuleConfig={this.props.getModuleConfig}
              getProjectTeamAccounts={this.props.getProjectTeamAccounts}
              permission={this.props.permission}
            />
          }

        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  currentUser: state.authentication.user,
  fieldConfig: state.employees.fieldConfig,
  createConfig: state.employees.createConfig,
  projectAppConfig: state.projects.createConfig,
  allEmployees: state.employees.allEmployees,
  allSubRoles: state.appconfig.allSubRoles && commonUtils.getSubRoles( state.appconfig.allSubRoles, commonUtils.getCurrentUserRoleId()),
  allTeams: state.appconfig.allTeams,
  registring: state.employees.registring,
  updating: state.employees.updating,
  allProjectTeamAccounts: state.accounts.allProjectTeamAccounts,
});

const mapDispatchToProps = dispatch => ({
  getModuleConfig: employeeActions.getModuleConfig,
  getProjectTeamAccounts:accountActions.getProjectTeamAccounts
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmployeeSidebar);
