import moment from "moment";

export const calendarUtils = {
  getWeekByWeekOftheMonth,
};


function getWeekByWeekOftheMonth(currentMoment) {
  let datesByWeekList = []
  const startOfMonth = currentMoment.clone().startOf('month').startOf("isoweek").startOf("day");
  const endOfMonth = currentMoment.clone().endOf('month').endOf('isoweek').endOf("day");
  let tempMoment = startOfMonth.clone()
  let currentMonth = currentMoment.month()

  while (tempMoment.diff(endOfMonth) <= 0) {
    let dates = []
    for (var i = 0; i < 7; i++) {
      dates.push(
        {
          "isCurrentMonth": currentMonth ===  tempMoment.month(),
          "date": tempMoment.clone(),
          "isCurrentDate": moment().isSame(tempMoment, 'day'),
          "dayNumber": tempMoment.date()
        }
      );
      tempMoment.add(1, 'days')
    }
    datesByWeekList.push({
     "weekNumber" : tempMoment.isoWeek(),
      "weekDates": dates
    })
  }
  return datesByWeekList;
}