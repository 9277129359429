import { taskboardService } from '_services/taskboard.service';
import { taskboardConstants } from '../_constants';
import { commonActions } from './common.actions';
import { notificationActions } from './notification.actions';

export const taskboardActions = {
    getAllTaskboards,
    createTaskboard,
    updateTaskboard,
    removeTaskboard,
    activateTaskboard
};
function getAllTaskboards(requestObj, callbackFunction) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        taskboardService.getAllTaskboards(requestObj)
            .then(
                taskboards => {
                    dispatch(success(taskboards.payload));
                    callbackFunction(taskboards.payload)
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: taskboardConstants.GETALL_TASKBOARDS_REQUEST } }
    function success(taskboards) { return { type: taskboardConstants.GETALL_TASKBOARDS_SUCCESS, taskboards } }
    function failure(error) { return { type: taskboardConstants.GETALL_TASKBOARDS_FAILURE, error } }
}

function createTaskboard(taskboardRequest) {
    return dispatch => {
        dispatch(request(taskboardRequest));
        dispatch(notificationActions.clear());
        taskboardService.createTaskboard(taskboardRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload));
                    dispatch(notificationActions.success('Deliverable Creation successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)));
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));

                }
            );
    };

    function request(taskboard) { return { type: taskboardConstants.CREATE_TASKBOARD_REQUEST, taskboard } }
    function success(taskboard) { return { type: taskboardConstants.CREATE_TASKBOARD_SUCCESS, taskboard } }
    function failure(error) { return { type: taskboardConstants.CREATE_TASKBOARD_FAILURE, error } }
}

function updateTaskboard(taskboardRequest) {
    return dispatch => {
        dispatch(request(taskboardRequest));
        dispatch(notificationActions.clear());
        taskboardService.updateTaskboard(taskboardRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload, taskboardRequest.id));
                    dispatch(notificationActions.success('Taskboard update successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)))
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));
                }
            );
    };

    function request(taskboard) { return { type: taskboardConstants.UPDATE_TASKBOARD_REQUEST, taskboard } }
    function success(taskboard, taskboardId) { return { type: taskboardConstants.UPDATE_TASKBOARD_SUCCESS, taskboard, taskboardId } }
    function failure(error) { return { type: taskboardConstants.UPDATE_TASKBOARD_FAILURE, error } }
}

function removeTaskboard(taskboardId) {
    return dispatch => {
        dispatch(request(taskboardId));

        taskboardService.removeTaskboard(taskboardId)
            .then(
                taskResponse => dispatch(success(taskboardId)),
                error => {
                    dispatch(failure(taskboardId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(taskboardId) { return { type: taskboardConstants.REMOVE_TASKBOARD_REQUEST, taskboardId } }
    function success(taskboardId) { return { type: taskboardConstants.REMOVE_TASKBOARD_SUCCESS, taskboardId } }
    function failure(taskboardId, error) { return { type: taskboardConstants.REMOVE_TASKBOARD_FAILURE, taskboardId, error } }
}

function activateTaskboard(taskboardId, isActive) {
    return dispatch => {
        dispatch(request(taskboardId));

        taskboardService.activateTaskboard(taskboardId, isActive)
            .then(
                taskboardResponse => dispatch(success(taskboardId, isActive)),
                error => {
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(taskboardId) { return { type: taskboardConstants.ACTIVATE_TASKBOARD_REQUEST, taskboardId } }
    function success(taskboardId, isActive) { return { type: taskboardConstants.ACTIVATE_TASKBOARD_SUCCESS, taskboardId, isActive } }
  
}