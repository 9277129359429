import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { workItemActions } from "_actions";
import { store } from "_helpers";
import TaskBoardListItem from "./TaskBoardListItem";

class TaskBoardList extends Component {
  handleDeleteWorkItem = (workItemIdTobeDeleted, isDelete) => {
    store.dispatch(this.props.deleteWorkItem(workItemIdTobeDeleted, isDelete));
  }

  handleRemoveWorkItem = (workItemId) => {
    store.dispatch(this.props.removeWorkItem(workItemId));
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table_custom table-nowrap table-vcenter table-borderless position-relative">
          <thead>
            <tr>
              <th>#</th>
              <th>Task</th>
              <th>Category</th>
              <th>Assignee</th>
              <th>Duration</th>
              <th>Status</th>
              <th></th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
              <>
                {
                  this.props.items && this.props.items.map((workItem, index) =>
                    <TaskBoardListItem
                      key={workItem.id}
                      permission={this.props.permission}
                      workItem={workItem} slNo={index + 1}
                      handleDeleteWorkItem={this.handleDeleteWorkItem}
                      handleRemoveWorkItem={this.handleRemoveWorkItem}
                      showTaskStatusUpdateModel={this.props.showTaskStatusUpdateModel} />
                  )
                }
              </>
            }
            {this.props.isLoading &&
              <tr>
                <td colSpan="8">
                  <Skeleton count={10} height={30} />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
  deleteWorkItem: workItemActions.deleteWorkItem,
  removeWorkItem: workItemActions.removeWorkItem
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskBoardList);

