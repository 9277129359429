
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const taskService = {
    getAllTasks,
    getAllRecurrableTasks,
    getTaskInfo,
    createTask,
    updateTask,
    updateTaskStatus,
    activateTask,
    removeTask,
    deleteTask,
};

async function getAllTasks(request) {
    return await axiosUtils.get(urlConfig.TASK_GET_ALL + `/${request.projectId}`);
}

async function getAllRecurrableTasks(request) {
    return await axiosUtils.get(urlConfig.TASK_RECURRABLE_GET_ALL + `/${request.projectId}`);
}
async function getTaskInfo(taskId) {
    return await axiosUtils.get(urlConfig.TASK_GET_INFO + `/${taskId}`);
}

async function createTask(taskRequest) {
    return await axiosUtils.post(urlConfig.TASK_CREATE, taskRequest);
}

async function updateTask(taskRequest) {
    return await axiosUtils.put(urlConfig.TASK_UPDATE, taskRequest);
}
async function updateTaskStatus(taskId, status) {
    return await axiosUtils.put(urlConfig.TASK_UPDATE + `/status/${taskId}/${status}`);
}

async function activateTask(taskId, isActive) {
    return await axiosUtils.put(urlConfig.TASK_ACTIVATE + `/${taskId}/${isActive}`);
}

async function deleteTask(taskId, isDelete) {
    return await axiosUtils.remove(urlConfig.TASK_DELETE + `/${taskId}/${isDelete}`);
}

async function removeTask(taskId) {
    return await axiosUtils.remove(urlConfig.TASK_REMOVE + `/${taskId}`);
}
