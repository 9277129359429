import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class ThemeDiv extends Component {
  render() {
    return (
       <div className="theme_div">
        <div className="card">
        <div className="card-header pt-0 border-bottom">
            <h3 className="card-title">Themes</h3>
            <div className="card-options">
              <button className="link p-2 theme-div-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          <div className="card-body">
            <ul className="list-group list-unstyled theme-selection">
              <li className="list-group-item mb-2">
                <p>Default</p>
                <button className="link"><img src="/assets/images/themes/default.png" className="img-fluid"/></button>
              </li>
              <li className="list-group-item mb-2">
                <p>Night Mode</p>
                <button className="link"><img src="/assets/images/themes/dark.png" className="img-fluid" /></button>
              </li>                    
              <li className="list-group-item mb-2 d-none">
                <p>RTL Version</p>
                <button className="link"><img src="/assets/images/themes/rtl.png" className="img-fluid" /></button>
              </li>
              <li className="list-group-item mb-2">
                <p>Theme Version2</p>
                <button className="link"><img src="/assets/images/themes/theme2.png" className="img-fluid" /></button>
              </li>
              <li className="list-group-item mb-2">
                <p>Theme Version3</p>
                <button className="link"><img src="/assets/images/themes/theme3.png" className="img-fluid" /></button>
              </li>
              <li className="list-group-item mb-2">
                <p>Theme Version4</p>
                <button className="link"><img src="/assets/images/themes/theme4.png" className="img-fluid" /></button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ThemeDiv;
