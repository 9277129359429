import React, { Component } from "react";
import CustomInput from "views/common/fragments/formfields/CustomInput";
class EmployeeProjectEstimate extends Component {
    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.name, {
            ...this.props.employeeProjectEstimateEstimate,
            [fieldName]: newValue
        })
    }
    onEmployeeProjectEstimateChange = (fieldName, selectedEmployeeProjectEstimates) => {

        let updatedEmployeeProjectEstimateEstimate = null
        if (this.props.employeeProjectEstimateEstimate) {
            updatedEmployeeProjectEstimateEstimate = {}
            if (selectedEmployeeProjectEstimates) {
                selectedEmployeeProjectEstimates.forEach(employeeProjectEstimate => {
                    let newServiceEstimate = this.props.employeeProjectEstimateEstimate[employeeProjectEstimate] || (this.props.parentEmployeeProjectEstimateEstimate && this.props.parentEmployeeProjectEstimateEstimate[employeeProjectEstimate]) || { estHours: 0, estHourlyRate: 0 }
                    updatedEmployeeProjectEstimateEstimate = {
                        ...updatedEmployeeProjectEstimateEstimate,
                        [employeeProjectEstimate]: {
                            ...newServiceEstimate
                        }
                    }
                })
            }
        }
        this.props.onChange(fieldName, selectedEmployeeProjectEstimates)
        this.props.onChange(this.props.name, updatedEmployeeProjectEstimateEstimate)
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <CustomInput
                            type='select'
                            label="Restricted for Employees"
                            name="restrictedByEmployeeIds"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.mappingObject}
                            onChange={this.props.onChange}
                            onFormError={this.props.onFormError}
                            placeholder="Restrict access for employees..."
                            options={this.props.options}
                            multi={true}
                        />
                    </div>
                </div>
                {this.props.permission.resourceLevelProjectHoursAllocationEnabled && this.props.mappingObject.restrictedByEmployeeIds &&
                    <div className="row bgcolorlightgray">
                        <div className="row bgcolorgray col-12 py-1 px-0 mx-0 fweight600">
                            <div className="col-4 fsize11">Employee Name</div>
                            <div className="col-4 fsize11">Contribution %</div>
                            <div className="col-4 fsize11">Est Hour Per Day</div>
                        </div>
                        {this.props.mappingObject.restrictedByEmployeeIds.map(employeeId =>
                            <div className="row col-12 ml-1" key={employeeId}>
                                <EmployeeProjectEstimateEstimateSection
                                    readOnly={this.props.readOnly}
                                    config={this.props.config}
                                    key={employeeId}
                                    employeeProjectEstimateEstimate={this.props.employeeProjectEstimateEstimate}
                                    selectedEmployeeId={employeeId}
                                    employeeOptions={this.props.options}
                                    onChange={this.onChange}
                                    fieldConfig={this.props.fieldConfig}
                                    onFormError={this.props.onFormError}
                                    createConfig={this.props.createConfig}
                                />
                            </div>
                        )
                        }
                    </div>
                }

            </>
        )
    }
}


class EmployeeProjectEstimateEstimateSection extends Component {

    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.selectedEmployeeProjectEstimate, {
            ...this.props.employeeProjectEstimateEstimate[this.props.selectedEmployeeProjectEstimate] || {},
            [fieldName]: newValue
        })
    }
    onFormError = () => {

    }
    render() {
        return (
            <>
                <div className="col-4">
                    <span className="d-block mt-1 fsize11">{this.props.employeeOptions.find(employee => employee.id === this.props.selectedEmployeeId).name}</span>
                </div>
                <div className="col-4">
                    <CustomInput inputtype='text'
                        readOnly={this.props.readOnly}
                        name="estHours"
                        fieldConfig={this.props.fieldConfig}
                        //mappingObject={this.props.employeeProjectEstimateEstimate[this.props.selectedEmployeeProjectEstimate] || {}}
                        onChange={this.onChange}
                        onFormError={this.onFormError}
                        placeholder="Contribution %..."
                        datatype='decimal' />

                </div>
                <div className="col-4">

                    <CustomInput inputtype='text'
                        readOnly={this.props.readOnly}
                        name="estHourPerDay"
                        fieldConfig={this.props.fieldConfig}
                       // mappingObject={this.props.employeeProjectEstimateEstimate[this.props.selectedEmployeeProjectEstimate] || {}}
                        onChange={this.onChange}
                        onFormError={this.onFormError}
                        placeholder="Est Hour Per Day..."
                        datatype='hourdecimal' />

                </div>

            </>
        )
    }
}

export default EmployeeProjectEstimate