import { dashbaordService } from '_services';
import { taskService } from '_services';
import { dashboardService } from '_services/dashboard.service';
import { dashboardConstants } from '../_constants';
import { commonActions } from './common.actions';
import { notificationActions } from './notification.actions';

export const dashboardActions = {
    //projectStatistics,
    employeeWeeklyUtilization,
    getProjectStatisticsByCategory,
    getTaskStatisticsByCategory,
    getWorkItemStatisticsByCategory

    /*taskActivity,
    projectTeamComparison,
    employeeTeamWise,
    employeeLocation,
    employeeStructure,
    employeeDivision,
    employeeType*/
    
};

function employeeWeeklyUtilization(employeeId, startDate, endDate) {
    return dispatch => {
        dispatch(request());
        dashbaordService.employeeWeeklyUtilization({employeeId, startDate, endDate})
            .then(
                dashboardResponse => {
                    dispatch(success(dashboardResponse.payload));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_EMPLOYEE_WEEKLY_UTILIZATION_REQUEST } }
    function success(employeeWeeklyUtilization) { return { type: dashboardConstants.GET_EMPLOYEE_WEEKLY_UTILIZATION_SUCCESS, employeeWeeklyUtilization } }
    function failure(error) { return { type: dashboardConstants.GET_EMPLOYEE_WEEKLY_UTILIZATION_FAILURE, error } }
}

function getProjectStatisticsByCategory(purchaseOrderId) {
    //console.log(purchaseOrderId);
    return dispatch => {
        dispatch(request());
        dashbaordService.getProjectStatisticsByCategory(purchaseOrderId)
            .then(
                dashboardResponse => {
                    dispatch(success(dashboardResponse.payload));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_PROJECT_STATISTICS_BY_CATEGORY_REQUEST } }
    function success(projectStatisticsByCategory) { return { type: dashboardConstants.GET_PROJECT_STATISTICS_BY_CATEGORY_SUCCESS, projectStatisticsByCategory } }
    function failure(error) { return { type: dashboardConstants.GET_PROJECT_STATISTICS_BY_CATEGORY_FAILURE, error } }
}

function getTaskStatisticsByCategory(projectId) {
    //console.log(projectId);
    return dispatch => {
        dispatch(request());
        dashbaordService.getTaskStatisticsByCategory(projectId)
            .then(
                dashboardResponse => {
                    dispatch(success(dashboardResponse.payload));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_TASK_STATISTICS_BY_CATEGORY_REQUEST } }
    function success(taskStatisticsByCategory) { return { type: dashboardConstants.GET_TASK_STATISTICS_BY_CATEGORY_SUCCESS, taskStatisticsByCategory } }
    function failure(error) { return { type: dashboardConstants.GET_TASK_STATISTICS_BY_CATEGORY_FAILURE, error } }
}

function getWorkItemStatisticsByCategory(deliverableId) {
    //console.log(deliverableId);
    return dispatch => {
        dispatch(request());
        dashbaordService.getWorkItemStatisticsByCategory(deliverableId)
            .then(
                dashboardResponse => {
                    dispatch(success(dashboardResponse.payload));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_WORKITEM_STATISTICS_BY_CATEGORY_REQUEST } }
    function success(workItemStatisticsByCategory) { return { type: dashboardConstants.GET_WORKITEM_STATISTICS_BY_CATEGORY_SUCCESS, workItemStatisticsByCategory } }
    function failure(error) { return { type: dashboardConstants.GET_WORKITEM_STATISTICS_BY_CATEGORY_FAILURE, error } }
}
