import React, { Component } from "react";
import { Link } from "react-router-dom";
import Columnchart from 'views/common/plugins/charts/columnchart';
import Donutchart from 'views/common/plugins/charts/donutchart';
import Piechart from 'views/common/plugins/charts/piechart';
import Stackedchart from 'views/common/plugins/charts/stackedchart';
import { commonUtils } from "_helpers";
class EmployeeDashboardPage extends Component {

	componentDidMount() {
		this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
	}
	render() {
		return (
			<div className="employeedashboard">
				<div className={`section-body mt-1`}>
					<div className="container-fluid">
						<div className="row clearfix">
							<div className="col-lg-7">
								<div className={`section-body mb-2 pb-1"}`}>
									<span className="d-block fsize18">Welcome <span className="fweight600">{commonUtils.getCurrentUser().name}</span></span>
									<span className="fsize15">
										{commonUtils.getCurrentUser().defaultUserTeamDetails.name} / {commonUtils.getCurrentUser().designation}
									</span>
								</div>
							</div>
						</div>
						<div className="row clearfix">
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body ribbon">
										<div className="ribbon-box green">5</div>
										<Link to="/hrms/employees" className="my_sort_cut text-muted">
											<i className="icon-users" />
											<span>Employees</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/hrms/holidays" className="my_sort_cut text-muted">
											<i className="icon-umbrella" />
											<span>Holidays</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body ribbon">
										<div className="ribbon-box orange">8</div>
										<Link to="/hrms/timesheet" className="my_sort_cut text-muted">
											<i className="icon-book-open" />
											<span>Timesheets</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/hrms/invoice" className="my_sort_cut text-muted">
											<i className="icon-credit-card" />
											<span>Invoice</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/hrms/attendance" className="my_sort_cut text-muted">
											<i className="icon-calculator" />
											<span>Attendance</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/project/calendar" className="my_sort_cut text-muted">
											<i className="icon-calendar" />
											<span>Calendar</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="row clearfix row-deck">
							<div className="col-xl-6 col-lg-12 col-md-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Teamwise Employees</h3>
										<div className="card-options">
											<label className="custom-switch m-0">
												<input
													type="checkbox"
													defaultValue={1}
													className="custom-switch-input"
													defaultChecked
												/>
												<span className="custom-switch-indicator" />
											</label>
										</div>
									</div>
									<div className="card-body">
										<Stackedchart></Stackedchart>
									</div>
									<div className="card-footer">
										<div className="d-flex justify-content-between align-items-center">

										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Employees Location</h3>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-lg-6 col-md-12">
												<Donutchart />
											</div>
											<div className="col-lg-6 col-md-12">
												<div className="table-responsive">
													<table className="table table-hover table-vcenter text-nowrap card-table table_custom">
														<tbody>
															<tr>
																<td>
																	<div className="clearfix">
																		<div className="float-left">
																			<strong>35%</strong>
																		</div>
																		<div className="float-right">
																			<small className="text-muted">
																				visitor from America
																			</small>
																		</div>
																	</div>
																	<div className="progress progress-xs">
																		<div
																			className="progress-bar bg-azure"
																			role="progressbar"
																			style={{ width: '35%' }}
																			aria-valuenow={42}
																			aria-valuemin={0}
																			aria-valuemax={100}
																		/>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="clearfix">
																		<div className="float-left">
																			<strong>25%</strong>
																		</div>
																		<div className="float-right">
																			<small className="text-muted">
																				visitor from Canada
																			</small>
																		</div>
																	</div>
																	<div className="progress progress-xs">
																		<div
																			className="progress-bar bg-green"
																			role="progressbar"
																			style={{ width: '25%' }}
																			aria-valuenow={0}
																			aria-valuemin={0}
																			aria-valuemax={100}
																		/>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="clearfix">
																		<div className="float-left">
																			<strong>15%</strong>
																		</div>
																		<div className="float-right">
																			<small className="text-muted">
																				visitor from India
																			</small>
																		</div>
																	</div>
																	<div className="progress progress-xs">
																		<div
																			className="progress-bar bg-orange"
																			role="progressbar"
																			style={{ width: '15%' }}
																			aria-valuenow={36}
																			aria-valuemin={0}
																			aria-valuemax={100}
																		/>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="clearfix">
																		<div className="float-left">
																			<strong>20%</strong>
																		</div>
																		<div className="float-right">
																			<small className="text-muted">
																				visitor from UK
																			</small>
																		</div>
																	</div>
																	<div className="progress progress-xs">
																		<div
																			className="progress-bar bg-indigo"
																			role="progressbar"
																			style={{ width: '20%' }}
																			aria-valuenow={6}
																			aria-valuemin={0}
																			aria-valuemax={100}
																		/>
																	</div>
																</td>
															</tr>
															<tr>
																<td>
																	<div className="clearfix">
																		<div className="float-left">
																			<strong>5%</strong>
																		</div>
																		<div className="float-right">
																			<small className="text-muted">
																				visitor from Australia
																			</small>
																		</div>
																	</div>
																	<div className="progress progress-xs">
																		<div
																			className="progress-bar bg-cyan"
																			role="progressbar"
																			style={{ width: '5%' }}
																			aria-valuenow={7}
																			aria-valuemin={0}
																			aria-valuemax={100}
																		/>
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row clearfix row-deck">
							<div className="col-xl-6 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Employee Structure</h3>
									</div>
									<div className="card-body text-center">
										<Columnchart isEmployee={true}></Columnchart>

									</div>

									{/* <div className="card-body text-center">
												<div className="row clearfix">
													<div className="col-6">
														<h6 className="mb-0">50</h6>
														<small className="text-muted">Male</small>
													</div>
													<div className="col-6">
														<h6 className="mb-0">17</h6>
														<small className="text-muted">Female</small>
													</div>
												</div>
											</div> */}
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Division</h3>
									</div>
									<div className="card-body">

										<ul className="list-group mt-3 mb-0">
											<li className="list-group-item">
												<div className="clearfix">
													<div className="float-left">
														<strong>35%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Design Team</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-azure"
														role="progressbar"
														style={{ width: '35%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
											</li>
											<li className="list-group-item">
												<div className="clearfix">
													<div className="float-left">
														<strong>25%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Developer Team</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-green"
														role="progressbar"
														style={{ width: '25%' }}
														aria-valuenow={0}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
											</li>
											<li className="list-group-item">
												<div className="clearfix">
													<div className="float-left">
														<strong>15%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Marketing</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-orange"
														role="progressbar"
														style={{ width: '15%' }}
														aria-valuenow={36}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
											</li>
											<li className="list-group-item">
												<div className="clearfix">
													<div className="float-left">
														<strong>20%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Management</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-indigo"
														role="progressbar"
														style={{ width: '20%' }}
														aria-valuenow={6}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
											</li>
											<li className="list-group-item">
												<div className="clearfix">
													<div className="float-left">
														<strong>11%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Other</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-pink"
														role="progressbar"
														style={{ width: '11%' }}
														aria-valuenow={6}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Employee Type</h3>
									</div>
									<Piechart></Piechart>

									{/* <div className="card-footer text-center">
												<div className="row clearfix">
													<div className="col-6">
														<h6 className="mb-0">$3,095</h6>
														<small className="text-muted">Last Year</small>
													</div>
													<div className="col-6">
														<h6 className="mb-0">$2,763</h6>
														<small className="text-muted">This Year</small>
													</div>
												</div>
											</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmployeeDashboardPage;
