import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions } from "_actions";
import { employeeConstants } from "_constants";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import EmployeeImage from "./fragments/EmployeeImage";

class UserDiv extends Component {
  toggleUserDivSidebar = () => {
    store.dispatch({ type: employeeConstants.TOGGLE_USER_DIV_WINDOW })
  }
  render() {
    return (
      <div className={`user_div ${this.props.show ? 'open' : ''}`}>
        <h5 className="brand-name mb-4"> <img alt="famstack" src="/favicon.ico" style={{ width: 30 + 'px' }}></img><span className="brand-name pl-2">famstack</span>

          <button className="link user_btn" onClick={() => this.toggleUserDivSidebar()}><i className="icon-logout" /></button></h5>
        <div className="card">
          <div className="card-body">
            <div className="media">
              <EmployeeImage employee={commonUtils.getCurrentUser()} classes={"avatar avatar-xl mr-3"} />
              <div className="media-body">
                <h6 className="m-0">{commonUtils.getCurrentUser().name}</h6>
                <p className="text-muted mb-0">{commonUtils.getCurrentUser().designation}</p>
                <p className="text-muted mb-0">{commonUtils.getCurrentUser().defaultUserTeamDetails.name}</p>
              </div>
            </div>
          </div>
        </div>
        {this.props.show &&
          <UserUtilization
          employeeWeeklyUtilization={this.props.employeeWeeklyUtilization}
          />
        }
      </div>
    );
  }
}


class UserUtilization extends Component {

  componentDidMount() {
    const startDate = dateUtils.getCurrentTime().clone().startOf('week').startOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = dateUtils.getCurrentTime().clone().endOf('week').endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(dashboardActions.employeeWeeklyUtilization(commonUtils.getCurrentUserId(), startDate, endDate))
  }

  convertToString = (timeinMins) => {
    if (timeinMins && timeinMins > 0) {
      return Math.floor(timeinMins / 60) + "h " + (timeinMins % 60) + "m";
    }
    return 0;
  }

  render() {
    const totalMins = this.props.employeeWeeklyUtilization ? (this.props.employeeWeeklyUtilization.billableMins + this.props.employeeWeeklyUtilization.nonBillableMins + this.props.employeeWeeklyUtilization.leaveMins + this.props.employeeWeeklyUtilization.holidayMins) : 0;
    const leaveOrHolidayMins = this.props.employeeWeeklyUtilization ? (this.props.employeeWeeklyUtilization.leaveMins + this.props.employeeWeeklyUtilization.holidayMins) : 0;
    let utilization = (((totalMins - leaveOrHolidayMins) / 60) / 40) * 100;
    return (
      <>      {this.props.employeeWeeklyUtilization &&
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Statistics</h3>
            <div className="card-options">
              <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up" /></button>
            </div>
          </div>
          <div className="card-body">
            <div className="text-center">
              <div className="row">
                <div className="col-6 pb-3">
                  <label className="mb-0">Hours this week</label>
                  <h4 className="font-30 font-weight-bold">40</h4>
                </div>
                <div className="col-6 pb-3">
                  <label className="mb-0">Utilization</label>
                  <h4 className="font-30 font-weight-bold">{utilization}%</h4>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="d-block">Non Billabe Hours<span className="float-right">{this.convertToString(this.props.employeeWeeklyUtilization.nonBillableMins)}</span></label>
              <div className="progress progress-xs">
                <div className="progress-bar bg-blue" role="progressbar" style={{ width: `${commonUtils.getPercentageOfCompletion(totalMins, this.props.employeeWeeklyUtilization.nonBillableMins)}%` }} />
              </div>
            </div>
            <div className="form-group">
              <label className="d-block">Billabe Hours <span className="float-right">{this.convertToString(this.props.employeeWeeklyUtilization.billableMins)}</span></label>
              <div className="progress progress-xs">
                <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${commonUtils.getPercentageOfCompletion(totalMins, this.props.employeeWeeklyUtilization.billableMins)}%` }} />
              </div>
            </div>
            <div className="form-group">
              <label className="d-block">Leave/Holiday Hours <span className="float-right">{this.convertToString(leaveOrHolidayMins)}</span></label>
              <div className="progress progress-xs">
                <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${commonUtils.getPercentageOfCompletion(totalMins, leaveOrHolidayMins)}%` }} />
              </div>
            </div>
            <div className="form-group mb-0">
              <label className="d-block">Total Hours <span className="float-right">{this.convertToString(totalMins)}</span></label>
              <div className="progress progress-xs">
                <div className="progress-bar bg-green" role="progressbar" style={{ width: `${commonUtils.getPercentageOfCompletion(2400, totalMins)}%` }} />
              </div>
            </div>
          </div>
        </div>
      }
      </>
    )
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication,
  show: state.employees.showUserDiv,
  employeeWeeklyUtilization: state.dashboard.employeeWeeklyUtilization
});


export default connect(
  mapStateToProps
)(UserDiv);

