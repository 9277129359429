import React, { PureComponent } from "react";
import ProjectListGrids from "./components/ProjectListGrids";
import ProjectListTable from "./components/ProjectListTable";
import CreateProjectSidebar from "./components/modal/CreateProjectSidebar";
import ProjectListBarChart from "views/common/plugins/charts/ProjectListBarChart";
import { connect } from "react-redux";
import { projectActions } from "_actions";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";
import PageInnerHeader from "views/common/PageInnerHeader";
import { commonUtils } from "_helpers";
import { projectConstants } from "_constants";
import { accessUtils } from "_helpers";
import ItemNotFoundSection from "./components/ItemsNotFoundSection";
import ViewProjectDetailsSidebar from "./components/modal/ViewProjectDetailsSidebar";
import { purchaseOrderActions } from "_actions";


class ProjectListPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      showGrid: false,
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ACTIVE", label: "Active" }, { name: "ARCHIVED", label: "Archived" }, { name: "MYPROJECTS", label: "My Projects" }, { name: "FAVORITE", label: "Favorite" }], selected: "ACTIVE" },
        SORT: { options: [{ name: "ESTIMATEDENDDATE", label: "Due Date" }, { name: "ESTIMATEDSTARTDATE", label: "Est Start Date" }, { name: "CREATEDDATE", label: "Created Date" }, { name: "A-Z", label: "A-Z" }], selected: "A-Z", directionUp:true },
        filterText: null,
        dateRangeValue: dateUtils.getDefaultDateRange(),
      },
      permission: accessUtils.getProjectAccessControl(),
      editMode: false,
      duplicateMode: false
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    if (!this.props.items) {
      this.invokeProjectListService();
    }
  }

  invokeProjectListService = () => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    store.dispatch({ type: projectConstants.INITIALIZE_PROJECTS_USER_TEAMS, userTeams });
    this.reloadProjectListService(userTeams);
  }

  handleSearchSubmit = (searchOption, searchTerm) => {

  }

  reloadProjectListService = (userTeams) => {
    let selectedUserTeams = []
    let currentUserId = commonUtils.getCurrentUserId();
    if (this.state.permission.switchUserTeamsEnabled === true) {
      userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    }
    const startDate = this.state.filterOptions.dateRangeValue.start.startOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = this.state.filterOptions.dateRangeValue.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    const isOnlyUserTeams = this.state.permission.listOnlyCurrentTeamProject;
    store.dispatch(this.props.getProjectList({ userTeamIds: selectedUserTeams, currentUserId: currentUserId, startDate: startDate, endDate: endDate, isOnlyUserTeams }, this.state.filterOptions))
  }


  onCreateItemClick = (e) => {
    e.preventDefault();
    this.setState({ editMode: false });
    this.setState({ duplicateMode: false });
    let defaultTeam = commonUtils.getCurrentUserTeamId();
    store.dispatch({ type: projectConstants.SHOW_PROJECT_CREATE_WINDOW });
    store.dispatch({ type: projectConstants.CLEAR_SELECTED_PROJECT });
    store.dispatch(this.props.initializeCreateProject(defaultTeam));
    store.dispatch(this.props.getActivePurchaseOrderList({ userTeamIds: defaultTeam }))
  }

  onCloseCreateItem = (e) => {
    e.preventDefault();
    store.dispatch({ type: projectConstants.CLOSE_PROJECT_CREATE_WINDOW });
  }
  onGridClick = (e) => {
    e.preventDefault();
    this.setState({ showGrid: true });
  }
  onListClick = (e) => {
    e.preventDefault();
    this.setState({ showGrid: false });
  }

  handleDateRangeChange = (daterange) => {
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        dateRangeValue: daterange
      }
    }), () => {
      this.reloadProjectListService(commonUtils.getUserTeams(this.props.userTeams));
    })
  }

  handleDeleteProject = (projectIdTobeDeleted, isDelete) => {

    store.dispatch(this.props.deleteProject(projectIdTobeDeleted, isDelete));
  }

  handleUpdateStatus = (projectId, status) => {
    store.dispatch(this.props.updateProjectStatus(projectId, status));
  }


  handleEditProject = (projectId, projectUserTeamId) => {

    this.setState({ duplicateMode: false, editMode: true });
    store.dispatch(this.props.getProjectInfo(projectId));
    store.dispatch({ type: projectConstants.SHOW_PROJECT_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateProject(projectUserTeamId));
    store.dispatch(this.props.getActivePurchaseOrderList({ userTeamIds: projectUserTeamId }))
  }

  handleDuplicateProject = (projectId, projectUserTeamId) => {

    this.setState({ duplicateMode: true, editMode: false });
    store.dispatch(this.props.getProjectInfo(projectId));
    store.dispatch({ type: projectConstants.SHOW_PROJECT_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreateProject(projectUserTeamId));
    store.dispatch(this.props.getActivePurchaseOrderList({ userTeamIds: projectUserTeamId }))
  }

  onViewProject = (projectId) => {
    store.dispatch(this.props.getProjectInfo(projectId));
    store.dispatch({ type: projectConstants.SHOW_PROJECT_VIEW_WINDOW });
  }

  onCloseViewProject = () => {
    store.dispatch({ type: projectConstants.CLOSE_PROJECT_VIEW_WINDOW });
  }

  handleRemoveProject = (projectId) => {
    store.dispatch(this.props.removeProject(projectId));
  }

  handleArchiveProject = (projectId, isActive) => {
    store.dispatch(this.props.archiveProject(projectId, isActive));
  }

  handleFavoriteProject = (projectId, isFavorite) => {
    store.dispatch(this.props.addToFavorites(projectId, commonUtils.getCurrentUserId(), isFavorite));
  }

  handleSubmit = (projectRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
    if (isEdtiMode) {
      store.dispatch(this.props.updateProject(projectRequest));
    } else {
      store.dispatch(this.props.createProject(projectRequest, isCreateAnotherEnabled, callbackFunction));
    }
  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: projectConstants.CHANGE_FILTER_PROJECTS, filterOptions });
      });
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: projectConstants.INITIALIZE_PROJECTS_USER_TEAMS, userTeams });
    this.reloadProjectListService(userTeams)
  }


  handleSearchSubmit = (searchField, searchTerm) => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    let currentUserId = commonUtils.getCurrentUserId();
    let selectedUserTeamIds = commonUtils.getSelectedUserTeamIds(userTeams);
    const isOnlyUserTeams = this.state.permission.listOnlyCurrentTeamProject;
    store.dispatch(this.props.searchProjectList({ userTeamIds: selectedUserTeamIds, currentUserId: currentUserId, isOnlyUserTeams, projectSearchRequest: { searchField, searchTerm } }, this.state.filterOptions))
  }

  render() {
    return (
      <div className="projectlist">

        <PageInnerHeader
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          handleUserTeamsSelection={this.handleUserTeamsSelection}
          showGridDefault={this.state.showGrid}
          showCreateMenu={this.state.permission.createEnabled}
          showGridListMenu={true}
          searchViewEnabled={true}
          searchViewOpitons={{
            "proposal_no": "Proposal Number",
            "po_number": "Purchase Order Number",
            "id": "Project Id",
            "code": "Project Code",
            "order_book_ref_no": "Order Book Ref No"
          }}
          defaultSearchViewOpiton={"proposal_no"}
          handleSearchSubmit={this.handleSearchSubmit}
          showFilter={true}
          showUserTeamMenu={true}
          isLoading={this.props.isLoading}
          filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
          handleFilterChange={this.handleFilterChange}
          onFilterTextChange={this.onFilterTextChange}
          showCreateMenuText="CREATE PROJECT"
          createItemHandler={this.onCreateItemClick}
          gridClickHandler={this.onGridClick}
          listClickHandler={this.onListClick}
          showDateRangeSelector={true}
          handleDateRangeChange={this.handleDateRangeChange}
          permission={this.state.permission}
        >
        </PageInnerHeader>
        {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
          <>
            <ProjectListBarChart />

            <div className="section-body mt-1">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content">
                      {this.state.showGrid &&
                        <ProjectListGrids
                          items={this.props.items}
                          isLoading={this.props.isLoading}
                          handleDeleteProject={this.handleDeleteProject}
                          handleEditProject={this.handleEditProject}
                          handleArchiveProject={this.handleArchiveProject}
                          handleRemoveProject={this.handleRemoveProject}
                          onViewProject={this.onViewProject}
                          permission={this.state.permission} />
                      }
                      {!this.state.showGrid &&
                        <ProjectListTable
                          items={this.props.items}
                          isLoading={this.props.isLoading}
                          handleDeleteProject={this.handleDeleteProject}
                          handleEditProject={this.handleEditProject}
                          handleUpdateStatus={this.handleUpdateStatus}
                          handleDuplicateProject={this.handleDuplicateProject}
                          handleArchiveProject={this.handleArchiveProject}
                          handleRemoveProject={this.handleRemoveProject}
                          handleFavoriteProject={this.handleFavoriteProject}
                          onViewProject={this.onViewProject}
                          permission={this.state.permission}
                          reloadProjectList={this.invokeProjectListService}
                        />

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        <ItemNotFoundSection items={this.props.items} isLoading={this.props.isLoading} imgurl="noprojects1-min.PNG" text="No Projects found ?" description="Try Creating a Project from scratch..." />
        <CreateProjectSidebar
          show={this.props.showCreateModel}
          closeActionHandler={this.onCloseCreateItem}
          editMode={this.state.editMode}
          duplicateMode={this.state.duplicateMode}
          selectedProjectInfo={this.props.selectedProjectInfo}
          handleSubmit={this.handleSubmit}
          permission={this.state.permission}
        />
        <ViewProjectDetailsSidebar show={this.props.isShowProjectViewSidebar} onCloseViewProject={this.onCloseViewProject} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication,
  isLoading: state.projects.loading,
  userTeams: commonUtils.getUserTeams(state.projects.userTeams),
  items: commonUtils.filterProjects(state.projects.items, state.projects.filterOptions),
  filterOptions: state.projects.filterOptions,
  selectedProjectInfo: state.projects.selectedProjectInfo,
  showCreateModel: state.projects.showCreateModel,
  isShowProjectViewSidebar: state.projects.isShowProjectViewSidebar,
});
const mapDispatchToProps = dispatch => ({
  getProjectInfo: projectActions.getProjectInfo,
  getProjectList: projectActions.getProjectList,
  searchProjectList: projectActions.searchProjectList,
  getActivePurchaseOrderList: purchaseOrderActions.getActivePurchaseOrderList,
  createProject: projectActions.createProject,
  updateProject: projectActions.updateProject,
  deleteProject: projectActions.deleteProject,
  removeProject: projectActions.removeProject,
  archiveProject: projectActions.archiveProject,
  addToFavorites: projectActions.addToFavorites,
  updateProjectStatus: projectActions.updateProjectStatus,
  initializeCreateProject: projectActions.initializeCreateProject
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectListPage);