import React, { PureComponent } from "react";

import classNames from 'classnames/bind';
import CronSelector from "../plugins/CronSelector";

class CronJobSelectionMenu extends PureComponent {
    constructor() {
        super();
        this.state = {
        };
    }
    componentDidMount() {

    }
    onCronValueChange = (cronValue, cronString) => {
        this.setState(prevState => ({
            cronValue,
            cronString
        }))
    }

    render() {
        return (
            <div className={classNames("dropdowncontainer", { "d-none": !this.props.showDropDown })} >
                <div className="dropdownmenu-lg custom-controls-stacked pl-2 font-weight-normal text-capitalize">
                    <div className="pr-3">
                        <CronSelector 
                            onChange={this.onCronValueChange}
                            cronValue={this.props.cronValue}
                        />
                        <div className="border-bottom">
                            <button onClick={() => this.props.onChange(this.state.cronValue, this.state.cronString)}>
                                Done
                            </button>
                            <button className="pl-2 colorblue link" onClick={() => this.props.closeDropDwonMenu(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CronJobSelectionMenu