import { taskboardConstants } from '../_constants';
const taskboardFieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
  { name: 'employeeMapInfo', mandatory: true, enabled: true },
  { name: 'userTeamId', mandatory: true, enabled: true }
];

export function taskBoard(state = {taskboardFieldConfig}, action) {

  switch (action.type) {

    case taskboardConstants.SHOW_TASKBOARD_CONFIGURE_WINDOW:
      return {
        ...state,
        isShowConfigureTaskboardSidebar: true
      };
    case taskboardConstants.CLOSE_TASKBOARD_CONFIGURE_WINDOW:
      return {
        ...state,
        isShowConfigureTaskboardSidebar: false
      };

    case taskboardConstants.GETALL_TASKBOARDS_REQUEST:
      return {
        ...state,
        taskboardsLoading: true
      };
    case taskboardConstants.GETALL_TASKBOARDS_SUCCESS:
      return {
        ...state,
        taskboardsLoading: false,
        items: action.taskboards
      };
    case taskboardConstants.GETALL_TASKBOARDS_FAILURE:
      return {
        ...state,
        taskboardsLoading: false,
        error: action.error
      };

    case taskboardConstants.CREATE_TASKBOARD_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case taskboardConstants.CREATE_TASKBOARD_SUCCESS:
      state.items ? state.items.push(action.taskboard) : state.items = [action.taskboard];
      return {
        ...state,
        creating: false
      };
    case taskboardConstants.CREATE_TASKBOARD_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case taskboardConstants.UPDATE_TASKBOARD_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items.map(taskboard =>
          taskboard.id === action.taskboardId
            ? { ...taskboard, updating: true }
            : taskboard
        ),
        error: null
      };
    case taskboardConstants.UPDATE_TASKBOARD_SUCCESS:
      let oldItem = state.items.find(taskboard => taskboard.id === action.taskboardId);
      let newItems = state.items.filter(taskboard => taskboard.id !== action.taskboardId);
      let newItem = {
        ...oldItem,
        ...action.taskboard
      }
      newItems.push(newItem);
      return {
        ...state,
        updating: false,
        items: newItems
      };
    case taskboardConstants.UPDATE_TASKBOARD_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items.map(taskboard => {
          if (taskboard.id === action.taskboardId) {
            const { updating, ...taskboardCopy } = taskboard;
            return { ...taskboardCopy, deleteError: action.error };
          }
          return taskboard;
        }),
        error: action.error
      };

    case taskboardConstants.REMOVE_TASKBOARD_SUCCESS:
      return {
        ...state,
        items: state.items.filter(taskboard => taskboard.id !== action.taskboardId)
      };

    case taskboardConstants.ACTIVATE_TASKBOARD_REQUEST:
      return {
        ...state,
        items: state.items.map(taskboard =>
          taskboard.id === action.taskboardId
            ? { ...taskboard, activating: true }
            : taskboard
        )
      };
      case taskboardConstants.ACTIVATE_TASKBOARD_SUCCESS:
        return {
          ...state,
          items: state.items.map(taskboard =>
            taskboard.id === action.taskboardId
              ? { ...taskboard, active: action.isActive }
              : taskboard
          )
        };
      case taskboardConstants.ACTIVATE_TASKBOARD_FAILURE:
        return {
          ...state,
          items: state.items.map(taskboard => {
            if (taskboard.id === action.taskboardId) {
              const { activating, ...taskboardCopy } = taskboard;
              return { ...taskboardCopy, deleteError: action.error };
            } 
            return taskboard;
          })
        };
    default:
      return state
  }
}