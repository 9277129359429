import { bidRequestsConstants } from '../_constants';
import { appConfigService, bidRequestsService } from '../_services';
import { notificationActions } from '.';
import { appConfigActions } from './appconfig.actions';
import { accountActions } from './account.actions';
import { employeeActions } from './employee.actions';

export const bidRequestsActions = {
    initializeCreateBidRequests,
    createBidRequests,
    updateBidRequests,
    updateBidRequestsStatus,
    deleteBidRequests,
    removeBidRequests,
    archiveBidRequests,
    getBidRequestsList,
    getActiveBidRequestsList,
    getBidRequestsInfo,
    getModuleConfig
  
};

function initializeCreateBidRequests(userTeamId) {
    return dispatch => {
        dispatch(getModuleConfig(userTeamId));
        dispatch(employeeActions.getModuleConfig(userTeamId));
        dispatch(employeeActions.getAllEmployees());
        dispatch(appConfigActions.getAllTeams());
        dispatch(accountActions.getProjectTeamAccounts(userTeamId));
    };
}

function getModuleConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'SALES').then(
            appConfig => {
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("getModuleConfig BidRequests");
    };
    function request() { return { type: bidRequestsConstants.INITIALIZE_CREATE_BID_REQUEST_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: bidRequestsConstants.INITIALIZE_CREATE_BID_REQUEST_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: bidRequestsConstants.INITIALIZE_CREATE_BID_REQUEST_FAILURE, error } }
}


function getBidRequestsList(requestObj,filterOptions ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        bidRequestsService.getBidRequestsList(requestObj)
            .then(
                bidRequests => dispatch(success(bidRequests.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: bidRequestsConstants.GETALL_BID_REQUESTS_REQUEST } }
    function success(bidRequests, filterOptions) { return { type: bidRequestsConstants.GETALL_BID_REQUESTS_SUCCESS, bidRequests, filterOptions } }
    function failure(error) { return { type: bidRequestsConstants.GETALL_BID_REQUESTS_FAILURE, error } }
}

function getActiveBidRequestsList(requestObj ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        bidRequestsService.getBidRequestsList(requestObj)
            .then(
                bidRequests => dispatch(success(bidRequests.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: bidRequestsConstants.GETALL_ACTIVE_BID_REQUEST_REQUEST } }
    function success(bidRequests) { return { type: bidRequestsConstants.GETALL_ACTIVE_BID_REQUEST_SUCCESS, bidRequests } }
    function failure(error) { return { type: bidRequestsConstants.GETALL_ACTIVE_BID_REQUEST_FAILURE, error } }
}

function getBidRequestsInfo(prjectId) {
    return dispatch => {
        dispatch(request());
        bidRequestsService.getBidRequestsInfo(prjectId)
            .then(
                bidRequests => dispatch(success(bidRequests.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: bidRequestsConstants.GET_BID_REQUEST_REQUEST } }
    function success(bidRequests) { return { type: bidRequestsConstants.GET_BID_REQUEST_SUCCESS, bidRequests } }
    function failure(error) { return { type: bidRequestsConstants.GET_BID_REQUEST_FAILURE, error } }
}


function createBidRequests(BidRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(BidRequest));
        dispatch(notificationActions.clear());
        bidRequestsService.createBidRequests(BidRequest)
            .then(
                bidRequestsResponse => {
                    dispatch(success(bidRequestsResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('BidRequests Creation successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(getErrorMessage(error)));
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(bidRequests) { return { type: bidRequestsConstants.CREATE_BID_REQUEST_REQUEST, bidRequests } }
    function success(bidRequests, showModel) { return { type: bidRequestsConstants.CREATE_BID_REQUEST_SUCCESS, bidRequests, showModel } }
    function failure(error) { return { type: bidRequestsConstants.CREATE_BID_REQUEST_FAILURE, error } }
}

function getErrorMessage(error){
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}


function updateBidRequests(BidRequest) {
    return dispatch => {
        dispatch(request(BidRequest));
        dispatch(notificationActions.clear());
        bidRequestsService.updateBidRequests(BidRequest)
            .then(
                bidRequestsResponse => {
                    dispatch(success(bidRequestsResponse.payload, BidRequest.id));
                    dispatch(notificationActions.success('BidRequests update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(bidRequests) { return { type: bidRequestsConstants.UPDATE_BID_REQUEST_REQUEST, bidRequests } }
    function success(bidRequests, bidRequestsId) { return { type: bidRequestsConstants.UPDATE_BID_REQUEST_SUCCESS, bidRequests , bidRequestsId} }
    function failure(error) { return { type: bidRequestsConstants.UPDATE_BID_REQUEST_FAILURE, error } }
}


function updateBidRequestsStatus(bidRequestsId, status) {
    return dispatch => {
        dispatch(request({id:bidRequestsId}));
        dispatch(notificationActions.clear());
        bidRequestsService.updateBidRequestsStatus(bidRequestsId, status)
            .then(
                bidRequestsResponse => {
                    dispatch(success(bidRequestsResponse.payload, bidRequestsId));
                    dispatch(notificationActions.success('BidRequests update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(bidRequests) { return { type: bidRequestsConstants.UPDATE_BID_REQUEST_REQUEST, bidRequests } }
    function success(bidRequests, bidRequestsId) { return { type: bidRequestsConstants.UPDATE_BID_REQUEST_SUCCESS, bidRequests , bidRequestsId} }
    function failure(error) { return { type: bidRequestsConstants.UPDATE_BID_REQUEST_FAILURE, error } }
}
function deleteBidRequests(bidRequestsId, isDelete) {
    return dispatch => {
        dispatch(request(bidRequestsId, isDelete));

        bidRequestsService.deleteBidRequests(bidRequestsId, isDelete)
            .then(
                bidRequestsResponse => dispatch(success(bidRequestsId, isDelete)),
                error => {
                    dispatch(failure(bidRequestsId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(bidRequestsId) { return { type: bidRequestsConstants.DELETE_BID_REQUEST_REQUEST, bidRequestsId } }
    function success(bidRequestsId, isDelete) { return { type: bidRequestsConstants.DELETE_BID_REQUEST_SUCCESS, bidRequestsId, isDelete } }
    function failure(bidRequestsId, error) { return { type: bidRequestsConstants.DELETE_BID_REQUEST_FAILURE, bidRequestsId, error } }
}

function removeBidRequests(bidRequestsId) {
    return dispatch => {
        dispatch(request(bidRequestsId));

        bidRequestsService.removeBidRequests(bidRequestsId)
            .then(
                bidRequestsResponse => dispatch(success(bidRequestsId)),
                error => {
                    dispatch(failure(bidRequestsId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(bidRequestsId) { return { type: bidRequestsConstants.REMOVE_BID_REQUEST_REQUEST, bidRequestsId } }
    function success(bidRequestsId) { return { type: bidRequestsConstants.REMOVE_BID_REQUEST_SUCCESS, bidRequestsId } }
    function failure(bidRequestsId, error) { return { type: bidRequestsConstants.REMOVE_BID_REQUEST_FAILURE, bidRequestsId, error } }
}

function archiveBidRequests(bidRequestsId, isArchive) {
    return dispatch => {
        dispatch(request(bidRequestsId));

        bidRequestsService.archiveBidRequests(bidRequestsId, isArchive)
            .then(
                bidRequestsResponse => dispatch(success(bidRequestsId, isArchive)),
                error => {
                    dispatch(failure(bidRequestsId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(bidRequestsId) { return { type: bidRequestsConstants.ARCHIVE_BID_REQUEST_REQUEST, bidRequestsId } }
    function success(bidRequestsId, isArchive) { return { type: bidRequestsConstants.ARCHIVE_BID_REQUEST_SUCCESS, bidRequestsId, isArchive } }
    function failure(bidRequestsId, error) { return { type: bidRequestsConstants.ARCHIVE_BID_REQUEST_FAILURE, bidRequestsId, error } }
}