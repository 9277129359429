import { workItemConstants } from '../_constants';

const nonProjectFieldConfig = [
  { name: 'workItemName', mandatory: true, enabled: true },
  { name: 'startDate', mandatory: true, enabled: true },
  { name: 'taskActCategory', mandatory: true, enabled: true },
  { name: 'timeEntry', mandatory: true, enabled: true },
  { name: 'account', mandatory: false, enabled: true, isAdditionalField: true },
  { name: 'division', mandatory: false, enabled: true, isAdditionalField: true },
  { name: 'requestingTeamName', mandatory: false, enabled: true, isAdditionalField: true },
  { name: 'clientPartner', mandatory: false, enabled: true, isAdditionalField: true }
];


const timeSheetNonProjectFieldConfig = [
  { name: 'workItemName', mandatory: true, enabled: true },
  { name: 'taskActCategory', mandatory: true, enabled: true },
  { name: 'timeEntry', mandatory: true, enabled: true },
  { name: 'account', mandatory: false, enabled: true, isAdditionalField: true },
  { name: 'division', mandatory: false, enabled: true, isAdditionalField: true },
  { name: 'requestingTeamName', mandatory: false, enabled: true, isAdditionalField: true },
  { name: 'clientPartner', mandatory: false, enabled: true, isAdditionalField: true }
];

const projectFieldConfig = [
  { name: 'workItemName', mandatory: true, enabled: true },
  { name: 'startDate', mandatory: true, enabled: true },
  { name: 'taskActCategory', mandatory: true, enabled: true },
  { name: 'timeEntry', mandatory: true, enabled: true }
];

const timeSheetProjectFieldConfig = [
  { name: 'workItemName', mandatory: true, enabled: true },
  { name: 'taskId', mandatory: true, enabled: true },
  { name: 'taskActCategory', mandatory: true, enabled: true },
  { name: 'timeEntry', mandatory: true, enabled: true }
];
const workItemFieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
  { name: 'taskActCategory', mandatory: true, enabled: true },
  { name: 'estStartTime', mandatory: true, enabled: true },
  { name: 'employeeId', mandatory: true, enabled: true },

];

export function workItem(state = { nonProjectFieldConfig, projectFieldConfig, timeSheetProjectFieldConfig, timeSheetNonProjectFieldConfig, workItems: [], items: [], showTodaysActivityModel: false }, action) {

  switch (action.type) {
    case workItemConstants.INITIALIZE_WORK_ITEMS_ACTIVITIES:
      return {
        ...state,
        filterOptionsTaskActivity: action.filterOptions
      };

    case workItemConstants.INITIALIZE_TASK_PLANNER_USER_TEAMS:
      return {
        ...state,
        userTeamsTaskPlanner: action.userTeams
      }

    case workItemConstants.INITIALIZE_TIME_SHEET_USER_TEAMS:
      return {
        ...state,
        userTeamsTimesheet: action.userTeams
      }

    case workItemConstants.CHANGE_FILTER_TASK_PLANNER:
      return {
        ...state,
        filterOptionsTaskPlanner: action.filterOptions
      };

    case workItemConstants.INITIALIZE_CREATE_WORK_ITEM_REQUEST:
      return {
        ...state,
        fieldConfig: workItemFieldConfig,
        createInitializing: true,
        showCreateModel: true,
        selectedWorkItemInfo: null
      };
    case workItemConstants.INITIALIZE_CREATE_WORK_ITEM_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        createConfig: action.configuration
      };


    case workItemConstants.INITIALIZE_CREATE_NONPROJECT_REQUEST:
      return {
        ...state,
        createNonProjectWorkItemInitializing: true,
        selectedNonProjectTaskInfo: null
      };
    case workItemConstants.INITIALIZE_CREATE_NONPROJECT_SUCCESS:
      return {
        ...state,
        createNonProjectWorkItemInitializing: false,
        nonProjectAppConfig: action.configuration
      };

    case workItemConstants.INITIALIZE_CREATE_NONPROJECT_FAILURE:
      return {
        ...state,
        createNonProjectWorkItemInitializing: false,
        createNonProjectInitializeError: action.error
      };

    case workItemConstants.CLOSE_WORK_ITEM_CREATE_WINDOW:
      const { selectedWorkItemInfo, ...stateCopy } = state;
      return {
        ...stateCopy,
        showCreateModel: false
      };
    case workItemConstants.INITIALIZE_CREATE_WORK_ITEM_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };
    case workItemConstants.GETALL_WORK_ITEMS_REQUEST:
      return {
        ...state,
        workItemLoading: true
      };
    case workItemConstants.GETALL_WORK_ITEMS_SUCCESS:
      return {
        ...state,
        workItemLoading: false,
        items: action.workItems,
        filterOptionsWI: action.filterOptions
      };
    case workItemConstants.GETALL_WORK_ITEMS_FAILURE:
      return {
        ...state,
        workItemLoading: false,
        items: [],
        error: action.error
      };

    case workItemConstants.GET_WORK_ITEM_REQUEST:
      return {
        ...state,
        getInfoLoading: true,
        selectedWorkItemInfo: null
      };
    case workItemConstants.GET_WORK_ITEM_SUCCESS:
      return {
        ...state,
        getInfoLoading: false,
        selectedWorkItemInfo: action.workItem
      };
    case workItemConstants.GET_WORK_ITEM_FAILURE:
      return {
        ...state,
        getInfoLoading: false,
        error: action.error
      };
    case workItemConstants.CREATE_WORK_ITEM_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case workItemConstants.CREATE_WORK_ITEM_SUCCESS:
      let itemsWithNewItem = state.items || [];
      itemsWithNewItem.push(action.workItem)
      return {
        ...state,
        items: itemsWithNewItem,
        creating: false,
        showCreateModel: action.showModel,
      };
    case workItemConstants.CREATE_WORK_ITEM_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case workItemConstants.UPDATE_WORK_ITEM_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items.map(workItem =>
          workItem.id === action.workItemId
            ? { ...workItem, updating: true }
            : workItem
        ),

        error: null
      };
    case workItemConstants.UPDATE_WORK_ITEM_SUCCESS:

      let newItems = null;
      if (state.items) {
        newItems = [...state.items];
        const index = state.items.findIndex(workItem => workItem.id === action.workItemId);
        newItems[index] = { ...newItems[index], ...action.workItem }
      }
      let newWorkItems = null;
      if (state.workItems) {
        newWorkItems = [...state.workItems];
        const index = state.workItems.findIndex(workItem => workItem.id === action.workItemId);
        newWorkItems[index] = { ...newWorkItems[index], ...action.workItem }
      }
      return {
        ...state,
        updating: false,
        items: newItems,
        showCreateModel: false,
        workItems: newWorkItems
      };
    case workItemConstants.UPDATE_WORK_ITEM_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items.map(workItem => {
          if (workItem.id === action.workItemId) {
            const { updating, ...workItemCopy } = workItem;
            return { ...workItemCopy, deleteError: action.error };
          }
          return workItem;
        }),
        error: action.error
      };

    case workItemConstants.UPDATE_WORK_ITEM_STATUS_REQUEST:
      return {
        ...state,
        stausUpdating: true,
        error: null
      };
    case workItemConstants.UPDATE_WORK_ITEM_STATUS_SUCCESS:
      let newWorkItemBoardWorkItems = state.workItemBoardWorkItems.filter(workItem => workItem.id !== action.workItemId);
      let updatedWorkItemBoardWorkItem = state.workItemBoardWorkItems.find((taskObject) => { return taskObject.id === action.workItemId; });
      updatedWorkItemBoardWorkItem.stage = action.workItem.status === 'INPROGRESS' ? 3 : action.workItem.status === 'COMPLETED' ? 4 : action.workItem.status === 'READY_TO_START' ? 2 : action.workItem.status === 'PAUSED' ? 3 : 0;
      newWorkItemBoardWorkItems.push({
        ...updatedWorkItemBoardWorkItem,
        ...action.workItem
      });
      return {
        ...state,
        stausUpdating: false,
        workItemBoardWorkItems: newWorkItemBoardWorkItems
      };

    case workItemConstants.DELETE_WORK_ITEM_REQUEST:
      return {
        ...state,
        items: state.items.map(workItem =>
          workItem.id === action.workItemId
            ? { ...workItem, deleting: true }
            : workItem
        )
      };
    case workItemConstants.DELETE_WORK_ITEM_SUCCESS:
      // remove deleted workItem from state
      return {
        ...state,
        items: state.items && state.items.map(workItem => {
          if (workItem.id === action.workItemId) {
            // make copy of workItem without 'deleting:true' property
            const { deleting, ...workItemCopy } = workItem;
            // return copy of workItem with 'deleteError:[error]' property
            return { ...workItemCopy, deleted: action.isDelete };
          }

          return workItem;
        }),
        workItems: state.workItems && state.workItems.map(workItem => {
          if (workItem.id === action.workItemId) {
            // make copy of workItem without 'deleting:true' property
            const { deleting, ...workItemCopy } = workItem;
            // return copy of workItem with 'deleteError:[error]' property
            return { ...workItemCopy, deleted: action.isDelete };
          }

          return workItem;
        }),
        workItemBoardWorkItems: state.workItemBoardWorkItems && state.workItemBoardWorkItems.map(workItem => {
          if (workItem.id === action.workItemId) {
            // make copy of workItem without 'deleting:true' property
            const { deleting, ...workItemCopy } = workItem;
            // return copy of workItem with 'deleteError:[error]' property
            return { ...workItemCopy, deleted: action.isDelete };
          }

          return workItem;
        })
      };
    case workItemConstants.DELETE_WORK_ITEM_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to workItem 
      return {
        ...state,
        items: state.items.map(workItem => {
          if (workItem.id === action.workItemId) {
            // make copy of workItem without 'deleting:true' property
            const { deleting, ...workItemCopy } = workItem;
            // return copy of workItem with 'deleteError:[error]' property
            return { ...workItemCopy, deleteError: action.error };
          }

          return workItem;
        })
      };

    case workItemConstants.REMOVE_WORK_ITEM_SUCCESS:
      return {
        ...state,
        items: state.items && state.items.filter(workItem => workItem.id !== action.workItemId),
        workItems: state.workItems && state.workItems.filter(workItem => workItem.id !== action.workItemId),
        workItemBoardWorkItems: state.workItemBoardWorkItems && state.workItemBoardWorkItems.filter(workItem => workItem.id !== action.workItemId)
      };

    case workItemConstants.CHANGE_FILTER_WORK_ITEMS:
      return {
        ...state,
        filterOptionsWI: action.filterOptions
      };
    case workItemConstants.INITIALIZE_WORK_ITEMS_FULLCALENDAR:
      return {
        ...state,
        userTeamsFC: action.userTeams,
        viewMode: action.viewMode
      }

    case workItemConstants.GETALL_WORK_ITEM_FULLCALENDAR_SUCCESS:
      return {
        ...state,
        workItemsCalendar: action.workItems
      }

    case workItemConstants.GETALL_WORK_ITEM_COMPLETED_REQUEST:
      return {
        ...state,
        workItems: [],
        isLoadingCompleted: true
      }
    case workItemConstants.GETALL_WORK_ITEM_COMPLETED_SUCCESS:
      return {
        ...state,
        workItems: action.workItems,
        isLoadingCompleted: false
      }
    case workItemConstants.GETALL_WORK_ITEM_COMPLETED_FAILURE:
      return {
        ...state,
        isLoadingCompleted: false
      }

    case workItemConstants.GET_TODAYS_WORK_ITEM_REQUEST:
      return {
        ...state,
        todaysWorkItems: [],
        isTodaysWorkItemsLoading: true
      }
    case workItemConstants.GET_TODAYS_WORK_ITEM_SUCCESS:
      return {
        ...state,
        todaysWorkItems: action.workItems,
        isTodaysWorkItemsLoading: false
      }
    case workItemConstants.GET_TODAYS_WORK_ITEM_FAILURE:
      return {
        ...state,
        isTodaysWorkItemsLoading: false
      }

    case workItemConstants.GETALL_WORK_ITEM_PENDING_REQUEST:
      return {
        ...state,
        workItemsPending: [],
        isLoadingPending: true
      }
    case workItemConstants.GETALL_WORK_ITEM_PENDING_SUCCESS:
      return {
        ...state,
        workItemsPending: action.workItems,
        isLoadingPending: false
      }
    case workItemConstants.GETALL_WORK_ITEM_PENDING_FAILURE:
      return {
        ...state,
        isLoadingPending: false
      }

    case workItemConstants.INITIALIZE_WORK_ITEMS_BOARD:
      return {
        ...state,
        userTeamsTB: action.userTeams,
        filterOptionsTB: action.filterOptions
      }
    case workItemConstants.WORK_ITEMS_BOARD_CHANGE_EMPLOYEES:
      return {
        ...state,
        employeeIdsTB: action.employeeIds
      }
    case workItemConstants.GETALL_WORK_ITEM_BOARD_REQUEST:
      return {
        ...state,
        workItemBoardWorkItems: [],
        isLoadingBoard: true
      }
    case workItemConstants.GETALL_WORK_ITEM_BOARD_SUCCESS:
      return {
        ...state,
        workItemBoardWorkItems: action.workItems,
        isLoadingBoard: false
      }
    case workItemConstants.GETALL_WORK_ITEM_BOARD_FAILURE:
      return {
        ...state,
        isLoadingBoard: false
      }

    case workItemConstants.SHOW_NONPROJECT_CREATE_WINDOW:
      return {
        ...state,
        showNonProjectCreateModel: true
      };
    case workItemConstants.CLOSE_NONPROJECT_CREATE_WINDOW:
      return {
        ...state,
        showNonProjectCreateModel: false
      };


    case workItemConstants.SHOW_TASKPLANNER_CREATE_WINDOW:
      return {
        ...state,
        showTaskPlannerCreateModel: true
      };
    case workItemConstants.CLOSE_TASKPLANNER_CREATE_WINDOW:
      return {
        ...state,
        showTaskPlannerCreateModel: false
      };

    case workItemConstants.ADD_NONPROJECT_DATA_REQUEST:
      return {
        ...state,
        createNonProjectWorkItemInitializing: true
      };

    case workItemConstants.ADD_NONPROJECT_DATA_SUCCESS:
      return {
        ...state
      };

    case workItemConstants.GET_TIMESHEET_DATA_REQUEST:
      return {
        ...state,
        timesheetLoading: true,
        timeSheetData: null
      }
    case workItemConstants.GET_TIMESHEET_DATA_SUCCESS:
      return {
        ...state,
        timesheetLoading: false,
        timeSheetData: action.timesheetResponse
      }
    case workItemConstants.GET_TIMESHEET_DATA_FAILURE:
      return {
        ...state,
        timesheetLoading: false,
      }
    case workItemConstants.ADD_TIMESHEET_DATA_REQUEST:
      return {
        timesheetLoading: true,
        ...state,
      }
    case workItemConstants.ADD_TIMESHEET_DATA_SUCCESS:
      return {
        ...state,
        timesheetLoading: false,
        timeSheetData: action.timesheetResponse
      }

    case workItemConstants.SHOW_TODAYS_ACTIVITY_WINDOW:
      return {
        ...state,
        showTodaysActivityModel: !state.showTodaysActivityModel
      }

    case workItemConstants.CLOSE_TODAYS_ACTIVITY_WINDOW:
      return {
        ...state,
        showTodaysActivityModel: false
    }

    case workItemConstants.GETALL_CLONED_BY_WORK_ITEMS_REQUEST:
      return {
        clonedByWorkItems: null,
        ...state,
      }

    case workItemConstants.GETALL_CLONED_BY_WORK_ITEMS_SUCCESS:
      return {
        clonedByWorkItems: action.workItems,
        ...state,
      }

    case workItemConstants.GETALL_CLONED_BY_WORK_ITEMS_FAILURE:
      return {
        clonedByWorkItems: null,
        ...state,
      }

    default:
      return state
  }
}