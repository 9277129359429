import { itemAdditionalConstants } from "_constants";

let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
]

export function itemAdditional(state = { fieldConfig }, action) {

  let itemTypeName = (action.itemType === 'COMMENT' ? 'comments' : (action.itemType === 'ACTIVITY' ? 'activities' : 'histories'))
  switch (action.type) {

    case itemAdditionalConstants.GETALL_ITEM_ADDITIONALS_REQUEST:
      return {
        ...state,
        isLoading: true,
        [itemTypeName]: []
      };

    case itemAdditionalConstants.GETALL_ITEM_ADDITIONALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        [itemTypeName]: action.itemAdditionals
      };

    case itemAdditionalConstants.GETALL_ITEM_ADDITIONALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        [itemTypeName]: []
      };

    case itemAdditionalConstants.CREATE_ITEM_ADDITIONAL_REQUEST:
      return {
        ...state,
        creatingItemAdditional: true
      };

    case itemAdditionalConstants.CREATE_ITEM_ADDITIONAL_SUCCESS:
      let newComments = state[itemTypeName];
      newComments.push(action.itemAdditional);
      return {
        ...state,
        [itemTypeName]: newComments,
        creatingItemAdditional: false
      };

    case itemAdditionalConstants.CREATE_ITEM_ADDITIONAL_FAILURE:
      return {
        ...state,
        creatingItemAdditional: false
      };

    case itemAdditionalConstants.REMOVE_ITEM_ADDITIONAL_REQUEST:
      return {
        ...state,
      };

    case itemAdditionalConstants.REMOVE_ITEM_ADDITIONAL_SUCCESS:
      return {
        ...state,
        [itemTypeName]: state[itemTypeName].filter(item => item.id !== action.itemId)
      };
    case itemAdditionalConstants.REMOVE_ITEM_ADDITIONAL_FAILURE:
      return {
        ...state
      };
    case itemAdditionalConstants.SEARCH_INPUT_CHANGE:
      return {
        ...state,
        searchText: action.searchText
      };
    case itemAdditionalConstants.GET_ALL_SEARCH_RESULT_REQUEST:
    case itemAdditionalConstants.GET_ALL_SEARCH_RESULT_FAILURE:
      return {
        ...state,
        searchResults: null
      };
    case itemAdditionalConstants.GET_ALL_SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        searchResults: action.searchResults
      };
    default:
      return state
  }
}