
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const checklistService = {
    getAllChecklists,
    createChecklist,
    updateChecklist,
    removeChecklist,
    activateChecklist,
    updateItemChecklistData
};

async function getAllChecklists(request) {
    let params = request.userTeamId ? `?userTeamId=${request.userTeamId}` : '';
    return await axiosUtils.get(urlConfig.CHECKLIST + `/${request.itemType}` + params);
}

async function createChecklist(checklistRequest) {
    return await axiosUtils.post(urlConfig.CHECKLIST, checklistRequest);
}

async function updateChecklist(checklistRequest) {
    return await axiosUtils.put(urlConfig.CHECKLIST, checklistRequest);
}

async function removeChecklist(checklistId) {
    return await axiosUtils.remove(urlConfig.CHECKLIST + `/${checklistId}`);
}

async function activateChecklist(checklistId, isActive) {
    return await axiosUtils.put(urlConfig.CHECKLIST + `/${checklistId}/${isActive}`);
}

async function updateItemChecklistData(checklistDataReqeust, itemId, itemType) {
    return await axiosUtils.put(urlConfig.CHECKLIST + `/item/${itemId}/${itemType}`, checklistDataReqeust);
 }
 