import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import ResourcePlanner from "./components/ResourcePlanner";

class ResourcePlannerPage extends PureComponent {

  render() {
    return ( 
      <div className="projectlist">

        <div className="section-body mt-1">
          <div className="container-fluid">
            <div className="col-12 p-0">
              <div className="card">

                <div className="card-body">
                  <div className="table-responsive">
                    <ResourcePlanner /> 

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePlannerPage);