import classNames from "classnames/bind";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import dateUtils from "_helpers/date.utils";
import { commonUtils } from "_helpers";

class ReportPageTable extends Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-vcenter table-striped">


          {this.props.isLoading &&
            <tbody>
              <tr>
                <td colSpan="12">
                  <Skeleton count={7} height={40} />
                </td>
              </tr>
            </tbody>
          }
          {this.props.metaInfo &&
            <thead className="thead-light">

              {this.getReportingHeader()}

            </thead>
          }


          <tbody>
            {this.props.items && this.props.metaInfo && this.props.items.map((item, index) =>
              <tr key={index}>{this.getReportingData(item, index + 1)}</tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  getDaysBetweenTwoDates = (startDate, endDate) => {
    const dates = [];
    var startDateTmp = startDate.clone().startOf('days');

    startDate = startDate.add(1, 'days');
    while (startDateTmp <= endDate) {
      dates.push(dateUtils.convertDateFormat(startDateTmp));
      startDateTmp = dateUtils.addXDays(startDateTmp, 1);
    }

    return dates;
  }

  getReportingHeader = () => {
    return this.props.metaInfo.reportType === 'default' ? this.getDefaultHeader() :
      this.props.metaInfo.reportType === 'format3' ? this.getFormat3Header() :
        this.props.metaInfo.reportType === 'projectDetailsBySkills' ? this.getProjectDetailsBySkillsHeader() :
          this.props.metaInfo.reportType === 'employeeUtilization' ? this.getEmployeeUtilizationHeader() :
            this.props.metaInfo.reportType === 'myTimesheetData' ? this.getMyUtilizationTimeHeader() :
              this.props.metaInfo.reportType === 'myUtilization' ? this.getMyUtilizationHeader() :
                this.props.metaInfo.reportType === 'siteActivity' ? this.getEmployeeSiteActivityHeader() :
                  this.props.metaInfo.reportType === 'weekWiseEmployeeUtilizationMonthly' ? this.getWeekWiseEmployeeUtilizationMonthlyHeader() :
                    this.props.metaInfo.reportType === 'weekWiseEmployeeUtilizationMonthlyEndToEnd' ? this.getWeekWiseEmployeeUtilizationMonthlyHeader() :
                      this.props.metaInfo.reportType === 'weeklyPOEstimation' ? this.getWeeklyPOEstimationHeader() :
                        this.props.metaInfo.reportType === 'weeklyProjectHours' ? this.getWeeklyProjectHoursHeader() :
                          this.props.metaInfo.reportType === 'utilizationByEmployeeBySkills' ? this.getUtilizationByEmployeeBySkillsHeader() :
                            this.props.metaInfo.reportType === 'utilizationByEmployeeByProjectCategory' ? this.getUtilizationByEmployeeByCategoryHeader() :
                              this.props.metaInfo.reportType === 'dataDump' ? this.getDataDumpHeader() :
                                this.props.metaInfo.reportType === 'myTimesheetDataDump' ? this.getDataDumpHeader() :
                                  this.props.metaInfo.reportType === 'projectCompletion' ? this.getProjectCompletionHeader() :
                                    this.props.metaInfo.reportType === 'resourceAllocation' ? this.getResourceAllocationHeader() :
                                      this.props.metaInfo.reportType === 'project15Percentage' ? this.getProject15PercentageHeader() :
                                        (this.props.metaInfo.reportType === 'estimateVsActualByDeliverablesByTaskDate' || this.props.metaInfo.reportType === 'estimateVsActualByDeliverablesByActivityDate') ? this.getEstimateVsActualByDeliverablesHeader() : <></>
  }

  getReportingData = (item, index) => {
    return this.props.metaInfo.reportType === 'default' ? this.getDefaultData(item, index) :
      this.props.metaInfo.reportType === 'format3' ? this.getFormat3Data(item, index) :
        this.props.metaInfo.reportType === 'projectDetailsBySkills' ? this.getProjectDetailsBySkillsData(item, index) :
          this.props.metaInfo.reportType === 'employeeUtilization' ? this.getEmployeeUtilizationData(item, index) :
            this.props.metaInfo.reportType === 'myTimesheetData' ? this.getMyUtilizationTimeData(item, index) :
              this.props.metaInfo.reportType === 'myUtilization' ? this.getMyUtilizationData(item, index) :
                this.props.metaInfo.reportType === 'siteActivity' ? this.getEmployeeSiteActivityData(item, index) :
                  this.props.metaInfo.reportType === 'weekWiseEmployeeUtilizationMonthly' ? this.getWeekWiseEmployeeUtilizationMonthlyData(item, index) :
                    this.props.metaInfo.reportType === 'weekWiseEmployeeUtilizationMonthlyEndToEnd' ? this.getWeekWiseEmployeeUtilizationMonthlyData(item, index) :
                      this.props.metaInfo.reportType === 'weeklyPOEstimation' ? this.getWeeklyPOEstimationData(item, index) :
                        this.props.metaInfo.reportType === 'weeklyProjectHours' ? this.getWeeklyProjectHoursData(item, index) :
                          this.props.metaInfo.reportType === 'utilizationByEmployeeBySkills' ? this.getUtilizationByEmployeeBySkillsData(item, index) :
                            this.props.metaInfo.reportType === 'utilizationByEmployeeByProjectCategory' ? this.getUtilizationByEmployeeByCategoryData(item, index) :
                              this.props.metaInfo.reportType === 'dataDump' ? this.getDataDumpData(item, index) :
                                this.props.metaInfo.reportType === 'myTimesheetDataDump' ? this.getDataDumpData(item, index) :
                                  this.props.metaInfo.reportType === 'projectCompletion' ? this.getProjectCompletionData(item, index) :
                                    this.props.metaInfo.reportType === 'resourceAllocation' ? this.getResourceAllocationData(item, index) :
                                      this.props.metaInfo.reportType === 'project15Percentage' ? this.getProject15PercentageData(item, index) :
                                      (this.props.metaInfo.reportType === 'estimateVsActualByDeliverablesByTaskDate' || this.props.metaInfo.reportType === 'estimateVsActualByDeliverablesByActivityDate') ? this.getEstimateVsActualByDeliverablesData(item, index) : <></>

  }

  getEmployeeSiteActivityHeader = () => {
    const startDate = dateUtils.convertDateToMoment(this.props.metaInfo.startDate);
    const endDate = dateUtils.convertDateToMoment(this.props.metaInfo.endDate);

    return <tr className="reportDataHeader-activity">
      <th scope="col" width="50px">Sl No</th>
      <th scope="col" width="100px">User Group</th>
      <th scope="col" width="100px">Employee Name</th>
      <th scope="col">Reporting Manager</th>
      {this.getDaysBetweenTwoDates(startDate, endDate).map(dateString =>
        <th scope="col" key={dateString}>{dateString}</th>
      )}
    </tr>;
  }

  getEmployeeSiteActivityData = (item, index) => {

    return <>
      <td scope="row" width="50px">{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.employeeName}</td>
      <td>{item.reportingManager || ''}</td>
      {item.statusList.map((statusItem, statusIndex) =>
        <td key={index + '' + statusIndex} className={classNames({ 'colorred': statusItem.status === 'Inactive', 'colorgreen': statusItem.status !== 'Inactive' })}>{statusItem.status}</td>
      )}
    </>
  }

  getDefaultHeader = () => {
    return <></>
  }

  getDefaultData = () => {
    return <></>
  }

  getFormat3Header = () => {
    return <></>
  }

  getFormat3Data = () => {
    return <></>
  }

  getProjectDetailsBySkillsHeader = () => {
    return <></>
  }

  getProjectDetailsBySkillsData = () => {
    return <></>
  }

  getEmployeeUtilizationHeader = () => {
    return <tr className="reportDataHeader-utilization">
      <th width="50px">Sl No</th>
      <th>User Group</th>
      <th>Employee Name</th>
      <th>Employee Code</th>
      <th>Reporting Manager</th>
      <th>Billable Hours</th>
      <th>Non Billable Hours</th>
      <th>Leave or Holiday</th>
      <th>No. Of Work Days</th>
      <th>Utilization</th>
      <th>Total Hours</th>
      {this.props.metaInfo.IS_MONTHLY &&
        <th>Billable Utilization</th>
      }
    </tr>;
  }

  getEmployeeUtilizationData = (item, index) => {
    return <>
      <td scope="row" width="50px">{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.employeeName}</td>
      <td>{item.empId}</td>
      <td>{item.reportingManager}</td>
      <td>{item.billableHours}</td>
      <td>{item.nonBillableHours}</td>
      <td>{item.leaveOrHolidayHours}</td>
      <td>{item.noOfWorkingDays}</td>
      <td>{item.utilization} %</td>
      <td>{item.totalHours}</td>
      {this.props.metaInfo.IS_MONTHLY && <td>{item.billableUtilization} %</td>}
    </>
  }

  getMyUtilizationHeader = () => {
    return <tr className="reportDataHeader-utilization">
      <th width="50px">Sl No</th>
      <th>Date</th>
      <th>User Group</th>
      <th>Billable Hours</th>
      <th>Non Billable Hours</th>
      <th>Leave or Holiday</th>
      <th>Total Hours</th>
      <th>Utilization</th>
    </tr>;
  }

  getMyUtilizationData = (item, index) => {
    return <>
      <td scope="row" width="50px">{index}</td>
      <td>{item.startDateString}</td>
      <td>{item.userTeamId}</td>
      <td>{item.billableHours}</td>
      <td>{item.nonBillableHours}</td>
      <td>{item.leaveOrHolidayHours}</td>
      <td>{item.totalHours}</td>
      <td>{item.utilization} %</td>
    </>
  }
  getMyUtilizationTimeHeader = () => {
    return <tr className="reportDataHeader-utilization">
      <th width="50px">Sl No</th>
      <th>Date</th>
      <th>User Group</th>
      <th>Billable Hours</th>
      <th>Non Billable Hours</th>
      <th>Leave or Holiday</th>
      <th>Total Hours</th>
    </tr>;
  }
  getMyUtilizationTimeData = (item, index) => {
    return <>
      <td scope="row" width="50px">{index}</td>
      <td>{item.startDateString}</td>
      <td>{item.userTeamId}</td>
      <td>{item.billableHours}</td>
      <td>{item.nonBillableHours}</td>
      <td>{item.leaveOrHolidayHours}</td>
      <td>{item.totalHours}</td>
    </>
  }
  getWeekWiseEmployeeUtilizationMonthlyHeader = () => {
    return <>
      <tr><th width="50px" className="reportDataHeader-utilization-monthly">Sl No</th>
        <th>User Group</th>
        <th>Employee Name</th>
        <th>Employee Code</th>
        <th>Reporting Manager</th>
        {Object.keys(this.props.metaInfo.WEEK_DATE_RANGE_LIST).map((weekNumber) =>
          <th scope="col" colSpan={4} key={weekNumber}>{this.props.metaInfo.WEEK_DATE_RANGE_LIST[weekNumber]}</th>
        )}
      </tr>
      <tr>
        <td colspan='5'></td>
        {Object.keys(this.props.metaInfo.WEEK_DATE_RANGE_LIST).map((weekNumber) => <>
          <th>B</th>
          <th>NB</th>
          <th>L/H</th>
          <th>Total Hours</th>
        </>
        )}
      </tr>
    </>
  }

  getWeekWiseEmployeeUtilizationMonthlyData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.employeeName}</td>
      <td>{item.empId}</td>
      <td>{item.reportingManager}</td>
      {Object.keys(this.props.metaInfo.WEEK_DATE_RANGE_LIST).map((weekNumber) => <>
        <td>{item.userUtilizationMap[weekNumber].billableHours}</td>
        <td>{item.userUtilizationMap[weekNumber].nonBillableHours}</td>
        <td>{item.userUtilizationMap[weekNumber].leaveOrHolidayHours}</td>
        <td>{item.userUtilizationMap[weekNumber].totalWithLeaveHrs}</td>
      </>
      )}
    </>
  }


  getResourceAllocationHeader = () => {
    return <tr className="reportDataHeader-project15percentage">
      <th><strong>Sl No</strong></th>
      <th><strong>User Group Name</strong></th>
      <th><strong>Start Date</strong></th>
      <th><strong>Full Name</strong></th>
      <th><strong>Employee Id</strong></th>
      <th><strong>Account Name</strong></th>
      <th><strong>Project Id</strong></th>
      <th><strong>Project Name</strong></th>
      <th><strong>Deliverable Assigned</strong></th>
      <th><strong>Task Assigned</strong></th>
      <th><strong>Service Line</strong></th>
      <th><strong>No. of Hours Assigned</strong></th>
      <th><strong>No. of Hours Executed</strong></th>
      <th><strong>Assigned By</strong></th>
    </tr>
  }

  getResourceAllocationData = (item, index) => {
    return <>
      <td>{index}</td>
      <td >{item.userGroupName}</td>
      <td >{dateUtils.convertDateTimeFormat(item.startTime)}</td>
      <td >{item.fullName}</td>
      <td >{item.employeeId}</td>
      <td >{item.accountName}</td>
      <td >{item.projectId}</td>
      <td >{item.projectName}</td>
      <td >{item.taskName}</td>
      <td >{item.taskActivityName}</td>
      <td >{item.serviceLine}</td>
      <td >{dateUtils.minuteToHourString(item.estimateDualDurationInMins)}</td>
      <td >{dateUtils.minuteToHourString(item.actualDurationInMins)}</td>
      <td >{item.assignedBy || item.createdBy}</td>
    </>
  }

  getProjectCompletionHeader = () => {
    return <tr className="reportDataHeader-project15percentage">
      <th><strong>Sl No</strong></th>
      <th>User Group</th>
      <th><strong>Purchase Order Number</strong></th>
      <th><strong>Proposal Number</strong></th>
      <th><strong>Project Id</strong></th>
      <th><strong>Project Code</strong></th>
      <th><strong>Project Name</strong></th>
      <th><strong>Project Start Date</strong></th>
      <th><strong>Project End Date	</strong></th>
      <th><strong>Completed By</strong></th>
      <th><strong>Closed By</strong></th>
      <th><strong>Account</strong></th>
      <th><strong>Team Name</strong></th>
      <th><strong>Client Name</strong></th>
      <th><strong>Project Status</strong></th>

    </tr>
  }


  getProjectCompletionData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td >{item.purchaseOrderNumber}</td>
      <td >{item.proposalNumber}</td>
      <td >{item.projectId}</td>
      <td >{item.projectCode}</td>
      <td >{item.projectName}</td>
      <td >{dateUtils.convertDateFormat(item.projectStartTime)}</td>
      <td >{dateUtils.convertDateFormat(item.projectCompletionTime)}</td>
      <td >{item.completedBy}</td>
      <td >{item.closedBy}</td>
      <td >{item.projectAccountName}</td>
      <td >{item.projectTeamName}</td>
      <td >{item.projectClientNames && item.projectClientNames.join(',')}</td>
      <td >{item.projectStatus}</td>
    </>
  }

  getProject15PercentageHeader = () => {
    return <tr className="reportDataHeader-project15percentage">
      <th><strong>Sl No</strong></th>
      <th>User Group</th>
      <th><strong>Proposal Number</strong></th>
      <th><strong>Project Id</strong></th>
      <th><strong>Project Code</strong></th>
      <th><strong>Project Name</strong></th>
      <th><strong>Serviceline</strong></th>
      <th><strong>Project Start Date</strong></th>
      <th><strong>Project End Date	</strong></th>
      <th><strong>Account</strong></th>
      <th><strong>Team Name</strong></th>
      <th><strong>Client Name</strong></th>
      <th><strong>Project Status</strong></th>
      <th><strong>Estimated Hours</strong></th>
      <th><strong>Executed Hours</strong></th>
      <th><strong>Overall Programmers</strong></th>
      <th><strong>Programmers consumed 15% on executed Hrs</strong></th>
    </tr>
  }

  getProject15PercentageData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td >{item.proposalNumber}</td>
      <td >{item.projectId}</td>
      <td >{item.projectCode}</td>
      <td >{item.projectName}</td>
      <td >{item.serviceLine}</td>
      <td >{dateUtils.convertDateFormat(item.projectStartTime)}</td>
      <td >{dateUtils.convertDateFormat(item.projectCompletionTime)}</td>
      <td >{item.projectAccountName}</td>
      <td >{item.projectTeamName}</td>
      <td >{item.projectClientNames && item.projectClientNames.join(',')}</td>
      <td >{item.projectStatus}</td>
      <td >{item.projectDurationHrs}</td>
      <td >{dateUtils.minuteToHourString(item.totalActualProjectDurationInMins)}</td>
      <td dangerouslySetInnerHTML={{ __html: item.employeeTaskActivityDurationMap && Object.keys(item.employeeTaskActivityDurationMap).map(emp => emp + " (" + dateUtils.minuteToHourString(item.employeeTaskActivityDurationMap[emp]) + ")").join("<br/>") }}></td>
      <td >{item.aboveThresholdEmployees && item.aboveThresholdEmployees.join(", ")}</td>
    </>
  }

  getEstimateVsActualByDeliverablesHeader = () => {
    return <tr className="reportDataHeader-project15percentage">
      <th><strong>Sl No</strong></th>
      <th>User Group</th>
      <th><strong>Proposal Number</strong></th>
      <th><strong>Project Id</strong></th>
      <th><strong>Purchase Order Number</strong></th>
      <th><strong>Project Name</strong></th>
      <th><strong>Service Line</strong></th>

      <th><strong>Deliverable Category"</strong></th>
      <th><strong>Deliverable Id</strong></th>
      <th><strong>Delivery Type</strong></th>

      <th><strong>Deliverable Name</strong></th>
      <th><strong>Deliverable Start Date</strong></th>
      <th><strong>Deliverable End Date	</strong></th>
      <th><strong>Account</strong></th>
      <th><strong>Team Name</strong></th>
      <th><strong>Client Name</strong></th>

      <th><strong>LOI</strong></th>
      <th><strong>Project Complexity</strong></th>
      <th><strong>Tool</strong></th>
      <th><strong>Project Type</strong></th>
      <th><strong>Service Needed</strong></th>
      <th><strong>Survey Type</strong></th>

      <th><strong>Project Status</strong></th>
      <th><strong>Deliverable Status</strong></th>
      <th><strong>Estimated Hours</strong></th>

      <th><strong>Actual Hours Spent</strong></th>
      <th><strong>Non Invoice Hours Spent</strong></th>
      <th><strong>Rework Hours Spent</strong></th>

      <th><strong>Total Hours Spent</strong></th>
    </tr>

  }
  getEstimateVsActualByDeliverablesData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td >{item.proposalNumber}</td>
      <td >{item.projectId}</td>
      <td >{item.purchaseOrderNumber}</td>
      <td >{item.projectName}</td>
      <td >{item.serviceLine}</td>

      <td >{item.taskCategory}</td>
      <td >{item.id}</td>
      <td >{item.taskType}</td>

      <td >{item.name}</td>
      <td >{dateUtils.convertDateFormat(item.estStartTime)}</td>
      <td >{dateUtils.convertDateFormat(item.estCompletionTime)}</td>
      <td >{item.projectAccountName}</td>
      <td >{item.projectTeamName}</td>
      <td >{item.projectClientNames && item.projectClientNames.join(',')}</td>

      <td >{item.purchaseOrderLoi}</td>
      <td >{item.projectComplexity}</td>
      <td >{item.projectTools}</td>
      <td >{item.projectType}</td>
      <td >{item.purchaseOrderServiceCategory}</td>
      <td >{item.purchaseOrderSurveyType}</td>

      <td >{item.projectStatus}</td>
      <td >{item.status}</td>
      <td >{dateUtils.minuteToHourString(item.estDurationMins)}</td>

      <td >{dateUtils.minuteToHourString((item.additionalAttributes && item.additionalAttributes.actualMins) || 0)}</td>
      <td >{dateUtils.minuteToHourString((item.additionalAttributes && item.additionalAttributes.invoiceMins) || 0)}</td>
      <td >{dateUtils.minuteToHourString((item.additionalAttributes && item.additionalAttributes.reworkMins) || 0)}</td>

      <td >{dateUtils.minuteToHourString(item.billableHours)}</td>
    </>
  }

  getWeeklyPOEstimationHeader = () => {
    return <tr className="reportDataHeader-poestimation">
      <th><strong>Sl No</strong></th>
      <th>User Group</th>
      <th><strong>Client Name</strong></th>
      <th><strong>Order book ref id</strong></th>
      <th><strong>Proposal Number</strong></th>
      <th><strong>Offshore/Onshore</strong></th>
      <th><strong>Delivery Lead</strong></th>
      <th><strong>Team Name</strong></th>
      <th><strong>Account</strong></th>
      <th><strong>Project Name</strong></th>
      <th><strong>Project Id</strong></th>
      <th><strong>Project Code</strong></th>
      <th><strong>PO No</strong></th>
      <th><strong>SOW</strong></th>
      <th><strong>Start Date</strong></th>
      <th><strong>End Date</strong></th>
      <th><strong>Current Status</strong></th>
      <th><strong>Total Estimation Hours</strong></th>
      <th><strong>Consumed Hours</strong></th>
      <th><strong>% of Utilization</strong></th>
    </tr>
  }

  getWeeklyPOEstimationData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td >{item.projectClientNames && item.projectClientNames.join(',')}</td>
      <td >{item.orderBookId}</td>
      <td >{item.proposalNumber}</td>
      <td >{item.location}</td>
      <td >{item.deliveryLeadName}</td>
      <td >{item.teamName}</td>
      <td >{item.accountName}</td>
      <td >{item.projectName}</td>
      <td >{item.projectId}</td>
      <td >{item.projectCode}</td>
      <td >{item.projectNumber}</td>
      <td >{item.sowLineItem}</td>
      <td >{item.projectStartTimeFormated}</td>
      <td >{item.projectCompletionTimeFormated}</td>
      <td >{item.projectEstimateStatus}</td>
      <td dangerouslySetInnerHTML={{ __html: Object.keys(item.serviceLineEstimate).map(key => key + " (" + item.serviceLineEstimate[key] + " Hrs)").join("<br/>") }}></td>
      <td >{item.taskActivityDurationHrs}</td>
      <td >{item.utilizationString} %</td></>
  }


  getWeeklyProjectHoursHeader = () => {
    return <tr className="reportDataHeader-projecthours">
      <th width="50px">Sl No</th>
      <th>User Group</th>
      <th>Team Name</th>
      <th>Account Name</th>
      <th>Year</th>
      <th>Month</th>
      <th>Week NO</th>
      <th>Client Name</th>
      <th>PO NO</th>
      <th>Project Name</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Team Members</th>
      <th>Funded/Non Funded Resource</th>
      <th>Owner Name</th>
      <th>Estimated Hours</th>
      <th>Actual Hours</th>
      <th>Leave/Holiday</th>
    </tr>
  }

  getWeeklyProjectHoursData = (item, index) => {
    return <> <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.teamName}</td>
      <td>{item.accountName}</td>
      <td>{(dateUtils.getCurrentTime().year())}</td>
      <td>{(dateUtils.getCurrentTime().format('MMMM'))}</td>
      <td>{(dateUtils.getCurrentTime().week())}</td>
      <td>{item.clientNames && item.clientNames.join(',')}</td>
      <td>{item.projectNumber}</td>
      <td>{item.projectName}</td>
      <td>{item.startDateString}</td>
      <td>{item.endDateString}</td>
      <td>{item.teamMembers}</td>
      <td>{item.funded}</td>
      <td>{item.employeeName}</td>
      <td>{item.estimatedHours}</td>
      <td>{item.actualHours}</td>
      <td>{item.leaveOrHolidayHours}</td></>
  }


  getUtilizationByEmployeeBySkillsHeader = () => {
    return <tr className="reportDataHeader-utilizationbyuserskills">
      <th width="50px">Sl No</th>
      <th>User Group</th>
      <th>Employee Code</th>
      <th>Employee Name</th>
      <th>Designation</th>
      <th>Skillset</th>
      <th>Month</th>
      <th>Billable Hours</th>
      <th>Non Billable Hours</th>
      <th>Total Hours</th>
    </tr>

  }

  getUtilizationByEmployeeBySkillsData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.employeeCode}</td>
      <td>{item.employeeName}</td>
      <td>{item.designation}</td>
      <td>{item.skillOrCategory}</td>
      <td>{item.monthYear}</td>
      <td>{item.billableHours}</td>
      <td>{item.nonBillableHours}</td>
      <td>{item.totalHrs}</td>
    </>
  }

  getUtilizationByEmployeeByCategoryHeader = () => {
    return <tr className="reportDataHeader-utilizationbyprojectcategory">
      <th width="50px">Sl No</th>
      <th>User Group</th>
      <th>Employee Code</th>
      <th>Employee Name</th>
      <th>Designation</th>
      <th>Category</th>
      <th>Accounts</th>
      <th>Month</th>
      <th>Billable Hours</th>
      <th>Non Billable Hours</th>
      <th>Total Hours</th>
    </tr>
  }

  getUtilizationByEmployeeByCategoryData = (item, index) => {
    return <>
      <td>{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.employeeCode}</td>
      <td>{item.employeeName}</td>
      <td>{item.designation}</td>
      <td>{item.skillOrCategory}</td>
      <td>{item.projectAccounts.map(function (account) { return account; })}</td>
      <td>{item.monthYear}</td>
      <td>{item.billableHours}</td>
      <td>{item.nonBillableHours}</td>
      <td>{item.totalHrs}</td>
    </>
  }

  getDataDumpHeader = () => {
    return <tr className="reportDataHeader-timesheetdump">

      <th width="50px">Sl No</th>
      <th>User Group</th>
      <th>Employee Name</th>
      <th>Employee Code</th>
      <th>Delivery Lead</th>
      <th>Client</th>
      <th>Project code</th>
      <th>ID</th>
      <th>PO Id</th>
      <th>Order book Ref</th>
      <th>Proposal Number</th>
      <th>Project Name</th>
      <th>Project Status</th>
      <th>Project Type</th>
      <th>Project Category</th>
      <th>New Project Category</th>
      <th>Task Name</th>
      <th>Account</th>
      <th>Team</th>
      <th>Sub Team</th>
      <th>Date</th>
      <th>Actual Hours Spent</th>
      <th>Estimated Hours</th>
      <th>comment</th>
      <th>Quantity</th>
      <th>Updated Date &amp; Time</th>
    </tr>
  }

  getDataDumpData = (item, index) => {
    return <><td>{index}</td>
      <td>{item.userGroupName}</td>
      <td>{item.fullName}</td>
      <td>{item.employeeId}</td>
      <td>{item.deliveryLead}</td>
      <td>{item.clientNames && item.clientNames.join(',')}</td>
      <td>{item.projectCode}</td>
      <td>{item.projectId}</td>
      <td>{item.projectNumber}</td>
      <td>{item.orderRefNumber}</td>
      <td>{item.proposalNumber}</td>
      <td>{item.projectName}</td>
      <td>{item.projectStatus}</td>
      <td>{item.projectType}</td>
      <td>{item.projectCategory}</td>
      <td>{item.newProjectCategory}</td>
      <td>{item.taskName}</td>
      <td>{item.accountName}</td>
      <td>{item.teamName}</td>
      <td>{item.subTeamName}</td>
      <td>{item.taskActivityStartTimeFormated}</td>
      <td>{item.actDurationInHours}</td>
      <td>{dateUtils.minuteToHourString(item.estDurationInMins)}</td>
      <td>{item.taskCompletionComments}</td>
      <td>{item.quantity}</td>
      <td>{item.lastModifiedTimeFormated}</td>
    </>
  }

}
export default ReportPageTable;