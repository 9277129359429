import React, { Component } from "react";

class ProjectDetailsCalendar extends Component {
    render() {
        return (
            <div className="card pt-2 h-100 mb-0">
                <div className="card-header bline pt-0 pb-3">
                    <h3 className="card-title pl-2 text-normal-case fweight600">Thursday, 14</h3>
                    <div className="card-options">
                        <ul className="nav nav-tabs page-header-tab pl-2">
                            <li className="nav-item pr-0">
                                <button className="link grid-view-link active"><i className="fa fa-chevron-left" /></button></li>
                            <li className="nav-item pl-0 pr-2">
                                <button className="link list-view-link"><i className="fa fa-chevron-right" /></button></li>
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="container-fluid">
                            <div className="table-responsive">
                                <table className="table table-vcenter mb-0 table_custom spacing15 text-nowrap">
                                    <tbody>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectDetailsCalendar;
