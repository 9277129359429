import { purchaseOrderConstants } from '../_constants';
let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
  { name: 'userTeamId', mandatory: true, enabled: true, editable: true },
  { name: 'proposalNumber', mandatory: true, enabled: true, editable: true },
  { name: 'purchaseOrderNumber', mandatory: true, enabled: true, editable: true },
  { name: 'estStartTime', mandatory: true, enabled: true, editable: true },
  { name: 'estCompletionTime', mandatory: true, enabled: true, editable: true },
]
export function purchaseOrders(state = { fieldConfig }, action) {

  switch (action.type) {
    case purchaseOrderConstants.INITIALIZE_CREATE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        fieldConfig: fieldConfig,
        createInitializing: true,
      };
    case purchaseOrderConstants.INITIALIZE_CREATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        createConfig: [
          ...action.configuration
        ]
      };
    case purchaseOrderConstants.INITIALIZE_CREATE_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };
      case purchaseOrderConstants.CLEAR_SELECTED_PURCHASE_ORDER:
        return {
          ...state,
          selectedProjectInfo: null
        };

    case purchaseOrderConstants.GETALL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case purchaseOrderConstants.GETALL_PURCHASE_ORDERS_SUCCESS:
      //console.log(action.purchaseOrders);
      return {
        ...state,
        loading: false,
        items: action.purchaseOrders,
        filterOptions: action.filterOptions
      };
    case purchaseOrderConstants.GETALL_PURCHASE_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        error: action.error
      };
    case purchaseOrderConstants.GETALL_ACTIVE_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case purchaseOrderConstants.GETALL_ACTIVE_PURCHASE_ORDERS_SUCCESS:
      //console.log(action.purchaseOrders);
      return {
        ...state,
        loading: false,
        activeItems: action.purchaseOrders
      };
    case purchaseOrderConstants.GETALL_ACTIVE_PURCHASE_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        activeItems: [],
        error: action.error
      };

    case purchaseOrderConstants.CREATE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case purchaseOrderConstants.CREATE_PURCHASE_ORDER_SUCCESS:
      state.items.push(action.purchaseOrder);

      return {
        ...state,
        creating: false,
        showCreateModel: action.showModel,
      };
    case purchaseOrderConstants.CREATE_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case purchaseOrderConstants.UPDATE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items && state.items.map(purchaseOrder =>
          purchaseOrder.id === action.purchaseOrderId
            ? { ...purchaseOrder, updating: true }
            : purchaseOrder
        ),
        error: null
      };
    case purchaseOrderConstants.UPDATE_PURCHASE_ORDER_SUCCESS:
      let newItems = null;
      if (state.items) {
        newItems = [...state.items];
        const index = state.items.findIndex(purchaseOrder => purchaseOrder.id === action.purchaseOrderId);
        newItems[index] = { ...newItems[index], ...action.purchaseOrder }
      }
      const currentPurchaseOrderInfo = state.selectedPurchaseOrderInfo && action.purchaseOrder.id === state.selectedPurchaseOrderInfo.id ? {...state.selectedPurchaseOrderInfo, ...action.purchaseOrder} : state.selectedPurchaseOrderInfo
      return {
        ...state,
        updating: false,
        items: newItems,
        showCreateModel: false,
        selectedPurchaseOrderInfo: currentPurchaseOrderInfo
      };
    case purchaseOrderConstants.UPDATE_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items && state.items.map(purchaseOrder => {
          if (purchaseOrder.id === action.purchaseOrderId) {
            const { updating, ...purchaseOrderCopy } = purchaseOrder;
            return { ...purchaseOrderCopy, deleteError: action.error };
          }
          return purchaseOrder;
        }),
        error: action.error
      };
    case purchaseOrderConstants.DELETE_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        items: state.items.map(purchaseOrder =>
          purchaseOrder.id === action.purchaseOrderId
            ? { ...purchaseOrder, deleting: true }
            : purchaseOrder
        )
      };
    case purchaseOrderConstants.DELETE_PURCHASE_ORDER_SUCCESS:
      // remove deleted purchaseOrder from state
      return {
        ...state,
        items: state.items.map(purchaseOrder => {
          if (purchaseOrder.id === action.purchaseOrderId) {
            // make copy of purchaseOrder without 'deleting:true' property
            const { deleting, ...purchaseOrderCopy } = purchaseOrder;
            // return copy of purchaseOrder with 'deleteError:[error]' property
            return { ...purchaseOrderCopy, deleted: action.isDelete };
          }

          return purchaseOrder;
        })
      };
    case purchaseOrderConstants.DELETE_PURCHASE_ORDER_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to purchaseOrder 
      return {
        ...state,
        items: state.items.map(purchaseOrder => {
          if (purchaseOrder.id === action.purchaseOrderId) {
            // make copy of purchaseOrder without 'deleting:true' property
            const { deleting, ...purchaseOrderCopy } = purchaseOrder;
            // return copy of purchaseOrder with 'deleteError:[error]' property
            return { ...purchaseOrderCopy, deleteError: action.error };
          }

          return purchaseOrder;
        })
      };

    case purchaseOrderConstants.REMOVE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        items: state.items.filter(purchaseOrder => purchaseOrder.id !== action.purchaseOrderId)
      };

    case purchaseOrderConstants.ARCHIVE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        items: state.items.map(purchaseOrder =>
          purchaseOrder.id === action.purchaseOrderId
            ? { ...purchaseOrder, archived: action.isArchive }
            : purchaseOrder
        )
      };

    case purchaseOrderConstants.SHOW_PURCHASE_ORDER_CREATE_WINDOW:
      return {
        ...state,
        showCreateModel: true
      };

    case purchaseOrderConstants.CLOSE_PURCHASE_ORDER_CREATE_WINDOW:
      const { selectedPurchaseOrderInfo, ...stateCopy } = state;
      return {
        ...stateCopy,
        showCreateModel: false
      };


    case purchaseOrderConstants.GET_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        selectedPurchaseOrderInfo: null,
        getInfoLoading: true
      };
    case purchaseOrderConstants.GET_PURCHASE_ORDER_SUCCESS:
      //console.log(action.purchaseOrder);
      return {
        ...state,
        getInfoLoading: false,
        selectedPurchaseOrderInfo: action.purchaseOrder
      };
    case purchaseOrderConstants.GET_PURCHASE_ORDER_FAILURE:
      return {
        ...state,
        getInfoLoading: false,
        error: action.error
      };

    case purchaseOrderConstants.INITIALIZE_PURCHASE_ORDERS_USER_TEAMS:
      return {
        ...state,
        userTeams: action.userTeams
      }
    case purchaseOrderConstants.CHANGE_FILTER_PURCHASE_ORDERS:
      return {
        ...state,
        filterOptions: action.filterOptions
      };

    case purchaseOrderConstants.SHOW_PURCHASE_ORDER_VIEW_WINDOW:
      return {
        ...state,
        isShowPurchaseOrderViewSidebar: true
      }
    case purchaseOrderConstants.CLOSE_PURCHASE_ORDER_VIEW_WINDOW:
      return {
        ...state,
        isShowPurchaseOrderViewSidebar: false
      }


    default:
      return state
  }
}