import { employeeConstants } from '../_constants';
import { commonUtils } from "_helpers";

let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true, editable: true },
  { name: 'email', mandatory: true, enabled: true, editable: true },
  { name: 'empType', mandatory: true, enabled: true, editable: true },
  { name: 'dateOfJoin', mandatory: true, enabled: true, editable: false },
  { name: 'userTeamId', mandatory: true, enabled: true, editable: false },
  { name: 'userRole', mandatory: true, enabled: true, editable: true },
  { name: 'additionalUserTeamIds', mandatory: false, enabled: true, editable: false }
]

let holidayFieldConfig = [
  { name: 'name', mandatory: true, enabled: true, editable: true },
  { name: 'holidayDate', mandatory: true, enabled: true, editable: true },
  { name: 'locations', mandatory: true, enabled: true, editable: true },
]

export function employees(state = { holidayFieldConfig: holidayFieldConfig }, action) {

  switch (action.type) {
    case employeeConstants.INITIALIZE_CREATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        fieldConfig: fieldConfig,
        createInitializing: true
      };
    case employeeConstants.INITIALIZE_CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };
    case employeeConstants.INITIALIZE_CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        createConfig: [
          ...action.configuration
        ]
      };

    case employeeConstants.GET_TEAMS_EMPLOYEES_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        items: []
      };
    case employeeConstants.GET_TEAMS_EMPLOYEES_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        filterOptions: action.filterOptions,
        items: action.employees
      };
    case employeeConstants.GET_TEAMS_EMPLOYEES_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        error: action.error
      };
    case employeeConstants.GETALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        allEmployees: action.employees
      };

    case employeeConstants.UPDATE_EMPLOYEE_USER_ROLE_SUCCESS:
      let idsUpdated = action.employees.map(emp => emp.id);
      let employeeRolesUpdatedItems = state.allEmployees.filter(employee => !idsUpdated.includes(employee.id));
      return {
        ...state,
        allEmployees: [
          ...employeeRolesUpdatedItems,
          ...action.employees]
      };

    case employeeConstants.CHANGE_FILTER_EMPLOYEES:
      return {
        ...state,
        filterOptions: action.filterOptions
      };

    case employeeConstants.CREATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        registering: true,
        error: null
      };
    case employeeConstants.CREATE_EMPLOYEE_SUCCESS:
      state.items ? state.items.push(action.employee) : state.items = [action.employee];
      return {
        ...state,
        registering: false,
        showCreateModel: action.showModel,
      };
    case employeeConstants.CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        registering: false,
        error: action.error
      };

    case employeeConstants.UPDATE_EMPLOYEE_REQUEST:

      return {
        ...state,
        updating: true,
        items: state.items && state.items.map(employee =>
          employee.id === action.employeeId
            ? { ...employee, updating: true }
            : employee
        ),
        error: null
      };
    case employeeConstants.UPDATE_EMPLOYEE_SUCCESS:
      let newItems = null;
      if (state.items) {
        newItems = state.items && state.items.filter(employee => employee.id !== action.employeeId);
        newItems.push(action.employee);
      }
      const currentEmployeenfo = state.selectedEmployeeInfo && action.employee.id === state.selectedEmployeeInfo.id ? { ...state.selectedEmployeeInfo, ...action.employee } : state.selectedEmployeeInfo
      return {
        ...state,
        updating: false,
        items: newItems,
        showCreateModel: false,
        selectedEmployeeInfo: currentEmployeenfo
      };
    case employeeConstants.UPDATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items && state.items.map(employee => {
          if (employee.id === action.employeeId) {
            const { updating, ...employeeCopy } = employee;
            return { ...employeeCopy, deleteError: action.error };
          }
          return employee;
        }),
        error: action.error
      };
    case employeeConstants.DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        items: state.items.map(employee =>
          employee.id === action.employeeId
            ? { ...employee, deleting: true }
            : employee
        )
      };
    case employeeConstants.DELETE_EMPLOYEE_SUCCESS:
      // remove deleted employee from state
      return {
        ...state,
        items: state.items.map(employee => {
          if (employee.id === action.employeeId) {
            // make copy of employee without 'deleting:true' property
            const { deleting, ...employeeCopy } = employee;
            // return copy of employee with 'deleteError:[error]' property
            return { ...employeeCopy, deleted: action.isDelete };
          }

          return employee;
        })
      };
    case employeeConstants.DELETE_EMPLOYEE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to employee 
      return {
        ...state,
        items: state.items.map(employee => {
          if (employee.id === action.employeeId) {
            // make copy of employee without 'deleting:true' property
            const { deleting, ...employeeCopy } = employee;
            // return copy of employee with 'deleteError:[error]' property
            return { ...employeeCopy, deleteError: action.error };
          }

          return employee;
        })
      };

    case employeeConstants.REMOVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(employee => employee.id !== action.employeeId)
      };

    case employeeConstants.ACTIVATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        items: state.items.map(employee =>
          employee.id === action.employeeId
            ? { ...employee, active: action.isActive }
            : employee
        )
      };
    case employeeConstants.SHOW_EMPLOYEE_CREATE_WINDOW:
      {
        return {
          ...state,
          showCreateModel: true
        };
      }
    case employeeConstants.CLOSE_EMPLOYEE_CREATE_WINDOW:
      {
        const { selectedEmployeeInfo, ...stateCopy } = state;
        return {
          ...stateCopy,
          showCreateModel: false
        };
      }
    case employeeConstants.GET_INFO_EMPLOYEE_REQUEST:
      {
        const { selectedEmployeeInfo, ...stateCopy } = state;
        return {
          ...stateCopy,
          getInfoLoading: true
        };
      }
    case employeeConstants.GET_INFO_EMPLOYEE_SUCCESS:
      return {
        ...state,
        getInfoLoading: false,
        selectedEmployeeInfo: action.employee,
      };
    case employeeConstants.GET_INFO_EMPLOYEE_FAILURE:
      return {
        ...state,
        getInfoLoading: false
      };

    case employeeConstants.INITIALIZE_EMPLOYEE_USER_TEAMS:
      return {
        ...state,
        getInfoLoading: false
      }

    case employeeConstants.GET_TEAMS_ATTENDANCE_REQUEST:
      return {
        ...state,
        attendanceLoading: true,
        attendanceItems: []
      };

    case employeeConstants.INITIALIZE_EMPLOYEE_ATTENDANCE_USER_TEAMS:
      return {
        ...state,
        userTeamsA: action.userTeams
      }
    case employeeConstants.CHANGE_FILTER_EMPLOYEES_ATTENDANCE:
      return {
        ...state,
        filterOptionsA: action.filterOptions
      };

    case employeeConstants.GET_TEAMS_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendanceLoading: false,
        attendanceItems: action.attendance
      };

    case employeeConstants.CREATE_ATTENDANCE_SUCCESS:
      state.attendanceItems ? state.attendanceItems.push(action.attendance) : state.attendanceItems = [action.attendance];

      return {
        ...state
      };

    case employeeConstants.DELETE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        attendanceItems: state.attendanceItems.filter(item => item.id !== action.id)
      };


    case employeeConstants.INITIALIZE_HL_FULLCALENDAR:
      return {
        ...state,
        userTeamsHL: action.userTeams,
        viewModeHL: action.viewMode
      }


    case employeeConstants.SHOW_HOLIDAY_CREATE_WINDOW:
      return {
        ...state,
        showHolidayCreateModel: true
      };


    case employeeConstants.CLOSE_HOLIDAY_CREATE_WINDOW:
      return {
        ...state,
        showHolidayCreateModel: false
      };

    case employeeConstants.GET_HOLIDAY_LEAVE_REQUEST:
      return {
        ...state,
        holidayLoading: true,
        holidayItems: []
      };



    case employeeConstants.GET_HOLIDAY_LEAVE_SUCCESS:
      return {
        ...state,
        holidayLoading: false,
        holidayItems: action.holidays
      };



    case employeeConstants.CREATE_HOLIDAY_ITEM_REQUEST:
      return {
        ...state,
        holidayCreating: true,
        error: null
      };
    case employeeConstants.CREATE_HOLIDAY_ITEM_SUCCESS:
      let itemsWithNewItem = state.holidayItems || [];
      itemsWithNewItem.push(action.holidayItem)
      return {
        ...state,
        holidayItems: itemsWithNewItem,
        holidayCreating: false,
        showCreateModel: action.showModel,
      };
    case employeeConstants.CREATE_HOLIDAY_ITEM_FAILURE:
      return {
        ...state,
        holidayCreating: false,
        error: action.error
      };

    case employeeConstants.REMOVE_HOLIDAY_ITEM_SUCCESS:
      return {
        ...state,
        holidayItems: state.holidayItems.filter(holidayItem => holidayItem.id !== action.holidayItemId)
      };

    case employeeConstants.GET_TEAMS_EMPLOYEES_REQUEST:
      return {
        ...state,
        teamEmployeesloading: true,
        teamEmployees: []
      };
    case employeeConstants.GET_TEAMS_EMPLOYEES_SUCCESS:
      return {
        ...state,
        teamEmployeesloading: false,
        teamEmployees: commonUtils.getActiveEmployees(action.employees)
      };
    case employeeConstants.GET_TEAMS_EMPLOYEES_FAILURE:
      return {
        ...state,
        teamEmployeesloading: false,
        teamEmployees: [],
        error: action.error
      };
    case employeeConstants.TOGGLE_USER_DIV_WINDOW:
      return {
        ...state,
        showUserDiv: !state.showUserDiv
      };

    default:
      return state
  }
}
