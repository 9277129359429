import React, { Component } from "react";
import classNames from 'classnames/bind';
class ProjectListBarChart extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false
    };
  }

  toggle = (visible) => {
    this.setState(prevState => ({
      show: visible
    }));
  }

  render() {
    let progressBar = [];
    for (var i = 0; i < 28; i++) {
      progressBar.push(<div key={i} className="justify-content-between flex-column d-flex">
        <p className="vprogresslabel d-none"></p>
        <div className="progress progress-bar-vertical">
          <div className="progress-bar" style={{ height: (100 - i) + '%' }} role="progressbar" data-toggle="tooltip" data-placement="right" title="" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" data-original-title="Billable : 6:00 Hrs">
          </div>
        </div>
        <p className="vprogresslabel  fsize10 fweight600 mb-0">{i}</p>
      </div>)
    }
    return (
      <div className="row mx-0 px-0">
        <div className="col-lg-12 text-right">
          <button onClick={() => this.toggle(!this.state.show)} className="link pr-3 fsize10 fweight600">
            <i className={classNames("fa  pr-1", { "fa-eye-slash": this.state.show }, { "fa-eye": !this.state.show})} /></button>
        </div>
        {this.state.show &&
          <>
            <div className="col-lg-12 text-center m-0 px-4">
              <div className="d-flex justify-content-between align-items-center">
                {progressBar}
                {progressBar}
                {progressBar}
              </div>
            </div>
            <div className="col-lg-12 row">
              <div className="col-lg-4 text-left">
                <span className="text-muted font-weight-light fsize10 pl-4 text-capitalize">Jan</span>
              </div>
              <div className="col-lg-4 text-center">
                <span className="text-muted font-weight-light fsize10 text-capitalize"> Feb</span>
              </div>
              <div className="col-lg-4 text-right">
                <span className="text-muted font-weight-light fsize10 pr-2 text-capitalize">March</span>
              </div>
            </div>
          </>
        }
      </div>
    );
  }
}

export default ProjectListBarChart;