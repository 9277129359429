import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
class Columnchart extends Component {
    constructor(props) {
        super(props);

        this.state = 
        {
        taskActivity: {

            series: [{
                name: 'Non Billable',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: 'Billable',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }, {
                name: 'Leave/Holiday',
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    markers: {
                        fillColors: ['#5a5278', '#6f6593', '#8075aa', '#a192d9']
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: 'Hours'
                    }
                },
                fill: {
                    opacity: 1,
                    colors: ['#5a5278', '#6f6593', '#8075aa']
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "" + val + " hours"
                        }
                    }
                }
            },
        },

        employeeStructure: {

            series: [{
                name: 'Male',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: 'Female',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    markers: {
                        fillColors: ['#5a5278', '#6f6593', '#8075aa', '#a192d9']
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: 'Count'
                    }
                },
                fill: {
                    opacity: 1,
                    colors: ['#5a5278', '#6f6593', '#8075aa']
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "" + val + " count"
                        }
                    }
                }
            },
        }
    };
    }
    render() {
        return (
            <div>
                <div id="chart">
                    <ReactApexChart 
                        options={this.props.isEmployee ? this.state.employeeStructure.options : this.state.taskActivity.options} 
                        series={this.props.isEmployee ? this.state.employeeStructure.series : this.state.taskActivity.series} 
                        type="bar" height="auto" />
                </div>
            </div>
        );
    }
}

export default Columnchart;
