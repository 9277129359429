import React, { PureComponent } from "react";
import PurchaseOrderListTable from "./components/PurchaseOrderListTable";
import CreatePurchaseOrderSidebar from "./components/modal/CreatePurchaseOrderSidebar";
import { connect } from "react-redux";
import { purchaseOrderActions } from "_actions";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";
import PageInnerHeader from "views/common/PageInnerHeader";
import { commonUtils } from "_helpers";
import { purchaseOrderConstants } from "_constants";
import { accessUtils } from "_helpers";
import ItemNotFoundSection from "./components/ItemsNotFoundSection";
import ViewPurchaseOrderDetailsSidebar from "./components/modal/ViewPurchaseOrderDetailsSidebar";


class PurchaseOrderListPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ACTIVE", label: "Active" }, { name: "ARCHIVED", label: "Archived" }], selected: "ACTIVE" },
        SORT: { options: [{ name: "ESTIMATEDENDDATE", label: "Due Date" }, { name: "ESTIMATEDSTARTDATE", label: "Est Start Date" }, { name: "CREATEDDATE", label: "Created Date" }, { name: "A-Z", label: "A-Z" }], selected: "A-Z", directionUp:true },
        filterText: null,
        dateRangeValue: dateUtils.getDefaultDateRange(),
      },
      permission: accessUtils.getPurchaseOrderAccessControl(),
      editMode: false,
      duplicateMode: false
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    store.dispatch(purchaseOrderActions.loadModuleConfig(commonUtils.getCurrentUserTeamId(), this.updateConfig))
    if (!this.props.items) {
      this.invokePurchaseOrderListService();
    }
  }

  updateConfig = (config) => {
    this.setState({ isSalesForceEnabled: commonUtils.getAppConfigValue(config, "purchaseOrderImportFromSalesForceEnabled") });
  }

  invokePurchaseOrderListService = () => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    store.dispatch({ type: purchaseOrderConstants.INITIALIZE_PURCHASE_ORDERS_USER_TEAMS, userTeams });
    this.reloadPurchaseOrderListService(userTeams);
  }

  importPurchaseOrderList = () => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    let selectedUserTeams = commonUtils.getSelectedUserTeamIds(userTeams)
    let currentUserTeamId = commonUtils.getCurrentUserTeamId();
    const startDate = this.state.filterOptions.dateRangeValue.start.format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = this.state.filterOptions.dateRangeValue.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.importAndGetPurchaseOrderList({ userTeamIds: [currentUserTeamId, ...selectedUserTeams], startDate: startDate, endDate: endDate }, this.state.filterOptions))
  }

  reloadPurchaseOrderListService = (userTeams) => {
    let selectedUserTeams = []
    let currentUserId = commonUtils.getCurrentUserId();
    if (this.state.permission.switchUserTeamsEnabled === true) {
      userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    }
    const startDate = this.state.filterOptions.dateRangeValue.start.format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = this.state.filterOptions.dateRangeValue.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getPurchaseOrderList({ userTeamIds: selectedUserTeams, currentUserId: currentUserId, startDate: startDate, endDate: endDate }, this.state.filterOptions))
  }

  onCreateItemClick = (e) => {
    e.preventDefault();
    this.setState({ editMode: false });
    this.setState({ duplicateMode: false });
    let defaultTeam = commonUtils.getCurrentUserTeamId();
    store.dispatch({ type: purchaseOrderConstants.SHOW_PURCHASE_ORDER_CREATE_WINDOW });
    store.dispatch({ type: purchaseOrderConstants.CLEAR_SELECTED_PURCHASE_ORDER });
    store.dispatch(this.props.initializeCreatePurchaseOrder(defaultTeam));
  }
  onCloseCreateItem = (e) => {
    e.preventDefault();
    store.dispatch({ type: purchaseOrderConstants.CLOSE_PURCHASE_ORDER_CREATE_WINDOW });
  }

  handleDateRangeChange = (daterange) => {
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        dateRangeValue: daterange
      }
    }), () => {
      this.reloadPurchaseOrderListService(commonUtils.getUserTeams(this.props.userTeams));
    })
  }

  handleDeletePurchaseOrder = (purchaseOrderIdTobeDeleted, isDelete) => {

    store.dispatch(this.props.deletePurchaseOrder(purchaseOrderIdTobeDeleted, isDelete));
  }
  handleUpdateStatus = (projectId, status) => {
    store.dispatch(this.props.updatePurchaseOrderStatus(projectId, status));
  }


  handleEditPurchaseOrder = (purchaseOrderId, purchaseOrderUserTeamId) => {
    this.setState({ duplicateMode: false, editMode: true });
    store.dispatch(this.props.getPurchaseOrderInfo(purchaseOrderId));
    store.dispatch({ type: purchaseOrderConstants.SHOW_PURCHASE_ORDER_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreatePurchaseOrder(purchaseOrderUserTeamId));
  }

  handleDuplicatePurchaseOrder = (purchaseOrderId, purchaseOrderUserTeamId) => {

    this.setState({ duplicateMode: true, editMode: false });
    store.dispatch(this.props.getPurchaseOrderInfo(purchaseOrderId));
    store.dispatch({ type: purchaseOrderConstants.SHOW_PURCHASE_ORDER_CREATE_WINDOW });
    store.dispatch(this.props.initializeCreatePurchaseOrder(purchaseOrderUserTeamId));
  }

  onViewPurchaseOrder = (purchaseOrderId) => {
    store.dispatch(this.props.getPurchaseOrderInfo(purchaseOrderId));
    store.dispatch({ type: purchaseOrderConstants.SHOW_PURCHASE_ORDER_VIEW_WINDOW });
  }

  onCloseViewPurchaseOrder = () => {
    store.dispatch({ type: purchaseOrderConstants.CLOSE_PURCHASE_ORDER_VIEW_WINDOW });
  }
  handleRemovePurchaseOrder = (purchaseOrderId) => {
    store.dispatch(this.props.removePurchaseOrder(purchaseOrderId));
  }

  handleArchivePurchaseOrder = (purchaseOrderId, isActive) => {
    store.dispatch(this.props.archivePurchaseOrder(purchaseOrderId, isActive));
  }

  handleSubmit = (purchaseOrderRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
    if (isEdtiMode) {
      store.dispatch(this.props.updatePurchaseOrder(purchaseOrderRequest));
    } else {
      store.dispatch(this.props.createPurchaseOrder(purchaseOrderRequest, isCreateAnotherEnabled, callbackFunction));
    }
  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: purchaseOrderConstants.CHANGE_FILTER_PURCHASE_ORDERS, filterOptions });
      });
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: purchaseOrderConstants.INITIALIZE_PURCHASE_ORDERS_USER_TEAMS, userTeams });
    this.reloadPurchaseOrderListService(userTeams)
  }

  handleSearchSubmit = (searchField, searchTerm) => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    let currentUserId = commonUtils.getCurrentUserId();
    let selectedUserTeamIds = commonUtils.getSelectedUserTeamIds(userTeams);
    store.dispatch(this.props.searchPurchaseOrderList({ userTeamIds: selectedUserTeamIds, currentUserId: currentUserId, projectSearchRequest: { searchField, searchTerm: searchTerm.trim() } }, this.state.filterOptions))
  }

  render() {
    return (
      <div className="purchaseOrderlist">

        <PageInnerHeader
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          handleUserTeamsSelection={this.handleUserTeamsSelection}
          showCreateMenu={this.state.permission.createEnabled}
          showGridListMenu={false}
          searchViewEnabled={true}
          searchViewOpitons={{
            "All": "All"
          }}
          defaultSearchViewOpiton={"All"}
          handleSearchSubmit={this.handleSearchSubmit}
          showFilter={true}
          showUserTeamMenu={true}
          isLoading={this.props.isLoading}
          filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
          handleFilterChange={this.handleFilterChange}
          onFilterTextChange={this.onFilterTextChange}
          showCreateMenuText="CREATE PURCHASE ORDER"
          createItemHandler={this.onCreateItemClick}
          showDateRangeSelector={true}
          handleDateRangeChange={this.handleDateRangeChange}
          permission={this.state.permission}
          additionalButtons={this.state.isSalesForceEnabled === true ?
            <button type="button" className="link pr-2 float-right" title="Import from Salesforce" onClick={() => this.importPurchaseOrderList()}>
              <i className="fa fa-cloud-download colorblue fa-lg"></i>
            </button> : <></>
          }
        >
        </PageInnerHeader>
        {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
          <>

            <div className="section-body mt-1">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content">

                      <PurchaseOrderListTable
                        items={this.props.items}
                        isLoading={this.props.isLoading}
                        handleDeletePurchaseOrder={this.handleDeletePurchaseOrder}
                        handleEditPurchaseOrder={this.handleEditPurchaseOrder}
                        handleUpdateStatus={this.handleUpdateStatus}
                        handleDuplicatePurchaseOrder={this.handleDuplicatePurchaseOrder}
                        onViewPurchaseOrder={this.onViewPurchaseOrder}
                        handleArchivePurchaseOrder={this.handleArchivePurchaseOrder}
                        handleRemovePurchaseOrder={this.handleRemovePurchaseOrder}
                        reloadPurchaseOrderList={this.invokePurchaseOrderListService}
                        permission={this.state.permission} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        <ItemNotFoundSection items={this.props.items} isLoading={this.props.isLoading} imgurl="nopurchaseorders-min.PNG" text="No PurchaseOrders found ?" description="Try Creating a PurchaseOrder from scratch..." />
        <CreatePurchaseOrderSidebar
          show={this.props.showCreateModel}
          closeActionHandler={this.onCloseCreateItem}
          editMode={this.state.editMode}
          duplicateMode={this.state.duplicateMode}
          selectedPurchaseOrderInfo={this.props.selectedPurchaseOrderInfo}
          handleSubmit={this.handleSubmit}
          permission={this.state.permission}
        />
        <ViewPurchaseOrderDetailsSidebar
          show={this.props.isShowPurchaseOrderViewSidebar}
          onCloseViewPurchaseOrder={this.onCloseViewPurchaseOrder} />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication,
  isLoading: state.purchaseOrders.loading,
  userTeams: commonUtils.getUserTeams(state.purchaseOrders.userTeams),
  items: commonUtils.filterPurchaseOrders(state.purchaseOrders.items, state.purchaseOrders.filterOptions),
  filterOptions: state.purchaseOrders.filterOptions,
  selectedPurchaseOrderInfo: state.purchaseOrders.selectedPurchaseOrderInfo,
  showCreateModel: state.purchaseOrders.showCreateModel,
  isShowPurchaseOrderViewSidebar: state.purchaseOrders.isShowPurchaseOrderViewSidebar
});
const mapDispatchToProps = dispatch => ({
  getPurchaseOrderInfo: purchaseOrderActions.getPurchaseOrderInfo,
  getPurchaseOrderList: purchaseOrderActions.getPurchaseOrderList,
  importAndGetPurchaseOrderList: purchaseOrderActions.importAndGetPurchaseOrderList,
  searchPurchaseOrderList: purchaseOrderActions.searchPurchaseOrderList,
  createPurchaseOrder: purchaseOrderActions.createPurchaseOrder,
  updatePurchaseOrder: purchaseOrderActions.updatePurchaseOrder,
  updatePurchaseOrderStatus: purchaseOrderActions.updatePurchaseOrderStatus,
  deletePurchaseOrder: purchaseOrderActions.deletePurchaseOrder,
  removePurchaseOrder: purchaseOrderActions.removePurchaseOrder,
  archivePurchaseOrder: purchaseOrderActions.archivePurchaseOrder,
  initializeCreatePurchaseOrder: purchaseOrderActions.initializeCreatePurchaseOrder
});
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderListPage);