import React, { Component } from "react";
import Joyride, { CallBackProps, STATUS, Step, StoreHelpers } from 'react-joyride';

class PageHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      run: false,
      steps: [
        {
          content: <h2>Let's begin our journey!</h2>,
          locale: { skip: <strong aria-label="skip">SKIP</strong> },
          placement: 'center',
          target: 'body',
        },
        {
          content: <h2>Grid view</h2>,
          floaterProps: {
            disableAnimation: true,
          },
          spotlightPadding: 20,
          target: '.grid-view-link',
        },
        {
          content: <h2>List View</h2>,
          floaterProps: {
            disableAnimation: true,
          },
          spotlightPadding: 20,
          target: '.list-view-link',
        },
        
        {
          content: 'Create new projects!',
          placement: 'bottom',
          styles: {
            options: {
              width: 300,
            },
          },
          target: '.create-link',
          title: 'Create projects',
        }
      ],
    };
  }
    componentDidMount() {
      
    }
     getHelpers = (helpers) => {
      this.helpers = helpers;
    };
  
     handleClickStart = (e) => {
      e.preventDefault();
  
      this.setState({
       // run: true,
      });
    };
  
     handleJoyrideCallback = (data) => {
      const { status, type } = data;
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
  
      if (finishedStatuses.includes(status)) {
        this.setState({ run: false });
      }
  
      // tslint:disable:no-console
      console.groupCollapsed(type);
      //console.log(data);
      console.groupEnd();
      // tslint:enable:no-console
    };
    render() {
        return (
          <>
          <Joyride
          callback={this.handleJoyrideCallback}
          continuous={true}
          getHelpers={this.getHelpers}
          run={this.state.run}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={this.state.steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
            <i className="fa fa-3x fa-question-circle" onClick={(e) => {this.handleClickStart(e);}}></i>
        </>
        );
    }
}

export default PageHelp;