import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { workItemConstants } from "_constants";
import { accessUtils } from "_helpers";
import { store } from "_helpers";
import EmployeeImage from "./fragments/EmployeeImage";
import classNames from "classnames/bind";
import { employeeConstants } from "_constants";

class PageLeftMenubar extends Component {
  constructor() {
    super()
    this.state = {
      permission: accessUtils.getMenuAccessControl()
    };
  }
  toggleTodayActivitySidebar = () => {
    store.dispatch({ type: workItemConstants.SHOW_TODAYS_ACTIVITY_WINDOW })
  }
  toggleUserDivSidebar = () => {
    store.dispatch({ type: employeeConstants.TOGGLE_USER_DIV_WINDOW })
  }
  isDisabled = (module, linkName) => {
    return this.state.permission && this.state.permission[module] && this.state.permission[module][linkName] === false;
  }
  render() {
    return (
      <div id="header_top" className="header_top no-print">
        <div className="container">
          <div className="hleft">
            {this.isDisabled("projectModules", "dashboardEnabled") &&
              <NavLink to={"/project/dashboard"} className="header-brand">
                <img className="iconrotate" src="/favicon.ico" style={{ width: 30 + 'px' }}></img>
                <img alt="famstack" src="/assets/images/custom/c5i-logo.png" style={{ width: 45 + 'px', display: 'block' }}></img>
              </NavLink>
            }
            {!this.isDisabled("projectModules", "dashboardEnabled") &&
              <button className="link header-brand">
                <img className="iconrotate" src="/favicon.ico" style={{ width: 30 + 'px' }}></img>
                <img alt="famstack" src="/assets/images/custom/c5i-logo.png" style={{ width: 45 + 'px', display: 'block' }}></img>
              </button>
            }
           
            <div className="dropdown">
              <NavLink to={"/project/search"} className={classNames("nav-link icon", { "disabled colorlightgray": this.isDisabled("projectModules", "searchEnabled") })} activeClassName="active"> <i className="fa fa-search" /></NavLink>
              <NavLink to={"/project/taskboard"} className={classNames("nav-link icon", { "disabled colorlightgray": this.isDisabled("projectModules", "taskboardEnabled") })} activeClassName="active"> <i className="fa fa-tasks" /></NavLink>
              <NavLink to={"/project/calendar"} className={classNames("nav-link icon", { "disabled colorlightgray": this.isDisabled("projectModules", "calendarEnabled") })} activeClassName="active"> <i className="fa fa-calendar" /></NavLink>
              <NavLink to={"/project/chat"} className={classNames("nav-link icon disabled", { "disabled colorlightgray": this.isDisabled("projectModules", "chatEnabled") })} activeClassName="active"> <i className="fa fa-comments-o" /></NavLink>
              <NavLink to={"/project/filemanager"} className={classNames("nav-link icon disabled", { "disabled colorlightgray": this.isDisabled("projectModules", "filemanagerEnabled") })} activeClassName="active"> <i className="fa fa-folder-o" /></NavLink>

            </div>
          </div>
          <div className="hright">
            <div className="dropdown">
              <button className="link nav-link icon theme_btn"><i className="fa fa-paint-brush" data-toggle="tooltip" data-placement="right" title="Themes" /></button>
              <NavLink to={"/hrms/appconfig"} className={classNames("nav-link icon", { "disabled colorlightgray": this.isDisabled("hrmsModules", "appconfigEnabled") })} activeClassName="active"> <i className="fa fa-cogs" /></NavLink>
              <button className="link nav-link icon settingbar"><i className="fa fa-gear fa-spin" data-toggle="tooltip" data-placement="right" title="Settings" /></button>
              <button className="link nav-link iconfaq_btn todayactivity" onClick={() => this.toggleTodayActivitySidebar()}><i className="fa fa-stack-exchange"></i></button>

              <button className="link nav-link user_btn" onClick={() => this.toggleUserDivSidebar()}>
                <EmployeeImage employee={this.props.authentication && this.props.authentication.user} classes={"avatar"} />
              </button>
              <button className="link nav-link icon menu_toggle"><i className="fa fa-align-left" /></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageLeftMenubar;
