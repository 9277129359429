import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { employees } from './employees.reducer';
import { projects } from './projects.reducer';
import { accounts } from './accounts.reducer';
import { tasks } from './tasks.reducer';
import { workItem } from './workitem.reducer';
import { notification } from './notification.reducer';
import { appconfig } from './appconfig.reducer';
import { reports } from './reports.reducer';
import { schedules } from './schedules.reducer';
import { itemAdditional } from './item.additional.reducer';
import { purchaseOrders } from './purchaseorder.reducer';
import { bidRequests } from './bid.requests.reducer';
import { taskBoard } from './taskboard.reducer';
import { dashboard } from './dashboard.reducer';
import { checklist } from './checklist.reducer';
import { invoice } from './invoice.reducer';
import { userConstants } from '_constants';

const appReducer = combineReducers({
  authentication,
  employees,
  projects,
  accounts,
  tasks,
  workItem,
  notification,
  appconfig,
  reports,
  schedules,
  itemAdditional,
  purchaseOrders,
  bidRequests,
  taskBoard,
  dashboard,
  checklist,
  invoice
})

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;