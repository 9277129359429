import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import FileDropZone from "views/common/plugins/FileDropZone";
import SidebarFooter from "views/project/components/modal/SidebarFooter";
import { bidRequestsActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import { validationUtils } from '_helpers'
class CreateBidRequestsSidebarSection extends Component {

    constructor() {
        super();
        this.state = {
            formErrors: {},
            hasFormError: false
        }
    }

    componentDidMount() {
        this.initializeRequest();
    }

    initializeRequest = () => {
        let userTeamId = commonUtils.getCurrentUserTeamId();
        let bidRequest = this.props.editMode ? this.props.selectedBidRequestsInfo : this.props.duplicateMode ? {
            ...this.props.selectedBidRequestsInfo,
            id: null,
            createdBy: commonUtils.getCurrentUserId(),
            modifiedBy: null,
        } :
            {
                userTeamId: userTeamId,
                countriesData: {},
                studyTypesData: {},
                typeData: {},
                quotaInformation: {},
                additionalQuestions: {},
                screeningQuestions: {}
            }
        this.setState({
            bidRequest: bidRequest
        });
    }


    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }

    onResetFormError = () => {
        this.setState({
            hasFormError: false
        })
    }

    onChange = (fieldName, value) => {
        this.onResetFormError()
        if (fieldName === 'userTeamId') {
            store.dispatch(this.props.getModuleConfig(value));
        }
        this.setState(prevState => ({
            bidRequest: {
                ...prevState.bidRequest,
                [fieldName]: value
            }
        }), () => {

        })
    }

    onTypeDataChange = (fieldName, value) => {
        this.onChange("typeData", {
            ...this.state.bidRequest.typeData,
            [fieldName]: value
        })
    }


    handleSubmit = (isCreateAnotherEnabled) => {
        let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.bidRequest, this.state.formErrors);
        //console.log("Validating form  request, isValid - " + isValid);
        if (isValid) {
            const defaultNotificationEmails = commonUtils.getAppConfigValue(this.props.createConfig, "defaultNotificationEmails")
            let bidRequestToBeSaved = {
                ...this.state.bidRequest,
                watchers:[...(this.state.bidRequest.watchers || (defaultNotificationEmails ? defaultNotificationEmails : []))]
            }
            this.props.handleSubmit(bidRequestToBeSaved, this.props.editMode, isCreateAnotherEnabled, !this.props.editMode ? this.initializeRequest : null);
        } else {
            this.setState({
                hasFormError: true
            })
        }
    }

    render() {
        let selectedKindOfStudies = (this.state.bidRequest && this.state.bidRequest.kindOfStudies && this.state.bidRequest.kindOfStudies.filter(ks => !ks.startsWith('Others') && !ks.startsWith('Offline Qualitative'))) || [];
        if (this.state.bidRequest && this.state.bidRequest.specifykindOfStudy) {
            selectedKindOfStudies.push(this.state.bidRequest.specifykindOfStudy)
        }
        return (
            <>
                {this.state.bidRequest &&
                    <>
                        <div className={`card-body px-0 ${!this.props.readOnly ? "overflow-auto px-3" : 'px-0 '}`}>
                            <div className="row">
                                <div className="card pb-0 mb-0">
                                    <div className="card-header pt-0 pb-1 d-none">
                                        <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Basic details</span>
                                        <div className="card-options">
                                            <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-12 row">
                                        <div className="col-12 col-lg-6">
                                            <div className="row">
                                                <div className="col-12">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Mode and kind of study"
                                                        name="kindOfStudies"
                                                        configName={"kindOfStudy"}
                                                        createConfig={this.props.createConfig}
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        isAppConfig={true}
                                                        datatype='alpnumspace'
                                                        multi={true}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.bidRequest.kindOfStudies && this.state.bidRequest.kindOfStudies.find(ks => ks.startsWith('Others') || ks.startsWith('Offline Qualitative')) &&
                                                <div className="row bgcolorgray border">
                                                    <div className="col-12">
                                                        <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                            label="Specify Kind of study"
                                                            name="specifyKindOfStudy"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.bidRequest}
                                                            onChange={this.onChange}
                                                            onFormError={this.onFormError}
                                                            placeholder="Specify Kind of study..."
                                                            datatype='alpnumspl' />
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="Study Name"
                                                        name="studyName"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Study Name..."
                                                        datatype='alpnumspl' />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Client Name"
                                                        name="clientName"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Client Name..."
                                                        //options={this.props.allBidRequestsTeamAccounts && this.state.bidRequest && commonUtils.getClientsOptions(this.props.allBidRequestsTeamAccounts, this.state.bidRequest.accountName, this.state.bidRequest.teamName)}
                                                        isAppConfig={true}
                                                        createConfig={this.props.createConfig}
                                                        onFormError={this.onFormError}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="Number of OEs"
                                                        name="numberOfOes"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Number of OEs..."
                                                        datatype='num' />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="What is the proposed LOI"
                                                        name="proposedLoi"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="What is the proposed LOI..."
                                                        datatype='alpnumspace' />
                                                </div>
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly} type='radios'
                                                        label="Is there a target CPI in mind"
                                                        name="targetCPI"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Is there a target CPI in mind."
                                                    />

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="What is the expected IR for the study"
                                                        name="expectedIR"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="What is the expected IR for the study..."
                                                        datatype='alpnumspace' />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Which countries will this run in and sample size per wave per country?"
                                                        name="countries"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Countries..."
                                                        isAppConfig={true}
                                                        createConfig={this.props.createConfig}
                                                        onFormError={this.onFormError}
                                                        multi={true}
                                                    />
                                                </div>
                                            </div>

                                            {this.state.bidRequest.kindOfStudies && this.state.bidRequest.countries &&
                                                <div className="row border bgcolorgray">
                                                    <CountriesData
                                                        name="countriesData"
                                                        readOnly={this.props.readOnly}
                                                        onChange={this.onChange}
                                                        countriesData={this.state.bidRequest.countriesData}
                                                        selectedKindOfStudies={selectedKindOfStudies}
                                                        selectedCountries={this.state.bidRequest.countries}
                                                        onFormError={this.onFormError}
                                                    />
                                                </div>
                                            }

                                            <div className="row">
                                                <div className="col-6">
                                                    <CustomInput
                                                        readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Owner User Team"
                                                        name="userTeamId"
                                                        readOnlyValue={this.state.bidRequest.ownerUserTeamDetails && this.state.bidRequest.ownerUserTeamDetails.name}
                                                        isIdAsSelectValue={false}
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Owner User Team..."
                                                        options={commonUtils.getUserTeamsFromUserItem() && commonUtils.convertObjectToSelectObject(commonUtils.getUserTeamsFromUserItem())}
                                                        //options={this.props.allTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allTeams)}
                                                        onFormError={this.onFormError}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Bid Requests Owner"
                                                        name="bidRequestOwner"
                                                        readOnlyValue={this.state.bidRequest.ownerDetails && this.state.bidRequest.ownerDetails.name}
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Bid Request Owner..."
                                                        options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                                                        onFormError={this.onFormError}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                            
                                                <div className="col-12">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Tag Employees"
                                                        name="tagEmployees"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Tag Employees..."
                                                        options={this.props.allEmployees && commonUtils.convertEmployeeEmailToSelectObject(this.props.allEmployees)}
                                                        onFormError={this.onFormError}
                                                        multi={true}
                                                    />
                                                </div>
                                            </div>


                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="Sales Force Id"
                                                        name="salesForceId"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Sales Force Id..."
                                                        datatype='alpnumspace' />
                                                </div>
                                                <div className="col-6">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="Study Type"
                                                        name="type"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Study Type..."
                                                        options={[{ value: 'ADHOC', label: 'Adhoc' }, { value: 'FTE', label: 'FTE' }, { value: 'TRACKER', label: 'Tracker' }]}
                                                        onFormError={this.onFormError}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.bidRequest.type && this.state.bidRequest.type === 'TRACKER' &&
                                                <div className="row bgcolorgray border ml-1">
                                                    <div className="col-4">
                                                        <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                            label="Number of waves"
                                                            name="numberOfWaves"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.bidRequest.typeData}
                                                            onChange={this.onTypeDataChange}
                                                            onFormError={this.onFormError}
                                                            placeholder="Number of waves..."
                                                            datatype='num' />
                                                    </div>
                                                    <div className="col-4 ">
                                                        <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                            label="Frequency "
                                                            name="frequency"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.bidRequest.typeData}
                                                            onChange={this.onTypeDataChange}
                                                            onFormError={this.onFormError}
                                                            placeholder="Frequency..."
                                                            datatype='num' />
                                                    </div>
                                                    <div className="col-4 ">
                                                        <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                            label="Exclusion period "
                                                            name="exclusionPeriod"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.bidRequest.typeData}
                                                            onChange={this.onTypeDataChange}
                                                            onFormError={this.onFormError}
                                                            placeholder="Exclusion period..."
                                                            datatype='num' />
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-6 ">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="How much field time will be available "
                                                        name="fieldTime"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="How much field time will be available..."
                                                        datatype='num' />
                                                </div>
                                                <div className="col-6 ">
                                                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                                                        label="Tentative FW launch time"
                                                        name="launchTime"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Tentative FW launch time..."
                                                        datatype='num' />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='textarea'
                                                        label="Study objective"
                                                        name="objective"
                                                        rows={4}
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Study objective..."
                                                        onFormError={this.onFormError}
                                                        datatype='alpnumspl'
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <CustomInput readOnly={this.props.readOnly}
                                                        type='select'
                                                        label="What is the TG for the study with TG description "
                                                        name="studyTypes"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.bidRequest}
                                                        onChange={this.onChange}
                                                        placeholder="What is the TG for the study..."
                                                        options={this.props.studyTypeConfig}
                                                        onFormError={this.onFormError}
                                                        multi={true}
                                                    />
                                                </div>
                                            </div>


                                            {this.state.bidRequest.studyTypes &&
                                                <div className="border ml-1">
                                                    <StudyTypeData
                                                        name={"studyTypesData"}
                                                        readOnly={this.props.readOnly}
                                                        studyTypesData={this.state.bidRequest.studyTypesData}
                                                        selectedStudyTypes={this.props.studyTypeConfig.filter(studyTypeItem => this.state.bidRequest.studyTypes.includes(studyTypeItem.value))}
                                                        onChange={this.onChange}
                                                        fieldConfig={this.props.fieldConfig}
                                                        onFormError={this.onFormError}
                                                        createConfig={this.props.createConfig}
                                                    />
                                                </div>
                                            }


                                        </div>
                                    </div>
                                </div>

                                <Questions
                                    header={"Screening Questions"}
                                    name="screeningQuestions"
                                    readOnly={this.props.readOnly}
                                    questionData={this.state.bidRequest.screeningQuestions}
                                    questionsConfig={this.props.screeningQuestions}
                                    onChange={this.onChange}
                                />

                                <Questions
                                    header={"Additional Questions"}
                                    name="additionalQuestions"
                                    readOnly={this.props.readOnly}
                                    questionData={this.state.bidRequest.additionalQuestions}
                                    questionsConfig={this.props.additionalQuestions}
                                    onChange={this.onChange}
                                />
                                <Questions
                                    header={"Quota Information"}
                                    name="quotaInformation"
                                    readOnly={this.props.readOnly}
                                    questionData={this.state.bidRequest.quotaInformation}
                                    questionsConfig={this.props.quotaInformation}
                                    onChange={this.onChange}
                                />

                            </div>

                        </div>
                        {!this.props.readOnly &&
                            <SidebarFooter
                                handleSubmit={this.handleSubmit}
                                editMode={this.props.editMode}
                                duplicateMode={this.props.duplicateMode}
                                initializeRequest={this.initializeRequest}
                                isProcessing={this.props.isProcessing}
                                hasFormError={this.state.hasFormError}
                                onResetFormError={this.onResetFormError}
                            />
                        }
                    </>
                }
            </>
        );
    }
}

class CountriesData extends Component {
    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.name, {
            ...this.props.countriesData,
            [fieldName]: newValue
        })
    }
    render() {
        return (
            <>
                {this.props.selectedCountries.map(selectedCountry =>
                    <CountriesDataSection
                        readOnly={this.props.readOnly}
                        key={selectedCountry}
                        countriesData={this.props.countriesData}
                        selectedCountry={selectedCountry}
                        selectedKindOfStudies={this.props.selectedKindOfStudies}
                        onChange={this.onChange}
                        fieldConfig={this.props.fieldConfig}
                        onFormError={this.props.onFormError}
                        createConfig={this.props.createConfig}
                    />
                )
                }
            </>
        )
    }
}


class CountriesDataSection extends Component {

    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.selectedCountry, {
            ...this.props.countriesData[this.props.selectedCountry] || {},
            [fieldName]: newValue
        })
    }
    render() {
        return (
            <>
                <div className="col-3">
                    <span className="d-block mt-3">{this.props.selectedCountry}</span>
                </div>
                <div className="col-6">

                    <CustomInput readOnly={this.props.readOnly}
                        type='select'
                        label="Select audience "
                        name="audience"
                        fieldConfig={this.props.fieldConfig}
                        mappingObject={this.props.countriesData[this.props.selectedCountry] || {}}
                        onChange={this.onChange}
                        placeholder="Select audience..."
                        options={commonUtils.convertConfigListToSelectObject(this.props.selectedKindOfStudies)}
                        onFormError={this.props.onFormError}
                    />
                </div>

                <div className="col-3">
                    <CustomInput readOnly={this.props.readOnly} inputtype='text'
                        label="Sample size "
                        name="sampleSize"
                        fieldConfig={this.props.fieldConfig}
                        mappingObject={this.props.countriesData[this.props.selectedCountry] || {}}
                        onChange={this.onChange}
                        onFormError={this.props.onFormError}
                        placeholder="Sample size..."
                        datatype='num' />
                </div>

            </>
        )
    }
}

class StudyTypeData extends Component {

    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.name, {
            ...this.props.studyTypesData,
            [fieldName]: newValue
        })
    }
    render() {
        return (
            <>
                {this.props.selectedStudyTypes.map(selectedStudyType =>
                    <div className="row bgcolorgray" key={selectedStudyType.value}>
                        <StudyTypeSubSection
                            readOnly={this.props.readOnly}
                            key={selectedStudyType.value}
                            name={selectedStudyType.value}
                            studyTypesData={this.props.studyTypesData}
                            selectedStudyType={selectedStudyType}
                            onChange={this.onChange}
                            fieldConfig={this.props.fieldConfig}
                            onFormError={this.props.onFormError}
                            createConfig={this.props.createConfig}
                        />
                    </div>
                )
                }
            </>
        )
    }
}


class StudyTypeSubSection extends Component {
    onChange = (fieldName, newValue) => {
        this.props.onChange(this.props.selectedStudyType.value, {
            ...this.props.studyTypesData[this.props.selectedStudyType.value] || {},
            [fieldName]: newValue
        })
    }
    render() {
        return (
            <>
                <div className="col-12 bgcolorlightgray">
                    <span>{this.props.selectedStudyType.label}</span>
                </div>
                {this.props.selectedStudyType.subProperties && this.props.selectedStudyType.subProperties.map(subProperty =>
                    <div className="col-6" key={subProperty.name}>
                        <CustomInput readOnly={this.props.readOnly}
                            inputtype={subProperty.dataType === 'number' ? "number" : subProperty.dataType === 'string' ? 'text' : undefined}
                            dataType={subProperty.dataType === 'number' ? "num" : subProperty.dataType === 'string' ? 'alpnumspace' : undefined}
                            type={subProperty.dataType === 'select' ? 'select' : subProperty.dataType === 'decision' ? 'radios' : "text"}
                            label={subProperty.label}
                            name={subProperty.name}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={(this.props.studyTypesData && this.props.studyTypesData[this.props.selectedStudyType.value]) || {}}
                            onChange={this.onChange}
                            onFormError={this.props.onFormError}
                            placeholder={subProperty.label}
                            isAppConfig={subProperty.isAppConfig}
                            multi={subProperty.multiSelect || false}
                            createConfig={subProperty.dataType === 'select' ? this.props.createConfig : undefined}
                        />
                    </div>
                )
                }
            </>
        )
    }
}

class Questions extends Component {
    constructor() {
        super();
        this.state = {
            expand: true,
            isFileUploadEnabled: false,
            isTextEditorEnabled: false,
            isQuestionaireEnabled: true
        }
    }

    componentDidMount() {
        this.setState({
            isFileUploadEnabled: this.props.questionData && this.props.questionData.questionnaireList && this.props.questionData.questionnaireList.find(qd => qd.type === 'file'),
            isTextEditorEnabled: this.props.questionData && this.props.questionData.questionnaireList && this.props.questionData.questionnaireList.find(qd => qd.type === 'html'),
            isQuestionaireEnabled: this.props.questionData && this.props.questionData.questionnaireList && this.props.questionData.questionnaireList.some(qd => qd.type === 'questions')
        })
    }
    toggleExpand = (expand) => {
        this.setState({
            expand: expand
        })
    }

    toggleFileupload = (isFileUpload) => {
        this.setState({
            isFileUploadEnabled: isFileUpload,
            isTextEditorEnabled: false,
            isQuestionaireEnabled: false
        })
    }

    toggleTextEditor = (isTextEditor) => {
        this.setState({
            isTextEditorEnabled: isTextEditor,
            isFileUploadEnabled: false,
            isQuestionaireEnabled: false
        })
    }
    toggleQuestioner = (isQuestionaireEnabled) => {
        this.setState({
            isQuestionaireEnabled: isQuestionaireEnabled,
            isFileUploadEnabled: false,
            isTextEditorEnabled: false
        })
    }

    onTextEditorChange = (value) => {
        this.onChange({
            type: "html",
            html: value
        });
    }

    onFileUploadChange = (value) => {
        this.onChange({
            type: "file",
            fileIds: [value]
        });
    }

    onChange = (value) => {
        this.props.onChange(this.props.name, {
            "questionnaireList": [
                value
            ]
        });
    }

    getQuestions = () => {
        const questionsType = this.props.questionData && this.props.questionData.questionnaireList && this.props.questionData.questionnaireList.find(qd => qd.type === 'questions');
        return (questionsType && questionsType.questions) || this.props.questionsConfig;
    }

    onQuestionChange = (questionId, e) => {
        e.persist();
        const { name, value, type, checked } = e.target;
        let newAnswer = (type === 'checkbox' ? checked : (type === 'radio' ? (value === 'true' ? true : false) : value));
        let updatedQuestions = this.getQuestions().map(question => {
            if (question.id === questionId) {
                const { answer, ...questionCopy } = question;
                return { ...questionCopy, answer: newAnswer };
            }
            return question;
        })
        this.onChange({
            type: "questions",
            questions: updatedQuestions
        });
    }
    render() {
        const htmlValueType = this.props.questionData && this.props.questionData.questionnaireList && this.props.questionData.questionnaireList.find(qd => qd.type === 'html')
        const fileNamesType = this.props.questionData && this.props.questionData.questionnaireList && this.props.questionData.questionnaireList.find(qd => qd.type === 'file')

        const questions = this.getQuestions();
        const htmlValue = (htmlValueType && htmlValueType.html) || ""
        const fileNames = (fileNamesType && fileNamesType.fileNames) || {}

        return (<div className="card pb-0 mb-0 pt-1 mt-3">
            <div className="card-header pt-0 pb-1 bgcolorlightgray">
                <span className="fsize14 fweight600"><i className="fa fa-info-circle pr-1"></i>{this.props.header}</span>
                <div className="card-options">
                    {this.state.expand && !this.props.readOnly &&
                        <>
                            <button className={`link mx-2 ${this.state.isFileUploadEnabled ? "colorblue" : ""}`} onClick={() => this.toggleFileupload(!this.state.isFileUploadEnabled)}><i className="fa fa-upload"></i></button>
                            <button className={`link mx-2 ${this.state.isTextEditorEnabled ? "colorblue" : ""}`} onClick={() => this.toggleTextEditor(!this.state.isTextEditorEnabled)}><i className="fa fa-edit"></i></button>
                            <button className={`link mx-2 ${this.state.isQuestionaireEnabled ? "colorblue" : ""}`} onClick={() => this.toggleQuestioner(!this.state.isQuestionaireEnabled)}><i className="fa fa-question"></i></button>
                        </>
                    }
                    <button className="card-options-collapse link" data-toggle="card-collapse" onClick={() => this.toggleExpand(!this.state.expand)}><i className={`fe fe-chevron-${this.state.expand ? "up" : "down"}`}></i></button>
                </div>
            </div>
            {this.state.expand &&
                <div className="col-12 row">

                    {this.state.isTextEditorEnabled &&
                        <div className="col-12 height220">
                            {this.props.readOnly &&
                                <>
                                    {htmlValue || ''}
                                </>
                            }
                            {!this.props.readOnly &&
                                <textarea
                                    style={{ width: "100%" }}
                                    rows={5}
                                    value={htmlValue}
                                    onChange={(e) => this.onTextEditorChange(e.target.value)} />
                            }
                        </div>
                    }

                    {this.state.isFileUploadEnabled &&
                        <FileDropZone
                            selectedFiles={null}
                        />
                    }
                    {this.state.isQuestionaireEnabled &&
                        <div className="col-12 bgcolorgray ml-1">

                            {questions && questions.map(question =>
                                <div key={question.id} className="d-flex justify-content-between align-items-center mt-0 pb-2 px-3">
                                    <div className="bid-question-div">
                                        <span className="bid-question fa">{question.name}</span>
                                    </div>
                                    {this.props.readOnly &&
                                        <>
                                            {question.type === 'boolean' ? (question.answer === "false" ? 'No' : 'Yes') : question.answer || 'NA'}
                                        </>
                                    }
                                    {!this.props.readOnly &&
                                        <>
                                            {question.type === 'string' &&
                                                <div>

                                                    <input type="text" className="form-control input-sm filter"
                                                        value={question.answer || ''}
                                                        onChange={(event) => this.onQuestionChange(question.id, event)}
                                                    />
                                                </div>
                                            }
                                            {question.type === 'number' &&
                                                <div>
                                                    <input type="number" className="form-control input-sm filter"
                                                        value={question.answer || ''}
                                                        onChange={(event) => this.onQuestionChange(question.id, event)}
                                                    />
                                                </div>
                                            }
                                            {question.type === 'boolean' &&
                                                <div className="custom-controls-stacked mt-2">
                                                    <label className="custom-control custom-radio custom-control-inline">
                                                        <input
                                                            type="radio"
                                                            className={`custom-control-input`}
                                                            name={this.props.name}
                                                            value={true}
                                                            checked={question.answer !== undefined && question.answer === true ? true : false}
                                                            onChange={(event) => this.onQuestionChange(question.id, event)}
                                                        />
                                                        <div className="custom-control-label">Yes</div>
                                                    </label>
                                                    <label className="custom-control custom-radio custom-control-inline">
                                                        <input
                                                            type="radio"
                                                            className={`custom-control-input`}
                                                            name={this.props.name}
                                                            value={false}
                                                            checked={question.answer !== undefined && question.answer === false ? true : false}
                                                            onChange={(event) => this.onQuestionChange(question.id, event)}
                                                        />
                                                        <div className="custom-control-label">No</div>
                                                    </label>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    }
                </div>
            }
        </div>)
    }
}

const mapStateToProps = state => ({
    currentUser: state.authentication.user,
    fieldConfig: state.bidRequests.fieldConfig,
    createConfig: state.bidRequests.createConfig,
    allEmployees: state.employees.allEmployees,
    creating: state.bidRequests.creating,
    updating: state.bidRequests.updating,
    screeningQuestions: state.bidRequests.screeningQuestions,
    additionalQuestions: state.bidRequests.additionalQuestions,
    quotaInformation: state.bidRequests.quotaInformation,
    studyTypeConfig: state.bidRequests.studyTypeConfig,
});

const mapDispatchToProps = dispatch => ({
    getModuleConfig: bidRequestsActions.getModuleConfig
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBidRequestsSidebarSection);
