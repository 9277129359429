import React, { Component } from "react";

class EmployeeImage extends Component {
    render() {
        return (
            <img className={this.props.classes} src={`/assets/images/${this.props.employee && (this.props.employee.gender === 'female' || this.props.employee.gender === 'Female') ? 'female.png' : 'male.jpg'}`} alt="" />
        );
    }
}

export default EmployeeImage;
