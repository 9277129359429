export const invoiceConstants = {

    GETALL_INVOICES_REQUEST: 'GETALL_INVOICES_REQUEST',
    GETALL_INVOICES_SUCCESS: 'GETALL_INVOICES_SUCCESS',
    GETALL_INVOICES_FAILURE: 'GETALL_INVOICES_FAILURE',

    GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
    GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
    GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

    SHOW_INVOICE_CREATE_WINDOW: "SHOW_INVOICE_CREATE_WINDOW",
    CLOSE_INVOICE_CREATE_WINDOW: "CLOSE_INVOICE_CREATE_WINDOW",

    CREATE_INVOICE_REQUEST: 'CREATE_INVOICE_REQUEST',
    CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
    CREATE_INVOICE_FAILURE: 'CREATE_INVOICE_FAILURE',

    UPDATE_INVOICE_REQUEST: 'UPDATE_INVOICE_REQUEST',
    UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
    UPDATE_INVOICE_FAILURE: 'UPDATE_INVOICE_FAILURE',

    REMOVE_INVOICE_REQUEST: 'REMOVE_INVOICE_REQUEST',
    REMOVE_INVOICE_SUCCESS: 'REMOVE_INVOICE_SUCCESS',
    REMOVE_INVOICE_FAILURE: 'REMOVE_INVOICE_FAILURE'
};