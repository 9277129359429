import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const bidRequestsService = {
    createBidRequests,
    updateBidRequests,
    updateBidRequestsStatus,
    removeBidRequests,
    archiveBidRequests,
    deleteBidRequests,
    getBidRequestsList,
    getBidRequestsInfo
};

async function getBidRequestsList(request) {
    let params = request.startDate ? `&employeeIds=${request.currentUserId}&startDate=${request.startDate}&endDate=${request.endDate}` : '';
    return await axiosUtils.get(urlConfig.BID_REQUEST_PATH + `?userTeamIds=${request.userTeamIds}` + params);
 }

async function getBidRequestsInfo(bidRequestsId) {
   return await axiosUtils.get(urlConfig.BID_REQUEST_PATH + `/${bidRequestsId}`);
}

async function createBidRequests(BidRequest) {
    return await axiosUtils.post(urlConfig.BID_REQUEST_PATH,BidRequest);
}

async function updateBidRequests(BidRequest) {
    return await axiosUtils.put(urlConfig.BID_REQUEST_PATH,BidRequest);
}

async function updateBidRequestsStatus(bidRequestsId, status) {
    return await axiosUtils.put(urlConfig.BID_REQUEST_PATH + `/status/${bidRequestsId}/${status}`);
 }

async function deleteBidRequests(bidRequestsId, isDelete) {
   return await axiosUtils.remove(urlConfig.BID_REQUEST_PATH+`/${bidRequestsId}/${isDelete}`);
}

async function removeBidRequests(bidRequestsId) {
    return await axiosUtils.remove(urlConfig.BID_REQUEST_PATH+`/${bidRequestsId}`);
 }

 async function archiveBidRequests(bidRequestsId, isArchive) {
    return await axiosUtils.put(urlConfig.BID_REQUEST_PATH+`/${bidRequestsId}/${isArchive}`);
 }