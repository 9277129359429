export const accounts = [
    {
        "Microsoft": {
            "name": "Micro soft",
            "poc": "poc Name",
            "teams": [
                {
                    "name": "Micro soft Team",
                    "poc": "team poc Name",
                    "clients": [
                        {
                            "name": "client name",
                            "contact": "contact",
                            "email": "email"
                        }
                    ]
                }
            ]
        }
    },
    {
        "Intel": {
            "name": "Intel",
            "poc": "poc Name",
            "teams": [
                {
                    "name": "Intel Team",
                    "poc": "team poc Name",
                    "clients": [
                        {
                            "name": "client name",
                            "contact": "contact",
                            "email": "email"
                        }
                    ]
                }
            ]
        }
    }
]