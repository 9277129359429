
const dev = {
    BACK_END_API_ENDPOINT: "http://dev.famstack.com:8080",
    API_KEY: "sldjfldsjfo08324lkjsdf124lkj23425264ljsdfklsjfl972394374_dev",
    MS_CLIENT_ID: "06218c5c-ef09-411d-8907-432e34bd7213",
    MS_TENANT_ID: "a51f766e-fa31-4595-953b-86c64fb12099",
    MS_REDIRECT_URL: "http://localhost:3000/"
}

const prod = {
    BACK_END_API_ENDPOINT: "https://course5i.famstack.com",
    API_KEY: "sldjfldsjfo08324lkjsdf124lkj23425264ljsdfklsjfl972394374",
    MS_CLIENT_ID: "65cd9622-d82f-463f-b207-e0fa49981b5d",
    MS_TENANT_ID: "b5af2451-e21b-4aa2-b4b5-dc5907908dd8",
    MS_REDIRECT_URL: "https://course5i.famstack.com",
}
const config = process.env.NODE_ENV === 'development' ? dev : prod

if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
}

export default config