import { taskConstants } from '../_constants';
const taskFieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
  { name: 'taskOwner', mandatory: true, enabled: true },
  { name: 'serviceLine', mandatory: false, enabled: true },
  { name: 'taskCategory', mandatory: true, enabled: true },
  { name: 'estStartTime', mandatory: true, enabled: true },
  { name: 'estCompletionTime', mandatory: true, enabled: true },
  { name: 'estDurationHrs', mandatory: true, enabled: true }
];

export function tasks(state = {}, action) {

  switch (action.type) {

    case taskConstants.INITIALIZE_CREATE_TASK_REQUEST:
      return {
        ...state,
        fieldConfig: taskFieldConfig,
        createInitializing: true,
        showCreateModel: true,
        selectedTaskInfo: null
      };
    case taskConstants.INITIALIZE_CREATE_TASK_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        createConfig: action.configuration
      };
    case taskConstants.INITIALIZE_CREATE_TASK_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };

    case taskConstants.GETALL_TASKS_REQUEST:
      return {
        ...state,
        tasksLoading: true
      };
    case taskConstants.GETALL_TASKS_SUCCESS:
      return {
        ...state,
        tasksLoading: false,
        items: action.tasks
      };
    case taskConstants.GETALL_TASKS_FAILURE:
      return {
        ...state,
        tasksLoading: false,
        error: action.error
      };

    case taskConstants.GETALL_RECCURABLE_TASKS_REQUEST:
      return {
        ...state,
        recurrableTasksLoading: true
      };
    case taskConstants.GETALL_RECCURABLE_TASKS_SUCCESS:
      return {
        ...state,
        recurrableTasksLoading: false,
        recurrableTaskItems: action.tasks
      };
    case taskConstants.GETALL_RECCURABLE_TASKS_FAILURE:
      return {
        ...state,
        recurrableTasksLoading: false,
        error: action.error
      };

    case taskConstants.CREATE_TASK_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case taskConstants.CREATE_TASK_SUCCESS:
      state.items ? state.items.push(action.task) : state.items = [action.task];
      return {
        ...state,
        creating: false,
        showCreateModel: action.showModel,
      };
    case taskConstants.CREATE_TASK_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case taskConstants.UPDATE_TASK_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items && state.items.map(task =>
          task.id === action.taskId
            ? { ...task, updating: true }
            : task
        ),
        error: null
      };
    case taskConstants.UPDATE_TASK_SUCCESS:
      let newItems = null;
      if (state.items) {
        newItems = [...state.items];
        const index = state.items.findIndex(task => task.id === action.taskId);
        newItems[index] = { ...newItems[index], ...action.task }
      }
      const currentTaskInfo = state.selectedTaskInfo && action.task.id === state.selectedTaskInfo.id ? {...state.selectedTaskInfo, ...action.task}: state.selectedTaskInfo

      return {
        ...state,
        updating: false,
        items: newItems,
        showCreateModel: false,
        selectedTaskInfo: currentTaskInfo
      };
    case taskConstants.UPDATE_TASK_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items && state.items.map(task => {
          if (task.id === action.taskId) {
            const { updating, ...taskCopy } = task;
            return { ...taskCopy, deleteError: action.error };
          }
          return task;
        }),
        error: action.error
      };
    case taskConstants.DELETE_TASK_REQUEST:
      return {
        ...state,
        items: state.items.map(task =>
          task.id === action.taskId
            ? { ...task, deleting: true }
            : task
        )
      };
    case taskConstants.DELETE_TASK_SUCCESS:
      // remove deleted task from state
      return {
        ...state,
        items: state.items.map(task => {
          if (task.id === action.taskId) {
            // make copy of task without 'deleting:true' property
            const { deleting, ...taskCopy } = task;
            // return copy of task with 'deleteError:[error]' property
            return { ...taskCopy, deleted: action.isDelete };
          }

          return task;
        })
      };
    case taskConstants.DELETE_TASK_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to task 
      return {
        ...state,
        items: state.items.map(task => {
          if (task.id === action.taskId) {
            // make copy of task without 'deleting:true' property
            const { deleting, ...taskCopy } = task;
            // return copy of task with 'deleteError:[error]' property
            return { ...taskCopy, deleteError: action.error };
          }

          return task;
        })
      };


    case taskConstants.REMOVE_TASK_SUCCESS:
      return {
        ...state,
        items: state.items.filter(task => task.id !== action.taskId)
      };
    case taskConstants.ACTIVATE_TASK_REQUEST:
      return {
        ...state,
        items: state.items.map(task =>
          task.id === action.taskId
            ? { ...task, activating: true }
            : task
        )
      };

    case taskConstants.ACTIVATE_TASK_SUCCESS:
      return {
        ...state,
        items: state.items.map(task =>
          task.id === action.taskId
            ? { ...task, active: action.isActive }
            : task
        )
      };
    case taskConstants.ACTIVATE_TASK_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to task 
      return {
        ...state,
        items: state.items.map(task => {
          if (task.id === action.taskId) {
            // make copy of task without 'deleting:true' property
            const { activating, ...taskCopy } = task;
            // return copy of task with 'deleteError:[error]' property
            return { ...taskCopy, deleteError: action.error };
          }

          return task;
        })
      };


    case taskConstants.CLOSE_TASK_CREATE_WINDOW:
      const { selectedTaskInfo, ...stateCopy } = state;
      return {
        ...stateCopy,
        showCreateModel: false
      };


    case taskConstants.GET_TASK_REQUEST:
      return {
        ...state,
        selectedTaskInfo: null,
        loading: true
      };
    case taskConstants.GET_TASK_SUCCESS:
      //console.log(action.task);
      return {
        ...state,
        loading: false,
        selectedTaskInfo: action.task
      };
    case taskConstants.GET_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case taskConstants.INITIALIZE_TASKS_USER_TEAMS:
      return {
        ...state,
        userTeams: action.userTeams
      }
    case taskConstants.CHANGE_FILTER_TASKS:
      return {
        ...state,
        filterOptions: action.filterOptions
      };

    case taskConstants.SHOW_TASK_VIEW_WINDOW:
      return {
        ...state,
        timesheetLoading: false,
        isShowTaskViewSidebar: true
      }
    case taskConstants.CLOSE_TASK_VIEW_WINDOW:
      return {
        ...state,
        isShowTaskViewSidebar: false
      }

    default:
      return state
  }
}