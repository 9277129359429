import React, { PureComponent } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import CountUp from 'react-countup';
class EmployeePageSummaryItem extends PureComponent {
    render() {
        return (
            <div className="col-lg-2 col-md-6">
                {this.props.isLoading &&
                    <SkeletonTheme color="#fff" highlightColor="#ccc">
                        <Skeleton count={1} height={80} />
                    </SkeletonTheme>
                }
                {!this.props.isLoading &&
                    <div className="card">
                        <div className="card-body widgets1">
                            <div className="icon">
                                <i className={`${this.props.iconClass}`} />
                            </div>
                            <div className="details">
                                <span className="fsize16 fweight600">{this.props.label}</span>
                                <p className="mb-0"><CountUp className="fsize17 fweight600" end={this.props.count} /> Users</p>
                            </div>
                        </div>
                    </div>
                }
            </div>)
    }
}
export default EmployeePageSummaryItem;