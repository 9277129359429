import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import dateUtils from "_helpers/date.utils";
import classNames from 'classnames/bind';
import SelectInput from "views/common/fragments/formfields/SelectInput";

class PurchaseOrderInfoLine extends Component {

  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }
  handleRemovePurchaseOrder = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemovePurchaseOrder(this.props.item.id);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (
      <tr>
        <td>
          {this.props.item.itemIdUserTeamPrefix}</td>
        <td>{dateUtils.convertDateFormat(this.props.item.createdDate)}</td>
        <td className="w-200">
          <NavLink
            to={`/project/purchaseorder/${this.props.item.itemIdUserTeamPrefix}`}
            className="nav-link list-b pl-0"
            activeClassName="active"
          >
            {this.props.item.source && this.props.item.source === 'SF' &&
              <i className="fa fa-mixcloud sharelink colorblue"></i>
            }
            <span className="d-inline-block text-truncate mt-0 align-middle w-200" title={this.props.item.name}>{this.props.item.name}</span>

          </NavLink>
        </td>
        <td>{this.props.item.proposalNumber}</td>
        <td>{this.props.item.bidId}</td>
        <td>{this.props.item.accountName}</td>
        <td className="d-block">
          {this.props.item.teamName &&
            <p className="m-0 m-0 text-truncate w-100" title={this.props.item.teamName}>- {this.props.item.teamName}</p>
          }
          {this.props.item.clientNames &&
            <p className="m-0 m-0 text-truncate w-100" title={this.props.item.clientNames && this.props.item.clientNames.join(',')}>
              - {this.props.item.clientNames && this.props.item.clientNames.join(',')}
            </p>
          }
        </td>
        <td>
          <SelectInput
            options={[
              { value: "NEW", label: "New" },
              { value: "CREATED", label: "CREATED" },
              { value: "INPROGRESS", label: "In Progress" },
              { value: "COMPLETED", label: "Completed" },
              { value: "CLOSED", label: "Closed" }]}
            value={this.props.item.status}
            allowLevelDown={this.props.permission.allowStatusLevelDown}
            changeableStatus={this.props.permission.changeableStatus}
            isReadOnly={(!this.props.permission.allowStatusChange) || this.props.readOnly}
            itemId={this.props.item.id}
            onChange={this.props.handleUpdateStatus}
            className={classNames("badge",
              { "badge-info": this.props.item.status === 'NEW' },
              { "badge-danger": this.props.item.status === 'CREATED' },
              { "badge-warning": this.props.item.status === 'INPROGRESS' },
              { "badge-success": this.props.item.status === 'COMPLETED' },
              { "badge-dark": this.props.item.status === 'CLOSED' }
            )} />

        </td>
        <td className="w-100 d-block">
          <span className="project-due-text colorblue px-1 py-0 mb-0 d-block">{dateUtils.convertDateFormat(this.props.item.estStartTime)}</span>
          <span className="project-due-text red px-1 py-0">{dateUtils.convertDateFormat(this.props.item.estCompletionTime)}</span>
        </td>
        <td className="text-center">
          <span>
            {this.props.permission.archiveEnabled === true &&
              <label className=" custom-switch custom-switch-sm m-0">
                <input type="checkbox" onChange={e => { }}
                  onClick={() => this.props.handleArchivePurchaseOrder(this.props.item.id, !this.props.item.archived)}
                  className="custom-switch-input" checked={this.props.item.archived ? false : true} />
                <span className="custom-switch-indicator" />
              </label>
            }
            {this.props.permission.viewEnabled === true &&
              <button className="link pl-2" title="View"
                onClick={(e) => this.props.onViewPurchaseOrder(this.props.item.id)}
              ><i className="fa fa-eye align-text-top fa-lg"></i></button>
            }
            {!this.props.item.archived && this.props.permission.editEnabled === true &&
              < button type="button" className="link pl-2" title="Edit"
                onClick={(e) => this.props.handleEditPurchaseOrder(this.props.item.id, this.props.item.userTeamId)}
              ><i className="fa fa-edit" /></button>
            }
            {this.props.permission.duplicateEnabled === true &&
              < button type="button" className="link pl-2" title="Duplicate"
                onClick={(e) => this.props.handleDuplicatePurchaseOrder(this.props.item.id, this.props.item.userTeamId)}
              ><i className="fa fa-clone" /></button>
            }
            {this.props.permission.deleteEnabled === true &&
              <button type="button"
                onClick={(e) => this.props.handleDeletePurchaseOrder(this.props.item.id, !this.props.item.deleted)}
                className="link pl-2" title={this.props.item.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
                {this.props.item.deleted &&
                  <>
                    <i className="fa fa-rotate-left colorred blink_text"></i>
                  </>
                }
                {!this.props.item.deleted &&
                  <>
                    <i className="fa fa-trash"></i>
                  </>
                }
              </button>
            }
            {this.props.permission.removeEnabled === true &&

              <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemovePurchaseOrder(this.state.confrimDelete)}>
                {this.state.confrimDelete === true &&
                  <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
                }
                {this.state.confrimDelete === false &&
                  <i className="dropdown-icon fa fa-times colorred"></i>
                }
              </button>
            }
          </span>
        </td>
      </tr >
    );
  }
}
export default PurchaseOrderInfoLine;