import React, { PureComponent } from "react";
import { store } from '_helpers'
import { notificationActions } from '_actions';
import classNames from 'classnames/bind';
import { NavLink } from "react-router-dom";
class EmployeeInfoLine extends PureComponent {

  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }
  handleRemoveEmployee = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveEmployee(this.props.employee.id)
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (
      <tr>
        <td className={classNames("d-flex", { "deleted": this.props.employee.deleted }, { "inactive": !this.props.employee.active })}>
          <img className="img-thumbnail rounded-circle avatar-lg" src={`/assets/images/${this.props.employee.gender === 'female' ? 'female.png' : 'male.jpg'}`} alt="" />
          <div className="ml-3 mt-0">
            <span className="mb-0 d-block">{this.props.employee.empCode}</span>
            <span className="mb-0 d-block">
              {this.props.permission.viewEnabled === true &&
                <NavLink
                  to={"/hrms/profile/" + this.props.employee.id}
                  className="fsize14 fweight600"
                  activeClassName="active"
                >
                  {this.props.employee.name}
                </NavLink>
              }
              {!this.props.permission.viewEnabled &&
                this.props.employee.name
              }
            </span>
            <span className="text-muted">{this.props.employee.designation}</span>
          </div>
        </td>
        <td><span className="">{this.props.employee.primarySkill}</span></td>
        <td className="text-center"><span

          className={classNames("tag", { "tag-info": this.props.employee.empType === 'Permanent' },
            { "tag-warning": this.props.employee.empType === 'Contract' },
            { "tag-success": this.props.employee.empType === 'Intern' },
            { "tag-dark": this.props.employee.empType === 'Consultant' }
          )}

        >{this.props.employee.empType}</span></td>
        <td><span className="">{this.props.employee.email}</span></td>
        <td><span className="">{this.props.employee.dateOfJoin}</span></td>
        <td><span className="">{this.props.employee.mobileNumber}</span></td>
        <td>
          {this.props.permission.editEnabled === true &&
            <button type="button" className="link" title="Edit"
              onClick={(e) => this.props.handleEditEmployee(this.props.employee.id, this.props.employee.userTeamId)}
            ><i className="fa fa-edit" /></button>
          }
          {this.props.permission.deleteEnabled === true &&
            <button type="button"
              onClick={(e) => this.props.handleDeleteEmployee(this.props.employee.id, !this.props.employee.deleted)}
              className="link pl-2" title={this.props.employee.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
              {this.props.employee.deleted &&
                <>
                  <i className="fa fa-rotate-left colorred blink_text"></i>
                </>
              }
              {!this.props.employee.deleted &&
                <>
                  <i className="fa fa-trash"></i>
                </>
              }
            </button>
          }
          {this.props.permission.archiveEnabled === true &&
            <button type="button"
              onClick={(e) => this.props.handleActivateEmployee(this.props.employee.id, !this.props.employee.active)}
              className="link pl-2" title={this.props.employee.active ? 'Inactivate' : 'Activate'} data-type="confirm">
              {this.props.employee.active &&
                <>
                  <i className="dropdown-icon fe fe-user-x text-primary colorred"></i>
                </>
              }
              {!this.props.employee.active &&
                <>
                  <i className="dropdown-icon fe fe-user-check text-primary colorgreen"></i>
                </>
              }
            </button>
          }
          {this.props.permission.removeEnabled === true &&

            <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveEmployee(this.state.confrimDelete)}>
              {this.state.confrimDelete === true &&
                <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
              }
              {this.state.confrimDelete === false &&
                <i className="dropdown-icon fa fa-times colorred"></i>
              }
            </button>
          }
        </td>
      </tr>
    );
  }
}
export default EmployeeInfoLine;