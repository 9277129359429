import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classNames from 'classnames/bind';
class Menu extends Component {

  activeRoute(routeName) {
    let layoutAndPage = this.props.location.pathname.split('/');
    //console.log(routeName)
    //console.log(layoutAndPage)
    if (layoutAndPage.length > 2 && routeName === "/" + layoutAndPage[1]) {
      //let pageRoute = this.props.routes.find((route) => { return route.layout === routeName && route.path === "/" + layoutAndPage[2] });
      //return pageRoute && pageRoute.showInMenu
      return true;
    }
    return false;
  }
  activeRoutePage(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  render() {
    return (
      <li className={classNames({ 'active': this.activeRoute(this.props.layoutName) })}>
        <a href="#" className="has-arrow arrow-c" aria-expanded={this.activeRoute(this.props.layoutName)}><i className={this.props.icon} /><span>{this.props.menuName}</span></a>
        <ul className={this.activeRoute(this.props.layoutName) ? "collapse in" : "collapse"} aria-expanded={this.activeRoute(this.props.layoutName)} style={{ height: this.activeRoute(this.props.layoutName) ? "unset" : "0px" }}>
          {this.props.routes.map((prop, key) => {
            if (prop.layout === this.props.layoutName && prop.showInMenu && this.props.menuAccessControl && this.props.menuAccessControl[prop.layout.substring(1) + "Modules"][prop.path.substring(1) + "Enabled"] === true)
              return (
                <li
                  className={this.activeRoutePage(prop.layout + prop.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link list-b"
                    activeClassName="active"
                  >
                    <span>{prop.name}</span>
                  </NavLink>
                </li>
              );
            return null;
          })}
        </ul>
      </li>
    );
  }
}
export default Menu;