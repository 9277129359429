import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";

class SidebarFooter extends Component {
    constructor() {
        super();
        this.state = {
            createAnotherEnabled: false,
            keepDataEnabled: false
        }
    }
    onCreateAnotherEnabled = (value) => {
        this.setState({
            createAnotherEnabled: value
        })
    }
    onKeepDataEnabled = (value) => {
        this.setState({
            keepDataEnabled: value
        })
    }
    render() {
        return (
            <>
                {this.props.hasFormError &&
                    <div className="alert alert-danger alert-dismissible mb-0 py-1">
                        <button type="button" className="close py-1" onClick={() => this.props.onResetFormError()}></button>
                        <strong><i className="fa fa-exclamation-triangle colorred"></i></strong> Error : Please complete the mandatory fields before Submitting!
                    </div>
                }
                <div className="card-footer py-1 d-flex">
                    {!this.props.editMode && !this.props.duplicateMode && !this.props.disableKeepMeOpen &&
                        <span className="fsize12 pr-3  pt-1">
                            <span className="pr-1">Keep me open</span>
                            <input value={this.state.createAnotherEnabled} type="checkbox" onChange={(e) => this.onCreateAnotherEnabled(e.target.checked)} />
                        </span>
                    }
                    {this.state.createAnotherEnabled === true && this.props.keepDataOptionEnabled &&
                        <span className="fsize12 pr-3  pt-1">
                            <span className="pr-1">Keep Data</span>
                            <input value={this.state.keepDataEnabled} type="checkbox" onChange={(e) => this.onKeepDataEnabled(e.target.checked)} />
                        </span>
                    }
                    {this.props.editMode && this.props.effectiveUpdateDateEnabled &&
                        <span className="fsize12 pr-3 d-flex pt-1">
                            <span className="pr-1 w-150">Effective Date</span>
                            <ReactDatePicker
                                selected={!this.props.mappingObject.effectiveDate ? new Date(): typeof (this.props.mappingObject.effectiveDate) === 'string' ? new Date(this.props.mappingObject.effectiveDate) : this.props.mappingObject.effectiveDate}
                                onChange={selectedValue => this.props.onEffectiveDateDateChange(selectedValue)}
                                format={'YYYY-MM-DD'}
                            />
                        </span>
                    }
                    {this.props.isProcessing &&
                        <button className="btn btn-primary btn-sm mr-3">
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                        </button>
                    }
                    {!this.props.isProcessing &&
                        <>

                            <button className="btn btn-primary btn-sm mr-3" onClick={() => this.props.handleSubmit(this.state.createAnotherEnabled, this.state.keepDataEnabled)}>
                                {this.props.isProcessing &&
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                }
                                {!this.props.isProcessing &&
                                    <>
                                        {this.props.editMode ? "Update" : this.props.duplicateMode ? "Duplicate" : this.props.createButtonLabel ? this.props.createButtonLabel : "Create"}
                                    </>
                                }
                            </button>

                            <button
                                className="btn btn-default btn-sm"
                                onClick={() => this.props.initializeRequest(this.state.createAnotherEnabled)}
                            >{this.props.closeButtonLabel || 'Reset'}</button>
                        </>
                    }
                </div>
            </>
        )
    }
}

export default SidebarFooter;