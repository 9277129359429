import React, { PureComponent } from "react";

import classNames from 'classnames/bind';
import { commonUtils } from "_helpers";

class PageInnerHeaderTeamsSelectionMenu extends PureComponent {
    constructor() {
        super();
        this.state = {
            showTeamDropDown: false,
            selectedUserTeamsIds: [],
            filterText: "",
            selectAll: false
        };
    }
    componentDidMount() {
        this.initalizeUserTeamIds()
    }

    toggleTeamDropDown = (isDropdownShow) => {
        this.setState({ showTeamDropDown: isDropdownShow });
    }
    onSelectAll = (event) => {
        this.setState({
            selectAll: event.target.checked,
            selectedUserTeamsIds: this.state.selectedUserTeamsIds.map(userItem => { return { ...userItem, isChecked: event.target.checked } })

        })
    }

    initalizeUserTeamIds = () => {
        let selectedIds = [];
        this.props.userTeams && this.props.userTeams.map(userItem => {
            if (userItem.isChecked) {
                selectedIds.push({ id: userItem.id, name: userItem.name, isChecked: true, default: userItem.default });
            } else {
                selectedIds.push({ id: userItem.id, name: userItem.name, isChecked: false, default: userItem.default });
            }
        })
        this.setState({ selectedUserTeamsIds: selectedIds });
    }
    closeTeamDropDown = () => {
        this.initalizeUserTeamIds();
        this.toggleTeamDropDown(false);
    }
    handleUserTeamsSelection = () => {
        this.toggleTeamDropDown(false);
        this.props.handleUserTeamsSelection(this.state.selectedUserTeamsIds);
    }

    onFilterTextChange = (text) => {
        this.setState({
            filterText: text
        })
    }

    onCheckChangeElement = (event) => {
        this.setState({
            selectedUserTeamsIds: this.state.selectedUserTeamsIds.map(userItem =>
                userItem.id == event.target.value
                    ? { ...userItem, isChecked: event.target.checked }
                    : (this.props.enableSingleSelection === true ? { ...userItem, isChecked: false } : userItem)
            )
        });
    }

    render() {
        return (
            <>
                {this.props.userTeams.length > 1 && this.props.enabled &&
                    <span className="">
                        <button className="link" onClick={() => !this.props.isLoading && this.toggleTeamDropDown(!this.state.showTeamDropDown)} title="Select Teams">
                            <i className="fe fe-filter pr-1"></i>
                            <span className="totalcount-notation">{this.state.selectedUserTeamsIds.filter(userItem => userItem.isChecked).length}</span>
                        </button>
                        <div className={classNames("dropdowncontainer", { "d-none": !this.state.showTeamDropDown })} >
                            <div className="dropdownmenu custom-controls-stacked font-weight-normal text-capitalize w-200">
                                <label className="custom-control custom-checkbox ml-1">
                                    <input type="checkbox" disabled={this.props.isLoading || this.props.enableSingleSelection} checked={this.state.selectAll} onChange={(event) => this.onSelectAll(event)} className="custom-control-input" />
                                    <div className="custom-control-label input-icon mb-2 ml-1">
                                        <span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                                        <input type="text" className="form-control input-sm filter" placeholder="Filter..."
                                            value={this.state.filterText || ''}
                                            onChange={(event) => this.onFilterTextChange(event.target.value)}
                                        />
                                    </div>
                                </label>
                                <div className="custom-dropdownmenu pl-2 ">
                                    <div className="pr-3">
                                        {this.state.selectedUserTeamsIds && this.state.selectedUserTeamsIds
                                            .filter(userTeam =>
                                                this.state.filterText == "" || commonUtils.stringContainsString(userTeam.name, this.state.filterText))
                                            .map(userTeam =>

                                                <label className="custom-control custom-checkbox" key={userTeam.id}>
                                                    <input type="checkbox" disabled={this.props.isLoading} checked={userTeam.isChecked} onChange={(e) => this.onCheckChangeElement(e)} className="custom-control-input" name={`Team${userTeam.id}`} value={userTeam.id} />
                                                    <span className="custom-control-label fsize13 fweight600">{userTeam.name}</span>
                                                </label>
                                            )}
                                    </div>
                                </div>
                                <div className="pr-3 pt-1 mt-2 border">
                                    {this.state.selectedUserTeamsIds.length > 0 &&
                                        <button className={classNames("plainbutton fsize12 py-0 px-2 mb-1 ml-1 text-upper border fweight600 fsize13", { "colorlightgray": this.state.selectedUserTeamsIds.filter(userItem => userItem.isChecked).length === 0 })} onClick={() => this.state.selectedUserTeamsIds.filter(userItem => userItem.isChecked).length > 0 && this.handleUserTeamsSelection()}>
                                            Done
                                        </button>
                                    }
                                    <button className="pl-2 colorblue link float-right mt-1" onClick={() => this.closeTeamDropDown()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </span>
                }
            </>

        );
    }
}

export default PageInnerHeaderTeamsSelectionMenu