import React, { Component } from "react";
import classNames from 'classnames/bind';
import Select from "react-select";
import { commonUtils } from "_helpers";
import PageInnerHeaderEmployeesRoleSelectionMenu from "views/common/fragments/PageInnerHeaderEmployeesRoleSelectionMenu";
import { employeeActions } from "_actions";
import { connect } from "react-redux";
import { store } from "_helpers";
import { userActions } from "_actions";
import { validationUtils } from "_helpers";
let modulePermissionAttributes = {
    "viewEnabled": "View",
    "createEnabled": "Create",
    "editEnabled": "Edit",
    "deleteEnabled": "Delete",
    "removeEnabled": "Remove",
    "duplicateEnabled": "Duplicate",
    "archiveEnabled": "Archive"
};
class ApplicationConfigSection extends Component {
    constructor() {
        super();
        this.state = {
            configRequest: {},
            showConfigProperty: {
                name: {
                    enabled: false
                },
                parent: {
                    enabled: false
                },
                category: {
                    enabled: false
                }
            }
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.menuSelection && this.props.menuSelection.type === 'role' && !this.props.allEmployees) {
            store.dispatch(this.props.getAllEmployees());
        }
        if (!previousState.showConfigProperty.name.enabled && previousState.showConfigProperty.name.value != this.props.data.name) {
            this.setState(prevState => ({
                showConfigProperty: {
                    ...prevState.showConfigProperty,
                    category: {
                        ...prevState.showConfigProperty.category,
                        value: prevState.showConfigProperty.category.enabled ? prevState.showConfigProperty.category.value : this.props.data.category
                    },
                    name: {
                        ...prevState.showConfigProperty.name,
                        value: prevState.showConfigProperty.name.enabled ? prevState.showConfigProperty.name.value : this.props.data.name
                    },
                    parent: {
                        ...prevState.showConfigProperty.parent,
                        value: prevState.showConfigProperty.parent.enabled ? prevState.showConfigProperty.parent.value : this.props.data.parent
                    }
                },
            }))
        }
    }

    onChangeShowAddtionalOption = (propertyName, type, value) => {
        this.setState(prevState => ({
            ...prevState,
            showConfigProperty: {
                ...prevState.showConfigProperty,
                [propertyName]: {
                    ...prevState.showConfigProperty[propertyName],
                    [type]: value
                }
            },
        }))
    }

    handleSaveConfigProperty = (propertyName) => {
        var propertyValue = this.state.showConfigProperty[propertyName].value;
        //console.log(propertyName + " : " + propertyValue);
        this.onChangeShowAddtionalOption(propertyName, "enabled", false);
        this.props.handleSaveConfigProperty(propertyName, propertyValue);
    }

    componentDidMount() {
        this.setState(prevState => ({
            configRequest: {
                ...this.props.data
            },
            showConfigProperty: {
                ...prevState.showConfigProperty,
                category: {
                    value: this.props.data.category
                },
                name: {
                    value: this.props.data.name
                }
            },
        }))
    }

    onReset = () => {
        this.setState(prevState => ({
            configRequest: {
                ...this.props.data
            }
        }))
        this.props.handleUpdateAppConfigItem({});
    }

    onChange = (fieldName, value) => {
        this.setState(prevState => ({
            configRequest: {
                ...prevState.configRequest,
                modules: {
                    ...prevState.configRequest.modules,
                    [fieldName]: value,
                }
            }
        }), () => {
            this.props.onChange(this.state.configRequest);
        })
    }

    onSaveOrUpdate = () => {
        this.props.handleUpdateAppConfigItem(this.state.configRequest);
    }

    updateEmployeeRoles = (selectedEmployeeRoles, closeModelCallBack) => {
        let employeeRoles = {};
        selectedEmployeeRoles.map(selectedEmployeeRole => {

            employeeRoles = {
                ...employeeRoles,
                [selectedEmployeeRole.id]: selectedEmployeeRole.userRole
            }

            return selectedEmployeeRole;
        })
        store.dispatch(this.props.updateEmployeeRoles(employeeRoles))
        closeModelCallBack(false);
    }

    refreshUserRole = () => {
        store.dispatch(userActions.authRefresh());
    }

    render() {
        return (
            <div className="justify-content-between flex-column d-flex">
                <div className="card">
                    {!this.props.readOnly &&
                        <div className="card-header border-bottom py-2">
                            <span className="card-title">{this.props.label}: </span>
                            {!this.state.showConfigProperty.name.enabled &&
                                <span className="pl-1 fsize14 fweight600" onClick={() => this.onChangeShowAddtionalOption("name", "enabled", true)}>{this.props.data.name}</span>
                            }
                            {this.state.showConfigProperty.name.enabled &&
                                <>
                                    <input type="text" className="appConfig addnewConfigInput ml-1" onChange={(e) => this.onChangeShowAddtionalOption("name", "value", e.target.value)} value={this.state.showConfigProperty.name.value} />
                                    {this.state.showConfigProperty.name.value && this.state.showConfigProperty.name.value.length > 3 &&
                                        <i className="fa fa-check colorgreen pl-1" onClick={() => this.handleSaveConfigProperty("name")}></i>
                                    }
                                    <i className="fa fa-times-circle-o  pl-2" onClick={() => this.onChangeShowAddtionalOption("name", "enabled", false)}></i>
                                </>
                            }
                            {(this.props.menuSelection.type === 'role' && this.props.allEmployees) &&
                                <>
                                    <span className="pl-1 fsize14 fweight600 text-muted pl-3">No. of Employees: </span>
                                    <PageInnerHeaderEmployeesRoleSelectionMenu
                                        enabled={true}
                                        roles={this.props.roles}
                                        selectedMenu={this.props.menuSelection}
                                        allEmployees={this.props.allEmployees}
                                        handleUpdateEmployeeRoles={this.updateEmployeeRoles}
                                    />
                                </>
                            }
                            <div className="card-options">


                                {this.props.parentMenuOptions.find(menuNode => menuNode.value === this.props.menuSelection.id) && this.props.parentMenuOptions.find(menuNode => menuNode.value === this.props.menuSelection.id).parent &&
                                    <>
                                        <span className="fsize11 pt-1 pr-2">PARENT: </span>
                                        {!this.state.showConfigProperty.parent.enabled &&
                                            <span className="fsize11 fweight600 pt-1" onClick={() => this.onChangeShowAddtionalOption("parent", "enabled", true)}>
                                                {this.props.parentMenuOptions.find(parentMenuNode => parentMenuNode.value === this.props.parentMenuOptions.find(menuNode => menuNode.value === this.props.menuSelection.id).parent).label}

                                            </span>
                                        }
                                        {this.state.showConfigProperty.parent.enabled &&
                                            <>
                                                <select className="custom-select appConfig addnewConfigSelect" onChange={(e) => this.onChangeShowAddtionalOption("parent", "value", e.target.value)} value={this.state.showConfigProperty.parent.value}>
                                                    <option value="">Select Parent</option>
                                                    {this.props.parentMenuOptions.map((node) => {
                                                        if (node.category !== 'Team' && node.value !== this.props.menuSelection.id && node.value !== this.props.parentMenuOptions.find(menuNode => menuNode.value === this.props.menuSelection.id).parent) {
                                                            return <option value={node.value} key={node.value}>{node.label}</option>
                                                        }
                                                    })
                                                    }

                                                </select>
                                                {this.state.showConfigProperty.parent.value && this.state.showConfigProperty.parent.value.length > 0 &&
                                                    <i className="fa fa-check colorgreen pl-1 pt-1" onClick={() => this.handleSaveConfigProperty("parent")}></i>
                                                }
                                                <i className="fa fa-times-circle-o  pl-2  pt-1" onClick={() => this.onChangeShowAddtionalOption("parent", "enabled", false)}></i>
                                            </>
                                        }
                                    </>
                                }
                                {this.props.menuSelection.type === 'org' &&
                                    <>
                                        <span className="fsize11 pt-1 pr-2 pl-3">TYPE: </span>
                                        {!this.state.showConfigProperty.category.enabled &&
                                            <span className="fsize11 fweight600 pt-1" onClick={() => this.onChangeShowAddtionalOption("category", "enabled", true)}>{this.props.data.category || "Default"}</span>
                                        }
                                        {this.state.showConfigProperty.category.enabled &&
                                            <>
                                                <select className="custom-select appConfig addnewConfigSelect" onChange={(e) => this.onChangeShowAddtionalOption("category", "value", e.target.value)} value={this.state.showConfigProperty.category.value}>
                                                    <option value="">Select Type</option>
                                                    <option value="Department">Department</option>
                                                    <option value="Division">Division</option>
                                                    <option value="Section">Section</option>
                                                    <option value="Account">Account</option>
                                                    <option value="Team">Team</option>
                                                </select>
                                                {this.state.showConfigProperty.category.value && this.state.showConfigProperty.category.value.length > 1 &&
                                                    <i className="fa fa-check colorgreen pl-1  pt-1" onClick={() => this.handleSaveConfigProperty("category")}></i>
                                                }
                                                <i className="fa fa-times-circle-o  pl-2  pt-1" onClick={() => this.onChangeShowAddtionalOption("category", "enabled", false)}></i>
                                            </>
                                        }
                                    </>
                                }
                                {this.props.menuSelection.type === 'role' &&
                                    <button className="link pl-3" onClick={() => this.refreshUserRole()}><i className="fa fa-refresh"></i></button>
                                }
                            </div>
                        </div>
                    }
                    <div className="card-body pr-3  overflow-y vh-100">
                        <div className="row">
                            {this.props.data && this.props.data && this.props.data.modules
                                && Object.keys(this.props.data.modules).map((key) => {
                                    return this.props.data.modules[key].parentEnabled &&
                                        <ApplicationModuleConfig key={key} selectedKey={key} module={this.props.data.modules[key]} onChange={this.onChange} />
                                })}
                        </div>
                    </div>
                    {!this.props.readOnly &&
                        <div className="card-footer py-1">
                            <button type="button" onClick={() => this.onSaveOrUpdate()} className="btn btn-round btn-primary">Update</button> &nbsp;&nbsp;
                            <button type="button" onClick={() => this.onReset()} className="btn btn-round btn-default">Reset</button>
                        </div>
                    }
                </div>
            </div >
        )
    }
}

class ApplicationModuleConfig extends Component {

    constructor() {
        super();
        this.state = {
            moduleConfigRequest: {},
            collapsed: false
        }
    }

    componentDidMount() {
        this.setState(prevState => ({
            moduleConfigRequest: {
                ...this.props.module
            }
        }))
    }

    onChange = (fieldName, value) => {
        this.setState(prevState => ({
            moduleConfigRequest: {
                ...prevState.moduleConfigRequest,
                "configurations": {
                    ...prevState.moduleConfigRequest.configurations,
                    [fieldName]: value
                }
            }
        }), () => {
            this.props.onChange(this.props.selectedKey, this.state.moduleConfigRequest);
        })
    }

    onModuleChange = (fieldName, value) => {
        this.setState(prevState => ({
            moduleConfigRequest: {
                ...prevState.moduleConfigRequest,
                [fieldName]: value
            }
        }), () => {
            this.props.onChange(this.props.selectedKey, this.state.moduleConfigRequest);
        })
    }

    renderConfigurationSingleItems = (configurationItems) => {
        return (
            <ul className="list-group mb-3 tp-setting">
                {Object.keys(configurationItems).map((key) => {
                    return configurationItems[key].parentHidden === false && (typeof (configurationItems[key].value) === 'string' || typeof (configurationItems[key].value) === 'boolean')
                        && <SingleConfigItem key={key} selectedKey={key} configItem={configurationItems[key]} onChange={this.onChange} />
                })
                }
            </ul>
        )
    }

    renderConfigurationMultiListItems = (configurationItems) => {
        return (
            <div className="row">
                {Object.keys(configurationItems).map((key) => {
                    return configurationItems[key].parentHidden === false && configurationItems[key].inputType !== 'multi' && Array.isArray(configurationItems[key].value)
                        && <MultiListValueConfigItem key={key} selectedKey={key} configItem={configurationItems[key]} onChange={this.onChange} />
                })
                }
            </div>
        )
    }
    renderConfigurationMultiLevelListItems = (configurationItems) => {
        return (
            <div className="row">
                {Object.keys(configurationItems).map((key) => {
                    return configurationItems[key].parentHidden === false && configurationItems[key].inputType === 'multi' && typeof (configurationItems[key].value) === 'object'
                        && <MultiLevelListValueConfigItem key={key} selectedKey={key} configItem={configurationItems[key]} onChange={this.onChange} />
                })
                }
            </div>
        )
    }
    renderConfigurationMultiMapItems = (configurationItems) => {
        return (
            <div className="row">
                {Object.keys(configurationItems).map((key) => {
                    return configurationItems[key].parentHidden === false && configurationItems[key].inputType !== 'multi' && !Array.isArray(configurationItems[key].value) && typeof (configurationItems[key].value) === 'object' && !configurationItems[key].longList
                        && <MultiMapValueConfigItem key={key} selectedKey={key} configItem={configurationItems[key]} onChange={this.onChange} />
                })
                }
            </div>
        )
    }
    renderConfigurationMultiLongListGroupItems = (configurationItems, multiLabelAttributes, mainLabel) => {
        let configItems = {}
        Object.keys(configurationItems).map((key) => {
            if (configurationItems[key].parentHidden === false && configurationItems[key].longList && configurationItems[key].longList === true && (!configurationItems[key].inputType || configurationItems[key].inputType === 'group')) {
                configItems = { ...configItems, [key]: configurationItems[key] }
            }
        })
        return (
            <>
                {configItems && Object.keys(configItems).length > 0 &&
                    <div className="table-responsive mb-4">
                        <table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th>{mainLabel}</th>
                                    {Object.keys(multiLabelAttributes).map(key =>
                                        <th key={key}>{multiLabelAttributes[key]}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(configItems).map((key) => {
                                    return <MultiLongListGroupValueConfigItem key={key} selectedKey={key} configItem={configItems[key]} onChange={this.onChange} />
                                })
                                }
                            </tbody>
                        </table>
                    </div>}
            </>
        )
    }

    renderConfigurationMultiLongListListItems = (configurationItems) => {

        return (
            <div className="col-12 row">
                {Object.keys(configurationItems).map((key) => {
                    return configurationItems[key].parentHidden === false && configurationItems[key].longList && configurationItems[key].longList === true && (configurationItems[key].inputType && configurationItems[key].inputType === 'list')
                        && <MultiLongListListValueConfigItem key={key} selectedKey={key} configItem={configurationItems[key]} onChange={this.onChange} />

                })
                }
            </div>
        )
    }
    render() {
        return (
            <div className={classNames("card pb-0 mb-0", { "card-collapsed": this.state.collapsed })}>
                <div className="card-header pt-0 pb-1">
                    <span className="text-color-gray-aaa">{this.props.module.label}</span>
                    <div className="card-options">
                        <label className="custom-switch custom-switch-sm m-0">
                            <input type="checkbox" onChange={e => { }} onClick={() => this.onModuleChange("enabled", !this.state.moduleConfigRequest.enabled)} className="custom-switch-input" checked={this.state.moduleConfigRequest.enabled ? this.state.moduleConfigRequest.enabled : false} />
                            <span className="custom-switch-indicator" />
                        </label>
                        {!this.props.module.parentLocked &&
                            <button onClick={() => this.onModuleChange("locked", !this.state.moduleConfigRequest.locked)} className="link pl-2"><i className={classNames('fe', { "fe-unlock": !this.state.moduleConfigRequest.locked }, { "fe-lock": this.state.moduleConfigRequest.locked })} ></i></button>
                        }
                        <button onClick={() => this.setState({ collapsed: !this.state.collapsed })} className="card-options-collapse link pl-2"><i className="fe fe-chevron-up"></i></button>
                    </div>
                </div>
                {this.state.moduleConfigRequest.enabled &&
                    <div className="card-body pl-3">
                        {this.renderConfigurationSingleItems(this.props.module.configurations)}
                        {this.renderConfigurationMultiListItems(this.props.module.configurations)}
                        {this.renderConfigurationMultiLevelListItems(this.props.module.configurations)}
                        {this.renderConfigurationMultiMapItems(this.props.module.configurations)}
                        {this.renderConfigurationMultiLongListGroupItems(this.props.module.configurations, modulePermissionAttributes, 'Module Permission')}
                        {this.renderConfigurationMultiLongListListItems(this.props.module.configurations)}
                    </div>
                }
            </div>

        )
    }
}
class SingleConfigItem extends Component {
    constructor() {
        super();
        this.state = {
            configItemRequest: null
        }
    }
    componentDidMount() {
        this.setState(prevState => ({
            configItemRequest: {
                ...this.props.configItem
            }
        }))
    }

    onChange = (fieldName, value) => {
        if ("overridden" === fieldName) {
            let oldvalues = this.props.configItem.parentValue && value === false ? this.props.configItem.parentValue : this.props.configItem.value
            this.setState(prevState => ({
                configItemRequest: {
                    ...this.props.configItem,
                    "overridden": value,
                    "value": oldvalues
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        } else {
            this.setState(prevState => ({
                configItemRequest: {
                    ...prevState.configItemRequest,
                    [fieldName]: value
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        }
    }

    onConfigValueInputChange = (e) => {
        e.persist();
        //this.props.configItem.value !== (e.target.type === 'checkbox' ? e.target.checked : e.target.value) ? this.onChange("overridden", true) : this.onChange("overridden", false);
        this.onChange("value", e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    }

    render() {
        return (
            <>
                {this.state.configItemRequest && <li className="list-group-item">
                    <span >{this.state.configItemRequest.label}</span>
                    <div className="float-right">
                        <div className="input-group">
                            {typeof (this.state.configItemRequest.value) === 'boolean' &&

                                <label className="custom-control custom-checkbox">
                                    <input type="checkbox" onClick={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event) }} onChange={(event) => () => { }} checked={this.state.configItemRequest.value} disabled={this.state.locked} className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                </label>
                            }
                            {typeof (this.state.configItemRequest.value) === 'string' &&
                                <input type="text" value={this.state.configItemRequest.value} className="width250 height25" onChange={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event) }} />
                            }

                            {typeof (this.state.configItemRequest.value) === 'number' &&
                                <input type="number" value={this.state.configItemRequest.value} className="width100 height25" onChange={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event) }} />
                            }

                            {!this.props.configItem.parentLocked &&
                                <>
                                    <button onClick={() => this.onChange("overridden", !this.state.configItemRequest.overridden)} className="ml-2 fsize14 link"><i className={classNames('fa', { "fa-chain-broken": this.state.configItemRequest.overridden }, { "fa-chain": !this.state.configItemRequest.overridden })} /></button>

                                    <span className="input-group-append locksymbol ml-1">
                                        <button onClick={() => this.onChange("locked", !this.state.configItemRequest.locked)} className="link"><i className={classNames('fa', { "fa-unlock": !this.state.configItemRequest.locked }, { "fa-lock": this.state.configItemRequest.locked })} ></i></button>
                                    </span>
                                    {this.state.configItemRequest.locked &&
                                        <span className="input-group-append locksymbol pl-2">
                                            <button onClick={() => this.onChange("hidden", !this.state.configItemRequest.hidden)} className="link"><i className={classNames('fsize12 fa', { "fa-eye": !this.state.configItemRequest.hidden }, { "fa-eye-slash": this.state.configItemRequest.hidden })} ></i></button>
                                        </span>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </li>
                }
            </>
        )
    }
}

class MultiListValueConfigItem extends Component {
    constructor() {
        super();
        this.state = {
            configItemRequest: null,
            addNewValueEnabled: false,
            addNewValue: '',
            multiAddEnabled: false
        }
    }
    componentDidMount() {
        this.setState(prevState => ({
            configItemRequest: {
                ...this.props.configItem
            }
        }))
    }

    onChange = (fieldName, value) => {

        if ("overridden" === fieldName) {
            let oldvalues = this.props.configItem.parentValue && value === false ? this.props.configItem.parentValue : this.props.configItem.value
            this.setState(prevState => ({
                configItemRequest: {
                    ...this.props.configItem,
                    "overridden": value,
                    "value": oldvalues
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        } else {
            this.setState(prevState => ({
                configItemRequest: {
                    ...prevState.configItemRequest,
                    [fieldName]: value
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        }

    }

    onDelete = (valueToBeDeteleted) => {
        this.onChange("value", this.state.configItemRequest.value.filter(value => valueToBeDeteleted != value))
    }

    onAddNewValueEnabled = (valueToBeDeteleted) => {
        this.setState(prevState => ({
            addNewValueEnabled: true
        }))
    }

    onAddNewValue = () => {
        let addNewValue = this.state.addNewValue.trim();
        if (addNewValue !== '') {
            if (this.state.multiAddEnabled) {
                let addNewValueArray = addNewValue.split(/[\n,]/).map(function (item) {
                    return item.trim();
                }).filter(Boolean);
                this.onChange("value", [...addNewValueArray, ...this.state.configItemRequest.value])
            } else {
                this.onChange("value", [addNewValue.trim(), ...this.state.configItemRequest.value])
            }

            this.onClearAddNew();
        }
    }

    onClearAddNew = () => {
        this.setState(prevState => ({
            addNewValueEnabled: false,
            addNewValue: ''
        }))
    }

    onAddNewValueInputChange = (e) => {
        e.persist();
        if (validationUtils.validateAlphaNumbericWithCommaAndReturn(e.target.value)) {
            this.setState(prevState => ({
                addNewValue: e.target.value
            }));
        }
    }

    onToggleMultiAddEnabled = () => {
        this.setState(prevState => ({
            multiAddEnabled: !prevState.multiAddEnabled
        }))
    }

    render() {
        return (
            <>
                {this.state.configItemRequest &&
                    <div className="col-4" >
                        <div className="card border px-2">
                            <div className="card-header py-1 pl-0 border-bottom pr-0">
                                <span className="fsize13 fweight600">{this.state.configItemRequest.label} <span className="fsize12"> ({this.state.configItemRequest.value.length})</span></span>
                                <div className="card-options">
                                    {!this.props.configItem.parentLocked &&
                                        <>
                                            <button onClick={() => this.onChange("overridden", !this.state.configItemRequest.overridden)} className="ml-0 fsize12 link"><i className={classNames('fa', { "fa-chain-broken": this.state.configItemRequest.overridden }, { "fa-chain": !this.state.configItemRequest.overridden })} /></button>
                                            <button onClick={() => this.onChange("locked", !this.state.configItemRequest.locked)} className="ml-1 fsize12 link"><i className={classNames('fa', { "fa-unlock": !this.state.configItemRequest.locked }, { "fa-lock": this.state.configItemRequest.locked })} ></i></button>
                                            {this.state.configItemRequest.locked &&
                                                <button onClick={() => this.onChange("hidden", !this.state.configItemRequest.hidden)} className="ml-1 fsize12 link"><i className={classNames('fa', { "fa-eye": !this.state.configItemRequest.hidden }, { "fa-eye-slash": this.state.configItemRequest.hidden })} ></i></button>
                                            }
                                            {this.state.addNewValueEnabled && <button onClick={this.onToggleMultiAddEnabled} className={`ml-2 fsize12 link`}><i className={`fa fa fa-list ${this.state.multiAddEnabled ? '' : "text-muted"}`} /></button>}
                                            {this.state.configItemRequest.overridden && <button onClick={this.onAddNewValueEnabled} className="ml-2 fsize12 link"><i className="fa fa-plus" /></button>}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="card-body appconfig-item">
                                <ul className="list-unstyled leading-loose">

                                    {this.state.addNewValueEnabled &&
                                        <li>
                                            {this.state.multiAddEnabled &&
                                                <textarea value={this.state.addNewValue} className="w-185 h-40px fsize12 line-height-0" onChange={this.onAddNewValueInputChange} />
                                            }
                                            {!this.state.multiAddEnabled &&
                                                <input type="text" value={this.state.addNewValue} className="appConfig addnewInput" onChange={this.onAddNewValueInputChange} />
                                            }
                                            <i onClick={this.onClearAddNew} className="fa fa-times-circle-o float-right  pl-2 pt-2"></i>
                                            <i onClick={this.onAddNewValue} className="fa fa-check float-right colorgreen pl-1 pt-2"></i>
                                        </li>
                                    }
                                    {this.state.configItemRequest.value.map((value, index) =>

                                        <li key={value} className="fsize12">{index + 1}. <span className="fweight600">{value}</span>
                                            {!this.props.configItem.parentLocked && this.state.configItemRequest.overridden &&
                                                <i onClick={() => this.onDelete(value)} className="fa fa-close float-right colorred pt-1"></i>
                                            }
                                        </li>

                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}


class MultiLevelListValueConfigItem extends Component {
    constructor() {
        super();
        this.state = {
            configItemRequest: null,
        }
    }
    componentDidMount() {
        this.setState(prevState => ({
            configItemRequest: {
                ...this.props.configItem
            }
        }))
    }

    onChange = (fieldName, value) => {

        if ("overridden" === fieldName) {
            let oldvalues = this.props.configItem.parentValue && value === false ? this.props.configItem.parentValue : this.props.configItem.value
            this.setState(prevState => ({
                configItemRequest: {
                    ...this.props.configItem,
                    "overridden": value,
                    "value": oldvalues
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        } else {
            this.setState(prevState => ({
                configItemRequest: {
                    ...prevState.configItemRequest,
                    [fieldName]: value
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        }

    }
    render() {
        return (
            <>
                {this.state.configItemRequest &&
                    <div className="col-12" >
                        <div className="card border px-2">
                            <div className="card-header py-1 pl-0 border-bottom pr-0">
                                <span className="fsize13 fweight600">{this.state.configItemRequest.label} <span className="fsize12">({Object.keys(this.state.configItemRequest.value).length})</span></span>
                                <div className="card-options">
                                    {!this.props.configItem.parentLocked &&
                                        <>
                                            <button onClick={() => this.onChange("overridden", !this.state.configItemRequest.overridden)} className="ml-0 fsize12 link"><i className={classNames('fa', { "fa-chain-broken": this.state.configItemRequest.overridden }, { "fa-chain": !this.state.configItemRequest.overridden })} /></button>
                                            <button onClick={() => this.onChange("locked", !this.state.configItemRequest.locked)} className="ml-1 fsize12 link"><i className={classNames('fa', { "fa-unlock": !this.state.configItemRequest.locked }, { "fa-lock": this.state.configItemRequest.locked })} ></i></button>
                                            {this.state.configItemRequest.locked &&
                                                <button onClick={() => this.onChange("hidden", !this.state.configItemRequest.hidden)} className="ml-1 fsize12 link"><i className={classNames('fa', { "fa-eye": !this.state.configItemRequest.hidden }, { "fa-eye-slash": this.state.configItemRequest.hidden })} ></i></button>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="col-12 row" >
                                    <MultiLevelValueSection
                                        levelLabel={this.state.configItemRequest.multiLevelLabel}
                                        configItemRequest={this.state.configItemRequest}
                                        values={this.state.configItemRequest.value}
                                        isRoot={true}
                                        onChange={this.onChange}
                                        level={Object.keys(this.state.configItemRequest.multiLevelLabel).length}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

class MultiLevelValueSection extends Component {
    constructor() {
        super();
        this.state = {
            topOverridden: false,
            topSelectedItemValue: null,
            selectedItemValue: null,
            addNewValueEnabled: false,
            addNewValue: '',
            multiAddEnabled: false
        }
    }

    onChange = (fieldName, values) => {
        if (this.props.selectedItemValue) {
            this.props.values[this.props.selectedItemValue] = values;
            this.props.onChange(fieldName, this.props.values)
        } else {
            this.props.onChange(fieldName, values)
        }
    }
    componentDidUpdate() {
        if (this.props.configItemRequest.overridden !== this.state.topOverridden) {

            this.setState(prevState => ({
                topSelectedItemValue: null,
                selectedItemValue: null,
                topOverridden: this.props.configItemRequest.overridden
            }))
        }
        if (this.props.selectedItemValue !== this.state.topSelectedItemValue) {
            this.setState(prevState => ({
                selectedItemValue: null,
                addNewValueEnabled: false,
                addNewValue: '',
                multiAddEnabled: false,
                topSelectedItemValue: this.props.selectedItemValue
            }))
        }
    }

    onDelete = (valueToBeDeteleted) => {
        let updatedValues = this.getValue();
        delete updatedValues[valueToBeDeteleted];
        this.onChange("value", updatedValues)
    }

    onAddNewValueEnabled = (valueToBeDeteleted) => {
        this.setState(prevState => ({
            addNewValueEnabled: true
        }))
    }

    onAddNewValue = () => {
        let addNewValue = this.state.addNewValue.trim();
        if (addNewValue !== '') {
            if (this.state.multiAddEnabled) {
                let addNewValueArray = addNewValue.split(/[\n,]/).map(function (item) {
                    return item.trim();
                }).filter(Boolean);
                this.onChange("value", { ...addNewValueArray.reduce((a, v) => ({ ...a, [v]: {} }), {}), ...this.getValue() })
            } else {
                this.onChange("value", { [addNewValue]: {}, ...this.getValue() })
            }

            this.onClearAddNew();
        }
    }

    onClearAddNew = () => {
        this.setState(prevState => ({
            addNewValueEnabled: false,
            addNewValue: ''
        }))
    }

    onAddNewValueInputChange = (e) => {
        e.persist();
        if (validationUtils.validateAlphaNumbericWithCommaAndReturn(e.target.value)) {
            this.setState(prevState => ({
                addNewValue: e.target.value
            }));
        }
    }

    onToggleMultiAddEnabled = () => {
        this.setState(prevState => ({
            multiAddEnabled: !prevState.multiAddEnabled
        }))
    }
    toggleSelectItem = (value) => {
        this.setState(prevState => ({
            selectedItemValue: prevState.selectedItemValue === value ? null : value
        }))
    }

    getValue = () => {
        if (this.props.selectedItemValue && !this.props.isRoot && this.props.values) {
            return this.props.values[this.props.selectedItemValue]
        }
        return this.props.isRoot && this.props.values;
    }

    render() {
        let values = this.getValue()
        return (
            <>
                {this.props.configItemRequest &&
                    <div className="col-4" >
                        <div className="card border px-2">
                            <div className="card-header py-1 pl-0 border-bottom pr-0">
                                <span className="fsize13 fweight600">{this.props.levelLabel[this.props.level]} <span className="fsize12"> ({values && Object.keys(values).length})</span></span>
                                <div className="card-options">
                                    {!this.props.configItemRequest.parentLocked &&
                                        <>
                                            {this.state.addNewValueEnabled && <button onClick={this.onToggleMultiAddEnabled} className={`ml-2 fsize12 link`}><i className={`fa fa fa-list ${this.state.multiAddEnabled ? '' : "text-muted"}`} /></button>}
                                            {this.props.configItemRequest.overridden && (this.props.selectedItemValue || this.props.isRoot) && <button onClick={this.onAddNewValueEnabled} className="ml-2 fsize12 link"><i className="fa fa-plus" /></button>}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="card-body appconfig-item multivalueitem">
                                <ul className="list-unstyled leading-loose">

                                    {this.state.addNewValueEnabled && (this.props.selectedItemValue || this.props.isRoot) &&
                                        <li>
                                            {this.state.multiAddEnabled &&
                                                <textarea value={this.state.addNewValue} className="w-185 h-40px fsize12 line-height-0" onChange={this.onAddNewValueInputChange} />
                                            }
                                            {!this.state.multiAddEnabled &&
                                                <input type="text" value={this.state.addNewValue} className="appConfig addnewInput" onChange={this.onAddNewValueInputChange} />
                                            }
                                            <i onClick={this.onClearAddNew} className="fa fa-times-circle-o float-right  pl-2 pt-2"></i>
                                            <i onClick={this.onAddNewValue} className="fa fa-check float-right colorgreen pl-1 pt-2"></i>
                                        </li>
                                    }
                                    {values && Object.keys(values).map((value, index) =>

                                        <li key={value}
                                            onClick={() => this.toggleSelectItem(value)}
                                            className={`fsize12 multivalueitemvalue ${this.state.selectedItemValue === value ? 'bgcolorlightblue' : ''}`}>
                                            {index + 1}. <span className="fweight600">{value}</span>
                                            {!this.props.configItemRequest.parentLocked && this.props.configItemRequest.overridden &&
                                                <i onClick={() => this.onDelete(value)} className="fa fa-close float-right colorred pt-1"></i>
                                            }
                                        </li>

                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {this.props.level > 1 &&
                    <MultiLevelValueSection
                        configItemRequest={this.props.configItemRequest}
                        selectedItemValue={this.state.selectedItemValue}
                        isRoot={false}
                        values={values}
                        levelLabel={this.props.levelLabel}
                        level={this.props.level - 1}
                        onChange={this.onChange}
                    />
                }
            </>
        )
    }
}



class MultiMapValueConfigItem extends Component {
    constructor() {
        super();
        this.state = {
            configItemRequest: null,
            addNewValueEnabled: false,
            addNewValue: '',
            addNewKey: ''
        }
    }
    componentDidMount() {
        this.setState(prevState => ({
            configItemRequest: {
                ...this.props.configItem
            }
        }))
    }

    onChange = (fieldName, value) => {
        if ("overridden" === fieldName) {
            let oldvalues = this.props.configItem.parentValue && value === false ? this.props.configItem.parentValue : this.props.configItem.value
            this.setState(prevState => ({
                configItemRequest: {
                    ...this.props.configItem,
                    "overridden": value,
                    "value": oldvalues
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        } else {
            this.setState(prevState => ({
                configItemRequest: {
                    ...prevState.configItemRequest,
                    [fieldName]: value
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        }
    }

    onDelete = (keToBeDeteleted) => {
        let newValues = { ...this.state.configItemRequest.value };
        delete newValues[keToBeDeteleted];
        this.onChange("value", newValues)
    }

    onAddNewValueEnabled = () => {
        this.setState(prevState => ({
            addNewValueEnabled: true
        }))
    }

    onAddNewValue = () => {
        this.onChange("value", {
            [this.state.addNewKey]: this.state.addNewValue,
            ...this.state.configItemRequest.value
        }
        );
        this.onClearAddNew();
    }

    onClearAddNew = () => {
        this.setState(prevState => ({
            addNewValueEnabled: false,
            addNewValue: '',
            addNewKey: ''
        }))
    }

    onAddNewValueInputChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            addNewValue: e.target.value
        }))
    }


    onAddNewKeyInputChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            addNewKey: e.target.value
        }))
    }

    render() {
        return (
            <>
                {this.state.configItemRequest &&
                    <div className="col-6" >
                        <div className="card border px-2">
                            <div className="card-header py-1 pl-0 border-bottom pr-0">
                                <span className="fsize15 fweight600">{this.state.configItemRequest.label}</span>
                                <div className="card-options">
                                    {!this.props.configItem.parentLocked &&
                                        <>
                                            <button onClick={() => this.onChange("overridden", !this.state.configItemRequest.overridden)} className="ml-0 fsize12 link"><i className={classNames('fa', { "fa-chain-broken": this.state.configItemRequest.overridden }, { "fa-chain": !this.state.configItemRequest.overridden })} /></button>
                                            <button onClick={() => this.onChange("locked", !this.state.configItemRequest.locked)} className="ml-1 fsize12 link"><i className={classNames('fa', { "fa-unlock": !this.state.configItemRequest.locked }, { "fa-lock": this.state.configItemRequest.locked })} ></i></button>
                                            {this.state.configItemRequest.locked &&
                                                <button onClick={() => this.onChange("hidden", !this.state.configItemRequest.hidden)} className="ml-1 fsize12 link"><i className={classNames('fa', { "fa-eye": !this.state.configItemRequest.hidden }, { "fa-eye-slash": this.state.configItemRequest.hidden })} ></i></button>
                                            }
                                            {this.state.configItemRequest.overridden && <button onClick={this.onAddNewValueEnabled} className="ml-2 fsize12 link"><i className="fa fa-plus" /></button>}
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="card-body">
                                <ul className="list-unstyled leading-loose">

                                    {this.state.addNewValueEnabled &&
                                        <div className="row">
                                            <div className="col-5">
                                                <input type="text" value={this.state.addNewKey} className="appConfig addnewKeyValueInput" onChange={this.onAddNewKeyInputChange} />
                                            </div>
                                            <div className="col-5">
                                                <input type="text" value={this.state.addNewValue} className="appConfig addnewKeyValueInput" onChange={this.onAddNewValueInputChange} />
                                            </div>
                                            <div className="col-2">
                                                <i onClick={this.onClearAddNew} className="fa fa-times-circle-o float-right  pl-2 pt-2"></i>
                                                <i onClick={this.onAddNewValue} className="fa fa-check float-right colorgreen pl-1 pt-2"></i>
                                            </div>
                                        </div>
                                    }
                                    {Object.keys(this.state.configItemRequest.value).map((key) =>

                                        <div key={key} className="row">
                                            <div className="col-5">{key}</div>
                                            <div className="col-5">{this.state.configItemRequest.value[key]}</div>
                                            <div className="col-2">
                                                {!this.props.configItem.parentLocked && this.state.configItemRequest.overridden &&
                                                    <i onClick={() => this.onDelete(key)} className="fa fa-close float-right colorred pt-1"></i>
                                                }
                                            </div>
                                        </div>
                                    )}

                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

class MultiLongListGroupValueConfigItem extends Component {
    constructor() {
        super();
        this.state = {
            configItemRequest: null,
            locked: false
        }
    }
    componentDidMount() {
        this.setState(prevState => ({
            configItemRequest: {
                ...this.props.configItem
            },
            locked: this.props.configItem.parentLocked
        }))
    }
    onChange = (fieldName, value) => {
        //console.log(fieldName);
        //console.log(value);
        if ("overridden" === fieldName) {
            let oldvalues = this.props.configItem.parentValue && value === false ? this.props.configItem.parentValue : this.props.configItem.value
            this.setState(prevState => ({
                configItemRequest: {
                    ...this.props.configItem,
                    "overridden": value,
                    "value": oldvalues
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        } else {
            this.setState(prevState => ({
                configItemRequest: {
                    ...prevState.configItemRequest,
                    [fieldName]: value
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        }
    }

    onValueChange = (propertyName, value) => {
        //console.log(propertyName + "  :  " + value);
        this.setState(prevState => ({
            configItemRequest: {
                ...prevState.configItemRequest,
                "value": {
                    ...prevState.configItemRequest.value,
                    [propertyName]: value
                }
            }
        }), () => {
            this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
        })
    }

    onConfigValueInputChange = (event, propertyName) => {
        event.persist();
        this.onValueChange(propertyName, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    }

    renderConfigurationValueItems = () => {
        return (
            <>
                {Object.keys(modulePermissionAttributes).map(key => {

                    return <td key={key}>
                        {typeof (this.state.configItemRequest.value[key]) !== 'undefined' &&
                            <label className="custom-control custom-checkbox">
                                <input type="checkbox" onClick={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event, key) }} onChange={(e) => () => { }} checked={this.state.configItemRequest.value[key]} disabled={this.state.locked} className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                            </label>
                        }
                    </td>

                })
                }
            </>
        )
    }
    render() {
        return (
            <>
                {this.state.configItemRequest &&
                    <tr>
                        <td>{this.state.configItemRequest.label}</td>
                        {this.renderConfigurationValueItems()}
                        <td>
                            {!this.props.configItem.parentLocked &&
                                <div className="d-flex">
                                    <span className="input-group-append locksymbol pr-2">
                                        <button onClick={() => this.onChange("overridden", !this.state.configItemRequest.overridden)} className="ml-0 fsize14  link"><i className={classNames('fa', { "fa-chain-broken": this.state.configItemRequest.overridden }, { "fa-chain": !this.state.configItemRequest.overridden })} /></button>
                                    </span>
                                    <span className="input-group-append locksymbol pr-2">
                                        <button onClick={() => this.onChange("locked", !this.state.configItemRequest.locked)} className="link"><i className={classNames('fa', { "fa-unlock": !this.state.configItemRequest.locked }, { "fa-lock": this.state.configItemRequest.locked })} ></i></button>
                                    </span>
                                    {this.state.configItemRequest.locked &&
                                        <span className="input-group-append locksymbol">
                                            <button onClick={() => this.onChange("hidden", !this.state.configItemRequest.hidden)} className="link"><i className={classNames('fsize12 fa', { "fa-eye": !this.state.configItemRequest.hidden }, { "fa-eye-slash": this.state.configItemRequest.hidden })} ></i></button>
                                        </span>
                                    }
                                </div>
                            }
                        </td>
                    </tr>
                }
            </>
        )
    }
}

class MultiLongListListValueConfigItem extends Component {
    constructor() {
        super();
        this.state = {
            configItemRequest: null,
            locked: false
        }
    }
    componentDidMount() {
        this.setState(prevState => ({
            configItemRequest: {
                ...this.props.configItem
            },
            locked: this.props.configItem.parentLocked
        }))
    }
    onChange = (fieldName, value) => {
        //console.log(fieldName);
        //console.log(value);
        if ("overridden" === fieldName) {
            let oldvalues = this.props.configItem.parentValue && value === false ? this.props.configItem.parentValue : this.props.configItem.value
            this.setState(prevState => ({
                configItemRequest: {
                    ...this.props.configItem,
                    "overridden": value,
                    "value": oldvalues
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        } else {
            this.setState(prevState => ({
                configItemRequest: {
                    ...prevState.configItemRequest,
                    [fieldName]: value
                }
            }), () => {
                this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
            })
        }
    }

    onValueChange = (propertyName, value) => {
        //console.log(propertyName + "  :  " + value);
        this.setState(prevState => ({
            configItemRequest: {
                ...prevState.configItemRequest,
                "value": {
                    ...prevState.configItemRequest.value,
                    [propertyName]: value
                }
            }
        }), () => {
            this.props.onChange(this.props.selectedKey, this.state.configItemRequest);
        })
    }

    onSelectMultiValueChange = (propertyName, value) => {
        this.onValueChange(propertyName, value ? value : []);
    }

    onConfigValueInputChange = (event, propertyName) => {
        event.persist();
        this.onValueChange(propertyName, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    }
    renderConfigurationValueItems = () => {
        return (
            <>
                {Object.keys(this.state.configItemRequest.value).map(key => {
                    return <li className="d-flex" key={key}>
                        <span>{commonUtils.convertToSentenceCase(key)}</span>
                        <span className="card-options">

                            {Array.isArray(this.state.configItemRequest.value[key]) &&
                                <span className="select_sm">
                                    <Select
                                        value={commonUtils.convertConfigListToSelectObject(this.state.configItemRequest.value[key])}
                                        onChange={(selectedOptions) => { if (this.state.configItemRequest.overridden) this.onSelectMultiValueChange(key, (selectedOptions ? selectedOptions.map(selectedOption =>
                                            selectedOption.value) : null))}}
                                        options={commonUtils.convertConfigListToSelectObject(this.props.configItem.parentValue[key] || this.props.configItem.value[key])}
                                        isSearchable={true}
                                        isClearable={false}
                                        classNamePrefix={'custom-select'}
                                        isMulti={true}
                                        menuPlacement={'auto'}
                                        isDisabled={!this.state.configItemRequest.overridden}
                                    />
                                </span>
                            }
                            {typeof (this.state.configItemRequest.value[key]) === 'boolean' &&

                                <label className="custom-control custom-checkbox">
                                    <input type="checkbox" onClick={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event, key) }} onChange={(e) => () => { }} checked={this.state.configItemRequest.value[key]} disabled={this.state.locked} className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                </label>
                            }
                            {typeof (this.state.configItemRequest.value[key]) === 'string' &&
                                <input type="text" value={this.state.configItemRequest.value[key]} className="width150 height25" onChange={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event, key) }} />
                            }

                            {typeof (this.state.configItemRequest.value[key]) === 'number' &&
                                <input type="number" value={this.state.configItemRequest.value[key]} className="width100 height25" onChange={(event) => { if (this.state.configItemRequest.overridden) this.onConfigValueInputChange(event, key) }} />
                            }

                        </span>
                    </li>
                })
                }
            </>
        )
    }

    render() {
        return (
            <>
                {this.state.configItemRequest &&
                    <div className="col-6" >
                        <div className="card border px-2">
                            <div className="card-header py-1 pl-0 border-bottom pr-0">
                                <span className="fsize13 fweight600">{this.state.configItemRequest.label}</span>
                                <div className="card-options">
                                    {!this.props.configItem.parentLocked &&
                                        <div className="d-flex">
                                            <span className="input-group-append locksymbol pr-2">
                                                <button onClick={() => this.onChange("overridden", !this.state.configItemRequest.overridden)} className="ml-0 fsize14 link"><i className={classNames('fa', { "fa-chain-broken": this.state.configItemRequest.overridden }, { "fa-chain": !this.state.configItemRequest.overridden })} /></button>
                                            </span>
                                            <span className="input-group-append locksymbol pr-2">
                                                <button onClick={() => this.onChange("locked", !this.state.configItemRequest.locked)} className="link"><i className={classNames('fa', { "fa-unlock": !this.state.configItemRequest.locked }, { "fa-lock": this.state.configItemRequest.locked })} ></i></button>
                                            </span>
                                            {this.state.configItemRequest.locked &&
                                                <span className="input-group-append locksymbol">
                                                    <button onClick={() => this.onChange("hidden", !this.state.configItemRequest.hidden)} className="link"><i className={classNames('fsize12 fa', { "fa-eye": !this.state.configItemRequest.hidden }, { "fa-eye-slash": this.state.configItemRequest.hidden })} ></i></button>
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="card-body appconfig-item">
                                <ul className="list-unstyled leading-loose">
                                    {this.renderConfigurationValueItems()}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    allEmployees: state.employees.allEmployees
});

const mapDispatchToProps = dispatch => ({
    getAllEmployees: employeeActions.getAllEmployees,
    updateEmployeeRoles: employeeActions.updateEmployeeRoles
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationConfigSection);
