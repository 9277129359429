import { invoiceConstants } from '../_constants';
import { invoiceService } from '../_services';
import { notificationActions } from './notification.actions';
import { commonActions } from './common.actions';
import { userUtils } from '_helpers';

export const invoiceActions = {
    getAllInvoices,
    getInvoiceInfo,
    createInvoice,
    updateInvoice,
    updateInvoiceStatus,
    removeInvoice
};

function getAllInvoices(requestObj, filterOptions) {
    return dispatch => {
        dispatch(request());
        invoiceService.getAllInvoices(requestObj)
            .then(
                invoices => dispatch(success(invoices.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: invoiceConstants.GETALL_INVOICES_REQUEST } }
    function success(invoices, filterOptions) { return { type: invoiceConstants.GETALL_INVOICES_SUCCESS, invoices, filterOptions } }
    function failure(error) { return { type: invoiceConstants.GETALL_INVOICES_FAILURE, error } }
}

function getInvoiceInfo(invoiceId, hasAccess) {
    return dispatch => {
        dispatch(request());
        invoiceService.getInvoiceInfo(invoiceId, hasAccess)
            .then(
                invoice => dispatch(success(invoice.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: invoiceConstants.GET_INVOICE_REQUEST } }
    function success(invoice) { return { type: invoiceConstants.GET_INVOICE_SUCCESS, invoice } }
    function failure(error) { return { type: invoiceConstants.GET_INVOICE_FAILURE, error } }
}


function createInvoice(invoiceRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(invoiceRequest));
        dispatch(notificationActions.clear());
        invoiceService.createInvoice(invoiceRequest)
            .then(
                invoiceResponse => {
                    dispatch(success(invoiceResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('Deliverable Creation successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)));
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));

                }
            );
    };

    function request(invoice) { return { type: invoiceConstants.CREATE_INVOICE_REQUEST, invoice } }
    function success(invoice, showModel) { return { type: invoiceConstants.CREATE_INVOICE_SUCCESS, invoice, showModel } }
    function failure(error) { return { type: invoiceConstants.CREATE_INVOICE_FAILURE, error } }
}
function updateInvoice(invoiceRequest) {
    return dispatch => {
        dispatch(request(invoiceRequest));
        dispatch(notificationActions.clear());
        invoiceService.updateInvoice(invoiceRequest)
            .then(
                invoiceResponse => {
                    dispatch(success(invoiceResponse.payload, invoiceRequest.id));
                    dispatch(notificationActions.success('Invoice update successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)))
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));
                }
            );
    };

    function request(invoice) { return { type: invoiceConstants.UPDATE_INVOICE_REQUEST, invoice } }
    function success(invoice, invoiceId) { return { type: invoiceConstants.UPDATE_INVOICE_SUCCESS, invoice, invoiceId } }
    function failure(error) { return { type: invoiceConstants.UPDATE_INVOICE_FAILURE, error } }
}


function updateInvoiceStatus(invoiceId, status) {
    return dispatch => {
        dispatch(request({ id: invoiceId }));
        dispatch(notificationActions.clear());
        var employeeId = userUtils.getCurrentUserId()
        invoiceService.updateInvoiceStatus(invoiceId, status, employeeId)
            .then(
                invoiceResponse => {
                    dispatch(success(invoiceResponse.payload, invoiceId));
                    dispatch(notificationActions.success('Invoice update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(invoice) { return { type: invoiceConstants.UPDATE_INVOICE_REQUEST, invoice } }
    function success(invoice, invoiceId) { return { type: invoiceConstants.UPDATE_INVOICE_SUCCESS, invoice, invoiceId } }
    function failure(error) { return { type: invoiceConstants.UPDATE_INVOICE_FAILURE, error } }
}
function getErrorMessage(error) {
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}


function removeInvoice(invoiceId) {
    return dispatch => {
        dispatch(request(invoiceId));

        invoiceService.removeInvoice(invoiceId)
            .then(
                invoiceResponse => dispatch(success(invoiceId)),
                error => {
                    dispatch(failure(invoiceId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(invoiceId) { return { type: invoiceConstants.REMOVE_INVOICE_REQUEST, invoiceId } }
    function success(invoiceId) { return { type: invoiceConstants.REMOVE_INVOICE_SUCCESS, invoiceId } }
    function failure(invoiceId, error) { return { type: invoiceConstants.REMOVE_INVOICE_FAILURE, invoiceId, error } }
}
