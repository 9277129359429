import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import dateUtils from "_helpers/date.utils";
import classNames from 'classnames/bind';
import SelectInput from "views/common/fragments/formfields/SelectInput";

class BidRequestsInfoLine extends Component {

  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }
  handleRemoveBidRequests = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveBidRequests(this.props.item.id);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (
      <tr>
        <td>
          {this.props.item.itemIdUserTeamPrefix}</td>
        <td>{dateUtils.convertDateFormat(this.props.item.createdDate)}</td>
        <td className="w-200">

          <span className="project-name-text pl-0 d-inline-block" title={this.props.item.studyName}>

            <NavLink
              to={`/sales/bidrequest/${this.props.item.itemIdUserTeamPrefix}`}
              className="nav-link list-b pl-0"
              activeClassName="active"
            >
              {this.props.item.studyName}
            </NavLink>
          </span></td>
        <td>{this.props.item.type}</td>
        <td>{this.props.item.studyTypes && this.props.item.studyTypes.map(studyType => <p className="p-0 m-0">{studyType}</p>)}</td>
        <td>{this.props.item.salesForceId}</td>
        <td className="w-200">{this.props.item.kindOfStudies && this.props.item.kindOfStudies.map(kindOfStudy => <p className="p-0 m-0">{kindOfStudy}</p>)}</td>
        <td className="">{this.props.item.countries && this.props.item.countries.map(country => <p className="p-0 m-0">{country}</p>)}</td>
        <td>
          <SelectInput
            options={[
              { value: "NEW", label: "New" },
              { value: "ASSIGNED", label: "Assinged" },
              { value: "INPROGRESS", label: "In Progress" },
              { value: "WON", label: "Won" },
              { value: "LOST", label: "Lost" },
              { value: "CANCELLED", label: "Canceled" },
              { value: "REJECTED", label: "Rejected" },
              { value: "CLOSED", label: "Closed" }]}
            value={this.props.item.status}
            allowLevelDown={this.props.permission.allowStatusLevelDown}
            changeableStatus={this.props.permission.changeableStatus}
            isReadOnly={(!this.props.permission.allowStatusChange) || this.props.readOnly}
            itemId={this.props.item.id}
            onChange={this.props.handleUpdateStatus}
            className={classNames("badge",
              { "badge-info": this.props.item.status === 'NEW' },
              { "badge-primary": this.props.item.status === 'ASSIGNED' },
              { "badge-warning": this.props.item.status === 'INPROGRESS' },
              { "badge-success": this.props.item.status === 'WON' },
              { "badge-secondary": this.props.item.status === 'LOST' },
              { "badge-danger": this.props.item.status === 'CANCELLED' },
              { "badge-dark": this.props.item.status === 'REJECTED' },
              { "badge-dark": this.props.item.status === 'CLOSED' }
            )} />
        </td>
        <td className="text-center">
          <span>
            {this.props.permission.archiveEnabled === true &&
              <label className=" custom-switch custom-switch-sm m-0">
                <input type="checkbox" onChange={e => { }}
                  onClick={() => this.props.handleArchiveBidRequests(this.props.item.id, !this.props.item.archived)}
                  className="custom-switch-input" checked={this.props.item.archived ? false : true} />
                <span className="custom-switch-indicator" />
              </label>
            }
            {this.props.permission.viewEnabled === true &&
              <button className="link pl-2" title="View"
                onClick={(e) => this.props.onViewBidRequests(this.props.item.id)}
              ><i className="fa fa-eye align-text-top fa-lg"></i></button>
            }
            {!this.props.item.archived && this.props.permission.editEnabled === true &&
              < button type="button" className="link pl-2" title="Edit"
                onClick={(e) => this.props.handleEditBidRequests(this.props.item.id, this.props.item.userTeamId)}
              ><i className="fa fa-edit" /></button>
            }
            {this.props.permission.duplicateEnabled === true &&
              < button type="button" className="link pl-2" title="Duplicate"
                onClick={(e) => this.props.handleDuplicateBidRequests(this.props.item.id, this.props.item.userTeamId)}
              ><i className="fa fa-clone" /></button>
            }
            {this.props.permission.deleteEnabled === true &&
              <button type="button"
                onClick={(e) => this.props.handleDeleteBidRequests(this.props.item.id, !this.props.item.deleted)}
                className="link pl-2" title={this.props.item.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
                {this.props.item.deleted &&
                  <>
                    <i className="fa fa-rotate-left colorred blink_text"></i>
                  </>
                }
                {!this.props.item.deleted &&
                  <>
                    <i className="fa fa-trash"></i>
                  </>
                }
              </button>
            }
            {this.props.permission.removeEnabled === true &&

              <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveBidRequests(this.state.confrimDelete)}>
                {this.state.confrimDelete === true &&
                  <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
                }
                {this.state.confrimDelete === false &&
                  <i className="dropdown-icon fa fa-times colorred"></i>
                }
              </button>
            }
          </span>
        </td>
      </tr >
    );
  }
}
export default BidRequestsInfoLine;