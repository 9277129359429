import React, { Component } from "react";
import CountUp from 'react-countup';
import { store } from "_helpers";
import { commonUtils } from "_helpers";

class ItemStatistics extends Component {

    componentDidMount() {
        store.dispatch(this.props.loadData(this.props.itemId))
    }

    getSum =(itemName)=>{
        let sum = 0;
        this.props.items && this.props.items.map(item => sum += item[itemName]);
        return sum;
    }

    render() {

        let completedCount = this.getSum("completedCount") 
        let inProgressCount = this.getSum("inProgressCount") 
        let newCount = this.getSum("newCount") 
        let assignedCount = this.getSum("assignedCount") 
        
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{this.props.label}</h3>
                </div>
                <div className="card-body">
                    <div className="row text-center col-12">
                        <div className="col-6 border-right pb-1 pt-1 px-0 mx-0">
                            <label className="mb-0">Completed</label>
                            <h4 className="font-weight-bold text-col-blue"><CountUp className=" fweight600" end={completedCount} /></h4>
                        </div>
                        <div className="col-6 pb-1 pt-1 px-0 mx-0">
                            <label className="mb-0">In Progress</label>
                            <h4 className="font-weight-bold text-col-blue"><CountUp className=" fweight600" end={inProgressCount} /></h4>
                        </div>

                        <div className="col-6 pb-1 pt-1 px-0 mx-0">
                            <label className="mb-0">New</label>
                            <h4 className="font-weight-bold text-col-blue"><CountUp className=" fweight600" end={newCount} /></h4>
                        </div>

                        <div className="col-6 pb-1 pt-1 px-0 mx-0">
                            <label className="mb-0">Assigned</label>
                            <h4 className="font-weight-bold text-col-blue"><CountUp className=" fweight600" end={assignedCount} /></h4>
                        </div>
                    </div>
                </div>
                {this.props.items &&
                    <div className="card-body px-3">
                        {this.props.items.map((item, index) =>
                            <div key={index} className="form-group">
                                <label className="d-block">
                                    <span className="d-inline-block text-truncate mt-0 align-middle w-200 fsize14" title={`${item.category || 'Other'}`}>{item.category || 'Other'}</span>
                                    <span className="float-right">{item.completedCount || 0 } / {item.inProgressCount || 0 }</span>
                                </label>
                                <div className="progress progress-xs progress-bar bg-danger">
                                    <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: `${commonUtils.getPercentageOfCompletion(item.completedCount, item.inProgressCount)}%` }}></div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </div>
        )
    }
}
export default ItemStatistics;
