import React, { Component } from "react";
import { connect } from "react-redux";
import CreateHolidayModel from "views/project/components/modal/CreateHolidayModel";
import { employeeActions } from "_actions";
import { employeeConstants } from "_constants";
import { commonUtils } from "_helpers";
import { accessUtils } from "_helpers";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";

class HolidayListTable extends Component {
    constructor() {
        super()
        this.state = {
            permission: accessUtils.getHolidayAccessControl(),
            minimize: true
        }
    }

    onCreateItemClick = () => {
        this.setState({ editMode: false });
        store.dispatch(employeeActions.getModuleConfig(commonUtils.getCurrentUserTeamId()));
        store.dispatch({ type: employeeConstants.SHOW_HOLIDAY_CREATE_WINDOW });
    }


    onCloseCreateItem = (e) => {
        store.dispatch({ type: employeeConstants.CLOSE_HOLIDAY_CREATE_WINDOW });
    }
    handleDeleteHoliday = ( holidayIdTobeDeleted, isDelete) => {

        store.dispatch(this.props.deleteHoliday(holidayIdTobeDeleted, isDelete));
    }

    handleEditHoliday = (holidayId) => {

        this.setState({ editMode: true });
        store.dispatch(this.props.getHolidayInfo(holidayId));
    }

    handleMinimizeHoliday = (minimize) => {
        this.setState({ minimize: minimize });
    }

    handleRemoveHoliday = (holidayId) => {
        store.dispatch(this.props.removeHoliday(holidayId));
    }

    handleSubmit = (holidayRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
        if (isEdtiMode) {
            store.dispatch(this.props.updateHoliday(holidayRequest));
        } else {
            store.dispatch(this.props.createHoliday(holidayRequest, isCreateAnotherEnabled, callbackFunction));
        }
    }
    render() {
        return (
            <div className="col-lg-12">
                <div className={`card mb-1 ${this.state.minimize ? 'card-collapsed' : ""}`}>
                    <div className="card-header p-0 m-0 separator pt-2">
                        <div className="fsize15 text-center">
                            Organization Holidays
                        </div>
                        <div className="card-options">
                            {!this.state.minimize &&
                                <span className="card-options-remove">
                                    <button className="link" onClick={() => this.onCreateItemClick()}>
                                        <i className="fe fe-plus colorblue"></i>
                                    </button>
                                </span>
                            }
                            <span className="card-options-collapse pl-2" onClick={() => this.handleMinimizeHoliday(!this.state.minimize)}><i className="fe fe-chevron-up" />
                            </span>

                        </div>
                    </div>
                    {!this.state.minimize &&
                        <div className="card-body  pt-1">
                            {this.props.showCreateModel &&
                                <CreateHolidayModel
                                    onCloseCreateItem={this.onCloseCreateItem}
                                    show={this.props.showCreateModel}
                                    editMode={false}
                                    selectedHolidayInfo={this.props.selectedHolidayInfo}
                                    handleSubmit={this.handleSubmit}
                                    taskInfo={this.props.taskInfo}
                                />
                            }
                            {this.props.items && this.props.items.map(item =>
                                <HolidayItemInfo
                                    key={item.id}
                                    item={item}
                                    permission={this.state.permission}
                                    removeHoliday={this.handleRemoveHoliday}
                                />
                            )}

                            {(!this.props.items || this.props.items.length === 0) &&
                                <div className="text-center text-muted">
                                    No Hollidays
                                </div>
                            }
                        </div>
                    }
                   
                </div>
                <hr />
            </div>
        );
    }
}

class HolidayItemInfo extends Component {
    constructor() {
        super();
        this.state = {
            confrimDelete: false
        }
    }
    handleRemoveHoliday = (isConfrimDelete) => {
        if (isConfrimDelete) {
            this.props.removeHoliday(this.props.item.id);
        } else {
            this.setState({
                confrimDelete: true
            })
        }
    }

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center border-bottom" key={this.props.item.id}>
                <span className="w-75p">

                    <span className="fweight600 fsize14"> <i className="fe fe-disc mr-1" data-toggle="tooltip"></i>{this.props.item.name}</span>
                    {this.props.item.description &&
                        <span className="text-muted d-block fsize12">{this.props.item.description}</span>
                    }
                    <address className="fsize13 ml-2"> {this.props.item.locations && this.props.item.locations.map(location =>
                        <span key={location} className="pr-2"><i className="fa fa-map-marker pr-1" />{location}</span>
                    )}</address>
                </span>
                <span>
                    <span className="text-muted">{dateUtils.convertDateToMoment(this.props.item.holidayDate).format("DD-MMM")}</span>
                    <span className="">
                        {this.props.permission.removeEnabled === true &&

                            <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveHoliday(this.state.confrimDelete)}>
                                {this.state.confrimDelete === true &&
                                    <i className="dropdown-icon fe fe-check colorred blink_text"></i>
                                }
                                {this.state.confrimDelete === false &&
                                    <i className="dropdown-icon fe fe-x-circle colorred"></i>
                                }
                            </button>
                        }
                    </span>
                </span>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    items: state.employees.holidayItems,
    showCreateModel: state.employees.showHolidayCreateModel
});

const mapDispatchToProps = dispatch => ({
    getHolidays: employeeActions.getHolidayLeavesForCalendar,
    createHoliday: employeeActions.createHolidayItem,
    removeHoliday: employeeActions.removeHolidayItem,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HolidayListTable)