
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const purchaseOrderService = {
    createPurchaseOrder,
    updatePurchaseOrder,
    updatePurchaseOrderStatus,
    removePurchaseOrder,
    archivePurchaseOrder,
    deletePurchaseOrder,
    getPurchaseOrderList,
    importAndGetPurchaseOrderList,
    searchPurchaseOrderList,
    getPurchaseOrderInfo
};

async function getPurchaseOrderList(request) {
    let params = request.startDate ? `&employeeIds=${request.currentUserId}&startDate=${request.startDate}&endDate=${request.endDate}` : '';
    return await axiosUtils.get(urlConfig.PURCHASE_ORDER_PATH + `?userTeamIds=${request.userTeamIds}` + params);
 }

 async function importAndGetPurchaseOrderList(request) {
   let params = request.startDate ? `&startDate=${request.startDate}&endDate=${request.endDate}` : '';
   return await axiosUtils.get(urlConfig.PURCHASE_ORDER_PATH + `/salesforce?userTeamIds=${request.userTeamIds}` + params);
}

 async function searchPurchaseOrderList(request) {
    return await axiosUtils.post(urlConfig.PURCHASE_ORDER_PATH + `/search?userTeamIds=${request.userTeamIds}`, request.projectSearchRequest);
 }

async function getPurchaseOrderInfo(purchaseOrderId) {
   return await axiosUtils.get(urlConfig.PURCHASE_ORDER_PATH + `/${purchaseOrderId}`);
}

async function createPurchaseOrder(purchaseOrderRequest) {
    return await axiosUtils.post(urlConfig.PURCHASE_ORDER_PATH,purchaseOrderRequest);
}

async function updatePurchaseOrder(purchaseOrderRequest) {
    return await axiosUtils.put(urlConfig.PURCHASE_ORDER_PATH,purchaseOrderRequest);
}

async function updatePurchaseOrderStatus(purchaseOrderId, status, currentUserId) {
    return await axiosUtils.put(urlConfig.PURCHASE_ORDER_PATH + `/status/${purchaseOrderId}/${status}?employeeId=${currentUserId}`);
 }

async function deletePurchaseOrder(purchaseOrderId, isDelete) {
   return await axiosUtils.remove(urlConfig.PURCHASE_ORDER_PATH+`/${purchaseOrderId}/${isDelete}`);
}

async function removePurchaseOrder(purchaseOrderId) {
    return await axiosUtils.remove(urlConfig.PURCHASE_ORDER_PATH+`/${purchaseOrderId}`);
 }

 async function archivePurchaseOrder(purchaseOrderId, isArchive) {
    return await axiosUtils.put(urlConfig.PURCHASE_ORDER_PATH+`/${purchaseOrderId}/${isArchive}`);
 }