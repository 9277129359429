
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const invoiceService = {
    getAllInvoices,
    getInvoiceInfo,
    createInvoice,
    updateInvoice,
    updateInvoiceStatus,
    removeInvoice
};

async function getAllInvoices() {
    return await axiosUtils.get(urlConfig.INVOICE_PATH + "/list/12");
}

async function getInvoiceInfo(invoiceId, hasAccess) {
    return await axiosUtils.get((hasAccess ? urlConfig.DOCS_INVOICE_PATH : urlConfig.INVOICE_PATH)+ `/${invoiceId}`);
}

async function createInvoice(invoiceRequest) {
    return await axiosUtils.post(urlConfig.INVOICE_PATH, invoiceRequest);
}

async function updateInvoice(invoiceRequest) {
    return await axiosUtils.put(urlConfig.INVOICE_PATH, invoiceRequest);
}
async function updateInvoiceStatus(invoiceId, status, employeeId) {
    return await axiosUtils.put(urlConfig.INVOICE_PATH + `/status/${invoiceId}/${status}?employeeId=`+ employeeId);
}

async function removeInvoice(invoiceId) {
    return await axiosUtils.remove(urlConfig.INVOICE_PATH + `/${invoiceId}`);
}
