import React, { Component } from "react";
import { connect } from "react-redux";
import { purchaseOrderActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import PurchaseOrderDetailsGeneral from "./components/PurchaseOrderDetailsGeneral";
import PurchaseOrderDetailsHeader from "./components/PurchaseOrderDetailsHeader";
import ItemStatistics from "./components/fragments/ItemStatistics";
import { dashboardActions } from "_actions";
import { accessUtils } from "_helpers";
import ProjectListPageTableSection from "./components/ProjectListPageTableSection";
import ViewProjectDetailsSidebar from "./components/modal/ViewProjectDetailsSidebar";
import { projectConstants } from "_constants";
import { projectActions } from "_actions";

class PurchaseOrderDetailsPage extends Component {
    constructor() {
        super()
        this.state = {
            selectedMenuName: "details",
            permission: accessUtils.getPurchaseOrderAccessControl(),
            permissionProjects: accessUtils.getProjectAccessControl(),
        };

    }
    componentDidMount() {

        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name + " : " + this.props.match.params.purchaseOrderId)
        if (!this.props.purchaseOrderInfo || this.props.purchaseOrderInfo.id !== this.props.match.params.purchaseOrderId) {
            let purchaseOrderId = commonUtils.removeNameIntial(this.props.match.params.purchaseOrderId);
            store.dispatch(this.props.getPurchaseOrderInfo(purchaseOrderId));
            this.setState({
                purchaseOrderId: purchaseOrderId
            })
        }
    }
    onCloseViewProject = () => {
        store.dispatch({ type: projectConstants.CLOSE_PROJECT_VIEW_WINDOW });
    }
    onSelectMenu = (menuName) => {
        this.setState({
            selectedMenuName: menuName
        })
        if (menuName === 'projects') {
            this.invokeProjectListService();
        }
    }

    invokeProjectListService = () => {
        store.dispatch(this.props.getProjectsByPurchaseOrder({ "userTeamIds": this.state.permission.showAllPurchaseOrderProjects === false ? commonUtils.getCurrentUserTeamIds() : [], "purchaseOrderNumber": this.props.purchaseOrderInfo.id , currentUserId:commonUtils.getCurrentUserId()}))
    }

    render() {
        return (
            <div className="projectDetails">
                {this.props.purchaseOrderInfo && commonUtils.hasAccess(this.props.purchaseOrderInfo, this.state.permission.allowAccessToOtherUserTeamPurchaseOrder) &&
                    <PurchaseOrderDetailsHeader
                        onSelectMenu={this.onSelectMenu}
                    />
                }
                <div className="section-body mt-0">
                    <div className="container-fluid px-0">
                        {!this.props.isLoading && this.props.purchaseOrderInfo &&
                            <>
                                {this.state.selectedMenuName === 'projects' ?
                                    <ProjectListPageTableSection
                                        items={this.props.projectItems}
                                        isLoading={this.props.loadingPOProjects}
                                        invokeProjectListService={this.props.invokeProjectListService}
                                        permission={{ ...this.state.permissionProjects,
                                             editEnabled: false,
                                              duplicateEnabled: false }}
                                    />
                                    :
                                    <div className="row mx-0 taslDetailsSection pt-1 border-top">
                                        <div className="col-lg-9 px-1">
                                            <PurchaseOrderDetailsGeneral 
                                                item={this.props.purchaseOrderInfo}
                                                permission={this.state.permission}
                                                readOnly={(!commonUtils.hasAccess(this.props.purchaseOrderInfo, this.state.permission.allowAccessToOtherUserTeamPurchaseOrder)) || this.props.purchaseOrderInfo.deleted}
                                            />
                                        </div>
                                        <div className="col-lg-3 sidemenu border-left px-0">
                                            <ItemStatistics
                                                label="Project Statistics"
                                                items={this.props.projectStatisticsByCategory}
                                                loadData={this.props.getProjectStatisticsByCategory}
                                                itemId={this.props.purchaseOrderInfo.purchaseOrderNumber}
                                            />
                                        </div>
                                    </div>

                                }
                            </>
                        }
                    </div>
                </div>
                {!this.props.purchaseOrderInfo && !this.props.isLoading &&
                    <div className="text-center pt-3">
                        <img src="/assets/images/custom/notasks-min.PNG" alt="No Tasks"/>
                        <span className="d-block fsize15"> No Purchase Order details found ?</span>
                        <span className="d-block fsize12 text-muted">You may not have enough privilleage to view the deliverable details
                        </span>
                        <span className="d-block fsize12 text-muted">
                            or Deliverable does not exist</span>
                    </div>
                }
                <ViewProjectDetailsSidebar show={this.props.isShowProjectViewSidebar} onCloseViewProject={this.onCloseViewProject} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    purchaseOrderInfo: state.purchaseOrders.selectedPurchaseOrderInfo,
    isLoading: state.purchaseOrders.loading,
    projectStatisticsByCategory: state.dashboard.projectStatisticsByCategory,
    isShowProjectViewSidebar: state.projects.isShowProjectViewSidebar,
    projectItems: state.projects.projectItems,
    loadingPOProjects:state.projects.loadingPOProjects
});
const mapDispatchToProps = dispatch => ({
    getPurchaseOrderInfo: purchaseOrderActions.getPurchaseOrderInfo,
    getProjectStatisticsByCategory: dashboardActions.getProjectStatisticsByCategory,
    getProjectsByPurchaseOrder: projectActions.getProjectsByPurchaseOrder
});
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDetailsPage);
