
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const accountService = {
    getProjectTeamAccounts,
    getProjectAllAccounts,
    getChildAccountsByIdAndTypeAndExcludeUserTeam,
    createAccountItem,
    updateAccountItem,
    removeAccountItem,
    detachFromAccountItem,
    addToAccountItem
};

async function getProjectTeamAccounts(request) {
   return await axiosUtils.get(urlConfig.PROJECT_ACCOUNT_ALL_BY_USER_TEAM + `?userTeamIds=${request.userTeamIds}`);
}

async function getProjectAllAccounts() {
   return await axiosUtils.get(urlConfig.PROJECT_ACCOUNT_ALL);
}

async function getChildAccountsByIdAndTypeAndExcludeUserTeam(request){
   return await axiosUtils.get(urlConfig.PROJECT_ACCOUNT_SUB_BY_EXCLUDE_USER_TEAM + `/${request.parentId}?userTeamIds=${request.userTeamIds}&type=${request.type}&excludeUserTeams=${request.isExcludeUserTeams}&onlyForUserTeams=${request.isOnlyForUserTeams}&isAll=true`);
}

async function createAccountItem(accountRequest) {
   return await axiosUtils.post(urlConfig.PROJECT_ACCOUNTS_API_PATH,accountRequest);
}

async function updateAccountItem(accountRequest) {
   return await axiosUtils.put(urlConfig.PROJECT_ACCOUNTS_API_PATH,accountRequest);
}

async function removeAccountItem(itemId) {
   return await axiosUtils.remove(urlConfig.PROJECT_ACCOUNTS_API_PATH+`/${itemId}`);
}

async function detachFromAccountItem(itemId, userTeamId, parentItemId) {
  return await axiosUtils.put(urlConfig.PROJECT_ACCOUNT_DETACH_FROM_TEAM+`/${itemId}/${userTeamId}/${parentItemId}`);
}

async function addToAccountItem(itemId, userTeamId, parentItemId) {
   return await axiosUtils.put(urlConfig.PROJECT_ACCOUNTS_API_PATH+`/${itemId}/${userTeamId}/${parentItemId}`);
}

