import React, { Component } from "react";
import { connect } from "react-redux";
import { appConfigActions } from "_actions";
import { store } from "_helpers";
import ApplicationConfigSection from "./components/ApplicationConfigSection";

import ApplicationConfigLeftMenu from "./components/ApplicationConfigLeftMenu";
import { commonUtils } from "_helpers";

class ApplicationConfigPage extends Component {
    constructor() {
        super();
        this.state = {
            appConfigRequest: null,
        }
    }

    componentDidMount() {
        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
        store.dispatch(this.props.initializeConfig(commonUtils.getCurrentUserTeamId()));
    }

    onAppConfigItemChange = (value) => {
        this.setState(prevState => ({
            appConfigRequest: {
                ...value
            }
        }))
    }

    handleAppConfigMenuSelect = (id, type) => {
        store.dispatch(this.props.configMenuChange(id, type));
        store.dispatch(this.props.getAppConfig(id, type));
    }

    handleCreateAppConfigItem = (value, parentId) => {
        //console.log(value + parentId);
        store.dispatch(this.props.createNewConfig(this.props.appConfigMenuSelection.type, value, parentId));
    }

    handleUpdateAppConfigItem = (configRequest) => {
        //console.log(configRequest);
        store.dispatch(this.props.updateConfig(configRequest, this.props.appConfigMenuSelection.id));
    }

    handleSaveConfigProperty = (propertyName, propertyValue) => {
        store.dispatch(this.props.updateConfigProperty(this.props.appConfigMenuSelection.id, propertyName, propertyValue));
    }

    render() {
        return (
            <div className="applicationconfig">
                <div className="section-body mt-1">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-3 col-md-12 pr-0">
                                <ApplicationConfigLeftMenu 
                                    type="org" 
                                    header="Organization Config" 
                                    handleAppConfigMenuSelect={this.handleAppConfigMenuSelect} 
                                    menuData={this.props.configMenuData && this.props.configMenuData.org} 
                                    appConfigMenuSelection={this.props.appConfigMenuSelection} 
                                    handleCreateAppConfigItem={this.handleCreateAppConfigItem} />
                                <ApplicationConfigLeftMenu 
                                    type="role" 
                                    header="Role And Permissions" 
                                    handleAppConfigMenuSelect={this.handleAppConfigMenuSelect} 
                                    menuData={this.props.configMenuData && this.props.configMenuData.role} 
                                    appConfigMenuSelection={this.props.appConfigMenuSelection} 
                                    handleCreateAppConfigItem={this.handleCreateAppConfigItem} />
                              </div>
                            <div className="col-lg-9 col-md-12">
                                <div className="row clearfix">
                                  <div className="col-lg-12 col-md-12">   
                                  {
                                  this.props.selectedConfigData && 
                                    <ApplicationConfigSection 
                                        label={this.props.appConfigMenuSelection && this.props.appConfigMenuSelection.type === 'role'?"Role & Permissions":"Organisation"}
                                        roles={this.props.configMenuData && this.props.configMenuData.role} 
                                        data={this.props.selectedConfigData}
                                        onChange={()=>{}} 
                                        menuSelection={this.props.appConfigMenuSelection}
                                        handleUpdateAppConfigItem={this.handleUpdateAppConfigItem}
                                        parentMenuOptions={this.props.parentMenuOptions}
                                        handleSaveConfigProperty={this.handleSaveConfigProperty}
                                        />
                                  }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    configMenuData: state.appconfig.configMenuData,
    configData: state.appconfig.configData,
    selectedConfigData: state.appconfig.selectedConfigData,
    appConfigMenuSelection : state.appconfig.appConfigMenuSelection,
    parentMenuOptions : state.appconfig.parentMenuOptions
});

const mapDispatchToProps = dispatch => ({
    initializeConfig: appConfigActions.initializeConfig,
    createNewConfig: appConfigActions.createNewConfig,
    updateConfig: appConfigActions.updateConfig,
    getAppConfig: appConfigActions.getAppConfig,
    configMenuChange: appConfigActions.configMenuChange,
    updateConfigProperty: appConfigActions.updateConfigProperty
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationConfigPage);
