import { commonUtils } from "./common.utils";

export const accessUtils = {
    reload,
    getEmployeeAttendanceAccessControl,
    getMenuAccessControl,
    getEmployeeAccessControl,
    getInvoiceAccessControl,
    getUserTeamsAccessControl,
    getAccountsAccessControl,
    getProjectAccessControl,
    getPurchaseOrderAccessControl,
    getTaskAccessControl,
    getWorkItemAccessControl,
    getTimeSheetAccessControl,
    getTaskBoardAccessControl,
    getWorkItemsCalendarAccessControl,
    getReportAccessControl,
    getHolidayAccessControl,
    getUserCalendarAccessControl,
    getTaskPlannerAccessControl,
    getBidRequestsAccessControl,
    getChecklistAccessControl
}
let accessControlJson = null;

function reload() {
    let accessControlItem = localStorage.getItem('ac')
    let key = localStorage.getItem('authToken')
    if (accessControlItem != null) {
        accessControlJson = JSON.parse(commonUtils.decryptString(accessControlItem, key));
    }
}
function getAccessControll(name) {
    if (accessControlJson == null) {
        reload();
    }

    return accessControlJson && accessControlJson[name] ? accessControlJson[name] : {};
}


function getMenuAccessControl() {
    let HRMSModuleEnabled = getAccessControll("HRMSModuleEnabled");
    let SALESModuleEnabled = getAccessControll("SALESModuleEnabled");
    let PROJECTModuleEnabled = getAccessControll("PROJECTModuleEnabled");
    let hrmsModules = getAccessControll("hrmsModules");
    let projectModules = getAccessControll("projectModules");
    let salesModules = getAccessControll("salesModules");
    return {
        "hrmsModules": {
            "active": HRMSModuleEnabled,
            "defaultRedirectPage": hrmsModules.defaultRedirectPage,
            "dashboardEnabled": hrmsModules.dashboardEnabled,
            "employeesEnabled": hrmsModules.employeesEnabled,
            "attendanceEnabled": hrmsModules.attendanceEnabled,
            "holidaysEnabled": hrmsModules.holidaysEnabled,
            "timesheetEnabled": hrmsModules.timesheetEnabled,
            "settingsEnabled": hrmsModules.settingsEnabled,
            "appconfigEnabled": hrmsModules.appconfigEnabled,
            "profileEnabled": hrmsModules.profileEnabled,
            "invoiceEnabled": hrmsModules.invoiceEnabled
        },
        "projectModules": {
            "active": PROJECTModuleEnabled,
            "defaultRedirectPage": projectModules.defaultRedirectPage,
            "dashboardEnabled": projectModules.dashboardEnabled,
            "listEnabled": projectModules.listEnabled,
            "taskplannerEnabled": projectModules.taskPlannerEnabled,
            "todolistEnabled": projectModules.todoListEnabled,
            "accountsEnabled": projectModules.accountsEnabled,
            "taskboardEnabled": projectModules.taskBoardEnabled,
            "activitesEnabled": projectModules.activitesEnabled,
            "reportsEnabled": projectModules.reportsEnabled,
            "filemanagerEnabled": false,//projectModules.fileManagerEnabled,
            "chatEnabled": false,//projectModules.chatEnabled,
            "calendarEnabled": projectModules.calendarEnabled,
            "detailsEnabled": projectModules.detailsEnabled,
            "searchEnabled": projectModules.searchEnabled,
            "rplannerEnabled": projectModules.resourcePlannerEnabled,
        },
        
        "salesModules": {
            "active": SALESModuleEnabled,
            "defaultRedirectPage": salesModules.defaultRedirectPage,
            "bidrequestsEnabled": salesModules.bidRequestsEnabled,
            "purchaseordersEnabled": salesModules.purchaseOrdersEnabled
        }

    }
}
function getEmployeeAttendanceAccessControl() {
    let attendance = getAccessControll("attendance");
    let attendanceAdditional = getAccessControll("attendanceAdditional");
    return {
        "createEnabled": attendance.createEnabled,
        "deleteEnabled": attendance.deleteEnabled,
        "viewEnabled": attendance.viewEnabled,
        "switchUserTeamsEnabled": attendanceAdditional.switchUserTeamsEnabled
    }
}
function getEmployeeAccessControl() {
    let employees = getAccessControll("employees");
    let employeesAdditional = getAccessControll("employeesAdditional");
    return {
        "createEnabled": employees.createEnabled,
        "editEnabled": employees.editEnabled,
        "deleteEnabled": employees.deleteEnabled,
        "archiveEnabled": employees.archiveEnabled,
        "removeEnabled": employees.removeEnabled,
        "viewEnabled": employees.viewEnabled,
        "switchUserTeamsEnabled": employeesAdditional.switchUserTeamsEnabled,
        "updateUserExitDateEnabled": employeesAdditional.updateUserExitDateEnabled,
    }
}

function getInvoiceAccessControl() {
    let invoice = getAccessControll("invoice");
    return {
        "createEnabled": invoice.createEnabled,
        "editEnabled": invoice.editEnabled,
        "removeEnabled": invoice.removeEnabled,
        "viewEnabled": invoice.viewEnabled
    }
}

function getHolidayAccessControl() {
    let holidays = getAccessControll("holidays");
    return {
        "createEnabled": holidays.createEnabled,
        "editEnabled": holidays.editEnabled,
        "removeEnabled": holidays.removeEnabled,
    }
}
function getProjectAccessControl() {
    let projects = getAccessControll("projects");
    let projectsAdditional = getAccessControll("projectsAdditional");
    return {
        "createEnabled": projects.createEnabled,
        "editEnabled": projects.editEnabled,
        "duplicateEnabled": projects.duplicateEnabled,
        "deleteEnabled": projects.deleteEnabled,
        "removeEnabled": projects.removeEnabled,
        "archiveEnabled": projects.archiveEnabled,
        "viewEnabled": projects.viewEnabled,
        "switchUserTeamsEnabled": projectsAdditional.switchUserTeamsEnabled,
        "allowStatusChange": projectsAdditional.allowStatusChange,
        "allowStatusLevelDown": projectsAdditional.allowStatusLevelDown,
        "changeableStatus": projectsAdditional.changeableStatus,
        "listOnlyCurrentTeamProject": projectsAdditional.listOnlyCurrentTeamProject,
        "viewAccessToOtherTeamProjectDeliverables": true,
        "createAccessToOtherTeamProjectDeliverables": true,
        "viewServiceLineEstimate": projectsAdditional.viewServiceLineEstimate,
        "enableProjectPurchaseOrderEditable": projectsAdditional.enableProjectPurchaseOrderEditable,

        "projectDatesRestrictedByPurchaseOrderDates": projectsAdditional.projectDatesRestrictedByPurchaseOrderDates,

        "enableServiceLineEstimateHourEditable": projectsAdditional.enableServiceLineEstimateHourEditable,
        "enableServiceLineEstimateHourVisible": projectsAdditional.enableServiceLineEstimateHourVisible,
        "enableServiceLineEstimateCostEditable": projectsAdditional.enableServiceLineEstimateCostEditable,
        "enableServiceLineEstimateCostVisible": projectsAdditional.enableServiceLineEstimateCostVisible,


        "showOnlyActiveUserTeamsInCreateWindow": projectsAdditional.showOnlyActiveUserTeamsSelectableInProjectCreateWindow,
        "showAllUserTeamsSelectableInProjectCreateWindow": projectsAdditional.showAllUserTeamsSelectableInProjectCreateWindow,
        "allowAccessToOtherUserTeamProjects": projectsAdditional.allowAccessToOtherUserTeamProjects,

        "checklistEnabled": projectsAdditional.checklistEnabled,
        "checklistSaveEnabled": projectsAdditional.checklistSaveEnabled,
        "checklistUpdateCompletedEnabled": projectsAdditional.checklistUpdateCompletedEnabled,
        "checklistCompleteEnabled": projectsAdditional.checklistCompleteEnabled,
        "checklistChangeEnabled": projectsAdditional.checklistChangeEnabled,

        "resourceLevelProjectHoursAllocationEnabled": projectsAdditional.resourceLevelProjectHoursAllocationEnabled,

    }
}
function getChecklistAccessControl() {
    let checklists = getAccessControll("checklists");
    return {
        "viewEnabled": checklists.viewEnabled,
        "createEnabled": checklists.createEnabled,
        "editEnabled": checklists.editEnabled,
        "duplicateEnabled": checklists.duplicateEnabled,
        "removeEnabled": checklists.removeEnabled,
    }
}
function getPurchaseOrderAccessControl() {
    let purchaseOrders = getAccessControll("purchaseOrders");
    let purchaseOrdersAdditional = getAccessControll("purchaseOrdersAdditional");
    return {
        "createEnabled": purchaseOrders.createEnabled,
        "editEnabled": purchaseOrders.editEnabled,
        "duplicateEnabled": purchaseOrders.duplicateEnabled,
        "deleteEnabled": purchaseOrders.deleteEnabled,
        "removeEnabled": purchaseOrders.removeEnabled,
        "archiveEnabled": purchaseOrders.archiveEnabled,
        "viewEnabled": purchaseOrders.viewEnabled,

        "enableServiceLineEstimateHourEditable": purchaseOrdersAdditional.enableServiceLineEstimateHourEditable,
        "enableServiceLineEstimateHourVisible": purchaseOrdersAdditional.enableServiceLineEstimateHourVisible,
        "enableServiceLineEstimateCostEditable": purchaseOrdersAdditional.enableServiceLineEstimateCostEditable,
        "enableServiceLineEstimateCostVisible": purchaseOrdersAdditional.enableServiceLineEstimateCostVisible,


        "switchUserTeamsEnabled": purchaseOrdersAdditional.switchUserTeamsEnabled,
        "allowStatusChange": purchaseOrdersAdditional.allowStatusChange,
        "allowStatusLevelDown": purchaseOrdersAdditional.allowStatusLevelDown,
        "changeableStatus": purchaseOrdersAdditional.changeableStatus,
        "allowAccessToOtherUserTeamPurchaseOrder": purchaseOrdersAdditional.allowAccessToOtherUserTeamPurchaseOrder,
        "showAllPurchaseOrderProjects": purchaseOrdersAdditional.showAllPurchaseOrderProjects,

        "checklistEnabled": purchaseOrdersAdditional.checklistEnabled,
        "checklistSaveEnabled": purchaseOrdersAdditional.checklistSaveEnabled,
        "checklistUpdateCompletedEnabled": purchaseOrdersAdditional.checklistUpdateCompletedEnabled,
        "checklistCompleteEnabled": purchaseOrdersAdditional.checklistCompleteEnabled,
        "checklistChangeEnabled": purchaseOrdersAdditional.checklistChangeEnabled
    }
}

function getTaskAccessControl() {
    let tasks = getAccessControll("tasks");
    let tasksAdditional = getAccessControll("tasksAdditional");
    return {
        "createEnabled": tasks.createEnabled,
        "editEnabled": tasks.editEnabled,
        "duplicateEnabled": tasks.duplicateEnabled,
        "deleteEnabled": tasks.deleteEnabled,
        "removeEnabled": tasks.removeEnabled,
        "archiveEnabled": tasks.archiveEnabled,
        "viewEnabled": tasks.viewEnabled,

        "enableDeliverableEstimateHourEditable": tasksAdditional.enableDeliverableEstimateHourEditable,

        "switchUserTeamsEnabled": tasksAdditional.switchUserTeamsEnabled,
        "allowStatusChange": tasksAdditional.allowStatusChange,
        "allowStatusLevelDown": tasksAdditional.allowStatusLevelDown,
        "changeableStatus": tasksAdditional.changeableStatus,
        "showOnlyActiveUserTeamsInCreateWindow": tasksAdditional.showOnlyActiveUserTeamsSelectableInDeliverableCreateWindow,
        "allowAccessToOtherUserTeamDeliverables": tasksAdditional.allowAccessToOtherUserTeamDeliverables,

        "checklistEnabled": tasksAdditional.checklistEnabled,
        "checklistSaveEnabled": tasksAdditional.checklistSaveEnabled,
        "checklistUpdateCompletedEnabled": tasksAdditional.checklistUpdateCompletedEnabled,
        "checklistCompleteEnabled": tasksAdditional.checklistCompleteEnabled,
        "checklistChangeEnabled": tasksAdditional.checklistChangeEnabled
    }
}
function getWorkItemAccessControl() {
    let workItems = getAccessControll("workItems");
    let workItemsAdditional = getAccessControll("workItemsAdditional");
    let timeSheet = getAccessControll("timeSheet");
    return {
        "createEnabled": workItems.createEnabled,
        "editEnabled": workItems.editEnabled,
        "duplicateEnabled": workItems.duplicateEnabled,
        "deleteEnabled": workItems.deleteEnabled,
        "removeEnabled": workItems.removeEnabled,
        "archiveEnabled": workItems.archiveEnabled,
        "viewEnabled": workItems.viewEnabled,

        "editCompletedWorkItemInfo": workItemsAdditional.editCompletedWorkItemInfo,
        "editCompletedWorkItemHours": workItemsAdditional.editCompletedWorkItemHours,
        "editCompletedWorkItemEstimatedHours": workItemsAdditional.editCompletedWorkItemEstimatedHours,
        "viewWorkItemEstimatedHours": workItemsAdditional.viewWorkItemEstimatedHours,
        "enableWorkItemDeleteTillNextDayXHour": workItemsAdditional.enableWorkItemDeleteTillNextDayXHour,
        "enableCompletedWorkItemHoursEditTillNextDayXHour": workItemsAdditional.enableCompletedWorkItemHoursEditTillNextDayXHour,

        "switchUserTeamsEnabled": workItemsAdditional.switchUserTeamsEnabled,
        "restrictedBySkillsEnabled": workItemsAdditional.restrictedBySkillsEnabled,
        "switchEmployeesEnabled": workItemsAdditional.switchEmployeesEnabled,
        "createWorkItemLogForOtherEmployees": workItemsAdditional.createWorkItemLogForOtherEmployees,
        "enableCreateCompletedWorkItem": workItemsAdditional.enableCreateCompletedWorkItem,
        "maxHoursAllowedToEnter": workItemsAdditional.maxHoursAllowedToEnter,
        "disableWorkItemCreateBeforeYWorkingDaysAfterXHours": workItemsAdditional.disableWorkItemCreateBeforeYWorkingDaysAfterXHours,


        "allowLeaveAndHolidayForFuture": workItemsAdditional.allowLeaveAndHolidayForFuture,
        "allowLeaveAndHolidayForPast": workItemsAdditional.allowLeaveAndHolidayForPast,
        "listOnlyCurrentTeamProject": workItemsAdditional.listOnlyCurrentTeamProject,
        "disableNonProjectWorkItemCreateBeforeXWorkingDays": workItemsAdditional.disableNonProjectWorkItemCreateBeforeXWorkingDays,
        "disableNonProjectWorkItemCreateAfterXWorkingDays": workItemsAdditional.disableNonProjectWorkItemCreateAfterXWorkingDays,
        "disableNonProjectWorkItemAssignBeforeXWorkingDays": workItemsAdditional.disableNonProjectWorkItemAssignBeforeXWorkingDays,
        "disableNonProjectWorkItemAssignAfterXWorkingDays": workItemsAdditional.disableNonProjectWorkItemAssignAfterXWorkingDays,

        "disableProjectWorkItemCreateBeforeXWorkingDays": workItemsAdditional.disableProjectWorkItemCreateBeforeXWorkingDays,
        "disableProjectWorkItemCreateAfterXWorkingDays": workItemsAdditional.disableProjectWorkItemCreateAfterXWorkingDays,
        "disableProjectWorkItemAssignBeforeXWorkingDays": workItemsAdditional.disableProjectWorkItemAssignBeforeXWorkingDays,
        "disableProjectWorkItemAssignAfterXWorkingDays": workItemsAdditional.disableProjectWorkItemAssignAfterXWorkingDays,

        "showWorkItemEstimatedHours": workItemsAdditional.showWorkItemEstimatedHours,

        "checklistEnabled": workItemsAdditional.checklistEnabled,
        "checklistSaveEnabled": workItemsAdditional.checklistSaveEnabled,
        "checklistUpdateCompletedEnabled": workItemsAdditional.checklistUpdateCompletedEnabled,
        "checklistCompleteEnabled": workItemsAdditional.checklistCompleteEnabled,
        "checklistChangeEnabled": workItemsAdditional.checklistChangeEnabled,
        "timeSheetNonBillableShowAllAccountsEnabled": timeSheet.timeSheetNonBillableShowAllAccountsEnabled
    }
}

function getTimeSheetAccessControl() {
    let timeSheet = getAccessControll("timeSheet");
    return {

        "enableTimeSheetEntry": timeSheet.enableTimeSheetEntry,
        "enableTimeSheetEdit": timeSheet.enableTimeSheetEdit,
        "enableTimeSheetApproval": timeSheet.enableTimeSheetApproval,

        "allowLeaveAndHolidayForFuture": timeSheet.allowLeaveAndHolidayForFuture,
        "allowLeaveAndHolidayForPast": timeSheet.allowLeaveAndHolidayForPast,
        "restrictedBySkillsEnabled": timeSheet.restrictedBySkillsEnabled,
        "listOnlyCurrentTeamProject": timeSheet.listOnlyCurrentTeamProject,
        "disableNonProjectWorkItemCreateBeforeXWorkingDays": timeSheet.disableNonProjectWorkItemCreateBeforeXWorkingDays,
        "disableNonProjectWorkItemCreateAfterXWorkingDays": timeSheet.disableNonProjectWorkItemCreateAfterXWorkingDays,
        "disableProjectWorkItemCreateBeforeXWorkingDays": timeSheet.disableProjectWorkItemCreateBeforeXWorkingDays,
        "disableProjectWorkItemCreateAfterXWorkingDays": timeSheet.disableProjectWorkItemCreateAfterXWorkingDays,

        "disableLastMonthAfterXDays": timeSheet.disableLastMonthAfterXDays,
        "disableWorkItemCreateBeforeYWorkingDaysAfterXHours": timeSheet.disableWorkItemCreateBeforeYWorkingDaysAfterXHours,

        "enableFutureTimeSheetView": timeSheet.enableFutureTimeSheetView,
        "enablePastTimeSheetView": timeSheet.enablePastTimeSheetView,
        "maxHoursAllowedToEnter": timeSheet.maxHoursAllowedToEnter,

        "switchUserTeamsEnabled": timeSheet.switchUserTeamsEnabled,
        "switchEmployeesEnabled": timeSheet.switchEmployeesEnabled,
        "timeSheetNonBillableShowAllAccountsEnabled": timeSheet.timeSheetNonBillableShowAllAccountsEnabled
    }
}

function getTaskBoardAccessControl() {
    let taskBoard = getAccessControll("taskBoard");
    return {
        ...getWorkItemAccessControl(),
        "multiTaskBoardEnabled": taskBoard.multiTaskBoardEnabled,
        "allUserTeamMultiTaskBoardEnabled": taskBoard.allUserTeamMultiTaskBoardEnabled,
        "switchUserTeamsEnabled": taskBoard.switchUserTeamsEnabled,
        "switchEmployeesEnabled": taskBoard.switchEmployeesEnabled,
        "createEnabled": taskBoard.createEnabled,
        "editEnabled": taskBoard.editEnabled,
        "deleteEnabled": taskBoard.deleteEnabled,
        "removeEnabled": taskBoard.removeEnabled,
        "configureEnabled": taskBoard.configureEnabled,
        "workItemDeleteEnabled": taskBoard.workItemDeleteEnabled,
        "workItemRemoveEnabled": taskBoard.workItemRemoveEnabled
    }
}

function getWorkItemsCalendarAccessControl() {
    let taskActivities = getAccessControll("taskActivities");
    return {
        ...getWorkItemAccessControl()
    }
}
function getTaskPlannerAccessControl() {
    let taskPlanner = getAccessControll("taskPlanner");
    return {
        ...getWorkItemAccessControl(),
        "switchUserTeamsEnabled": taskPlanner.switchUserTeamsEnabled
    }
}

function getReportAccessControl() {
    let reporting = getAccessControll("reporting");
    return {
        "switchUserTeamsEnabled": reporting.switchUserTeamsEnabled,
        "reportConfigurationEnabled": reporting.reportConfigurationEnabled,
        "defaultReportEnabled": reporting.defaultReportEnabled,
        "format3ReportEnabled": reporting.format3ReportEnabled,
        "employeeUtilizationReportEnabled": reporting.employeeUtilizationReportEnabled,
        "myUtilizationReportEnabled": reporting.myUtilizationReportEnabled,
        "myTimesheetDataReportEnabled": reporting.myTimesheetDataReportEnabled,
        "myTimesheetDataDumpReportEnabled": reporting.myTimesheetDataDumpReportEnabled,
        "siteActivityReportEnabled": reporting.siteActivityReportEnabled,
        "projectDetailsBySkillsReportEnabled": reporting.projectDetailsBySkillsReportEnabled,
        "project15PercentageReportEnabled": reporting.project15PercentageReportEnabled,
        "estimateVsActualByDeliverablesByTaskDateReportEnabled": reporting.estimateVsActualByDeliverablesByTaskDateReportEnabled,
        "estimateVsActualByDeliverablesByActivityDateReportEnabled": reporting.estimateVsActualByDeliverablesByActivityDateReportEnabled,
        "weekWiseEmployeeUtilizationMonthlyReportEnabled": reporting.weekWiseEmployeeUtilizationMonthlyReportEnabled,
        "weekWiseEmployeeUtilizationMonthlyEndToEndReportEnabled": reporting.weekWiseEmployeeUtilizationMonthlyEndToEndReportEnabled,
        "weeklyPOEstimationReportEnabled": reporting.weeklyPOEstimationReportEnabled,
        "weeklyProjectHoursReportEnabled": reporting.weeklyProjectHoursReportEnabled,
        "utilizationByEmployeeBySkillsReportEnabled": reporting.utilizationByEmployeeBySkillsReportEnabled,
        "utilizationByEmployeeByProjectCategoryReportEnabled": reporting.utilizationByEmployeeByProjectCategoryReportEnabled,
        "dataDumpReportEnabled": reporting.dataDumpReportEnabled,
        "resourceAllocationReportEnabled": reporting.resourceAllocationReportEnabled,
        "projectCompletionReportEnabled": reporting.projectCompletionReportEnabled,
        "reportScheduleAllTeamAccessEnabled": reporting.reportScheduleAllTeamAccessEnabled,

    }
}
function getUserCalendarAccessControl() {
    return {
        "switchUserTeamsEnabled": true
    }
}

function getBidRequestsAccessControl() {
    let bidRequest = getAccessControll("bidRequest");
    let bidRequestAdditional = getAccessControll("bidRequestAdditional");
    return {
        "createEnabled": bidRequest.createEnabled,
        "editEnabled": bidRequest.editEnabled,
        "duplicateEnabled": bidRequest.duplicateEnabled,
        "deleteEnabled": bidRequest.deleteEnabled,
        "removeEnabled": bidRequest.removeEnabled,
        "archiveEnabled": bidRequest.archiveEnabled,
        "viewEnabled": bidRequest.viewEnabled,

        "allowStatusChange": bidRequestAdditional.allowStatusChange,
        "allowStatusLevelDown": bidRequestAdditional.allowStatusLevelDown,
        "changeableStatus": bidRequestAdditional.changeableStatus,
        "switchUserTeamsEnabled": bidRequestAdditional.switchUserTeamsEnabled
    }
}

function getUserTeamsAccessControl() {
    let userTeams = getAccessControll("userTeams");
    return {
        "createEnabled": userTeams.createEnabled,
        "deleteEnabled": userTeams.deleteEnabled,
        "removeEnabled": userTeams.removeEnabled
    }
}


function getAccountsAccessControl() {
    let accounts = getAccessControll("accounts");
    let accountsAdditional = getAccessControll("accountsAdditional");
    return {
        "createEnabled": accounts.createEnabled,
        "deleteEnabled": accounts.deleteEnabled,
        "editEnabled": accounts.editEnabled,
        "removeEnabled": accounts.removeEnabled,
        "showAllUserTeamsAccount": accountsAdditional.showAllUserTeamsAccount,
        "showAllByAccounts": accountsAdditional.showAllByAccounts
    }
}


