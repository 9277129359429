import React, { Component } from "react";
import { connect } from "react-redux";
import PageInnerHeader from "views/common/PageInnerHeader";
import { projectActions } from '_actions';
import { taskActions } from "_actions";
import { taskConstants } from "_constants";
import { accessUtils } from '_helpers';
import { commonUtils, store } from "_helpers";
import ItemNotFoundSection from './components/ItemsNotFoundSection';
import CreateTaskSidebar from './components/modal/CreateTaskSidebar';
import ViewTaskDetailsSidebar from "./components/modal/ViewTaskDetailsSidebar";
import TasksDetailsTableItems from './components/TasksDetailsTableItems';

class TaskListPage extends Component {

  constructor() {
    super()
    this.state = {
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ONGOING", label: "On Going" }, { name: "COMPLETED", label: "Completed" }, { name: "MYASSIGNED", label: "My Assigned" }, { name: "CREATEDBYME", label: "Created By Me" }], selected: "ONGOING" },
        SORT: { options: [{ name: "ESTIMATEDENDDATE", label: "Due Date" }, { name: "ESTIMATEDSTARTDATE", label: "Est Start Date" }, { name: "CREATEDDATE", label: "Created Date" }, { name: "A-Z", label: "A-Z" }], selected: "A-Z", directionUp:true },
        filterText: null
      },
      permission: accessUtils.getTaskAccessControl(),
      editMode: false,
      duplicateMode: false,
    };
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name + " : " + this.props.match.params.projectId)
    let projectId = commonUtils.removeNameIntial(this.props.match.params.projectId);
    store.dispatch(this.props.getProjectInfo(projectId))
    this.invokeTasksListService(projectId);
    this.setState({
      projectId
    })

  }

  invokeTasksListService = (projectId) => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    //let restrictedByUserTeamIds = this.props.projectInfo.restrictedByUserTeamIds;
    //userTeams = restrictedByUserTeamIds && restrictedByUserTeamIds.length > 0 ? userTeams.filter(userTeam => restrictedByUserTeamIds.includes(userTeam.id)) : userTeams;

    store.dispatch({ type: taskConstants.INITIALIZE_TASKS_USER_TEAMS, userTeams });
    this.reloadTasksListService(userTeams, projectId);
  }

  reloadTasksListService = (userTeams, projectId) => {
    let selectedUserTeams = []
    userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    store.dispatch(this.props.getAllTasks({ projectId: projectId }, this.state.filterOptions))
  }

  onCreateItemClick = (e) => {
    e.preventDefault();
    this.setState({ editMode: false });
    this.setState({ duplicateMode: false });
    let defaultTeam = commonUtils.getCurrentUserTeamId();
    store.dispatch(this.props.initializeCreateTask(defaultTeam));
  }
  onCloseCreateItem = () => {

    store.dispatch({ type: taskConstants.CLOSE_TASK_CREATE_WINDOW });
  }

  handleDeleteTask = (taskIdTobeDeleted, isDelete) => {

    store.dispatch(this.props.deleteTask(taskIdTobeDeleted, isDelete));
  }

  handleEditTask = (taskId, projectId, taskUserTeamId) => {

    this.setState({ duplicateMode: false, editMode: true });
    store.dispatch(this.props.getTaskInfo(taskId, projectId));
    store.dispatch(this.props.initializeCreateTask(taskUserTeamId));
  }

  handleDuplicateTask = (taskId, projectId, taskUserTeamId) => {

    this.setState({ duplicateMode: true, editMode: false });
    store.dispatch(this.props.getTaskInfo(taskId, projectId));
    store.dispatch(this.props.initializeCreateTask(taskUserTeamId));
  }


  handleRemoveTask = (taskId) => {
    store.dispatch(this.props.removeTask(taskId));
  }
  onViewTask = (taskId, projectId) => {
    store.dispatch(this.props.getTaskInfo(taskId, projectId));
    store.dispatch({ type: taskConstants.SHOW_TASK_VIEW_WINDOW });
  }
  onCloseViewTask = () => {
    store.dispatch({ type: taskConstants.CLOSE_TASK_VIEW_WINDOW });
  }

  handleActivateTask = (taskId, isActive) => {
    store.dispatch(this.props.activateTask(taskId, isActive));
  }
  handleUpdateStatus = (taskId, status) => {
    store.dispatch(this.props.updateTaskStatus(taskId, status));
  }

  handleSubmit = (taskRequest, isEdtiMode, isCreateAnotherEnabled, callbackFunction) => {
    if (isEdtiMode) {
      store.dispatch(this.props.updateTask(taskRequest));
    } else {
      store.dispatch(this.props.createTask(taskRequest, isCreateAnotherEnabled, callbackFunction));
    }
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: taskConstants.CHANGE_FILTER_TASKS, filterOptions });
      });
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: taskConstants.INITIALIZE_TASKS_USER_TEAMS, userTeams });
    this.reloadTasksListService(userTeams)
  }

  render() {
    return (
      <div className="projectDetails">
        <div className="section-body mt-0">
          <div className="container-fluid px-0">

            <div className="row mx-0 projectDetailsSection pt-1 border-top">
              <div className="col-lg-12 px-1">
                <div className="col-lg-12 col-md-12 px-0">
                  <div className="mb-0 h-100">
                    <PageInnerHeader
                      userTeams={this.props.userTeams}
                      handleUserTeamsSelection={this.handleUserTeamsSelection}
                      showGridDefault={this.state.showGrid}
                      showCreateMenu={this.state.permission.createEnabled && this.props.projectInfo && this.props.projectInfo.status === 'INPROGRESS'}
                      showFilter={true}
                      showUserTeamMenu={true}
                      isLoading={this.props.isLoading}
                      filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
                      handleFilterChange={this.handleFilterChange}
                      onFilterTextChange={this.onFilterTextChange}
                      showCreateMenuText="CREATE DELIVERABLES"
                      createItemHandler={this.onCreateItemClick}
                      permission={this.state.permission}
                    />

                    {((this.props.tasks && this.props.tasks.length > 0) || this.props.isLoading) &&
                      <TasksDetailsTableItems
                        tasks={this.props.tasks}
                        permission={this.state.permission}
                        isLoading={this.props.isLoading}
                        handleDeleteTask={this.handleDeleteTask}
                        handleEditTask={this.handleEditTask}
                        handleUpdateStatus={this.handleUpdateStatus}
                        handleDuplicateTask={this.handleDuplicateTask}
                        projectId={this.state.projectId}
                        handleActivateTask={this.handleActivateTask}
                        handleRemoveTask={this.handleRemoveTask}
                        onViewTask={this.onViewTask}
                      />
                    }

                    <ItemNotFoundSection items={this.props.tasks} isLoading={this.props.isLoading} imgurl="notasks1-min.PNG" text="No Deliverables found ?" description="Try Creating a Deliverable from scratch..." />
                    {this.props.projectInfo &&
                      <CreateTaskSidebar
                        projectId={this.state.projectId}
                        show={this.props.showCreateModel}
                        userTeams={this.props.userTeams}
                        closeActionHandler={this.onCloseCreateItem}
                        editMode={this.state.editMode}
                        duplicateMode={this.state.duplicateMode}
                        selectedTaskInfo={this.props.selectedTaskInfo}
                        handleSubmit={this.handleSubmit}
                        projectInfo={this.props.projectInfo}
                        permission={this.state.permission}
                      />
                    }
                    <ViewTaskDetailsSidebar show={this.props.isShowTaskViewSidebar} onCloseViewTask={this.onCloseViewTask} />

                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tasks: commonUtils.filterTasks(state.tasks.items, state.tasks.filterOptions),
  projectInfo: state.projects.selectedProjectInfo,
  isLoading: state.tasks.tasksLoading,
  userTeams: state.tasks.userTeams,
  filterOptions: state.tasks.filterOptions,
  showCreateModel: state.tasks.showCreateModel,
  isShowTaskViewSidebar: state.tasks.isShowTaskViewSidebar
});
const mapDispatchToProps = dispatch => ({
  getAllTasks: taskActions.getAllTasks,
  getTaskInfo: taskActions.getTaskInfo,
  getProjectInfo: projectActions.getProjectInfo,
  createTask: taskActions.createTask,
  updateTask: taskActions.updateTask,
  updateTaskStatus: taskActions.updateTaskStatus,
  activateTask: taskActions.activateTask,
  removeTask: taskActions.removeTask,
  deleteTask: taskActions.deleteTask,
  initializeCreateTask: taskActions.initializeCreateTask
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskListPage);
