import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { accessUtils } from "_helpers";
import { store } from "_helpers";

import { Link } from "react-router-dom";
import { invoiceActions } from "_actions";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import SelectInput from "views/common/fragments/formfields/SelectInput";
import classNames from "classnames/bind";
class InvoiceListPage extends PureComponent {
    constructor() {
        super();
        this.state = {
            permission: accessUtils.getInvoiceAccessControl(),
        }
    }

    componentDidMount() {
        this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
        if (!this.props.items) {
            this.invokeInvoiceListService();
        }
    }

    invokeInvoiceListService = () => {
        store.dispatch(this.props.getAllInvoices());
    }

    removeInvoice = (id) => {
        store.dispatch(this.props.removeInvoice(id));
    }

    handleUpdateStatus = (invoiceId, status) => {
        store.dispatch(this.props.updateInvoiceStatus(invoiceId, status));
      }

    getCount = (status) => {
        return this.props.items && this.props.items.filter(item => item.status === status).length;
    }


    render() {
        return (
            <div className="projectlist">
                {((this.props.items && this.props.items.length > 0) || this.props.isLoading) &&
                    <>
                        <div className="section-body">
                            <div className="container-fluid">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row text-center">
                                            <div className="col-lg-4">
                                                <h4 className="mb-1"><i className="mdi mdi-trending-down text-danger"></i> <span className="counter">{this.getCount("APPROVED")}</span></h4>
                                                <div className="text-muted-dark">Total Approved</div>
                                            </div>
                                            <div className="col-lg-4">
                                                <h4 className="mb-1"><i className="mdi mdi-trending-up text-success"></i> <span className="counter">{this.getCount("PENDING")}</span></h4>
                                                <div className="text-muted-dark">Pending Invoice</div>
                                            </div>
                                            <div className="col-lg-4">
                                                <h4 className="mb-1"><i className="mdi mdi-trending-neutral text-warning"></i> <span className="counter">{this.getCount("CLOSED")}</span></h4>
                                                <div className="text-muted-dark">Closed</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover table-striped table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>Invoice No.</th>
                                                <th>Description</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th className="w-100">Amount</th>
                                                <th className="w-150">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.items && commonUtils.sortArrayOfObjectsByDateField(this.props.items, "invoiceDateTime",).map(item =>
                                                <InvoiceLineItem
                                                    key={item.id}
                                                    item={item}
                                                    removeInvoice={this.removeInvoice}
                                                    handleUpdateStatus={this.handleUpdateStatus}
                                                    permission={this.state.permission}
                                                />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                }


            </div>
        );
    }
}

class InvoiceLineItem extends Component {

    constructor() {
        super();
        this.state = {
            confrimDelete: false
        }
    }
    handleRemoveInvoice = (isConfrimDelete) => {
        if (isConfrimDelete) {
            this.props.removeInvoice(this.props.item.id);
        } else {
            this.setState({
                confrimDelete: true
            })
        }
    }
    getInvoiceNumber = () => {
        return this.props.item.invoiceNumber || ("C5" + (this.props.item && dateUtils.convertDateStringToMoment(this.props.item.invoiceDateTime).format("YYYYMMDD")) || "123456");
    }

    openShareInvoiceDetailsPage = (event) => {
        let invoiceNumber = this.getInvoiceNumber();
        let encryptInvoiceNumber = commonUtils.encryptString(invoiceNumber, invoiceNumber + "_" + this.props.item.id);
        return window.location.origin + `/document/invoice/details/${this.props.item.id}?ac=${encryptInvoiceNumber}&uin=${invoiceNumber}`;
    }
    render() {
        return (
            <tr>
                <td><Link to={`/hrms/invoice/details/${this.props.item.id}`} className="dropdown-item fweight600"><i className="dropdown-icon" />
                    #{this.getInvoiceNumber()}
                </Link></td>
                <td>{this.props.item.name}</td>
                <td>{dateUtils.convertDateFormat(this.props.item.invoiceDateTime)}</td>
                <td><i className="payment payment-cirrus"></i></td>
                <td>
                    <SelectInput
                        options={[
                            { value: "DRAFT", label: "Draft", level: 0 },
                            { value: "PENDING", label: "Pending", level: 1 },
                            { value: "APPROVED", label: "Approved", level: 2 },
                            { value: "PAID", label: "Paid", level: 3 },
                            { value: "CLOSED", label: "Closed", level: 4 }]}
                        value={this.props.item.status}
                        allowLevelDown={true}
                        changeableStatus={["DRAFT", "PENDING", "APPROVED", "PAID", "CLOSED"]}
                        isReadOnly={false}
                        itemId={this.props.item.id}
                        onChange={this.props.handleUpdateStatus}
                        className={classNames("badge",
                            { "badge-info": this.props.item.status === 'DRAFT' },
                            { "badge-primary": this.props.item.status === 'PENDING' },
                            { "badge-warning": this.props.item.status === 'APPROVED' },
                            { "badge-success": this.props.item.status === 'PAID' },
                            { "badge-dark": this.props.item.status === 'CLOSED' }
                        )} />
                </td>
                <td>{(this.props.permission.viewEnabled && <>&#x20B9;{this.props.item.subTotal}</>) || 0.00}</td>
                <td>
                    <button type="button" className="link pr-2"><i className="icon-envelope text-info"></i></button>
                    <button type="button" className="link pr-2"><i className="icon-printer"></i></button>
                    {this.props.permission.viewEnabled &&
                        <a href={this.openShareInvoiceDetailsPage()} target="_blank"><i className="icon-share" /></a>
                    }
                    {this.props.permission.removeEnabled === true &&

                        <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveInvoice(this.state.confrimDelete)}>
                            {this.state.confrimDelete === true &&
                                <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
                            }
                            {this.state.confrimDelete === false &&
                                <i className="dropdown-icon fa fa-times colorred"></i>
                            }
                        </button>
                    }
                </td>
            </tr>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    isLoading: state.invoice.invoicesLoading,
    items: state.invoice.items && commonUtils.sortArrayOfObjectsByDateField(state.invoice.items, "invoiceDateTime", false)
});
const mapDispatchToProps = dispatch => ({
    getAllInvoices: invoiceActions.getAllInvoices,
    getInvoiceInfo: invoiceActions.getInvoiceInfo,
    createInvoice: invoiceActions.createInvoice,
    updateInvoice: invoiceActions.updateInvoice,
    updateInvoiceStatus: invoiceActions.updateInvoiceStatus,
    removeInvoice: invoiceActions.removeInvoice,
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListPage);