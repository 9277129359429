import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class TaskDetailsHeader extends Component {
    render() {
        return (
            <div className="section-body listgridheader stickytop">
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">

                        <ul className="nav nav-tabs  ml-2">
                            <li className="nav-item">
                                <a href="#" className="nav-link link" data-toggle="tab">Dashboard</a></li>
                            <li className="nav-item">
                                <a href="#" className="nav-link link" data-toggle="tab">Related Deliverables</a></li>
                            <li className="nav-item px-0">
                                {this.props.taskInfo && (this.props.taskInfo.status !== 'NEW' && this.props.taskInfo.status !== 'ASSIGNED') &&
                                    <NavLink
                                        to={"/project/workitems/" + this.props.taskInfo.id}
                                        className={`nav-link`} >
                                        Work Items
                                    </NavLink>
                                }
                                {!(this.props.taskInfo && (this.props.taskInfo.status !== 'NEW' && this.props.taskInfo.status !== 'ASSIGNED')) &&
                                    <span className="link fsize11 colordisabled" data-toggle="tab">Work Items</span>
                                }
                            </li>
                        </ul>

                        <div className="header-action d-md-flex pt-0">
                            <ul className="nav nav-tabs  pl-2 mx-0">
                                <li className="nav-item">
                                    <a className="nav-link active" id="Project-tab" data-toggle="tab" href="#Project-Settings">Delivery Settings</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaskDetailsHeader;
