import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import EmployeeInfoLine from "./EmployeeInfoLine";

class EmployeeListTable extends Component {
  render() {
    return (

      <div className="table-responsive">
          <table className="table table_custom table-nowrap table-vcenter table-borderless position-relative">
            <thead>

              <tr>
                <th>Name</th>
                <th>Primary Skill</th>
                <th className="text-center">Type</th>
                <th>Email</th>
                <th>Date of Join</th>
                <th>Contact No</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
            {!this.props.isLoading && this.props.employees && this.props.employees.map((employee, index) =>
              <EmployeeInfoLine  key={employee.id} employee={employee} 
              handleDeleteEmployee={this.props.handleDeleteEmployee}
              handleEditEmployee={this.props.handleEditEmployee}
              handleActivateEmployee={this.props.handleActivateEmployee} 
              handleRemoveEmployee={this.props.handleRemoveEmployee}  
              permission={this.props.permission}
              />
            )}  
            {this.props.isLoading &&
             <tr>
                <td colSpan="7">
                  <Skeleton count={7} height={60} />
                </td>
            </tr>
            }
            </tbody>
          </table>
      </div>
    );
  }
}
export default EmployeeListTable;