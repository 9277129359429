
import React from 'react'
import dateUtils from "_helpers/date.utils";

let offset = null, interval = null

class WorkItemTimer extends React.Component {

    constructor() {
        super()
        this.state = { time: '' }
    }

    componentDidMount() {
        if (!this.props.pausedTime) {
            this.play();
        } else {
            this.setState({ pausedTime: this.props.pausedTime });
            this.updatewithPausedTime(this.props.pausedTime, this.props.startTime);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    pause = () => {
        clearInterval(this.state.intervalId);
        this.setState({ intervalId: null, pausedTime: Date.now() });
    }

    play = () => {
        let intervalId = setInterval(this.update, 100)
        this.setState({ intervalId: intervalId, pausedTime: null });
    }

    handlePlay =()=>{
        this.props.onPlay(this.props.workItem,  this.play);
       
    }
    handlePause =()=>{
        this.props.onPause(this.props.workItem.id);
        this.pause()
    }

    updatewithPausedTime = (currentTime, startTime) => {
        let timeDiff = currentTime - startTime;
        if(timeDiff >= 36000000) {
            clearInterval(this.state.intervalId);
            timeDiff = 36000000;
        }
        let time = dateUtils.convertSecTohhmmss(timeDiff / 1000)
        
        this.setState({ time: time })
    }
    update = () => {
        this.updatewithPausedTime(Date.now(), this.props.startTime);
    }

    render() {
        return (
            <span className="mr-2 fsize11">
                {this.state.intervalId ?
                    <button className="link " onClick={this.handlePause}>
                        <i className="fa fa-pause fa-lg colorred"></i> </button>
                    : <>
                        {this.props.workItemPausedTime &&
                            <button className="link " onClick={this.handlePlay}><i className="fa fa-play fa-lg colorgreen"></i></button>
                        }
                        <span className="pl-1 blink_text fsize10">PAUSED</span>
                    </>
                }
                <span className="pl-1">{this.state.time}</span>
            </span>
        )
    }
}


export default WorkItemTimer;
