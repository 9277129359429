
import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const employeeService = {
    createEmployee,
    updateEmployee,
    updateEmployeeExitDate,
    deleteEmployee,
    activateEmployee,
    removeEmployee,
    updateEmployeeRoles,
    getAllEmployees,
    getTeamsEmployees,
    getEmployeeById,
    getTeamsAttendance,
    createAttendance,
    deleteAttendance,
    getHolidays,
    createHolidayItem,
    removeHolidayItem
};

async function getAllEmployees(userTeamIds) {
    return await axiosUtils.get(urlConfig.EMPLOYEE_GET_ALL+`/true`, {});
 }

 async function getTeamsEmployees(userTeamIds) {
    return await axiosUtils.get(urlConfig.EMPLOYEE_TEAMS_ALL+ `?userTeamIds=${userTeamIds}`);
 }

async function getEmployeeById(employeeId) {
   return await axiosUtils.get(urlConfig.EMPLOYEE_GET_INFO+`/${employeeId}`);
}

async function createEmployee(employeeRequest) {
    return await axiosUtils.post(urlConfig.EMPLOYEE_CREATE,employeeRequest);
}

async function updateEmployeeRoles(selectedEmployeesAndRoles) {
    return await axiosUtils.put(urlConfig.EMPLOYEE_USER_ROLE_UPDATE,{"userRoles" : selectedEmployeesAndRoles});
}

async function updateEmployee(employeeRequest) {
    return await axiosUtils.put(urlConfig.EMPLOYEE_UPDATE,employeeRequest);
}

async function updateEmployeeExitDate(employeeRequest) {
    return await axiosUtils.put(urlConfig.EMPLOYEE_GET_INFO+`/${employeeRequest.employeeId}/exit?exitDate=${employeeRequest.exitDate != undefined ? employeeRequest.exitDate : ""}`,{});
}

async function deleteEmployee(employeeId, isDelete) {
   return await axiosUtils.remove(urlConfig.EMPLOYEE_DELETE+`/${employeeId}/${isDelete}`);
}

async function removeEmployee(employeeId) {
    return await axiosUtils.remove(urlConfig.EMPLOYEE_REMOVE+`/${employeeId}`);
 }

 async function activateEmployee(employeeId, isActive) {
    return await axiosUtils.put(urlConfig.EMPLOYEE_ACTIVATE+`/${employeeId}/${isActive}`);
 }
 
async function getTeamsAttendance(request) {
    return await axiosUtils.get(urlConfig.GET_TEAM_ATTENDANCE + `?userTeamIds=${request.userTeamIds}&startDate=${request.startDate}&endDate=${request.endDate}`);
}

async function createAttendance(request) {
    return await axiosUtils.put(urlConfig.MARK_ATTENDANCE + `/${request.employeeId}?date=${request.markDate}`);
}

async function deleteAttendance(attendaceId) {
   return await axiosUtils.remove(urlConfig.DELETE_ATTENDANCE + `/${attendaceId}`);
}

async function getHolidays(request) {
    return await axiosUtils.get(urlConfig.HOLIDAY_PATH + `?startDate=${request.startDate}&endDate=${request.endDate}`);
}

async function createHolidayItem(request) {
    return await axiosUtils.post(urlConfig.HOLIDAY_PATH, request);
}

async function removeHolidayItem(holidayId) {
   return await axiosUtils.remove(urlConfig.HOLIDAY_PATH + `/${holidayId}`);
}

