import React, { Component } from 'react'
import Cron from 'react-cron-generator'
import 'react-cron-generator/dist/cron-builder.css'
import { HEADER } from 'react-cron-generator'

class CronSelector extends Component {

  onChange = (cronValue, cronString) => {
    this.setState(
      {
        cronValue,
        cronString
      }
    );
    this.props.onChange(cronValue, cronString);
  }

  render() {
    return (<div className='cronSelector'>
      <Cron
        onChange={(cronValue, cronString) => this.onChange(cronValue, cronString)}
        showResultText={true}
        value={this.props.cronValue}
        showResultCron={false}
        options={{ headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.HOURLY, HEADER.DAILY] }}
      />

    </div>)
  }
}

export default CronSelector;