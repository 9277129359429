export const checklistConstants = {

    SHOW_CHECKLIST_CONFIGURE_WINDOW: "SHOW_CHECKLIST_CONFIGURE_WINDOW",
    CLOSE_CHECKLIST_CONFIGURE_WINDOW: "CLOSE_CHECKLIST_CONFIGURE_WINDOW",

    SHOW_CHECKLIST_ANSWER_WINDOW:'SHOW_CHECKLIST_ANSWER_WINDOW',
    CLOSE_CHECKLIST_ANSWER_WINDOW:'CLOSE_CHECKLIST_ANSWER_WINDOW',
    
    GETALL_CHECKLISTS_REQUEST: 'GETALL_CHECKLISTS_REQUEST',
    GETALL_CHECKLISTS_SUCCESS: 'GETALL_CHECKLISTS_SUCCESS',
    GETALL_CHECKLISTS_FAILURE: 'GETALL_CHECKLISTS_FAILURE',
    
    CREATE_CHECKLIST_REQUEST: 'CREATE_CHECKLIST_REQUEST',
    CREATE_CHECKLIST_SUCCESS: 'CREATE_CHECKLIST_SUCCESS',
    CREATE_CHECKLIST_FAILURE: 'CREATE_CHECKLIST_FAILURE',

    UPDATE_CHECKLIST_REQUEST: 'UPDATE_CHECKLIST_REQUEST',
    UPDATE_CHECKLIST_SUCCESS: 'UPDATE_CHECKLIST_SUCCESS',
    UPDATE_CHECKLIST_FAILURE: 'UPDATE_CHECKLIST_FAILURE',

    REMOVE_CHECKLIST_REQUEST: 'REMOVE_CHECKLIST_REQUEST',
    REMOVE_CHECKLIST_SUCCESS: 'REMOVE_CHECKLIST_SUCCESS',
    REMOVE_CHECKLIST_FAILURE: 'REMOVE_CHECKLIST_FAILURE',

    ACTIVATE_CHECKLIST_REQUEST:'ACTIVATE_CHECKLIST_REQUEST',
    ACTIVATE_CHECKLIST_SUCCESS:'ACTIVATE_CHECKLIST_SUCCESS',
    ACTIVATE_CHECKLIST_FAILURE:'ACTIVATE_CHECKLIST_FAILURE',

    CHECKLIST_ITEM_UPDATE_REQUEST: 'CHECKLIST_ITEM_UPDATE_REQUEST',
    CHECKLIST_ITEM_UPDATE_SUCCESS: 'CHECKLIST_ITEM_UPDATE_SUCCESS',
    CHECKLIST_ITEM_UPDATE_FAILURE: 'CHECKLIST_ITEM_UPDATE_FAILURE',
};