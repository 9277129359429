import classNames from 'classnames/bind';
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import PageInnerHeaderTeamsSelectionMenu from "views/common/fragments/PageInnerHeaderTeamsSelectionMenu";
import { employeeActions } from "_actions";
import { employeeConstants } from "_constants";
import { accessUtils } from '_helpers';
import { commonUtils, store } from "_helpers";
import dateUtils from "_helpers/date.utils";
class AttendancePage extends Component {
  constructor() {
    super()
    this.state = {
      selectedDate: dateUtils.convertDateToMoment(new Date()),
      singleUserView: false,
      mothDateArray: [],
      filterOptions: {
        filterText: null
      },
      permission:accessUtils.getEmployeeAttendanceAccessControl()
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
    this.refreshMonthDates(this.state.selectedDate);
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    store.dispatch({ type: employeeConstants.INITIALIZE_EMPLOYEE_ATTENDANCE_USER_TEAMS, userTeams });
    !this.props.employees && this.reloadEmployeeItems(userTeams);
    this.getTeamsAttendance(userTeams);
  }

  refreshMonthDates = (date) => {
    this.setState({
      selectedDate: date,
      mothDateArray: dateUtils.getDaysArrayByMonth(date)
    }, () => this.getTeamsAttendance(commonUtils.getUserTeams(this.props.userTeams)))

  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: employeeConstants.CHANGE_FILTER_EMPLOYEES_ATTENDANCE, filterOptions });
      });
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: employeeConstants.INITIALIZE_EMPLOYEE_ATTENDANCE_USER_TEAMS, userTeams });
    //console.log(userTeams)
    this.reloadEmployeeItems(userTeams);
    this.getTeamsAttendance(userTeams);
  }

  reloadEmployeeItems = (userTeams) => {
    let selectedUserTeams = []
    userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    selectedUserTeams.length > 0 && store.dispatch(this.props.getTeamsEmployees(selectedUserTeams, this.state.filterOptions))
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  goToNext = () => {
    this.refreshMonthDates(this.state.selectedDate.add(1, 'months'))
  };

  goToBack = () => {
    this.refreshMonthDates(this.state.selectedDate.subtract(1, 'months'))
  };

  goToCurrent = () => {
    this.refreshMonthDates(dateUtils.getCurrentTime())
  };

  getTeamsAttendance = (userTeams) => {
    let startDate = dateUtils.getFirstAndLastDayofMonth(this.state.selectedDate)[0];
    let endDate = dateUtils.getFirstAndLastDayofMonth(this.state.selectedDate)[1];
    let selectedUserTeams = []
    userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    selectedUserTeams.length > 0 && store.dispatch(this.props.getTeamsAttendance({ userTeamIds: selectedUserTeams, startDate: startDate.format(dateUtils.SERVER_DATE_FORMAT), endDate: endDate.format(dateUtils.SERVER_DATE_FORMAT) }));
  }
  createAttendance = (employeeId, markDate) => {
    //console.log("employeeId " + employeeId)
    //console.log("markDate " + markDate)
    store.dispatch(this.props.createAttendance({ employeeId: employeeId, markDate: markDate.format(dateUtils.SERVER_DATE_FORMAT) }));
  }
  deleteAttendance = (id) => {
    store.dispatch(this.props.deleteAttendance({ id }));
  }

  render() {
    return (
      <div className="section-body mt-2" >
        <div className="container-fluid">
          <div className="col-12 p-0">
            <div className="card">
              <div className="section-body listgridheader stickytop  border-bottom">
                <div className="container-fluid">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="header-action d-md-flex pt-0">
                      <span className="pt-2">
                        <PageInnerHeaderTeamsSelectionMenu
                          userTeams={this.props.userTeams}
                          handleUserTeamsSelection={this.handleUserTeamsSelection}
                          isLoading={this.props.isLoading}
                          enabled={this.state.permission.switchUserTeamsEnabled}
                        />
                      </span>
                      <span className="pl-2 pt-1 text-normal-case fweight600 fsize15">{this.state.selectedDate.format("MMM YYYY")}</span>
                    </div>
                    <div className="pt-2">
                      <ul className="nav nav-tabs page-header-tab pl-2">
                        <li className="nav-item pl-0">
                          <button className="link " onClick={this.goToBack}><i className="fa fa-chevron-left" /></button></li>
                        <li className="nav-item pl-0">
                          <button className="link " onClick={this.goToCurrent}><i className="fa fa-home" /></button></li>
                        <li className="nav-item pl-0">
                          <button className="link " onClick={this.goToNext}><i className="fa fa-chevron-right" /></button></li>
                      </ul>
                    </div>
                    <div className="header-action d-md-flex pt-2">

                      <div className="input-icon"><span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                        <input type="text" disabled={this.props.isLoading} className="form-control input-sm filter" placeholder="Filter..."
                          value={(this.props.filterOptions && this.props.filterOptions.filterText) || ''}
                          onChange={(event) => this.onFilterTextChange(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-vcenter attendance_list">
                    <thead>
                      <tr>
                        <th>Employee</th>
                        {this.state.mothDateArray && this.state.mothDateArray.map(dayMonth =>
                          <th className={classNames("weekend", { "saturday": dayMonth.weekday() === 5 }, { "sunday": dayMonth.weekday() === 6 })} key={dayMonth.format("DD/MM")}>{dayMonth.format("DD/MM")}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>

                      {!this.props.isLoading && this.props.employees && this.props.employees.map(employee =>
                        <tr key={employee.id}>
                          <td>{employee.name}</td>
                          {this.state.mothDateArray && this.state.mothDateArray.map(dayMonth =>
                            <AttendanceItem key={dayMonth.format("YYYY-MM-DD")}
                              item={this.props.items && this.props.items.find(item => item.employeeId === employee.id && item.markDate === dayMonth.format("YYYY-MM-DD"))}
                              employeeId={employee.id}
                              dayMonth={dayMonth}
                              createAttendance={this.createAttendance}
                              deleteAttendance={this.deleteAttendance}
                            />
                          )}
                        </tr>
                      )}

                      {this.props.isLoading &&
                        <tr>
                          <td colSpan={this.state.mothDateArray.length}>
                            <Skeleton count={15} height={40} />
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employees: commonUtils.filterEmployees(state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees), state.workItem.filterOptionsA),
  isLoading: state.employees.attendanceLoading,
  items: state.employees.attendanceItems,
  filterOptions: state.employees.filterOptionsA,
  userTeams: commonUtils.getUserTeams(state.employees.userTeamsA)
});

const mapDispatchToProps = dispatch => ({
  getTeamsEmployees: employeeActions.getTeamsEmployees,
  getTeamsAttendance: employeeActions.getTeamsAttendance,
  createAttendance: employeeActions.createAttendance,
  deleteAttendance: employeeActions.deleteAttendance
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendancePage);


class AttendanceItem extends Component {

  render() {
    return (
      <td className={classNames("weekend", { "saturday": this.props.dayMonth.weekday() === 5 }, { "sunday": this.props.dayMonth.weekday() === 6 })}>
        {this.props.item &&
          <i className="fa fa-check-circle fa-2x colorgreen"
            onClick={() => this.props.deleteAttendance(this.props.item.id)}
          />
        }
        {!this.props.item &&
          <i className="fa fa-times-circle fa-2x colorred"
            onClick={() => this.props.createAttendance(this.props.employeeId, this.props.dayMonth)} />
        }
      </td>
    )
  }
}