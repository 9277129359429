import React, { Component } from "react";
import dateUtils from "_helpers/date.utils";
import { NavLink } from "react-router-dom";
import { commonUtils } from "_helpers";
import ItemPercentageOfCompletion from "./fragments/ItemPercentageOfCompletion";
class ProjectInfoGrid extends Component {
    render() {
        return (
            <div className="col-lg-4 col-md-12 project-grid">
                <div className="card">
                    <div className="card-header ribbon">
                        <div className="ribbon-box green">{commonUtils.getNameInitial(this.props.item.name)}</div>
                        <h3 className="card-title pl-2 text-normal-case fsize15 pl-4">
                            {this.props.permission.viewEnabled === true &&
                                <NavLink
                                    to={`/project/details/${this.props.item.itemIdUserTeamPrefix}`}
                                    className="nav-link list-b pl-0"
                                    activeClassName="active"
                                >
                                    {this.props.item.name}
                                </NavLink>
                            }
                            {!this.props.permission.viewEnabled &&
                                this.props.item.name
                            }
                        </h3>
                        <div className="card-options">
                            {this.props.permission.archiveEnabled === true &&
                                <label className=" custom-switch custom-switch-sm m-0">
                                    <input type="checkbox" onChange={e => { }}
                                        onClick={() => this.props.handleArchiveProject(this.props.item.id, !this.props.item.archived)}
                                        className="custom-switch-input" checked={this.props.item.archived ? false : true} />
                                    <span className="custom-switch-indicator" />
                                </label>
                            }
                            <button type="button" className="link pl-2" data-toggle="dropdown" aria-expanded="true"><i className="fe fe-more-horizontal"></i></button>
                            <div className="dropdown-menu dropdown-menu-right dropdownrightmenu" x-placement="top-end">

                                {!this.props.item.archived && this.props.permission.editEnabled === true &&
                                    <>
                                        {(this.props.item.status !== 'COMPLETED' && this.props.item.status !== 'CLOSED') &&
                                            <button type="button"
                                                className="dropdown-item py-1 link"
                                                onClick={(e) => this.props.handleEditProject(this.props.item.id, this.props.item.userTeamId)}
                                                title="Edit"><i className="dropdown-icon fa fa-edit" />Edit</button>
                                        }
                                    </>
                                }

                                {this.props.permission.deleteEnabled === true &&
                                    <button type="button"
                                        onClick={(e) => this.props.handleDeleteProject(this.props.item.id, !this.props.item.deleted)}
                                        className="dropdown-item py-1 link">
                                        {this.props.item.deleted &&
                                            <>
                                                <i className="dropdown-icon fa fa-rotate-left colorred blink_text"></i> Undo Delete
                                            </>
                                        }
                                        {!this.props.item.deleted &&
                                            <>
                                                <i className="dropdown-icon fa fa-trash"></i>Delete
                                            </>
                                        }
                                    </button>
                                }
                                {this.props.permission.removeEnabled === true &&
                                    <button type="button"
                                        className="dropdown-item py-1 link"
                                        onClick={(e) => this.props.handleRemoveProject(this.props.item.id)}
                                        title="Edit"><i className="dropdown-icon fa fa-times colorred" />Remove</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        <span className="tag tag-blue mb-3">{this.props.item.accountName}</span>
                        <span className="project-due-text red px-1 py-0 float-right">Due to : {dateUtils.convertDateFormat(this.props.item.estCompletionTime)}</span>
                        <p> {this.props.item.summary}</p>
                        <div className="row">
                            <div className="col-5 py-0"><strong>Start Date:</strong></div>
                            <div className="col-7 py-0">{dateUtils.convertDateFormat(this.props.item.createdDate)}</div>
                            <div className="col-5 py-0"><strong>Category:</strong></div>
                            <div className="col-7 py-0"><strong>{this.props.item.category || 'Nil'}</strong></div>
                            <div className="col-5 py-0"><strong>Type:</strong></div>
                            <div className="col-7 py-0">{this.props.item.projectType || 'Nil'}-{this.props.item.type || 'Nil'}-{this.props.item.location || 'Nil'}</div>
                            <div className="col-5 py-0"><strong>Order Book Ref No:</strong></div>
                            <div className="col-7 py-0"><strong>{this.props.item.orderBookRef || 'Nil'}</strong></div>
                            <div className="col-5 py-0"><strong>Proposal No:</strong></div>
                            <div className="col-7 py-0"><strong>{this.props.item.proposalNumber || 'Nil'}</strong></div>
                            <div className="col-5 py-0"><strong>Client Partner:</strong></div>
                            <div className="col-7 py-0"><strong>{this.props.item.clientPartner || 'Nil'}</strong></div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-2">
                            <div className="float-left pl-5"><strong>{this.props.item.completedTaskCount}/{this.props.item.taskTotalCount | 0}</strong><small className="text-muted d-block text-center">Tasks</small></div>
                            <div className="verticalLine"></div>
                            <div className="float-right pr-5"><strong>{this.props.item.actualHours}/{this.props.item.estHours | 0}</strong><small className="text-muted d-block text-center">Hours</small></div>
                        </div>
                    </div>
                    <div className="card-footer">

                        <ItemPercentageOfCompletion
                            totalCount={this.props.item.taskTotalCount}
                            completedCount={this.props.item.completedTaskCount}
                        />
                    </div>
                </div>
            </div >
        );
    }
}
export default ProjectInfoGrid;