import React, { Component } from "react";

class ProjectDetailsNotification extends Component {
    render() {
        return (
            <div className="card mb-0 h-100">
                <div className="card-header">
                    <h3 className="card-title">Notifications Settings</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive d-none">
                        <table className="table table-vcenter mb-0 table_custom spacing15 text-nowrap">
                            <tbody>
                                <tr>
                                    <td className="pt-0 pl-3">
                                        <h6 className="mb-0 fsize13 fweight600">New code Update on github</h6>
                                        <span className="text-muted">It is a long established fact that a reader will be distracted...</span>
                                    </td>
                                    <td className="text-center text-top d-block">
                                        <label className="custom-switch m-0">
                                            <input type="checkbox" defaultValue={1} className="custom-switch-input" defaultChecked />
                                            <span className="custom-switch-indicator " />
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-0 pl-3">
                                        <h6 className="mb-0 fsize13 fweight600">New code Update on github</h6>
                                        <span className="text-muted">It is a long established fact that a reader will be distracted...</span>
                                    </td>
                                    <td className="text-center text-top d-block">
                                        <label className="custom-switch m-0">
                                            <input type="checkbox" defaultValue={1} className="custom-switch-input" defaultChecked />
                                            <span className="custom-switch-indicator " />
                                        </label></td>
                                </tr>
                                <tr>
                                    <td className="pt-0 pl-3">
                                        <h6 className="mb-0 fsize13 fweight600">New code Update on github</h6>
                                        <span className="text-muted">It is a long established fact that a reader will be distracted...</span>
                                    </td>
                                    <td className="text-center text-top d-block">
                                        <label className="custom-switch m-0">
                                            <input type="checkbox" defaultValue={1} className="custom-switch-input" defaultChecked />
                                            <span className="custom-switch-indicator " />
                                        </label></td>
                                </tr>
                                <tr>
                                    <td className="pt-0 pl-3">
                                        <h6 className="mb-0 fsize13 fweight600">New code Update on github</h6>
                                        <span className="text-muted">It is a long established fact that a reader will be distracted...</span>
                                    </td>
                                    <td className="text-center text-top d-block">
                                        <label className="custom-switch m-0">
                                            <input type="checkbox" defaultValue={1} className="custom-switch-input" defaultChecked />
                                            <span className="custom-switch-indicator " />
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    }
}

export default ProjectDetailsNotification;
