export const workItemConstants = {

    GETALL_WORK_ITEMS_REQUEST: 'GETALL_WORK_ITEMS_REQUEST',
    GETALL_WORK_ITEMS_SUCCESS: 'GETALL_WORK_ITEMS_SUCCESS',
    GETALL_WORK_ITEMS_FAILURE: 'GETALL_WORK_ITEMS_FAILURE',

    GET_TODAYS_WORK_ITEM_REQUEST: 'GET_TODAYS_WORK_ITEM_REQUEST',
    GET_TODAYS_WORK_ITEM_SUCCESS: 'GET_TODAYS_WORK_ITEM_SUCCESS',
    GET_TODAYS_WORK_ITEM_FAILURE: 'GET_TODAYS_WORK_ITEM_FAILURE',

    GET_WORK_ITEM_REQUEST: 'GET_WORK_ITEM_REQUEST',
    GET_WORK_ITEM_SUCCESS: 'GET_WORK_ITEM_SUCCESS',
    GET_WORK_ITEM_FAILURE: 'GET_WORK_ITEM_FAILURE',
    INITIALIZE_CREATE_WORK_ITEM_REQUEST: 'INITIALIZE_CREATE_WORK_ITEM_REQUEST',
    INITIALIZE_CREATE_WORK_ITEM_SUCCESS: 'INITIALIZE_CREATE_WORK_ITEM_SUCCESS',
    INITIALIZE_CREATE_WORK_ITEM_FAILURE: 'INITIALIZE_CREATE_WORK_ITEM_FAILURE',
    
    SHOW_WORK_ITEM_CREATE_WINDOW: 'SHOW_WORK_ITEM_CREATE_WINDOW',
    CLOSE_WORK_ITEM_CREATE_WINDOW: 'CLOSE_WORK_ITEM_CREATE_WINDOW',

    CREATE_WORK_ITEM_REQUEST: 'CREATE_WORK_ITEM_REQUEST',
    CREATE_WORK_ITEM_SUCCESS: 'CREATE_WORK_ITEM_SUCCESS',
    CREATE_WORK_ITEM_FAILURE: 'CREATE_WORK_ITEM_FAILURE',

    UPDATE_WORK_ITEM_REQUEST: 'UPDATE_WORK_ITEM_REQUEST',
    UPDATE_WORK_ITEM_SUCCESS: 'UPDATE_WORK_ITEM_SUCCESS',
    UPDATE_WORK_ITEM_FAILURE: 'UPDATE_WORK_ITEM_FAILURE',

    UPDATE_WORK_ITEM_STATUS_REQUEST: 'UPDATE_WORK_ITEM_STATUS_REQUEST',
    UPDATE_WORK_ITEM_STATUS_SUCCESS: 'UPDATE_WORK_ITEM_STATUS_SUCCESS',
    UPDATE_WORK_ITEM_STATUS_FAILURE: 'UPDATE_WORK_ITEM_STATUS_FAILURE',

    DELETE_WORK_ITEM_REQUEST: 'DELETE_WORK_ITEM_REQUEST',
    DELETE_WORK_ITEM_SUCCESS: 'DELETE_WORK_ITEM_SUCCESS',
    DELETE_WORK_ITEM_FAILURE: 'DELETE_WORK_ITEM_FAILURE',  
    
    REMOVE_WORK_ITEM_REQUEST: 'REMOVE_WORK_ITEM_REQUEST',
    REMOVE_WORK_ITEM_SUCCESS: 'REMOVE_WORK_ITEM_SUCCESS',
    REMOVE_WORK_ITEM_FAILURE: 'REMOVE_WORK_ITEM_FAILURE',

    CHANGE_FILTER_WORK_ITEMS: 'CHANGE_FILTER_WORK_ITEMS',

    INITIALIZE_WORK_ITEMS_FULLCALENDAR: "INITIALIZE_WORK_ITEMS_FULLCALENDAR",
    GETALL_WORK_ITEM_FULLCALENDAR_REQUEST: "GETALL_WORK_ITEM_FULLCALENDAR_REQUEST",
    GETALL_WORK_ITEM_FULLCALENDAR_SUCCESS: "GETALL_WORK_ITEM_FULLCALENDAR_SUCCESS",
    GETALL_WORK_ITEM_FULLCALENDAR_FAILURE: "GETALL_WORK_ITEM_FULLCALENDAR_FAILURE",

    INITIALIZE_WORK_ITEMS_BOARD: "INITIALIZE_WORK_ITEMS_BOARD",
    WORK_ITEMS_BOARD_CHANGE_EMPLOYEES : "WORK_ITEMS_BOARD_CHANGE_EMPLOYEES",
    GETALL_WORK_ITEM_BOARD_REQUEST: "GETALL_WORK_ITEM_BOARD_REQUEST",
    GETALL_WORK_ITEM_BOARD_SUCCESS: "GETALL_WORK_ITEM_BOARD_SUCCESS",
    GETALL_WORK_ITEM_BOARD_FAILURE: "GETALL_WORK_ITEM_BOARD_FAILURE",

    INITIALIZE_WORK_ITEMS_ACTIVITIES: "INITIALIZE_WORK_ITEMS_ACTIVITIES",
    GETALL_WORK_ITEM_COMPLETED_REQUEST: "GETALL_WORK_ITEM_COMPLETED_REQUEST",
    GETALL_WORK_ITEM_COMPLETED_SUCCESS: "GETALL_WORK_ITEM_COMPLETED_SUCCESS",
    GETALL_WORK_ITEM_COMPLETED_FAILURE: "GETALL_WORK_ITEM_COMPLETED_FAILURE",

    GETALL_WORK_ITEM_PENDING_REQUEST: "GETALL_WORK_ITEM_PENDING_REQUEST",
    GETALL_WORK_ITEM_PENDING_SUCCESS: "GETALL_WORK_ITEM_PENDING_SUCCESS",
    GETALL_WORK_ITEM_PENDING_FAILURE: "GETALL_WORK_ITEM_PENDING_FAILURE",

    INITIALIZE_WORK_ITEMS_PROJECT_CALEDAR: "INITIALIZE_WORK_ITEM_PROJECT_CALEDAR",
    GETALL_WORK_ITEM_PROJECT_CALEDAR_REQUEST: "GETALL_WORK_ITEM_PROJECT_CALEDAR_REQUEST",
    GETALL_WORK_ITEM_PROJECT_CALEDAR_SUCCESS: "GETALL_WORK_ITEM_PROJECT_CALEDAR_SUCCESS",
    GETALL_WORK_ITEM_PROJECT_CALEDAR_FAILURE: "GETALL_WORK_ITEM_PROJECT_CALEDAR_FAILURE",


    INITIALIZE_TIME_SHEET_USER_TEAMS: 'INITIALIZE_TIME_SHEET_USER_TEAMS',
    
    GET_TIMESHEET_DATA_REQUEST: 'GET_TIMESHEET_DATA_REQUEST',
    GET_TIMESHEET_DATA_SUCCESS: 'GET_TIMESHEET_DATA_SUCCESS',
    GET_TIMESHEET_DATA_FAILURE: 'GET_TIMESHEET_DATA_FAILURE',

    ADD_TIMESHEET_DATA_REQUEST:'ADD_TIMESHEET_DATA_REQUEST',
    ADD_TIMESHEET_DATA_SUCCESS:'ADD_TIMESHEET_DATA_SUCCESS',
    ADD_TIMESHEET_DATA_FAILURE:'ADD_TIMESHEET_DATA_FAILURE',

    INITIALIZE_CREATE_NONPROJECT_REQUEST: 'INITIALIZE_CREATE_NONPROJECT_REQUEST',
    INITIALIZE_CREATE_NONPROJECT_SUCCESS: 'INITIALIZE_CREATE_NONPROJECT_SUCCESS',
    INITIALIZE_CREATE_NONPROJECT_FAILURE: 'INITIALIZE_CREATE_NONPROJECT_FAILURE',

    ADD_NONPROJECT_DATA_REQUEST:'ADD_NONPROJECT_DATA_REQUEST',
    ADD_NONPROJECT_DATA_SUCCESS:'ADD_NONPROJECT_DATA_SUCCESS',
    
    SHOW_NONPROJECT_CREATE_WINDOW: 'SHOW_NONPROJECT_CREATE_WINDOW',
    CLOSE_NONPROJECT_CREATE_WINDOW: 'CLOSE_NONPROJECT_CREATE_WINDOW',

    INITIALIZE_TASK_PLANNER_USER_TEAMS: 'INITIALIZE_TASK_PLANNER_USER_TEAMS',
    CHANGE_FILTER_TASK_PLANNER: 'CHANGE_FILTER_TASK_PLANNER',
    SHOW_TASKPLANNER_CREATE_WINDOW: 'SHOW_TASKPLANNER_CREATE_WINDOW',
    CLOSE_TASKPLANNER_CREATE_WINDOW: 'CLOSE_TASKPLANNER_CREATE_WINDOW',

    SHOW_TODAYS_ACTIVITY_WINDOW: 'SHOW_TODAYS_ACTIVITY_WINDOW',
    CLOSE_TODAYS_ACTIVITY_WINDOW: 'CLOSE_TODAYS_ACTIVITY_WINDOW',

    UPDATE_TASK_ACTIVITY_STATUS_REQUEST: 'UPDATE_TASK_ACTIVITY_STATUS_REQUEST',
    UPDATE_TASK_ACTIVITY_STATUS_SUCCESS: 'UPDATE_TASK_ACTIVITY_STATUS_SUCCESS',
    UPDATE_TASK_ACTIVITY_STATUS_FAILURE: 'UPDATE_TASK_ACTIVITY_STATUS_FAILURE',

    GETALL_CLONED_BY_WORK_ITEMS_REQUEST: 'GETALL_CLONED_BY_WORK_ITEMS_REQUEST',
    GETALL_CLONED_BY_WORK_ITEMS_SUCCESS: 'GETALL_CLONED_BY_WORK_ITEMS_SUCCESS',
    GETALL_CLONED_BY_WORK_ITEMS_FAILURE: 'GETALL_CLONED_BY_WORK_ITEMS_FAILURE',
    
};