import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import BidRequestsInfoLine from "./BidRequestsInfoLine";

class BidRequestsListTable extends Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table_custom table-nowrap table-vcenter table-borderless position-relative">
          <thead>
            <tr>
              <th>#</th>
              <th>Created Date</th>
              <th>Study Name</th>
              <th>Type</th>
              <th className="text-center">Study Type</th>
              <th className="text-center">Sales Force Id</th>
              <th className="">Kind of Studies</th>
              <th>Countries</th>
              <th className="text-center">Status</th>
              <th className="text-center">Action
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.isLoading &&
              <tr>
                <td colSpan="11">
                  <Skeleton count={7} height={40} />
                </td>
              </tr>
            }

            {this.props.items && this.props.items.map(item =>
              <BidRequestsInfoLine item={item} key={item.id}
                handleDeleteBidRequests={this.props.handleDeleteBidRequests}
                handleEditBidRequests={this.props.handleEditBidRequests}
                handleUpdateStatus={this.props.handleUpdateStatus}
                handleDuplicateBidRequests={this.props.handleDuplicateBidRequests}
                onViewBidRequests={this.props.onViewBidRequests}
                handleArchiveBidRequests={this.props.handleArchiveBidRequests}
                handleRemoveBidRequests={this.props.handleRemoveBidRequests}
                permission={this.props.permission}
              />
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
export default BidRequestsListTable;