import React, { Component } from "react";
import dateUtils from "_helpers/date.utils";
import ItemPropertyInfo from "views/project/components/fragments/ItemPropertyInfo";
import classNames from 'classnames/bind';
import SelectInput from "views/common/fragments/formfields/SelectInput";
import ItemCommentsSection from "views/project/components/fragments/ItemCommentsSection";
import { bidRequestsActions } from "_actions";
import { connect } from "react-redux";
import { store } from "_helpers";
import CreateBidRequestsSidebarSection from "./modal/CreateBidRequestsSidebarSection";
//TODO: NOT USING
class BidRequestsDetailsGeneral extends Component {
    handleUpdateStatus = (bidRequestId, status) => {
        store.dispatch(this.props.updateBidRequestsStatus(bidRequestId, status));
    }
    render() {
        return (
            <>
                {this.props.item &&
                    <div className="card h-100 mb-0 pl-2">
                        <div className="card-header pt-1 pl-0  pb-2 border-bottom">
                            <span>
                                <label className="d-block fsize14 colorlightgreen">{this.props.item.ownerUserTeamDetails.name}</label>
                                <span className="card-title text-normal-case fsize20 ">
                                    <i className="fa fa fa-newspaper-o fa-lg mr-1 colorlightgreen"></i>
                                    <span className=""></span>{this.props.item.studyName}
                                </span>
                            </span>
                            <div className="card-options">
                                <SelectInput
                                    options={[
                                        { value: "NEW", label: "New" },
                                        { value: "ASSIGNED", label: "Assinged" },
                                        { value: "INPROGRESS", label: "In Progress" },
                                        { value: "WON", label: "Won" },
                                        { value: "LOST", label: "Lost" },
                                        { value: "CANCELLED", label: "Canceled" },
                                        { value: "REJECTED", label: "Rejected" },
                                        { value: "CLOSED", label: "Closed" }]}
                                    value={this.props.item.status}
                                    allowLevelDown={this.props.permission.allowStatusLevelDown}
                                    changeableStatus={this.props.permission.changeableStatus}
                                    isReadOnly={(!this.props.permission.allowStatusChange) || this.props.readOnly}
                                    itemId={this.props.item.id}
                                    onChange={this.handleUpdateStatus}
                                    className={classNames("tag",
                                        { "tag-info": this.props.item.status === 'NEW' },
                                        { "tag-primary": this.props.item.status === 'ASSIGNED' },
                                        { "tag-warning": this.props.item.status === 'INPROGRESS' },
                                        { "tag-success": this.props.item.status === 'WON' },
                                        { "tag-secondary": this.props.item.status === 'LOST' },
                                        { "tag-danger": this.props.item.status === 'CANCELLED' },
                                        { "tag-dark": this.props.item.status === 'REJECTED' },
                                        { "tag-dark": this.props.item.status === 'CLOSED' }
                                    )} />
                                <div className="item-action dropdown ml-2 show">
                                    <button className="link" data-toggle="dropdown" aria-expanded="true"><i className="fe fe-more-vertical"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right dropdownrightmenu" x-placement="top-end">
                                        <button className="dropdown-item link"><i className="dropdown-icon fa fa-cloud-download"></i> Download</button>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item link"><i className="dropdown-icon fa fa-edit"></i> Edit</button>
                                        <button className="dropdown-item link"><i className="dropdown-icon fa fa-paste"></i> Archive</button>
                                        <button className="dropdown-item link"><i className="dropdown-icon fa fa-trash"></i> Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body pt-3 pb-1">
                            <div className="row m-0 p-0">
                                <div className="col-9 py-0 justify-content-between flex-column d-flex">
                                    {this.props.item &&
                                        <CreateBidRequestsSidebarSection
                                            permission={this.props.permission}
                                            editMode={true}
                                            readOnly={true}
                                            duplicateMode={false}
                                            selectedBidRequestsInfo={this.props.item}
                                        />
                                    }
                                </div>
                                <div className="col-3 pl-3">
                                    <div className="form-group mb-1">
                                        <label className="text-muted fsize13 w150">BidRequests Owner:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{(this.props.item.ownerDetails && this.props.item.ownerDetails.name) || 'NA'}</span>
                                    </div>

                                    <div className="form-group mb-1 ">
                                        <label className="text-muted fsize13 w150">Field Time</label>
                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.fieldTime}</span>
                                    </div>

                                    <div className="form-group  mb-1">
                                        <label className="text-muted fsize13 w150">Launch Time:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{this.props.item.launchTime}</span>
                                    </div>


                                    <div className="form-group  mb-1">
                                        <label className="text-muted fsize13 w150">Created Date:</label>
                                        <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateTimeFormat(this.props.item.createdDate)}</span>
                                    </div>
                                    {this.props.item.lastModifiedDate &&
                                        <div className="form-group  mb-1">
                                            <label className="text-muted fsize13 w150">Updated Date:</label>
                                            <span className="fsize13 fweight600 menu-text-black">{dateUtils.convertDateTimeFormat(this.props.item.lastModifiedDate)}</span>
                                        </div>
                                    }
                                    {this.props.item.createdByDetails &&
                                        <div className="form-group  mb-1">
                                            <label className="text-muted fsize13 w150">Created By:</label>
                                            <span className="fsize13 fweight600 menu-text-black">{this.props.item.createdByDetails.name}</span>
                                        </div>
                                    }
                                    {this.props.item.updatedByDetails &&
                                        <div className="form-group  mb-1">
                                            <label className="text-muted fsize13 w150">Updated By:</label>
                                            <span className="fsize13 fweight600 menu-text-black">{this.props.item.updatedByDetails.name}</span>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        <ItemCommentsSection
                            itemId={this.props.item.id}
                            subType={"BID_REQUEST"}
                        />

                    </div>
                }
            </>
        );
    }
}
const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
    updateBidRequestsStatus: bidRequestsActions.updateBidRequestsStatus,
});
export default connect(mapStateToProps, mapDispatchToProps)(BidRequestsDetailsGeneral);
