import React, { Component } from "react";
import { connect } from "react-redux";
import { bidRequestsActions } from "_actions";
import CreateBidRequestsSidebarSection from "./CreateBidRequestsSidebarSection";

class CreateBidRequestsSidebar extends Component {

  render() {
    return (
      <div id="createbidRequestsidebar" className={`right_sidebar create_bid_request_right_sidebar  ${this.props.show ? "open" : ""}`}>
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title"> {this.props.editMode ? "Update" : this.props.duplicateMode ? "Duplicate" : "Create"} Bid Requests</h3>
            <div className="card-options">
              <button onClick={this.props.closeActionHandler} className="link p-2 create-bidRequests-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          {this.props.show && ((this.props.editMode && this.props.selectedBidRequestsInfo) || (this.props.duplicateMode && this.props.selectedBidRequestsInfo) || (!this.props.editMode && !this.props.duplicateMode)) &&

            <CreateBidRequestsSidebarSection
              handleSubmit={this.props.handleSubmit}
              editMode={this.props.editMode}
              duplicateMode={this.props.duplicateMode}
              selectedBidRequestsInfo={this.props.selectedBidRequestsInfo}
            />
          }

        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentUser: state.authentication.user,
  fieldConfig: state.bidRequests.fieldConfig,
  createConfig: state.bidRequests.createConfig,
  allEmployees: state.employees.allEmployees,
  creating: state.bidRequests.creating,
  updating: state.bidRequests.updating,
  screeningQuestions: state.bidRequests.screeningQuestions,
  additionalQuestions: state.bidRequests.additionalQuestions,
  quotaInformation: state.bidRequests.quotaInformation,
  studyTypeConfig: state.bidRequests.studyTypeConfig,
});

const mapDispatchToProps = dispatch => ({
  getModuleConfig: bidRequestsActions.getModuleConfig
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBidRequestsSidebar);

