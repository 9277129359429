import React, { Component } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import dateUtils from "_helpers/date.utils";
import classNames from 'classnames/bind';
import { commonUtils } from "_helpers";
import ItemPercentageOfCompletion from "./fragments/ItemPercentageOfCompletion";
import { NavLink } from "react-router-dom";
import SelectInput from "views/common/fragments/formfields/SelectInput";
//https://github.com/djorg83/react-bootstrap-sweetalert/blob/master/src/demo/src/Demo.tsx
class TaskDetailsItemLineInfo extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }
  handleRemoveTask = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveTask(this.props.task.id);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (
      <tr>
        <td>
          <span className="fsize15 pr-2">
            <i className={classNames("", { "fe fe-x-square colororange": this.props.task.status === 'NEW' },
              { "fa fa-spinner colororange": this.props.task.status === 'ASSIGNED' },
              { "fa fa-spinner fa-spin colororange": this.props.task.status === 'INPROGRESS' },
              { "fe  fe-check-square colorgreen": this.props.task.status === 'COMPLETED' },
              { "fe fe-check-square colorgreen": this.props.task.status === 'CLOSED' }
            )}></i>
          </span>
          {this.props.task.itemIdUserTeamPrefix}</td>
        <td>

          <span className="mb-0 fsize12 fweight600">
            <span className="project-name-text pl-0 d-inline-block  w250" title={this.props.task.name}>
              <NavLink
                to={`/project/deliverable/${this.props.task.itemIdUserTeamPrefix}`}
                className="nav-link list-b pl-0"
                activeClassName="active"
              >
                {this.props.task.name}
              </NavLink>
            </span>
            {/*[
            <button className="link"
           onClick={() => this.props.onSelectTask(this.props.task.id)}>{this.props.task.id}</button>] 
            */ }

          </span>
          <span className="text-muted d-block">{this.props.task.description}</span>
        </td>
        <td>
          {this.props.task.category}
        </td>
        <td>
          {this.props.task.taskActivityEmployeeIds &&
            <ul className="list-unstyled team-info mb-0">
              {this.props.task.taskActivityEmployeeIds.map(employeeId =>
                <li key={employeeId}><img src="/assets/images/male.jpg" onError={() => this.src = "/assets/images/male.jpg"} /></li>
              )}
            </ul>
          }
        </td>
        <td>
          <div className="text-info">{dateUtils.convertDateFormat(this.props.task.estStartTime)}</div>
          <div className="text-pink">{dateUtils.convertDateFormat(this.props.task.estCompletionTime)}</div>
        </td>
        <td>
          <SelectInput
            options={[
              { value: "NEW", label: "New", level: 0 },
              { value: "ASSIGNED", label: "Assigned", level: 1 },
              { value: "INPROGRESS", label: "In Progress", level: 2 },
              { value: "COMPLETED", label: "Completed", level: 3 },
              { value: "CLOSED", label: "Closed", level: 4 }]}
            value={this.props.task.status}
            allowLevelDown={this.props.permission.allowStatusLevelDown}
            changeableStatus={this.props.permission.changeableStatus}
            isReadOnly={!this.props.permission.allowStatusChange}
            itemId={this.props.task.id}
            onChange={this.props.handleUpdateStatus}
            className={classNames("badge",
              { "badge-info": this.props.task.status === 'NEW' },
              { "badge-primary": this.props.task.status === 'ASSIGNED' },
              { "badge-warning": this.props.task.status === 'INPROGRESS' },
              { "badge-success": this.props.task.status === 'COMPLETED' },
              { "badge-dark": this.props.task.status === 'CLOSED' }
            )} />
        </td>
        <td>
          <ItemPercentageOfCompletion
            totalCount={this.props.task.taskActivityTotalCount}
            completedCount={this.props.task.completedTaskActivityCount}
          />
        </td>
        <td className="text-center">

          {this.props.permission.archiveEnabled === true &&
            <label className=" custom-switch custom-switch-sm m-0">
              <input type="checkbox" onChange={e => { }}
                onClick={() => this.props.handleActivateTask(this.props.task.id, !this.props.task.active)}
                className="custom-switch-input" checked={this.props.task.active ? true : false} />
              <span className="custom-switch-indicator" />
            </label>
          }
          {this.props.permission.viewEnabled === true &&
            <button className="link pl-2" title="View"
              onClick={(e) => this.props.onViewTask(this.props.task.id)}
            ><i className="fa fa-eye align-text-top fa-lg"></i></button>
          }
          {this.props.task.active && this.props.permission.editEnabled === true &&
            <>
              {(this.props.task.status !== 'COMPLETED' && this.props.task.status !== 'CLOSED') &&
                < button type="button" className="link pl-2" title="Edit"
                  onClick={(e) => this.props.handleEditTask(this.props.task.id, this.props.projectId, this.props.task.userTeamId)}
                ><i className="fa fa-edit" /></button>
              }
              {(this.props.task.status === 'COMPLETED' || this.props.task.status === 'CLOSED') &&
                <i className="pl-2 fa fa-edit text-muted" />
              }
            </>
          }
          {this.props.permission.duplicateEnabled === true &&
            < button type="button" className="link pl-2" title="Duplicate"
              onClick={(e) => this.props.handleDuplicateTask(this.props.task.id, this.props.projectId, this.props.task.userTeamId)}
            ><i className="fa fa-clone" /></button>
          }
          {this.props.permission.deleteEnabled === true &&
            <button type="button"
              onClick={(e) => this.props.handleDeleteTask(this.props.task.id, !this.props.task.deleted)}
              className="link pl-2" title={this.props.task.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
              {this.props.task.deleted &&
                <>
                  <i className="fa fa-rotate-left colorred blink_text"></i>
                </>
              }
              {!this.props.task.deleted &&
                <>
                  <i className="fa fa-trash"></i>
                </>
              }
            </button>
          }
          {this.props.permission.removeEnabled === true &&

            <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveTask(this.props.task.id)}>
              {this.state.confrimDelete === true &&
                <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
              }
              {this.state.confrimDelete === false &&
                <i className="dropdown-icon fa fa-times colorred"></i>
              }
            </button>
          }
        </td>
      </tr >
    );
  }
}

export default TaskDetailsItemLineInfo;
