import { commonUtils } from "./common.utils";
import dateUtils from "./date.utils";


const workItemUtils = {
    getWorkItemCreateStartMinDate,
    getWorkItemCreateEndMaxDate,
    getServiceLines,
    getTaskCategories,
    getWorkItemCategories,
    filterNonMandatoryFields,
    isWorkItemEditActive,
    getEmployeePrimaryPractice,
    getEmployeeSecondaryPractice
}

function getWorkItemMinDate(beforeXWorkingDays, disableXDaysBeforeHour, currentStartMoment) { // private method
    var currentMoment = dateUtils.getCurrentTime().clone().startOf('day');
    if (beforeXWorkingDays === undefined || beforeXWorkingDays === null) {
        beforeXWorkingDays = -1
    }
    beforeXWorkingDays = parseInt(beforeXWorkingDays);
    if (beforeXWorkingDays > -1) {
        if (beforeXWorkingDays !== 0 && disableXDaysBeforeHour) {
            var currentTimeMoment = dateUtils.getCurrentTime().clone();
            var currentTmpTimeMoment = dateUtils.getCurrentTime().clone();
            currentTmpTimeMoment.set('hour', parseInt(disableXDaysBeforeHour));
            currentTmpTimeMoment.set('minute', 0);
            currentTmpTimeMoment.set('second', 0);
            currentTmpTimeMoment.set('millisecond', 0);
            if (currentTmpTimeMoment.diff(currentTimeMoment) < 0) {
                beforeXWorkingDays = beforeXWorkingDays - 1;
            }
        }
        let tempDaysCount = beforeXWorkingDays;
        let startDate = currentMoment;
        while (tempDaysCount > 0) {
            startDate = startDate.subtract(1, "days").startOf('day');
            if (startDate.weekday() !== 6 && startDate.weekday() !== 5) {
                tempDaysCount = tempDaysCount - 1;
            }
        }
        return currentStartMoment.diff(startDate) > 0 ? currentStartMoment : startDate;
    }
    return currentStartMoment;
}

function getWorkItemMaxDate(afterXWorkingDays, currentEndMoment) { // private method
    var currentMoment = dateUtils.getCurrentTime().clone().endOf('day');
    if (afterXWorkingDays === undefined || afterXWorkingDays === null) {
        afterXWorkingDays = -1
    }
    afterXWorkingDays = parseInt(afterXWorkingDays);
    if (afterXWorkingDays > -1) {
        let tempDaysCount = afterXWorkingDays;
        let endDate = currentMoment;
        while (tempDaysCount > 0) {
            endDate = currentMoment.add(1, "days").endOf('day');
            if (endDate.weekday() !== 6 && endDate.weekday() !== 5) {
                tempDaysCount = tempDaysCount - 1;
            }
        }
        return currentEndMoment.diff(endDate) < 0 ? currentEndMoment : endDate;
    }
    return currentEndMoment;
}

function getWorkItemCreateStartMinDate(startMoment, permission) {
    var currentStartMoment = startMoment && startMoment.clone().startOf('day');
    return {
        "nonProjectStart": getWorkItemMinDate(permission.disableNonProjectWorkItemCreateBeforeXWorkingDays, permission.disableWorkItemCreateBeforeYWorkingDaysAfterXHours, currentStartMoment),
        "nonProjectAssignStart": getWorkItemMinDate(permission.disableNonProjectWorkItemAssignBeforeXWorkingDays, permission.disableWorkItemCreateBeforeYWorkingDaysAfterXHours, currentStartMoment),
        "projectStart": getWorkItemMinDate(permission.disableProjectWorkItemCreateBeforeXWorkingDays, permission.disableWorkItemCreateBeforeYWorkingDaysAfterXHours, currentStartMoment),
        "projectAssignStart": getWorkItemMinDate(permission.disableProjectWorkItemAssignBeforeXWorkingDays, permission.disableWorkItemCreateBeforeYWorkingDaysAfterXHours, currentStartMoment),
        "actualStartDate": startMoment
    }
}

function getWorkItemCreateEndMaxDate(endMoment, permission) {
    var currentEndMoment = endMoment && endMoment.clone().endOf('day');;
    return {
        "nonProjectEnd": getWorkItemMaxDate(permission.disableNonProjectWorkItemCreateAfterXWorkingDays, currentEndMoment),
        "nonProjectAssignEnd": getWorkItemMaxDate(permission.disableNonProjectWorkItemAssignAfterXWorkingDays, currentEndMoment),
        "projectEnd": getWorkItemMaxDate(permission.disableProjectWorkItemCreateAfterXWorkingDays, currentEndMoment),
        "projectAssignEnd": getWorkItemMaxDate(permission.disableProjectWorkItemAssignAfterXWorkingDays, currentEndMoment),
        "actualEndDate": endMoment
    }
}

function getServiceLines(appConfig) {
    return appConfig && commonUtils.convertConfigListToSelectObject(commonUtils.mapKeysToList(commonUtils.getAppConfigValue(appConfig, "categoryMap")))
}
function getTaskCategories(appConfig, projectCategory) {
    let projectCategories = appConfig && commonUtils.getAppConfigValue(appConfig, "categoryMap");
    return projectCategories && commonUtils.convertConfigListToSelectObject(commonUtils.mapKeysToList(projectCategories[projectCategory]))
}

function getWorkItemCategories(appConfig, serviceLine, taskCategory) {
    let projectCategories = appConfig && commonUtils.getAppConfigValue(appConfig, "categoryMap");
    const taskCategoryItem = projectCategories && serviceLine && taskCategory && projectCategories[serviceLine] && projectCategories[serviceLine][taskCategory];
    return taskCategoryItem && taskCategoryItem && commonUtils.convertConfigListToSelectObject(commonUtils.mapKeysToList(taskCategoryItem))
}

function filterNonMandatoryFields(nonProjectFieldConfig, categoryName, nonProjectAppConfig) {
    let excludeAdditionalFieldCategories = commonUtils.getAppConfigValue(nonProjectAppConfig, "excludeAdditionalFieldsForCategory")
    if (nonProjectFieldConfig && categoryName && nonProjectAppConfig && excludeAdditionalFieldCategories) {
        return nonProjectFieldConfig.map(nonBillableField => {
            excludeAdditionalFieldCategories = excludeAdditionalFieldCategories.map(field => field.toLowerCase());
            if (nonBillableField.isAdditionalField && excludeAdditionalFieldCategories.includes(categoryName.toLowerCase())) {
                return { ...nonBillableField, mandatory: false, enabled: false }
            } else {
                return nonBillableField;
            }
        })
    }
    return nonProjectFieldConfig;

}

function isWorkItemEditActive(actualStartTime, hoursToBeAdded) {
    let skipWeekEnds = true;
    let currentMoment = dateUtils.getCurrentTime();
    if (actualStartTime && hoursToBeAdded) {
        let validTillMoment = dateUtils.convertDateStringToMoment(actualStartTime).clone().endOf('day').add(hoursToBeAdded, "hours");
        if (skipWeekEnds === true) {
            if (validTillMoment.weekday() === 6) {
                validTillMoment.add(1, "days");
            }
            if (validTillMoment.weekday() === 5) {
                validTillMoment.add(2, "days");
            }
        }
        return currentMoment.diff(validTillMoment) < 0;
    }
    return false;
}


function getEmployeePrimaryPractice(appConfig) {
    return appConfig && commonUtils.convertConfigListToSelectObject(commonUtils.mapKeysToList(commonUtils.getAppConfigValue(appConfig, "practiceMap")))
}

function getEmployeeSecondaryPractice(appConfig, primaryPractice) {
    let practices = appConfig && commonUtils.getAppConfigValue(appConfig, "practiceMap");
    return practices &&  primaryPractice && commonUtils.convertConfigListToSelectObject(commonUtils.mapKeysToList(practices[primaryPractice]))
}


export default workItemUtils;