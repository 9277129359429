import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { validationUtils } from "_helpers";
import { store } from "_helpers";
import Select from "react-select";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import { projectActions } from "_actions";
import { workItemActions } from "_actions";
import SidebarFooter from "./SidebarFooter";
import workItemUtils from "_helpers/workitem.utils";

class CreateWorkItemSidebar extends Component {
    constructor() {
        super();
        this.state = {
            selectedProjectOption: null,
            selectedDeliverableOption: null,
            selectedTypeOption: null,
            selectedEmployeeOption: {
                ...commonUtils.getCurrentUser(), value: commonUtils.getCurrentUserId(), label: commonUtils.getCurrentUserName()
            },
            selectedStartTimeOption: { value: "8:00", label: "08:00 AM" },
            selectedStatusOption: { value: 'COMPLETED', label: 'Completed' },

            createWorkItemsRequest: {},
            skipWeekends: true,
            formErrors: {},
            hasFormError: false
        }
    }
    componentDidMount() {
        if (this.props.defaultSelectedStatusOption) {
            this.setState({
                selectedStatusOption: this.getDefaultSelectedStatusOption()
            })
        }
    }

    onResetFormError = () => {
        this.setState({
            hasFormError: false
        })
    }

    clearActionHandler = (isCreateAnotherEnabled, keepDataEnabled) => {
        if (keepDataEnabled === undefined|| keepDataEnabled === false) {
            this.setState({
                selectedProjectOption: null,
                selectedDeliverableOption: null,
                selectedTypeOption: null,
                selectedStartTimeOption: { value: "8:00", label: "08:00 AM" },
                selectedStatusOption: this.getDefaultSelectedStatusOption(),
                createWorkItemsRequest: {},
                skipWeekends: true
            });
        }
        if (!isCreateAnotherEnabled) {
            this.props.closeActionHandler()
        }
    }

    submitCallbackfunction = (isCreateAnotherEnabled, keepDataEnabled) => {
        this.clearActionHandler(isCreateAnotherEnabled, keepDataEnabled);
        if (this.props.handleReloadWorkItems) {
            this.props.handleReloadWorkItems();
        }
    }

    closeActionHandler = () => {
        this.clearActionHandler();
        this.props.closeActionHandler();
    }

    getDefaultSelectedStatusOption = () => {
        if (this.props.permission.enableCreateCompletedWorkItem === true) {
            return { value: 'ASSIGNED', label: 'Assigned' };
        }
        return this.props.defaultSelectedStatusOption === "ASSIGNED" ? { value: 'ASSIGNED', label: 'Assigned' } : { value: 'COMPLETED', label: 'Completed' }
    }

    getSelectStatusOptions = () => {
        if (this.props.permission.enableCreateCompletedWorkItem === true) {
            return [{ value: 'ASSIGNED', label: 'Assigned' }, { value: 'COMPLETED', label: 'Completed' }]
        }
        return [{ value: 'ASSIGNED', label: 'Assigned' }]
    }

    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }

    onTimeEntryDelete = (dateString) => {
        let timeEntries = this.state.createWorkItemsRequest.timeEntry;
        delete timeEntries[dateString];
        this.setState(prevState => ({
            createWorkItemsRequest: {
                ...prevState.createWorkItemsRequest,
                timeEntry: timeEntries
            }
        }))
    }

    onTimeEntryValueChange = (dateString, valueInHours) => {
        if (!validationUtils.validateDecimalNumberic(valueInHours) || (valueInHours && (this.props.permission.maxHoursAllowedToEnter < parseFloat(valueInHours) || (parseFloat(valueInHours) - parseInt(valueInHours) > .59)))) {
            return;
        }
        let valueInMins = 0;
        if (valueInHours !== "") {
            valueInMins = (parseInt(valueInHours) * 60) + (Math.round((parseFloat(valueInHours) - parseInt(valueInHours)) * 100));
        }
        this.setState(prevState => ({
            createWorkItemsRequest: {
                ...prevState.createWorkItemsRequest,
                timeEntry: {
                    ...prevState.createWorkItemsRequest.timeEntry,
                    [dateString]: {
                        ...prevState.createWorkItemsRequest.timeEntry[dateString],
                        "timesheetTimeMins": valueInMins,
                        "timesheetTimeHours": valueInHours
                    }
                }
            }
        }))
    }
    onTaskActChange = (fieldName, value) => {
        this.onChange("workItemName", value);
        this.onChange(fieldName, value);
    }

    onSelectRequestingTeamOption = (fieldName, selectedRequestingTeamOption) => {
        this.setState({
            selectedRequestingTeamOption
        })
        this.onChange(fieldName, selectedRequestingTeamOption.label);
    }

    onChange = (fieldName, value) => {
        this.onResetFormError()
        this.setState(prevState => ({
            createWorkItemsRequest: {
                ...prevState.createWorkItemsRequest,
                [fieldName]: value
            }
        }), () => {
            let dateRangeObject = {};
            if (fieldName === 'startDate' || fieldName === 'endDate' || fieldName === 'skipWeekends' || fieldName === 'workItemStartTime' || fieldName === 'workItemStatus' || fieldName === "deliverable" || fieldName === "skill" || fieldName === "quantity" || fieldName === "description") {
                if (this.state.createWorkItemsRequest.startDate && this.state.createWorkItemsRequest.endDate) {
                    let startDateMoment = dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.startDate);
                    let endDateMoment = dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.endDate);
                    if (dateUtils.compareTwoMoments(startDateMoment.startOf('day'), endDateMoment.endOf('day')) === -1) {
                        this.setState(prevState => ({
                            createWorkItemsRequest: {
                                ...prevState.createWorkItemsRequest,
                                [fieldName === 'startDate' ? 'endDate' : fieldName]: undefined,
                                timeEntry: {}
                            }
                        }));
                    } else {
                        let startDate = this.state.createWorkItemsRequest.startDate;
                        let endDate = this.state.createWorkItemsRequest.endDate;
                        let dateRangeArray = (this.state.createWorkItemsRequest.timeEntry && Object.keys(this.state.createWorkItemsRequest.timeEntry).map(te => { return { "value": te, "dayNumber": this.state.createWorkItemsRequest.timeEntry[te].dayNumber } })) || [];
                        if (fieldName !== "skill" && fieldName !== "quantity" && fieldName !== "description") {
                            if (this.state.selectedDeliverableOption && this.state.selectedDeliverableOption.estStartTime && this.state.selectedDeliverableOption.estCompletionTime) {
                                const estStartTimeMoment = dateUtils.getDayStart(dateUtils.convertDateStringToMoment(this.state.selectedDeliverableOption.estStartTime))
                                const estTaskCompletionTimeMoment = dateUtils.getDayEnd(dateUtils.convertDateStringToMoment(this.state.selectedDeliverableOption.estCompletionTime));
                                startDate = dateUtils.getDayStart(dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.startDate));
                                endDate = dateUtils.getDayEnd(dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.endDate));

                                if (estStartTimeMoment.diff(startDate) > 1) {
                                    startDate = estStartTimeMoment.clone().format(dateUtils.SERVER_DATE_FORMAT)
                                }

                                if (endDate.diff(estTaskCompletionTimeMoment) > 1) {
                                    endDate = estTaskCompletionTimeMoment.clone().format(dateUtils.SERVER_DATE_FORMAT)
                                }

                            }
                            dateRangeArray = dateUtils.getDateRangeArray(startDate, endDate, this.state.skipWeekends);
                        }
                        dateRangeArray.forEach(dateObject => {
                            /*if(this.state.selectedDeliverableOption){
                                let deliverableStartDate = dateUtils.convertDateStringToMoment(this.state.selectedDeliverableOption.estStartTime);
                                let deliverableEndDate =  dateUtils.convertDateStringToMoment(this.state.selectedDeliverableOption.estCompletionTime);
                                let currentDate = dateUtils.convertDateToMoment(dateObject)
                                if(dateUtils.compareTwoMoments(currentDate, deliverableStartDate) < 0 && dateUtils.compareTwoMoments(currentDate, deliverableEndDate) > 0){
                                    return;
                                }
                            }*/
                            dateRangeObject = {
                                ...dateRangeObject,
                                [dateObject.value]: {
                                    "timesheetTimeMins": (this.state.createWorkItemsRequest.timeEntry && this.state.createWorkItemsRequest.timeEntry[dateObject.value] && this.state.createWorkItemsRequest.timeEntry[dateObject.value].timesheetTimeMins) || 480,
                                    "timesheetTimeHours": (this.state.createWorkItemsRequest.timeEntry && this.state.createWorkItemsRequest.timeEntry[dateObject.value] && this.state.createWorkItemsRequest.timeEntry[dateObject.value].timesheetTimeHours) || 8,
                                    "isNew": true,
                                    "dayNumber": dateObject.dayNumber,
                                    "skill": this.state.createWorkItemsRequest.skill,
                                    "comments": this.state.createWorkItemsRequest.description,
                                    "quantity": this.state.createWorkItemsRequest.quantity,
                                    "workItemStartTime": this.state.selectedStartTimeOption.value,
                                    "workItemStatus": this.state.selectedStatusOption.value
                                }
                            }
                        });
                        this.setState(prevState => ({
                            createWorkItemsRequest: {
                                ...prevState.createWorkItemsRequest,
                                timeEntry: dateRangeObject
                            }
                        }))
                        if (fieldName === 'startDate' || fieldName === 'endDate') {
                            this.reloadProjectListService()
                        }
                    }
                } else if (fieldName === 'startDate' || fieldName === 'endDate') {
                    this.setState(prevState => ({
                        createWorkItemsRequest: {
                            ...prevState.createWorkItemsRequest,
                            timeEntry: null
                        }
                    }))
                }
            } else if (fieldName === 'taskActCategory' && (this.state.createWorkItemsRequest.name === null || this.state.createWorkItemsRequest.name === undefined || this.state.createWorkItemsRequest.name === "")) {
                this.setState(prevState => ({
                    createWorkItemsRequest: {
                        ...prevState.createWorkItemsRequest,
                        name: value
                    }
                }))
            }

        })
    }

    getDateSelectionMinDate = (minDateObject) => {
        let workItemStartDate = null;
        if (minDateObject && this.state.selectedTypeOption && this.state.selectedTypeOption.value === 'nonProject') {
            if (this.props.permission.allowLeaveAndHolidayForPast && this.state.createWorkItemsRequest && this.state.createWorkItemsRequest.taskActCategory && (this.state.createWorkItemsRequest.taskActCategory.toLowerCase() === 'leave' || this.state.createWorkItemsRequest.taskActCategory.toLowerCase() === 'holiday')) {
                workItemStartDate = minDateObject.actualStartDate;
            } else if (this.state.selectedStatusOption && this.state.selectedStatusOption.value === 'COMPLETED') {
                workItemStartDate = minDateObject.nonProjectStart;
            } else {
                workItemStartDate = minDateObject.nonProjectAssignStart;
            }
        } else if (minDateObject && this.state.selectedTypeOption && this.state.selectedTypeOption.value === 'project') {
            if (this.state.selectedStatusOption && this.state.selectedStatusOption.value === 'COMPLETED') {
                workItemStartDate = minDateObject.projectStart;
            } else {
                workItemStartDate = minDateObject.projectAssignStart;
            }
        }
        return workItemStartDate;
    }

    getDateSelectionMaxDate = (maxDateObject) => {
        let workItemEndDate = null;
        if (maxDateObject && this.state.selectedTypeOption && this.state.selectedTypeOption.value === 'nonProject') {
            if (this.props.permission.allowLeaveAndHolidayForFuture && this.state.createWorkItemsRequest && this.state.createWorkItemsRequest.taskActCategory && (this.state.createWorkItemsRequest.taskActCategory.toLowerCase() === 'leave' || this.state.createWorkItemsRequest.taskActCategory.toLowerCase() === 'holiday')) {
                workItemEndDate = maxDateObject.actualEndDate;
            } else if (this.state.selectedStatusOption && this.state.selectedStatusOption.value === 'COMPLETED') {
                workItemEndDate = maxDateObject.nonProjectEnd;
            } else {
                workItemEndDate = maxDateObject.nonProjectAssignEnd;
            }
        } else if (maxDateObject && this.state.selectedTypeOption && this.state.selectedTypeOption.value === 'project') {
            if (this.state.selectedStatusOption && this.state.selectedStatusOption.value === 'COMPLETED') {
                workItemEndDate = maxDateObject.projectEnd;
            } else {
                workItemEndDate = maxDateObject.projectAssignEnd;
            }
        }
        return workItemEndDate;
    }

    validateDateSelection = () => {
        let dateMinDateMoment = this.getDateSelectionMinDate(this.props.minDate);
        let dateMaxDateMoment = this.getDateSelectionMaxDate(this.props.maxDate);
        let workItemStartDate = dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.startDate);
        let workItemEndDate = dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.endDate);
        let isValid = true;
        if (dateMinDateMoment && dateMaxDateMoment) {
            dateMinDateMoment = dateMinDateMoment.clone().startOf("day");
            dateMaxDateMoment = dateMaxDateMoment.clone().endOf("day");
            if (workItemStartDate && (workItemStartDate.diff(dateMinDateMoment) < 0 || workItemStartDate.diff(dateMaxDateMoment) > 0)) {
                this.onChange("startDate", null);
                isValid = false;
            }
            if (workItemEndDate && (workItemEndDate.diff(dateMinDateMoment) < 0 || workItemEndDate.diff(dateMaxDateMoment) > 0)) {
                this.onChange("endDate", null);
                isValid = false;
            }
        }
        return isValid;
    }

    onTypeSelection = (selectedTypeOption) => {
        this.setState(prevState => ({
            selectedTypeOption: selectedTypeOption,
            selectedProjectOption: null,
            selectedDeliverableOption: null,

        }), () => {
            const hasValidDate = this.validateDateSelection();
            if (selectedTypeOption.value === 'nonProject') {
                store.dispatch(this.props.initializeNonProjectWorkItem(this.props.permission.timeSheetNonBillableShowAllAccountsEnabled === true ? true : false, this.state.selectedEmployeeOption.userTeamId));
            } else {
                //store.dispatch(employeeActions.getModuleConfig(this.state.selectedEmployeeOption.userTeamId));
                store.dispatch(projectActions.getModuleConfig(this.state.selectedEmployeeOption.userTeamId));
                //store.dispatch(taskActions.getModuleNonProjectConfig(commonUtils.getCurrentUserTeamId(), false));
                //store.dispatch(appConfigActions.getAllTeams(true));
                if (hasValidDate) {
                    this.reloadProjectListService()
                }
            }
        })
    }
    reloadProjectListService = () => {
        if (this.state.createWorkItemsRequest.startDate && this.state.createWorkItemsRequest.endDate && this.state.selectedEmployeeOption && this.state.selectedTypeOption && this.state.selectedTypeOption.value !== 'nonProject') {
            this.setState({
                selectedProjectOption: null,
                selectedDeliverableOption: null
            })
            let startDateMoment = dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.startDate);
            let endDateMoment = dateUtils.convertDateStringToMoment(this.state.createWorkItemsRequest.endDate);

            const startDate = startDateMoment.format(dateUtils.SERVER_DATE_FORMAT);
            const endDate = endDateMoment.format(dateUtils.SERVER_DATE_FORMAT);
            const isOnlyUserTeams = this.props.permission.listOnlyCurrentTeamProject;
            store.dispatch(this.props.getProjectList({ userTeamIds: [this.state.selectedEmployeeOption.userTeamId], currentUserId: this.state.selectedEmployeeOption.id, startDate: startDate, endDate: endDate, isOnlyUserTeams }))
        }
    }

    onStartTimeSelection = (selectionOption) => {
        this.setState(prevState => ({
            selectedStartTimeOption: selectionOption
        }), () => { this.onChange("workItemStartTime", selectionOption.value) })
    }

    onToggleSkipWeekendEnabled = (skipWeekends) => {
        this.setState(prevState => ({
            skipWeekends: skipWeekends

        }), () => { this.onChange("skipWeekends", skipWeekends) })
    }
    onStatusSelection = (selectionOption) => {

        this.setState(prevState => ({
            selectedStatusOption: selectionOption
        }), () => {
            this.validateDateSelection();
            this.onChange("workItemStatus", selectionOption.value);
        })
    }

    onEmployeeSelection = (selectionOption) => {
        this.setState({
            selectedEmployeeOption: selectionOption
        }, () => this.reloadProjectListService())
    }

    onChangeProjectSelection = (projectSelectionOption) => {
        this.setState({
            selectedProjectOption: projectSelectionOption,
            selectedDeliverableOption: null
        })

    }

    onDeliverableSelect = (deliverableSelectionOption) => {
        this.setState(prevState => ({
            selectedDeliverableOption: deliverableSelectionOption
        }), () => {
            this.onChange("deliverable", deliverableSelectionOption.value);
            this.onChange("taskActCategory", null);
        })
    }

    getRestrictedSkillOptions = () => {
        let employeeSkills = commonUtils.getEmployeeSkills(this.state.selectedEmployeeOption);
        const restrictedByEmployeeSkills = this.state.selectedDeliverableOption && this.state.selectedDeliverableOption.restrictedByEmployeeSkills;

        if (this.props.permission.restrictedBySkillsEnabled === true && restrictedByEmployeeSkills && employeeSkills) {
            employeeSkills = employeeSkills.filter(skill => restrictedByEmployeeSkills.includes(skill));
        }
        return commonUtils.convertConfigListToSelectObject(employeeSkills);
    }

    getRestrictedDeliverables = (selectedProjectOption) => {
        let deliverables = selectedProjectOption.taskInfoList;
        if (this.props.permission.restrictedBySkillsEnabled === true) {
            const employeeSkills = commonUtils.getEmployeeSkills(this.state.selectedEmployeeOption);
            deliverables = selectedProjectOption.taskInfoList &&
                selectedProjectOption.taskInfoList.filter(deliverable => !deliverable.restrictedByEmployeeSkills || employeeSkills.some(skill => deliverable.restrictedByEmployeeSkills.includes(skill)));
            deliverables = deliverables && deliverables.filter(deliverable => !deliverable.restrictedByEmployeeIds || (this.state.selectedEmployeeOption && deliverable.restrictedByEmployeeIds.includes(this.state.selectedEmployeeOption.value)));
        }
        if (commonUtils.getAppConfigValue(this.props.projectAppConfig, "enableRestrictProjectByEmployeeServiceLine") && deliverables) {
            deliverables = deliverables.filter(deliverable => this.state.selectedEmployeeOption.serviceLines && this.state.selectedEmployeeOption.serviceLines.includes(deliverable.serviceLine));
        }
        return commonUtils.convertObjectToSelectObject(deliverables)
    }

    getRestrictedDeliverableOptions = () => {
        return commonUtils.convertObjectToSelectObject(this.getRestrictedDeliverables(this.state.selectedProjectOption))
    }

    handleAddNonProjectTasks = (isCreateAnotherEnabled, isKeepDataEnabled) => {
        let selectedEmployeeId = this.state.selectedEmployeeOption.id;
        let selectedUserTeamId = this.state.selectedEmployeeOption.userTeamId;
        let startDate = this.state.createWorkItemsRequest.startDate;
        let endDate = this.state.createWorkItemsRequest.endDate;
        let timeSheetRequest = {}
        if (this.state.selectedTypeOption.value === 'nonProject') {
            timeSheetRequest = {
                "nonProject": {
                    [this.state.createWorkItemsRequest.taskActCategory]: {
                        "tasks": [
                            {
                                "employeeId": selectedEmployeeId,
                                "userTeamId": selectedUserTeamId,
                                ...this.state.createWorkItemsRequest
                            }
                        ]
                    }
                }
            }
        } else {
            timeSheetRequest = {
                "project": {
                    [this.state.selectedProjectOption.value]: {
                        "projectName": this.state.selectedProjectOption.label,
                        "tasks": [
                            {
                                "employeeId": selectedEmployeeId,
                                "userTeamId": selectedUserTeamId,
                                "taskName": this.state.selectedDeliverableOption.label,
                                "taskId": this.state.selectedDeliverableOption.value,
                                ...this.state.createWorkItemsRequest
                            }
                        ]
                    }
                }
            }
        }
        store.dispatch(this.props.createWorkItems(startDate, endDate, [selectedEmployeeId], selectedEmployeeId, [], timeSheetRequest, () => { this.submitCallbackfunction(isCreateAnotherEnabled, isKeepDataEnabled) }))
    }

    getNonBillableFieldConfig = () => {
        return this.state.createWorkItemsRequest && workItemUtils.filterNonMandatoryFields(this.props.nonProjectFieldConfig, this.state.createWorkItemsRequest.taskActCategory, this.props.nonProjectAppConfig);
    }

    handleSubmit = (isCreateAnotherEnabled, isKeepDataEnabled) => {
        const isNonProject = this.state.selectedTypeOption && this.state.selectedTypeOption.value === 'nonProject';
        let fieldConfig = isNonProject ? this.getNonBillableFieldConfig() : this.props.projectFieldConfig;
        let validationResult = validationUtils.isValidRequest(fieldConfig, this.state.createWorkItemsRequest, this.state.formErrors);
        //console.log("Validating form " + validationResult + "  request ");

        let isValid = this.state.selectedStartTimeOption && this.state.selectedStatusOption && this.state.selectedEmployeeOption;
        isValid = isNonProject ? isValid : (isValid && this.state.selectedProjectOption && this.state.selectedDeliverableOption)
        if (validationResult && isValid) {
            this.handleAddNonProjectTasks(isCreateAnotherEnabled, isKeepDataEnabled)
        } else {
            this.setState({
                hasFormError: true
            })
        }
    }

    render() {
        let nonProjectAppConfig = this.getNonBillableFieldConfig()
        return (
            <div id="createprojectsidebar" className={`right_sidebar create_nonproject_right_sidebar ${this.props.show === true ? 'open' : ''}`}>
                <div className="justify-content-between flex-column d-flex h-100vh">
                    <div className="card-header py-0 border-bottom">
                        <h3 className="card-title">{this.props.label ? this.props.label : "Create Work Items"}</h3>
                        <div className="card-options">
                            <button onClick={this.closeActionHandler} className="p-2 create-project-close-link float-right link"><i className="fa fa-close" /></button>
                        </div>
                    </div>
                    <div className="card-body pl-3 pr-3 overflow-auto">
                        <div className="row">
                            <div className="col-12 py-0 pl-2 pr-2 justify-content-between flex-column d-flex">
                                <div className="top">
                                    <div className="row">


                                        <div className="col-12 pt-1 row">
                                            <div className="col-6 m-0 p-0">
                                                <div className="col-12 pt-0 row mb-1">
                                                    <div className="col-6 mb-1">
                                                        <label className="form-label text-muted">Employee Name<span className="form-required">*</span></label>
                                                        <Select
                                                            isSearchable={true}
                                                            isClearable={false}
                                                            placeholder={"Select Employee"}
                                                            classNamePrefix={'custom-select'}
                                                            isMulti={false}
                                                            menuPlacement={'auto'}
                                                            value={this.state.selectedEmployeeOption}
                                                            onChange={(selectedOption) => this.onEmployeeSelection(selectedOption)}
                                                            options={this.props.employees && this.props.permission.createWorkItemLogForOtherEmployees === true && commonUtils.convertObjectToSelectObject(this.props.employees)}
                                                        />
                                                    </div>
                                                    <div className="col-6 pt-0 mb-1">
                                                        <label className="form-label text-muted">Workitem Type<span className="form-required">*</span></label>
                                                        <Select
                                                            isSearchable={true}
                                                            isClearable={false}
                                                            placeholder={"Select Type"}
                                                            classNamePrefix={'custom-select'}
                                                            isMulti={false}
                                                            menuPlacement={'auto'}
                                                            value={this.state.selectedTypeOption}
                                                            onChange={(selectedOption) => this.onTypeSelection(selectedOption)}
                                                            options={[{ value: 'project', label: 'Billable' }, { value: 'nonProject', label: 'Non Billable' }]}
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.selectedTypeOption &&
                                                    <div className="col-12 pt-1 row">

                                                        <div className="col-6 mr-0 pr-0">
                                                            <CustomInput
                                                                type='dateselect'
                                                                label="Start Date"
                                                                name="startDate"
                                                                fieldConfig={this.props.nonProjectFieldConfig}
                                                                mappingObject={this.state.createWorkItemsRequest}
                                                                onChange={this.onChange}
                                                                time={false}
                                                                onFormError={this.onFormError}
                                                                minDate={this.props.minDate && dateUtils.toMomentToDate(this.getDateSelectionMinDate(this.props.minDate))}
                                                                maxDate={this.props.maxDate && dateUtils.toMomentToDate(this.getDateSelectionMaxDate(this.props.maxDate))}
                                                            />
                                                        </div>
                                                        <div className="col-6 mr-0 pr-0">
                                                            <CustomInput
                                                                type='dateselect'
                                                                label="End Date"
                                                                name="endDate"
                                                                fieldConfig={this.props.nonProjectFieldConfig}
                                                                mappingObject={this.state.createWorkItemsRequest}
                                                                onChange={this.onChange}
                                                                time={false}
                                                                onFormError={this.onFormError}
                                                                minDate={this.props.minDate && dateUtils.toMomentToDate(this.getDateSelectionMinDate(this.props.minDate))}
                                                                maxDate={this.props.maxDate && dateUtils.toMomentToDate(this.getDateSelectionMaxDate(this.props.maxDate))}
                                                            />
                                                        </div>

                                                    </div>
                                                }

                                                <div className="col-12 pt-0 row">
                                                    <div className="col-12 pt-0">
                                                        <CustomInput
                                                            type='text'
                                                            label="Name"
                                                            name="workItemName"
                                                            fieldConfig={this.props.nonProjectFieldConfig}
                                                            mappingObject={this.state.createWorkItemsRequest}
                                                            onChange={this.onChange}
                                                            placeholder="Work Item Name.."
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 pt-0 row mb-1">
                                                    <div className="col-4 pt-0">
                                                        <label className="form-label text-muted">{this.state.selectedStatusOption.value === "COMPLETED" ? 'Actual Start Time' : 'Est Start Time'}</label>
                                                        <Select
                                                            isSearchable={true}
                                                            isClearable={false}
                                                            placeholder={"Select Time"}
                                                            classNamePrefix={'custom-select'}
                                                            isMulti={false}
                                                            menuPlacement={'auto'}
                                                            value={this.state.selectedStartTimeOption}
                                                            onChange={(selectedOption) => this.onStartTimeSelection(selectedOption)}
                                                            options={dateUtils.getTimeSelectionOptions()}
                                                        />
                                                    </div>
                                                    <div className="col-4 pt-0">
                                                        <label className="form-label text-muted">Task Status</label>
                                                        <Select
                                                            isSearchable={true}
                                                            isClearable={false}
                                                            placeholder={"Select Status"}
                                                            classNamePrefix={'custom-select'}
                                                            isMulti={false}
                                                            menuPlacement={'auto'}
                                                            value={this.state.selectedStatusOption}
                                                            onChange={(selectedOption) => this.onStatusSelection(selectedOption)}
                                                            options={this.getSelectStatusOptions()}
                                                        />
                                                    </div>
                                                    <div className="col-4 text-center">
                                                        <label className="form-label text-muted">Skip Weekends</label>
                                                        <div className="form-group mb-2 ">

                                                            <label className=" custom-switch custom-switch-sm m-0">
                                                                <input type="checkbox" onChange={e => { }}
                                                                    onClick={() => this.onToggleSkipWeekendEnabled(!this.state.skipWeekends)}
                                                                    className="custom-switch-input" checked={this.state.skipWeekends ? true : false} />
                                                                <span className="custom-switch-indicator" />
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-6 m-0 p-0">

                                                {this.state.selectedTypeOption && this.state.selectedTypeOption.value !== 'nonProject' &&
                                                    <div className="col-12 pt-0 row mb-1">
                                                        <div className="col-6 pt-0 mr-0 pr-0">
                                                            <label className="form-label text-muted">Project Name<span className="form-required">*</span></label>
                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder={"Select Project"}
                                                                classNamePrefix={'custom-select'}
                                                                isMulti={false}
                                                                menuPlacement={'auto'}
                                                                value={this.state.selectedProjectOption}
                                                                onChange={(selectedOption) => this.onChangeProjectSelection(selectedOption)}
                                                                options={(this.props.projectsForTimesheet && commonUtils.convertObjectToSelectObject(this.props.projectsForTimesheet)) || []}
                                                            />
                                                        </div>
                                                        <div className="col-6 pt-0 mb-1">
                                                            <label className="form-label text-muted">Delivery Name<span className="form-required">*</span></label>

                                                            <Select
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder={"Select Deliverable"}
                                                                classNamePrefix={'custom-select'}
                                                                isMulti={false}
                                                                menuPlacement={'auto'}
                                                                value={this.state.selectedDeliverableOption}
                                                                onChange={(selectedOption) => this.onDeliverableSelect(selectedOption)}
                                                                options={(this.state.selectedProjectOption && this.getRestrictedDeliverableOptions()) || []}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.selectedTypeOption &&
                                                    <div className='col-12'>
                                                        {(commonUtils.getAppConfigValue(this.props.projectAppConfig, "enableCategorySelection") || this.state.selectedTypeOption.value === 'nonProject') &&
                                                            <CustomInput
                                                                type='select'
                                                                createConfig={this.state.selectedTypeOption.value === 'nonProject' ? this.props.nonProjectAppConfig : this.props.projectAppConfig}
                                                                label="Category"
                                                                name="taskActCategory"
                                                                configName="taskActCategory"
                                                                fieldConfig={this.props.nonProjectFieldConfig}
                                                                mappingObject={this.state.createWorkItemsRequest}
                                                                onChange={this.onTaskActChange}
                                                                placeholder="Category..."
                                                                isAppConfig={true}
                                                                defaultOptions={this.state.selectedTypeOption.value === 'nonProject' ? [{ value: 'HOLIDAY', label: 'Holiday' }, { value: 'LEAVE', label: 'Leave' }] : []}
                                                                onFormError={this.onFormError}
                                                            />
                                                        }
                                                        {(!commonUtils.getAppConfigValue(this.props.projectAppConfig, "enableCategorySelection") && this.state.selectedTypeOption.value !== 'nonProject') &&
                                                            <CustomInput
                                                                type='select'
                                                                label="Task Category"
                                                                name="taskActCategory"
                                                                configName="taskActCategory"
                                                                fieldConfig={this.props.projectFieldConfig}
                                                                mappingObject={this.state.createWorkItemsRequest}
                                                                onChange={this.onTaskActChange}
                                                                placeholder="Task Category..."
                                                                options={this.props.projectAppConfig && this.state.selectedDeliverableOption && workItemUtils.getWorkItemCategories(this.props.projectAppConfig, commonUtils.getAppConfigValue(this.props.projectAppConfig, "enableServiceLineEstimate") ? this.state.selectedDeliverableOption.serviceLine : this.state.selectedProjectOption.category, this.state.selectedDeliverableOption.taskCategory)}
                                                                onFormError={this.onFormError}
                                                            />
                                                        }
                                                    </div>
                                                }

                                                {this.state.selectedTypeOption && this.state.selectedTypeOption.value !== 'nonProject' &&
                                                    <>
                                                        <div className="col-12 pt-0 row mb-1">
                                                            <div className="col-6 pt-0">
                                                                <CustomInput
                                                                    type='select'
                                                                    label="Skill"
                                                                    name="skill"
                                                                    fieldConfig={this.props.projectFieldConfig}
                                                                    mappingObject={this.state.createWorkItemsRequest}
                                                                    onChange={this.onChange}
                                                                    placeholder="Skill..."
                                                                    //isAppConfig={true}
                                                                    //createConfig={this.props.employeeAppConfig}
                                                                    //configName={"skills"}
                                                                    options={this.state.selectedEmployeeOption && this.getRestrictedSkillOptions()}
                                                                    onFormError={this.onFormError}
                                                                />
                                                            </div>
                                                            <div className="col-6 pt-0">
                                                                <CustomInput
                                                                    type='text'
                                                                    datatype='num'
                                                                    label="Quantity"
                                                                    name="quantity"
                                                                    fieldConfig={this.props.projectFieldConfig}
                                                                    mappingObject={this.state.createWorkItemsRequest}
                                                                    onChange={this.onChange}
                                                                    placeholder="Quantity.."
                                                                    onFormError={this.onFormError}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-12">
                                                    <CustomInput
                                                        type='textarea'
                                                        label="Comments"
                                                        name="description"
                                                        height={"35px"}
                                                        fieldConfig={this.props.nonProjectFieldConfig}
                                                        mappingObject={this.state.createWorkItemsRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Description..."
                                                        onFormError={this.onFormError}
                                                        datatype='alpnumspl'
                                                    />
                                                </div>
                                                {this.state.selectedTypeOption && this.state.selectedTypeOption.value === 'nonProject' && this.state.createWorkItemsRequest.taskActCategory &&
                                                    <>
                                                        <div className="col-12 pt-0 row mb-1">
                                                            <div className="col-6 pt-0">
                                                                <div className="form-group">

                                                                    <CustomInput
                                                                        readOnly={this.props.readOnly}
                                                                        type='select'
                                                                        label={"Requesting Team"}
                                                                        name="requestingTeamName"
                                                                        fieldConfig={nonProjectAppConfig}
                                                                        mappingObject={{ ...this.state.createWorkItemsRequest, "requestingTeamName": this.state.selectedRequestingTeamOption }}
                                                                        onChange={this.onSelectRequestingTeamOption}
                                                                        placeholder="Team Name..."
                                                                        options={this.props.allUserTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allUserTeams)}
                                                                        onFormError={this.onFormError}
                                                                        getSelectOptionEnabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 pt-0">
                                                                <CustomInput
                                                                    type='select'
                                                                    createConfig={this.props.nonProjectAppConfig}
                                                                    label="Division"
                                                                    name="division"
                                                                    fieldConfig={nonProjectAppConfig}
                                                                    mappingObject={this.state.createWorkItemsRequest}
                                                                    onChange={this.onChange}
                                                                    placeholder="Division..."
                                                                    isAppConfig={true}
                                                                    onFormError={this.onFormError}
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className="col-12 pt-0 row mb-1">
                                                            <div className="col-6 pt-0">
                                                                <CustomInput
                                                                    type='select'
                                                                    label="Client Partner"
                                                                    name="clientPartner"
                                                                    createConfig={this.props.nonProjectAppConfig}
                                                                    fieldConfig={nonProjectAppConfig}
                                                                    mappingObject={this.state.createWorkItemsRequest}
                                                                    onChange={this.onChange}
                                                                    placeholder="Client Partner..."
                                                                    isAppConfig={true}
                                                                    onFormError={this.onFormError}
                                                                />
                                                            </div>
                                                            <div className="col-6">
                                                                <CustomInput
                                                                    type='select'
                                                                    label="Account"
                                                                    name="account"
                                                                    fieldConfig={nonProjectAppConfig}
                                                                    mappingObject={this.state.createWorkItemsRequest}
                                                                    onChange={this.onChange}
                                                                    placeholder="Account..."
                                                                    options={(this.props.allProjectTeamAccounts || this.props.allProjectAccounts) && commonUtils.getAccountOptions(this.props.allProjectTeamAccounts || this.props.allProjectAccounts)}
                                                                    onFormError={this.onFormError}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.createWorkItemsRequest.timeEntry &&
                                    <>
                                        <span className="subHeading">Daily Hours split ({this.state.selectedStatusOption.value === "COMPLETED" ? 'Actual Start Time' : 'Est Start Time'})</span>
                                        <div className="row">
                                            {Object.keys(this.state.createWorkItemsRequest.timeEntry).map((key) =>
                                                <div className="col-4" key={key}>
                                                    <div className="d-flex align-items-center  pt-1">
                                                        <span className={`${this.state.createWorkItemsRequest.timeEntry[key].dayNumber === 0 ? "colorred" : this.state.createWorkItemsRequest.timeEntry[key].dayNumber === 6 ? "colororange" : ""} fsize11 fweight600 mr-3`}>{key} {this.state.selectedStartTimeOption.label}</span>
                                                        <span className="timesheet-inputs d-flex">
                                                            <input className="form-control px-1 py-0 state-valid"
                                                                onChange={(e) => this.onTimeEntryValueChange(key, e.target.value)}
                                                                type="number" placeholder="0h00m" min={0} max={this.props.permission.maxHoursAllowedToEnter}
                                                                value={this.state.createWorkItemsRequest.timeEntry[key].timesheetTimeHours} />
                                                            <span className="ml-2">
                                                                <button className="link actions-item" onClick={(e) => this.onTimeEntryDelete(key)}><i className="fa fa-close colorred"></i></button>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                    <SidebarFooter
                        handleSubmit={this.handleSubmit}
                        editMode={this.props.editMode}
                        keepDataOptionEnabled={true}
                        createButtonLabel={this.props.createButtonLabel}
                        closeButtonLabel={'Clear'}
                        initializeRequest={this.clearActionHandler}
                        hasFormError={this.state.hasFormError}
                        onResetFormError={this.onResetFormError}
                    />
                </div >
            </div >
        );
    }
}
const mapStateToProps = state => ({
    currentUser: state.authentication.user,
    nonProjectAppConfig: state.workItem.nonProjectAppConfig,
    projectAppConfig: state.projects.createConfig,
    nonProjectFieldConfig: state.workItem.nonProjectFieldConfig,
    projectFieldConfig: state.workItem.projectFieldConfig,
    allUserTeams: state.appconfig.allUserTeamsByGroup,
    allProjectTeamAccounts: state.accounts.allProjectTeamAccounts,
    allProjectAccounts: state.accounts.allProjectAccounts,
    employeeAppConfig: state.employees.createConfig,
    projectsForTimesheet: state.projects.projectsForTimesheet,
});

const mapDispatchToProps = dispatch => ({
    initializeNonProjectWorkItem: workItemActions.initializeNonProjectWorkItem,
    createWorkItems: workItemActions.createWorkItems,
    getProjectList: projectActions.getProjectListForTimesheet
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateWorkItemSidebar);

