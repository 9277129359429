import React from 'react'
import { commonUtils } from '_helpers';

class TreeView extends React.Component {

    constructor() {
        super()
        this.state = {
            visible: true,
            newItemValue: null
        }
    }

    handleInputChange = (e) => {
        this.setState({ newItemValue: e.target.value });
    }
    toggle = () => {
        this.setState({ visible: !this.state.visible });
    }
    componentDidMount() {
    }
    render() {
        var childNodeItems = null;
        if (this.props.node.nodes) {
            childNodeItems = this.props.node.nodes.map((node, index) =>
                <li className="" key={index}>
                    <TreeView type={this.props.type}
                        node={node}
                        isRootDisabled={this.props.isRoot === true && index === 0 && commonUtils.getCurrentUser().userRole != 2}
                        handleAppConfigMenuSelect={this.props.handleAppConfigMenuSelect}
                        appConfigMenuSelection={this.props.appConfigMenuSelection}
                        handleClearButtonClick={this.props.handleClearButtonClick}
                        createParentItem={this.props.createParentItem}
                        handleCreateAppConfigItem={this.props.handleCreateAppConfigItem}
                    /></li>
            );
        }

        var style = {};
        if (!this.state.visible) {
            style.display = "none";
        }
        let isDefault = this.props.type === 'role' ? this.props.node.id === commonUtils.getCurrentUser().userRole ? true : false : this.props.node.id === commonUtils.getCurrentUserTeamId() ? true : false;
        return (
            
            <>
                {this.props.node.name &&
                    <span onClick={() => !this.props.isRootDisabled && this.props.handleAppConfigMenuSelect(this.props.node.id, this.props.type, this.props.node.category)}>
                        {childNodeItems != null && childNodeItems.length > 0 ? <span onClick={this.toggle}> {this.state.visible ?
                            <i className="fe fe-chevron-down"></i> : <i className="fe fe-chevron-right"></i>}
                        </span> : <span className="pl-3"></span>}
                        <span className={`${this.props.appConfigMenuSelection && this.props.appConfigMenuSelection.id === this.props.node.id && this.props.appConfigMenuSelection.type === this.props.type ? 'active' : ''}`}>
                            {this.props.type === 'org' &&
                                <>
                                    {this.props.node.category && this.props.node.category === 'Team' ?
                                        <i className="fe fe-type pr-1"></i>
                                        : <i className="fe fe-package pr-1"></i>
                                    }
                                </>
                            }
                            {this.props.type === 'role' &&
                                <i className="fe fe-layers pr-1"></i>
                            }

                            <span className={`${isDefault ? 'fweight600' : ''}`}>{this.props.node.name}</span>  {isDefault && <i className="fe fe-check-circle colorgreen"></i>}
                        </span>

                    </span>
                }
                {this.props.createParentItem && this.props.createParentItem.id === this.props.node.id && this.props.type === this.props.createParentItem.type &&
                    <div className="d-block">
                        <input placeholder="Type here..." type="text" onChange={this.handleInputChange} value={this.state.newItemValue || ''} className="appConfig addnewConfigInput ml-4" />

                        {this.state.newItemValue && this.state.newItemValue.length > 3 &&
                            <i className="fa fa-check colorgreen pl-1" onClick={() => { this.props.handleCreateAppConfigItem(this.state.newItemValue, this.props.node.id); this.props.handleClearButtonClick() }}></i>
                        }
                        <i className="fa fa-times-circle-o  pl-2" onClick={() => this.props.handleClearButtonClick()}></i>
                    </div>
                }
                {childNodeItems != null && <ul style={style} className="fsize13">
                    {childNodeItems}
                </ul>
                }
            </>
        );
    }
}

export default TreeView;
