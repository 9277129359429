import React, { Component, PureComponent } from "react";

import classNames from 'classnames/bind';
import { commonUtils } from "_helpers";
import SelectInput from "./formfields/SelectInput";
import Select from "react-select";

class PageInnerHeaderEmployeesRoleSelectionMenu extends PureComponent {
    constructor() {
        super();
        this.state = {

            showEmployeeDropDown: false,
            selectedEmployeesIdsRoles: [
            ],
            filterText: ""
        };
    }
    componentDidMount() {
        this.setState({ selectedRoleId: this.props.selectedMenu.id });
        this.initalizeEmployeesIds()
    }

    componentDidUpdate() {
        if (this.state.selectedRoleId != this.props.selectedMenu.id) {
            this.setState({ selectedRoleId: this.props.selectedMenu.id });
            this.initalizeEmployeesIds()
        }
    }

    toggleEmployeeDropDown = (isDropdownShow) => {
        //this.initalizeEmployeesIds();
        this.setState({ showEmployeeDropDown: isDropdownShow });
    }

    initalizeEmployeesIds = () => {
        let selectedIds = []
        this.props.allEmployees && this.props.allEmployees.filter(emp => emp.userRole === this.props.selectedMenu.id).map(employeeItem => {
            selectedIds.push({ id: employeeItem.id, name: employeeItem.name, userRole: employeeItem.userRole, updated: false });
            return selectedIds;
        })
        this.setState({ selectedEmployeesIdsRoles: commonUtils.sortArrayOfObjects(selectedIds, true) });

    }

    closeEmployeeDropDown = () => {
        this.initalizeEmployeesIds();
        this.toggleEmployeeDropDown(false);
    }
    handleEmployeesSelection = () => {
        this.toggleEmployeeDropDown(false);
        this.props.handleEmployeesSelection(this.state.selectedEmployeesIdsRoles);
    }

    onFilterTextChange = (text) => {
        this.setState({
            filterText: text
        })
    }

    onChangeRole = (employeeId, newUserRole) => {
        this.setState({
            selectedEmployeesIdsRoles: this.state.selectedEmployeesIdsRoles.map(employeeItem =>
                employeeItem.id === employeeId
                    ? { ...employeeItem, updated: employeeItem.userRole !== newUserRole, newUserRole: newUserRole }
                    : employeeItem
            )
        });
    }
    onAddEmployees = (employee) => {
        let selectedEmployees = [...this.state.selectedEmployeesIdsRoles]
        selectedEmployees.push({ id: employee.id, name: employee.name, newUserRole: this.props.selectedMenu.id, updated: true, new: true });
        this.setState({
            selectedEmployeesIdsRoles: selectedEmployees
        })
    }
    onRemovEmployees = (employeeId) => {
        let selectedEmployees = this.state.selectedEmployeesIdsRoles.filter(employee => employee.id !== employeeId);
        this.setState({
            selectedEmployeesIdsRoles: selectedEmployees
        })

    }

    getRoles = (nodes) => {
        var roles = []
        if (nodes) {
            nodes.map((node, index) => {
                roles.push({ id: node.id, name: node.name });
                if (node.nodes) {
                    roles = [...roles, ...this.getRoles(node.nodes)];
                }
                return roles;
            })
        }
        return roles;
    }

    getEmployeeIdsToBeAdded = () => {
        if (this.props.allEmployees) {
            let selectedEmloyeeIds = this.state.selectedEmployeesIdsRoles.map(employeeItem => employeeItem.id);
            return this.props.allEmployees.filter(employee => !selectedEmloyeeIds.includes(employee.id))
        }
        return null;
    }

    handleUpdateEmployeeRoles = () => {
        this.props.handleUpdateEmployeeRoles(this.state.selectedEmployeesIdsRoles.filter(employee => employee.updated === true).map(employee => { return { id: employee.id, userRole: employee.newUserRole } }), this.toggleEmployeeDropDown);
    }

    render() {
        let roles = this.getRoles(this.props.roles.nodes);
        return (
            <>
                {this.props.enabled &&
                    <span className="listgridheader">
                        <button className="link" onClick={() => !this.props.isLoading && this.toggleEmployeeDropDown(!this.state.showEmployeeDropDown)} title="Show Employees">
                            <span className="pl-1 fsize14 fweight600 text-muted colorblue">{(this.state.selectedEmployeesIdsRoles && this.state.selectedEmployeesIdsRoles.length) || 0}</span>
                        </button>
                        <div className={classNames("dropdowncontainer", { "d-none": !this.state.showEmployeeDropDown })} >

                            <div className="dropdownmenu custom-controls-stacked font-weight-normal text-capitalize w-350">
                                <div className="input-icon mb-2 ml-4"><span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                                    <input type="text" className="form-control input-sm filter" placeholder="Filter..."
                                        value={this.state.filterText || ''}
                                        onChange={(event) => this.onFilterTextChange(event.target.value)}
                                    />
                                </div>

                                <div className=" pl-2 employeeRoleWindow ">
                                    <div className="pr-1">
                                        <ul className="m-0 p-0">
                                            {this.state.selectedEmployeesIdsRoles && this.state.selectedEmployeesIdsRoles
                                                .filter(employee =>
                                                    this.state.filterText === "" || commonUtils.stringContainsString(employee.name, this.state.filterText))
                                                .map(employee =>
                                                    <li className="dropdown-item m-0 p-0" key={employee.id}>
                                                        <span className="fsize13 fweight600 d-inline-block w-200">{employee.name}</span>

                                                        {!employee.new &&
                                                            <SelectInput className="float-right fsize13 fweight600"
                                                                value={employee.newUserRole || employee.userRole}
                                                                isReadOnly={false}
                                                                itemId={employee.id}
                                                                onChange={this.onChangeRole}
                                                                options={roles && roles.map(role => {
                                                                    return { "value": role.id, "label": role.name }
                                                                }
                                                                )}
                                                            />
                                                        } {employee.new &&
                                                            <>
                                                                <span className="float-right fsize13 fweight600">
                                                                    {roles &&
                                                                        <>
                                                                            {roles.filter(role => role.id === employee.newUserRole)[0].name}
                                                                        </>
                                                                    }
                                                                    <button type="button" className="link pl-2" title="Remove"
                                                                        onClick={(e) => this.onRemovEmployees(employee.id)}
                                                                    >
                                                                        <i className="dropdown-icon fa fa-times colorred"></i>
                                                                    </button>
                                                                </span>

                                                            </>
                                                        }
                                                    </li>
                                                )}
                                            <li className="dropdown-item my-3 p-0">
                                                <RoleAddChageInput
                                                    allEmployees={this.getEmployeeIdsToBeAdded()}
                                                    onAddEmployees={this.onAddEmployees}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="pr-3 pt-1 mt-2 border">
                                    {this.state.selectedEmployeesIdsRoles &&
                                        <button className={classNames("plainbutton fsize12 py-0 px-2 mb-1 ml-1 text-upper border fweight600 fsize13", { "colorlightgray": this.state.selectedEmployeesIdsRoles.filter(employeeItem => employeeItem.updated).length === 0 })}
                                            onClick={() => this.handleUpdateEmployeeRoles()}>
                                            Update
                                        </button>
                                    }
                                    <button className="pl-2 colorblue link float-right mt-1 fsize12" onClick={() => this.closeEmployeeDropDown()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </span>
                }
            </>

        );
    }
}

class RoleAddChageInput extends Component {
    constructor() {
        super()
        this.state = {
            employee: null
        }
    }

    onChange = (employee) => {
        this.setState({
            employee: employee
        })
    }

    onAddEmployees = () => {
        this.props.onAddEmployees(this.state.employee)
        this.setState({
            employee: null
        })
    }
    render() {
        return (<>
            <span className="fsize13 fweight600 w-250 d-inline-block">
                <Select
                    value={this.state.employee}
                    isSearchable={true}
                    isClearable={false}
                    classNamePrefix={'custom-select'}
                    isMulti={false}
                    menuPlacement={'auto'}
                    onChange={(selectedOption) => this.onChange(selectedOption)}
                    options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                />
            </span>
            <span className="float-right fsize13 fweight600 mr-3">
                <button className="btn btn-round btn-primary p-1 " title="Add"
                    onClick={() => this.onAddEmployees()}
                >Add</button>
            </span>
        </>)
    }
}

export default PageInnerHeaderEmployeesRoleSelectionMenu