import React, { Component } from "react";
import { connect } from "react-redux";
import PageInnerHeader from "views/common/PageInnerHeader";
import Calendar from "views/common/plugins/calendar/Calendar";
import HolidayListTable from "views/hrms/components/HolidayListTable";
import { employeeActions } from "_actions";
import { workItemActions } from "_actions";
import { workItemConstants } from "_constants";
import { store } from "_helpers";
import { accessUtils } from "_helpers";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import workItemUtils from "_helpers/workitem.utils";
import CreateWorkItemSidebar from "./components/modal/CreateWorkItemSidebar";
import WorkItemActiveItems from "./components/WorkItemActiveItems";
import WorkItemPendingItems from "./components/WorkItemPendingItems";
import { checklistConstants } from "_constants";
import ConfigureChecklistSidebar from "./components/modal/ConfigureChecklistSidebar";

class WorkItemAcitivitiesCalendarPage extends Component {

  constructor() {
    super()
    this.state = {
      dateRange: { "startDateMoment": dateUtils.getCurrentTime(), "endDateMoment": dateUtils.getCurrentTime() },
      selectedEmployeeIds: [commonUtils.getCurrentUserId()],
      permission: accessUtils.getWorkItemsCalendarAccessControl(),
      currentMonthRange: {
        startMoment: dateUtils.getCurrentTime().clone().startOf('month').startOf("isoweek").startOf("days"),
        endMoment: dateUtils.getCurrentTime().clone().endOf('month').endOf("isoweek").endOf("days")
      }
    }
  }
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
    this.realoadTeamWorkItems([commonUtils.getCurrentUserId()], this.state.dateRange)
    this.realodHolidays(this.state.currentMonthRange.startMoment, this.state.currentMonthRange.endMoment,);
    this.loadPendingWorkItems();
    this.loadEmployeeList(commonUtils.getUserTeams(this.props.userTeams))
  }

  loadEmployeeList = (userTeams) => {
    let selectedUserTeamsIds = [];
    userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeamsIds.push(userTeam.id));
    store.dispatch(this.props.getTeamsEmployees(selectedUserTeamsIds))
  }
  loadPendingWorkItems = () => {
    let startDate = dateUtils.substractXFromMoments(dateUtils.getCurrentTime().clone().startOf('day'), "days", 90).clone().format(dateUtils.SERVER_DATE_FORMAT);
    let endDate = dateUtils.addXToMoments(dateUtils.getCurrentTime().clone().endOf('day'), "days", 30).format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getWorkItemActivitiesPending({ employeeIds: [commonUtils.getCurrentUserId()], userTeamIds: [commonUtils.getCurrentUserTeamId()], startDate: startDate, endDate: endDate }));

  }

  realodHolidays = (startDate, endDate) => {
    store.dispatch(employeeActions.getHolidays({ startDate: startDate.format(dateUtils.SERVER_DATE_FORMAT), endDate: endDate.format(dateUtils.SERVER_DATE_FORMAT) }));
  }
  onSelectedDateChange = selectedDates => {
    let dateRange = { "startDateMoment": selectedDates[0].startOf("days"), "endDateMoment": selectedDates[selectedDates.length - 1].endOf("days") }
    this.setState({ dateRange })
    this.realoadTeamWorkItems(this.state.selectedEmployeeIds, dateRange)
  }

  onCalendarViewDate = (viewMonthMoment) => {
    let startDate = viewMonthMoment.clone().startOf('month').startOf("week").startOf("days")
    let endDate = viewMonthMoment.clone().endOf('month').endOf("week").endOf("days")
    this.realodHolidays(startDate, endDate)
    this.setState({
      currentMonthRange: {
        startMoment: startDate,
        endMoment: endDate
      }
    })
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_ACTIVITIES, userTeams, filterOptions: this.props.filterOptions });
    this.loadEmployeeList(userTeams);
    // this.realoadTeamWorkItems(userTeams, this.state.dateRange)
  }

  handleEmployeesSelection = (employees) => {
    let employeeIds = commonUtils.getSelectedEmployeeIds(employees);
    this.setState({ selectedEmployeeIds: employeeIds },
      () => {
        this.realoadTeamWorkItems(employeeIds, this.state.dateRange)
      });

  }

  refreshWorkItems = () => {
    this.realoadTeamWorkItems(this.state.selectedEmployeeIds, this.state.dateRange);
    this.loadPendingWorkItems();
  }

  realoadTeamWorkItems = (employeeIds, dateRange) => {

    if (!employeeIds || employeeIds.length === 0) {
      employeeIds = [commonUtils.getCurrentUserId()];
    }

    let startDate = dateRange.startDateMoment.clone().format(dateUtils.SERVER_DATE_FORMAT);
    let endDate = dateRange.endDateMoment.clone().format(dateUtils.SERVER_DATE_FORMAT);

    store.dispatch(this.props.getWorkItemActivities({ employeeIds: employeeIds, userTeamIds: [], startDate: startDate, endDate: endDate }));
  }

  createItemClickHandler = () => {
    store.dispatch({ type: workItemConstants.SHOW_NONPROJECT_CREATE_WINDOW });
  }
  closeCreateItemHandler = () => {
    store.dispatch({ type: workItemConstants.CLOSE_NONPROJECT_CREATE_WINDOW });
  }

  onCheklistItemClick = (selectedChecklistItem) => {
    this.setState(prevState => ({
      selectedChecklistItem
    }),
      () => {
        store.dispatch(this.props.getWorkItemInfo(selectedChecklistItem.id));
        store.dispatch({ type: checklistConstants.SHOW_CHECKLIST_ANSWER_WINDOW });
      }
    );
  }
  render() {
    return (
      <div className="workitemcalendarpage">
        <div className="section-body">
          <div className="container-fluid">
            <div className="row">
              <div className="card px-1 p-0 m-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 border-right xs-hide">
                      <div className="d-block">
                        <Calendar
                          className={"customSmallCalendar border-bottom pb-3"}
                          onSelectedDateChange={this.onSelectedDateChange}
                          onCalendarViewDate={this.onCalendarViewDate}
                          holidayItems={this.props.holidayItems}
                          workItems={this.props.completedItems}
                        />
                        <HolidayListTable />
                      </div>
                      <div className="row">
                        <div className="col-12">

                          <WorkItemPendingItems
                            items={this.props.pendingItems} isLoading={this.props.isLoadingPending}
                            permission={this.state.permission}
                            reloadPendingTasks={this.loadPendingWorkItems}
                          />
                        </div>
                      </div>
                    </div>
                    <WorkItemActivitesSection
                      userTeams={this.props.userTeams}
                      handleUserTeamsSelection={this.handleUserTeamsSelection}
                      createItemClickHandler={this.createItemClickHandler}
                      employees={this.props.employees}
                      handleEmployeesSelection={this.handleEmployeesSelection}
                      isLoading={this.props.isLoadingCompleted}
                      filterOptions={this.props.filterOptions}
                      workItems={this.props.workItems}
                      isLoadingCompleted={this.props.isLoadingCompleted}
                      onCheklistItemClick={this.onCheklistItemClick}
                      showChecklistAnswerSidebar={this.props.showChecklistAnswerSidebar}
                      showChecklistConfigSidebar={this.props.showChecklistConfigSidebar}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateWorkItemSidebar
          employees={this.props.employees}
          show={this.props.showNonProjectCreateModel}
          closeActionHandler={this.closeCreateItemHandler}
          handleReloadWorkItems={this.refreshWorkItems}
          permission={this.state.permission}
          minDate={this.state.currentMonthRange && workItemUtils.getWorkItemCreateStartMinDate(this.state.currentMonthRange.startMoment.clone(), this.state.permission)}
          maxDate={this.state.currentMonthRange && workItemUtils.getWorkItemCreateEndMaxDate(this.state.currentMonthRange.endMoment.clone(), this.state.permission)}

        />
        {this.props.selectedWorkItemInfo &&
          <>
            <ConfigureChecklistSidebar
              type={"TASK_ACTIVITY"}
              show={this.props.showChecklistConfigSidebar || this.props.showChecklistAnswerSidebar}
              showConfigWindow={this.props.showChecklistConfigSidebar}
              showChecklistQuestionsWindow={this.props.showChecklistAnswerSidebar}
              selectedItemInfo={this.props.selectedWorkItemInfo}
              permission={this.state.permission}
              configureDisabled={this.props.showChecklistAnswerSidebar}
            />
          </>
        }
      </div>
    );
  }
}

class WorkItemActivitesSection extends Component {
  constructor() {
    super()
    this.state = {
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ONGOING", label: "On Going" }, { name: "COMPLETED", label: "Completed" }, { name: "ASSIGNED", label: "Assigned" }, { name: "CREATEDBYME", label: "Created By Me" }], selected: "COMPLETED" },
        SORT: { options: [{ name: "ACTUALSTARTDATE", label: "Start Date" }, { name: "ESTIMATEDENDDATE", label: "Due Date" }, { name: "CREATEDDATE", label: "Created Date" }, { name: "A-Z", label: "A-Z" }, { name: "PROJECT", label: "Project", hasDirection: false }, { name: "DELIVERABLE", label: "Deliverable", hasDirection: false }], selected: "A-Z", directionUp: true },
        filterText: null
      },
      permission: accessUtils.getWorkItemsCalendarAccessControl()
    }
  }

  componentDidMount() {
    store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_ACTIVITIES, userTeams: this.props.userTeams, filterOptions: this.state.filterOptions });
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_ACTIVITIES, userTeams: this.props.userTeams, filterOptions });
      });
  }

  render() {
    return (
      <div className="col-md-8 col-xs-12">
        <div className="card pt-0">

          <PageInnerHeader
            userTeams={commonUtils.getUserTeams(this.props.userTeams)}
            handleUserTeamsSelection={this.props.handleUserTeamsSelection}
            showCreateMenu={this.state.permission.createEnabled}
            showCreateMenuText="CREATE Tasks"
            createItemHandler={this.props.createItemClickHandler}
            showGridListMenu={false}
            showFilter={true}
            showEmployeeMenu={true}
            employees={this.props.employees}
            handleEmployeesSelection={this.props.handleEmployeesSelection}
            showUserTeamMenu={true}
            isLoading={this.props.isLoadingCompleted}
            filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
            handleFilterChange={this.handleFilterChange}
            onFilterTextChange={this.onFilterTextChange}
            showDateRangeSelector={false}
            permission={this.state.permission}
          >
          </PageInnerHeader>

          <WorkItemActiveItems
            items={this.props.workItems}
            isLoading={this.props.isLoadingCompleted}
            sortBy={(this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions).SORT.selected}
            permission={this.state.permission}
            onCheklistItemClick={this.props.onCheklistItemClick}
          />

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoadingCompleted: state.workItem.isLoadingCompleted,
  isLoadingPending: state.workItem.isLoadingPending,
  workItems: state.workItem.workItems && commonUtils.filterWorkItems(state.workItem.workItems, state.workItem.filterOptionsTaskActivity),
  pendingItems: state.workItem.workItemsPending,
  employees: state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees),
  holidayItems: state.employees.holidayItems,
  filterOptions: state.workItem.filterOptionsTaskActivity,
  showNonProjectCreateModel: state.workItem.showNonProjectCreateModel,
  selectedWorkItemInfo: state.workItem.selectedWorkItemInfo,
  showChecklistAnswerSidebar: state.checklist.isShowChecklistAnswerSidebar,
  showChecklistConfigSidebar: state.checklist.isShowConfigureChecklistSidebar
});

const mapDispatchToProps = dispatch => ({
  getTeamsEmployees: employeeActions.getTeamsEmployees,
  getWorkItemActivities: workItemActions.getWorkItemActivities,
  getWorkItemActivitiesPending: workItemActions.getWorkItemActivitiesPending,
  getWorkItemInfo: workItemActions.getWorkItemInfo,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkItemAcitivitiesCalendarPage);

