import React, { Component } from 'react';
import CustomInput from 'views/common/fragments/formfields/CustomInput';
import FileDropZone from 'views/common/plugins/FileDropZone';
import { Effect } from 'views/common/plugins/model';
import { Modal } from 'views/common/plugins/model/Modal';
import { validationUtils } from '_helpers';
import dateUtils from '_helpers/date.utils';
import workItemUtils from '_helpers/workitem.utils';
import SidebarFooter from './SidebarFooter';
const fieldConfig = [
   { name: 'id', mandatory: true, enabled: true },
   { name: 'actualStartTime', mandatory: true, enabled: true }
];
class TaskCompletionModel extends Component {
   constructor() {
      super();
      this.state = {
         //minMaxDateArray: { minDate: null, maxDate: null },
         taskCompletionDurationTimeInMs: null,
         workItemStatusRequest: {
         },
         formErrors: {},
         hasFormError: false,
         expandPreviousTimeSection: false
      }
   }
   componentDidMount() {
      let workItemMinDates = workItemUtils.getWorkItemCreateStartMinDate(dateUtils.convertDateStringToMoment(this.props.workItem.taskEstStartTime || dateUtils.getCurrentTime().clone().subtract(30, "days")), this.props.permission);
      let workItemMaxDates = workItemUtils.getWorkItemCreateEndMaxDate(dateUtils.convertDateStringToMoment(this.props.workItem.taskEstCompletionTime || dateUtils.getCurrentTime().clone().add(30, "days")), this.props.permission);
      this.setState(prevState => ({
         workItemMinDates,
         workItemMaxDates,
         workItemStatusRequest: {
            ...this.props.workItem,
            actualStartTime: this.props.workItem.actualStartTime || this.getTaskStartTime(),
            durationInHrs: this.props.modalType === 'COMPLETED' ? dateUtils.minuteToHourDecimal(this.getCompletionDurationInMins()) : null,
            durationInMins: this.props.modalType === 'COMPLETED' ? (this.getCompletionDurationInMins() || 0) : null,
            actualEndTime: this.props.modalType === 'COMPLETED' ? (this.props.workItem.taskPausedTime || this.getActualCompletionTime(this.props.workItem.estDurationInMins)) : null
         },
         formErrors: {},
      }),
         () => {
            this.validateDateSelection();
            this.calculateEndDateTime();
         }
      );
   }
   onFormError = (fieldName, hasError) => {
      this.setState(prevState => ({
         formErrors: {
            ...prevState.formErrors,
            [fieldName]: hasError
         }
      }))
   }
   expandPreviousTimeSection

   onResetFormError = () => {
      this.setState({
         hasFormError: false
      })
   }

   toggleExpandPreviousTimeSection = (isActive) => {
      this.setState({
         expandPreviousTimeSection: isActive
      })
   }

   closeActionHandler = () => {
      if (this.props.isPlay === true) {
         this.props.playAction(this.state.workItemStatusRequest, this.props.modalType, false, this.props.callbackFunction)
      } else {
         this.props.cancelAction(this.props.workItem)
      }
   }

   onCommentChange = (fieldName, value) => {
      this.onChange(fieldName, value);
      this.onChange(this.props.modalType === 'COMPLETED' ? 'completionComment' : 'inprogressComment', value);
   }

   onChange = (fieldName, value) => {
      this.setState(prevState => ({
         workItemStatusRequest: {
            ...prevState.workItemStatusRequest,
            [fieldName]: value
         }
      }))
   }

   onDateChange = (fieldName, value) => {
      this.setState(prevState => ({
         workItemStatusRequest: {
            ...prevState.workItemStatusRequest,
            [fieldName]: value
         }
      }),
         () => {
            this.calculateEndDateTime();
         }
      );
   }

   calculateEndDateTime = () => {
      this.onChange("actualEndTime", this.props.modalType === 'COMPLETED' ? dateUtils.convertMomentToString(dateUtils.addXToMoments(dateUtils.convertDateStringToMoment(this.state.workItemStatusRequest.actualStartTime), "minutes", this.state.workItemStatusRequest.durationInMins)) : null)
   }

   getTaskStartTime = () => {
      let estTaskCompletionTimeMoment = dateUtils.getCurrentDayEnd(dateUtils.convertDateStringToMoment(this.props.workItem.taskEstCompletionTime));
      let estStartTimeMoment = dateUtils.convertDateStringToMoment(this.props.workItem.estStartTime);
      let currentTimeMoment = dateUtils.getCurrentTime();
      if (estStartTimeMoment.diff(currentTimeMoment) >= 0 && estTaskCompletionTimeMoment.diff(currentTimeMoment) <= 0) {
         return currentTimeMoment.clone().format(dateUtils.SERVER_DATE_FORMAT)
      }
      return estStartTimeMoment.format(dateUtils.SERVER_DATE_FORMAT)
   }

   onTimeEntryValueChange = (valueInHours) => {
      if (!validationUtils.validateDecimalNumberic(valueInHours) || (valueInHours && (this.props.permission.maxHoursAllowedToEnter < parseFloat(valueInHours) || (parseFloat(valueInHours) - parseInt(valueInHours) > .59)))) {
         return;
      }
      let valueInMins = 0;
      if (valueInHours !== "") {
         valueInMins = (parseInt(valueInHours) * 60) + (Math.round((parseFloat(valueInHours) - parseInt(valueInHours)) * 100));
      }
      this.onDateChange("durationInMins", valueInMins);
      this.onDateChange("durationInHrs", valueInHours);
   }

   getCompletionDurationInMins = () => {
      let actualStartTimeMoment = dateUtils.convertDateStringToMoment(this.props.workItem.actualStartTime || this.getTaskStartTime());
      let currentMoment = dateUtils.getCurrentTime().clone();
      const timeDiffInMins = currentMoment.diff(actualStartTimeMoment, 'minutes')
      const maxHoursAllowedToEnterInMins = parseInt(this.props.permission.maxHoursAllowedToEnter) * 60;
      //return (maxHoursAllowedToEnterInMins < timeDiffInMins  || timeDiffInMins < 0) ? (this.props.workItem.estDurationInMins === 0 ? maxHoursAllowedToEnterInMins : this.props.workItem.estDurationInMins) : timeDiffInMins;
      return (maxHoursAllowedToEnterInMins < timeDiffInMins || timeDiffInMins < 0) ? (this.props.workItem.estDurationInMins === 0 ? maxHoursAllowedToEnterInMins : 0) : timeDiffInMins;
   }

   getActualCompletionTime = () => {
      let actualStartTimeMoment = dateUtils.convertDateStringToMoment(this.props.workItem.actualStartTime || this.getTaskStartTime());
      return dateUtils.convertMomentToString(dateUtils.addXToMoments(actualStartTimeMoment, "minutes", this.props.workItem.estDurationInMins));
   }

   getDateSelectionMinDate = () => {
      let minDateObject = this.state.workItemMinDates;
      let workItemStartDate = null;
      if (minDateObject && this.props.workItem && this.props.workItem.taskType === 'NON_PROJECT') {
         if (this.props.permission.allowLeaveAndHolidayForPast && this.props.workItem && this.props.workItem.taskActCategory && (this.props.workItem.taskActCategory.toLowerCase() === 'leave' || this.props.workItem.taskActCategory.toLowerCase() === 'holiday')) {
            workItemStartDate = minDateObject.actualStartDate;
         } else {
            workItemStartDate = minDateObject.nonProjectStart;
         }
      } else if (minDateObject && this.props.workItem && this.props.workItem.taskType !== 'NON_PROJECT') {
         workItemStartDate = minDateObject.projectStart;
      }
      return workItemStartDate;
   }

   getDateSelectionMaxDate = () => {
      let maxDateObject = this.state.workItemMaxDates;
      let workItemEndDate = null;
      if (maxDateObject && this.props.workItem && this.props.workItem.taskType === 'NON_PROJECT') {
         if (this.props.permission.allowLeaveAndHolidayForPast && this.props.workItem && this.props.workItem.taskActCategory && (this.props.workItem.taskActCategory.toLowerCase() === 'leave' || this.props.workItem.taskActCategory.toLowerCase() === 'holiday')) {
            workItemEndDate = maxDateObject.actualEndDate;
         } else {
            workItemEndDate = maxDateObject.nonProjectEnd;
         }
      } else if (maxDateObject && this.props.workItem && this.props.workItem.taskType !== 'NON_PROJECT') {
         workItemEndDate = maxDateObject.projectEnd;
      }
      return workItemEndDate;
   }

   validateDateSelection = () => {
      let dateMinDateMoment = this.getDateSelectionMinDate(this.props.workItem.taskEstStartTime);
      let dateMaxDateMoment = this.getDateSelectionMaxDate(this.props.workItem.taskEstCompletionTime);
      let workItemStartDate = dateUtils.convertDateStringToMoment(this.state.workItemStatusRequest.actualStartTime);
      let isValid = true;
      if (dateMinDateMoment && dateMaxDateMoment) {
         dateMinDateMoment = dateMinDateMoment.clone().startOf("day");
         dateMaxDateMoment = dateMaxDateMoment.clone().endOf("day");
         if (workItemStartDate && (workItemStartDate.diff(dateMinDateMoment) < 0 || workItemStartDate.diff(dateMaxDateMoment) > 0)) {
            this.onChange("actualStartTime", null);
            isValid = false;
         }
      }
      return isValid;
   }

   handleSubmit = (isCreateAnotherEnabled) => {
      let isValid = validationUtils.isValidRequest(fieldConfig, this.state.workItemStatusRequest, this.state.formErrors);
      //console.log("Validating form " + isValid + "  request ");
      if(this.props.modalType === 'COMPLETED' && this.state.workItemStatusRequest.durationInMins === 0) {
         isValid = false;
      }
      if (isValid) {
         if (this.props.isPlay === true) {
            this.props.playAction(this.state.workItemStatusRequest, this.props.modalType, true, this.props.callbackFunction)
         } else {
            this.props.updateAction(this.state.workItemStatusRequest, this.props.modalType);
         }
      } else {
         this.setState({
            hasFormError: true
         })
      }
   }
   render() {
      const { onRequestClose } = this.props;
      return (
         <Modal
            onRequestClose={onRequestClose}
            effect={Effect.ScaleUp}>
            <div className="justify-content-between flex-column d-flex">
               <div className="card-header py-0 border-bottom">
                  <h3 className="card-title">Update task status</h3>
                  <div className="card-options">
                     <button onClick={() => this.props.cancelAction(this.props.workItem)} className="link p-2 float-right"><i className="fa fa-close" /></button>
                  </div>
               </div>
               <div className="card-body pl-3 pr-3">
                  <div className="row">
                     <div className="col-12 row">
                        <div className="col-12 col-lg-7">
                           <div className="col-12 px-0">
                              <div className="form-group pb-0 mb-2">
                                 <span className="badge badge-dim badge-warning">{this.props.workItem.taskActCategory}</span>
                                 <label className="form-label">{this.props.workItem.name}</label>
                                 <div className="form-control-plaintext fsize14 py-0">{this.props.workItem.taskName}</div>
                                 <div className="form-control-plaintext fsize12 text-muted py-0">{this.props.workItem.description}</div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-4">
                                 <label className="form-label">Estimated Start time</label>
                                 <div className="form-control-plaintext fsize14 py-0">
                                    {dateUtils.convertDateTimeFormat(this.props.workItem.estStartTime) || 'NA'}
                                 </div>
                              </div>
                              {(this.state.workItemStatusRequest.actualStartTime || this.props.workItem.actualStartTime) &&
                                 <div className="col-4">
                                    <label className="form-label">Actual Start time</label>
                                    <div className="form-control-plaintext fsize14 py-0">
                                       {dateUtils.convertDateTimeFormat(this.props.workItem.actualStartTime || this.state.workItemStatusRequest.actualStartTime)}
                                    </div>
                                 </div>
                              }
                           </div>
                           <div className="row">
                              <div className="col-4">
                                 <label className="form-label">Recorded Start time</label>
                                 <div className="form-control-plaintext fsize14 py-0">
                                    {dateUtils.convertDateTimeFormat(this.props.workItem.recordedStartTime || dateUtils.getCurrentTime())}
                                 </div>
                              </div>
                              <div className="col-6">
                                 <CustomInput
                                    type='dateselect'
                                    label="Adjusted Start Time"
                                    name="actualStartTime"
                                    fieldConfig={fieldConfig}
                                    mappingObject={this.state.workItemStatusRequest}
                                    onChange={this.onDateChange}
                                    // value={}
                                    time={true}
                                    // minDate={dateUtils.convertDateStringToDate(this.props.workItem.taskEstStartTime)}
                                    //maxDate={dateUtils.convertDateStringToDate(this.props.workItem.actualEndTime || this.props.workItem.taskEstCompletionTime)}
                                    minDate={this.props.workItem && this.state.workItemMinDates && dateUtils.toMomentToDate(this.getDateSelectionMinDate())}
                                    maxDate={this.props.workItem && this.state.workItemMinDates && dateUtils.toMomentToDate(this.getDateSelectionMaxDate())}
                                    // minDateDependsOnProperty={"actualEndTime"}
                                    //maxDate={dateUtils.convertDateStringToDate(this.state.minMaxDateArray.maxDate || this.props.workItem.taskEstCompletionTime)}
                                    format={dateUtils.DATE_TIME_PICKER_FORMAT}
                                    onFormError={this.onFormError}
                                 />
                              </div>
                              {this.props.modalType === 'COMPLETED' &&
                                 <div className="col-2">
                                    <CustomInput
                                       inputtype='text'
                                       mandatory={true}
                                       label="Duration"
                                       name="durationInHrs"
                                       fieldConfig={this.props.fieldConfig}
                                       mappingObject={this.state.workItemStatusRequest}
                                       onChange={(name, value) => this.onTimeEntryValueChange(value)}
                                       onFormError={this.onFormError}
                                       placeholder="0h00m" />
                                 </div>
                              }
                           </div>
                           {this.props.modalType === "COMPLETED" &&
                              <>
                                 <div className="row">
                                    <div className="col-4">
                                       <label className="form-label">Recorded End Time</label>
                                       <div className="form-control-plaintext fsize14 py-0">{dateUtils.convertDateTimeFormat(dateUtils.getCurrentTime())}</div>
                                    </div>
                                    <div className="col-8">
                                       <label className="form-label">Actual End Time</label>
                                       <div className="form-control-plaintext fsize14 py-0">{dateUtils.convertDateTimeFormat(this.state.workItemStatusRequest.actualEndTime)}</div>
                                    </div>
                                 </div>

                              </>
                           }

                        </div>

                        <div className="col-12 col-lg-5 ">
                           <div className="row pb-2">
                              <div className="col-12">
                                 <CustomInput
                                    type='textarea'
                                    label={this.props.modalType === "COMPLETED" ? "Completion Comment" : "In Progress Comment"}
                                    name="comments"
                                    height={"45px"}
                                    fieldConfig={fieldConfig}
                                    mappingObject={this.state.workItemStatusRequest}
                                    onChange={this.onCommentChange}
                                    placeholder={this.props.modalType === "COMPLETED" ? "Completion Comment..." : "In Progress Comment..."}
                                    onFormError={this.onFormError}
                                    datatype='alpnumspl'
                                 />
                              </div>
                           </div>
                           {this.props.modalType === "COMPLETED" &&
                              <>
                                 {this.props.workItem.taskType !== 'NON_PROJECT' &&
                                    <div className="row">
                                       <div className="col-8">
                                          <label className="form-label">Skill</label>
                                          <div className="form-control-plaintext red pb-1 pt-0">{this.state.workItemStatusRequest.skill || "NA"}</div>
                                       </div>
                                       <div className="col-4">
                                          <CustomInput
                                             type='text'
                                             label="Quantity"
                                             name="quantity"
                                             fieldConfig={fieldConfig}
                                             mappingObject={this.state.workItemStatusRequest}
                                             onChange={this.onChange}
                                             onFormError={this.onFormError}
                                          />
                                       </div>
                                    </div>
                                 }
                                 {this.props.workItem.taskType === 'NON_PROJECT' &&
                                    <>
                                       <div className="row">
                                          <div className="col-6">
                                             <label className="form-label">Account</label>
                                             <div className="form-control-plaintext red pb-1 pt-0">{this.state.workItemStatusRequest.account || "NA"}</div>
                                          </div>
                                          <div className="col-6">
                                             <label className="form-label">Division</label>
                                             <div className="form-control-plaintext red pb-1 pt-0">{this.state.workItemStatusRequest.division || "NA"}</div>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className="col-6">
                                             <label className="form-label">Requesting Team</label>
                                             <div className="form-control-plaintext red pb-1 pt-0">{this.state.workItemStatusRequest.teamName || "NA"}</div>
                                          </div>
                                          <div className="col-6">
                                             <label className="form-label">Client Partner</label>
                                             <div className="form-control-plaintext red pb-1 pt-0">{this.state.workItemStatusRequest.clientPartner || "NA"}</div>
                                          </div>
                                       </div>
                                    </>
                                 }
                              </>
                           }
                        </div>

                        {this.props.clonedByItems &&
                           <div className='col-7'>
                              <div className="row">
                                 <div className="col-12">
                                    <label className="form-label border-bottom fsize13 pt-2 colorred"> Previously Recorded Time
                                       <button onClick={() => this.toggleExpandPreviousTimeSection(!this.state.expandPreviousTimeSection)} className='link ml-2'>
                                          <i className={`fe ${this.state.expandPreviousTimeSection ? 'fe-chevron-up' : 'fe-chevron-down'}`}></i></button></label>
                                 </div>
                              </div>
                              {this.state.expandPreviousTimeSection &&
                                 <>
                                    <div className="row">
                                       <div className="col-4">
                                          <label className="form-label">Previous Start Time</label>
                                       </div>
                                       <div className="col-4">
                                          <label className="form-label">Duration In Hrs</label>
                                       </div>
                                    </div>
                                    {this.props.clonedByItems.map(item =>
                                       <div className="row" key={item.id}>
                                          <div className="col-4">
                                             <label className="form-label">{dateUtils.convertDateTimeFormat(item.actualStartTime)}</label>
                                          </div>
                                          <div className="col-4">
                                             <label className="form-label"> {dateUtils.minuteToHourString(item.durationInMins) || 'NA'}</label>
                                          </div>
                                       </div>
                                    )}
                                 </>
                              }
                           </div>
                        }

                        <div className="col-12">
                           <div className="row">
                              <FileDropZone />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
            <SidebarFooter
               handleSubmit={this.handleSubmit}
               editMode={this.props.editMode}
               createButtonLabel={this.props.isPlay ? "Complete Paused Task and Start this" : this.props.modalType === "COMPLETED" ? "Complete Task" : "Start Task"}
               closeButtonLabel={this.props.isPlay ? "Ignore Paused Task and Continue this" : 'Cancel'}
               disableKeepMeOpen={true}
               initializeRequest={this.closeActionHandler}
               hasFormError={this.state.hasFormError}
               onResetFormError={this.onResetFormError}
            />
         </Modal >
      );
   }
}

export default TaskCompletionModel;