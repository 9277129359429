import axios from "axios";
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

class FeedbackPage extends Component {
  render() {
    return (
      <main class="overflow-hidden">
        <div class="container">
          <div class="wrapper">
            <div class="row">
              <div class="col-md-12 tab-100 fadeInleft">
                <div class="product_review">
                  <h2 class="review_title">
                    Leave The Review
                  </h2>
                  <div class="product_review_inner">
                    <h4 class="num-heading">Do you Find yourself Strugling More than usual?</h4>
                    <form method="post" novalidate="">
                      <div class="input_field">
                        <label for="question">Question</label>
                        <input type="text" name="question" id="question" placeholder="Do you find yourself strugling more than usual?" />
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="input_field">
                            <label for="Who">Who</label>

                            <div class="select">
                              <div class="select-toggle">Select</div>
                              <div class="select-menu">
                                <div class="select-option" data-value="Reviewee Only"><i class="fa-solid fa-user"></i>Reviewee Only</div>
                                <div class="select-option" data-value="Group"><i class="fa-solid fa-user-group"></i>Group</div>
                                <div class="select-option" data-value="Buyer"><i class="fa-solid fa-user-plus"></i>Buyer</div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="input_field">
                            <label for="type">Question Type</label>

                            <div class="select">
                              <div class="select_no_drop">
                                <div class="top_btn"><i class="fa-solid fa-angle-up"></i></div>
                                <div class="bottom_btn"><i class="fa-solid fa-angle-down"></i></div>
                              </div>
                              <div class="no-drop-menu">
                                <div class="no-drop-option" data-value="Happy"><i class="fa-solid fa-face-smile"></i>Happy</div>
                                <div class="no-drop-option" data-value="Sad"><i class="fa-solid fa-face-sad-tear"></i>Sad</div>
                                <div class="no-drop-option show slide-out" data-value="Shocked"><i class="fa-solid fa-face-surprise"></i>Shocked</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="reactions">
                        <div class="input_field">
                          <label for="">Format Preview</label>
                        </div>
                        <div class="reaction_inner">
                          <div class="emoji" data-value="Cool">
                            <div class="emoji_img">
                              <img src="/assets/images/cool.png" alt="emoji" />
                            </div>
                            <span>Cool</span>
                          </div>
                          <div class="emoji" data-value="Sad">
                            <div class="emoji_img">
                              <img src="/assets/images/sad.png" alt="emoji" />
                            </div>
                            <span>Sad</span>
                          </div>
                          <div class="emoji" data-value="Shock">
                            <div class="emoji_img">
                              <img src="/assets/images/shock.png" alt="emoji" />
                            </div>
                            <span>Shock</span>
                          </div>
                          <div class="emoji" data-value="Wink">
                            <div class="emoji_img">
                              <img src="/assets/images/wink.png" alt="emoji" />
                            </div>
                            <span>Wink</span>
                          </div>
                          <div class="emoji" data-value="Happy">
                            <div class="emoji_img">
                              <img src="/assets/images/happy.png" alt="emoji" />
                            </div>
                            <span>Happy</span>
                          </div>

                        </div>
                      </div>
                      <div class="info-text">
                        <i class="fa-solid fa-circle-info"></i>
                        <h5>
                          A Mood Scale allow analyze and calculate the overall mood Of
                          the employee. Emoji fix to keep the score calculation logic
                        </h5>
                      </div>
                      <div class="input_field">
                        <label for="">Addtional Options</label>
                        <div class="row">
                          <div class="radio-toggle">
                            <div class="toggle-onOff active">
                              <div class="toggle-btn"></div>
                            </div>
                            <span>Allow Comments</span>
                          </div>
                          <div class="radio-toggle ms-3">
                            <div class="toggle-onOff">
                              <div class="toggle-btn"></div>
                            </div>
                            <span>Required Question</span>
                          </div>
                        </div>
                      </div>
                      <div class="review_btn">
                        <a href="https://templates.seekviral.com/filpo/Templates/SingleProductFeedback/thankyou.html" target="_blank"><button type="button" id="submit" class="submit">Give Review
                          <img src="/assets/images/thumb.gif" alt="thumb" /></button></a>
                        <button type="button">Cancel</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}
export default FeedbackPage;
