import React, { Component } from "react";
import dateUtils from "_helpers/date.utils";

import classNames from 'classnames/bind';
import { accessUtils } from "_helpers";
class TaskBoardListItem extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false,
      permission: accessUtils.getTaskBoardAccessControl(),
    }
  }
  handleRemoveWorkItem = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.handleRemoveWorkItem(this.props.workItem.id, isConfrimDelete);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (

      <tr>
        <td>{this.props.slNo}</td>
        <td>
          <h6 className="mb-0 fsize14">{this.props.workItem.name}</h6>
          <span>{this.props.workItem.taskName}</span>
        </td>
        <td> <span className={`badge badge-primary mx-1 mb-1`}>{this.props.workItem.taskActCategory || 'Other'}</span></td>
        <td>
          <ul className="list-unstyled team-info mb-0">
            <li>
              <img src={`/assets/images/male.jpg`} alt="Assignee" />
            </li>
          </ul>
        </td>
        <td>
          <div className="text-info">Start: {dateUtils.convertDateTimeFormat(this.props.workItem.estStartTime)}</div>
          <div className="text-pink">Duration:
            {this.props.workItem.stage === 4 ? <span className="pl-1"> {dateUtils.minuteToHourString(this.props.workItem.durationInMins) || '0h 0m'}</span> 
            : <span className="pl-1"> {this.state.permission.showWorkItemEstimatedHours === true && dateUtils.minuteToHourString(this.props.workItem.estDurationInMins) || '0h 0m'}</span>}
          </div>
        </td>
        <td>
          <span className={classNames("badge", { "badge-info": this.props.workItem.status === 'NEW' },
            { "badge-warning": this.props.workItem.status === 'ASSIGNED' },
            { "badge-warning": this.props.workItem.status === 'INPROGRESS' },
            { "badge-success": this.props.workItem.status === 'COMPLETED' },
            { "badge-dark": this.props.workItem.status === 'CLOSED' }
          )}>{this.props.workItem.status}</span>
        </td>
        <td>
          <div className="clearfix">
            <div className="float-left"><strong>0%</strong></div>
            <div className="float-right"><small className="text-muted">Progress</small></div>
          </div>
          <div className="progress progress-xs">
            <div className="progress-bar bg-azure" role="progressbar" style={{ width: '0%' }} aria-valuenow={42} aria-valuemin={0} aria-valuemax={100} />
          </div>
        </td>
        <td className="text-right">
          <button type="button" className={classNames("mx-2 plainbutton", { "d-none": this.props.workItem.status === 'INPROGRESS' || this.props.workItem.status === 'COMPLETED' || this.props.workItem.status === 'CLOSED' })} title="Move to Inprogress"
            onClick={(e) => this.props.showTaskStatusUpdateModel(this.props.workItem, "INPROGRESS", false, ()=>{})}
          ><i className="fa fa-play-circle-o colororange fa-lg" /></button>
          <button type="button" className={classNames("mx-2 plainbutton", { "d-none": this.props.workItem.status === 'COMPLETED' || this.props.workItem.status === 'CLOSED' })} title="Move to Completed"
            onClick={(e) => this.props.showTaskStatusUpdateModel(this.props.workItem, "COMPLETED", false, ()=>{})}
          ><i className="fa fa-check-square-o colorgreen fa-lg" /></button>
          {this.props.permission.workItemDeleteEnabled === true &&
            <button type="button"
              onClick={(e) => this.props.handleDeleteWorkItem(this.props.workItem.id, !this.props.workItem.deleted)}
              className="link pl-2" title={this.props.workItem.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
              {this.props.workItem.deleted &&
                <>
                  <i className="fa fa-rotate-left colorred blink_text"></i>
                </>
              }
              {!this.props.workItem.deleted &&
                <>
                  <i className="fa fa-trash"></i>
                </>
              }
            </button>
          }
          {this.props.permission.workItemRemoveEnabled === true &&

            <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveWorkItem(this.state.confrimDelete)}>
              {this.state.confrimDelete === true &&
                <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
              }
              {this.state.confrimDelete === false &&
                <i className="dropdown-icon fa fa-times colorred"></i>
              }
            </button>
          }
        </td>
      </tr>
    );
  }
}

export default TaskBoardListItem;
