import React, { Component } from "react";
import TreeView from "views/common/plugins/TreeView";

class ApplicationConfigLeftMenu extends Component {
    constructor() {
        super();
        this.state = {
            createParentItem: null,
            selectedMenuCategory: null
        }
    }
    
    handleAppConfigMenuSelect = (id, type, category) => {
        if (id !== this.props.appConfigMenuSelection.id) {
            this.setState({
                selectedMenuCategory : category
            })
            this.props.handleAppConfigMenuSelect(id, type, category);
        }
        
    }

    handleCreateButtonClick = (selectedValue, type) => {
        this.setState({
            createParentItem: {
                "id": selectedValue,
                "type": type
            }
        })
    }

    handleClearButtonClick = () => {
        this.setState({
            createParentItem: null
        })
    }
    render() {
        return (

            <div className="card treeMenu">
                <div className="card-header py-1 border-bottom">
                    <span className="fsize15 fweight600">{this.props.header}</span>
                    <div className="card-options">
                        <button className="link card-options-collapse ml-0" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                        {this.props.appConfigMenuSelection && this.props.appConfigMenuSelection.type === this.props.type && (this.props.appConfigMenuSelection.category != 'Team' && (this.state.selectedMenuCategory !== 'Team')) &&
                        <button className="link ml-1"><i className="fe fe-plus" onClick={()=>this.handleCreateButtonClick(this.props.appConfigMenuSelection.id , this.props.type)}/></button>
                        }
                        </div>
                </div>
                <div className="card-body">
                   {this.props.menuData &&  
                <TreeView {...this.props} 
                   handleAppConfigMenuSelect={this.handleAppConfigMenuSelect} 
                   handleClearButtonClick={this.handleClearButtonClick} 
                   node={this.props.menuData} 
                   isRoot={true}
                   createParentItem={this.state.createParentItem}/>}
                </div>
            </div>
        );
    }
}

export default ApplicationConfigLeftMenu;