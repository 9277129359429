import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { validationUtils } from "_helpers";

class PageInnerHeaderAccounts extends PureComponent {

    onFilterTextChange = (value) => {
        let isValidChar = validationUtils.validateAlphaNumbericWithSpecial(value);
        if (isValidChar) {
            this.props.onFilterTextChange(value)
        }
    }

    render() {
        return (
            <div className={`section-body listgridheader ${this.props.stickytop === undefined || this.props.stickytop === true ? "stickytop" : ''} border-bottom`}>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="pt-2">
                            <span>
                                {this.props.breadCrumps.length > 0 &&
                                    <>
                                        {this.props.viewByAccounts &&
                                            <button className="link"
                                                onClick={() => this.props.onSelectItem(null, "ACCOUNT", -1)} >
                                                All Accounts
                                            </button>
                                        }
                                        {!this.props.viewByAccounts &&
                                            <button className="link"
                                                onClick={() => this.props.onSelectItem(null, "USERTEAMS", -1)} >
                                                All User Teams
                                            </button>
                                        }
                                    </>
                                } {this.props.breadCrumps.length === 0 &&
                                    <> {this.props.viewByAccounts ? "All Accounts" : "All User Teams"}</>
                                }
                                <i className={`mx-2 fa  text-success ${this.props.breadCrumps.length === 0 ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
                            </span>
                            {this.props.breadCrumps && this.props.breadCrumps.map((breadCrump, index) =>
                                <React.Fragment key={breadCrump.currentItem.id}>
                                    {index != this.props.breadCrumps.length - 1 &&

                                        <NavLink className="link"
                                            to={"/project/accounts?index=" + index}
                                            onClick={() => this.props.onSelectItem(breadCrump.currentItem, breadCrump.viewName, index)} >
                                            {breadCrump.currentItem.name}
                                            <i className={`mx-2 fa  text-success ${index === this.props.breadCrumps.length - 1 ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
                                        </NavLink>
                                    }
                                    {index === this.props.breadCrumps.length - 1 &&
                                        <>
                                            {breadCrump.currentItem.name}
                                            <i className={`mx-2 fa  text-success ${index === this.props.breadCrumps.length - 1 ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
                                        </>
                                    }
                                </React.Fragment>
                            )}

                        </div>
                        <div className="header-action d-md-flex pt-0">

                            <div className="header-action d-md-flex pt-2 pl-5">

                                {this.props.showFilter &&
                                    <div className="input-icon"><span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                                        <input type="text" disabled={this.props.isLoading} className="form-control input-sm filter" placeholder="Filter..."
                                            value={(this.props.filterOptions && this.props.filterOptions.filterText) || ''}
                                            onChange={(event) => this.onFilterTextChange(event.target.value)}
                                        />
                                    </div>
                                }
                                {this.props.showGridListMenu && 
                                    <ul className="nav nav-tabs page-header-tab pl-2 mr-0 pr-5 xs-hide">
                                        <li className="nav-item pr-0">
                                            <button id="Tab-View" className={`link grid-view-link ${this.props.showGridDefault ? 'active' : ''} `} data-toggle="tab" onClick={this.props.gridClickHandler}><i className="fe fe-grid mr-0" />Show by User Team</button></li>
                                        <li className="nav-item pl-2 pr-2">
                                            <button id="Tab-View" className={`link list-view-link ${this.props.showGridDefault ? '' : 'active'} `} data-toggle="tab" onClick={this.props.listClickHandler}><i className="fe fe-list mr-0" />Show by All Accounts</button></li>
                                    </ul>
                                }
                                {this.props.showCreateMenu &&
                                    <button onClick={this.props.createItemHandler} className="link create-link pt-0 xs-hide pr-3 pl-3"><i className="fe fe-plus-circle mr-1" />ADD {this.props.showCreateMenuText}</button>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        );
    }
}

export default PageInnerHeaderAccounts