import moment from 'moment';
import React, { Component } from 'react'
import { calendarUtils } from '_helpers/calendar.utils';
import classNames from 'classnames/bind';
import dateUtils from '_helpers/date.utils';

class Calendar extends Component {
    constructor() {
        super()
        this.state = {
            selectedDates: [dateUtils.getCurrentTime()],
            viewMonthDate: dateUtils.getCurrentTime(),
            view: "days",
        }
    }

    goToBackMonth = (type) => {
        let viewMonthDate = dateUtils.substractXFromMoments(this.state.viewMonthDate, type, 1);
        this.setState({
            viewMonthDate
        })
        this.props.onCalendarViewDate(viewMonthDate);
    };

    goToNextMonth = (type) => {
        let viewMonthDate = dateUtils.addXToMoments(this.state.viewMonthDate, type, 1);
        this.setState({
            viewMonthDate
        })
        this.props.onCalendarViewDate(viewMonthDate);
    };

    goToCurrent = () => {
        this.setState({
            viewMonthDate: dateUtils.getCurrentTime()
        })
        this.props.onCalendarViewDate(dateUtils.getCurrentTime());
    };

    convertToMap = () => {
        this.props.holidayItems.map()
    }

    onDateSelect = (selectedDate) => {
        this.setState({ selectedDates: [selectedDate] },
            () => {
                this.props.onSelectedDateChange(this.state.selectedDates);
            });
    }
    onWeekDatesSelect = (selectedDates) => {
        this.setState({ selectedDates: selectedDates.map(selectedDate => selectedDate.date) },
            () => {
                this.props.onSelectedDateChange(this.state.selectedDates);
            });
    }

    onMonthDatesSelect = (weekByWeekOftheMonth) => {
        let selectedDates = []
        weekByWeekOftheMonth.map(weekDates => weekDates.weekDates.map(selectedDate => { if (selectedDate.isCurrentMonth) { selectedDates.push(selectedDate.date) } }))
        this.setState({ selectedDates: selectedDates },
            () => {
                this.props.onSelectedDateChange(this.state.selectedDates);
            });
    }

    render() {
        let weekByWeekOftheMonth = calendarUtils.getWeekByWeekOftheMonth(this.state.viewMonthDate);
        return (
            <div className="card">
                <div className="card-header mt-0 pt-0">
                    <button className="card-title link" onClick={() => { this.onMonthDatesSelect(weekByWeekOftheMonth) }}>{this.state.viewMonthDate.format(dateUtils.DATE_MONTH_FORMAT)}</button>
                    <div className="card-options">

                        <div className="card-options">
                            <button className="link mr-2" onClick={() => this.goToBackMonth("months")}><i className="fa fa-angle-double-left fa-lg" /></button>
                            <button className="link mr-2 " onClick={this.goToCurrent}><i className="fa fa-home" /></button>
                            <button className="link " onClick={() => this.goToNextMonth("months")}><i className="fa fa-angle-double-right fa-lg" /></button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <table className="table table-calendar table-bordered">
                        <tbody>
                            <tr>
                                <th className="text-muted bgcolorlightgray fsize9">Week</th>
                                <th>Mo</th>
                                <th>Tu</th>
                                <th>We</th>
                                <th>Th</th>
                                <th>Fr</th>
                                <th>Sa</th>
                                <th>Su</th>
                            </tr>

                            <CalendarView
                                datesByWeekList={weekByWeekOftheMonth}
                                selectedDates={this.state.selectedDates}
                                holidayItems={this.props.holidayItems}
                                complatedTaskItems={this.props.complatedTaskItems}
                                onDateSelect={this.onDateSelect}
                                onWeekDatesSelect={this.onWeekDatesSelect}
                            />

                        </tbody>
                    </table>
                </div>
            </div>)
    }
}

class CalendarView extends Component {

    isSelectedDate = (thisDate) => {
        return this.props.selectedDates.some(selectedDate => selectedDate.date() === thisDate.date() && selectedDate.month() === thisDate.month());
    }

    isHoliday = (thisDate) => {
        return this.props.holidayItems && this.props.holidayItems.some(holidayItem => holidayItem.holidayDate.substring(0, holidayItem.holidayDate.indexOf("T")) === thisDate.format(dateUtils.DATE_PICKER_FORMAT));
    }

    getTaskMark = (thisDate) => {
        // return "green";
        //let currentTask = this.props.complatedTaskItems && this.props.complatedTaskItems.filter(completedTaskItem => completedTaskItem.actualStartTime.substring(0, completedTaskItem.actualStartTime.indexOf("T")) === thisDate.format(dateUtils.DATE_PICKER_FORMAT));
    }

    render() {
        return (
            <>
                {this.props.datesByWeekList.map((weekListItem) =>
                    <tr key={weekListItem.weekNumber}>
                        <td onClick={() => this.props.onWeekDatesSelect(weekListItem.weekDates)} className="text-muted bgcolorlightgray fsize14 fweight600">{weekListItem.weekNumber}</td>
                        {weekListItem.weekDates.map((date, index) =>
                            <td key={date.dayNumber + "-" + index} onClick={() => this.props.onDateSelect(date.date)} className={classNames("", { "holiday": this.isHoliday(date.date) }, { "active": this.isSelectedDate(date.date) }, { "text-muted notcurrentmonth": !date.isCurrentMonth }, { "fweight600": date.isCurrentMonth }, { "weekend": index === 5 || index === 6 }, { "currentDay": date.isCurrentDate })}>
                                <span className={"marked " + (this.getTaskMark(date.date) || '')}>{date.dayNumber}</span>
                            </td>
                        )}
                    </tr>

                )}
            </>
        )
    }
}

export default Calendar;