import { invoiceConstants } from '../_constants';

const invoiceFieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
];

export function invoice(state = { invoiceFieldConfig }, action) {

  switch (action.type) {

    case invoiceConstants.GETALL_INVOICES_REQUEST:
      return {
        ...state,
        invoicesLoading: true
      };
    case invoiceConstants.GETALL_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        items: action.invoices
      };
    case invoiceConstants.GETALL_INVOICES_FAILURE:
      return {
        ...state,
        invoicesLoading: false,
        error: action.error
      };

    case invoiceConstants.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case invoiceConstants.CREATE_INVOICE_SUCCESS:
      state.items ? state.items.push(action.invoice) : state.items = [action.invoice];
      return {
        ...state,
        creating: false,
        showCreateModel: action.showModel,
      };
    case invoiceConstants.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case invoiceConstants.UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items && state.items.map(invoice =>
          invoice.id === action.invoiceId
            ? { ...invoice, updating: true }
            : invoice
        ),
        error: null
      };
    case invoiceConstants.UPDATE_INVOICE_SUCCESS:
      let newItems = null;
      if (state.items) {
        newItems = [...state.items];
        const index = state.items.findIndex(invoice => invoice.id === action.invoiceId);
        newItems[index] = { ...newItems[index], ...action.invoice }
      }
      const currentInvoiceInfo = state.selectedInvoiceInfo && action.invoice.id === state.selectedInvoiceInfo.id ? { ...state.selectedInvoiceInfo, ...action.invoice } : state.selectedInvoiceInfo

      return {
        ...state,
        updating: false,
        items: newItems,
        showCreateModel: false,
        selectedInvoiceInfo: currentInvoiceInfo
      };
    case invoiceConstants.UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items && state.items.map(invoice => {
          if (invoice.id === action.invoiceId) {
            const { updating, ...invoiceCopy } = invoice;
            return { ...invoiceCopy, deleteError: action.error };
          }
          return invoice;
        }),
        error: action.error
      };

    case invoiceConstants.REMOVE_INVOICE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(invoice => invoice.id !== action.invoiceId)
      };
    case invoiceConstants.ACTIVATE_INVOICE_REQUEST:
      return {
        ...state,
        items: state.items.map(invoice =>
          invoice.id === action.invoiceId
            ? { ...invoice, activating: true }
            : invoice
        )
      };


    case invoiceConstants.CLOSE_INVOICE_CREATE_WINDOW:
      const { selectedInvoiceInfo, ...stateCopy } = state;
      return {
        ...stateCopy,
        showCreateModel: false
      };


    case invoiceConstants.GET_INVOICE_REQUEST:
      return {
        ...state,
        selectedInvoiceInfo: null,
        loading: true
      };
    case invoiceConstants.GET_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedInvoiceInfo: action.invoice
      };
    case invoiceConstants.GET_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}