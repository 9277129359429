import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { commonUtils } from "_helpers";
import { validationUtils } from '_helpers'
import dateUtils from "_helpers/date.utils";
import workItemUtils from "_helpers/workitem.utils";
import SidebarFooter from "./SidebarFooter";


class CreateWorkItemModel extends Component {

  constructor() {
    super();
    this.state = {
      workItemRequest: {},
      formErrors: {},
      hasFormError: false
    }
  }

  componentDidMount() {
    this.initializeRequest();
    let workItemMinDates = workItemUtils.getWorkItemCreateStartMinDate(dateUtils.convertDateStringToMoment(this.props.taskInfo.estStartTime), this.props.permission);
    let workItemMaxDates = workItemUtils.getWorkItemCreateEndMaxDate(dateUtils.convertDateStringToMoment(this.props.taskInfo.estCompletionTime), this.props.permission);
    this.setState(prevState => ({
      workItemMinDates,
      workItemMaxDates,
    }))
  }
  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }

  initializeRequest = () => {
    let workItemRequest = this.props.editMode ? this.props.selectedWorkItemInfo :
      {
        defaultTeam: commonUtils.getCurrentUserTeamId(),
        taskId: this.props.taskInfo.id,
        canComplete: false,
        estStartTime: dateUtils.toMomentToDate(this.getDefaultTime()),
        createdBy: commonUtils.getCurrentUserId()
      }

    this.setState({
      workItemRequest: {
        ...workItemRequest,
        canComplete: workItemRequest.status === 'COMPLETED',
        "durationInHours": dateUtils.minuteToHourDecimal(workItemRequest.durationInMins ? workItemRequest.durationInMins : 0),
        "estDurationInHours": dateUtils.minuteToHourDecimal(workItemRequest.estDurationInMins ? workItemRequest.estDurationInMins : 0)
      }
    });
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  onTimeEntryValueChange = (fieldName, valueInHours) => {
    if (!validationUtils.validateDecimalNumberic(valueInHours) || (valueInHours && this.props.permission.maxHoursAllowedToEnter < parseFloat(valueInHours) || (parseFloat(valueInHours) - parseInt(valueInHours) > .59))) {
      return;
    }
    let valueInMins = 0;
    if (valueInHours !== "") {
      valueInMins = (parseInt(valueInHours) * 60) + (Math.round((parseFloat(valueInHours) - parseInt(valueInHours)) * 100));
    }
    if (fieldName === 'durationInHours') {
      this.setState(prevState => ({
        workItemRequest: {
          ...prevState.workItemRequest,
          estDurationInMins: prevState.workItemRequest.estDurationInMins ? prevState.workItemRequest.estDurationInMins : valueInMins,
          durationInMins: valueInMins,
          "durationInHours": valueInHours
        }
      }))
    } else {
      if (fieldName === 'estDurationInHours') {
        this.setState(prevState => ({
          workItemRequest: {
            ...prevState.workItemRequest,
            estDurationInMins: valueInMins,
            durationInMins: 0,
            "estDurationInHours": valueInHours
          }
        }))
      }
    }
  }

  onChange = (fieldName, value) => {
    this.onResetFormError()
    this.setState(prevState => ({
      workItemRequest: {
        ...prevState.workItemRequest,
        [fieldName]: value
      }
    }), () => {
      if (fieldName === 'canComplete') {
        this.validateDateSelection()
      }
    })

  }

  handleSubmit = (isCreateAnotherEnabled) => {
    let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.workItemRequest, this.state.formErrors);
    //console.log("Validating form  request, isValid - " + isValid);
    if (isValid) {
      this.props.handleSubmit(this.state.workItemRequest, this.props.editMode, isCreateAnotherEnabled, !this.props.editMode ? this.initializeRequest : null);
    } else {
      this.setState({
        hasFormError: true
      })
    }
  }


  getDateSelectionMinDate = (minDateObject) => {
    let workItemStartDate = null;
    if (minDateObject) {
      if (this.state.workItemRequest.canComplete && this.state.workItemRequest.canComplete === true) {
        workItemStartDate = minDateObject.projectStart;
      } else {
        workItemStartDate = minDateObject.projectAssignStart;
      }
    }
    return workItemStartDate;
  }

  getDateSelectionMaxDate = (maxDateObject) => {
    let workItemEndDate = null;
    if (maxDateObject) {
      if (this.state.workItemRequest.canComplete && this.state.workItemRequest.canComplete === true) {
        workItemEndDate = maxDateObject.projectEnd;
      } else {
        workItemEndDate = maxDateObject.projectAssignEnd;
      }
    }
    return workItemEndDate;
  }

  validateDateSelection = () => {
    let dateMinDateMoment = this.getDateSelectionMinDate(this.props.minDate);
    let dateMaxDateMoment = this.getDateSelectionMaxDate(this.props.maxDate);
    let workItemStartDate = dateUtils.convertDateStringToMoment(this.state.workItemRequest.estStartTime);
    let isValid = true;
    if (dateMinDateMoment && dateMaxDateMoment) {
      dateMinDateMoment = dateMinDateMoment.clone().startOf("day");
      dateMaxDateMoment = dateMaxDateMoment.clone().endOf("day");
      if (workItemStartDate && (workItemStartDate.diff(dateMinDateMoment) < 0 || workItemStartDate.diff(dateMaxDateMoment) > 0)) {
        this.onChange("estStartTime", null);
        isValid = false;
      }

    }
    return isValid;
  }
  getDefaultTime = () => {
    return dateUtils.getCurrentTime();
  }

  getRestrictedEmployees = () => {

    let employees = this.props.allEmployees;
    if (this.props.taskInfo.restrictedByEmployeeDetails && this.props.taskInfo.restrictedByEmployeeDetails.length > 0) {
      employees = this.props.taskInfo.restrictedByEmployeeDetails;
    } else if (this.props.taskInfo.projectInfo.restrictedByEmployeeDetails && this.props.taskInfo.projectInfo.restrictedByEmployeeDetails.length > 0) {
      employees = this.props.taskInfo.projectInfo.restrictedByEmployeeDetails;
    }

    let restrictedUserTeamIds = (this.props.allUserTeams && this.props.allUserTeams.map(userTeam => userTeam.id));
    if (this.props.taskInfo.restrictedByUserTeamIds && this.props.taskInfo.restrictedByUserTeamIds.length > 0) {
      restrictedUserTeamIds = this.props.taskInfo.restrictedByUserTeamIds;
    } else if (this.props.taskInfo.projectRestrictedByUserTeamIds && this.props.taskInfo.projectRestrictedByUserTeamIds.length > 0) {
      restrictedUserTeamIds = this.props.taskInfo.projectRestrictedByUserTeamIds;
    }
    if (employees && restrictedUserTeamIds) {
      employees = commonUtils.filterEmployeesByTeams(employees, restrictedUserTeamIds)
    }
    let restrictedBySkills = this.props.taskInfo.restrictedByUserSkills;
    if (restrictedBySkills) {
      employees = commonUtils.filterSkillsByEmployee(employees, restrictedBySkills)
    }
    return employees;
  }

  getRestrictedByEmployeeOptions = () => {
    let employees = this.getRestrictedEmployees();
    return commonUtils.convertObjectToSelectObject(employees);
  }

  render() {
    return (
      <div id="createworkItemsidebar" className={`shadow-lg bg-white rounded workitem model ${this.props.show === false ? 'closed' : 'p-1 mb-2'}`}>
        <div className="card-body pl-3 pr-3">
          <div className="row">
            <div className="col-12 row">
              <div className="col-6">
                <CustomInput inputtype='text'
                  label="Name"
                  name="name"
                  autoComplete="on"
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.workItemRequest}
                  onChange={this.onChange}
                  onFormError={this.onFormError}
                  placeholder="Name..."
                  datatype='alpnumspl' />
              </div>

              <div className="col-6">
                <CustomInput
                  type='select'
                  label="Assigned To"
                  name="employeeId"
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.workItemRequest}
                  onChange={this.onChange}
                  placeholder="Assignee..."
                  options={this.getRestrictedByEmployeeOptions()}
                  onFormError={this.onFormError}
                />
              </div>
            </div>
            <div className="col-6 row">
              <div className="col-6">
                <CustomInput
                  type='dateselect'
                  label={this.state.workItemRequest && (!this.state.workItemRequest.canComplete || this.state.workItemRequest.canComplete === false) ? "Est Start Date" : "Actual Start Date"}
                  name="estStartTime"
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.workItemRequest}
                  onChange={this.onChange}
                  time={true}
                  format={dateUtils.DATE_TIME_PICKER_FORMAT}
                  onFormError={this.onFormError}
                  minDate={this.props.minDate && dateUtils.toMomentToDate(this.getDateSelectionMinDate(this.props.minDate))}
                  maxDate={this.props.maxDate && dateUtils.toMomentToDate(this.getDateSelectionMaxDate(this.props.maxDate))}
                />

              </div>
              <div className="col-6 pt-0">
                {commonUtils.getAppConfigValue(this.props.createConfig, "enableCategorySelection") &&
                  <CustomInput
                    type='select'
                    createConfig={this.props.createConfig}
                    label="Category"
                    name="taskActCategory"
                    configName="taskActCategory"
                    fieldConfig={this.props.fieldConfig}
                    mappingObject={this.state.workItemRequest}
                    onChange={this.onChange}
                    placeholder="Category..."
                    isAppConfig={true}
                    onFormError={this.onFormError}
                  />
                }
                {!commonUtils.getAppConfigValue(this.props.createConfig, "enableCategorySelection") &&
                  <CustomInput
                    type='select'
                    label="Category"
                    name="taskActCategory"
                    configName="taskActCategory"
                    fieldConfig={this.props.fieldConfig}
                    mappingObject={this.state.workItemRequest}
                    onChange={this.onChange}
                    placeholder="Category..."
                    options={this.props.createConfig && this.props.taskInfo && workItemUtils.getWorkItemCategories(this.props.createConfig, commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") ? this.props.taskInfo.serviceLine : this.props.taskInfo.projectInfo.category, this.props.taskInfo.taskCategory)}
                    onFormError={this.onFormError}
                  />
                }
              </div>
              <div className="col-4">
                {!this.state.workItemRequest.canComplete &&
                  <CustomInput
                    inputtype='number'
                    label="Est Duration In Hrs"
                    name="estDurationInHours"
                    fieldConfig={this.props.fieldConfig}
                    mappingObject={this.state.workItemRequest}
                    onChange={this.onTimeEntryValueChange}
                    onFormError={this.onFormError}
                    placeholder="Duration..." />
                }
                {this.state.workItemRequest.canComplete === true &&
                  <CustomInput
                    inputtype='number'
                    label={"Duration In Hrs"}
                    name="durationInHours"
                    fieldConfig={this.props.fieldConfig}
                    mappingObject={this.state.workItemRequest}
                    onChange={this.onTimeEntryValueChange}
                    onFormError={this.onFormError}
                    placeholder="Duration..." />
                }
              </div>
              {this.props.permission.enableCreateCompletedWorkItem === true &&
                <div className="col-4">
                  <CustomInput
                    label="Mark As Complete?"
                    name="canComplete"
                    fieldConfig={this.props.fieldConfig}
                    mappingObject={this.state.workItemRequest}
                    onChange={this.onChange}
                    onFormError={this.onFormError}
                    inputtype='checkbox' />
                </div>
              }
              {this.state.workItemRequest.canComplete === true &&
                <>
                  <div className="col-4">
                    <CustomInput inputtype='text'
                      label={"Quantity"}
                      name="quantity"
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.workItemRequest}
                      onChange={this.onChange}
                      onFormError={this.onFormError}
                      placeholder="Quantity..."
                      datatype='num' />
                  </div>
                  <div className="col-6 pt-0">
                    <CustomInput
                      type='select'
                      label="Skill"
                      name="skill"
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.workItemRequest}
                      onChange={this.onChange}
                      placeholder="Skill..."
                      isAppConfig={true}
                      createConfig={this.props.employeeAppConfig}
                      configName={"skills"}
                      onFormError={this.onFormError}
                    />
                  </div>
                </>
              }
            </div>
            <div className="col-6 row">
              <div className="col-12">
                <CustomInput
                  type='textarea'
                  label="Description"
                  name="description"
                  rows={2}
                  fieldConfig={this.props.fieldConfig}
                  mappingObject={this.state.workItemRequest}
                  onChange={this.onChange}
                  placeholder="Description..."
                  onFormError={this.onFormError}
                  datatype='alpnumspl'
                />
              </div>
            </div>
          </div>
        </div>
        <SidebarFooter
          handleSubmit={this.handleSubmit}
          editMode={this.props.editMode}
          closeButtonLabel={'Close'}
          initializeRequest={this.props.onCloseCreateItem}
          hasFormError={this.state.hasFormError}
          onResetFormError={this.onResetFormError}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  taskInfo: state.tasks.selectedTaskInfo,
  isLoading: state.tasks.loading,
  fieldConfig: state.workItem.fieldConfig,
  createConfig: state.workItem.createConfig,
  allEmployees: state.employees.allEmployees,
  employeeAppConfig: state.employees.createConfig,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateWorkItemModel);

