import { checklistService } from '_services/checklist.service';
import { checklistConstants } from '../_constants';
import { commonActions } from './common.actions';
import { notificationActions } from './notification.actions';

export const checklistActions = {
    getAllChecklists,
    createChecklist,
    updateChecklist,
    removeChecklist,
    activateChecklist,
    updateItemChecklistData
};
function getAllChecklists(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        checklistService.getAllChecklists(requestObj)
            .then(
                checklists => {
                    dispatch(success(checklists.payload));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: checklistConstants.GETALL_CHECKLISTS_REQUEST } }
    function success(checklists) { return { type: checklistConstants.GETALL_CHECKLISTS_SUCCESS, checklists } }
    function failure(error) { return { type: checklistConstants.GETALL_CHECKLISTS_FAILURE, error } }
}

function createChecklist(checklistRequest, submitCallBackFunction) {
    return dispatch => {
        dispatch(request(checklistRequest));
        dispatch(notificationActions.clear());
        checklistService.createChecklist(checklistRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload));
                    submitCallBackFunction();
                    dispatch(notificationActions.success('Checklist Creation successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)));
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));

                }
            );
    };

    function request(checklist) { return { type: checklistConstants.CREATE_CHECKLIST_REQUEST, checklist } }
    function success(checklist) { return { type: checklistConstants.CREATE_CHECKLIST_SUCCESS, checklist } }
    function failure(error) { return { type: checklistConstants.CREATE_CHECKLIST_FAILURE, error } }
}

function updateChecklist(checklistRequest, submitCallBackFunction) {
    return dispatch => {
        dispatch(request(checklistRequest));
        dispatch(notificationActions.clear());
        checklistService.updateChecklist(checklistRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload, checklistRequest.id));
                    submitCallBackFunction();
                    dispatch(notificationActions.success('Checklist update successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)))
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));
                }
            );
    };

    function request(checklist) { return { type: checklistConstants.UPDATE_CHECKLIST_REQUEST, checklist } }
    function success(checklist, checklistId) { return { type: checklistConstants.UPDATE_CHECKLIST_SUCCESS, checklist, checklistId } }
    function failure(error) { return { type: checklistConstants.UPDATE_CHECKLIST_FAILURE, error } }
}

function removeChecklist(checklistId) {
    return dispatch => {
        dispatch(request(checklistId));

        checklistService.removeChecklist(checklistId)
            .then(
                taskResponse => dispatch(success(checklistId)),
                error => {
                    dispatch(failure(checklistId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(checklistId) { return { type: checklistConstants.REMOVE_CHECKLIST_REQUEST, checklistId } }
    function success(checklistId) { return { type: checklistConstants.REMOVE_CHECKLIST_SUCCESS, checklistId } }
    function failure(checklistId, error) { return { type: checklistConstants.REMOVE_CHECKLIST_FAILURE, checklistId, error } }
}

function activateChecklist(checklistId, isActive) {
    return dispatch => {
        dispatch(request(checklistId));

        checklistService.activateChecklist(checklistId, isActive)
            .then(
                checklistResponse => dispatch(success(checklistId, isActive)),
                error => {
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(checklistId) { return { type: checklistConstants.ACTIVATE_CHECKLIST_REQUEST, checklistId } }
    function success(checklistId, isActive) { return { type: checklistConstants.ACTIVATE_CHECKLIST_SUCCESS, checklistId, isActive } }
  
}


function updateItemChecklistData(checklistData, itemId, itemType) {
    return dispatch => {
        dispatch(request());
        checklistService.updateItemChecklistData(checklistData, itemId, itemType).then(
            checklistResponse => {
                dispatch(notificationActions.success('Checklist Updated successful'));
                dispatch(success(checklistResponse.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
    };
    function request() { return { type: checklistConstants.CHECKLIST_ITEM_UPDATE_REQUEST} }
    function success(checklistResponse) { return { type: checklistConstants.CHECKLIST_ITEM_UPDATE_SUCCESS, checklistResponse } }
    function failure(error) { return { type: checklistConstants.CHECKLIST_ITEM_UPDATE_FAILURE, error } }
}