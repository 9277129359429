export const projectConstants = {
    INITIALIZE_CREATE_PROJECT_REQUEST: 'INITIALIZE_CREATE_PROJECT_REQUEST',
    INITIALIZE_CREATE_PROJECT_SUCCESS: 'INITIALIZE_CREATE_PROJECT_SUCCESS',
    INITIALIZE_CREATE_PROJECT_FAILURE: 'INITIALIZE_CREATE_PROJECT_FAILURE',

    CLEAR_SELECTED_PROJECT: 'CLEAR_SELECTED_PROJECT',

    GETALL_PROJECTS_REQUEST: 'GETALL_PROJECTS_REQUEST',
    GETALL_PROJECTS_SUCCESS: 'GETALL_PROJECTS_SUCCESS',
    GETALL_PROJECTS_FAILURE: 'GETALL_PROJECTS_FAILURE',

    GETALL_PURCHASE_ORDER_PROJECTS_REQUEST: 'GETALL_PURCHASE_ORDER_PROJECTS_REQUEST',
    GETALL_PURCHASE_ORDER_PROJECTS_SUCCESS: 'GETALL_PURCHASE_ORDER_PROJECTS_SUCCESS',
    GETALL_PURCHASE_ORDER_PROJECTS_FAILURE: 'GETALL_PURCHASE_ORDER_PROJECTS_FAILURE',

    GETALL_PROJECTS_TIMESHEET_REQUEST: 'GETALL_PROJECTS_TIMESHEET_REQUEST',
    GETALL_PROJECTS_TIMESHEET_SUCCESS: 'GETALL_PROJECTS_TIMESHEET_SUCCESS',
    GETALL_PROJECTS_TIMESHEET_FAILURE: 'GETALL_PROJECTS_TIMESHEET_FAILURE',

    GET_PROJECT_REQUEST: 'GET_PROJECT_REQUEST',
    GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
    GET_PROJECT_FAILURE: 'GET_PROJECT_FAILURE',

    SHOW_PROJECT_CREATE_WINDOW: "SHOW_PROJECT_CREATE_WINDOW",
    CLOSE_PROJECT_CREATE_WINDOW: "CLOSE_PROJECT_CREATE_WINDOW",
    CREATE_PROJECT_REQUEST: 'CREATE_PROJECT_REQUEST',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE',

    UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',

    DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE',

    REMOVE_PROJECT_REQUEST: 'REMOVE_PROJECT_REQUEST',
    REMOVE_PROJECT_SUCCESS: 'REMOVE_PROJECT_SUCCESS',
    REMOVE_PROJECT_FAILURE: 'REMOVE_PROJECT_FAILURE',

    ARCHIVE_PROJECT_REQUEST: 'ARCHIVE_PROJECT_REQUEST',
    ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
    ARCHIVE_PROJECT_FAILURE: 'ARCHIVE_PROJECT_FAILURE',
    ADD_TO_FAVORITES_PROJECT_REQUEST: 'ADD_TO_FAVORITES_PROJECT_REQUEST',
    ADD_TO_FAVORITES_PROJECT_SUCCESS: 'ADD_TO_FAVORITES_PROJECT_SUCCESS',
    ADD_TO_FAVORITES_PROJECT_FAILURE: 'ADD_TO_FAVORITES_PROJECT_FAILURE',

    CHANGE_FILTER_PROJECTS: 'CHANGE_FILTER_PROJECTS',
    INITIALIZE_PROJECTS_USER_TEAMS: 'INITIALIZE_PROJECTS_USER_TEAMS',

    PROJECT_UPDATE_ESTIMATE_REQUEST: 'PROJECT_UPDATE_ESTIMATE_REQUEST',
    PROJECT_UPDATE_ESTIMATE_SUCCESS: 'PROJECT_UPDATE_ESTIMATE_SUCCESS',
    PROJECT_UPDATE_ESTIMATE_FAILURE: 'PROJECT_UPDATE_ESTIMATE_FAILURE',
    
    SHOW_PROJECT_VIEW_WINDOW: 'SHOW_PROJECT_VIEW_WINDOW',
    CLOSE_PROJECT_VIEW_WINDOW: 'CLOSE_PROJECT_VIEW_WINDOW',

    GETALL_REPORT_REQUEST: 'GETALL_REPORT_REQUEST',
    GETALL_REPORT_SUCCESS: 'GETALL_REPORT_SUCCESS',
    GETALL_REPORT_FAILURE: 'GETALL_REPORT_FAILURE',
    INITIALIZE_REPORTS_USER_TEAMS: 'INITIALIZE_REPORTS_USER_TEAMS',
    CHANGE_FILTER_REPORTS: 'CHANGE_FILTER_REPORTS',
    INITIALIZE_CREATE_REPORTCONFIG_REQUEST: 'INITIALIZE_CREATE_REPORTCONFIG_REQUEST',
    INITIALIZE_CREATE_REPORTCONFIG_SUCCESS: 'INITIALIZE_CREATE_REPORTCONFIG_SUCCESS',
    INITIALIZE_CREATE_REPORTCONFIG_FAILURE: 'INITIALIZE_CREATE_REPORTCONFIG_FAILURE',

    SHOW_REPORTCONFIG_WINDOW: 'SHOW_REPORTCONFIG_WINDOW',
    CLOSE_REPORTCONFIG_WINDOW: 'CLOSE_REPORTCONFIG_WINDOW',

    CHANGE_FILTER_ACCOUNTS: 'CHANGE_FILTER_ACCOUNTS',
    SHOW_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW: 'SHOW_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW',
    CLOSE_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW: 'CLOSE_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW',

    GET_ALL_PROJECT_ACCOUNTS_REQUEST: 'GET_ALL_PROJECT_ACCOUNTS_REQUEST',
    GET_ALL_PROJECT_ACCOUNTS_SUCCESS: 'GET_ALL_PROJECT_ACCOUNTS_SUCCESS',
    GET_ALL_PROJECT_ACCOUNTS_FAILURE: 'GET_ALL_PROJECT_ACCOUNTS_FAILURE',

    GET_ALL_PROJECT_TEAM_ACCOUNTS_REQUEST: 'GET_ALL_PROJECT_TEAM_ACCOUNTS_REQUEST',
    GET_ALL_PROJECT_TEAM_ACCOUNTS_SUCCESS: 'GET_ALL_PROJECT_TEAM_ACCOUNTS_SUCCESS',
    GET_ALL_PROJECT_TEAM_ACCOUNTS_FAILURE: 'GET_ALL_PROJECT_TEAM_ACCOUNTS_FAILURE',

    GET_ALL_PROJECT_ACCOUNTS_SUB_REQUEST: 'GET_ALL_PROJECT_ACCOUNTS_SUB_REQUEST',
    GET_ALL_PROJECT_ACCOUNTS_SUB_SUCCESS: 'GET_ALL_PROJECT_ACCOUNTS_SUB_SUCCESS',
    GET_ALL_PROJECT_ACCOUNTS_SUB_FAILURE: 'GET_ALL_PROJECT_ACCOUNTS_SUB_FAILURE',

    CREATE_ACCOUNT_ITEM_REQUEST: 'CREATE_ACCOUNT_ITEM_REQUEST',
    CREATE_ACCOUNT_ITEM_SUCCESS: 'CREATE_ACCOUNT_ITEM_SUCCESS',
    CREATE_ACCOUNT_ITEM_FAILURE: 'CREATE_ACCOUNT_ITEM_FAILURE',

    UPDATE_ACCOUNT_ITEM_REQUEST: 'UPDATE_ACCOUNT_ITEM_REQUEST',
    UPDATE_ACCOUNT_ITEM_SUCCESS: 'UPDATE_ACCOUNT_ITEM_SUCCESS',
    UPDATE_ACCOUNT_ITEM_FAILURE: 'UPDATE_ACCOUNT_ITEM_FAILURE',

    DETACH_ACCOUNT_ITEM_REQUEST: 'DETACH_ACCOUNT_ITEM_REQUEST',
    DETACH_ACCOUNT_ITEM_SUCCESS: 'DETACH_ACCOUNT_ITEM_SUCCESS',
    DETACH_ACCOUNT_ITEM_FAILURE: 'DETACH_ACCOUNT_ITEM_FAILURE',

    ADD_TO_ACCOUNT_ITEM_REQUEST: 'ADD_TO_ACCOUNT_ITEM_REQUEST',
    ADD_TO_ACCOUNT_ITEM_SUCCESS: 'ADD_TO_ACCOUNT_ITEM_SUCCESS',
    ADD_TO_ACCOUNT_ITEM_FAILURE: 'ADD_TO_ACCOUNT_ITEM_FAILURE',

    REMOVE_ACCOUNT_ITEM_REQUEST: 'REMOVE_ACCOUNT_ITEM_REQUEST',
    REMOVE_ACCOUNT_ITEM_SUCCESS: 'REMOVE_ACCOUNT_ITEM_SUCCESS',
    REMOVE_ACCOUNT_ITEM_FAILURE: 'REMOVE_ACCOUNT_ITEM_FAILURE',
    GET_ALL_CURRENT_VIEW_ACCOUNTS_SUCCESS: 'GET_ALL_CURRENT_VIEW_ACCOUNTS_SUCCESS'
};
