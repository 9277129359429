import React, { Component } from "react";

class SelectInput extends Component {

    isOptionEnabled = (value) => {
        return this.props.changeableStatus && this.props.changeableStatus.includes(value);
    }
    onChange = (itemId, value)=>{
        let canChange = true;
        if(this.props.allowLevelDown !== undefined && this.props.allowLevelDown === false) {
            let currentOption = this.props.options.find(option => {
                return option.value === this.props.value
              })

              let newOption = this.props.options.find(option => {
                return option.value === value
              })

              if(currentOption && currentOption.level && (currentOption.level > newOption.level)) {
                canChange = false;
              }
        }
        if(canChange === true){
            this.props.onChange(itemId, value);
        }
    }
    render() {
        return (
            <>
                {!this.props.isReadOnly &&
                    <select
                        className={this.props.className + " noborderselect text-center text-uppercase"}
                        onChange={(e) => this.onChange(this.props.itemId, e.target.value)}
                        value={this.props.value}>
                        {this.props.options.map(option =>
                            <option disabled={!this.isOptionEnabled(option.value)} key={option.value} value={option.value}>{option.label}</option>
                        )}
                    </select>
                }
                {this.props.isReadOnly &&
                    <span className={this.props.className}>
                        {this.props.value}
                    </span>
                }
            </>
        );
    }
}

export default SelectInput;
