import classNames from "classnames/bind";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { workItemActions } from "_actions";
import { validationUtils } from "_helpers";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";
import ItemNotFoundSection from "./ItemsNotFoundSection";
import workItemUtils from "_helpers/workitem.utils";
class WorkItemActiveItems extends Component {
    handleDeleteWorkItem = (workItemIdTobeDeleted, isDelete) => {
        store.dispatch(this.props.deleteWorkItem(workItemIdTobeDeleted, isDelete));
    }

    handleRemoveWorkItem = (workItemId) => {
        store.dispatch(this.props.removeWorkItem(workItemId));
    }

    handleEditWorkItem = (workItemId) => {
        this.props.handleEditWorkItem(workItemId);
    }

    handleUpdateWorkItem = (workItemRequest) => {
        store.dispatch(this.props.updateWorkItem(workItemRequest));
    }

    render() {
        const listByProjectsAndTasks =
            this.props.items && this.props.items.reduce(function (r, a) {
                let projectId = a.projectId || 'nonProject';
                let taskId = a.taskId || a.category;
                r[projectId] = r[projectId] || [];
                r[projectId][taskId] = r[projectId][taskId] || [];
                r[projectId][taskId].push(a);
                return r;
            }, Object.create(null));

        const listByTasks =
            this.props.items && this.props.items.reduce(function (r, a) {
                let taskId = a.taskId || a.taskActCategory;
                r[taskId] = r[taskId] || [];
                r[taskId].push(a);
                return r;
            }, Object.create(null));

        const listByProjects =
            this.props.items && this.props.items.reduce(function (r, a) {
                let taskId = a.projectId || "nonProject";
                r[taskId] = r[taskId] || [];
                r[taskId].push(a);
                return r;
            }, Object.create(null));
        return (
            <div className="card-body pt-0" >
                <div className="row">
                    <div className="container-fluid p-0 m-0">
                        <div className="table-responsive">

                            {this.props.isLoading &&
                                <>
                                    <table className="table table-nowrap table-vcenter position-relative">
                                        <tbody>
                                            <tr>
                                                <td colSpan="11">
                                                    <Skeleton count={7} height={40} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            }
                            <div className="taskactivitySection">
                                {this.props.sortBy === 'DELIVERABLE' ?
                                    <div className="ml-1 mt-2">
                                        {listByTasks && Object.keys(listByTasks).map(key =>
                                            <WorkItemActiveItemByItem
                                                key={key}
                                                items={listByTasks[key]}
                                                permission={this.props.permission}
                                                name={this.props.items[0].taskName}
                                                id={listByTasks[key][0].taskId}
                                                estStartTime={listByTasks[key][0].taskEstStartTime}
                                                estCompletionTime={listByTasks[key][0].taskEstCompletionTime}
                                                code={null}
                                                type={listByTasks[key][0].taskType}
                                                status={listByTasks[key][0].taskStatus}
                                                category={listByTasks[key][0].taskActCategory}
                                                handleDeleteWorkItem={this.handleDeleteWorkItem}
                                                handleRemoveWorkItem={this.handleRemoveWorkItem}
                                                handleEditWorkItem={this.handleEditWorkItem}
                                                handleUpdateWorkItem={this.handleUpdateWorkItem}
                                                onCheklistItemClick={this.props.onCheklistItemClick}
                                            />
                                        )}
                                    </div> :
                                    this.props.sortBy === 'PROJECT' ?
                                        <div className="ml-1 mt-2">
                                            {listByProjects && Object.keys(listByProjects).map(key =>

                                                <WorkItemActiveItemByItem
                                                    key={key}
                                                    items={listByProjects[key]}
                                                    permission={this.props.permission}
                                                    name={listByProjects[key][0].projectName}
                                                    id={listByProjects[key][0].projectId}
                                                    estStartTime={listByProjects[key][0].projectEstStartTime}
                                                    estCompletionTime={listByProjects[key][0].projectEstCompletionTime}
                                                    code={listByProjects[key][0].projectcode}
                                                    type={listByProjects[key][0].taskType}
                                                    status={listByProjects[key][0].projectStatus}
                                                    projectCategory={listByProjects[key][0].projectCategory}
                                                    handleDeleteWorkItem={this.handleDeleteWorkItem}
                                                    handleRemoveWorkItem={this.handleRemoveWorkItem}
                                                    handleEditWorkItem={this.handleEditWorkItem}
                                                    handleUpdateWorkItem={this.handleUpdateWorkItem}
                                                    onCheklistItemClick={this.props.onCheklistItemClick}
                                                />

                                            )}
                                        </div> : <div className="ml-1 mt-2">
                                            {this.props.items && this.props.items.length > 0 && this.props.items.map((activityItem, index) =>
                                                <WorkItemActiveItem
                                                    key={activityItem.id}
                                                    item={activityItem}
                                                    view={this.props.view}
                                                    permission={this.props.permission}
                                                    handleDeleteWorkItem={this.handleDeleteWorkItem}
                                                    handleRemoveWorkItem={this.handleRemoveWorkItem}
                                                    handleEditWorkItem={this.handleEditWorkItem}
                                                    handleUpdateWorkItem={this.handleUpdateWorkItem}
                                                    onCheklistItemClick={this.props.onCheklistItemClick}

                                                />
                                            )}
                                        </div>
                                }
                            </div>

                            <ItemNotFoundSection
                                items={this.props.items}
                                isLoading={this.props.isLoading}
                                imgurl="noactivetasks-min.PNG"
                                text="No Work Items found ?"
                                bgcolor={"bgcolorwhite"}
                                description="Try Creating a work item from scratch or Try changing the filters" />

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

class WorkItemActiveItemByItem extends Component {
    constructor() {
        super()
        this.state = {
            showChilds: true
        }
    }

    toggleChildItems = (show) => {
        this.setState({
            showChilds: show
        })
    }

    render() {
        let estStartTime = (this.props.estStartTime && dateUtils.convertDateFormat(this.props.estStartTime)) || '';
        let estCompletionTime = (this.props.estCompletionTime && dateUtils.convertDateFormat(this.props.estCompletionTime)) || '';
        let totalDuration = this.props.items.reduce((totalSum, object) => {
            return totalSum + (object.durationInMins ? object.durationInMins : 0);
        }, 0);
        return (

            <div className="border-bottom mb-2 pb-1 bg-white">
                <div className="d-flex justify-content-between align-items-center mt-0 py-0 mb-2 px-0">
                    <div>
                        <button className="link" onClick={() => this.toggleChildItems(!this.state.showChilds)}><i className={`fa ${this.state.showChilds ? "fa-chevron-down" : "fa-chevron-up"}`}></i></button>
                        <span className="mx-1 fweight600 fsize13">[{this.props.type === 'NON_PROJECT' ? 'NON BILLABLE' : this.props.id}]</span>
                        <span className="fsize14 mx-1 mt-0 align-middle">
                            {this.props.status &&
                                <span className="badge badge-danger fsize9">InProgress</span>
                            }
                        </span>
                        <span className="fsize14 mx-1 d-inline-block text-truncate mt-0 align-middle w-250">
                            {this.props.name || this.props.category}
                        </span>
                        <span className="badge badge-primary mx-1 mb-1">{this.props.projectCategory}</span>
                    </div>
                    <div>
                        <span className="float-right">
                            <span className="mx-1 fsize12 fweight600">{estStartTime} - {estCompletionTime}</span>
                            <span className="custom-rounded mr-3">{dateUtils.minuteToHourString(totalDuration)}</span>
                        </span>
                    </div>
                </div>
                {this.state.showChilds &&
                    <div className="pl-1">
                        {this.props.items && this.props.items.length > 0 && this.props.items.map((activityItem, index) =>
                            <WorkItemActiveItem
                                key={activityItem.id}
                                item={activityItem}
                                permission={this.props.permission}
                                handleDeleteWorkItem={this.props.handleDeleteWorkItem}
                                handleRemoveWorkItem={this.props.handleRemoveWorkItem}
                                handleEditWorkItem={this.props.handleEditWorkItem}
                                handleUpdateWorkItem={this.props.handleUpdateWorkItem}
                                onCheklistItemClick={this.props.onCheklistItemClick}
                            />
                        )}
                    </div>
                }
            </div>
        )
    }
}

class WorkItemActiveItem extends Component {
    constructor() {
        super();
        this.state = {
            confrimDelete: false,
            isActive: false,
            editEnabled: false
        }
    }
    componentDidMount() {
        this.setState({
            workItemRequest: {
                ...this.props.item,
                "estDurationInHrs": dateUtils.minuteToHourDecimal(this.props.item.estDurationInMins),
                "durationInHrs": dateUtils.minuteToHourDecimal(this.props.item.durationInMins),
            }
        })
    }
    handleRemoveWorkItem = (isConfrimDelete) => {
        if (isConfrimDelete) {
            this.props.handleRemoveWorkItem(this.props.item.id, isConfrimDelete);
        } else {
            this.setState({
                confrimDelete: true
            })
        }
    }
    toggeleShowDetails = (isActive) => {
        this.setState({
            isActive: isActive
        })
    }

    onChange = (fieldName, value) => {
        if (validationUtils.validateAlphaNumbericWithSpecial(value)) {
            this.setState(prevState => ({
                workItemRequest: {
                    ...prevState.workItemRequest,
                    [fieldName]: value
                }
            }))
        }
    }

    onTimeEntryValueChange = (fieldName, valueInHours) => {
        if (!validationUtils.validateDecimalNumberic(valueInHours) || (valueInHours && this.props.permission.maxHoursAllowedToEnter < parseFloat(valueInHours) || (parseFloat(valueInHours) - parseInt(valueInHours) > .59))) {
            return;
        }
        let valueInMins = 0;
        if (valueInHours !== "") {
            valueInMins = (parseInt(valueInHours) * 60) + (Math.round((parseFloat(valueInHours) - parseInt(valueInHours)) * 100));
        }
        let durationInMins = this.state.workItemRequest.durationInMins;
        let estDurationInMins = this.state.workItemRequest.estDurationInMins;
        if (fieldName === 'durationInHrs') {
            durationInMins = valueInMins;
        } else if (fieldName === 'estDurationInHrs') {
            estDurationInMins = valueInMins;
        }

        this.setState(prevState => ({
            workItemRequest: {
                ...prevState.workItemRequest,
                "durationInMins": durationInMins,
                "estDurationInMins": estDurationInMins,
                [fieldName]: valueInHours
            }
        }))
    }

    toggelEditEnabled = (value) => {
        this.setState({
            editEnabled: value
        })
    }

    onSubmit = () => {
        this.props.handleUpdateWorkItem(this.state.workItemRequest);
        this.toggelEditEnabled(false);
    }

    isCompletedHourEditByHoursEnabled = () => {
        if (this.props.item && this.props.item.status === 'COMPLETED') {
            let hoursToBeAdded = parseInt(this.props.permission.enableCompletedWorkItemHoursEditTillNextDayXHour || 0)
            return workItemUtils.isWorkItemEditActive(this.props.item.actualStartTime, hoursToBeAdded)
        }
        return false;
    }

    isWorkItemDeleteByHoursEnabled = () => {
        if (this.props.item) {
            let hoursToBeAdded = parseInt(this.props.permission.enableWorkItemDeleteTillNextDayXHour || 0)
            return workItemUtils.isWorkItemEditActive(this.props.item.actualStartTime, hoursToBeAdded)
        }
        return false;
    }


    render() {
        let completedHourEditEnabled = this.state.editEnabled && ((this.props.permission.editCompletedWorkItemHours && this.props.item.status === 'COMPLETED') || this.isCompletedHourEditByHoursEnabled());
        return (
            <>
                {this.props.item &&
                    <div className={`taskActivityContainer ${this.state.isActive ? "active" : ""}`} >
                        <div className="d-flex justify-content-between align-items-center mt-0 py-0 mb-1 px-2 shadow-sm bg-white rounded">
                            <div onClick={() => this.toggeleShowDetails(!this.state.isActive)} >
                                <i className={classNames("fa-lg", { "fa fa-square colororange": this.props.item.status === 'NEW' },
                                    { "fa fa-spinner colororange": this.props.item.status === 'ASSIGNED' },
                                    { "fa fa-spinner fa-spin colororange": this.props.item.status === 'INPROGRESS' },
                                    { "fa fa-check-circle colorgreen": this.props.item.status === 'COMPLETED' },
                                    { "fa fa-minus-circle colorgreen": this.props.item.status === 'CLOSED' }
                                )}></i>
                                <span className={`badge ${"URGENT" === this.props.item.taskPriority ? "badge-info" : "HIGH" === this.props.item.taskPriority ? "badge-primary" : "MEDIUM" === this.props.item.taskPriority ? "badge-warning" : "LOW" === this.props.item.taskPriority ? "badge-danger" : "badge-warning"} mx-1`}>{"URGENT" === this.props.item.taskPriority ? 1 : "HIGH" === this.props.item.taskPriority ? 2 : "MEDIUM" === this.props.item.taskPriority ? 3 : "LOW" === this.props.item.taskPriority ? 4 : 3}</span>
                                <span className="mx-1 fweight600 fsize13 colorblue">{this.props.item.itemIdUserTeamPrefix}</span>
                                <span title={this.props.item.name} className={`fsize14 mx-1 d-inline-block text-truncate mt-0 align-middle ${this.props.view === "PAGE" ? 'w-300' : 'w-200'}`}>
                                    {this.props.item.name}
                                </span>
                                <span className="fsize14 mx-1 d-none">{this.props.item.taskName || ''}</span>
                            </div>
                            <div>
                                <span className="float-right">
                                    <span className="mx-1 fsize12 colorred">{dateUtils.convertDateTimeFormat(this.props.item.actualStartTime || this.props.item.estStartTime)}</span>
                                    <span className={`badge badge-primary mx-1 mb-1 d-inline-block text-truncate mt-0 align-middle mx-w-100`} title={this.props.item.taskActCategory || 'Other'}>{this.props.item.taskActCategory || 'Other'}</span>
                                    <span title={this.props.item.ownerDetails && this.props.item.ownerDetails.name ? this.props.item.ownerDetails.name : 'Unknown'} className="avatar avatar-sm mx-1 avatar-cyan">{this.props.item.ownerDetails && this.props.item.ownerDetails.name ? this.props.item.ownerDetails.name.substring(0, 1) : "U"}</span>
                                    <span className={classNames("custom-rounded", { "colorgreen": this.props.item.status === 'COMPLETED' || this.props.item.status === 'CLOSED' }, { "colororange": this.props.item.status === 'INPROGRESS' }, { "colorblue": this.props.item.status === 'ASSIGNED' })}>{dateUtils.minuteToHourString((this.props.item.status === 'COMPLETED' || this.props.item.status === 'CLOSED') ? this.props.item.durationInMins : this.props.item.estDurationInMins) || "0h 0m"}</span>
                                    {this.props.permission.checklistEnabled === true && this.props.view !== "PAGE" && (this.props.item.taskType === 'PROJECT' || this.props.item.taskType === 'NON_PROJECT') &&
                                        <button type="button" className="link pl-2" title="Checklist"
                                            onClick={(e) => this.props.onCheklistItemClick(this.props.item)}
                                        ><i className={`fa fa-wpforms ${this.props.item.checklistStatus && this.props.item.checklistStatus === 'COMPLETED' ? 'colorgreen' : this.props.item.checklistStatus === 'INPROGRESS' ? 'colorblue' : ''}`} /></button>
                                    }
                                    {this.props.permission.editEnabled === true && this.props.view === "PAGE" &&
                                        <button type="button" className="link pl-2" title="Edit"
                                            onClick={(e) => this.props.handleEditWorkItem(this.props.item.id)}
                                        ><i className="fa fa-edit" /></button>
                                    }
                                    {(this.props.permission.deleteEnabled === true || this.isWorkItemDeleteByHoursEnabled()) &&
                                        <button type="button"
                                            onClick={(e) => this.props.handleDeleteWorkItem(this.props.item.id, !this.props.item.deleted)}
                                            className="link pl-2" title={this.props.item.deleted ? 'Undo Delete' : 'Delete'} data-type="confirm">
                                            {this.props.item.deleted &&
                                                <>
                                                    <i className="fe fe-rotate-ccw colorred blink_text"></i>
                                                </>
                                            }
                                            {!this.props.item.deleted &&
                                                <>
                                                    <i className="fe fe-trash"></i>
                                                </>
                                            }
                                        </button>
                                    }
                                    {this.props.permission.removeEnabled === true &&

                                        <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveWorkItem(this.state.confrimDelete)}>
                                            {this.state.confrimDelete === true &&
                                                <i className="fe fe-check colorred blink_text"></i>
                                            }
                                            {this.state.confrimDelete === false &&
                                                <i className="fe fe-x colorred"></i>
                                            }
                                        </button>
                                    }
                                </span>
                            </div>

                        </div>
                        {this.state.isActive &&
                            <div className="card">
                                <div className="card-status card-status-left bg-blue"></div>
                                {(this.props.permission.editCompletedWorkItemInfo || this.isCompletedHourEditByHoursEnabled()) &&
                                    <div className="card-header py-0 pr-2">
                                        {!this.state.editEnabled &&
                                            <span className="fsize12">{this.props.item.name}</span>
                                        }
                                        {this.state.editEnabled &&
                                            <input className="form-control px-1 py-0 state-valid"
                                                onChange={(e) => this.onChange("name", e.target.value)}
                                                type="text" value={this.state.workItemRequest.name} />
                                        }
                                        <div className="card-options">
                                            {!this.state.editEnabled &&
                                                <button className="link colorblue" onClick={() => this.toggelEditEnabled(true)}><i className="fa fa-pencil" /></button>
                                            }
                                            {this.state.editEnabled &&
                                                <>
                                                    <button className="link px-2 colorblue" onClick={() => { this.onSubmit() }}><i className="fa fa-save" /></button>
                                                    <button className="link fsize10 colorred" onClick={() => this.toggelEditEnabled(false)}>Cancel</button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="card-body py-0">
                                    <div className="overlaySection">
                                        <div className="row pt-1 text-left">
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Actual Start Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.actualStartTime) || 'NA'}
                                                </span>
                                            </div>

                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Recorded Start Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.recordedStartTime) || 'NA'}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Estimated Start Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.estStartTime) || 'NA'}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Actual End Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.actualEndTime) || 'NA'}
                                                </span>
                                            </div>

                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Recorded End Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.recordedEndTime) || 'NA'}
                                                </span>
                                            </div>

                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Last Updated Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.lastModifiedDate)}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Estimated Duration Hrs</label>
                                                {!(this.state.editEnabled && this.props.permission.editCompletedWorkItemEstimatedHours) && this.props.permission.viewWorkItemEstimatedHours &&
                                                    <span className="fsize12 fweight600 menu-text-black">
                                                        {dateUtils.minuteToHourString(this.props.item.estDurationInMins) || 'NA'}
                                                    </span>
                                                }

                                                {this.state.editEnabled && this.props.permission.editCompletedWorkItemEstimatedHours && this.props.permission.viewWorkItemEstimatedHours &&
                                                    <span className="timesheet-inputs d-flex">
                                                        <input className="form-control px-1 py-0 state-valid"
                                                            onChange={(e) => this.onTimeEntryValueChange("estDurationInHrs", e.target.value)}
                                                            type="number" placeholder="0h00m" min={0} max={this.props.permission.maxHoursAllowedToEnter}
                                                            value={this.state.workItemRequest.estDurationInHrs} />
                                                    </span>
                                                }
                                                {!this.props.permission.viewWorkItemEstimatedHours &&
                                                    <span className="timesheet-inputs d-flex">---</span>
                                                }
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Actual Duration Hrs</label>
                                                {!(completedHourEditEnabled) &&
                                                    <span className="fsize12 fweight600 menu-text-black">
                                                        {dateUtils.minuteToHourString(this.props.item.durationInMins) || 'NA'}
                                                    </span>
                                                }
                                                {completedHourEditEnabled &&
                                                    <span className="timesheet-inputs d-flex">
                                                        <input className="form-control px-1 py-0 state-valid"
                                                            onChange={(e) => this.onTimeEntryValueChange("durationInHrs", e.target.value)}
                                                            type="number" placeholder="0h00m" min={0} max={this.props.permission.maxHoursAllowedToEnter}
                                                            value={this.state.workItemRequest.durationInHrs} />
                                                    </span>
                                                }

                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Created Time</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {dateUtils.convertDateTimeFormat(this.props.item.createdDate) || 'NA'}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Created By</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {(this.props.item.createdByDetails && this.props.item.createdByDetails.name) || 'NA'}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Completed By</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {(this.props.item.completedByDetails && this.props.item.completedByDetails.name) || 'NA'}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">Assigned To</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {(this.props.item.ownerDetails && this.props.item.ownerDetails.name) || 'NA'}
                                                </span>
                                            </div>
                                            <div className="form-group col-4 mb-1">
                                                <label className="form-label mb-0 text-muted">User Team</label>
                                                <span className="fsize12 fweight600 menu-text-black">
                                                    {(this.props.item.ownerUserTeamDetails && this.props.item.ownerUserTeamDetails.name) || 'NA'}
                                                </span>
                                            </div>
                                            {this.props.item.taskType === 'PROJECT' &&
                                                <>
                                                    <div className="form-group col-4 mb-1">
                                                        <label className="form-label mb-0 text-muted">Quantity</label>


                                                        {!this.state.editEnabled && !completedHourEditEnabled &&
                                                            <span className="fsize12 fweight600 menu-text-black">
                                                                {(this.props.item.quantity) || 'NA'}
                                                            </span>
                                                        }
                                                        {completedHourEditEnabled &&
                                                            <span className="timesheet-inputs d-flex">
                                                                <input className="form-control px-1 py-0 state-valid"
                                                                    onChange={(e) => this.onChange("quantity", e.target.value)}
                                                                    value={this.state.workItemRequest.quantity || ''} />
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className="form-group col-4 mb-1">
                                                        <label className="form-label mb-0 text-muted">Skill</label>
                                                        <span className="fsize12 fweight600 menu-text-black">
                                                            {(this.props.item.skill) || 'NA'}
                                                        </span>
                                                    </div>
                                                </>
                                            }
                                            {this.props.item.taskType === 'NON_PROJECT' &&
                                                <>
                                                    <div className="form-group col-4 mb-1">
                                                        <label className="form-label mb-0 text-muted">Account</label>
                                                        <span className="fsize12 fweight600 menu-text-black">
                                                            {(this.props.item.account) || 'NA'}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-4 mb-1">
                                                        <label className="form-label mb-0 text-muted">Division</label>
                                                        <span className="fsize12 fweight600 menu-text-black">
                                                            {(this.props.item.division) || 'NA'}
                                                        </span>
                                                    </div>

                                                    <div className="form-group col-4 mb-1">
                                                        <label className="form-label mb-0 text-muted">Client Partner</label>
                                                        <span className="fsize12 fweight600 menu-text-black">
                                                            {(this.props.item.clientPartner) || 'NA'}
                                                        </span>
                                                    </div>

                                                    <div className="form-group col-4 mb-1">
                                                        <label className="form-label mb-0 text-muted">Team Name</label>
                                                        <span className="fsize12 fweight600 menu-text-black">
                                                            {(this.props.item.teamName) || 'NA'}
                                                        </span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    removeWorkItem: workItemActions.removeWorkItem,
    deleteWorkItem: workItemActions.deleteWorkItem,
    updateWorkItem: workItemActions.updateWorkItem
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkItemActiveItems);


