import { accountService } from '_services';
import { projectConstants } from '../_constants';
import { appConfigActions } from './appconfig.actions';
import { employeeActions } from './employee.actions';
import { notificationActions } from './notification.actions';

export const accountActions = {
    initializeCreateAccounts,
    getProjectTeamAccounts,
    getProjectAllAccounts,
    getChildAccountsByIdAndTypeAndExcludeUserTeam, // to show in the accoutn add menu
    createAccountItem,
    updateAccountItem,
    removeAccountItem,
    detachFromAccountItem,
    addToAccountItem
};

function initializeCreateAccounts(userTeamId, parentId, currentViewName) {
    return dispatch => {
        dispatch(employeeActions.getAllEmployees());
        dispatch(appConfigActions.getAllTeams(true));
        dispatch(getChildAccountsByIdAndTypeAndExcludeUserTeam(userTeamId, parentId, currentViewName && currentViewName, true, false));
    };
}


function getProjectTeamAccounts(userTeamId) {
    return dispatch => {
        dispatch(request());
        accountService.getProjectTeamAccounts({userTeamIds: [userTeamId]})
            .then(
                accounts => dispatch(success(accounts.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GET_ALL_PROJECT_TEAM_ACCOUNTS_REQUEST } }
    function success(accounts) { return { type: projectConstants.GET_ALL_PROJECT_TEAM_ACCOUNTS_SUCCESS, accounts } }
    function failure(error) { return { type: projectConstants.GET_ALL_PROJECT_TEAM_ACCOUNTS_FAILURE, error } }
}

function getProjectAllAccounts() {
    return dispatch => {
        dispatch(request());
        accountService.getProjectAllAccounts()
            .then(
                accounts => dispatch(success(accounts.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GET_ALL_PROJECT_ACCOUNTS_REQUEST } }
    function success(accounts) { return { type: projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUCCESS, accounts } }
    function failure(error) { return { type: projectConstants.GET_ALL_PROJECT_ACCOUNTS_FAILURE, error } }
}

function getChildAccountsByIdAndTypeAndExcludeUserTeam(excludeUserTeamId, parentId, currentViewName, isExcludeUserTeams, isOnlyForUserTeams){

    return dispatch => {
        dispatch(request());
        accountService.getChildAccountsByIdAndTypeAndExcludeUserTeam({userTeamIds: [excludeUserTeamId], parentId, type : currentViewName, isExcludeUserTeams, isOnlyForUserTeams})
            .then(
                subAccountItems => dispatch(success(subAccountItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUB_REQUEST } }
    function success(subAccountItems) { return { type: projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUB_SUCCESS, subAccountItems } }
    function failure(error) { return { type: projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUB_FAILURE, error } }
}

 function createAccountItem(accountRequest,  createAnotherItemEnabled, callbackFunction) {
    return dispatch => {
        dispatch(request());
        accountService.createAccountItem(accountRequest)
            .then(
                accountItem => {
                    dispatch(success(accountItem.payload, createAnotherItemEnabled));
                    dispatch(notificationActions.success('Account Creation successful'));
                    callbackFunction();
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.CREATE_ACCOUNT_ITEM_REQUEST } }
    function success(accountItem, createAnotherItemEnabled) { return { type: projectConstants.CREATE_ACCOUNT_ITEM_SUCCESS, accountItem , createAnotherItemEnabled} }
    function failure(error) { return { type: projectConstants.CREATE_ACCOUNT_ITEM_FAILURE, error } }
 }
 
function updateAccountItem(accountRequest) {
    return dispatch => {
        dispatch(request());
        accountService.updateAccountItem(accountRequest)
            .then(
                accountItem => dispatch(success(accountItem.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.UPDATE_ACCOUNT_ITEM_REQUEST } }
    function success(accountItem) { return { type: projectConstants.UPDATE_ACCOUNT_ITEM_SUCCESS, accountItem } }
    function failure(error) { return { type: projectConstants.UPDATE_ACCOUNT_ITEM_FAILURE, error } }

 }
 
function removeAccountItem(itemId) {
    return dispatch => {
        dispatch(request());
        accountService.removeAccountItem(itemId)
            .then(
                response => dispatch(success(itemId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.REMOVE_ACCOUNT_ITEM_REQUEST } }
    function success(itemId) { return { type: projectConstants.REMOVE_ACCOUNT_ITEM_SUCCESS , itemId} }
    function failure(error) { return { type: projectConstants.REMOVE_ACCOUNT_ITEM_FAILURE, error } }
 }
 
 function detachFromAccountItem(itemId, userTeamId, parentItemId) {
    return dispatch => {
        dispatch(request());
        accountService.detachFromAccountItem(itemId, userTeamId, parentItemId)
            .then(
                accountItem => dispatch(success(accountItem.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.DETACH_ACCOUNT_ITEM_REQUEST } }
    function success(accountItem) { return { type: projectConstants.DETACH_ACCOUNT_ITEM_SUCCESS , accountItem} }
    function failure(error) { return { type: projectConstants.DETACH_ACCOUNT_ITEM_FAILURE, error } }
 }
 
 function addToAccountItem(itemId, userTeamId, parentItemId, createAnotherItemEnabled, callbackFunction) {
    return dispatch => {
        dispatch(request());
        accountService.addToAccountItem(itemId, userTeamId, parentItemId)
            .then(
                accountItem => {
                    dispatch(success(accountItem.payload, createAnotherItemEnabled));
                    dispatch(notificationActions.success('Account Linking successful'));
                    callbackFunction();
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.ADD_TO_ACCOUNT_ITEM_REQUEST } }
    function success(accountItem, createAnotherItemEnabled) { return { type: projectConstants.ADD_TO_ACCOUNT_ITEM_SUCCESS , accountItem, createAnotherItemEnabled} }
    function failure(error) { return { type: projectConstants.ADD_TO_ACCOUNT_ITEM_FAILURE, error } }
 }
 
