import React, { Component } from "react";

class ProjectDetailsMembers extends Component {
    render() {
        return (
            <div className="card h-100 mb-0">
            <div className="card-header">
              <h3 className="card-title">Members</h3>
            </div>
            <div className="card-body px-3">
              <form>
                <div className="input-group m-b-20">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><i className="icon-magnifier" /></span>
                  </div>
                  <input type="text" className="form-control" placeholder="Search..." />
                </div>
              </form>
              <ul className="right_chat list-unstyled d-none">
                <li className="online"> 
                  <button className="link">
                    <div className="media"> 
                      <img className="media-object " src="/assets/images/xs/avatar4.jpg" alt="" />
                      <div className="media-body">
                        <span className="name">Donald Gardner</span>
                        <span className="message">Designer, Blogger</span>
                        <span className="badge badge-outline status" />
                      </div>
                      <i className="fa fa-list pt-3 pr-2" data-toggle="tooltip" title="" data-original-title=""><span className="fsize15 fweight600"> 1/3</span></i>
                    </div>
                  </button>                            
                </li>
                <li className="online">
                  <button className="link">
                    <div className="media">
                      <img className="media-object " src="/assets/images/xs/avatar5.jpg" alt="" />
                      <div className="media-body">
                        <span className="name">Wendy Keen</span>
                        <span className="message">Java Developer</span>
                        <span className="badge badge-outline status" />
                      </div>
                    </div>
                  </button>                            
                </li>
                <li className="offline">
                  <button className="link">
                    <div className="media">
                      <img className="media-object " src="/assets/images/xs/avatar2.jpg" alt="" />
                      <div className="media-body">
                        <span className="name">Matt Rosales</span>
                        <span className="message">CEO, Epic Theme</span>
                        <span className="badge badge-outline status" />
                      </div>
                    </div>
                  </button>                            
                </li>
                <li className="offline">
                  <button className="link">
                    <div className="media">
                      <img className="media-object " src="/assets/images/xs/avatar1.jpg" alt="" />
                      <div className="media-body">
                        <span className="name">Nancy Flanary</span>
                        <span className="message">Art director, Movie Cut</span>
                        <span className="badge badge-outline status" />
                      </div>
                    </div>
                  </button>                            
                </li>
                <li className="online">
                  <button className="link">
                    <div className="media">
                      <img className="media-object " src="/assets/images/xs/avatar3.jpg" alt="" />
                      <div className="media-body">
                        <span className="name">Phillip Smith</span>
                        <span className="message">Writter, Mag Editor</span>
                        <span className="badge badge-outline status" />
                      </div>
                    </div>
                  </button>                            
                </li>                        
              </ul>
            </div>
          </div>
        );
    }
}

export default ProjectDetailsMembers;
