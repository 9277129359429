import React, { Component } from "react";

class FileManagerPage extends Component {
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
  }

  render() {
    return (
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Recently Accessed Files</h3>
                  <div className="card-options">
                    <button className="link"><i className="fa fa-plus" data-toggle="tooltip" data-placement="right"  data-original-title="Upload New" /></button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="file_folder">
                    <button className="link">
                      <div className="icon">
                        <i className="fa fa-folder text-success" />
                      </div>
                      <div className="file-name">
                        <p className="mb-0 text-muted">Projects</p>
                        <small>3 File, 1.2GB</small>
                      </div>
                    </button>
                    <button className="link">
                      <div className="icon">
                        <i className="fa fa-file-word-o text-primary" />
                      </div>
                      <div className="file-name">
                        <p className="mb-0 text-muted">Report2017.doc</p>
                        <small>Size: 68KB</small>
                      </div>
                    </button>
                    <button className="link">
                      <div className="icon">
                        <i className="fa fa-file-pdf-o text-danger" />
                      </div>
                      <div className="file-name">
                        <p className="mb-0 text-muted">Report2017.pdf</p>
                        <small>Size: 68KB</small>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card bg-none b-none">
                <div className="card-body pt-0">
                  <div className="table-responsive">
                    <table className="table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th />
                          <th>Name</th>
                          <th>Share With</th>
                          <th>Owner</th>
                          <th>Last Update</th>
                          <th>File Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-folder" />
                          </td>
                          <td>
                            <span className="folder-name">Work</span>
                          </td>
                          <td>
                            <div className="avatar-list avatar-list-stacked">
                              <img className="avatar avatar-sm" src=" /assets/images/male.jpg" data-toggle="tooltip" data-original-title="Avatar" alt="avatar"/>
                              <img className="avatar avatar-sm" src=" /assets/images/female.png" data-toggle="tooltip" data-original-title="Avatar" aria-describedby="tooltip773075" alt="avatar"/>
                              <img className="avatar avatar-sm" src=" /assets/images/male.jpg" data-toggle="tooltip" data-original-title="Avatar" alt="avatar"/>
                            </div>
                          </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>Oct 7, 2021</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> - </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-folder" />
                          </td>
                          <td>
                            <span className="folder-name">Projects</span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>Oct 17, 2021</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> - </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-folder text-danger" />
                          </td>
                          <td>
                            <span className="folder-name">Holidays</span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>John</span>
                          </td>
                          <td className="width100">
                            <span>Oct 18, 2021</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> 50MB </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-folder" />
                          </td>
                          <td>
                            <span className="folder-name">Mobile Work </span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>April 7, 2022</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> 1GB </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-folder" />
                          </td>
                          <td>
                            <span className="folder-name">Photoshop Data</span>
                          </td>
                          <td>
                            <div className="avatar-list avatar-list-stacked">
                              <img className="avatar avatar-sm" src=" /assets/images/male.jpg" data-toggle="tooltip" data-original-title="Employee" alt="avatar"/>
                              <img className="avatar avatar-sm" src=" /assets/images/female.png" data-toggle="tooltip" data-original-title="Employee" alt="avatar"/>
                            </div>
                          </td>
                          <td className="width100">
                            <span>Andrew</span>
                          </td>
                          <td className="width100">
                            <span>Oct 23, 2022</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> - </span>
                          </td>
                        </tr>
                      
                        <tr>
                          <td className="width45">
                            <i className="fa fa-file-excel-o text-success" />
                          </td>
                          <td>
                            <span className="folder-name">new timesheet.xlsx</span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>Dec 5, 2021</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> 52KB </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-file-word-o text-warning" />
                          </td>
                          <td>
                            <span className="folder-name">new project.doc</span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>May 5, 2022</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> 152KB </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-file-pdf-o text-warning" />
                          </td>
                          <td>
                            <span className="folder-name">report.pdf</span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>May 2, 2022</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> 841KB </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="width45">
                            <i className="fa fa-file-pdf-o text-warning" />
                          </td>
                          <td>
                            <span className="folder-name">report-2021.pdf</span>
                          </td>
                          <td>
                            -
                    </td>
                          <td className="width100">
                            <span>Me</span>
                          </td>
                          <td className="width100">
                            <span>Oct 16, 2021</span>
                          </td>
                          <td className="width100 text-center">
                            <span className="size"> 541KB </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default FileManagerPage;
