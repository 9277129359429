import { appConfigConstants } from '../_constants';
import { appConfigService } from '../_services';
import { notificationActions } from './';
export const appConfigActions = {
    initializeConfig,
    createNewConfig,
    deleteUserTeamItem,
    removeUserTeamItem,
    updateConfig,
    getAppConfig,
    configMenuChange,
    updateConfigProperty,
    getAllRoles,
    getAllTeams
}

function configMenuChange(configId, configType) {
    return dispatch => {
        dispatch(request(configId, configType));
    };
    function request(configId, configType) { return { type: appConfigConstants.CONFIG_MENU_CHANGE, configId, configType } }
}

function updateConfigProperty(configId, propertyName, propertyValue) {
    return dispatch => {
        appConfigService.updateConfigProperty(configId, propertyName, propertyValue)
            .then(
                appConfig => {
                    dispatch(success(appConfig.payload));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function success(appConfig) { return { type: appConfigConstants.CONFIG_PROPERTY_UPDATE_SUCCESS, appConfig } }
    function failure(error) { return { type: appConfigConstants.CONFIG_PROPERTY_UPDATE_FAILURE, error } }
}
function initializeConfig(configId) {
    return dispatch => {
        dispatch(request());
        appConfigService.initializeConfig(configId)
            .then(
                appConfig => {
                    dispatch(success(appConfig.payload));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request() { return { type: appConfigConstants.INIALIZE_CONFIG_REQUEST } }
    function success(appConfig) { return { type: appConfigConstants.INIALIZE_CONFIG_SUCCESS, appConfig } }
    function failure(error) { return { type: appConfigConstants.INIALIZE_CONFIG_FAILURE, error } }

}

function createNewConfig(type, name, parentId) {
    return dispatch => {
        dispatch(request());
        //console.log("createNewConfig");
        appConfigService.createNewConfig(type, name, parentId)
            .then(
                appConfig => {
                    dispatch(success(appConfig.payload));
                    dispatch(notificationActions.success('Configuration Created successfully'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: appConfigConstants.CREATE_CONFIG_REQUEST } }
    function success(appConfig) { return { type: appConfigConstants.CREATE_CONFIG_SUCCESS, appConfig } }
    function failure(error) { return { type: appConfigConstants.CREATE_CONFIG_FAILURE, error } }
}

function updateConfig(configRequest, configId) {
    return dispatch => {
        if (configRequest["name"] === undefined) {
            dispatch(resetRequest());
        }
        dispatch(request());
        appConfigService.updateConfig(configRequest, configId)
            .then(
                appConfig => {
                    dispatch(success(appConfig.payload));
                    dispatch(notificationActions.success('Configuration Updated successfully'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };
    function resetRequest() { return { type: appConfigConstants.RESET_CONFIG_REQUEST} }
    function request() { return { type: appConfigConstants.UPDATE_CONFIG_REQUEST} }
    function success(appConfig) { return { type: appConfigConstants.UPDATE_CONFIG_SUCCESS, appConfig } }
    function failure(error) { return { type: appConfigConstants.UPDATE_CONFIG_FAILURE, error } }
}


function removeUserTeamItem(itemId) {
    return dispatch => {
        dispatch(request());
        appConfigService.removeUserTeamItem(itemId)
            .then(
                response => dispatch(success(itemId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: appConfigConstants.REMOVE_CONFIG_ITEM_REQUEST } }
    function success(itemId) { return { type: appConfigConstants.REMOVE_CONFIG_ITEM_SUCCESS, itemId } }
    function failure(error) { return { type: appConfigConstants.REMOVE_CONFIG_ITEM_FAILURE, error } }
}


function deleteUserTeamItem(itemId) {
    return dispatch => {
        dispatch(request());
        appConfigService.deleteUserTeamItem(itemId, true)
            .then(
                response => dispatch(success(itemId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: appConfigConstants.DELETE_CONFIG_ITEM_REQUEST } }
    function success(itemId) { return { type: appConfigConstants.DELETE_CONFIG_ITEM_SUCCESS, itemId } }
    function failure(error) { return { type: appConfigConstants.DELETE_CONFIG_ITEM_FAILURE, error } }
}

function getAppConfig(configId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getAppConfig(configId, false)
            .then(
                appConfig => {
                    dispatch(success(appConfig.payload));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: appConfigConstants.GET_CONFIG_REQUEST } }
    function success(appConfig) { return { type: appConfigConstants.GET_CONFIG_SUCCESS, appConfig } }
    function failure(error) { return { type: appConfigConstants.GET_CONFIG_FAILURE, error } }
}

function getAllTeams(isGroupByEnabled) {
    return dispatch => {
        dispatch(isGroupByEnabled ? requestByGroup() : request());
        appConfigService.getAllTeams(isGroupByEnabled).then(
            appConfig => {
                dispatch(isGroupByEnabled ? successByGroup(appConfig.payload) : success(appConfig.payload));
            },
            error => {
                dispatch(isGroupByEnabled ? failureByGroup(error.toString()) : failure(error.toString()));
            }
        );
        //console.log("getAllTeams");
    };
    function request() { return { type: appConfigConstants.INIALIZE_ALL_TEAMS_REQUEST, configuration: {} } }
    function success(teams) { return { type: appConfigConstants.INIALIZE_ALL_TEAMS_SUCCESS, teams: teams } }
    function failure(error) { return { type: appConfigConstants.INIALIZE_ALL_TEAMS_FAILURE, error } }

    function requestByGroup() { return { type: appConfigConstants.INIALIZE_ALL_TEAMS_GROUP_REQUEST, configuration: {} } }
    function successByGroup(teams) { return { type: appConfigConstants.INIALIZE_ALL_TEAMS_GROUP_SUCCESS, teams: teams } }
    function failureByGroup(error) { return { type: appConfigConstants.INIALIZE_ALL_TEAMS_GROUP_FAILURE, error } }
}

function getAllRoles(isGroupByEnabled) {
    return dispatch => {
        dispatch(isGroupByEnabled ? requestByGroup() : request());
        appConfigService.getAllRoles(isGroupByEnabled).then(
            appConfig => {
                dispatch(isGroupByEnabled ? successByGroup([appConfig.payload]) : success(appConfig.payload));
            },
            error => {
                dispatch(isGroupByEnabled ? failureByGroup(error.toString()) : failure(error.toString()));
            }
        );
    };
    function request() { return { type: appConfigConstants.INIALIZE_ALL_ROLES_REQUEST, configuration: {} } }
    function success(roles) { return { type: appConfigConstants.INIALIZE_ALL_ROLES_SUCCESS, roles: roles } }
    function failure(error) { return { type: appConfigConstants.INIALIZE_ALL_ROLES_FAILURE, error } }

    function requestByGroup() { return { type: appConfigConstants.INIALIZE_ALL_ROLES_GROUP_REQUEST, configuration: {} } }
    function successByGroup(roles) { return { type: appConfigConstants.INIALIZE_ALL_ROLES_GROUP_SUCCESS, roles: roles } }
    function failureByGroup(error) { return { type: appConfigConstants.INIALIZE_ALL_ROLES_GROUP_FAILURE, error } }

}
