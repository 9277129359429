export const userConstants = {

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    INITATE_ACCOUNT_ACTIVATION_REQUEST:'INITATE_ACCOUNT_ACTIVATION_REQUEST',
    INITATE_ACCOUNT_ACTIVATION_SUCCESS:'INITATE_ACCOUNT_ACTIVATION_SUCCESS',
    INITATE_ACCOUNT_ACTIVATION_FAILURE:'INITATE_ACCOUNT_ACTIVATION_FAILURE',

    VALIDATE_ACCOUNT_ACTIVATION_REQUEST:'VALIDATE_ACCOUNT_ACTIVATION_REQUEST',
    VALIDATE_ACCOUNT_ACTIVATION_SUCCESS:'VALIDATE_ACCOUNT_ACTIVATION_SUCCESS',
    VALIDATE_ACCOUNT_ACTIVATION_FAILURE:'VALIDATE_ACCOUNT_ACTIVATION_FAILURE',

    CHANGE_PASSWORD_REQUEST:'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS:'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE:'CHANGE_PASSWORD_FAILURE',

    UPDATE_PASSWORD_REQUEST:'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS:'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE:'UPDATE_PASSWORD_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',
};
