import React, { Component } from "react";
import CountUp from 'react-countup';
import { store } from "_helpers";
import { commonUtils } from "_helpers";

class ProjectServiceLineView extends Component {

    getCost = (selectedServiceLine) => {
        return (parseFloat((this.props.item.serviceLineEstimate[selectedServiceLine] && this.props.item.serviceLineEstimate[selectedServiceLine].estHourlyRate) || 0) * parseInt((this.props.item.serviceLineEstimate[selectedServiceLine] && this.props.item.serviceLineEstimate[selectedServiceLine].estHours) || 0)).toFixed(2);
    }

    getTotal = (type) => {
        let total = 0;
        this.props.item.serviceLineEstimate && Object.keys(this.props.item.serviceLineEstimate).map(selectedServiceLine => {
             let tempTotal = (this.props.item.serviceLineEstimate[selectedServiceLine][type] || 0)
             total += parseFloat(tempTotal);
        })
        return Number(total).toFixed(2);
    }

    getTotalCost = () => {
        let total = 0;
        this.props.item.serviceLineEstimate && Object.keys(this.props.item.serviceLineEstimate).map(selectedServiceLine => {
            let tempTotal = this.getCost(selectedServiceLine);
            total += parseFloat(tempTotal);
        })
        return Number(total).toFixed(2);
    }
    render() {
        return (
            <>
                {this.props.item.serviceLineEstimate && Object.keys(this.props.item.serviceLineEstimate).length > 0 &&
                    <div className="row bgcolorlightgray">
                        <div className="row bgcolorgray col-12 py-1 px-0 mx-0 fweight600">
                            <div className="col-4 fsize11">Service Line</div>
                            <div className="col-3 fsize11">Est Hours</div>
                            <div className="col-3 fsize11">Est Hourly Rate ($)</div>
                            <div className="col-2 fsize11">Est Cost</div>
                        </div>
                        {Object.keys(this.props.item.serviceLineEstimate).map(selectedServiceLine =>
                            <div className="row col-12 ml-1" key={selectedServiceLine}>
                                <div className="col-4">
                                    <span className="d-block mt-1 fsize11">{selectedServiceLine}</span>
                                </div>
                                <div className="col-3">
                                    {this.props.permission.enableServiceLineEstimateHourVisible &&
                                        <>
                                            {this.props.item.serviceLineEstimate[selectedServiceLine].estHours || 0}
                                        </>
                                    }
                                    {!this.props.permission.enableServiceLineEstimateHourVisible &&
                                        <span>---</span>
                                    }
                                </div>
                                <div className="col-3">
                                    {this.props.permission.enableServiceLineEstimateCostVisible &&
                                        <>
                                            {this.props.item.serviceLineEstimate[selectedServiceLine].estHourlyRate || 0}
                                        </>
                                    }
                                    {!this.props.permission.enableServiceLineEstimateCostVisible &&
                                        <span>$xxx</span>
                                    }
                                </div>
                                <div className="col-2">
                                    {this.props.permission.enableServiceLineEstimateCostVisible &&
                                        <>
                                            ${this.getCost(selectedServiceLine)}
                                        </>
                                    }
                                    {!this.props.permission.enableServiceLineEstimateCostVisible &&
                                        <span>$xxx</span>
                                    }
                                </div>
                            </div>
                        )

                        }
                        <div className="row col-12 ml-1 bgcolorlightblue_1 fweight600 py-0 fsize13 mt-1">
                            <div className="col-4">Total</div>
                            <div className="col-3">{this.props.permission.enableServiceLineEstimateHourVisible ? this.getTotal("estHours") : "---"}</div>
                            <div className="col-3">{this.props.permission.enableServiceLineEstimateCostVisible ? this.getTotal("estHourlyRate") : "$xxx"}</div>
                            <div className="col-2">{this.props.permission.enableServiceLineEstimateCostVisible ? this.getTotalCost() : "$xxx"}</div>
                        </div>
                    </div>
                }</>
        )
    }
}
export default ProjectServiceLineView;
