export const appConfigConstants = {
    INIALIZE_CONFIG_REQUEST : "INIALIZE_CONFIG_REQUEST",
    INIALIZE_CONFIG_SUCCESS : "INIALIZE_CONFIG_SUCCESS",
    INIALIZE_CONFIG_FAILURE : "INIALIZE_CONFIG_FAILURE",
    CREATE_CONFIG_REQUEST : "CREATE_CONFIG_REQUEST",
    CREATE_CONFIG_SUCCESS : "CREATE_CONFIG_SUCCESS",
    CREATE_CONFIG_FAILURE : "CREATE_CONFIG_FAILURE",
    RESET_CONFIG_REQUEST : "RESET_CONFIG_REQUEST",
    UPDATE_CONFIG_REQUEST : "UPDATE_CONFIG_REQUEST",
    UPDATE_CONFIG_SUCCESS : "UPDATE_CONFIG_SUCCESS", 
    UPDATE_CONFIG_FAILURE : "UPDATE_CONFIG_FAILURE",

    DELETE_CONFIG_ITEM_REQUEST :"DELETE_CONFIG_ITEM_REQUEST",
    DELETE_CONFIG_ITEM_SUCCESS :"DELETE_CONFIG_ITEM_SUCCESS",
    DELETE_CONFIG_ITEM_FAILURE : "DELETE_CONFIG_ITEM_FAILURE",

    REMOVE_CONFIG_ITEM_REQUEST :"REMOVE_CONFIG_ITEM_REQUEST",
    REMOVE_CONFIG_ITEM_SUCCESS :"REMOVE_CONFIG_ITEM_SUCCESS",
    REMOVE_CONFIG_ITEM_FAILURE :"REMOVE_CONFIG_ITEM_FAILURE",

    GET_CONFIG_REQUEST : "GET_CONFIG_REQUEST",
    GET_CONFIG_SUCCESS: "GET_CONFIG_SUCCESS",
    GET_CONFIG_FAILURE : "GET_CONFIG_FAILURE",
    CONFIG_MENU_CHANGE : "CONFIG_MENU_CHANGE",
    CONFIG_PROPERTY_UPDATE_SUCCESS : "CONFIG_PROPERTY_UPDATE_SUCCESS",
    CONFIG_PROPERTY_UPDATE_FAILURE : "CONFIG_PROPERTY_UPDATE_SUCCESS",

    INIALIZE_ALL_ROLES_REQUEST : "INIALIZE_ALL_ROLES_REQUEST",
    INIALIZE_ALL_ROLES_SUCCESS:"INIALIZE_ALL_ROLES_SUCCESS",
    INIALIZE_ALL_ROLES_FAILURE:"INIALIZE_ALL_ROLES_FAILURE",

    INIALIZE_ALL_ROLES_GROUP_REQUEST : "INIALIZE_ALL_ROLES_GROUP_REQUEST",
    INIALIZE_ALL_ROLES_GROUP_SUCCESS:"INIALIZE_ALL_ROLES_GROUP_SUCCESS",
    INIALIZE_ALL_ROLES_GROUP_FAILURE:"INIALIZE_ALL_ROLES_GROUP_FAILURE",
    
    INIALIZE_ALL_TEAMS_REQUEST : "INIALIZE_ALL_TEAMS_REQUEST",
    INIALIZE_ALL_TEAMS_SUCCESS:"INIALIZE_ALL_TEAMS_SUCCESS",
    INIALIZE_ALL_TEAMS_FAILURE:"INIALIZE_ALL_TEAMS_FAILURE",

    INIALIZE_ALL_TEAMS_GROUP_REQUEST: "INIALIZE_ALL_TEAMS_GROUP_REQUEST",
    INIALIZE_ALL_TEAMS_GROUP_SUCCESS : "INIALIZE_ALL_TEAMS_GROUP_SUCCESS",
    INIALIZE_ALL_TEAMS_GROUP_FAILURE : "INIALIZE_ALL_TEAMS_GROUP_FAILURE",

    SHOW_SETTINGS_WINDOW: "SHOW_SETTINGS_WINDOW",
    CLOSE_SETTINGS_WINDOW: "CLOSE_SETTINGS_WINDOW"
    
};
