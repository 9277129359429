import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EmployeeInfoGrid from "./EmployeeInfoGrid";

class EmployeeListGrids extends Component {
  render() {
    return (
      <div className="row clearfix gridsection">
        {this.props.isLoading &&
          <>
            <div className="col-lg-3 col-md-6">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={208} />
              </SkeletonTheme>
            </div>
            <div className="col-lg-3 col-md-6">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={208} />
              </SkeletonTheme>
            </div>
            <div className="col-lg-3 col-md-6">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={208} />
              </SkeletonTheme>
            </div>
            <div className="col-lg-3 col-md-6">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={208} />
              </SkeletonTheme>
            </div>
          </>
        }
        {this.props.employees && this.props.employees.map((employee, index) =>
          <EmployeeInfoGrid key={employee.id} employee={employee}
          handleDeleteEmployee={this.props.handleDeleteEmployee}
            handleEditEmployee={this.props.handleEditEmployee} 
            handleActivateEmployee={this.props.handleActivateEmployee} 
            handleRemoveEmployee={this.props.handleRemoveEmployee} 
            permission={this.props.permission}
            />
        )}
      </div>
    );
  }
}
export default EmployeeListGrids;