import React, { Component } from "react";
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import Barchart from "views/common/plugins/charts/barchart";
import { commonUtils } from "_helpers";
import Columnchart from "views/common/plugins/charts/columnchart";

class ProjectDashboardPage extends Component {

	componentDidMount() {
		this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
	}
	render() {
		return (
			<div className="projectdashboard">
				<div className={`section-body mt-1`}>
					<div className="container-fluid">
						<div className="row clearfix pb-2">
							<div className="col-lg-7">
								<div className={`section-body mb-2 pb-1"}`}>
									<span className="d-block fsize18">Welcome <span className="fweight600">{commonUtils.getCurrentUser().name}</span></span>
									<span className="fsize15">
										{commonUtils.getCurrentUser().defaultUserTeamDetails.name} / {commonUtils.getCurrentUser().designation}
									</span>
								</div>
							</div>
							<div className="col-lg-5 d-block">

								<div className="mt-0 float-right">
									<span className="d-block mb-1 fsize15">{commonUtils.getCurrentUser().defaultUserTeamDetails.name}</span>
									<span className="">
										<button className="btn btn-sm btn-outline-secondary mr-1 active" id="one_month">
											1M
										</button>
										<button className="btn btn-sm btn-outline-secondary mr-1" id="six_months">
											6M
										</button>
										<button className="btn btn-sm btn-outline-secondary mr-1" id="one_year">
											1Y
										</button>
										<button className="btn btn-sm btn-outline-secondary mr-1" id="ytd">
											YTD
										</button>
										<button className="btn btn-sm btn-outline-secondary" id="all">
											ALL
										</button>
									</span>
								</div>

							</div>
						</div>
						<div className="row clearfix">
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">

									<div className="card-body ribbon">
										<div className="ribbon-box green">5</div>
										<Link to="/project/taskboard" className="my_sort_cut text-muted">
											<i className="icon-list" />
											<span>TaskBoard</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body ribbon">
										<div className="ribbon-box pink">8</div>
										<Link to="/project/todolist" className="my_sort_cut text-muted">
											<i className="icon-pin" />
											<span>Todo</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/project/activities" className="my_sort_cut text-muted">
											<i className="icon-layers" />
											<span>Work Activities</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body ribbon">
										<div className="ribbon-box orange">8</div>
										<Link to="/project/list" className="my_sort_cut text-muted">
											<i className="icon-note" />
											<span>New Project</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/project/accounts" className="my_sort_cut text-muted">
											<i className="icon-calculator" />
											<span>Accounts</span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-4 col-xl-2">
								<div className="card">
									<div className="card-body">
										<Link to="/project/reports" className="my_sort_cut text-muted">
											<i className="icon-pie-chart" />
											<span>Report</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="row clearfix row-deck">
							<div className="col-xl-4 col-lg-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Project Statistics</h3>

									</div>
									<div className="card-body">
										<div className="row text-center">
											<div className="col-sm-4 border-right pb-1 pt-1">
												<label className="mb-0">Total <span className="fweight600">Project</span></label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={42} /></h4>
											</div>
											<div className="col-sm-4 border-right pb-1 pt-1">
												<label className="mb-0">On Going</label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={23} /></h4>
											</div>
											<div className="col-sm-4 pb-1 pt-1">
												<label className="mb-0">Pending</label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={8} /></h4>
											</div>
										</div>
										<div className="row text-center">
											<div className="col-sm-4 border-right pb-1 pt-1 px-0">
												<label className="mb-0">Total <span className="fweight600">Hours</span></label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={42} /></h4>
											</div>
											<div className="col-sm-4 border-right pb-1 pt-1 px-0">
												<label className="mb-0">Non Billable</label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={23} /></h4>
											</div>
											<div className="col-sm-4 pb-1 pt-1 px-0">
												<label className="mb-0">Billable</label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={8} /></h4>
											</div>
										</div>
										<div className="row text-center">
											<div className="col-sm-4 border-right pb-1 pt-1 px-0">
												<label className="mb-0">Total  <span className="fweight600">Deliverables</span></label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={42} /></h4>
											</div>
											<div className="col-sm-4 border-right pb-1 pt-1 px-0">
												<label className="mb-0">On Going</label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={23} /></h4>
											</div>
											<div className="col-sm-4 pb-1 pt-1 px-0">
												<label className="mb-0">Pending</label>
												<h4 className="font-30 font-weight-bold text-col-blue counter"><CountUp end={8} /></h4>
											</div>
										</div>
									</div>
									<div className="table-responsive">
										<table className="table table-striped table-vcenter mb-0">
											<tbody>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>35%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Design Team</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-azure"
																role="progressbar"
																style={{ width: '35%' }}
																aria-valuenow={42}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>25%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Developer Team</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-green"
																role="progressbar"
																style={{ width: '25%' }}
																aria-valuenow={0}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>15%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Marketing</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-orange"
																role="progressbar"
																style={{ width: '15%' }}
																aria-valuenow={36}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>

												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>20%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Management</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-indigo"
																role="progressbar"
																style={{ width: '20%' }}
																aria-valuenow={6}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<div className="clearfix">
															<div className="float-left">
																<strong>11%</strong>
															</div>
															<div className="float-right">
																<small className="text-muted">Other</small>
															</div>
														</div>
														<div className="progress progress-xs">
															<div
																className="progress-bar bg-pink"
																role="progressbar"
																style={{ width: '11%' }}
																aria-valuenow={6}
																aria-valuemin={0}
																aria-valuemax={100}
															/>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="col-xl-8 col-lg-12">
								<div className="card">
									<div className="card-header">
										<h2 className="card-title">Tasks activity</h2>
										<div className="card-options text-muted">
											<select className="custom-select custom-select-sm ml-2">
												<option>This week</option>
												<option>Last week</option>
											</select>
										</div>
									</div>
									<div className="card-body">
										<Columnchart></Columnchart>
									</div>
									<div className="card-tabs card-tabs-bottom">
										<a href="fake_url" className="card-tabs-item text-center active">
											<h3 className="mb-1">120</h3>
											<div className="text-muted-dark">Tasks completed</div>
										</a>
										<a href="fake_url" className="card-tabs-item text-center">
											<h3 className="mb-1">25</h3>
											<div className="text-muted-dark">Project Hours logged</div>
										</a>
										<a href="fake_url" className="card-tabs-item text-center">
											<h3 className="mb-1">2</h3>
											<div className="text-muted-dark">Non Billable hours Logged</div>
										</a>
										<a href="fake_url" className="card-tabs-item text-center">
											<h3 className="mb-1">2</h3>
											<div className="text-muted-dark">Projects Created</div>
										</a>
									</div>
								</div>
								<div className="card d-none">
									<div className="card-header">
										<h3 className="card-title">Current Ticket Status</h3>
									</div>

									<div className="card-body">
										{/* <div className="d-sm-flex justify-content-between">
											<div className="font-12">as of 10th to 17th of Jun 2019</div>
											<div className="selectgroup w250">
												<label className="selectgroup-item">
													<input
														type="radio"
														name="intensity"
														defaultValue="low"
														className="selectgroup-input"
														defaultChecked
													/>
													<span className="selectgroup-button">10 Day</span>
												</label>
												<label className="selectgroup-item">
													<input
														type="radio"
														name="intensity"
														defaultValue="medium"
														className="selectgroup-input"
													/>
													<span className="selectgroup-button">20 Day</span>
												</label>
												<label className="selectgroup-item">
													<input
														type="radio"
														name="intensity"
														defaultValue="high"
														className="selectgroup-input"
													/>
													<span className="selectgroup-button">30 Day</span>
												</label>
											</div>
										</div> */}
										{/* <div id="chart-combination" style={{ height: '16rem' }} /> */}
										{/* <div id="chart-combination" style={{ height: 205 }} /> */}
										<Barchart></Barchart>
									</div>
									<div className="card-footer">
										<div className="row">
											<div className="col-6 col-xl-3 col-md-6">
												<h5>05</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>35%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '35%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">New Tickets</span>
											</div>
											<div className="col-6 col-xl-3 col-md-6">
												<h5>18</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>61%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '61%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">Open Tickets</span>
											</div>
											<div className="col-6 col-xl-3 col-md-6">
												<h5>06</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>100%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '100%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">Solved Tickets</span>
											</div>
											<div className="col-6 col-xl-3 col-md-6">
												<h5>11</h5>
												<div className="clearfix">
													<div className="float-left">
														<strong>87%</strong>
													</div>
													<div className="float-right">
														<small className="text-muted">Yesterday</small>
													</div>
												</div>
												<div className="progress progress-xs">
													<div
														className="progress-bar bg-gray"
														role="progressbar"
														style={{ width: '87%' }}
														aria-valuenow={42}
														aria-valuemin={0}
														aria-valuemax={100}
													/>
												</div>
												<span className="text-uppercase font-10">Unresolved</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="row clearfix row-deck">
							<div className="col-xl-4 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Customer Satisfaction</h3>
									</div>
									<div className="card-body">
										<div className="d-flex align-items-baseline">
											<h1 className="mb-0 mr-2">9.8</h1>
											<p className="mb-0">
												<span className="text-success">
													1.6% <i className="fa fa-arrow-up" />
												</span>
											</p>
										</div>
										<h6 className="text-uppercase font-10">Performance Score</h6>
										<div className="progress progress-xs">
											<div
												className="progress-bar"
												role="progressbar"
												style={{ width: '15%' }}
												aria-valuenow={15}
												aria-valuemin={0}
												aria-valuemax={100}
											/>
											<div
												className="progress-bar bg-info"
												role="progressbar"
												style={{ width: '20%' }}
												aria-valuenow={20}
												aria-valuemin={0}
												aria-valuemax={100}
											/>
											<div
												className="progress-bar bg-success"
												role="progressbar"
												style={{ width: '30%' }}
												aria-valuenow={30}
												aria-valuemin={0}
												aria-valuemax={100}
											/>
											<div
												className="progress-bar bg-orange"
												role="progressbar"
												style={{ width: '5%' }}
												aria-valuenow={20}
												aria-valuemin={0}
												aria-valuemax={100}
											/>
											<div
												className="progress-bar bg-indigo"
												role="progressbar"
												style={{ width: '13%' }}
												aria-valuenow={20}
												aria-valuemin={0}
												aria-valuemax={100}
											/>
										</div>
									</div>
									<div className="table-responsive">
										<table className="table table-striped table-vcenter mb-0">
											<tbody>
												<tr>
													<td>
														<i className="fa fa-circle text-blue" />
													</td>
													<td className="tx-medium">Excellent</td>
													<td className="text-right">3,007</td>
													<td className="text-right">50%</td>
												</tr>
												<tr>
													<td>
														<i className="fa fa-circle text-success" />
													</td>
													<td className="tx-medium">Very Good</td>
													<td className="text-right">1,674</td>
													<td className="text-right">25%</td>
												</tr>
												<tr>
													<td>
														<i className="fa fa-circle text-info" />
													</td>
													<td className="tx-medium">Good</td>
													<td className="text-right">125</td>
													<td className="text-right">6%</td>
												</tr>
												<tr>
													<td>
														<i className="fa fa-circle text-orange" />
													</td>
													<td className="tx-medium">Fair</td>
													<td className="text-right">98</td>
													<td className="text-right">5%</td>
												</tr>
												<tr>
													<td>
														<i className="fa fa-circle text-indigo" />
													</td>
													<td className="tx-medium">Poor</td>
													<td className="text-right">512</td>
													<td className="text-right">10%</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-6">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Employee Satisfaction</h3>
									</div>
									<div className="card-body">
										<div className="d-flex align-items-baseline">
											<h2 className="font-28 mr-2">4.2</h2>
											<div className="font-14">
												<i className="fa fa-star text-orange" />
												<i className="fa fa-star text-orange" />
												<i className="fa fa-star text-orange" />
												<i className="fa fa-star text-orange" />
												<i className="fa fa-star" />
											</div>
										</div>
										<p className="mb-0 font-12">
											Overall the quality or your support team’s efforts Rating.
										</p>
									</div>
									<div className="table-responsive">
										<table className="table table-striped table-vcenter mb-0">
											<tbody>
												<tr>
													<td>
														<strong>5.0</strong>
													</td>
													<td>
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
													</td>
													<td className="text-right">432</td>
													<td className="text-right">58%</td>
												</tr>
												<tr>
													<td>
														<strong>4.0</strong>
													</td>
													<td>
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star-o" />
													</td>
													<td className="text-right">189</td>
													<td className="text-right">42%</td>
												</tr>
												<tr>
													<td>
														<strong>3.0</strong>
													</td>
													<td>
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star-o" />
														<i className="fa fa-star-o" />
													</td>
													<td className="text-right">125</td>
													<td className="text-right">23%</td>
												</tr>
												<tr>
													<td>
														<strong>2.0</strong>
													</td>
													<td>
														<i className="fa fa-star" />
														<i className="fa fa-star" />
														<i className="fa fa-star-o" />
														<i className="fa fa-star-o" />
														<i className="fa fa-star-o" />
													</td>
													<td className="text-right">89</td>
													<td className="text-right">18%</td>
												</tr>
												<tr>
													<td>
														<strong>1.0</strong>
													</td>
													<td>
														<i className="fa fa-star" />
														<i className="fa fa-star-o" />
														<i className="fa fa-star-o" />
														<i className="fa fa-star-o" />
														<i className="fa fa-star-o" />
													</td>
													<td className="text-right">18</td>
													<td className="text-right">11%</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-12 col-md-12">
								<div className="card">
									<div className="card-header">
										<div className="card-title">User Team Comparison</div>
									</div>
									<table className="table card-table">
										<tbody>
											<tr>
												<td className="font-weight-bold">Team Course 5i</td>
												<td className="text-right">
													115.52
													<span className="text-success">(0.34%) <i className="fa fa-caret-up" /></span>
												</td>
											</tr>
											<tr>
												<td className="font-weight-bold">Team A</td>
												<td className="text-right">
													635.3
													<span className="text-danger">(-1.15%) <i className="fa fa-caret-down" /></span>
												</td>
											</tr>
											<tr>
												<td className="font-weight-bold">Team B</td>
												<td className="text-right">
													46.74
													<span className="text-success">(0.26%) <i className="fa fa-caret-up" /></span>
												</td>
											</tr>
											<tr>
												<td className="font-weight-bold">Team C</td>
												<td className="text-right">
													190.04
													<span className="text-success">(0.28%) <i className="fa fa-caret-up" /></span>
												</td>
											</tr>
											<tr>
												<td className="font-weight-bold">Team D</td>
												<td className="text-right">
													181.47
													<span className="text-danger">(-0.23%) <i className="fa fa-caret-down" /></span>
												</td>
											</tr>
											<tr>
												<td className="font-weight-bold">Team E</td>
												<td className="text-right">
													37.75
													<span className="text-danger">(-0.74%) <i className="fa fa-caret-down" /></span>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProjectDashboardPage;
