import React, { PureComponent } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect } from "react-redux";
import { userActions } from "_actions";
import { employeeActions } from "_actions";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
class ProfilePageSideSecuritySettings extends PureComponent {
    constructor() {
        super();
        this.state = {
            passwordUpdateRequest: {
                newPassword: "",
                currentPassword: "",
                email: null,
                employeeId: null,
            },
            passwordError: false,
            passwordType: "password"
        }
    }

    componentDidMount() {
        this.setState(prevState => ({
            passwordUpdateRequest: {
                ...prevState.passwordUpdateRequest,
                employeeId: commonUtils.getCurrentUser().id,
                email: commonUtils.getCurrentUser().email,
            }
        }))
    }
    onChange = (e) => {
        this.setState({ submitted: false });
        const { name, value } = e.target;
        this.setState(prevState => ({
            passwordError: false,
            passwordUpdateRequest: {
                ...prevState.passwordUpdateRequest,
                [name]: value
            }
        })
        )
    }

    handleUpdatePassword = () => {
        if (this.state.passwordUpdateRequest &&
            this.state.passwordUpdateRequest.newPassword &&
            this.state.passwordUpdateRequest.currentPassword &&
            this.state.passwordUpdateRequest.newPassword.length > 8) {
            store.dispatch(this.props.updatePassword(this.state.passwordUpdateRequest))
        } else {
            this.setState({
                passwordError: true
            })
        }
    }

    togglePasswordType = () => {
        this.setState({
            passwordType: this.state.passwordType === 'text' ? 'password' : 'text'
        })
    }
    render() {
        return (
            <div className="card-body pl-3 pr-3 overflow-auto">
                <div className="row clearfix">

                    <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                            <input type="text" autoComplete="off" className="form-control" defaultValue={commonUtils.getCurrentUser().name} disabled placeholder="Username" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                            <input type="email" autoComplete="off" className="form-control" defaultValue={commonUtils.getCurrentUser().email} disabled placeholder="Email" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                            <input type="text" autoComplete="off" className="form-control" defaultValue={commonUtils.getCurrentUserTeamName()} disabled />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <hr />
                        <h6>Change Password</h6>
                        {this.props.updatePasswordSuccess === true &&
                            <div className="invalid-feedback">
                                <span>
                                    <p className="text-muted colorgreen">Password has been updated!</p>
                                </span>
                            </div>
                        }
                        <div className="form-group">
                            <input type="password" autoComplete="off" className="form-control" 
                                id="currentPassword"
                                name="currentPassword"
                                value={this.state.passwordUpdateRequest.currentPassword} onChange={this.onChange}
                                placeholder="Current Password" />
                        </div>
                        <div className="form-group">
                            <label className="form-label">New Password
                            </label>

                            <div className="passwordinputcontainer">
                                <span className="input-icon-addon"><i className="fe fe-lock"></i></span>
                                <input type={this.state.passwordType} autoComplete="off" className="form-control passwordinput" id="newPassword"
                                    placeholder="Password"
                                    name="newPassword"
                                    value={this.state.passwordUpdateRequest.newPassword} onChange={this.onChange}
                                />
                                <a className="passwordshoweye" onClick={this.togglePasswordType}><i className={`fe ${this.state.passwordType === 'text' ? 'fe-eye-off' : 'fe-eye'}`}></i></a>
                            </div>
                            {this.state.passwordError === true &&
                                <div className="invalid-feedback">Password length shoudl be greater than 8</div>
                            }
                            {this.props.updatePasswordError === true &&
                                <div className="invalid-feedback">Unable to update the password. Please try again!</div>
                            }
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-round btn-primary" onClick={() => this.handleUpdatePassword()}>Update</button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.authentication.user,
    updatePasswordSuccess: state.authentication.updatePasswordSuccess,
    updatePasswordError: state.authentication.updatePasswordError
});

const mapDispatchToProps = dispatch => ({
    updatePassword: userActions.updatePassword
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePageSideSecuritySettings);