import { scheduleConstants } from "_constants";

let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
]

let reportScheduleFieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
  { name: 'subject', mandatory: true, enabled: true },
]

export function schedules(state = { fieldConfig, reportScheduleFieldConfig, showCreateModel:false }, action) {

  switch (action.type) {
    case scheduleConstants.SHOW_CREATE_SCHEDULES_WINDOW:
      return {
        ...state,
        showCreateModel:true
      };
      case scheduleConstants.CLOSE_CREATE_SCHEDULES_WINDOW:
        return {
          ...state,
          showCreateModel:false
        };
    case scheduleConstants.GETALL_SCHEDULES_REQUEST:
      return {
        ...state,
        items:[]
      };

    case scheduleConstants.GETALL_SCHEDULES_SUCCESS:
      return {
        ...state,
        items:action.schedules
      };

    case scheduleConstants.GETALL_SCHEDULES_FAILURE:
      return {
        ...state,
        items:[]
      };

    case scheduleConstants.CREATE_SCHEDULE_REQUEST:
      return {
        ...state,
      };

    case scheduleConstants.CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.schedule],
        showCreateModel: false
      };

    case scheduleConstants.CREATE_SCHEDULE_FAILURE:
      return {
        ...state
      };


      case scheduleConstants.UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
      };

    case scheduleConstants.UPDATE_SCHEDULE_SUCCESS:
      let newItems = null;
      if (state.items) {
        newItems = state.items && state.items.filter(item => item.id !== action.id);
        newItems.push(action.schedule);
      }
      return {
        ...state,
        items: newItems,
        showCreateModel: false
      };

    case scheduleConstants.UPDATE_SCHEDULE_FAILURE:
      return {
        ...state
      };

    case scheduleConstants.ENABLE_SCHEDULE_REQUEST:
      return {
        ...state
      };

    case scheduleConstants.ENABLE_SCHEDULE_SUCCESS:
      return {
        ...state,
        items: state.items.map(schedule =>
          schedule.id === action.itemId
            ? { ...schedule, enabled: action.isEnabled }
            : schedule
        )
      };

    case scheduleConstants.ENABLE_SCHEDULE_FAILURE:
      return {
        ...state
      };

    case scheduleConstants.REMOVE_SCHEDULE_REQUEST:
      return {
        ...state,
      };

    case scheduleConstants.REMOVE_SCHEDULE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(schedule => schedule.id !== action.itemId)
      };
    case scheduleConstants.REMOVE_SCHEDULE_FAILURE:
      return {
        ...state
      };
    default:
      return state
  }
}