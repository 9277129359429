import { projectConstants } from '../_constants';

let fieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
]

export function accounts(state = { fieldConfig }, action) {

  switch (action.type) {

    case projectConstants.SHOW_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW:
      return {
        ...state,
        showCreateModel: true
      };

    case projectConstants.CLOSE_PROJECT_ACCOUNT_ITEM_CREATE_WINDOW:
      return {
        ...state,
        showCreateModel: false
      };

    case projectConstants.CHANGE_FILTER_ACCOUNTS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };

    case projectConstants.GET_ALL_PROJECT_ACCOUNTS_REQUEST:
      return {
        ...state,
        currentViewItems: null
      };
    case projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        allProjectAccounts: action.accounts,
        currentViewItems: action.accounts,
        viewName: "TEAM"
      };
    case projectConstants.GET_ALL_PROJECT_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case projectConstants.GET_ALL_PROJECT_TEAM_ACCOUNTS_REQUEST:
      return {
        ...state,
        allProjectTeamAccounts: null,
        currentViewItems: null
      };
    case projectConstants.GET_ALL_PROJECT_TEAM_ACCOUNTS_SUCCESS:
      return {
        ...state,
        allProjectTeamAccounts: action.accounts,
        currentViewItems: action.accounts,
        viewName: "TEAM"
      };
    case projectConstants.GET_ALL_PROJECT_TEAM_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUB_REQUEST:
      return {
        ...state,
        subAccountItems: null
      };
    case projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUB_SUCCESS:
      return {
        ...state,
        subAccountItems: action.subAccountItems
      };
    case projectConstants.GET_ALL_PROJECT_ACCOUNTS_SUB_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case projectConstants.GET_ALL_CURRENT_VIEW_ACCOUNTS_SUCCESS:

      return {
        ...state,
        currentViewItems: action.items,
        viewName: action.viewName
      };

    case projectConstants.ADD_TO_ACCOUNT_ITEM_SUCCESS:
      state.currentViewItems ? state.currentViewItems.push(action.accountItem) : state.currentViewItems = [action.accountItem];
      return {
        ...state,
        showCreateModel: action.createAnotherItemEnabled
      };

    case projectConstants.DETACH_ACCOUNT_ITEM_SUCCESS:
      let filteredCurrentViewItems = state.currentViewItems ? state.currentViewItems.filter(item => action.accountItem.id !== item.id) : []
      return {
        ...state,
        currentViewItems: filteredCurrentViewItems
      };

    case projectConstants.REMOVE_ACCOUNT_ITEM_SUCCESS:
      let currentViewItemsAfterItemDelete = state.currentViewItems ? state.currentViewItems.filter(item => action.itemId !== item.id) : []
      return {
        ...state,
        currentViewItems: currentViewItemsAfterItemDelete
      };

    case projectConstants.CREATE_ACCOUNT_ITEM_SUCCESS:
      state.currentViewItems ? state.currentViewItems.push(action.accountItem) : state.currentViewItems = [action.accountItem];
      return {
        ...state,
        showCreateModel: action.createAnotherItemEnabled
      };
    default:
      return state
  }
}