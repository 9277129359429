import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { accessUtils, history } from './_helpers';
import { notificationActions } from './_actions';
import MainLayout from 'layouts/MainLayout';
import UserLayout from 'layouts/UserLayout';
import ErrorLayout from 'layouts/ErrorLayout';
import DocsLayout from 'layouts/DocsLayout';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      notificationActions && dispatch(notificationActions.clear());
    });
  }, []);

  function getRedirectPageUrl() {
    const menuAccessControl = accessUtils.getMenuAccessControl();
   if(menuAccessControl && menuAccessControl.projectModules && menuAccessControl.projectModules.active === true){
    return "/project/" +  (menuAccessControl.projectModules.defaultRedirectPage ? menuAccessControl.projectModules.defaultRedirectPage : "dashboard");
   } else  if(menuAccessControl && menuAccessControl.hrmsModules && menuAccessControl.hrmsModules.active === true){
    return "/hrms/" +  (menuAccessControl.hrmsModules.defaultRedirectPage ? menuAccessControl.hrmsModules.defaultRedirectPage : "dashboard");
   } else if(menuAccessControl && menuAccessControl.salesModules  && menuAccessControl.salesModules.active === true){
    return "/sales/" +  (menuAccessControl.salesModules.defaultRedirectPage ? menuAccessControl.salesModules.defaultRedirectPage : "bidrequests");
   }
    return "/project/dashboard";
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path="/hrms" render={props => securePage(props)} />
        <Route path="/sales" render={props => securePage(props)} />
        <Route path="/project" render={props => securePage(props)} />
        <Route path="/user" render={props => inSecurePage(props)} />
        <Route path="/error" render={props =>  <ErrorLayout {...props} />} />
        <Route path="/document" render={props =>  <DocsLayout {...props} />} />
        <Redirect from="/" to={getRedirectPageUrl()} />
      </Switch>
    </Router>
  );

  function inSecurePage(props) {
    return (
      localStorage.getItem('cu')
        ? <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        : <UserLayout {...props} />
    );
  }

  function securePage(props) {
    return (
      localStorage.getItem('cu')
        ? <MainLayout {...props} />
        : <Redirect to={{ pathname: '/user/login', state: { from: props.location } }} />
    );
  }
}

export default App;