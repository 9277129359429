import React, { Component } from "react";
import TaskBoardList from "./components/TaskBoardList";
import TaskBoardGrid from "./components/TaskBoardGrid";
import PageInnerHeader from "views/common/PageInnerHeader";
import { connect } from "react-redux";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import { workItemActions } from "_actions";
import { workItemConstants } from "_constants";
import TaskCompletionModel from "./components/modal/TaskCompletionModel";
import { ModalManager } from "views/common/plugins/model/Modal";
import { accessUtils } from "_helpers";
import { employeeActions } from "_actions";
import ViewTaskDetailsSidebar from "./components/modal/ViewTaskDetailsSidebar";
import ViewProjectDetailsSidebar from "./components/modal/ViewProjectDetailsSidebar";
import { taskConstants } from "_constants";
import { projectActions } from "_actions";
import { taskActions } from "_actions";
import { projectConstants } from "_constants";
import ItemNotFoundSection from "./components/ItemsNotFoundSection";
import { taskboardActions } from "_actions";
import ConfigureTaskboardSidebar from "./components/modal/ConfigureTaskboardSidebar";
import { taskboardConstants } from "_constants";

class TaskBoardPage extends Component {
  constructor() {
    super()
    this.state = {
      date: new Date(),
      showGrid: true,
      selectedTaskBoard: {
        selectedEmployeeId: "" + commonUtils.getCurrentUserId(),
        item: null
      },
      filterOptions: {
        filterText: null
      },
      permission: accessUtils.getTaskBoardAccessControl()
    }
  }
  componentDidMount() {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name);
    let selectedUserTeamsIds = commonUtils.getSelectedUserTeamIds(userTeams);
    if (this.state.permission.multiTaskBoardEnabled === false) {
      store.dispatch(this.props.getTeamsEmployees(selectedUserTeamsIds))
      this.reloadTeamBoardWorkItems(null, null)
    }
    this.loadTaskBoards(selectedUserTeamsIds, commonUtils.getCurrentUserId())
  }
  gridClickHandler = (e) => {
    e.preventDefault();
    this.setState({ showGrid: true });
  }
  listClickHandler = (e) => {
    e.preventDefault();
    this.setState({ showGrid: false });
  }
  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.onFilterChange(this.state.filterOptions);
      }
    );
  }

  onFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_BOARD, userTeams: this.props.userTeams, filterOptions });
      });
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: workItemConstants.INITIALIZE_WORK_ITEMS_BOARD, userTeams, filterOptions: this.props.filterOptions });
    let selectedUserTeamsIds = commonUtils.getSelectedUserTeamIds(userTeams);
    store.dispatch(this.props.getTeamsEmployees(selectedUserTeamsIds))
    if (this.state.permission.switchEmployeesEnabled === false) {
      this.reloadTeamBoardWorkItems(userTeams, null)
    }
  }

  handleFilterChange = (filterOptions) => {
    let existingFilterOptions = this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions;
    if (existingFilterOptions.BOARDS) {
      if (existingFilterOptions.BOARDS.selected !== filterOptions.BOARDS.selected) {
        this.setState({
          selectedTaskBoard:
          {
            ...this.state.selectedTaskBoard,
            item: this.props.taskboardItems.filter(board => board.id === filterOptions.BOARDS.selected)[0]
          }
        });
      }
    }
    this.onFilterChange(filterOptions);
  }

  loadTaskBoards = (userTeamIds, emplyeeId) => {
    if (this.state.permission.multiTaskBoardEnabled === true) {
      if (this.state.permission.allUserTeamMultiTaskBoardEnabled === true) {
        store.dispatch(this.props.getLoadTaskBoards({ userTeamIds, emplyeeId: null }, this.onloadTaskBoardsCallBack))
      } else {
        store.dispatch(this.props.getLoadTaskBoards({ userTeamIds: null, emplyeeId }, this.onloadTaskBoardsCallBack))
      }
    }
  }


  onloadTaskBoardsCallBack = (taskBoards) => {
    let filterOptions = this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions;
    let options = [];
    let filteredTaskboards = taskBoards.filter(board => board.active)

    if (filteredTaskboards && filteredTaskboards.length > 0) {
      filteredTaskboards.map(board =>
        options.push({ name: board.id, label: board.name })
      )
    } else {
      filteredTaskboards = null;
      options = null;
    }
    let newfilterOptions = {
      ...filterOptions,
      BOARDS: {
        options: options,
        selected: options && options[0].name
      }
    }

    this.setState({
      selectedTaskBoard:
      {
        ...this.state.selectedTaskBoard,
        item: (filteredTaskboards && filteredTaskboards[0]) || {}
      }
    });
    this.onFilterChange(newfilterOptions)
    if (filteredTaskboards && filteredTaskboards[0].employeeMapInfo.employees[this.state.selectedTaskBoard.selectedEmployeeId] !== undefined) {
      this.reloadTeamBoardWorkItems(null, null)
    }
  }

  onTaskboardEmployeeChange = (employeeId) => {
    if (this.state.selectedTaskBoard.selectedEmployeeId !== employeeId) {
      this.reloadTeamBoardWorkItems(null, [employeeId]);
      this.setState({
        selectedTaskBoard:
        {
          ...this.state.selectedTaskBoard,
          selectedEmployeeId: employeeId
        }
      });
    }
  }

  handleEmployeesSelection = (employees) => {
    store.dispatch({ type: workItemConstants.WORK_ITEMS_BOARD_CHANGE_EMPLOYEES, employees });
    let employeeIds = commonUtils.getSelectedEmployeeIds(employees);
    this.reloadTeamBoardWorkItems(null, employeeIds)
  }

  reloadTeamBoardWorkItems = (userTeams, employeeIds) => {
    let selectedUserTeamIds = commonUtils.getSelectedUserTeamIds(userTeams);
    if (!employeeIds || employeeIds.length === 0) {
      employeeIds = [commonUtils.getCurrentUserId()];
    }
    let todaysDate = dateUtils.getDefaultTodayDateRange();
    const startDate = todaysDate.start.format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = todaysDate.end.endOf('day').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getWorkItemBoardActivities({ userTeamIds: selectedUserTeamIds, startDate, endDate, employeeIds: employeeIds }));
  }
  cancelAction = (task) => {
    this.updateActionSuccess(task, task.stage);
  }
  showTaskStatusUpdateModel = (task, modalType, isPlay, callbackFunction) => {
    if (modalType === 'COMPLETED') {
      store.dispatch(this.props.getClonedByWorkItems(task.id, (clonedByItems) => {
        this.openModel(task, modalType, isPlay, clonedByItems, callbackFunction);
      }))
    }
    this.openModel(task, modalType, isPlay, null, callbackFunction);
  }

  openModel = (task, modalType, isPlay, clonedByItems, callbackFunction) => {
    ModalManager.open(<TaskCompletionModel
      workItem={task}
      clonedByItems={clonedByItems}
      isPlay={isPlay}
      modalType={modalType}
      updateAction={this.handleUpdateWorkItemStatus}
      playAction={this.handlePlayWorkItem}
      callbackFunction={callbackFunction}
      onRequestClose={() => true}
      cancelAction={this.cancelAction}
      currentTime={dateUtils.getCurrentTime()}
      permission={this.state.permission}
    />);
  }

  updateActionSuccess = () => {
    //let draggedOverCol = modalType === 'COMPLETE' ? 4 : 3;
    ////console.log('update actions called')
    //const updatedtasks = this.props.workItems.slice(0);
    //updatedtasks.find((taskObject) => { return taskObject.id === task.id; }).stage = draggedOverCol;
    this.setState({ draggedOverCol: 0 });
    ModalManager.close();
  }
  updateWorkItemStatus = (workItem, status) => {
    store.dispatch(this.props.updateWorkItemStatus(workItem.id, status));
  }

  handleUpdateWorkItemStatus = (workItemStatusRequest, modalType) => {
    //console.log("Inprogress action");
    workItemStatusRequest = {
      ...workItemStatusRequest,
      "canComplete": modalType === 'COMPLETED',
      "status": modalType
    }
    store.dispatch(this.props.updateWorkItemWithStatus(workItemStatusRequest, () => this.updateActionSuccess()));
  }
  onShowTaskboardConfig = () => {
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    let selectedUserTeamsIds = [];
    userTeams && userTeams.map(userTeam => userTeam.isChecked && selectedUserTeamsIds.push(userTeam.id));
    store.dispatch(this.props.getTeamsEmployees(selectedUserTeamsIds))
    store.dispatch({ type: taskboardConstants.SHOW_TASKBOARD_CONFIGURE_WINDOW });
  }
  onCloseItemClick = () => {
    store.dispatch({ type: taskboardConstants.SHOW_TASKBOARD_CONFIGURE_WINDOW });
  }

  onViewProject = (projectId) => {
    store.dispatch(this.props.getProjectInfo(projectId));
    store.dispatch({ type: projectConstants.SHOW_PROJECT_VIEW_WINDOW });
  }
  onCloseViewProject = () => {
    store.dispatch({ type: projectConstants.CLOSE_PROJECT_VIEW_WINDOW });
  }
  onViewTask = (taskId, projectId) => {
    store.dispatch(this.props.getTaskInfo(taskId, projectId));
    store.dispatch({ type: taskConstants.SHOW_TASK_VIEW_WINDOW });
  }
  onCloseViewTask = () => {
    store.dispatch({ type: taskConstants.CLOSE_TASK_VIEW_WINDOW });
  }

  handlePauseWorItem = (workItemId) => {
    store.dispatch(this.props.pauseWorkItem(workItemId, dateUtils.convertMomentToString(dateUtils.getCurrentTime())));
  }
  handlePlayWorkItem = (workItemStatusRequest, modalType, isSavePrevious, callbackFunction) => {
    //console.log("Play action");
    workItemStatusRequest = {
      ...workItemStatusRequest,
      "canComplete": isSavePrevious,
      "status": 'INPROGRESS'
    }
    store.dispatch(this.props.playWorkItem(workItemStatusRequest, dateUtils.convertMomentToString(dateUtils.getCurrentTime()), isSavePrevious, () => { this.updateActionSuccess(); callbackFunction(); }));
  }
  render() {
    return (
      <div className="taskboard">
        <div className="section-body">
          <div className="container-fluid">
            <PageInnerHeader
              userTeams={commonUtils.getUserTeams(this.props.userTeams)}
              handleUserTeamsSelection={this.handleUserTeamsSelection}
              showGridDefault={this.state.showGrid}
              showCreateMenu={this.state.permission.multiTaskBoardEnabled && this.state.permission.configureEnabled}
              showCreateMenuText="Configure"
              createMenuIcon="fe-settings"
              createItemHandler={this.onShowTaskboardConfig}
              showGridListMenu={true}
              showFilter={true}
              showUserTeamMenu={this.state.permission.switchUserTeamsEnabled === true && this.state.permission.multiTaskBoardEnabled === false}
              showEmployeeMenu={this.state.permission.switchEmployeesEnabled === true && this.state.permission.multiTaskBoardEnabled === false}
              employees={this.props.employees}
              handleEmployeesSelection={this.handleEmployeesSelection}
              isLoading={this.props.isLoading}
              filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
              handleFilterChange={this.handleFilterChange}
              onFilterTextChange={this.onFilterTextChange}

              gridClickHandler={this.gridClickHandler}
              listClickHandler={this.listClickHandler}
              showDateRangeSelector={false}
              permission={this.state.permission}
            >
            </PageInnerHeader>
            {this.state.permission.multiTaskBoardEnabled &&
              <div className="section-body border-bottom mt-1">
                <div className="container-fluid">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="pl-1 fsize12">
                      <span className="text-muted font-weight-light">QUICK FILTER : </span>
                      {this.state.selectedTaskBoard && this.state.selectedTaskBoard.item && this.state.selectedTaskBoard.item.employeeMapInfo && this.state.selectedTaskBoard.item.employeeMapInfo.employees &&
                        Object.keys(this.state.selectedTaskBoard.item.employeeMapInfo.employees).map(employeeId =>
                          <button key={employeeId} onClick={() => this.onTaskboardEmployeeChange(employeeId)} className={`link-normal px-2 ${this.state.selectedTaskBoard.selectedEmployeeId === employeeId ? ' colorblue fweight600' : ''}`}>{this.state.selectedTaskBoard.item.employeeMapInfo.employees[employeeId]}</button>
                        )}
                    </span>
                  </div>
                </div>
              </div>
            }
            {/* <TaskBoardTopGraph/> */}
          </div>
        </div>
        <div className="section-body pt-1">
          <div className="container-fluid">
            <div className="tab-content taskboard">
              {(!this.state.showGrid &&
                ((this.props.items && this.props.items.length > 0) || this.props.isLoading)) &&
                <TaskBoardList
                  items={this.props.items}
                  isLoading={this.props.isLoading}
                  showTaskStatusUpdateModel={this.showTaskStatusUpdateModel}
                  onPause={this.handlePauseWorItem}
                  permission={this.state.permission}
                />
              }
              {this.state.showGrid &&
                <>
                  {
                    (this.props.items && this.props.items.length > 0) &&
                    <TaskBoardGrid workItems={this.props.items}
                      isLoading={this.props.isLoading}
                      showTaskStatusUpdateModel={this.showTaskStatusUpdateModel}
                      onPause={this.handlePauseWorItem}
                      onViewProject={this.onViewProject}
                      onViewTask={this.onViewTask}
                      updateWorkItemStatus={this.updateWorkItemStatus}
                    />
                  }
                  {
                    (!(this.props.items && this.props.items.length > 0)) && this.props.isLoading &&
                    <div>Loading</div>
                  }
                </>
              }

              <ItemNotFoundSection
                items={this.props.items}
                isLoading={this.props.isLoading}
                imgurl="noactivetasks-min.PNG"
                text="No Activities found ?"
                description="Try Creating a work item from project deliverables..."
                bgcolor={'bgcolorwhite'}
              />

            </div>
          </div>
        </div>
        <ViewProjectDetailsSidebar show={this.props.isShowProjectViewSidebar} onCloseViewProject={this.onCloseViewProject} />
        <ViewTaskDetailsSidebar show={this.props.isShowTaskViewSidebar} onCloseViewTask={this.onCloseViewTask} />
        <ConfigureTaskboardSidebar
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          show={this.props.isShowConfigureTaskboardSidebar}
          taskboardItems={this.props.taskboardItems}
          permission={this.state.permission}
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.workItem.isLoadingBoard,
  items: commonUtils.filterWorkItems(state.workItem.workItemBoardWorkItems, state.workItem.filterOptionsTB),
  userTeams: commonUtils.getUserTeams(state.workItem.userTeamsTB),
  employees: state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees),
  // employeeIds:commonUtils.getEmployeeIds(state.workItem.employeeIdsTB),
  filterOptions: state.workItem.filterOptionsTB,
  isShowProjectViewSidebar: state.projects.isShowProjectViewSidebar,
  isShowTaskViewSidebar: state.tasks.isShowTaskViewSidebar,
  isShowConfigureTaskboardSidebar: state.taskBoard.isShowConfigureTaskboardSidebar,
  taskboardItems: state.taskBoard.items
});

const mapDispatchToProps = dispatch => ({
  getWorkItemBoardActivities: workItemActions.getWorkItemBoardActivities,
  updateWorkItemWithStatus: workItemActions.updateWorkItemWithStatus,
  updateWorkItemStatus: workItemActions.updateWorkItemStatus,
  getTeamsEmployees: employeeActions.getTeamsEmployees,
  getProjectInfo: projectActions.getProjectInfo,
  getTaskInfo: taskActions.getTaskInfo,
  getLoadTaskBoards: taskboardActions.getAllTaskboards,
  pauseWorkItem: workItemActions.pauseWorkItem,
  playWorkItem: workItemActions.playWorkItem,
  getClonedByWorkItems: workItemActions.getClonedByWorkItems
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskBoardPage);

