import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import PurchaseOrderInfoLine from "./PurchaseOrderInfoLine";

class PurchaseOrderListTable extends Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table_custom table-nowrap table-vcenter table-borderless position-relative">
          <thead>
            <tr>
              <th>#</th>
              <th>Created Date</th>
              <th className="w-250">Name</th>
              <th>Proposal no</th>
              <th>Bid id</th>
              <th className="text-center">Account</th>
              <th className="text-center">Client Name</th>
              <th className="text-center">Status</th>
              <th>Date</th>
              <th className="text-center">Action
                <button type="button" className="link pr-2 float-right" title="Refresh" onClick={() => this.props.reloadPurchaseOrderList()}>
                  <i className="fe fe-refresh-cw fa-lg"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.isLoading &&
              <tr>
                <td colSpan="11">
                  <Skeleton count={7} height={40} />
                </td>
              </tr>
            }

            {this.props.items && this.props.items.map(item =>
              <PurchaseOrderInfoLine item={item} key={item.id}
                handleDeletePurchaseOrder={this.props.handleDeletePurchaseOrder}
                handleEditPurchaseOrder={this.props.handleEditPurchaseOrder}
                handleUpdateStatus={this.props.handleUpdateStatus}
                handleDuplicatePurchaseOrder={this.props.handleDuplicatePurchaseOrder}
                onViewPurchaseOrder={this.props.onViewPurchaseOrder}
                handleArchivePurchaseOrder={this.props.handleArchivePurchaseOrder}
                handleRemovePurchaseOrder={this.props.handleRemovePurchaseOrder}
                permission={this.props.permission}
              />
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
export default PurchaseOrderListTable;