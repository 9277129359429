export const employeeConstants = {
    INITIALIZE_CREATE_EMPLOYEE_REQUEST : 'INITIALIZE_CREATE_EMPLOYEE_REQUEST',
    INITIALIZE_CREATE_EMPLOYEE_SUCCESS : 'INITIALIZE_CREATE_EMPLOYEE_SUCCESS',
    INITIALIZE_CREATE_EMPLOYEE_FAILURE:'INITIALIZE_CREATE_EMPLOYEE_FAILURE',

    TOGGLE_USER_DIV_WINDOW:'TOGGLE_USER_DIV_WINDOW',
    SHOW_EMPLOYEE_CREATE_WINDOW:"SHOW_EMPLOYEE_CREATE_WINDOW",
    CLOSE_EMPLOYEE_CREATE_WINDOW:"CLOSE_EMPLOYEE_CREATE_WINDOW",
    CREATE_EMPLOYEE_REQUEST: 'CREATE_EMPLOYEE_REQUEST',
    CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
    CREATE_EMPLOYEE_FAILURE: 'CREATE_EMPLOYEE_FAILURE',

    UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
    UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
    UPDATE_EMPLOYEE_FAILURE: 'UPDATE_EMPLOYEE_FAILURE',
    
    UPDATE_EMPLOYEE_USER_ROLE_REQUEST: 'UPDATE_EMPLOYEE_USER_ROLE_REQUEST',
    UPDATE_EMPLOYEE_USER_ROLE_SUCCESS: 'UPDATE_EMPLOYEE_USER_ROLE_SUCCESS',
    UPDATE_EMPLOYEE_USER_ROLE_FAILURE: 'UPDATE_EMPLOYEE_USER_ROLE_FAILURE',
    
    DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
    DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
    DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

    REMOVE_EMPLOYEE_REQUEST: 'REMOVE_EMPLOYEE_REQUEST',
    REMOVE_EMPLOYEE_SUCCESS: 'REMOVE_EMPLOYEE_SUCCESS',
    REMOVE_EMPLOYEE_FAILURE: 'REMOVE_EMPLOYEE_FAILURE',

    ACTIVATE_EMPLOYEE_REQUEST: 'ACTIVATE_EMPLOYEE_REQUEST',
    ACTIVATE_EMPLOYEE_SUCCESS: 'ACTIVATE_EMPLOYEE_SUCCESS',
    ACTIVATE_EMPLOYEE_FAILURE: 'ACTIVATE_EMPLOYEE_FAILURE',
    
    GETALL_EMPLOYEES_REQUEST: 'GETALL_EMPLOYEES_REQUEST',
    GETALL_EMPLOYEES_SUCCESS: 'GETALL_EMPLOYEES_SUCCESS',
    GETALL_EMPLOYEES_FAILURE: 'GETALL_EMPLOYEES_FAILURE',

    GET_INFO_EMPLOYEE_REQUEST: 'GET_INFO_EMPLOYEE_REQUEST',
    GET_INFO_EMPLOYEE_SUCCESS: 'GET_INFO_EMPLOYEE_SUCCESS',
    GET_INFO_EMPLOYEE_FAILURE: 'GET_INFO_EMPLOYEE_FAILURE',

    GET_TEAMS_EMPLOYEES_REQUEST: 'GET_TEAMS_EMPLOYEES_REQUEST',
    GET_TEAMS_EMPLOYEES_SUCCESS: 'GET_TEAMS_EMPLOYEES_SUCCESS',
    GET_TEAMS_EMPLOYEES_FAILURE: 'GET_TEAMS_EMPLOYEES_FAILURE',

    GET_TEAMS_EMPLOYEES_PAGE_REQUEST: 'GET_TEAMS_EMPLOYEES_PAGE_REQUEST',
    GET_TEAMS_EMPLOYEES_PAGE_SUCCESS: 'GET_TEAMS_EMPLOYEES_PAGE_SUCCESS',
    GET_TEAMS_EMPLOYEES_PAGE_FAILURE: 'GET_TEAMS_EMPLOYEES_PAGE_FAILURE',
    
    CHANGE_FILTER_EMPLOYEES : 'CHANGE_FILTER_EMPLOYEES',
    INITIALIZE_EMPLOYEE_USER_TEAMS: 'INITIALIZE_EMPLOYEE_USER_TEAMS',

    INITIALIZE_EMPLOYEE_ATTENDANCE_USER_TEAMS:'INITIALIZE_EMPLOYEE_ATTENDANCE_USER_TEAMS',
    CHANGE_FILTER_EMPLOYEES_ATTENDANCE:'CHANGE_FILTER_EMPLOYEES_ATTENDANCE',
    GET_TEAMS_ATTENDANCE_REQUEST:'GET_TEAMS_ATTENDANCE_REQUEST',
    GET_TEAMS_ATTENDANCE_SUCCESS:'GET_TEAMS_ATTENDANCE_SUCCESS',
    GET_TEAMS_ATTENDANCE_FAILURE:'GET_TEAMS_ATTENDANCE_FAILURE',
    CREATE_ATTENDANCE_REQUEST:'CREATE_ATTENDANCE_REQUEST',
    CREATE_ATTENDANCE_SUCCESS:'CREATE_ATTENDANCE_SUCCESS',
    CREATE_ATTENDANCE_FAILURE:'CREATE_ATTENDANCE_FAILURE',
    DELETE_ATTENDANCE_REQUEST:'DELETE_ATTENDANCE_REQUEST',
    DELETE_ATTENDANCE_SUCCESS:'DELETE_ATTENDANCE_SUCCESS',
    DELETE_ATTENDANCE_FAILURE:'DELETE_ATTENDANCE_FAILURE',

    INITIALIZE_HL_FULLCALENDAR:'INITIALIZE_HL_FULLCALENDAR',
    GET_HOLIDAY_LEAVE_REQUEST:'GET_HOLIDAY_LEAVE_REQUEST',
    GET_HOLIDAY_LEAVE_SUCCESS:'GET_HOLIDAY_LEAVE_SUCCESS',
    GET_HOLIDAY_LEAVE_FAILURE:'GET_HOLIDAY_LEAVE_FAILURE',

    SHOW_HOLIDAY_CREATE_WINDOW: 'SHOW_HOLIDAY_CREATE_WINDOW',
    CLOSE_HOLIDAY_CREATE_WINDOW: 'CLOSE_HOLIDAY_CREATE_WINDOW',

    CREATE_HOLIDAY_ITEM_REQUEST: 'CREATE_HOLIDAY_ITEM_REQUEST',
    CREATE_HOLIDAY_ITEM_SUCCESS: 'CREATE_HOLIDAY_ITEM_SUCCESS',
    CREATE_HOLIDAY_ITEM_FAILURE: 'CREATE_HOLIDAY_ITEM_FAILURE',

    UPDATE_HOLIDAY_ITEM_REQUEST: 'UPDATE_HOLIDAY_ITEM_REQUEST',
    UPDATE_HOLIDAY_ITEM_SUCCESS: 'UPDATE_HOLIDAY_ITEM_SUCCESS',
    UPDATE_HOLIDAY_ITEM_FAILURE: 'UPDATE_HOLIDAY_ITEM_FAILURE',

    REMOVE_HOLIDAY_ITEM_REQUEST: 'REMOVE_HOLIDAY_ITEM_REQUEST',
    REMOVE_HOLIDAY_ITEM_SUCCESS: 'REMOVE_HOLIDAY_ITEM_SUCCESS',
    REMOVE_HOLIDAY_ITEM_FAILURE: 'REMOVE_HOLIDAY_ITEM_FAILURE',

};
