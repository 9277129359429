import { workItemConstants } from '../_constants';
import { appConfigService, workItemService } from '../_services';
import { accountActions, appConfigActions, notificationActions } from '.';
import { employeeActions } from './employee.actions';
import { taskActions } from './task.actions';
export const workItemActions = {
    getModuleConfig,
    getAllWorkItems,
    getWorkItemInfo,
    initializeCreateWorkItem,
    createWorkItem,
    createWorkItems,
    updateWorkItemWithStatus,
    pauseWorkItem,
    playWorkItem,
    addTimesheetData,
    loadTimeSheetData,

    updateWorkItem,
    updateWorkItemStatus,
    deleteWorkItem,
    removeWorkItem,

    initializeNonProjectWorkItem,
    getModuleNonProjectConfig,

    getWorkItemActivitiesForCalendar,
    getWorkItemActivities,
    getTodaysWorkItemActivities,
    getWorkItemActivitiesPending,
    getWorkItemBoardActivities,
    getProjectWorkItemActivities,
    getClonedByWorkItems
};
function initializeCreateWorkItem(userTeamId) {
    return dispatch => {
        dispatch(getModuleConfig(userTeamId))
        dispatch(employeeActions.getAllEmployees());
    };
}

function initializeNonProjectWorkItem(showAllAccounts, userTeamId) {
    return dispatch => {
        dispatch(getModuleNonProjectConfig(userTeamId));
        // dispatch(employeeActions.getModuleConfig(userTeamId));
        // dispatch(employeeActions.getAllEmployees());
        if (showAllAccounts) {
            dispatch(accountActions.getProjectAllAccounts());
        } else {
            dispatch(accountActions.getProjectTeamAccounts(userTeamId));
        }
        dispatch(appConfigActions.getAllTeams(true));
    };
}


function getModuleNonProjectConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'NONPROJECT').then(
            appConfig => {
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("getModuleConfig Project");
    };
    function request() { return { type: workItemConstants.INITIALIZE_CREATE_NONPROJECT_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: workItemConstants.INITIALIZE_CREATE_NONPROJECT_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: workItemConstants.INITIALIZE_CREATE_NONPROJECT_FAILURE, error } }
}


function getModuleConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'PROJECT').then(
            appConfig => {
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("getModuleConfig Project from Task");
    };
    function request() { return { type: workItemConstants.INITIALIZE_CREATE_WORK_ITEM_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: workItemConstants.INITIALIZE_CREATE_WORK_ITEM_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: workItemConstants.INITIALIZE_CREATE_WORK_ITEM_FAILURE, error } }
}
function getAllWorkItems(requestObj, filterOptions) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getAllWorkItems(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: workItemConstants.GETALL_WORK_ITEMS_REQUEST } }

    function success(workItems, filterOptions) { return { type: workItemConstants.GETALL_WORK_ITEMS_SUCCESS, workItems, filterOptions } }

    function failure(error) { return { type: workItemConstants.GETALL_WORK_ITEMS_FAILURE, error } }
}
function getWorkItemInfo(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getWorkItemInfo(requestObj)
            .then(
                workItem => dispatch(success(workItem.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: workItemConstants.GET_WORK_ITEM_REQUEST } }
    function success(workItem) { return { type: workItemConstants.GET_WORK_ITEM_SUCCESS, workItem } }
    function failure(error) { return { type: workItemConstants.GET_WORK_ITEM_FAILURE, error } }
}

function createWorkItem(workItemRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(workItemRequest));

        workItemService.createWorkItem(workItemRequest)
            .then(
                workItemResponse => {
                    dispatch(success(workItemResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('Workitem create successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(getErrorMessage(error)));
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(workItem) { return { type: workItemConstants.CREATE_WORK_ITEM_REQUEST, workItem } }
    function success(workItem, showModel) { return { type: workItemConstants.CREATE_WORK_ITEM_SUCCESS, workItem, showModel } }
    function failure(error) { return { type: workItemConstants.CREATE_WORK_ITEM_FAILURE, error } }
}


function loadTimeSheetData(startDate, endDate, employeeIds, userTeamIds, callBackFunction) {
    //console.log("loadTimeSheetData")
    return dispatch => {
        dispatch(request());

        workItemService.loadTimeSheetData({ startDate, endDate, employeeIds, userTeamIds })
            .then(
                timesheetResponse => {
                    dispatch(success(timesheetResponse.payload))
                    callBackFunction(timesheetResponse.payload);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: workItemConstants.GET_TIMESHEET_DATA_REQUEST } }
    function success(timesheetResponse) { return { type: workItemConstants.GET_TIMESHEET_DATA_SUCCESS, timesheetResponse } }
    function failure(error) { return { type: workItemConstants.GET_TIMESHEET_DATA_FAILURE, error } }

}

function addTimesheetData(startDate, endDate, employeeIds, currentEmployeeId, userTeamIds, timesheetRequest, callBackFunction) {
    //console.log("addTimesheetData")
    return dispatch => {
        dispatch(request());

        workItemService.addTimesheetData({ startDate, endDate, employeeIds, currentEmployeeId, userTeamIds, payload: timesheetRequest })
            .then(
                timesheetResponse => {
                    //dispatch(this.loadTimeSheetData(startDate, endDate, employeeIds, userTeamIds));
                    dispatch(success(timesheetResponse.payload))
                    callBackFunction(timesheetResponse.payload);
                },
                error => {
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: workItemConstants.ADD_TIMESHEET_DATA_REQUEST } }
    function success(timesheetResponse) { return { type: workItemConstants.ADD_TIMESHEET_DATA_SUCCESS, timesheetResponse } }
}


function createWorkItems(startDate, endDate, employeeIds, currentEmployeeId, userTeamIds, timesheetRequest, callBackFunction) {
    //console.log("addTimesheetData")
    return dispatch => {
        dispatch(request());

        workItemService.addTimesheetData({ startDate, endDate, employeeIds, currentEmployeeId, userTeamIds, payload: timesheetRequest })
            .then(
                timesheetResponse => {
                    dispatch(success(timesheetResponse.payload))
                    dispatch(notificationActions.success('Created work items successful'));
                    callBackFunction();
                },
                error => {
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: workItemConstants.ADD_NONPROJECT_DATA_REQUEST } }
    function success(timesheetResponse) { return { type: workItemConstants.ADD_NONPROJECT_DATA_SUCCESS, timesheetResponse } }
}


function getErrorMessage(error) {
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}

function updateWorkItem(workItemRequest) {
    return dispatch => {
        dispatch(request(workItemRequest));

        workItemService.updateWorkItem(workItemRequest)
            .then(
                workItemResponse => {
                    dispatch(success(workItemResponse.payload, workItemRequest.id));
                    dispatch(notificationActions.success('WorkItem update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(workItem) { return { type: workItemConstants.UPDATE_WORK_ITEM_REQUEST, workItem } }
    function success(workItem, workItemId) { return { type: workItemConstants.UPDATE_WORK_ITEM_SUCCESS, workItem, workItemId } }
    function failure(error) { return { type: workItemConstants.UPDATE_WORK_ITEM_FAILURE, error } }
}

function updateWorkItemWithStatus(workItemRequest, successCallbackFunction) {
    return dispatch => {
        dispatch(request(workItemRequest));

        workItemService.updateWorkItemWithStatus(workItemRequest)
            .then(
                workItemResponse => {
                    successCallbackFunction();
                    dispatch(success(workItemResponse.payload, workItemRequest.id));
                    dispatch(notificationActions.success('WorkItem status update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(workItem) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_REQUEST, workItem } }
    function success(workItem, workItemId) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_SUCCESS, workItem, workItemId } }
    function failure(error) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_FAILURE, error } }
}

function playWorkItem(workItemRequest, playTime, isSavePrevious, successCallbackFunction) {
    return dispatch => {
        dispatch(request(workItemRequest));

        workItemService.playWorkItem(workItemRequest, playTime, isSavePrevious)
            .then(
                workItemResponse => {
                    successCallbackFunction();
                    dispatch(success(workItemResponse.payload, workItemRequest.id));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(workItem) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_REQUEST, workItem } }
    function success(workItem, workItemId) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_SUCCESS, workItem, workItemId } }
    function failure(error) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_FAILURE, error } }
}

function pauseWorkItem(workItemId, pausetime) {
    return dispatch => {
        workItemService.pauseWorkItem({id: workItemId}, pausetime)
            .then(
                workItemResponse => {
                    dispatch(success(workItemResponse.payload, workItemId));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function success(workItem, workItemId) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_SUCCESS, workItem, workItemId } }
    function failure(error) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_FAILURE, error } }
}

function deleteWorkItem(workItemId, isDelete) {
    return dispatch => {
        dispatch(request(workItemId, isDelete));

        workItemService.deleteWorkItem(workItemId, isDelete)
            .then(
                workItemResponse => dispatch(success(workItemId, isDelete)),
                error => {
                    dispatch(failure(workItemId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(workItemId) { return { type: workItemConstants.DELETE_WORK_ITEM_REQUEST, workItemId } }
    function success(workItemId, isDelete) { return { type: workItemConstants.DELETE_WORK_ITEM_SUCCESS, workItemId, isDelete } }
    function failure(workItemId, error) { return { type: workItemConstants.DELETE_WORK_ITEM_FAILURE, workItemId, error } }
}

function removeWorkItem(workItemId) {
    return dispatch => {
        dispatch(request(workItemId));

        workItemService.removeWorkItem(workItemId)
            .then(
                workItemResponse => dispatch(success(workItemId)),
                error => {
                    dispatch(failure(workItemId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(workItemId) { return { type: workItemConstants.REMOVE_WORK_ITEM_REQUEST, workItemId } }
    function success(workItemId) { return { type: workItemConstants.REMOVE_WORK_ITEM_SUCCESS, workItemId } }
    function failure(workItemId, error) { return { type: workItemConstants.REMOVE_WORK_ITEM_FAILURE, workItemId, error } }
}

function getWorkItemActivitiesForCalendar(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getWorkItemActivitiesForCalendar(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: workItemConstants.GETALL_WORK_ITEM_FULLCALENDAR_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GETALL_WORK_ITEM_FULLCALENDAR_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GETALL_WORK_ITEM_FULLCALENDAR_FAILURE, error } }
}

function getWorkItemActivities(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getWorkItemActivities(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: workItemConstants.GETALL_WORK_ITEM_COMPLETED_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GETALL_WORK_ITEM_COMPLETED_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GETALL_WORK_ITEM_COMPLETED_FAILURE, error } }
}

function getTodaysWorkItemActivities(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getWorkItemActivities(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: workItemConstants.GET_TODAYS_WORK_ITEM_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GET_TODAYS_WORK_ITEM_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GET_TODAYS_WORK_ITEM_FAILURE, error } }
}

function getWorkItemActivitiesPending(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getWorkItemActivitiesPending(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: workItemConstants.GETALL_WORK_ITEM_PENDING_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GETALL_WORK_ITEM_PENDING_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GETALL_WORK_ITEM_PENDING_FAILURE, error } }
}

function getWorkItemBoardActivities(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getWorkItemBoardActivities(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: workItemConstants.GETALL_WORK_ITEM_BOARD_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GETALL_WORK_ITEM_BOARD_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GETALL_WORK_ITEM_BOARD_FAILURE, error } }
}


function getProjectWorkItemActivities(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        workItemService.getProjectWorkItemActivities(requestObj)
            .then(
                workItems => dispatch(success(workItems.payload)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: workItemConstants.GETALL_WORK_ITEM_PROJECT_CALEDAR_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GETALL_WORK_ITEM_PROJECT_CALEDAR_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GETALL_WORK_ITEM_PROJECT_CALEDAR_FAILURE, error } }
}


function updateWorkItemStatus(workItemId, status) {
    return dispatch => {

        dispatch(notificationActions.clear());
        workItemService.updateWorkItemStatus(workItemId, status)
            .then(
                workItemResponse => {
                    dispatch(success(workItemResponse.payload, workItemId));
                    dispatch(notificationActions.success('Task Activity update successful'));
                },
                error => {

                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };
    function success(workItem, workItemId) { return { type: workItemConstants.UPDATE_WORK_ITEM_STATUS_SUCCESS, workItem, workItemId } }
}

function getClonedByWorkItems(workItemId, callbackFunction) {
    return dispatch => {
        dispatch(request());
        workItemService.getClonedByWorkItems(workItemId)
            .then(
                workItems => {
                    callbackFunction(workItems.payload);
                    dispatch(success(workItems.payload));
                },
                error => {
                    callbackFunction(null);
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request() { return { type: workItemConstants.GETALL_CLONED_BY_WORK_ITEMS_REQUEST } }
    function success(workItems) { return { type: workItemConstants.GETALL_CLONED_BY_WORK_ITEMS_SUCCESS, workItems } }
    function failure(error) { return { type: workItemConstants.GETALL_CLONED_BY_WORK_ITEMS_FAILURE, error } }
}


