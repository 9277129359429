import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { validationUtils } from "_helpers";
import { commonUtils } from "_helpers";
import SidebarFooter from "./SidebarFooter";
import ProjectTaskAccessRestrictionControlInput from "../fragments/ProjectTaskAccessRestrictionControlInput";
import dateUtils from "_helpers/date.utils";
import workItemUtils from "_helpers/workitem.utils";

class CreateTaskSidebar extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <div id="createtasksidebar" className={`right_sidebar create_task_right_sidebar  ${this.props.show ? "open" : ""}`}>
                <div className="justify-content-between flex-column d-flex h-100vh">
                    <div className="card-header py-0 border-bottom">
                        <h3 className="card-title"> {this.props.editMode ? "Update" : this.props.duplicateMode ? "Duplicate" : "Create"} Deliverable</h3>
                        <div className="card-options">
                            <button onClick={this.props.closeActionHandler} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
                        </div>
                    </div>
                    {this.props.show && ((this.props.editMode && this.props.selectedTaskInfo) || (this.props.duplicateMode && this.props.selectedTaskInfo) || (!this.props.editMode && !this.props.duplicateMode)) &&
                        <CreateTaskSidebarSection
                            projectId={this.props.projectId}
                            handleSubmit={this.props.handleSubmit}
                            editMode={this.props.editMode}
                            duplicateMode={this.props.duplicateMode}
                            selectedTaskInfo={this.props.selectedTaskInfo}
                            currentUser={this.props.currentUser}
                            fieldConfig={this.props.fieldConfig}
                            createConfig={this.props.createConfig}
                            allEmployees={this.props.allEmployees}
                            employeeAppConfig={this.props.employeeAppConfig}
                            allRoles={this.props.allRoles}
                            userTeams={this.props.userTeams}
                            projectInfo={this.props.projectInfo}
                            isProcessing={this.props.creating || this.props.updating}
                            permission={this.props.permission}
                            allUserTeams={(this.props.editMode && this.props.selectedTaskInfo.projectInfo.restrictedByUserTeamDetails && this.props.selectedTaskInfo.projectInfo.restrictedByUserTeamDetails.length > 0 ? this.props.selectedTaskInfo.projectInfo.restrictedByUserTeamDetails : this.props.allUserTeams) || ((this.props.projectInfo && this.props.projectInfo.restrictedByUserTeamDetails && this.props.projectInfo.restrictedByUserTeamDetails.length > 0) ? this.props.projectInfo.restrictedByUserTeamDetails : this.props.allUserTeams)}
                            projectRestrictedByUserTeamIds={(this.props.editMode && this.props.selectedTaskInfo.projectInfo.restrictedByUserTeamIds) || (this.props.projectInfo && this.props.projectInfo.restrictedByUserTeamIds)}
                            projectRestrictedByEmployeeDetails={(this.props.editMode && this.props.selectedTaskInfo.projectInfo.restrictedByEmployeeDetails) || (this.props.projectInfo && this.props.projectInfo.restrictedByEmployeeDetails)}
                            projectRestrictedByEmployeeSkills={(this.props.editMode && this.props.selectedTaskInfo.projectInfo.restrictedByEmployeeSkills) || (this.props.projectInfo && this.props.projectInfo.restrictedByEmployeeSkills)}
                        />
                    }

                </div>
            </div>
        );
    }
}


class CreateTaskSidebarSection extends Component {
    constructor() {
        super();
        this.state = {
            formErrors: {},
            hasFormError: false
        }
    }

    componentDidMount() {
        this.initializeRequest();
    }
    onResetFormError = () => {
        this.setState({
            hasFormError: false
        })
    }

    initializeRequest = () => {
        let userTeamId = commonUtils.getCurrentUserTeamId();
        let taskRequest = this.props.editMode ? this.props.selectedTaskInfo : this.props.duplicateMode ? {
            ...this.props.selectedTaskInfo,
            id: null,
            createdBy: commonUtils.getCurrentUserId(),
            modifiedBy: null,
        } :
            {
                userTeamId: userTeamId,
                projectId: this.props.projectId
            }
        this.setState({
            taskRequest: {
                ...taskRequest,
                "estDurationHrs": dateUtils.minuteToHourDecimal(taskRequest.estDurationMins || 0)
            }
        });
    }

    onFormError = (fieldName, hasError) => {
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [fieldName]: hasError
            }
        }))
    }

    onCategoryChage = (fieldName, value) => {
        this.setState(prevState => ({
            taskRequest: {
                ...prevState.taskRequest,
                name: value,
                [fieldName]: value
            }
        }));
    }
    onServiceLineChage = (fieldName, value) => {
        this.setState(prevState => ({
            taskRequest: {
                ...prevState.taskRequest,
                taskCategory: null,
                [fieldName]: value
            }
        }));
    }

    onEstimateChange = (fieldName, valueInHours) => {
        let valueInMins = (parseInt(valueInHours) * 60) + (Math.round((parseFloat(valueInHours) - parseInt(valueInHours)) * 100));
        this.setState(prevState => ({
            taskRequest: {
                ...prevState.taskRequest,
                "estDurationMins": valueInMins
            }
        }), () => {
            this.onChange(fieldName, valueInHours);
        });
    }

    onChange = (fieldName, value) => {
        this.onResetFormError()

        this.setState(prevState => ({
            taskRequest: {
                ...prevState.taskRequest,
                [fieldName]: value
            }
        }), () => {
            if (fieldName === 'estStartTime' || fieldName === 'estCompletionTime') {
                if (this.state.taskRequest.estStartTime && this.state.taskRequest.estCompletionTime) {
                    let startDateMoment = dateUtils.convertDateStringToMoment(this.state.taskRequest.estStartTime);
                    let endDateMoment = dateUtils.convertDateStringToMoment(this.state.taskRequest.estCompletionTime);
                    if (dateUtils.compareTwoMoments(startDateMoment.startOf('day'), endDateMoment.endOf('day')) === -1) {
                        this.setState(prevState => ({
                            taskRequest: {
                                ...prevState.taskRequest,
                                [fieldName === 'estStartTime' ? 'estCompletionTime' : fieldName]: undefined,
                            }
                        }))
                    }
                }
            }

        })
    }

    handleSubmit = (isCreateAnotherEnabled) => {
        let isValid = validationUtils.isValidRequest(this.getFieldConfig(), this.state.taskRequest, this.state.formErrors);
        //console.log("Validating form  request, isValid - " + isValid);
        //console.log(this.state.taskRequest);
        //console.log(this.state.formErrors)
        if (isValid) {
            this.props.handleSubmit(this.state.taskRequest, this.props.editMode, isCreateAnotherEnabled, !this.props.editMode ? this.initializeRequest : null);
        } else {
            this.setState({
                hasFormError: true
            })
        }
    }

    getFieldConfig = () => {
        return commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") === true ? this.props.fieldConfig.map(field => { if (field.name === 'serviceLine') { field.mandatory = true } return field }) : this.props.fieldConfig
    }

    render() {
        return (
            <>
                {this.state.taskRequest &&
                    <>
                        <div className="card-body pl-3 pr-3 overflow-auto">
                            <div className="row">
                                <div className="col-12 py-0 pl-2 pr-2 justify-content-between flex-column d-flex">
                                    <div className="top">
                                        <div className="row">
                                            <div className="col-6">
                                                {commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                                                    <div className="col-12">
                                                        <CustomInput
                                                            type='select'
                                                            label="Service Line"
                                                            name="serviceLine"
                                                            fieldConfig={this.getFieldConfig()}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onServiceLineChage}
                                                            placeholder="Service Line..."
                                                            options={this.props.createConfig && this.props.projectInfo && commonUtils.convertConfigListToSelectObject(this.props.projectInfo.serviceLines)}
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>
                                                }
                                                <div className="col-12">
                                                    {commonUtils.getAppConfigValue(this.props.createConfig, "enableCategorySelection") && !commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                                                        <CustomInput
                                                            type='select'
                                                            label="Category"
                                                            name="taskCategory"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onCategoryChage}
                                                            placeholder="Delivery Category..."
                                                            isAppConfig={true}
                                                            createConfig={this.props.createConfig}
                                                            configName={"taskCategory"}
                                                            onFormError={this.onFormError}
                                                        />
                                                    }
                                                    {commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineSelection") && !commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                                                        <CustomInput
                                                            type='select'
                                                            label="Category"
                                                            name="taskCategory"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onCategoryChage}
                                                            placeholder="Delivery Category..."
                                                            options={this.props.createConfig && this.props.projectInfo && workItemUtils.getTaskCategories(this.props.createConfig, this.props.projectInfo.category)}
                                                            onFormError={this.onFormError}
                                                        />
                                                    }
                                                    {commonUtils.getAppConfigValue(this.props.createConfig, "enableServiceLineEstimate") &&
                                                        <CustomInput
                                                            type='select'
                                                            label="Category"
                                                            name="taskCategory"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onCategoryChage}
                                                            placeholder="Delivery Category..."
                                                            options={this.props.createConfig && this.props.projectInfo && workItemUtils.getTaskCategories(this.props.createConfig, this.state.taskRequest.serviceLine)}
                                                            onFormError={this.onFormError}
                                                        />
                                                    }

                                                </div>
                                                <div className="col-12">

                                                    <CustomInput inputtype='text'
                                                        label="Deliverable Name"
                                                        name="name"
                                                        autoComplete="on"
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.taskRequest}
                                                        onChange={this.onChange}
                                                        onFormError={this.onFormError}
                                                        placeholder="Deliverable Name..."
                                                        datatype='alpnumspl' />
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <CustomInput
                                                            type='dateselect'
                                                            label="Estimated Start Date"
                                                            name="estStartTime"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onChange}
                                                            time={false}
                                                            minDate={this.props.projectInfo.estStartTime}
                                                            maxDate={this.props.projectInfo.estCompletionTime}
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <CustomInput
                                                            type='dateselect'
                                                            label="Estimated End Date"
                                                            name="estCompletionTime"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onChange}
                                                            time={false}
                                                            minDate={this.props.projectInfo.estStartTime}
                                                            maxDate={this.props.projectInfo.estCompletionTime}
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="col-12">
                                                    <CustomInput
                                                        type='textarea'
                                                        label="Description"
                                                        name="description"
                                                        height={"87px"}
                                                        fieldConfig={this.props.fieldConfig}
                                                        mappingObject={this.state.taskRequest}
                                                        onChange={this.onChange}
                                                        placeholder="Description..."
                                                        onFormError={this.onFormError}
                                                        datatype='alpnumspl'
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <CustomInput
                                                            type='select'
                                                            label="Delivery Lead"
                                                            name="taskOwner"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onChange}
                                                            placeholder="Task Owner..."
                                                            options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(commonUtils.filterEmployeesByTeams(this.props.allEmployees, this.props.allUserTeams.map(userTeam => { return userTeam.id })))}
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <CustomInput
                                                            type='select'
                                                            label="Type"
                                                            name="taskType"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onChange}
                                                            placeholder="Task Type..."
                                                            options={[{ value: 'PRODUCTIVE', label: 'Productive' }, { value: 'REVIEW', label: 'Review' }, { value: 'ITERATION', label: 'Iteration' }]}
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <CustomInput
                                                            type='text'
                                                            readOnly={this.props.editMode && !this.props.permission.enableDeliverableEstimateHourEditable}
                                                            label="Estimated Duration (Hrs)"
                                                            name="estDurationHrs"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onEstimateChange}
                                                            placeholder="0"
                                                            datatype='hourdecimal'
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <CustomInput
                                                            type='select'
                                                            label="Priority"
                                                            name="priority"
                                                            fieldConfig={this.props.fieldConfig}
                                                            mappingObject={this.state.taskRequest}
                                                            onChange={this.onChange}
                                                            placeholder="Priority..."
                                                            options={[{ value: 'URGENT', label: 'Urgent' }, { value: 'HIGH', label: 'High' }, { value: 'MEDIUM', label: 'Medium' }, { value: 'LOW', label: 'Low' }]}
                                                            onFormError={this.onFormError}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <ProjectTaskAccessRestrictionControlInput
                                            userTeams={this.props.allUserTeams}
                                            employeeAppConfig={this.props.employeeAppConfig}
                                            allEmployees={this.props.allEmployees}
                                            fieldConfig={this.props.fieldConfig}
                                            mappingObject={this.state.taskRequest}
                                            onChange={this.onChange}
                                            onFormError={this.onFormError}
                                            teamClasssName={"col-6"}
                                            inputClassName={"col-6"}
                                            permission={this.props.permission}
                                            projectRestrictedByUserTeamIds={this.props.projectRestrictedByUserTeamIds}
                                            projectRestrictedByEmployeeDetails={this.props.projectRestrictedByEmployeeDetails}
                                            projectRestrictedByEmployeeSkills={this.props.projectRestrictedByEmployeeSkills}
                                        />
                                        <span className="subHeading d-none">Workflow</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SidebarFooter
                            handleSubmit={this.handleSubmit}
                            editMode={this.props.editMode}
                            duplicateMode={this.props.duplicateMode}
                            initializeRequest={this.initializeRequest}
                            isProcessing={this.props.isProcessing}
                            hasFormError={this.state.hasFormError}
                            onResetFormError={this.onResetFormError}
                        />
                    </>
                }
            </ >
        );
    }
}
const mapStateToProps = state => ({
    currentUser: state.authentication.user,
    allEmployees: state.employees.allEmployees,
    fieldConfig: state.tasks.fieldConfig,
    createConfig: state.tasks.createConfig,
    employeeAppConfig: state.employees.createConfig,
    allUserTeams: state.appconfig.allTeams,
    creating: state.tasks.taskCreating,
    updating: state.tasks.taskUpdating,
    selectedTaskInfo: state.tasks.selectedTaskInfo
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTaskSidebar);

