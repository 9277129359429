import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { store } from "_helpers";
import { commonUtils } from "_helpers";
import { accessUtils } from "_helpers";
import { employeeActions } from "_actions";
import dateUtils from "_helpers/date.utils";
import classNames from "classnames/bind";
import Skeleton from "react-loading-skeleton";
import { workItemConstants } from "_constants";
import { workItemActions } from "_actions";
import PageInnerHeader from "views/common/PageInnerHeader";
import CreateWorkItemSidebar from "./components/modal/CreateWorkItemSidebar";
import workItemUtils from "_helpers/workitem.utils";


class TaskPlannerPage extends PureComponent {
  constructor() {
    super()
    this.state = {
      selectedDate: dateUtils.convertDateToMoment(new Date()),
      expandedDateDayNumber: dateUtils.convertDateToMoment(new Date()).weekday(),
      weekDateArray: [],
      filterOptions: {
        SHOW: { options: [{ name: "ALL", label: "All" }, { name: "ONGOING", label: "On Going" }, { name: "INPROGRESS", label: "In Progress" }, { name: "COMPLETED", label: "Completed" }, { name: "ASSIGNED", label: "Assigned" }], selected: "ONGOING" },
        filterText: null
      },
      employeeFilterText: "",
      showEmployeeFilter: false,
      permission: accessUtils.getTaskPlannerAccessControl()
    }
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
    this.refreshWeeksDates(this.state.selectedDate);
    this.handleFilterChange(this.state.filterOptions)
    let userTeams = commonUtils.getUserTeams(this.props.userTeams);
    store.dispatch({ type: workItemConstants.INITIALIZE_TASK_PLANNER_USER_TEAMS, userTeams });
    !this.props.employees && this.reloadEmployeeItems(userTeams);

  }

  refreshWeeksDates = (date) => {
    this.setState({
      selectedDate: date,
      weekDateArray: dateUtils.getDaysArrayByWeek(date)
    }, () => this.refreshWorkItems())

  }

  refreshWorkItems = () => {
    this.realoadTeamWorkItems(commonUtils.getUserTeams(this.props.userTeams));
  }

  realoadTeamWorkItems = (userTeams) => {
    let selectedUserTeamIds = commonUtils.getSelectedUserTeamIds(userTeams);
    let startDate = this.state.weekDateArray[0].clone().format(dateUtils.SERVER_DATE_FORMAT);
    let endDate = this.state.weekDateArray[6].clone().format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.getWorkItemActivities({ employeeIds: [], userTeamIds: selectedUserTeamIds, startDate: startDate, endDate: endDate }));
  }


  handleFilterChange = (filterOptions) => {
    this.setState({ filterOptions: filterOptions },
      () => {
        store.dispatch({ type: workItemConstants.CHANGE_FILTER_TASK_PLANNER, filterOptions });
      });
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: workItemConstants.INITIALIZE_TASK_PLANNER_USER_TEAMS, userTeams });
    this.reloadEmployeeItems(userTeams);
    this.realoadTeamWorkItems(userTeams)
  }

  reloadEmployeeItems = (userTeams) => {
    let selectedUserTeamIds = commonUtils.getSelectedUserTeamIds(userTeams);
    selectedUserTeamIds.length > 0 && store.dispatch(this.props.getTeamsEmployees(selectedUserTeamIds, this.state.filterOptions))
  }


  onChangeexpandedDateDayNumber = (moment) => {
    this.setState({ expandedDateDayNumber: moment.weekday() })
  }

  toggleShowEmployeeFilter = (isShow) => {
    this.setState({ showEmployeeFilter: isShow })
  }

  onFilterTextChange = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      filterOptions: {
        ...prevState.filterOptions,
        filterText: value
      }
    }),
      () => {
        this.handleFilterChange(this.state.filterOptions);
      }
    );
  }

  onEmployeeFilterTextChange = (value) => {
    this.setState({ employeeFilterText: value })
  }

  createItemClickHandler = (e) => {
    e.preventDefault();
    store.dispatch({ type: workItemConstants.SHOW_TASKPLANNER_CREATE_WINDOW });
  }
  closeCreateItemHandler = () => {
    store.dispatch({ type: workItemConstants.CLOSE_TASKPLANNER_CREATE_WINDOW });
  }

  goToNext = () => {
    this.refreshWeeksDates(this.state.selectedDate.add(1, 'weeks'))
  };

  goToBack = () => {
    this.refreshWeeksDates(this.state.selectedDate.subtract(1, 'weeks'))
  };

  goToCurrent = () => {
    this.refreshWeeksDates(dateUtils.getCurrentTime())
  };

  getWorkItems = (employee, dayMonth) => {
    return this.props.workItems
      .filter(item => item.employeeId === employee.id && (item.actualStartTime && (item.status === 'COMPLETED' || item.status === 'CLOSED')
        ? dateUtils.convertDateStringToMoment(item.actualStartTime) : dateUtils.convertDateStringToMoment(item.estStartTime)).format("YYYY-MM-DD") === dayMonth.format("YYYY-MM-DD")
      )
  }

  render() {
    return (
      <div className="projectlist">

        <div className="section-body mt-1">
          <div className="container-fluid">
            <div className="col-12 p-0">
              <div className="card">
                <PageInnerHeader
                  userTeams={commonUtils.getUserTeams(this.props.userTeams)}
                  handleUserTeamsSelection={this.handleUserTeamsSelection}
                  showCreateMenu={this.state.permission.createEnabled}
                  showCreateMenuText="Assign Tasks"
                  createItemHandler={this.createItemClickHandler}
                  showGridListMenu={false}
                  showFilter={true}
                  showEmployeeMenu={false}
                  showUserTeamMenu={true}
                  isLoading={this.isLoadingCompleted}
                  filterOptions={this.props.filterOptions ? this.props.filterOptions : this.state.filterOptions}
                  handleFilterChange={this.handleFilterChange}
                  onFilterTextChange={this.onFilterTextChange}
                  showDateRangeSelector={false}
                  permission={this.state.permission}
                  enableCalendarControl={!this.props.showTaskPlannerCreateModel}
                  calendarControls={{
                    goToBack: this.goToBack,
                    goToCurrent: this.goToCurrent,
                    goToNext: this.goToNext
                  }
                  }
                  enableUserTeamSingleSelection={true}
                >
                </PageInnerHeader>

                <div className="card-body">
                  <div className="table-responsive">

                    <table className="table table-vcenter table-textcenter taskplanner">
                      <thead>
                        <tr>
                          <th className="text-left employeelist">
                            <span className="fweight600">Employees <i className="fa fa-sort-amount-desc fweight600 pl-3 colorblue" onClick={() => this.toggleShowEmployeeFilter(!this.state.showEmployeeFilter)}></i></span>
                            {this.state.showEmployeeFilter &&
                              <div className="input-icon mt-1"><span className="input-icon-addon"><i className="fa fa-sort-amount-desc"></i></span>
                                <input type="text" disabled={this.props.isLoading} className="form-control input-sm filter" placeholder="Filter..."
                                  value={this.state.employeeFilterText || ''}
                                  onChange={(event) => this.onEmployeeFilterTextChange(event.target.value)}
                                />
                              </div>
                            }
                          </th>
                          {this.state.weekDateArray && this.state.weekDateArray.map(dayMonth =>
                            <th onClick={() => this.onChangeexpandedDateDayNumber(dayMonth)}
                              className={classNames("weekday text-center fweight600 fsize12", { "weekend red": dayMonth.weekday() === 6 }, { "bgcolorlightgray": dayMonth.weekday() % 2 === 0 }, { "w-350": dayMonth.weekday() === this.state.expandedDateDayNumber }, { " weekend red": dayMonth.weekday() === 5 })} key={dayMonth.format("DD/MM")}>{dayMonth.format("DD/MM (ddd)")}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>

                        {!this.props.isLoading && this.props.employees && commonUtils.filterText(this.props.employees, this.state.employeeFilterText).map(employee =>
                          <tr key={employee.id}>
                            <td className="text-left w-150">{employee.name}</td>

                            {this.state.weekDateArray && this.state.weekDateArray.map(dayMonth =>
                              <td key={dayMonth} className={classNames("", { "saturday weekend": dayMonth.weekday() === 5 }, { "sunday weekend": dayMonth.weekday() === 6 }, { "bgcolorlightgray": dayMonth.weekday() % 2 === 0 }, { "w-350": dayMonth.weekday() === this.state.expandedDateDayNumber })}>
                                <TaskAssignSection
                                  workItems={this.props.workItems && this.getWorkItems(employee, dayMonth)}
                                  isWeekEnd={dayMonth.weekday() === 6 || dayMonth.weekday() === 5}
                                  isExpandedDateDayNumber={dayMonth.weekday() === this.state.expandedDateDayNumber}
                                />
                              </td>
                            )}

                          </tr>
                        )}

                        {this.props.isLoading &&
                          <tr>
                            <td colSpan={this.state.weekDateArray.length}>
                              <Skeleton count={15} height={40} />
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CreateWorkItemSidebar
          employees={this.props.employees}
          show={this.props.showTaskPlannerCreateModel}
          closeActionHandler={this.closeCreateItemHandler}
          permission={this.state.permission}
          label="Task Planner"
          defaultSelectedStatusOption={"ASSIGNED"}
          createButtonLabel={"Assign Tasks"}
          handleReloadWorkItems={this.refreshWorkItems}
          minDate={this.state.weekDateArray[0] && workItemUtils.getWorkItemCreateStartMinDate(this.state.weekDateArray[0].clone(), this.state.permission)}
          maxDate={this.state.weekDateArray[6] && workItemUtils.getWorkItemCreateEndMaxDate(this.state.weekDateArray[6].clone(), this.state.permission)}
        />
      </div>
    );
  }
}

class TaskAssignSection extends Component {

  constructor() {
    super()
    this.state = {
      active: false
    }
  }
  onFocusIn = () => {
    this.setState({
      //active: true
    })
  }
  onFocusOut = () => {
    this.setState({
      // active: false
    })
  }

  getStartTime = (item) => {
    return (item.actualStartTime && (item.status === 'COMPLETED' || item.status === 'CLOSED')
      ? dateUtils.convertDateStringToMoment(item.actualStartTime) : dateUtils.convertDateStringToMoment(item.estStartTime));
    //let endMoment = (item.actualCompletionTime && (item.status === 'COMPLETED' || item.status === 'CLOSED')
    //     ? dateUtils.convertDateStringToMoment(item.actualCompletionTime) : dateUtils.addXToMoments(startMoment.clone(), "mins", item.estDurationInMins))
  }

  getEndTime = (item) => {
    let startMoment = this.getStartTime(item);
    return (item.actualEndTime && (item.status === 'COMPLETED' || item.status === 'CLOSED')
      ? dateUtils.convertDateStringToMoment(item.actualEndTime) : dateUtils.addXToMoments(startMoment.clone(), "minutes", item.estDurationInMins))
  }
  render() {
    return (
      <div className={`taskcontainer ${this.state.active ? 'active' : ''}`} onMouseEnter={() => this.onFocusIn()} onMouseLeave={() => this.onFocusOut()}>

        {this.props.workItems && this.props.workItems.map(workItem =>

          <div key={workItem.id} title={"[" + workItem.id + "]" + workItem.name + "-" + workItem.taskActCategory} className={`line-height-0 tag tag-${workItem.status === 'COMPLETED' ? 'success bgcolorgreen' : workItem.status === 'ASSIGNED' ? 'info' : workItem.status === 'INPROGRESS' ? 'warning' : 'danger'} mb-1 pr-2 assingedtask  ${this.props.isExpandedDateDayNumber === true ? "w-340" : "w-100"}`}>
            <div className="coloryellow fsize10 text-left float-left">
              <p className="pb-0 mb-0">{this.getStartTime(workItem).format("HH:mm")}</p>
              <p className="pb-0 mb-0">{this.getEndTime(workItem).format("HH:mm")}</p>
            </div>
            <div className="text-left">
              <span className={`d-inline-block text-truncate mt-0 fsize12 ml-2 ${this.props.isExpandedDateDayNumber === true ? "w-300" : "w-50"}`}>[{workItem.id}] {workItem.name} - {workItem.taskActCategory}</span>
            </div>
          </div>

        )
        }
        <span className="overlaysection">
          <button className="link"> <i className="fa fa-plus-circle colorblue1 fa-2x mr-2" title="Assign Task"></i></button>
          {this.props.workItems &&
            <button className="link"> <i className="fa fa-info-circle colorgreen1 fa-2x" title="Task Info"></i></button>
          }
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isLoadingCompleted: state.workItem.isLoadingCompleted,
  isLoadingPending: state.workItem.isLoadingPending,
  workItems: state.workItem.workItems && commonUtils.filterWorkItems(state.workItem.workItems, state.workItem.filterOptionsTaskPlanner),
  employees: state.employees.teamEmployees,//commonUtils.filterEmployees(state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees), state.workItem.filterOptionsTaskPlanner),
  holidayItems: state.employees.holidayItems,
  filterOptions: state.workItem.filterOptionsTaskPlanner,
  userTeams: state.workItem.userTeamsTaskPlanner,
  showTaskPlannerCreateModel: state.workItem.showTaskPlannerCreateModel
});

const mapDispatchToProps = dispatch => ({
  getTeamsEmployees: employeeActions.getTeamsEmployees,
  getWorkItemActivities: workItemActions.getWorkItemActivities,
  getWorkItemActivitiesPending: workItemActions.getWorkItemActivitiesPending
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskPlannerPage);