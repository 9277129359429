import { checklistConstants } from '../_constants';
const checklistFieldConfig = [
  { name: 'name', mandatory: true, enabled: true },
  { name: 'type', mandatory: true, enabled: true },
  { name: 'userTeamId', mandatory: true, enabled: true }
];
export function checklist(state = { checklistFieldConfig }, action) {

  switch (action.type) {

    case checklistConstants.SHOW_CHECKLIST_CONFIGURE_WINDOW:
      return {
        ...state,
        isShowConfigureChecklistSidebar: true,
        isShowChecklistAnswerSidebar: false
      };
    case checklistConstants.CLOSE_CHECKLIST_CONFIGURE_WINDOW:
      return {
        ...state,
        isShowConfigureChecklistSidebar: false,
        isShowChecklistAnswerSidebar: false,
      };

    case checklistConstants.SHOW_CHECKLIST_ANSWER_WINDOW:
      return {
        ...state,
        isShowChecklistAnswerSidebar: true,
        isShowConfigureChecklistSidebar: false,
      };
    case checklistConstants.CLOSE_CHECKLIST_ANSWER_WINDOW:
      return {
        ...state,
        isShowConfigureChecklistSidebar: false,
        isShowChecklistAnswerSidebar: false
      };

    case checklistConstants.GETALL_CHECKLISTS_REQUEST:
      return {
        ...state,
        checklistsLoading: true
      };
    case checklistConstants.GETALL_CHECKLISTS_SUCCESS:
      return {
        ...state,
        checklistsLoading: false,
        items: action.checklists

      };
    case checklistConstants.GETALL_CHECKLISTS_FAILURE:
      return {
        ...state,
        checklistsLoading: false,
        error: action.error
      };

    case checklistConstants.CREATE_CHECKLIST_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case checklistConstants.CREATE_CHECKLIST_SUCCESS:
      state.items ? state.items.push(action.checklist) : state.items = [action.checklist];
      return {
        ...state,
        creating: false
      };
    case checklistConstants.CREATE_CHECKLIST_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case checklistConstants.UPDATE_CHECKLIST_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items.map(checklist =>
          checklist.id === action.checklistId
            ? { ...checklist, updating: true }
            : checklist
        ),
        error: null
      };
    case checklistConstants.UPDATE_CHECKLIST_SUCCESS:
      let oldItem = state.items.find(checklist => checklist.id === action.checklistId);
      let newItems = state.items.filter(checklist => checklist.id !== action.checklistId);
      let newItem = {
        ...oldItem,
        ...action.checklist
      }
      newItems.push(newItem);
      return {
        ...state,
        updating: false,
        items: newItems
      };
    case checklistConstants.UPDATE_CHECKLIST_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items.map(checklist => {
          if (checklist.id === action.checklistId) {
            const { updating, ...checklistCopy } = checklist;
            return { ...checklistCopy, deleteError: action.error };
          }
          return checklist;
        }),
        error: action.error
      };

    case checklistConstants.REMOVE_CHECKLIST_SUCCESS:
      return {
        ...state,
        items: state.items.filter(checklist => checklist.id !== action.checklistId)
      };

    case checklistConstants.ACTIVATE_CHECKLIST_REQUEST:
      return {
        ...state,
        items: state.items.map(checklist =>
          checklist.id === action.checklistId
            ? { ...checklist, activating: true }
            : checklist
        )
      };
    case checklistConstants.ACTIVATE_CHECKLIST_SUCCESS:
      return {
        ...state,
        items: state.items.map(checklist =>
          checklist.id === action.checklistId
            ? { ...checklist, active: action.isActive }
            : checklist
        )
      };
    case checklistConstants.ACTIVATE_CHECKLIST_FAILURE:
      return {
        ...state,
        items: state.items.map(checklist => {
          if (checklist.id === action.checklistId) {
            const { activating, ...checklistCopy } = checklist;
            return { ...checklistCopy, deleteError: action.error };
          }
          return checklist;
        })
      };
    default:
      return state
  }
}