export const taskConstants = {

    INITIALIZE_CREATE_TASK_REQUEST: 'INITIALIZE_CREATE_TASK_REQUEST',
    INITIALIZE_CREATE_TASK_SUCCESS: 'INITIALIZE_CREATE_TASK_SUCCESS',
    INITIALIZE_CREATE_TASK_FAILURE: 'INITIALIZE_CREATE_TASK_FAILURE',


    CHANGE_FILTER_TASKS: 'CHANGE_FILTER_TASKS',
    INITIALIZE_TASKS_USER_TEAMS: 'INITIALIZE_TASKS_USER_TEAMS',

    GETALL_TASKS_REQUEST: 'GETALL_TASKS_REQUEST',
    GETALL_TASKS_SUCCESS: 'GETALL_TASKS_SUCCESS',
    GETALL_TASKS_FAILURE: 'GETALL_TASKS_FAILURE',

    GETALL_RECCURABLE_TASKS_REQUEST: 'GETALL_RECCURABLE_TASKS_REQUEST',
    GETALL_RECCURABLE_TASKS_SUCCESS: 'GETALL_RECCURABLE_TASKS_SUCCESS',
    GETALL_RECCURABLE_TASKS_FAILURE: 'GETALL_RECCURABLE_TASKS_FAILURE',

    GET_TASK_REQUEST: 'GET_TASK_REQUEST',
    GET_TASK_SUCCESS: 'GET_TASK_SUCCESS',
    GET_TASK_FAILURE: 'GET_TASK_FAILURE',

    SHOW_TASK_CREATE_WINDOW: "SHOW_TASK_CREATE_WINDOW",
    CLOSE_TASK_CREATE_WINDOW: "CLOSE_TASK_CREATE_WINDOW",
    CREATE_TASK_REQUEST: 'CREATE_TASK_REQUEST',
    CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
    CREATE_TASK_FAILURE: 'CREATE_TASK_FAILURE',

    UPDATE_TASK_REQUEST: 'UPDATE_TASK_REQUEST',
    UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
    UPDATE_TASK_FAILURE: 'UPDATE_TASK_FAILURE',

    DELETE_TASK_REQUEST: 'DELETE_TASK_REQUEST',
    DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
    DELETE_TASK_FAILURE: 'DELETE_TASK_FAILURE',

    ACTIVATE_TASK_REQUEST: "ACTIVATE_TASK_REQUEST",
    ACTIVATE_TASK_SUCCESS: "ACTIVATE_TASK_SUCCESS",
    ACTIVATE_TASK_FAILURE: "ACTIVATE_TASK_FAILURE",

    REMOVE_TASK_REQUEST: 'REMOVE_TASK_REQUEST',
    REMOVE_TASK_SUCCESS: 'REMOVE_TASK_SUCCESS',
    REMOVE_TASK_FAILURE: 'REMOVE_TASK_FAILURE',
    
    SHOW_TASK_VIEW_WINDOW:'SHOW_TASK_VIEW_WINDOW',
    CLOSE_TASK_VIEW_WINDOW:'CLOSE_TASK_VIEW_WINDOW'
};