import { commonUtils } from "./common.utils";

export const userUtils = {
  getCurrentUser,
  getLoginType,
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserName,
  getCurrentTeamId,
  getCurrentTeamName
};
let userInfoJson = null;

function getCurrentUser() {
  let currentUserItem = localStorage.getItem('cu')
  let key = localStorage.getItem('authToken')
  if (currentUserItem != null && userInfoJson == null) {
    return JSON.parse(commonUtils.decryptString(currentUserItem, key));
  }
  return userInfoJson;
}

function getLoginType() {
  let loginType = localStorage.getItem('loginType');
  return loginType != null ? loginType : "FS";
}

function getCurrentUserId() {
  let currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.id;
  }
}

function getCurrentUserEmail() {
  let currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.email;
  }
}
function getCurrentUserName() {
  let currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.name;
  }
}
function getCurrentTeamId() {
  let currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.defaultUserTeamDetails.id;
  }
}
function getCurrentTeamName() {
  let currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.defaultUserTeamDetails.name;
  }
}