import { axiosUtils } from '../_helpers';
import urlConfig from 'url.config'

export const itemAdditionalService = {
    createItemAdditional,
    getItemAdditionals,
    removeItemAdditional,
    getSearchResults

};
async function createItemAdditional(request, itemAdditionalType) {
    return await axiosUtils.post(urlConfig.ITEM_ADDITIONAL_PATH,request);
}

async function getItemAdditionals(request) {
    return await axiosUtils.get(urlConfig.ITEM_ADDITIONAL_PATH+`/${request.itemId}/${request.type}/${request.subType}`);
}

async function removeItemAdditional(request) {
    return await axiosUtils.remove(urlConfig.ITEM_ADDITIONAL_PATH+`/${request.itemId}`);
}


async function getSearchResults(searchText) {
    return await axiosUtils.post(urlConfig.ITEM_ADDITIONAL_PATH+`/search?term=${searchText}`);
}


