import React, { Component } from "react";

class ProfileSettingsPage extends Component {
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
  }
  render() {
    return (
      <div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-lg-flex justify-content-between">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item"><a className="nav-link active show" data-toggle="tab" href="#Company_Settings">Company</a></li>
                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#Localization">Localization</a></li>
                  <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#Notifications">Notifications </a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                <div className="tab-content">
                  <div className="tab-pane active show" id="Company_Settings">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Company Settings</h3>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>Company Name <span className="text-danger">*</span></label>
                                <input disabled className="form-control" type="text" defaultValue="Course5i Intelligence Pvt Ltd." />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>Contact Person</label>
                                <input disabled className="form-control" defaultValue="Sebastian" type="text" />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <div className="form-group">
                                <label>Mobile Number <span className="text-danger">*</span></label>
                                <input disabled className="form-control" defaultValue="xxx-xxx-xxxx" type="text" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label>Country</label>
                                <select disabled className="form-control">
                                  <option value>-- Select Country --</option>
                                  
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label>State/Province</label>
                                <select disabled className="form-control">
                                  <option selected>Karnataka</option>
                                  <option>Alaska</option>
                                  <option>Alabama</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label>City</label>
                                <input disabled className="form-control" defaultValue="Bangalore" type="text" />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label>Postal Code</label>
                                <input disabled className="form-control" defaultValue={91403} type="text" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Phone Number</label>
                                <input disabled className="form-control" defaultValue="xxx-xxx-xxxx" type="text" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 text-right m-t-20">
                              <button disabled type="button" className="btn btn-primary">SAVE</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="Localization">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Basic Settings</h3>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Default Country</label>
                                <select disabled className="form-control">
                                  <option>USA</option>
                                  <option>United Kingdom</option>
                                  <option selected="selected">India</option>
                                  <option>French</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Date Format</label>
                                <select disabled className="form-control">
                                  <option value="d/m/Y">15/05/2016</option>
                                  <option value="d.m.Y">15.05.2016</option>
                                  <option value="d-m-Y">15-05-2016</option>
                                  <option selected="selected" value="m/d/Y">05/15/2016</option>
                                  <option value="Y/m/d">2016/05/15</option>
                                  <option value="Y-m-d">2016-05-15</option>
                                  <option value="M d Y">May 15 2016</option>
                                  <option  value="d M Y">15 May 2016</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Timezone</label>
                                <select disabled className="form-control">
                                  <option>10:45am Chennai (IST +5:30)</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Default Language</label>
                                <select disabled className="form-control">
                                  <option selected="selected">English</option>
                                  <option>Russian</option>
                                  <option>Arabic</option>
                                  <option>French</option>
                                  <option>Hindi</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Currency Code</label>
                                <select disabled className="form-control">
                                  <option selected="selected">USD</option>
                                  <option>Pound</option>
                                  <option>EURO</option>
                                  <option>Ringgit</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Currency Symbol</label>
                                <input disabled className="form-control" defaultValue="$" type="text" />
                              </div>
                            </div>
                            <div className="col-sm-12 text-right m-t-20">
                              <button disabled type="button" className="btn btn-primary">Save</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="Roles_Permissions">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Roles &amp; Permissions</h3>
                      </div>
                      <div className="card-body d-none">
                        <ul className="list-group mb-3 tp-setting">
                          <li className="list-group-item">
                            Anyone seeing my profile page
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone send me a message
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone posts a comment on my post
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone invite me to group
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                        <div className="table-responsive">
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Module Permission</th>
                                <th>Read</th>
                                <th>Write</th>
                                <th>Create</th>
                                <th>Delete</th>
                                <th>Import</th>
                                <th>Export</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Employee</td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Holidays</td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Leave Request</td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                              </tr>
                              <tr>
                                <td>Events</td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                                <td>
                                  <label className="custom-control custom-checkbox">
                                    <input disabled type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">&nbsp;</span>
                                  </label>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane" id="Notifications">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Notifications Settings</h3>
                      </div>
                      <div className="card-body  d-none">
                        <ul className="list-group">
                          <li className="list-group-item">
                            Anyone send me a message
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone seeing my profile page
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone posts a comment on my post
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone send me a message
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                          <li className="list-group-item">
                            Anyone seeing my profile page
                            <div className="float-right">
                              <label className="custom-control custom-checkbox">
                                <input disabled type="checkbox" className="custom-control-input" />
                                <span className="custom-control-label">&nbsp;</span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSettingsPage;
