import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "_helpers/microsoftAuthConfig";
import { msalConfig } from "_helpers/microsoftAuthConfig";
import { userActions } from "_actions";
import { store } from "_helpers";
import { useIsAuthenticated } from "@azure/msal-react";
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
let initailload = true;
export const MicrosoftSignInButton = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState(null);
    const { res, setRes } = useState(null);

    if(useIsAuthenticated() && accounts && initailload) {
       // getLoginToken(accounts[0]);
        initailload = false;
    }

    function callbackFunction(response, token){
        localStorage.setItem('loginType',"MS");
        localStorage.setItem('authToken', JSON.stringify(token));
        localStorage.setItem('authRefreshToken', JSON.stringify(token));
    }

    function getLoginToken(account) {
        instance.acquireTokenSilent({
            scopes: [msalConfig.auth.clientId +"/.default"],
            account: account
        }).then((res) => {
            setToken(res.accessToken);
            store.dispatch(userActions.login(account.username,res.accessToken, "MS", (response) => callbackFunction(response, res.accessToken)));
        });
    }

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest)
                .then(response => {
                    getLoginToken(response.account);
                    setRes(response);
                })
                .catch(e => {
                    console.log(e);
                });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <button className='microsoftlogin' onClick={() => handleLogin("popup")}>
            <img src="/assets/images/microsoftlogin.png" />
        </button>
    )
}