import { dashboardConstants } from '../_constants';

export function dashboard(state = {}, action) {

  switch (action.type) {
    case dashboardConstants.GET_EMPLOYEE_WEEKLY_UTILIZATION_SUCCESS:
      return {
        ...state,
        employeeWeeklyUtilization: action.employeeWeeklyUtilization
      };
    case dashboardConstants.GET_PROJECT_STATISTICS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        projectStatisticsByCategory: action.projectStatisticsByCategory
      };

    case dashboardConstants.GET_TASK_STATISTICS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        taskStatisticsByCategory: action.taskStatisticsByCategory
      };
    case dashboardConstants.GET_WORKITEM_STATISTICS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        workItemStatisticsByCategory: action.workItemStatisticsByCategory
      };
    default:
      return state
  }
}