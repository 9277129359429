import { taskConstants } from '../_constants';
import { appConfigService, taskService } from '../_services';
import { employeeActions } from './employee.actions';
import { appConfigActions } from './appconfig.actions';
import { notificationActions } from './notification.actions';
import { commonActions } from './common.actions';

export const taskActions = {
    initializeCreateTask,
    getModuleConfig,
    getAllTasks,
    getAllRecurrableTasks,
    getTaskInfo,
    createTask,
    updateTask,
    updateTaskStatus,
    activateTask,
    deleteTask,
    removeTask
};

function initializeCreateTask(userTeamId) {
    return dispatch => {
        dispatch(getModuleConfig(userTeamId))
        dispatch(employeeActions.getModuleConfig(userTeamId));
        dispatch(employeeActions.getAllEmployees());
        dispatch(appConfigActions.getAllTeams());
    };
}
function getModuleConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'PROJECT').then(
            appConfig => {
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("getModuleConfig Project from Task");
    };
    function request() { return { type: taskConstants.INITIALIZE_CREATE_TASK_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: taskConstants.INITIALIZE_CREATE_TASK_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: taskConstants.INITIALIZE_CREATE_TASK_FAILURE, error } }
}

function getAllTasks(requestObj, filterOptions) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        taskService.getAllTasks(requestObj)
            .then(
                tasks => dispatch(success(tasks.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: taskConstants.GETALL_TASKS_REQUEST } }
    function success(tasks, filterOptions) { return { type: taskConstants.GETALL_TASKS_SUCCESS, tasks, filterOptions } }
    function failure(error) { return { type: taskConstants.GETALL_TASKS_FAILURE, error } }
}

function getAllRecurrableTasks(requestObj) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        taskService.getAllRecurrableTasks(requestObj)
            .then(
                tasks => dispatch(success(tasks.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: taskConstants.GETALL_RECCURABLE_TASKS_REQUEST } }
    function success(tasks) { return { type: taskConstants.GETALL_RECCURABLE_TASKS_SUCCESS, tasks } }
    function failure(error) { return { type: taskConstants.GETALL_RECCURABLE_TASKS_FAILURE, error } }
}

function getTaskInfo(taskId) {
    //console.log(taskId);
    return dispatch => {
        dispatch(request());
        taskService.getTaskInfo(taskId)
            .then(
                task => dispatch(success(task.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: taskConstants.GET_TASK_REQUEST } }
    function success(task) { return { type: taskConstants.GET_TASK_SUCCESS, task } }
    function failure(error) { return { type: taskConstants.GET_TASK_FAILURE, error } }
}


function createTask(taskRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(taskRequest));
        dispatch(notificationActions.clear());
        taskService.createTask(taskRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('Deliverable Creation successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)));
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));

                }
            );
    };

    function request(task) { return { type: taskConstants.CREATE_TASK_REQUEST, task } }
    function success(task, showModel) { return { type: taskConstants.CREATE_TASK_SUCCESS, task, showModel } }
    function failure(error) { return { type: taskConstants.CREATE_TASK_FAILURE, error } }
}
function updateTask(taskRequest) {
    return dispatch => {
        dispatch(request(taskRequest));
        dispatch(notificationActions.clear());
        taskService.updateTask(taskRequest)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload, taskRequest.id));
                    dispatch(notificationActions.success('Task update successful'));
                },
                error => {
                    dispatch(failure(commonActions.getErrorMessage(error)))
                    dispatch(notificationActions.error(commonActions.getErrorMessage(error)));
                }
            );
    };

    function request(task) { return { type: taskConstants.UPDATE_TASK_REQUEST, task } }
    function success(task, taskId) { return { type: taskConstants.UPDATE_TASK_SUCCESS, task, taskId } }
    function failure(error) { return { type: taskConstants.UPDATE_TASK_FAILURE, error } }
}


function updateTaskStatus(taskId, status) {
    return dispatch => {
        dispatch(request({id:taskId}));
        dispatch(notificationActions.clear());
        taskService.updateTaskStatus(taskId, status)
            .then(
                taskResponse => {
                    dispatch(success(taskResponse.payload, taskId));
                    dispatch(notificationActions.success('Task update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(task) { return { type: taskConstants.UPDATE_TASK_REQUEST, task } }
    function success(task, taskId) { return { type: taskConstants.UPDATE_TASK_SUCCESS, task , taskId} }
    function failure(error) { return { type: taskConstants.UPDATE_TASK_FAILURE, error } }
}
function getErrorMessage(error){
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}

function deleteTask(taskId, isDelete) {
    return dispatch => {
        dispatch(request(taskId, isDelete));

        taskService.deleteTask(taskId, isDelete)
            .then(
                taskResponse => dispatch(success(taskId, isDelete)),
                error => {
                    dispatch(failure(taskId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(taskId) { return { type: taskConstants.DELETE_TASK_REQUEST, taskId } }
    function success(taskId, isDelete) { return { type: taskConstants.DELETE_TASK_SUCCESS, taskId, isDelete } }
    function failure(taskId, error) { return { type: taskConstants.DELETE_TASK_FAILURE, taskId, error } }
}

function removeTask(taskId) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.removeTask(taskId)
            .then(
                taskResponse => dispatch(success(taskId)),
                error => {
                    dispatch(failure(taskId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(taskId) { return { type: taskConstants.REMOVE_TASK_REQUEST, taskId } }
    function success(taskId) { return { type: taskConstants.REMOVE_TASK_SUCCESS, taskId } }
    function failure(taskId, error) { return { type: taskConstants.REMOVE_TASK_FAILURE, taskId, error } }
}

function activateTask(taskId, isActive) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.activateTask(taskId, isActive)
            .then(
                taskResponse => dispatch(success(taskId, isActive)),
                error => {
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(taskId) { return { type: taskConstants.ACTIVATE_TASK_REQUEST, taskId } }
    function success(taskId, isActive) { return { type: taskConstants.ACTIVATE_TASK_SUCCESS, taskId, isActive } }
  
}