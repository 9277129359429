import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ProjectInfoGrid from "./ProjectInfoGrid";

class ProjectListGrids extends Component {
  render() {
    return (
      <div className="row">
        {this.props.isLoading &&
          <>
            <div className="col-lg-4 col-md-12 project-grid">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={350} />
              </SkeletonTheme>
            </div>
            <div className="col-lg-4 col-md-12 project-grid">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={350} />
              </SkeletonTheme>
            </div>
            <div className="col-lg-4 col-md-12 project-grid">
              <SkeletonTheme color="#fff" highlightColor="#ccc">
                <Skeleton count={1} height={350} />
              </SkeletonTheme>
            </div>
          </>
        }

        {this.props.items && this.props.items.map(item =>
          <ProjectInfoGrid item={item} key={item.id}
            handleDeleteProject={this.props.handleDeleteProject}
            handleEditProject={this.props.handleEditProject}
            handleArchiveProject={this.props.handleArchiveProject}
            handleRemoveProject={this.props.handleRemoveProject}
            permission={this.props.permission}
          />
        )}
      </div>
    );
  }
}
export default ProjectListGrids;