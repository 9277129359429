import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { itemAdditionalActions } from "_actions";
import { itemAdditionalConstants } from "_constants";
import { store } from "_helpers";

class SearchPage extends Component {
  constructor() {
    super();
    this.state = {
      searchText: null
    }
  }
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
  }


  onSearchTextChange = (searchText) => {
    //console.log(this.state.searchText)
    this.setState({
      searchText: searchText
    })
  }

  search = (event) => {
    if (event.key === 'Enter') {
      //console.log("SEARCH");
      store.dispatch({ type: itemAdditionalConstants.SEARCH_INPUT_CHANGE, searchText: this.state.searchText });
      store.dispatch(this.props.getSearchResults(this.state.searchText));
    }
  }
  render() {
    return (
      <div>
        <div className="section-body mt-1 px-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="input-group mb-3">
                      <input onKeyPress={(e) => this.search(e)} onChange={(e) => this.onSearchTextChange(e.target.value)} value={this.state.searchText || ""} type="text" className="form-control" placeholder="Search here..." />
                      <button onClick={(e) => this.search(e)} className="btn btn-secondary" type="button"><i className="fe fe-search"></i></button>
                    </div>
                    {this.props.searchText && <p className="mb-0">Search Result For {this.props.searchText}</p>}
                    {this.props.searchResults && this.props.searchResults.metaData &&
                      <strong className="font-12"> About  {this.props.searchResults.metaData.numberOfResults} result ( {this.props.searchResults.metaData.timeTakenInSecs} seconds)</strong>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SearchResults
          searchResults={this.props.searchResults}
        />

      </div>

    );
  }
}

class SearchResults extends Component {

  constructor() {
    super();
    this.state = {
      view: "ALL"
    }
  }
  onChangeView = (viewName) => {
    this.setState({
      view: viewName
    })
  }

  render() {
    return (
      <div className="section-body px-2">
        <div className="container-fluid">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item"><button className="link nav-link active" onClick={() => this.onChangeView("ALL")} data-toggle="tab" aria-expanded="true">All</button></li>
            <li className="nav-item"><button className="link nav-link" onClick={() => this.onChangeView("PROJECT")} data-toggle="tab" aria-expanded="true">Projects ({(this.props.searchResults && this.props.searchResults.projects && this.props.searchResults.projects.length) || '0'})</button></li>
            <li className="nav-item"><button className="link nav-link" onClick={() => this.onChangeView("TASK")} data-toggle="tab" aria-expanded="false">Deliverables({(this.props.searchResults && this.props.searchResults.tasks && this.props.searchResults.tasks.length) || '0'})</button></li>
            <li className="nav-item d-none"><button className="link nav-link" onClick={() => this.onChangeView("COMMENTS")} data-toggle="tab" aria-expanded="false">Comments (0)</button></li>
          </ul>

          <div className="tab-content mt-3">
            {this.props.searchResults &&
              <div role="tabpanel" className="tab-pane vivify fadeIn active" aria-expanded="false">
                <div className="table-responsive">
                  <table className="table table-hover card-table table_custom">
                    <tbody>
                      {(this.state.view === 'ALL' || this.state.view === 'PROJECT') &&
                        <>
                          {this.props.searchResults.projects.map(project =>
                            <tr key={project.id}>
                              <td>
                                <span ></span>
                                <h6>
                                  <NavLink
                                    to={`/project/details/${project.id}`}
                                    className="nav-link list-b pl-0">
                                    {project.deleted === true ? <del>{project.name}</del> : project.name}
                                  </NavLink>
                                </h6>
                                <span className="text-green font-13">/project/{project.id}</span>
                                <p className="mt-10 mb-0 text-muted">{project.deleted === true ? <del>{project.summary}</del> : project.summary}</p>
                              </td>
                              <td>
                                <span className="badge badge-success d-none"><i className="fa fa-eye" /> 1501</span>
                              </td>
                            </tr>
                          )}
                        </>
                      }
                      {(this.state.view === 'ALL' || this.state.view === 'TASK') &&
                        <>
                          {this.props.searchResults.tasks.map(task =>
                            <tr key={task.id}>
                              <td>
                                <h6> <NavLink
                                  to={`/project/deliverable/${task.id}`}
                                  className="nav-link list-b pl-0">
                                  {task.name}
                                </NavLink></h6>
                                <span className="text-green font-13">/deliverable/{task.id}</span>
                                <p className="mt-10 mb-0 text-muted">{task.summary}</p>
                              </td>
                              <td>
                                <span className="badge badge-success  d-none"><i className="fa fa-eye" /> 1501</span>
                              </td>
                            </tr>
                          )}
                        </>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            }
            {this.props.searchResults && this.props.searchResults.metaData.numberOfResults === 0 &&
              <div role="tabpanel" className="tab-pane vivify fadeIn" id="Images" aria-expanded="true">
                <div className="card">
                  <div className="card-body text-center py-5">
                    <img alt="No Results Found" src="assets/images/search.svg" className="width360 mb-3" />
                    <h4>No Results Found</h4>
                    <span>Choose a different search word to view results to you</span>
                  </div>
                </div>
              </div>
            }
          </div>


          <div className="col-lg-12 text-center d-none">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item disabled">
                  <button className="page-link link" tabIndex="-1">Previous</button>
                </li>
                <li className="page-item"><button className="link page-link">1</button></li>
                <li className="page-item"><button className="link page-link">2</button></li>
                <li className="page-item"><button className="link page-link">3</button></li>
                <li className="page-item">
                  <button className="page-link link">Next</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication,
  searchText: state.itemAdditional.searchText,
  searchResults: state.itemAdditional.searchResults
});

const mapDispatchToProps = dispatch => ({
  getSearchResults: itemAdditionalActions.getSearchResults
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPage);