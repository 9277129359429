import { projectConstants } from '../_constants';
import { appConfigService, projectService } from '../_services';
import { notificationActions } from './';
import { appConfigActions } from './appconfig.actions';
import { accountActions } from './account.actions';
import { employeeActions } from './employee.actions';
import { commonUtils } from '_helpers';

export const projectActions = {
    initializeCreateProject,
    createProject,
    updateProject,
    deleteProject,
    removeProject,
    archiveProject,
    updateProjectStatus,
    addToFavorites,
    getProjectList,
    getProjectsByPurchaseOrder,
    searchProjectList,
    getProjectListForTimesheet,
    getProjectInfo,
    getModuleConfig,
    getModuleConfigData,
    updateProjectEstimate,
    getViewReportData,
    initializeCreateReportConfig,
    
};

function initializeCreateProject(userTeamId) {
    return dispatch => {
        dispatch(getModuleConfig(userTeamId));
        dispatch(employeeActions.getModuleConfig(userTeamId));
        dispatch(employeeActions.getAllEmployees());
        dispatch(appConfigActions.getAllTeams());
        dispatch(accountActions.getProjectTeamAccounts(userTeamId));
    };
}

function getModuleConfigData(userTeamId, callbackFunction) {
    return dispatch => {
        appConfigService.getModuleConfig(userTeamId, 'PROJECT').then(
            appConfig => {
                callbackFunction(appConfig.payload);
            },
            error => {
            }
        );
        //console.log("getModuleConfig Project");
    };
 }

function getModuleConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        appConfigService.getModuleConfig(userTeamId, 'PROJECT').then(
            appConfig => {
                dispatch(success(appConfig.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("getModuleConfig Project");
    };
    function request() { return { type: projectConstants.INITIALIZE_CREATE_PROJECT_REQUEST, configuration: {} } }
    function success(appConfig) { return { type: projectConstants.INITIALIZE_CREATE_PROJECT_SUCCESS, configuration: appConfig } }
    function failure(error) { return { type: projectConstants.INITIALIZE_CREATE_PROJECT_FAILURE, error } }
}


function getProjectList(requestObj,filterOptions ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        projectService.getProjectList(requestObj)
            .then(
                projects => dispatch(success(projects.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GETALL_PROJECTS_REQUEST } }
    function success(projects, filterOptions) { return { type: projectConstants.GETALL_PROJECTS_SUCCESS, projects, filterOptions } }
    function failure(error) { return { type: projectConstants.GETALL_PROJECTS_FAILURE, error } }
}

function getProjectsByPurchaseOrder(requestObj ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        projectService.getProjectsByPurchaseOrder(requestObj)
            .then(
                projects => dispatch(success(projects.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GETALL_PURCHASE_ORDER_PROJECTS_REQUEST } }
    function success(projects, filterOptions) { return { type: projectConstants.GETALL_PURCHASE_ORDER_PROJECTS_SUCCESS, projects, filterOptions } }
    function failure(error) { return { type: projectConstants.GETALL_PURCHASE_ORDER_PROJECTS_FAILURE, error } }
}

function searchProjectList(requestObj,filterOptions ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        projectService.searchProjectList(requestObj)
            .then(
                projects => dispatch(success(projects.payload, filterOptions)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GETALL_PROJECTS_REQUEST } }
    function success(projects, filterOptions) { return { type: projectConstants.GETALL_PROJECTS_SUCCESS, projects, filterOptions } }
    function failure(error) { return { type: projectConstants.GETALL_PROJECTS_FAILURE, error } }
}



function getProjectListForTimesheet(requestObj ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        projectService.getProjectListForTimesheet(requestObj)
            .then(
                projects => dispatch(success(projects.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GETALL_PROJECTS_TIMESHEET_REQUEST } }
    function success(projects) { return { type: projectConstants.GETALL_PROJECTS_TIMESHEET_SUCCESS, projects } }
    function failure(error) { return { type: projectConstants.GETALL_PROJECTS_TIMESHEET_FAILURE, error } }
}


function getProjectInfo(prjectId) {
    return dispatch => {
        dispatch(request());
        projectService.getProjectDeatils(prjectId)
            .then(
                project => dispatch(success(project.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GET_PROJECT_REQUEST } }
    function success(project) { return { type: projectConstants.GET_PROJECT_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.GET_PROJECT_FAILURE, error } }
}


function createProject(projectRequest, isCreateAnother, callbackFunction) {
    return dispatch => {
        dispatch(request(projectRequest));
        dispatch(notificationActions.clear());
        projectService.createProject(projectRequest)
            .then(
                projectResponse => {
                    dispatch(success(projectResponse.payload, isCreateAnother));
                    dispatch(notificationActions.success('Project Creation successful'));
                    callbackFunction();
                },
                error => {
                    dispatch(failure(getErrorMessage(error)));
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(project) { return { type: projectConstants.CREATE_PROJECT_REQUEST, project } }
    function success(project, showModel) { return { type: projectConstants.CREATE_PROJECT_SUCCESS, project, showModel } }
    function failure(error) { return { type: projectConstants.CREATE_PROJECT_FAILURE, error } }
}

function getErrorMessage(error){
    return error && error.response && error.response.data && error.response.data.error && error.response.data.error[0].message
}


function updateProject(projectRequest) {
    return dispatch => {
        dispatch(request(projectRequest));
        dispatch(notificationActions.clear());
        projectService.updateProject(projectRequest)
            .then(
                projectResponse => {
                    dispatch(success(projectResponse.payload, projectRequest.id));
                    dispatch(notificationActions.success('Project update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(project) { return { type: projectConstants.UPDATE_PROJECT_REQUEST, project } }
    function success(project, projectId) { return { type: projectConstants.UPDATE_PROJECT_SUCCESS, project , projectId} }
    function failure(error) { return { type: projectConstants.UPDATE_PROJECT_FAILURE, error } }
}

function updateProjectStatus(projectId, status) {
    return dispatch => {
        dispatch(request({id:projectId}));
        dispatch(notificationActions.clear());
        projectService.updateProjectStatus(projectId, status, commonUtils.getCurrentUserId())
            .then(
                projectResponse => {
                    dispatch(success(projectResponse.payload, projectId));
                    dispatch(notificationActions.success('Project update successful'));
                },
                error => {
                    dispatch(failure(getErrorMessage(error)))
                    dispatch(notificationActions.error(getErrorMessage(error)));
                }
            );
    };

    function request(project) { return { type: projectConstants.UPDATE_PROJECT_REQUEST, project } }
    function success(project, projectId) { return { type: projectConstants.UPDATE_PROJECT_SUCCESS, project , projectId} }
    function failure(error) { return { type: projectConstants.UPDATE_PROJECT_FAILURE, error } }
}
function deleteProject(projectId, isDelete) {
    return dispatch => {
        dispatch(request(projectId, isDelete));

        projectService.deleteProject(projectId, isDelete)
            .then(
                projectResponse => dispatch(success(projectId, isDelete)),
                error => {
                    dispatch(failure(projectId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(projectId) { return { type: projectConstants.DELETE_PROJECT_REQUEST, projectId } }
    function success(projectId, isDelete) { return { type: projectConstants.DELETE_PROJECT_SUCCESS, projectId, isDelete } }
    function failure(projectId, error) { return { type: projectConstants.DELETE_PROJECT_FAILURE, projectId, error } }
}

function removeProject(projectId) {
    return dispatch => {
        dispatch(request(projectId));

        projectService.removeProject(projectId)
            .then(
                projectResponse => dispatch(success(projectId)),
                error => {
                    dispatch(failure(projectId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(projectId) { return { type: projectConstants.REMOVE_PROJECT_REQUEST, projectId } }
    function success(projectId) { return { type: projectConstants.REMOVE_PROJECT_SUCCESS, projectId } }
    function failure(projectId, error) { return { type: projectConstants.REMOVE_PROJECT_FAILURE, projectId, error } }
}

function archiveProject(projectId, isArchive) {
    return dispatch => {
        dispatch(request(projectId));

        projectService.archiveProject(projectId, isArchive)
            .then(
                projectResponse => dispatch(success(projectId, isArchive)),
                error => {
                    dispatch(failure(projectId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(projectId) { return { type: projectConstants.ARCHIVE_PROJECT_REQUEST, projectId } }
    function success(projectId, isArchive) { return { type: projectConstants.ARCHIVE_PROJECT_SUCCESS, projectId, isArchive } }
    function failure(projectId, error) { return { type: projectConstants.ARCHIVE_PROJECT_FAILURE, projectId, error } }
}
function addToFavorites(projectId, employeeId, isFavorite) {
    return dispatch => {
        dispatch(request(projectId));

        projectService.addToFavorites(projectId,employeeId, isFavorite)
            .then(
                addToFavoritesResponse => dispatch(success(projectId, isFavorite)),
                error => {
                    dispatch(failure(projectId, error.toString()));
                    dispatch(notificationActions.error(error.toString()));
                }
            );
    };

    function request(projectId) { return { type: projectConstants.ADD_TO_FAVORITES_PROJECT_REQUEST, projectId } }
    function success(projectId, isFavorite) { return { type: projectConstants.ADD_TO_FAVORITES_PROJECT_SUCCESS, projectId, isFavorite } }
    function failure(projectId, error) { return { type: projectConstants.ADD_TO_FAVORITES_PROJECT_FAILURE, projectId, error } }
}

function updateProjectEstimate(projectEstimate, projectID) {
    return dispatch => {
        dispatch(request());
        projectService.updateProjectEstimate(projectEstimate, projectID).then(
            projectEstimateResponse => {
                dispatch(notificationActions.success('Project Estiamte Updated successful'));
                dispatch(success(projectEstimateResponse.payload));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(notificationActions.error(error.toString()));
            }
        );
        //console.log("updateProjectEstimate Project");
    };
    function request() { return { type: projectConstants.PROJECT_UPDATE_ESTIMATE_REQUEST} }
    function success(projectEstimateResponse) { return { type: projectConstants.PROJECT_UPDATE_ESTIMATE_SUCCESS, projectEstimateResponse } }
    function failure(error) { return { type: projectConstants.PROJECT_UPDATE_ESTIMATE_FAILURE, error } }
}

function getViewReportData(requestObj ) {
    //console.log(requestObj);
    return dispatch => {
        dispatch(request());
        projectService.getViewReportData(requestObj)
            .then(
                reports => dispatch(success(reports.payload)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: projectConstants.GETALL_REPORT_REQUEST } }
    function success(reports) { return { type: projectConstants.GETALL_REPORT_SUCCESS, reports } }
    function failure(error) { return { type: projectConstants.GETALL_REPORT_FAILURE, error } }
}

function initializeCreateReportConfig(userTeamId) {
    return dispatch => {
        dispatch(request());
        dispatch(employeeActions.getAllEmployees());
        dispatch(appConfigActions.getAllTeams());
        dispatch(success());
    };

    function request() { return { type: projectConstants.INITIALIZE_CREATE_REPORTCONFIG_REQUEST } }
    function success() { return { type: projectConstants.INITIALIZE_CREATE_REPORTCONFIG_SUCCESS } }
}
