import { bidRequestsConstants } from '../_constants';

let fieldConfig = [
  { name: 'studyName', mandatory: true, enabled: true },
  { name: 'bidRequestOwner', mandatory: true, enabled: true, editable: true },
  { name: 'studyTypes', mandatory: true, enabled: true, editable: true },
  { name: 'type', mandatory: true, enabled: true, editable: true },
  { name: 'kindOfStudies', mandatory: true, enabled: true, editable: true },
  { name: 'userTeamId', mandatory: true, enabled: true, editable: true }
  
]

let screeningQuestions = [
  {
    id: 100,
    name: "Is this a follow up to a previous study then provide that study number?",
    type: "string",
    value: ""
  },
  {
    id: 101,
    name: " Any specific panel partner to reach out to ?",
    type: "string",
    value: ""
  },
  {
    id: 103,
    name: "Any other services needed (survey translation, OE translation)?",
    type: "string",
    value: ""
  }
];

let additionalQuestions = [
  {
    id: 200,
    name: "Additional Questions 1?",
    type: "boolean"
  },
  {
    id: 201,
    name: "Additional Questions 2?",
    type: "number",
    value: ""
  },
  {
    id: 203,
    name: "Additional Questions 3?",
    type: "string",
    value: ""
  }
];

let quotaInformation = [
  {
    id: 200,
    name: "Quota Information Questions 1?",
    type: "boolean"
  },
  {
    id: 201,
    name: "Quota Information Questions 2?",
    type: "number",
    value: ""
  },
  {
    id: 203,
    name: "Quota Information Questions 3?",
    type: "string",
    value: ""
  }
];

let defaultCreateConfig = [
  {
    name: "gender",
    value: ["Male", "Female"]
  }
]

let studyTypeConfig = [
  {
    value: 'CONGENPOP',
    label: 'Consumer / Gen Pop',
    subProperties: [
      {
        name: "age",
        label: 'Age',
        dataType: "string",
        isAppConfig: false
      },
      {
        name: "gender",
        label: 'Gender',
        dataType: "string",
        isAppConfig: false
      },
      {
        name: "income",
        label: 'Income',
        dataType: "string",
        isAppConfig: false
      },
      {
        name: "specificCriteria",
        label: 'Any specific criteria ',
        dataType: "string",
        isAppConfig: false
      }
    ]
  },
  {
    value: 'B2B',
    label: 'B2B',
    subProperties: [
      {
        name: "dm",
        label: 'DM',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      },
      {
        name: "designation",
        label: 'Designation',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      },
      {
        name: "companySize",
        label: 'Company Size',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      },
      {
        name: "turnover",
        label: 'Turnover',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      },
      {
        name: "industry",
        label: 'Industry (Specify) ',
        dataType: "string",
        isAppConfig: false
      }
    ]
  },
  {
    value: 'HEALTHCARE',
    label: 'Healthcare',
    subProperties: [
      {
        name: "medicalProfessionals",
        label: 'Medical Professionals',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      },
      {
        name: "patients",
        label: 'Patients',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      },
      {
        name: "hospitalAdministration ",
        label: 'Hospital Administration',
        dataType: "string",
        isAppConfig: false
      },
      {
        name: "noOfPatientsSeen",
        label: 'Number of Patients seen ',
        dataType: "number",
        isAppConfig: false
      },
      {
        name: "noOfOperationsConducted",
        label: 'Number of operations conducted',
        dataType: "number",
        isAppConfig: false
      },
      {
        name: "yearOfExiperience",
        label: 'Years of experience ',
        dataType: "string",
        isAppConfig: false
      },
      {
        name: "hospitalType",
        label: 'Hospital type',
        dataType: "select",
        isAppConfig: true,
        multiSelect: true
      }
    ]
  }
]

export function bidRequests(state = { fieldConfig, screeningQuestions, additionalQuestions,quotaInformation, defaultCreateConfig, studyTypeConfig }, action) {

  switch (action.type) {
    case bidRequestsConstants.INITIALIZE_CREATE_BID_REQUEST_REQUEST:
      return {
        ...state,
        fieldConfig: fieldConfig,
        createInitializing: true,
        selectedBidRequestsInfo: null
      };
    case bidRequestsConstants.INITIALIZE_CREATE_BID_REQUEST_SUCCESS:
      return {
        ...state,
        createInitializing: false,
        createConfig: [
          ...state.defaultCreateConfig,
          ...action.configuration
        ]
      };
    case bidRequestsConstants.INITIALIZE_CREATE_BID_REQUEST_FAILURE:
      return {
        ...state,
        createInitializing: false,
        createInitializeError: action.error,
        showCreateModel: false
      };

    case bidRequestsConstants.GETALL_BID_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bidRequestsConstants.GETALL_BID_REQUESTS_SUCCESS:
      //console.log(action.bidRequests);
      return {
        ...state,
        loading: false,
        items: action.bidRequests,
        filterOptions: action.filterOptions
      };
    case bidRequestsConstants.GETALL_BID_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        items: [],
        error: action.error
      };
    case bidRequestsConstants.GETALL_ACTIVE_BID_REQUEST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bidRequestsConstants.GETALL_ACTIVE_BID_REQUEST_SUCCESS:
      //console.log(action.bidRequests);
      return {
        ...state,
        loading: false,
        activeItems: action.bidRequests
      };
    case bidRequestsConstants.GETALL_ACTIVE_BID_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        activeItems: [],
        error: action.error
      };

    case bidRequestsConstants.CREATE_BID_REQUEST_REQUEST:
      return {
        ...state,
        creating: true,
        error: null
      };
    case bidRequestsConstants.CREATE_BID_REQUEST_SUCCESS:
      state.items.push(action.bidRequests);

      return {
        ...state,
        creating: false,
        showCreateModel: action.showModel,
      };
    case bidRequestsConstants.CREATE_BID_REQUEST_FAILURE:
      return {
        ...state,
        creating: false,
        error: action.error
      };

    case bidRequestsConstants.UPDATE_BID_REQUEST_REQUEST:
      return {
        ...state,
        updating: true,
        items: state.items.map(bidRequests =>
          bidRequests.id === action.bidRequestsId
            ? { ...bidRequests, updating: true }
            : bidRequests
        ),
        error: null
      };
    case bidRequestsConstants.UPDATE_BID_REQUEST_SUCCESS:
      let newItems = [...state.items];
      const index = state.items.findIndex(bidRequests => bidRequests.id === action.bidRequestsId);
      newItems[index] = { ...newItems[index], ...action.bidRequests }
      return {
        ...state,
        updating: false,
        items: newItems,
        showCreateModel: false
      };
    case bidRequestsConstants.UPDATE_BID_REQUEST_FAILURE:
      return {
        ...state,
        updating: false,
        items: state.items.map(bidRequests => {
          if (bidRequests.id === action.bidRequestsId) {
            const { updating, ...bidRequestsCopy } = bidRequests;
            return { ...bidRequestsCopy, deleteError: action.error };
          }
          return bidRequests;
        }),
        error: action.error
      };
    case bidRequestsConstants.DELETE_BID_REQUEST_REQUEST:
      return {
        ...state,
        items: state.items.map(bidRequests =>
          bidRequests.id === action.bidRequestsId
            ? { ...bidRequests, deleting: true }
            : bidRequests
        )
      };
    case bidRequestsConstants.DELETE_BID_REQUEST_SUCCESS:
      // remove deleted bidRequests from state
      return {
        ...state,
        items: state.items.map(bidRequests => {
          if (bidRequests.id === action.bidRequestsId) {
            // make copy of bidRequests without 'deleting:true' property
            const { deleting, ...bidRequestsCopy } = bidRequests;
            // return copy of bidRequests with 'deleteError:[error]' property
            return { ...bidRequestsCopy, deleted: action.isDelete };
          }

          return bidRequests;
        })
      };
    case bidRequestsConstants.DELETE_BID_REQUEST_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to bidRequests 
      return {
        ...state,
        items: state.items.map(bidRequests => {
          if (bidRequests.id === action.bidRequestsId) {
            // make copy of bidRequests without 'deleting:true' property
            const { deleting, ...bidRequestsCopy } = bidRequests;
            // return copy of bidRequests with 'deleteError:[error]' property
            return { ...bidRequestsCopy, deleteError: action.error };
          }

          return bidRequests;
        })
      };

    case bidRequestsConstants.REMOVE_BID_REQUEST_SUCCESS:
      return {
        ...state,
        items: state.items.filter(bidRequests => bidRequests.id !== action.bidRequestsId)
      };

    case bidRequestsConstants.ARCHIVE_BID_REQUEST_SUCCESS:
      return {
        ...state,
        items: state.items.map(bidRequests =>
          bidRequests.id === action.bidRequestsId
            ? { ...bidRequests, archived: action.isArchive }
            : bidRequests
        )
      };

    case bidRequestsConstants.SHOW_BID_REQUEST_CREATE_WINDOW:
      return {
        ...state,
        showCreateModel: true
      };

    case bidRequestsConstants.CLOSE_BID_REQUEST_CREATE_WINDOW:
      const { selectedBidRequestsInfo, ...stateCopy } = state;
      return {
        ...stateCopy,
        showCreateModel: false
      };


    case bidRequestsConstants.GET_BID_REQUEST_REQUEST:
      return {
        ...state,
        selectedBidRequestsInfo: null,
        getInfoLoading: true
      };
    case bidRequestsConstants.GET_BID_REQUEST_SUCCESS:
      //console.log(action.bidRequests);
      return {
        ...state,
        getInfoLoading: false,
        selectedBidRequestsInfo: action.bidRequests
      };
    case bidRequestsConstants.GET_BID_REQUEST_FAILURE:
      return {
        ...state,
        getInfoLoading: false,
        error: action.error
      };

    case bidRequestsConstants.INITIALIZE_BID_REQUEST_USER_TEAMS:
      return {
        ...state,
        userTeams: action.userTeams
      }
    case bidRequestsConstants.CHANGE_FILTER_BID_REQUEST:
      return {
        ...state,
        filterOptions: action.filterOptions
      };

    case bidRequestsConstants.SHOW_BID_REQUEST_VIEW_WINDOW:
      return {
        ...state,
        isShowBidRequestsViewSidebar: true
      }
    case bidRequestsConstants.CLOSE_BID_REQUEST_VIEW_WINDOW:
      return {
        ...state,
        isShowBidRequestsViewSidebar: false
      }


    default:
      return state
  }
}