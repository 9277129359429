import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import TaskDetailsItemLineInfo from "./TaskDetailsItemLineInfo";

class TasksDetailsTableItems extends Component {
    render() {
        return (
            <div className="table-responsive">
                <table className="table table_custom table-nowrap table-vcenter table-borderless position-relative">
                    <thead>
                        <tr>
                            <th className="w-100">#</th>
                            <th className="w-350">Name</th>
                            <th className="w-50">Category</th>
                            <th>Members</th>
                            <th className="w-150">Duration</th>
                            <th className="w-50">Status</th>
                            <th className="w-100">Progress</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.tasks && this.props.tasks.map((task, index) =>
                            <TaskDetailsItemLineInfo key={task.id} 
                            taskSlNumber={index + 1}
                            task={task}
                            permission={this.props.permission}
                            onSelectTask={this.props.onSelectTask}
                            handleDeleteTask={this.props.handleDeleteTask}
                            handleEditTask={this.props.handleEditTask}
                            handleUpdateStatus={this.props.handleUpdateStatus}
                            handleDuplicateTask={this.props.handleDuplicateTask}
                            projectId ={this.props.projectId}
                            handleActivateTask={this.props.handleActivateTask}
                            handleRemoveTask={this.props.handleRemoveTask}
                            removeEnabled={true} 
                            onViewTask={this.props.onViewTask}
                            />
                        )
                        }
                        {this.props.isLoading &&
                             <tr>
                             <td colSpan="8">
                               <Skeleton count={7} height={40} />
                             </td>
                           </tr>
                        }
                        
                    </tbody>
                </table>
            </div>

        );
    }
}

export default TasksDetailsTableItems;
