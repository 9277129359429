import React, { Component } from "react";
import PageInnerHeaderTimesheet from "views/common/PageInnerHeaderTimesheet";
import TimesheetPageInputItem from "./TimesheetPageInputItem";
import { accessUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { commonUtils } from "_helpers";
import { store } from "_helpers";
import { workItemActions } from "_actions";
import { employeeActions } from "_actions";
import workItemUtils from "_helpers/workitem.utils";
import { workItemConstants } from "_constants";

class TimesheetPage extends Component {
  constructor() {
    super();
    this.state = {
      showCreateModel: true,
      permission: accessUtils.getTimeSheetAccessControl(),
      selectedDate: dateUtils.getCurrentTime(),
      applicableDates: [],
      //selectedEmployeeIds: [commonUtils.getCurrentUserId()],
      selectedEmployee: commonUtils.getCurrentUser()
    }
  }
  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
    this.handleDateChange(dateUtils.getCurrentTime())
    if (this.state.permission.switchEmployeesEnabled === true) {
      this.loadEmployeeList(commonUtils.getUserTeams(this.props.userTeams))
    }
  }

  handleUserTeamsSelection = (userTeams) => {
    store.dispatch({ type: workItemConstants.INITIALIZE_TIME_SHEET_USER_TEAMS, userTeams });
    this.loadEmployeeList(userTeams);
  }

  loadEmployeeList = (userTeams) => {
    store.dispatch(this.props.getTeamsEmployees(commonUtils.getSelectedUserTeamIds(userTeams)))
  }

  handleEmployeesSelection = (selectedEmployees) => {
    this.setState(prevState => ({
      //selectedEmployeeIds: commonUtils.getSelectedEmployeeIds(selectedEmployees),
      selectedEmployee: commonUtils.getSelectedEmployee(selectedEmployees)
    }), () => {
      this.handleDateChange(this.state.selectedDate);
    })
  }

  updateTimeSheetRequest = (timeSheetRequest) => {
    this.setState({
      timeSheetRequest: timeSheetRequest
    })
  }

  resetTimeSheet = () => {
    this.handleDateChange(this.state.selectedDate);
  }

  handleUpdateTimeSheet = (submitCallBackFunction) => {
    //console.log("handleUpdateEstimate PROJECT PAGE")
    //console.log(this.state.timeSheetRequest)
    let currentEmployeeId = commonUtils.getCurrentUserId();
    let date = this.state.selectedDate;
    const startDate = date.clone().startOf('isoWeek').format(dateUtils.SERVER_DATE_FORMAT);
    const endDate = date.clone().endOf('isoWeek').format(dateUtils.SERVER_DATE_FORMAT);
    store.dispatch(this.props.addTimesheetData(startDate, endDate, [this.state.selectedEmployee.id], currentEmployeeId, [], this.state.timeSheetRequest, this.updateTimeSheetRequest))
    submitCallBackFunction();
  }

  handleDateChange = (date) => {
    //console.log("Current date : " + date.toDate())
    const startDate = date.clone().startOf('isoWeek');
    const endDate = date.clone().endOf('isoWeek');

    store.dispatch(this.props.loadTimeSheetData(startDate.format(dateUtils.SERVER_DATE_FORMAT), endDate.format(dateUtils.SERVER_DATE_FORMAT), [this.state.selectedEmployee.id], [], this.updateTimeSheetRequest));

    this.setState({
      selectedDate: date,
      applicableDates: {
        "currentWeekDates": dateUtils.getCurrentWeekDateArray(date).map(dateObject => dateObject.value),
        "workItemMinDates": workItemUtils.getWorkItemCreateStartMinDate(startDate, this.state.permission),
        "workItemMaxDates": workItemUtils.getWorkItemCreateEndMaxDate(endDate, this.state.permission)
      }
    })

  }


  goToNext = () => {
    this.handleDateChange(dateUtils.addXDays(this.state.selectedDate, 7))
  };

  goToBack = () => {
    this.handleDateChange(dateUtils.substractXDays(this.state.selectedDate, 7))
  };

  goToCurrent = () => {
    this.handleDateChange(dateUtils.getCurrentTime())
  };

  cancelShowSelectionWindow =(isShow) =>{
    this.setState({
      showCreateModel: !isShow
    })
  }

  createItemClickHandler = () => {
    this.setState({
      showCreateModel: false
    })
  }

  render() {
    return (
      <div className="timesheet">
        <PageInnerHeaderTimesheet
          createItemHandler={this.createItemClickHandler}
          showCreateMenu={this.state.showCreateModel}
          goToBack={this.goToBack}
          goToCurrent={this.goToCurrent}
          goToNext={this.goToNext}
          permission={this.state.permission}
          currentStartOfTheWeek={dateUtils.getStartOftheWeek(dateUtils.getCurrentTime())}
          startOfTheWeek={this.state.selectedDate && dateUtils.getStartOftheWeek(this.state.selectedDate)}
          showEmployeeMenu={this.state.permission.switchEmployeesEnabled}
          showUserTeamMenu={this.state.permission.switchUserTeamsEnabled}
          handleEmployeesSelection={this.handleEmployeesSelection}
          userTeams={commonUtils.getUserTeams(this.props.userTeams)}
          enableUserTeamSingleSelection={true}
          handleUserTeamsSelection={this.handleUserTeamsSelection}
          employees={this.props.employees}
        />

        <div className="col-12">
          <div className="row clearfix border-top border-bottom">
            <TimesheetSummarySection label={"Total"}
              hours={"total"}
              maxValue={2400}
              timeSheetData={this.state.timeSheetRequest}
              icon={"fsize35 fe fe-target colorblue"}
              applicableDates={this.state.applicableDates}
            />
            <TimesheetSummarySection label={"Billable"} hours={"billable"}
              timeSheetData={this.state.timeSheetRequest}
              maxValue={2400}
              icon={"fsize35 fe fe-stop-circle colorgreen"}
              applicableDates={this.state.applicableDates}
            />

            <TimesheetSummarySection label={"Non Billable"} hours={"nonProject"}
              timeSheetData={this.state.timeSheetRequest}
              icon={"fsize35 fe fe-crosshair"}
              maxValue={2400}
              applicableDates={this.state.applicableDates}
            />

            <TimesheetSummarySection label={"Leave/Holiday"} hours={"leaveOrHoliday"}
              timeSheetData={this.state.timeSheetRequest}
              icon={"fsize35 fe fe-globe colororange"}
              maxValue={2400}
              applicableDates={this.state.applicableDates}
            />

            <TimesheetSummarySection label={"Billable Utilization"} hours={"billableUtilization"}
              timeSheetData={this.state.timeSheetRequest}
              icon={"fsize35 fe fe-pie-chart colorlightred"}
              maxValue={100}
              applicableDates={this.state.applicableDates}
            />
            <TimesheetSummarySection label={"N/B Utilization"} hours={"nonUtilization"}
              timeSheetData={this.state.timeSheetRequest}
              icon={"fsize35 fe fe-clock colorlightblue"}
              maxValue={100}
              applicableDates={this.state.applicableDates}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="card pt-0 ml-1">
              <div className="card-body currency_state pt-0">
                {(this.state.timeSheetRequest) && this.props.timesheetLoading === false &&
                  <TimesheetPageInputItem
                    permission={this.state.permission}
                    //selectedEmployeeId={this.state.selectedEmployeeIds && this.state.selectedEmployeeIds.length > 0 && this.state.selectedEmployeeIds[0]}
                    timeSheetRequest={this.state.timeSheetRequest ? this.state.timeSheetRequest : {}}
                    onChange={this.updateTimeSheetRequest}
                    showSelectionWindow={!this.state.showCreateModel}
                    cancelShowSelectionWindow={this.cancelShowSelectionWindow}
                    selectedEmployee={this.state.selectedEmployee}
                    resetTimeSheet={this.resetTimeSheet}
                    handleUpdateTimeSheet={this.handleUpdateTimeSheet}
                    applicableDates={this.state.applicableDates}
                    startOfTheWeek={this.state.selectedDate && dateUtils.getStartOftheWeek(this.state.selectedDate)}
                  />

                }
                {(!this.state.timeSheetRequest || this.props.timesheetLoading === true) &&
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <td colSpan="12">
                            <Skeleton count={10} height={40} />
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}


const mapStateToProps = state => ({
  timesheetLoading: state.workItem.timesheetLoading,
  userTeams: state.workItem.userTeamsTimesheet,
  employees: state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees),
});

const mapDispatchToProps = dispatch => ({
  loadTimeSheetData: workItemActions.loadTimeSheetData,
  addTimesheetData: workItemActions.addTimesheetData,
  getTeamsEmployees: employeeActions.getTeamsEmployees,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetPage);

class TimesheetSummarySection extends Component {

  getTimeSheet = () => {
    let billableMins = 0;
    let nonProjectMins = 0;
    let leaveOrHolidayMins = 0;
    let totalMins = 0;
    this.props.timeSheetData && Object.keys(this.props.timeSheetData).map(timesheetType => {
      let taskGroupItems = this.props.timeSheetData[timesheetType];
      taskGroupItems && Object.keys(taskGroupItems).map(taskGroupId => {
        let timeEntryTaskSection = taskGroupItems[taskGroupId];
        let timeSheetTaskList = timeEntryTaskSection && timeEntryTaskSection.tasks;
        timeSheetTaskList && timeSheetTaskList.map((timeSheetTaskItem, index) => {
          let timeEntry = timeSheetTaskItem && timeSheetTaskItem.timeEntry;

          timeEntry && Object.keys(timeEntry).map(dateString => {
            if (this.props.applicableDates.currentWeekDates.includes(dateString)) {
              let time = parseInt((timeEntry[dateString] && timeEntry[dateString].timesheetTimeMins) || 0);
              if (timesheetType === 'project') {
                billableMins += time;
              } else if (timesheetType === 'nonProject') {
                if (timeSheetTaskItem.taskActCategory && (timeSheetTaskItem.taskActCategory.toLowerCase() === 'leave' || timeSheetTaskItem.taskActCategory.toLowerCase() === 'holiday')) {
                  leaveOrHolidayMins += time;
                } else {
                  nonProjectMins += time;
                }
              }
            }
          })
        })
      })
    })

    totalMins = billableMins + nonProjectMins
    let utilization = (((totalMins - leaveOrHolidayMins) / 60) / 40) * 100;
    let billableUtilization = (((billableMins) / 60) / 40) * 100;
    let nonUtilization = (((nonProjectMins) / 60) / 40) * 100;
    return {
      billableMins, nonProjectMins, leaveOrHolidayMins, totalMins,
      utilizationMins: utilization,
      billableUtilizationMins: billableUtilization,
      nonUtilizationMins: nonUtilization,
      billableString: this.convertToString(billableMins), nonProjectString: this.convertToString(nonProjectMins), leaveOrHolidayString: this.convertToString(leaveOrHolidayMins), totalString: this.convertToString(totalMins),
      utilizationString: (utilization.toFixed(2)) + " %",
      billableUtilizationString: (billableUtilization.toFixed(2)) + " %",
      nonUtilizationString: (nonUtilization.toFixed(2)) + " %"
    }
  }

  convertToString = (timeinMins) => {
    if (timeinMins > 0) {
      return Math.floor(timeinMins / 60) + "h " + (timeinMins % 60) + "m";
    }
    return 0;
  }

  render() {
    const hoursMins = this.getTimeSheet()[this.props.hours + "Mins"];
    const hoursString = this.getTimeSheet()[this.props.hours + "String"];
    return (
      <div className="col-2 pagesummary">

        <div className="card my-1">

          {this.props.timeSheetData &&

            <div className="card-body currency_state">
              <div className="icon mx-2"><i className={this.props.icon} /></div>
              <div className="content">
                <div className="fsize13 fweight600">{this.props.label}</div>
                <span className={`number ${this.props.maxValue < hoursMins ? 'blink_text' : ''}`} >{hoursString}</span>
              </div>
            </div>
          }
          {!this.props.timeSheetData &&
            <Skeleton count={1} height={50} />
          }
        </div>
      </div>)
  }
}
