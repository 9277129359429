import React, { Component } from "react";
import { connect } from "react-redux";
import EmployeeImage from "views/common/fragments/EmployeeImage";
import Ckeditor from "views/common/plugins/charts/ckeditor";
import { itemAdditionalActions } from "_actions";
import { commonUtils } from "_helpers";
import { store } from "_helpers";
import dateUtils from "_helpers/date.utils";

class ItemCommentsSection extends Component {
    constructor() {
        super();
        this.state = {
            currentView: "COMMENT"
        }
    }
    componentDidMount() {
        this.loadItemAdditiona("COMMENT");
    }

    onChangeView = (viewName) => {
        this.setState({
            currentView: viewName
        })
    }

    loadItemAdditiona = (type) => {
        this.onChangeView(type);
        store.dispatch(this.props.getItemAdditionals({ itemId: this.props.itemId, subType: this.props.subType, type }, type));
    }

    handleSubmit = (type, comment) => {
        store.dispatch(this.props.createItemAdditional({
            "userTeamId": this.props.userTeamId,
            "itemId": this.props.itemId,
            "type": type,
            "subType": this.props.subType,
            "description": comment
        }
        ));
    }

    render() {
        return (
            <div className="card mb-0 border-top">
                <div className="card-header">
                    <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item"><a className={`nav-link  ${this.props.currentView === "COMMENT" ? "active" : ""}`} onClick={() => this.loadItemAdditiona("COMMENT")} >Comments</a></li>
                        <li className="nav-item"><a className={`nav-link  ${this.props.currentView === "ACTIVITY" ? "active" : ""}`} onClick={() => this.loadItemAdditiona("ACTIVITY")} >Activities</a></li>
                        <li className="nav-item"><a className={`nav-link  ${this.props.currentView === "HISTORY" ? "active" : ""}`} onClick={() => this.loadItemAdditiona("HISTORY")} >History</a></li>
                    </ul>   <div className="card-options">
                    </div>
                </div>
                {!this.props.isLoading &&
                    <div className="card-body px-3">
                        <div className="tab-content mt-0">
                            <div className="tab-pane fade show">
                                {this.props.activities && this.state.currentView === "ACTIVITY" &&
                                    <ItemActivities activities={this.props.activities}
                                        isReadOnly={this.props.isReadOnly}
                                    />
                                }
                            </div>
                            <div className="tab-pane fade show">
                                {this.props.histories && this.state.currentView === "HISTORY" &&
                                    <ItemHistory histories={this.props.histories}
                                        isReadOnly={this.props.isReadOnly}
                                    />
                                }
                            </div>
                            <div className="tab-pane fade show active">
                                {this.state.currentView === "COMMENT" &&
                                    <ItemComments
                                        isReadOnly={this.props.isReadOnly}
                                        comments={this.props.comments}
                                        handleSubmit={this.handleSubmit}
                                        creatingItemAdditional={this.props.creatingItemAdditional}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

class ItemComments extends Component {

    constructor() {
        super();
        this.state = {
            comment: "",
            submitEnabled: false
        }
    }

    onChange = (comment) => {

        this.setState({
            comment: comment
        })
        if (this.state.comment.length > 1) {
            this.setState({
                submitEnabled: true
            })
        } else {
            this.setState({
                submitEnabled: false
            })
        }

    }

    onSubmit = () => {

        this.props.handleSubmit("COMMENT", this.state.comment);
        this.onChange("");
    }

    render() {
        return (<div className="card">
            <div className="card-body">

                <div className="form-group">
                    {!this.props.isReadOnly &&
                        <Ckeditor
                            onChange={this.onChange}
                            content={this.state.comment}
                        />
                    }
                </div>
                <span className="">{(this.props.comments && this.props.comments.length) || 0} Comments</span>
                {this.state.submitEnabled  && !this.props.isReadOnly &&
                    <button onClick={() => this.onSubmit()} className="btn btn-primary float-right">Add comment</button>
                }

                <hr />
                {this.props.comments &&

                    <ul className="recent_comments list-unstyled">
                        {this.props.comments.map(comment =>
                            <li key={comment.id}>
                                <EmployeeImage employee={comment.employeeInfo} classes={"avatar"} />
                                <div className="comment_body pl-2">
                                    <h6>{comment.employeeInfo.name} <small>wrote a comment</small>
                                        <small className="float-right">{dateUtils.convertDateTimeFormat(comment.createdDate)}</small>
                                    </h6>
                                    <span className="htmlComments" dangerouslySetInnerHTML={{ __html: comment.description }}></span>
                                    <div className="float-right">
                                        <button type="button"
                                            onClick={(e) => this.props.handleLike(this.props.item.id)}
                                            className="link pl-2" title="Like"><span></span><i className="pl-1 fa fa-heart-o colorblue" /></button>
                                        {comment.employeeInfo.id === commonUtils.getCurrentUserId() &&
                                            < button type="button"
                                                onClick={(e) => this.props.handleRemove(this.props.item.id)}
                                                className="link pl-2" title="Remove"><i className="fa fa-times colorred" /></button>
                                        }
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                }
            </div>
        </div >
        )
    }
}


class ItemHistory extends Component {

    render() {
        return (<div className="card">
            <div className="card-body">
                <ul className="recent_comments list-unstyled">
                    <li>
                        <div className="avatar_img">
                            <img className="rounded img-fluid" src="/assets/images/male.jpg" alt="fake_url" />
                        </div>
                        <div className="comment_body">
                            <h6>Donald Gardner <small className="float-right">Just now</small></h6>
                            <p>Lorem ipsum Veniam aliquip culpa laboris minim tempor</p>
                            <div>
                                <span className="tag tag-success">Approved</span>
                                <a href="fake_url;"><i className="icon-bubbles" /></a>
                                <a href="fake_url;"><i className="icon-trash" /></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="avatar_img">
                            <img className="rounded img-fluid" src="/assets/images/male.jpg" alt="fake_url" />
                        </div>
                        <div className="comment_body">
                            <h6>Dessie Parks <small className="float-right">1min ago</small></h6>
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking</p>
                            <div>
                                <span className="tag tag-danger">Rejected</span>
                                <a href="fake_url;"><i className="icon-bubbles" /></a>
                                <a href="fake_url;"><i className="icon-trash" /></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="avatar_img">
                            <img className="rounded img-fluid" src="/assets/images/male.jpg" alt="fake_url" />
                        </div>
                        <div className="comment_body">
                            <h6>Gary Camara <small className="float-right">5imn ago</small></h6>
                            <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution</p>
                            <div>
                                <span className="tag tag-warning">Pending</span>
                                <a href="fake_url;"><i className="icon-bubbles" /></a>
                                <a href="fake_url;"><i className="icon-trash" /></a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        )
    }
}

class ItemActivities extends Component {

    render() {
        return (<div className="card">
            <div className="card-body">
                <div className="timeline_item ">
                    <img className="tl_avatar" src="/assets/images/male.jpg" alt="fake_url" />
                    <span><a href="fake_url">Elisse Joson</a> San Francisco, CA <small className="float-right text-right">20-April-2019 - Today</small></span>
                    <h6 className="font600">Hello, 'Im a single div responsive timeline without media Queries!</h6>
                    <div className="msg">
                        <p>I'm speaking with myself, number one, because I have a very good brain and I've said a lot of things. I write the best placeholder text, and I'm the biggest developer on the web card she has is the Lorem card.</p>
                        <a href="fake_url" className="mr-20 text-muted"><i className="fa fa-heart text-pink" /> 12 Love</a>
                        <a className="text-muted" role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i className="fa fa-comments" /> 1 Comment</a>
                        <div className="collapse p-4 section-gray mt-2" id="collapseExample">
                            <form className="well">
                                <div className="form-group">
                                    <textarea rows={2} className="form-control no-resize" placeholder="Enter here for tweet..." defaultValue={""} />
                                </div>
                                <button className="btn btn-primary">Submit</button>
                            </form>
                            <ul className="recent_comments list-unstyled mt-4 mb-0">
                                <li>
                                    <div className="avatar_img">
                                        <img className="rounded img-fluid" src="/assets/images/male.jpg" alt="fake_url" />
                                    </div>
                                    <div className="comment_body">
                                        <h6>Donald Gardner <small className="float-right font-14">Just now</small></h6>
                                        <p>Lorem ipsum Veniam aliquip culpa laboris minim tempor</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="timeline_item ">
                    <img className="tl_avatar" src="/assets/images/male.jpg" alt="fake_url" />
                    <span><a href="fake_url" >Dessie Parks</a> Oakland, CA <small className="float-right text-right">19-April-2019 - Yesterday</small></span>
                    <h6 className="font600">Oeehhh, that's awesome.. Me too!</h6>
                    <div className="msg">
                        <p>I'm speaking with myself, number one, because I have a very good brain and I've said a lot of things. on the web by far... While that's mock-ups and this is politics, are they really so different? I think the only card she has is the Lorem card.</p>
                        <div className="timeline_img mb-20">
                            <img className="width100" src="/assets/images/gallery/1.jpg" alt="Awesome" />
                            <img className="width100" src="/assets/images/gallery/2.jpg" alt="Awesome" />
                        </div>
                        <a href="fake_url" className="mr-20 text-muted"><i className="fa fa-heart text-pink" /> 23 Love</a>
                        <a className="text-muted" role="button" data-toggle="collapse" href="#collapseExample1" aria-expanded="false" aria-controls="collapseExample1"><i className="fa fa-comments" /> 2 Comment</a>
                        <div className="collapse p-4 section-gray mt-2" id="collapseExample1">
                            <form className="well">
                                <div className="form-group">
                                    <textarea rows={2} className="form-control no-resize" placeholder="Enter here for tweet..." defaultValue={""} />
                                </div>
                                <button className="btn btn-primary">Submit</button>
                            </form>
                            <ul className="recent_comments list-unstyled mt-4 mb-0">
                                <li>
                                    <div className="avatar_img">
                                        <img className="rounded img-fluid" src="/assets/images/male.jpg" alt="fake_url" />
                                    </div>
                                    <div className="comment_body">
                                        <h6>Donald Gardner <small className="float-right font-14">Just now</small></h6>
                                        <p>Lorem ipsum Veniam aliquip culpa laboris minim tempor</p>
                                        <div className="timeline_img mb-20">
                                            <img className="width150" src="/assets/images/gallery/7.jpg" alt="Awesome" />
                                            <img className="width150" src="/assets/images/gallery/8.jpg" alt="Awesome" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="avatar_img">
                                        <img className="rounded img-fluid" src="/assets/images/male.jpg" alt="fake_url" />
                                    </div>
                                    <div className="comment_body">
                                        <h5>Dessie Parks <small className="float-right font-14">1min ago</small></h5>
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="timeline_item ">
                    <img className="tl_avatar" src="/assets/images/male.jpg" alt="fake_url" />
                    <span><a href="fake_url">Rochelle Barton</a> San Francisco, CA <small className="float-right text-right">12-April-2019</small></span>
                    <h6 className="font600">An Engineer Explains Why You Should Always Order the Larger Pizza</h6>
                    <div className="msg">
                        <p>I'm speaking with myself, number one, because I have a very good brain and I've said a lot of things. I write the best placeholder text, and I'm the biggest developer on the web by far... While that's mock-ups and this is politics, is the Lorem card.</p>
                        <a href="fake_url" className="mr-20 text-muted"><i className="fa fa-heart text-pink" /> 7 Love</a>
                        <a className="text-muted" role="button" data-toggle="collapse" href="#collapseExample2" aria-expanded="false" aria-controls="collapseExample2"><i className="fa fa-comments" /> 1 Comment</a>
                        <div className="collapse p-4 section-gray mt-2" id="collapseExample2">
                            <form className="well">
                                <div className="form-group">
                                    <textarea rows={2} className="form-control no-resize" placeholder="Enter here for tweet..." defaultValue={""} />
                                </div>
                                <button className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    comments: state.itemAdditional.comments,
    activities: state.itemAdditional.activities,
    histories: state.itemAdditional.histories,
    isLoading: state.itemAdditional.isLoading,
    creatingItemAdditional: state.itemAdditional.creatingItemAdditional,
});
const mapDispatchToProps = dispatch => ({
    getItemAdditionals: itemAdditionalActions.getItemAdditionals,
    createItemAdditional: itemAdditionalActions.createItemAdditional,
    deleteItemAdditional: itemAdditionalActions.deleteItemAdditional
});
export default connect(mapStateToProps, mapDispatchToProps)(ItemCommentsSection);

