import React, { PureComponent } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { employeeActions } from "_actions";
import { taskboardActions } from "_actions";
import { taskboardConstants } from "_constants";
import { commonUtils, store, validationUtils } from "_helpers";
import SidebarFooter from "./SidebarFooter";

class ConfigureTaskboardSidebar extends PureComponent {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      taskboardRequest: null
    }
  }

  closeTaskboardsWindow = () => {
    store.dispatch({ type: taskboardConstants.CLOSE_TASKBOARD_CONFIGURE_WINDOW });
  }

  onChange = (taskboardRequest) => {
    this.setState({
      taskboardRequest
    })
  }

  onEditTaskboard = (taskboardRequest) => {
    this.onChange(taskboardRequest);
    this.setState({
      editMode: true
    })
  }

  onClearTaskboard = (taskboardRequest) => {
    this.onChange(taskboardRequest);
    this.setState({
      editMode: false
    })
  }

  render() {
    return (
      <div id="createConfigureTaskboardSidebar" className={`right_sidebar create_taskboardconfig-right_sidebar   ${this.props.show ? "open" : ""}`}>
        <div className="justify-content-between flex-column d-flex h-100vh">
          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">Taskboard Configuration</h3>
            <div className="card-options">
              <button onClick={this.closeTaskboardsWindow} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          <div className="card-body">
            {this.props.show &&
              <CreateConfigureTaskboardSidebarSection
                permission={this.props.permission}
                handleSubmit={this.props.handleSubmit}
                editMode={this.state.editMode}
                taskboardRequest={this.state.taskboardRequest}
                onChange={this.onChange}
                onClearTaskboard={this.onClearTaskboard}
                currentUser={this.props.currentUser}
                fieldConfig={this.props.fieldConfig}
                employees={this.props.employees}
                isProcessing={this.props.creating || this.props.updating}
                userTeams={this.props.userTeams}
                userTeamId={commonUtils.getCurrentUserTeamId()}
                createTaskboard={this.props.createTaskboard}
                updateTaskboard={this.props.updateTaskboard}
                closeTaskboardsrWindow={this.closeTaskboardsrWindow}
                getTeamsEmployees={this.props.getTeamsEmployees}
                getLoadTaskBoards={this.props.getLoadTaskBoards}
              />
            }
            {this.props.show &&
              <TaskboardConfigTable
                permission={this.props.permission}
                items={this.props.taskboardItems}
                employees={this.props.employees}
                onChange={this.onChange}
                onEditTaskboard={this.onEditTaskboard}
                enableTaskboard={this.props.enableTaskboard}
                removeTaskboard={this.props.removeTaskboard}
                activateTaskboard={this.props.activateTaskboard}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

class TaskboardConfigTable extends PureComponent {

  handleRemoveTaskboard = (itemId) => {
    store.dispatch(this.props.removeTaskboard(itemId));
  }
  handleActivateTaskboard = (itemId, isEnabled) => {
    store.dispatch(this.props.activateTaskboard(itemId, isEnabled));
  }

  render() {
    return (
      <div className="table-responsive p-2">
        <table className="table table-hover table-vcenter table-striped">

          {this.props.isLoading &&
            <tbody>
              <tr>
                <td colSpan="12">
                  <Skeleton count={7} height={40} />
                </td>
              </tr>
            </tbody>
          }
          {!this.props.isLoading && <>
            <thead className="thead-light">

              <tr className="taskboardConfig-header">
                <th width="50px">Sl No</th>
                <th>Name</th>
                <th>Employees</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>

            <tbody>
              {this.props.items && this.props.items.map((item, index) =>
                <TaskboardTableItem
                  key={item.id}
                  permission={this.props.permission}
                  item={item}
                  index={index}
                  onEditTaskboard={this.props.onEditTaskboard}
                  employees={this.props.employees}
                  enableTaskboard={this.enableTaskboard}
                  updateTaskboard={this.props.updateTaskboard}
                  removeTaskboard={this.handleRemoveTaskboard}
                  activateTaskboard={this.handleActivateTaskboard}
                />
              )}
            </tbody>
          </>}
        </table>
      </div>
    );
  }
}

class TaskboardTableItem extends PureComponent {
  constructor() {
    super();
    this.state = {
      confrimDelete: false
    }
  }

  handleRemoveTaskboard = (isConfrimDelete) => {
    if (isConfrimDelete) {
      this.props.removeTaskboard(this.props.item.id);
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  activateTaskboard = (itemId, isEnabled) => {
    this.props.activateTaskboard(itemId, isEnabled);
  }
  onEditTaskboard = () => {
    this.props.onEditTaskboard(this.props.item);
  }
  renderEmployees = (item) => {
    return item.employeeMapInfo
      && item.employeeMapInfo.employees &&
      Object.keys(item.employeeMapInfo.employees).map(employeeId => {
        return <span key={employeeId} className="d-block"><span> {this.getEmployeeName(employeeId)} : </span><span>{item.employeeMapInfo.employees[employeeId]}</span></span>
      }
      )
  }

  getEmployeeName = (employeeId) => {
    const employee = this.props.employees && this.props.employees.find(employee => employee.id == employeeId);// single ==
    return (employee && employee.name) || 'Unknown';
  }
  render() {
    return (<tr key={this.props.item.id}>
      <td className="">{this.props.index + 1}</td>
      <td className="">{this.props.item.name}</td>
      <td className="">{this.renderEmployees(this.props.item)}</td>
      <td className="text-right">
        <span className="mt-1 ml-4">
          <span>
            <label className=" custom-switch custom-switch-sm m-0">
              <input type="checkbox" onChange={() => { }}
                onClick={e => this.activateTaskboard(this.props.item.id, !this.props.item.active)}
                checked={this.props.item.active ? true : false}
                className="custom-switch-input" />
              <span className="custom-switch-indicator" />
            </label>
          </span>
          {this.props.permission.editEnabled === true &&
            < button type="button" className="link pl-2" title="Edit"
              onClick={(e) => this.onEditTaskboard()}
            ><i className="fa fa-edit" /></button>
          }
          {this.props.permission.removeEnabled === true &&

            <button type="button" className="link pl-2" title="Remove" onClick={() => this.handleRemoveTaskboard(this.state.confrimDelete)}>
              {this.state.confrimDelete === true &&
                <i className="dropdown-icon fa fa fa-check colorred blink_text"></i>
              }
              {this.state.confrimDelete === false &&
                <i className="dropdown-icon fa fa-times colorred"></i>
              }
            </button>
          }
        </span>
      </td>
    </tr >)
  }

}

class CreateConfigureTaskboardSidebarSection extends PureComponent {

  constructor() {
    super();
    this.state = {
      formErrors: {},
      selectedEmployee: {},
      hasFormError: false
    }
  }

  componentDidMount() {
    this.initialize()
  }
  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }
  initialize = () => {
    store.dispatch(this.props.getTeamsEmployees([this.props.userTeamId]))
    this.props.onClearTaskboard({
      "userTeamId": this.props.userTeamId,
      "employeeMapInfo": {
      }
    },)
    this.setState({
      selectedEmployee: {
        employee: null,
      }
    })
  }
  onCreateAnotherEnabled = (value) => {
    this.setState({
      createAnotherEnabled: value
    })
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  onChange = (fieldName, value) => {
    this.onResetFormError()
    this.props.onChange({
      ...this.props.taskboardRequest,
      [fieldName]: value
    })
  }


  onUserTeamChange = (fieldName, value) => {
    store.dispatch(this.props.getTeamsEmployees([value]))
    store.dispatch(this.props.getLoadTaskBoards({userTeamIds:[value], emplyeeId: null }, ()=>{}))
    this.onChange(fieldName, value)
    this.setState({
      selectedEmployee: {
        employee: null,
      }
    })

  }

  onFieldValueChange = (fieldName, value) => {
    let nickName = this.state.selectedEmployee.nickName;
    if ("employee" === fieldName) {
      nickName = (value && value.name && value.name.split(' ')[0]) || '';
    } else if ("nickName" === fieldName) {
      nickName = value;
    }
    this.setState(prevState => ({
      selectedEmployee: {
        ...prevState.selectedEmployee,
        nickName: nickName,
        [fieldName]: value
      }
    }))
  }

  onAddEmployee = () => {

    let newEmployee = (this.props.taskboardRequest && this.props.taskboardRequest.employeeMapInfo && this.props.taskboardRequest.employeeMapInfo.employees) || {}

    if (this.state.selectedEmployee) {
      newEmployee = {
        ...newEmployee,
        [this.state.selectedEmployee.employee.id]: this.state.selectedEmployee.nickName
      }
    }
    this.updateEmployees(newEmployee);
  }

  removeEmployee = (employeeId) => {
    let newEmployee = (this.props.taskboardRequest && this.props.taskboardRequest.employeeMapInfo && this.props.taskboardRequest.employeeMapInfo.employees) || {}

    if (newEmployee[employeeId]) {
      delete newEmployee[employeeId]
    }
    this.updateEmployees(newEmployee);
  }

  updateEmployees = (newEmployees) => {
    this.props.onChange({
      ...this.props.taskboardRequest,
      employeeMapInfo: {
        ...this.props.taskboardRequest.employeeMapInfo,
        employees: {
          ...newEmployees
        }
      }
    })
    this.setState(prevState => ({
      selectedEmployee: {
        employee: null,
      }
    }))
  }

  filterSelectedEmployees = (employees) => {
    let selectedEmployeeIds = this.props.taskboardRequest.employeeMapInfo.employees &&
      Object.keys(this.props.taskboardRequest.employeeMapInfo.employees).map(employeeId => employeeId);
    return selectedEmployeeIds && employees ? employees.filter(employee => !selectedEmployeeIds.includes("" + employee.id)) : employees;
  }

  getEmployeeName = (employeeId) => {
    const employee = this.props.employees && this.props.employees.find(employee => employee.id == employeeId); // single ==
    return (employee && employee.name) || 'Unknown';
  }

  handleSubmit = () => {
    let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.props.taskboardRequest, this.state.formErrors);
    //console.log("Validating form  request, isValid - " + isValid);
    //console.log(this.props.taskboardRequest);
    //console.log(this.state.formErrors)
    if (isValid) {
      if (this.props.editMode) {
        store.dispatch(this.props.updateTaskboard(this.props.taskboardRequest));
      } else {
        store.dispatch(this.props.createTaskboard(this.props.taskboardRequest));
      }
      this.initialize();
    } else {
      this.setState({
        hasFormError: true
      })
    }
  }

  render() {
    return (
      <>
        {this.props.taskboardRequest &&
          <>
            <div className="card-body pl-3 pr-3">
              <div className="row">
                <div className="card pb-0 mb-0">
                  <div className="row">

                    <div className="col-12 row">
                      <div className="col-5 row">
                        <div className="col-12">
                          <CustomInput
                            type='select'
                            label="UserTeam"
                            name="userTeamId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.taskboardRequest}
                            onChange={this.onUserTeamChange}
                            placeholder="User Team"
                            options={this.props.userTeams && commonUtils.convertObjectToSelectObject(this.props.userTeams)}
                            onFormError={this.onFormError}
                            multi={false}
                          />
                        </div>
                        <div className="col-12">
                          <CustomInput inputtype='text'
                            label="Name"
                            name="name"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.taskboardRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Taskboard Name..."
                            datatype='alpnumspl' />
                        </div>
                        <div className="col-12">
                          <CustomInput
                            label="Description"
                            name="description"
                            type='textarea'
                            height={"37px"}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.props.taskboardRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Description..."
                            datatype='alpnumspl' />
                        </div>
                      </div>
                      <div className="col-7 ">
                        <div className="col-12 row">
                          <div className="col-6">
                            <CustomInput
                              type='select'
                              label="Employee"
                              name="employee"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.selectedEmployee}
                              onChange={this.onFieldValueChange}
                              placeholder="Employee..."
                              options={this.props.employees && commonUtils.convertObjectToSelectObject(this.filterSelectedEmployees(this.props.employees))}
                              onFormError={this.onFormError}
                              getSelectOptionEnabled={true}
                            />
                          </div>
                          <div className="col-5">
                            <CustomInput inputtype='text'
                              label="Nick Name"
                              name="nickName"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.selectedEmployee}
                              onChange={this.onFieldValueChange}
                              onFormError={this.onFormError}
                              placeholder="Nick Name..."
                              datatype='alpnumspl' />
                          </div>
                          <div className="col-1 align-items-center d-flex mt-3">
                            <button onClick={() => this.onAddEmployee()} className="btn btn-primary btn-sm">Add</button>
                          </div>
                        </div>
                        <div className="col-12 row">
                          <div className="table-responsive p-2">
                            <table className="table table-hover table-vcenter table-striped">
                              <thead>
                                <tr>
                                  <th>SlNo</th>
                                  <th>Employee Name</th>
                                  <th>Nick Name</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.taskboardRequest.employeeMapInfo.employees && Object.keys(this.props.taskboardRequest.employeeMapInfo.employees).map((emloyeeId, index) =>
                                  <tr key={emloyeeId}>
                                    <td>{index + 1}</td>
                                    <td>{this.getEmployeeName(emloyeeId)}</td>
                                    <td>{this.props.taskboardRequest.employeeMapInfo.employees[emloyeeId]}</td>
                                    <td>
                                      <button onClick={() => this.removeEmployee(emloyeeId)} className="link pl-2" title="Remove">
                                        <i className="dropdown-icon fa fa fa-times colorred"></i>
                                      </button>
                                    </td>
                                  </tr>
                                )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <SidebarFooter
                disableKeepMeOpen={true}
                handleSubmit={this.handleSubmit}
                editMode={this.props.editMode}
                closeButtonLabel={'Reset'}
                initializeRequest={this.initialize}
                hasFormError={this.state.hasFormError}
                onResetFormError={this.onResetFormError}
              />
            </div>

          </>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  fieldConfig: state.taskBoard.taskboardFieldConfig,
  employees: state.employees.teamEmployees && commonUtils.getActiveEmployees(state.employees.teamEmployees),
});

const mapDispatchToProps = dispatch => ({
  createTaskboard: taskboardActions.createTaskboard,
  updateTaskboard: taskboardActions.updateTaskboard,
  removeTaskboard: taskboardActions.removeTaskboard,
  activateTaskboard: taskboardActions.activateTaskboard,
  getTeamsEmployees: employeeActions.getTeamsEmployees,
  getLoadTaskBoards: taskboardActions.getAllTaskboards
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureTaskboardSidebar);

